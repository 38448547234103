<template>
  <!-- 注册 -->
  <div class="chongzhi">
    <Navbar :title="'充值'"></Navbar>
    <div class="top">
      <p class="title">充值金额</p>
      <div class="input-box">
        <span>¥</span>
        <input type="text" v-model="money" placeholder="">
      </div>
    </div>
    <div class="bottom-box">
        <a href="javascript:;" class="xiadan-btn" @click="pay">确认支付</a>
    </div>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
// import websocket from '../utils/webSocket'
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../api";
import base from "../api/base.js";
import { wechatConfig } from "../utils/wxConfig";
import { login } from "../utils/login_toast";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      money:"",
      jsApiParameters:"",
      userInfo: {
        id: "",
      },
      invate_uid:""
    };
  },
  created(){
    
    var invate_uid = this.$route.query.invate_uid;
    if(invate_uid){
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.WXlogin()
    this.setShare()
  },
  methods:{
    
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-账户充值`,
        desc: `聚制冷精英，集行业资源，方便您我他！`,
        link: base.shareURL + "/chongzhi?invate_uid=" + this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    WXlogin() {
      var _this = this;
      var token = this.$cookies.get("Authorization");
      var code = this.getUrlKey("code");
      var state = this.getUrlKey("state");
      if (!token && code && state) {
        login(_this.getLoginUser);
      }
    },
    getLoginUser(userInfo) {
      // this.getUser();
      console.log(userInfo);
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    pay(){
      var _this = this;
      var data = {
        money:_this.money,
      }
      _this.$toast.loading({
        message: "请稍等...",
        forbidClick: true,
        duration: 0
      });
      addOp("/bill_order/user_account_recharge",data).then(res=>{
        _this.$toast.clear();
        if (res && res.status == 200 && res.data.state == 1000) {
          console.log(res.data)
          _this.jsApiParameters = res.data.data.pay_param;
          _this.callpay();
          // _this.payTypeShow = false;
        } else {
          _this.$toast.fail(res.data.msg);
        }
      })
    },
    //支付
    jsApiCall() {
      var _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        _this.jsApiParameters,
        function(res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            alert("充值成功！");
            setTimeout(()=>{
              _this.$router.go(-1)
            },500)
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            alert("支付失败！请重试！");
          }
        }
      );
    },
    callpay() {
      var _this = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            _this.jsApiCall,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
          document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
        }
      } else {
        _this.jsApiCall();
      }
    },
  }
};
</script>

<style>
</style>