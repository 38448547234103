<template>
  <van-nav-bar class="navber" fixed placeholder z-index="99"  >
    <template #title>
      <span class="title" :style="`color:${textColor}`">{{title}}</span>
    </template>
    <template #left>
      <van-icon @click="goBack" name="arrow-left" :color="textColor" size="15px" />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    title:String,
    color:{
      type:String,
      default:"#2F78DC"
    },
    textColor:{
      type:String,
      default:"#fff"
    }
  },
  data() {
    return {};
  },
  computed: {},
  created() {
  },
  mounted() {},
  watch: {},
  methods: {
    goBack(){
      this.$router.go(-1)
    },
  },
  components: {},
};
</script>

<style scoped >
</style>
