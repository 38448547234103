<template>
  <div class="product-fabu">
    <van-nav-bar fixed placeholder z-index="99">
      <template #title>
        <span class="title" style="color: #000; font-size: 15px">产品修改</span>
      </template>
      <template #left>
        <van-icon @click="goBack" name="arrow-left" color="#000" size="15px" />
      </template>
      <template #right>
        <van-button
          class="fabu-btn"
          type="info"
          round
          size="small"
          block
          @click="onFabu"
          >修改</van-button
        >
      </template>
    </van-nav-bar>

    <div class="top-box">
      <van-field
        v-model="formData.content"
        rows="1"
        :autosize="{ minHeight: 150 }"
        type="textarea"
        :border="false"
        placeholder="描述一下宝贝的品牌型号、货品来源."
        @blur="onContentInputBlue"
      />
      <div class="upload-box">
        <van-uploader
          v-model="fileList"
          preview-size="95px"
          multiple
          :after-read="upload"
          @delete="uploadDelete"
          accept="image/*,video/*"
        >
          <div class="upload-btn" v-if="fileList.length <= 0">
            + 添加优质首图更吸引人~
          </div>
          <div class="upload-btn" v-else>+ 添加更多细节图卖的更快~</div>
        </van-uploader>
      </div>
      <div class="address-box" @click="showLocation = true">
        <van-icon name="location" />
        <span
          v-if="
            formData.province ||
            formData.city ||
            formData.area ||
            formData.address
          "
        >
          <span>{{ formData.province ? formData.province : "" }}</span>
          <span>{{ formData.city ? `- ${formData.city}` : "" }}</span>
          <span>{{ formData.area ? `- ${formData.area}` : "" }}</span>
          {{ formData.address ? `  ${formData.address}` : "" }}
        </span>
        <span v-else>请选择地址</span>
        <van-icon name="play" />
      </div>
      <van-popup
        v-model="showLocation"
        position="bottom"
        :style="{ height: '80%' }"
        :close-on-click-overlay="false"
      >
        <div class="city-box">
          <van-field
            class="input"
            readonly
            clickable
            name="picker"
            :value="cityvalue"
            label="所在地区"
            placeholder="点击选择城市"
            @click="showCityPicker = true"
            :border="true"
          />
          <van-field
            class="input"
            name="picker"
            v-model="formData.address"
            label="详细地址"
            placeholder="请输入详细地址"
          />
          <div class="btns-box">
            <van-button
              style="margin-right: 20px; width: 120px"
              round
              @click="
                showLocation = false;
                showCityPicker = false;
              "
              >取消</van-button
            >
            <van-button
              style="width: 120px"
              type="info"
              round
              @click="
                showLocation = false;
                showCityPicker = false;
              "
              >确定</van-button
            >
          </div>
        </div>
      </van-popup>
      <van-popup
        class="city-popup"
        v-model="showCityPicker"
        position="bottom"
        :overlay="true"
        style="z-index: 10001 !important"
      >
        <van-area
          :area-list="cityColumns"
          @confirm="onCityConfirm"
          @cancel="showCityPicker = false"
        />
      </van-popup>

      <div class="class-box">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="1" :border="false">
            <template #title>
              <div class="collapse-title">分类/品牌</div>
            </template>
            <div class="collapse-container">
              <div class="class-item">
                <div class="title">分类</div>
                <div class="right-box">
                  <div
                    :class="
                      item.id == formData.category_id ? 'item active' : 'item'
                    "
                    v-for="item in category"
                    :key="item.id"
                    @click="activeRadio('category', item)"
                  >
                    {{ item.name }}
                  </div>
                  <div class="item" @click="showAddCategory = true">+ 添加</div>
                  <van-dialog
                    v-model="showAddCategory"
                    title="添加分类"
                    show-cancel-button
                    :before-close="addCategory"
                  >
                    <div class="addbox">
                      <van-field
                        v-model="categoryvalue"
                        placeholder="请输入分类名称"
                        :border="true"
                        size="large"
                      />
                    </div>
                  </van-dialog>
                </div>
              </div>
              <div class="class-item">
                <div class="title">品牌</div>
                <div class="right-box">
                  <div
                    :class="
                      item.id == formData.brand_id ? 'item active' : 'item'
                    "
                    v-for="item in brands"
                    :key="item.id"
                    @click="activeRadio('brands', item)"
                  >
                    {{ item.name }}
                  </div>
                  <div class="item" @click="showAddbrands = true">+ 添加</div>
                  <van-dialog
                    v-model="showAddbrands"
                    title="添加品牌"
                    show-cancel-button
                    :before-close="addBrands"
                  >
                    <div class="addbox">
                      <van-field
                        v-model="brandsvalue"
                        placeholder="请输入品牌名称"
                        :border="true"
                        size="large"
                      />
                    </div>
                  </van-dialog>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div class="botom-box">
      <van-field
        class="input-item"
        label="现价"
        placeholder="¥0.00"
        input-align="right"
        v-model="formData.sale_price"
        :border="false"
      />
      <van-field
        class="input-item"
        label="原价"
        placeholder="¥0.00"
        input-align="right"
        v-model="formData.origin_price"
        :border="false"
      />
      <van-field
        class="input-item"
        label="进货价格"
        placeholder="¥0.00"
        input-align="right"
        v-model="formData.purchase_price"
        v-if="userInfo.identity == 9"
        :border="false"
      />
      <!-- <van-field
        class="input-item"
        label="发布类型"
        is-link
        readonly
        input-align="right"
        placeholder="请选择发布类型"
        :border="false"
        @click="showPicker = true"
        v-model="goodtype_value"
      /> -->
      <van-field
        class="input-item"
        input-align="right"
        :border="false"
        name="checkboxGroup"
        label="发布类型"
      >
        <template #input>
          <van-checkbox-group
            v-model="formData.good_type"
            direction="horizontal"
          >
            <van-checkbox name="sale" shape="square">出售</van-checkbox>
            <van-checkbox name="rent" shape="square">出租</van-checkbox>
            <van-checkbox name="exchange" shape="square">交换</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <!-- <van-field
        class="input-item"
        label="详细地址"
        placeholder="请输入详细地址"
        input-align="right"
        v-model="formData.address"
        :border="false"
      /> -->
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        value-key="text"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
import province_list from "../../utils/area";
export default {
  props: {},
  data() {
    return {
      url: base.apiURL,
      userInfo: {},
      activeNames: ["1"],
      fileList: [],
      jage: "¥0.00",
      showPicker: false,
      columns: [
        { text: "出售", value: "sale" },
        { text: "出租", value: "rent" },
        { text: "交换", value: "exchange" },
      ],
      goodtype_value: "",
      formData: {
        content: "",
        origin_price: "", //商品原价
        sale_price: "", //商品现价
        purchase_price: "", //商品进货价
        good_type: [], //商品类型

        province: "",
        city: "",
        area: "",
        latitude: "",
        address: "",
        longitude: "",
        video: [],
        image: [],

        brand_id: "",
        category_id: "",
      },
      location: {}, //位置信息

      brands: [], //品牌
      category: [], //分类

      showAddCategory: false,
      categoryvalue: "",
      showAddbrands: false,
      brandsvalue: "",
      pid: "",

      showLocation: false,

      cityColumns: province_list,
      cityvalue: "",
      showCityPicker: false,
    };
  },
  computed: {},
  created() {
    var _this = this;
    _this.userInfo = this.$cookies.get("userInfo");
    _this.getLocation();
    console.log(_this.userInfo);
    var code = _this.getUrlKey("code");
    var state = _this.getUrlKey("state");
    var token = _this.$cookies.get("Authorization");
    if (!token && !code) {
      loginAlert();
      return;
    } else if (!token && code) {
      login(_this.getLoginUser);
      return;
    }
    _this.pid = _this.$route.query.id;
    _this.getInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    onCityConfirm(values) {
      this.cityvalue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      this.formData.province = values[0].name;
      this.formData.city = values[1].name;
      this.formData.area = values[2].name;
      this.showCityPicker = false;
    },
    getInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/product/info";
      indexOp(api, { id: _this.pid }).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          var data = res.data;
          _this.formData.content = data.content;
          _this.onContentInputBlue();
          _this.formData.origin_price = data.origin_price;
          _this.formData.sale_price = data.sale_price;
          _this.formData.purchase_price = data.purchase_price;
          _this.formData.good_type = data.good_type;
          if (data.province) _this.formData.province = data.province;
          if (data.city) _this.formData.city = data.city;
          if (data.area) _this.formData.area = data.area;
          if (data.latitude) _this.formData.latitude = data.latitude;
          if (data.longitude) _this.formData.longitude = data.longitude;
          _this.cityvalue = data.province + "-" + data.city + "-" + data.area;
          _this.formData.address = data.address;
          _this.formData.brand_id = data.brand_id;
          _this.formData.category_id = data.category_id;
          if (data.image && data.image.length > 0) {
            data.image.map((item) => {
              var d = {
                url: item,
                filetype: "image",
                content: _this.url + item,
              };
              _this.fileList.push(d);
            });
          }
          if (data.video && data.video.length > 0) {
            data.video.map((item) => {
              var d = {
                url: item,
                filetype: "video",
                content: _this.url + item,
              };
              _this.fileList.push(d);
            });
          }
        }
      });
    },
    onFabu() {
      var _this = this;
      if (_this.formData.good_type.length == 0) {
        _this.$toast.fail("请选择发布类型！");
        return;
      }
      if (_this.fileList.length > 0) {
        _this.fileList.map((item) => {
          if (item.filetype == "image") {
            _this.formData.image.push(item.url);
          } else if (item.filetype == "video") {
            _this.formData.video.push(item.url);
          }
        });
      }
      _this.formData.uid = _this.userInfo.id;
      _this.formData.id = _this.pid;
      addOp("/product/store", _this.formData).then((res) => {
        if (res && res.status == 200) {
          _this.$toast.success("修改成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        }
        console.log(res);
      });
    },
    onContentInputBlue(e) {
      var _this = this;
      console.log(_this.formData.content);
      var data = {
        content: _this.formData.content,
      };
      indexOp("/product/store_init", data).then((res) => {
        if (res.status == 200) {
          _this.brands = res.data.brands;
          _this.category = res.data.category;
        }
      });
    },
    upload(file, value) {
      var _this = this;
      console.log(_this.fileList);
      if (file instanceof Array) {
        file.map((item) => {
          console.log(item);
          var type = item.file.type.split("/")[0];
          console.log(type);
          if (type == "image") {
            item.filetype = type;
            _this.uploadImg(item);
          } else if (type == "video") {
            item.filetype = type;
            _this.uploadVideo(item);
          }
        });
      } else {
        var type = file.file.type.split("/")[0];
        console.log(type);
        if (type == "image") {
          file.filetype = type;
          _this.uploadImg(file);
        } else if (type == "video") {
          file.filetype = type;
          _this.uploadVideo(file);
        }
      }
      console.log(file);
    },
    uploadImg(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          file.url = res.data.url;
          console.log(_this.fileList);
          // _this.formData.image = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    uploadVideo(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadFile(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          file.message = "";
          file.url = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    activeRadio(type, info) {
      var _this = this;
      if (type == "category") {
        _this.formData.category_id = info.id;
      }
      if (type == "brands") {
        _this.formData.brand_id = info.id;
      }
      console.log(type, info);
    },
    uploadDelete(file) {
      var _this = this;
      console.log(file);
    },
    onConfirm(value) {
      console.log(value);
      this.formData.good_type = value.value;
      this.goodtype_value = value.text;
      this.showPicker = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    getLoginUser(userInfo) {
      console.log(userInfo);
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getLocation() {
      var _this = this;
      var geolocation = new qq.maps.Geolocation(
        "U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX",
        "地图-mapApp"
      );
      var options = {
        timeout: 8000,
      };
      geolocation.getIpLocation(_this.showPosition, _this.showErr);
    },

    showPosition(position) {
      var _this = this;
      _this.location = position;
      _this.formData.latitude = position.lat;
      _this.formData.longitude = position.lng;
      // alert(position.province + "-" + position.city + "-" + position.addr);
      console.log(position);
      var data = {
        key: "U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX",
        location: position.lat + "," + position.lng,
        output: "jsonp",
      };
      var api = "https://apis.map.qq.com/ws/geocoder/v1/";

      _this.$toast.loading({
        message: "定位中..",
        forbidClick: true,
      });
      _this
        .$jsonp(api, data)
        .then((res) => {
          console.log(res);
          _this.formData.province = res.result.address_component.province;
          _this.formData.city = res.result.address_component.city;
          _this.formData.area = res.result.address_component.district;
          _this.$toast.clear();
        })
        .catch((err) => {
          console.log(err);
        });
      // indexOp("https://apis.map.qq.com/ws/geocoder/v1/", data).then((res) => {
      //   console.log(res);
      // });
    },

    showErr() {
      alert("定位失败");
      console.log("定位失败");
    },
    //添加分类
    addCategory(action, done) {
      var _this = this;
      if (action === "confirm") {
        addOp("/product/category/store", { name: _this.categoryvalue }).then(
          (res) => {
            if (res.data) {
              var it = { name: res.data.name, id: res.data.id };
              var have = false;
              _this.category.map((item) => {
                if (item.id == it.id) {
                  have = true;
                } else {
                  have = false;
                }
              });
              setTimeout(() => {
                if (!have) {
                  _this.category.push(it);
                  _this.formData.category_id = res.data.id;
                }
              });
              done();
            } else {
              _this.$toast.file("添加失败！");
              done(false);
            }
          }
        );
      } else {
        done();
      }
    },
    //添加品牌
    addBrands(action, done) {
      var _this = this;
      console.log(action);
      if (action === "confirm") {
        addOp("/product/brand/store", { name: _this.brandsvalue }).then(
          (res) => {
            if (res.data) {
              var it = { name: res.data.name, id: res.data.id };
              var have = false;
              _this.brands.map((item) => {
                if (item.id == it.id) {
                  have = true;
                } else {
                  have = false;
                }
              });
              setTimeout(() => {
                if (!have) {
                  _this.brands.push(it);
                  _this.formData.brand_id = res.data.id;
                }
              });
              done();
            } else {
              _this.$toast.file("添加失败！");
              done(false);
            }
          }
        );
      } else {
        done();
      }
    },
  },
  components: {},
};
</script>

<style scoped>
::v-deep .city-popup {
  z-index: 10002 !important;
}
.city-box {
  height: 100%;
  padding: 45px 15px 15px;
  margin-bottom: 0;
  flex-direction: column;
}
.city-box .input {
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.city-box .btns-box {
  position: absolute;
  bottom: 100px;
}
.city-box span {
  margin-right: 0 !important ;
}
.fabu-btn {
  padding: 0 15px;
}
.product-fabu {
  padding: 10px 10px 100px;
}
.top-box {
  padding: 15px;
  border-radius: 15px;
  background: #fff;
}
.van-cell {
  padding: 0;
}
.upload-box {
  margin-bottom: 15px;
}
.upload-btn {
  width: 95px;
  height: 95px;
  background: #f2f2f2;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: #666;
  border-radius: 5px;
}
.address-box {
  display: inline-block;
  background: #f2f2f2;
  font-size: 12px;
  color: #666;
  padding: 5px;
  border-radius: 15px;
  margin-bottom: 15px;
}
.address-box span {
  padding: 0 2px;
}
::v-deep .class-box .van-collapse .van-cell {
  padding: 10px 0;
}
::v-deep .class-box .van-collapse-item__content {
  padding: 0;
}
.collapse-title {
  font-size: 14px;
  font-weight: bold;
}
.class-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.class-item .title {
  font-size: 14px;
  color: #323233;
  width: 20%;
  text-align: right;
  padding: 0 5px;
}
.class-item .right-box {
  font-size: 14px;
  color: #323233;
  width: 80%;
  white-space: nowrap;
  overflow: auto;
}
.class-item .right-box .item {
  display: inline-block;
  margin: 0 5px;
  background: #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
}
.class-item .right-box .item.active {
  background: #1989fa;
  font-weight: bold;
  color: #fff;
}
.botom-box {
  padding: 15px;
  background: #fff;
  border-radius: 15px;
  margin-top: 15px;
}
.botom-box .input-item {
  margin-bottom: 15px;
}
.botom-box .input-item:last-child {
  margin-bottom: 0;
}
::v-deep .botom-box .van-cell__title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
::v-deep .botom-box .van-field__control {
  font-size: 14px;
  font-weight: bold;
}
::v-deep .botom-box .input-item:first-child .van-field__control {
  color: red;
}
.addbox {
  padding: 15px;
  display: flex;
  justify-content: center;
}
</style>
