<template>
	<div class="upload-video">
		<Navbar :title="'添加产品'"></Navbar>
		<!-- <div class="title">产品分类</div>
		<div class="input-item">
			<van-field v-model="formData.category" type="text" placeholder="请选择产品所在分类" />
		</div> -->
		<div class="title">产品名称</div>
		<div class="input-item">
			<van-field v-model="formData.goods_name" autosize type="text" placeholder="请输入产品名称" />
		</div>
		<div class="title">产品图片</div>
		<div class="upload-box">
			<van-uploader v-model="fileList" :after-read="uploadVideo" upload-text="请选择您要上传的产品图片" upload-icon="photo"
				max-count="1" accept="image/*" :before-delete="deleteImg" />
		</div>
		<div class="title">产品介绍</div>
		<div class="editor-box">
			<editor v-if="formData.goods_name" @editor-input="onEditorChange" :value="htmlValue" :menus="['image']"></editor>
		</div>
		<div class="btn-box">
			<button class="btn" @click="onSubmit">提交</button>
		</div>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";

	import Editor from "../../components/editor"
	export default {
		components: {
			Navbar,
			Tabber,
			Editor
		},
		props: {},
		data() {
			return {
				fileList: [],
				formData: {
					category: "",
					goods_name: "",
					images: [],
					content: "",
				},
				userInfo: "",
				htmlValue: '',
				showPicker: false,
				columns: [],
				classValue: "",
				id: "", //商品id

				info: {},
				baseUrl: base.apiURL
			};
		},
		computed: {},
		created() {
			this.userInfo = this.$cookies.get("userInfo");
			this.id = this.$route.query.id
			this.getDetail()
		},
		mounted() {},
		watch: {},
		methods: {
			getDetail() {
				var _this = this;
				var data = {
					id: _this.id
				}
				indexOp("/expo_joiner/show_goods", data).then(res => {
					if (res && res.data.state == 1000) {
						_this.info = res.data.data
						_this.formData.category = res.data.data.category
						_this.formData.goods_name = res.data.data.goods_name
						_this.formData.content = res.data.data.content
						_this.formData.images = res.data.data.images
						_this.htmlValue = res.data.data.content
						if (res.data.data.images.length > 0) {
							res.data.data.images.map(i => {
								var d = {
									url: _this.baseUrl + i
								}
								_this.fileList.push(d)
							})
						}
					} else {
						_this.$toast.fail(res.data.msg)
					}
				})
			},
			onEditorChange(value) {
				this.htmlValue = value
				console.log(value)
			},
			uploadVideo(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						console.log(res);
						_this.formData.images.push(res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//删除以上传图片
			deleteImg(file, detail) {
				this.fileList.splice(detail.index, 1);
				this.formData.images.splice(detail.index, 1);
			},
			onSubmit() {
				var _this = this;
				if (_this.formData.category == "") {
					_this.$toast.fail("产品分类不能为空！");
					return;
				}
				if (_this.formData.goods_name == "") {
					_this.$toast.fail("产品名称不能为空！");
					return;
				}
				if (_this.htmlValue == "") {
					_this.$toast.fail("产品介绍不能为空！");
					return;
				}
				if (_this.formData.images.length == 0) {
					_this.$toast.fail("请至少上传一张产品图片！");
					return;
				}
				_this.formData.content = _this.htmlValue;
				_this.formData.id = _this.id;

				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				addOp("/expo_joiner/edit_goods", _this.formData).then((res) => {
					_this.$toast.clear();
					if (res && res.data.state == 1000) {
						_this.$toast.success("修改成功！");
						setTimeout(() => {
							_this.$router.go(-1);
						}, 800);
					} else {
						_this.$toast.fail(res.data.msg);

					}
				});
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped>
</style>