<template>
	<div class="repair-detail bidding-end">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">维修详情</span>
			<!-- <span @click="onRefundBidding" v-if="info.deposit_pay_status == 1 && userInfo.id == info.uid" style="font-size:14px">申请退款</span> -->
			<van-button type="danger" size="mini" style="width: 60px" @click="onOrderChange"
				v-if="info.service_type == 'take_order'">更换师傅</van-button>
		</div>

		<div class="order-info">
			<div class="title-box">
				<span>订单信息</span>
				<span class="time">发布时间：{{ info.created_at }}</span>
			</div>
			<div class="center">
				<div class="describe">
					<div class="left">
						<p class="item tit" @click="copy(`维修产品名称：${info.name}`)">
							维修产品名称：{{ info.name }}
							<i class="iconfont iconfuzhi" style="font-size: 12px; color: #999; margin-left: 5px"></i>
						</p>
						<p class="item" @click="copy(`维修描述：${info.desc}`)">
							维修描述：{{ info.desc }}
							<i class="iconfont iconfuzhi" style="font-size: 12px; color: #999; margin-left: 5px"></i>
						</p>
						<p class="item">
							商品质保：{{ info.is_time_out == 1 ? "保外" : "保内" }}
						</p>
					</div>
					<div class="right" v-if="info.get_user_info">
						<img :src="info.get_user_info.avatar" alt="" />
						<span>{{ info.get_user_info.nickname }}</span>
					</div>
				</div>
				<div class="img-box" v-if="info.image && info.image.length > 0">
					<span>故障产品照片：</span>
					<div class="imgs">
						<img v-for="(item, index) in info.image" :key="index" :src="item" alt=""
							@click="onPreviewImg(info.image)" />
					</div>
				</div>
				<p class="position">
					<span>地图导航：</span>
					<a v-if="info.lat && info.lng"
						:href="`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${info.lat},${info.lng};title:${info.city};addr:${info.address}&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp`">
						{{ info.full_address }}
					</a>
					<a v-else
						:href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`">
						{{ info.full_address }}
					</a>

					<i class="iconfont iconfuzhi" style="font-size: 12px; color: #999; margin-left: 5px" @click.stop="
              copy(info.full_address)
            "></i>
				</p>
				<div class="position">
					<span>联系电话：</span>{{ info.mobile }}
					<i class="iconfont iconfuzhi" style="font-size: 12px; color: #999; margin-left: 5px"
						@click.stop="copy(info.mobile)"></i>
				</div>
			</div>
			<div style="display:flex;">
				<div class="status-box" v-if="info.deposit_pay_status == 1">
					<span class="status">已预付定金</span>
					<span class="icon"><i class="iconfont iconrenminbi"></i></span>
				</div>
				<div class="status-box" v-else>
					<span class="status">未预付费用</span>
					<span class="icon"><i class="iconfont iconrenminbi"></i></span>
				</div>
				<div class="status-box" style="margin-left:5px;" v-if="info.part_status_alias">
					<span class="status">{{info.part_status_alias}}</span>
					<span class="icon"><van-icon name="underway-o" /></span>
				</div>
			</div>

			<div class="coll" @click="showRepairRule">
				<!-- <i class="iconfont iconduihua"></i> -->
				<span style="font-size: 12px">维修交易规则</span>
			</div>
		</div>


		<div class="bottom-box" v-if="info.is_guarantee == 1">
			<div class="title-box">
				<span>保修信息</span>
			</div>
			<p class="item">
				<span class="tit" style="font-size:13px;">是否保修：</span>
				<span class="price">是</span>
			</p>
			<p class="item">
				<span class="tit" style="font-size:13px;">开始日期：</span>
				<span class="price">{{ info.guarantee_begin_time }} </span>
			</p>
			<p class="item">
				<span class="tit" style="font-size:13px;">结束日期：</span>
				<span class="price">{{ info.guarantee_end_time }} </span>
			</p>
			<p class="item">
				<span class="tit" style="font-size:13px;">备注：</span>
				<span class="price">{{ info.guarantee_remark }} </span>
			</p>
		</div>
		
		<div class="bottom-box" v-if="(info.service_type == 'service_end' || info.service_type == 'user_sure_service') && ( userInfo.id == info.uid || userInfo.identity == 9  ) ">
			<div class="title-box">
				<span>订单金额信息</span>
			</div>
			<p class="item">
				<span class="tit" style="font-size:13px;width:30%;font-weight:bold;">上门费+服务费：</span>
				<span class="price">¥{{info.door_money}}</span>
			</p>
			<p class="item">
				<span class="tit" style="font-size:13px;width:30%;font-weight:bold;">维修费用：</span>
				<span class="price">¥{{ info.service_money }} </span>
			</p>
			<p class="item">
				<span class="tit" style="font-size:13px;width:30%;font-weight:bold;">合计：</span>
				<span class="price">¥{{ parseFloat(info.door_money) + parseFloat(info.service_money) }} </span>
			</p>
		</div>

		<div class="order-process" style="padding: 15px; font-size: 14px">
			不用本单子里的聊天记录私下交易，取消交易资格，下次禁止发布需求信息。
		</div>

		<!-- <div class="map-route">
      <div id="map"></div>
    </div> -->

		<!-- <div
      class="form-box"
      v-if="
        info.deposit_pay_status == 0 && info.service_type == 'order_bidding'
      "
    >
      <div class="title-box">
        <span>预付定金</span>
      </div>
      <van-cell-group>
        <van-field name="is_guarantee" label="预付定金">
          <template #input>
            <van-radio-group
              v-model="depositPay"
              direction="horizontal"
              @change="onRadioChange"
            >
              <van-radio
                :name="index"
                icon-size="16px"
                v-for="(item, index) in depositList"
                :key="index"
                >¥ {{ item.money }}</van-radio
              >
              <van-radio :name="100" icon-size="16px">自定义金额</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group v-if="depositPay == 100">
        <van-field
          label="自定义金额"
          type="number"
          v-model="deposit_money"
          placeholder="自定义金额"
        />
      </van-cell-group>
      <van-button class="btn" type="info" @click="ondepositShow">
        <i class="iconfont iconqueren1"></i> 提交
      </van-button>
    </div> -->

		<div class="order-process" v-if="false">
			<div class="title-box">
				<span>订单进程</span>
			</div>
			<div class="status-box">
				<span :class="service_type_active === item.index ? 'active' : ''" v-for="(item, index) in statusList"
					:key="index" @click="onChangeService(item.index)">{{ item.text }}</span>
			</div>
			<template v-if="info.get_service_log && info.get_service_log.length > 0">
				<div v-for="(item, index) in info.get_service_log" :key="index">
					<template v-if="item.service_type == service_type_active">
						<div class="item">操作时间：{{ item.updated_at }}</div>
						<div class="item" v-if="item.content">
							补充说明：{{ item.content }}
						</div>
						<div class="item" v-else>补充说明：无</div>
						<div class="img-box" v-if="item.images && item.images.length > 0">
							<span>故障产品照片：</span>
							<div class="imgs">
								<img v-for="(src, index) in item.images" :key="index" :src="src" alt=""
									@click="onPreviewImg(item.images)" />
							</div>
						</div>
						<div class="img-box" v-if="item.video_url">
							<span>维修视频：</span>
						</div>
						<div class="video-box" v-if="item.video_url">
							<video :src="item.video_url" style="width: 100%" controls="controls"
								poster="@/assets/image/banner1.jpg"></video>
						</div>
					</template>
				</div>
			</template>
			<div class="item" v-if="info.service_type == 'cancel_order'" style="color: red">
				该订单已取消。
			</div>
			<div class="item" v-if="
          info.service_type == 'order_bidding' && info.deposit_pay_status == 1
        ">
				预付定金：<b style="color: red">{{ info.deposit_money }}</b>元
			</div>
		</div>

		<div class="jingjia" v-if="info.service_id">
			<div class="title-box">
				<span>师傅信息</span>
			</div>
			<div class="list" v-if="serviceInfo">
				<div class="item" v-if="serviceInfo" @click="toShifuDetail(serviceInfo.id)">
					<!-- <div class="status">保证金</div> -->
					<div class="flex-box">
						<div class="left-box">
							<img class="avatar" v-if="serviceInfo.service_info"
								:src="serviceInfo.service_info.user_info.avatar" alt="" />
							<img class="avatar" v-else src="../../assets/image/shifu.png" alt="" />
							<van-tag v-if="serviceInfo.believe > 90" class="tag" color="#ff5722" text-color="#fff">金牌师傅
							</van-tag>
							<van-tag v-else-if="serviceInfo.believe > 80 && serviceInfo.believe < 90" class="tag"
								color="#a7a7a7" text-color="#fff">银牌师傅</van-tag>
							<van-tag v-else-if="serviceInfo.believe < 80" class="tag" color="#7A443A" text-color="#fff">
								铜牌师傅</van-tag>
							<van-rate v-if="serviceInfo.service_info" :value="serviceInfo.service_info.comment_star"
								:size="14" color="#ffd21e" void-icon="star" void-color="#eee" />
						</div>
						<div class="right-box">
							<p class="title">
								{{ serviceInfo.name }}
							</p>
							<p class="describe">
								联系人：{{ serviceInfo.person ? serviceInfo.person : "暂无" }}
							</p>
							<p class="describe">
								联系电话：{{ serviceInfo.phone ? serviceInfo.phone : "暂无" }}
							</p>
							<p class="describe">
								所在地区：{{
                  serviceInfo.province_info
                    ? serviceInfo.province_info.area_name
                    : ""
                }}
								{{
                  serviceInfo.city_info ? serviceInfo.city_info.area_name : ""
                }}
								{{
                  serviceInfo.area_info ? serviceInfo.area_info.area_name : ""
                }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-box" v-if="info.service_type != 'order_bidding' && false">
			<div class="title-box">
				<span>订单私密信息
					<span class="money" style="font-size: 12px; color: red" v-if="info.deposit_pay_status == 1">（
						剩余订金：¥{{ info.deposit_money }} ）</span>
				</span>
			</div>
			<p class="item">
				<span class="tit">上门检修费：</span>
				<span class="price">¥{{ info.door_money }}</span>
			</p>
			<p class="item">
				<span class="tit">维修费用：</span>
				<span class="price" v-if="info.service_money > 0">
					¥{{ info.service_money }}
					<van-tag v-if="info.service_pay_status == 0" type="danger" style="font-size: 10px">
						未支付
					</van-tag>
					<van-tag v-else type="success" style="font-size: 10px">已支付</van-tag>
				</span>
				<span class="price" v-else>暂定</span>
			</p>
			<p class="item">
				<span class="tit">配件费用：</span>
				<span class="price" v-if="info.fittings_money > 0">
					¥{{ info.fittings_money }}
					<van-tag v-if="info.fittings_pay_status == 0" type="danger" style="font-size: 10px">
						未支付
					</van-tag>
					<van-tag v-else type="success" style="font-size: 10px">已支付</van-tag>
				</span>
				<span class="price" v-else>暂定</span>
			</p>
		</div>

		<!-- 支付方式 -->
		<van-overlay :show="payTypeShow" @click="payTypeShow = false">
			<div class="payType-mask" @click.stop>
				<div class="title">请选择支付方式</div>
				<div class="item" @click="changePayType(1)">
					<i class="iconfont iconyue"></i>
					<span>余额支付</span>
					<i v-if="payType == 1" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<div class="item" @click="changePayType(2)">
					<i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
					<span>微信支付</span>
					<i v-if="payType == 2" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<button class="pay-btn" @click="onPay" v-if="info.service_type == 'order_bidding'">
					支付
				</button>
				<button class="pay-btn" @click="onServerPay" v-if="info.service_type == 'service_ing'">
					支付
				</button>
			</div>
		</van-overlay>

		<!-- 预付上门费-支付方式 -->
		<van-overlay :show="depositShow" @click="depositShow = false">
			<div class="payType-mask" @click.stop>
				<div class="title">请选择支付方式</div>
				<div class="item" @click="changePayTypeDeposit(1)">
					<i class="iconfont iconyue"></i>
					<span>余额支付</span>
					<i v-if="depositPayType == 1" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<div class="item" @click="changePayTypeDeposit(2)">
					<i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
					<span>微信支付</span>
					<i v-if="depositPayType == 2" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<button class="pay-btn" @click="onPayDeposit">支付</button>
			</div>
		</van-overlay>
		<!-- <template
      v-if="
        (info.service_type == 'service_ing' && info.service_money > 0) ||
        info.fittings_money > 0
      "
    > -->
		<!-- <div
      class="bottom-btn"
      v-if="
        (info.service_money > 0 && info.service_pay_status == 0) ||
        (info.fittings_money > 0 && info.fittings_pay_status == 0)
      "
    >
      <van-button class="jingjia-btn" type="info" @click="onPayMoney"
        >支付维修配件费</van-button
      >
    </div> -->
		<!-- </template> -->
		<!-- <div class="bottom-btn" v-else-if="info.service_type == 'service_end'">
      <van-button class="jingjia-btn" type="info" @click="onSuccessOrder"
        >确认完成</van-button
      >
    </div> -->
		<!-- <div
      class="bottom-btn"
      v-else-if="info.service_type == 'user_sure_service'"
    >
      <van-button class="jingjia-btn" type="info" @click="onPingjia"
        >评价</van-button
      >
    </div> -->
		<van-dialog v-model="showDiaolg" title="提示" show-cancel-button confirmButtonText="在线支付" cancelButtonText="现金支付"
			@confirm="onOnlinePay" @cancel="onOffPay">
			<div class="text" style="
          width: 100%;
          font-size: 14px;
          text-align: center;
          padding: 15px 0;
        ">
				确认完成订单前，请先支付维修费用：<br />维修费用：<b style="color: red; font-weight: normal">{{ info.service_money }}元</b>
				<br />
				配件费用：<b style="color: red; font-weight: normal">{{ info.fittings_money }}元</b>
			</div>
		</van-dialog>
		<!-- <div class="bottom-btn" v-if="info.service_type == 'arrive_order'">
      <van-button class="jingjia-btn" type="info" @click="onShifuArrive"
        >确认师傅到达</van-button
      >
    </div> -->
		<div class="bottom-btn" v-if="info.service_type == 'order_bidding'"
			style="display: flex; justify-content: space-between">
			<van-button :style="info.deposit_pay_status == 0 ? {width: '30%'} : {width: '100%'}" class="jingjia-btn"
				type="danger" @click="onCancelOrder">取消订单</van-button>
			<van-button style="width: 68%" class="jingjia-btn" type="info" v-if="info.deposit_pay_status == 0"
				@click="payDepositmoney">预付定金</van-button>
		</div>
		<div class="bottom-btn" v-else-if="info.service_type == 'order_draft'">
			<van-button class="jingjia-btn" type="info" @click="toOrderDraft(info.order_no)">继续编辑</van-button>
		</div>
		<div class="bottom-btn" v-else-if="info.service_type == 'waiting_cancel'">
			<van-button class="jingjia-btn" type="info" disabled>订单取消审核中</van-button>
		</div>
		<div class="bottom-btn" v-else-if="info.service_type == 'cancel_order'">
			<van-button class="jingjia-btn" type="info" disabled>订单已取消</van-button>
		</div>
		<div class="bottom-btn" v-else-if="info.service_type != 'order_bidding'">
			<van-button class="jingjia-btn" type="info" @click="toOrderChat(info.uid)">进入聊天室</van-button>
		</div>
		<!-- 评价 -->
		<van-overlay :show="pingjiaShow" @click="pingjiaShow = false">
			<div class="pingjia-mask" @click.stop>
				<!-- <div class="title">评价</div> -->
				<img src="@/assets/image/shifu.png" alt="" />
				<span class="name">{{ serviceInfo.name }}</span>
				<span class="tips">给师傅打分</span>
				<van-rate v-model="rate" size="24" color="#ffd21e" />
				<textarea v-model="pingjiaValue" class="pingjia-input" cols="30" rows="4"
					placeholder="请输入对师傅的评价"></textarea>
				<button class="pay-btn" @click="onSubPingjia">提交</button>
			</div>
		</van-overlay>
		<van-image-preview v-model="previewShow" :images="previewImages">
		</van-image-preview>
		<Tabber></Tabber>
		<notice :xieyiShow.sync="xieyiShow"></notice>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import notice from "../../components/notice";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		getVue,
		payAccount
	} from "../../utils/payAccount";
	export default {
		components: {
			Navbar,
			Tabber,
			notice,
		},
		inject: ["reload"],
		props: {},
		data() {
			return {
				order_no: "", //订单id
				info: "", //订单信息
				statusList: [{
						index: "order_bidding",
						text: "待接单",
					},
					{
						index: "sure_service_user",
						text: "已确认师傅",
					},
					{
						index: "take_order",
						text: "已接单",
					},
					{
						index: "arrive_order",
						text: "师傅已到达",
					},
					{
						index: "user_sure_arrive",
						text: "确认师傅到达",
					},
					{
						index: "service_ing",
						text: "维修中",
					},
					{
						index: "service_end",
						text: "维修完成",
					},
					{
						index: "user_sure_service",
						text: "确认完成",
					},
					{
						index: "appraise_order",
						text: "已评价",
					},
					// {
					//   index: "pay_money",
					//   text: "订单结束",
					// },
					{
						index: "cancel_order",
						text: "订单取消",
					},
				],
				payTypeShow: false,
				payType: 1, //1 余额支付 2微信支付
				bj_money: 0, //待补缴金额
				serviceInfo: "",
				jsApiParameters: "",
				pingjiaShow: false, //评价弹出
				rate: 2, //评分
				pingjiaValue: "", //评价
				showDiaolg: false,
				service_type: "",
				service_type_active: "",
				off_pay: false,
				previewImages: [],
				previewShow: false,
				userInfo: {
					id: "",
				},
				depositPay: "",
				depositList: [],
				deposit_money: "",
				depositShow: false,
				depositPayType: 1, //1 余额支付 2微信支付
				map: "",
				marker: "",
				infoWindow: "",
				xieyiShow: false, //显示维修交易规则
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.order_no = this.$route.query.order_no;
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			} else {
				this.getOrderDetail();
				this.getDeposit();
			}
		},
		mounted() {},
		watch: {},
		methods: {
			//编辑草稿
			toOrderDraft(orderNo) {
				var _this = this;
				_this.$router.push({
					path: "/orderRelease",
					query: {
						order_no: orderNo
					}
				})
			},
			//支付预付定金
			payDepositmoney() {
				const _this = this;
				var data = {
					// money: 1,
					money: _this.info.deposit_money,
					pay_type: "deposit_money_account",
					service_order_id: _this.info.id,
					desc: "预付定金",
				};
				console.log(data);
				// return;
				addOp("/bill_order/pay_service_order", data).then((res) => {
					if (res && res.status == 200 && res.data.state == 1000) {
						_this.getOrderDetail();
					} else {
						if (res.data.state == 9999) {
							payAccount(deposit_money, user_account);
						} else {
							_this.$toast.fail(res.data.msg);
						}
					}
				});
			},
			showRepairRule() {
				this.xieyiShow = true;
			},
			//更换师傅
			onOrderChange() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
				};
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认更换维修师傅？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						_this.outChat();
						addOp("/service_order/change_service", data).then((res) => {
							if (res && res.data == 1) {
								_this.$toast.success("操作成功！");
								_this.setServiceLog();
								setTimeout(() => {
									_this.reload();
								}, 800);
							}
						});
					});
			},

			outChat() {
				var _this = this;
				var data = {
					chat_id: _this.info.order_no + "_" + _this.info.uid,
					repel_uid: _this.info.service_id,
				};
				addOp("/im/repel_chat", data).then((res) => {
					// if (res && res.status == 200 && res.data == 1) {
					// } else {
					//   _this.$toast.fail("该成员以移出！");
					// }
					console.log(res);
				});
			},
			setServiceLog() {
				var _this = this;
				var data = {
					uid: _this.uid,
					order_no: _this.info.order_no,
					service_type: "refund_order",
					identity_type: "user",
				};
				addOp("/service_log/create", data).then((res) => {
					// _this.reload();
					console.log(res);
				});
			},
			toBack() {
				this.$router.go(-1);
			},

			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},

			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
				_this.identity = userInfo.identity;
				this.getOrderDetail();
				this.getDeposit();
			},

			//初始化地图
			initMap() {
				var _this = this;
				//定义地图中心点坐标
				// var userInfo = this.$cookies.get("userInfo");
				var lat = _this.userInfo.latitude;
				var lng = _this.userInfo.longitude;
				var shifu_lat = _this.serviceInfo.service_info.user_info.latitude;
				var shifu_lng = _this.serviceInfo.service_info.user_info.longitude;
				var center = new TMap.LatLng(lat, lng);
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				_this.map = new TMap.Map(document.getElementById("map"), {
					center: center, //设置地图中心点坐标
					zoom: 11, //设置地图缩放级别
				});
				_this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
				_this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
				//初始化marker
				_this.marker = new TMap.MultiMarker({
					id: "marker-layer", //图层id
					map: _this.map,
					styles: {
						//点标注的相关样式
						myMarker: new TMap.MarkerStyle({
							width: 25,
							height: 35,
							anchor: {
								x: 16,
								y: 32,
							},
							src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
						}),
						marker: new TMap.MarkerStyle({
							width: 25,
							height: 25,
							anchor: {
								x: 16,
								y: 32,
							},
							src: "../image/dian.png",
						}),
					},
					geometries: [{
							//点标注数据数组
							id: "demo",
							styleId: "myMarker",
							position: new TMap.LatLng(lat, lng),
							properties: {
								title: "我的位置",
							},
						},
						{
							//点标注数据数组
							id: "demo",
							styleId: "marker",
							position: new TMap.LatLng(shifu_lat, shifu_lng),
							properties: {
								title: "师傅位置",
							},
						},
					],
				});
				_this.marker.on("click", _this.clickHandler);
				//初始化infoWindow
				_this.infoWindow = new TMap.InfoWindow({
					map: _this.map,
					position: new TMap.LatLng(lat, lng),
					offset: {
						x: -3,
						y: -32,
					}, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
				});
				_this.infoWindow.close();
			},

			clickHandler(evt) {
				console.log(evt.geometry.properties.title);
				this.infoWindow.open(); //打开信息窗
				this.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
				this.infoWindow.setContent(evt.geometry.properties.title); //设置信息窗内容
			},

			//获取订单地址坐标
			getOrderLocation(value) {
				var _this = this;
				console.log(_this.value);
				var url = "https://apis.map.qq.com/ws/geocoder/v1/";
				_this
					.$jsonp(url, {
						key: "OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77",
						output: "jsonp",
						address: _this.value,
					})
					.then((json) => {
						// var location = json.result.location;
						console.log(json);
					})
					.catch((err) => {
						_this.$toast.fail("地区搜索失败！");
						console.log(err);
					});
			},

			//支付预付上门费
			ondepositShow() {
				var _this = this;
				if (this.deposit_money == "") {
					_this.$toast.fail("等选择预付金额");
					return;
				}
				if (this.deposit_money && this.deposit_money <= 0) {
					_this.$toast.fail("预付金额必须大于0！");
					return;
				}
				_this.depositShow = true;
				console.log(this.deposit_money);
			},

			uploadOrder() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
					deposit_money: _this.deposit_money,
				};
				addOp("/service_order/update", data).then((res) => {
					if (res && res.data) {
						_this.$toast.success("操作成功！");
						_this.reload();
					} else {
						_this.$toast.fail("操作失败！");
					}
				});
			},

			//选择支付方式
			changePayTypeDeposit(type) {
				this.depositPayType = type;
			},

			//支付
			onPayDeposit() {
				var _this = this;
				var type = this.depositPayType;
				console.log(type);
				// 微信支付
				if (type == 2) {
					_this.$toast.loading({
						message: "提交中...",
						forbidClick: true,
						duration: 0,
					});
					var data = {
						order_no: _this.order_no,
						money: _this.deposit_money,
						pay_type: "deposit_money_wxpay",
					};
					addOp("/pay_order", data).then((res) => {
						_this.$toast.clear();
						if (res && res.status == 200) {
							_this.jsApiParameters = res.data;
							_this.callpayDeposit();
							_this.depositShow = false;
						} else {
							_this.$toast.fail(res.data.error);
						}
					});
				} else {
					// 余额支付
					var _this = this;
					var user_account = parseFloat(
						_this.$cookies.get("userInfo").user_account
					);
					var deposit_money = parseFloat(_this.deposit_money);
					//判断余额是否充足
					if (user_account >= deposit_money) {
						_this.$toast.loading({
							message: "提交中...",
							forbidClick: true,
							duration: 0,
						});
						var data = {
							order_no: _this.order_no,
							money: _this.deposit_money,
							pay_type: "deposit_money_account",
						};
						addOp("/pay_order", data).then((res) => {
							console.log(res);
							_this.$toast.clear();
							if (res && res.status == 200) {
								_this.uploadOrder();
								_this.depositShow = false;
							} else {
								_this.$toast.fail(res.data.error);
							}
						});
					} else {
						_this.$toast.fail("账户余额不足，请改用其他支付方式！");
					}
				}
			},
			//支付
			jsApiCallDeposit() {
				var _this = this;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest",
					_this.jsApiParameters,
					function(res) {
						if (res.err_msg === "get_brand_wcpay_request:ok") {
							// alert("支付成功！");
							_this.uploadOrder();
							_this.depositShow = false;
						} else if (res.err_msg === "get_brand_wcpay_request:fail") {
							alert("支付失败！请重试！");
						}
					}
				);
			},

			callpayDeposit() {
				var _this = this;
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							_this.jsApiCallDeposit,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", _this.jsApiCallDeposit);
						document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCallDeposit);
					}
				} else {
					_this.jsApiCallDeposit();
				}
			},

			//单选
			onRadioChange(name) {
				if (name == 100) {
					this.deposit_money = "";
				} else {
					this.deposit_money = this.depositList[name].money;
				}
				console.log(this.deposit_money);
			},

			//获取定金列表
			getDeposit() {
				var _this = this;
				indexOp("/deposit/index").then((res) => {
					if (res) {
						_this.depositList = res.data;
					}
					console.log(res);
				});
			},

			//分享
			setShare() {
				const _this = this;
				var vip = "";
				if (_this.info.user_vip_info && _this.info.user_vip_info.vip_level == 1) {
					vip = "普通会员";
				} else if (
					_this.info.user_vip_info &&
					_this.info.user_vip_info.vip_level == 2
				) {
					vip = "黄金会员";
				} else if (
					_this.info.user_vip_info &&
					_this.info.user_vip_info.vip_level == 3
				) {
					vip = "钻石会员";
				} else {
					vip = "体验会员";
				}
				var title = "招募制冷维保技师合伙人- " + _this.info.name,
					provinceName = _this.info.province_info ?
					_this.info.province_info.area_name :
					"暂无",
					cityName = _this.info.city_info ? _this.info.city_info.area_name : "暂无",
					areaName = _this.info.area_info ? _this.info.area_info.area_name : "暂无";

				// const shareInfo = {
				//   title:
				//     "招募制冷维保技师合伙人 - " +
				//     this.info.name +
				//     " " +
				//     provinceName +
				//     "-" +
				//     cityName +
				//     "-" +
				//     areaName,
				//   desc: "点确认接单，进单子的聊天室里，报上门检修费，先付款再维修，让师傅更放心！",
				//   link:
				//     base.shareURL +
				//     "/RepairDetail?order_no=" +
				//     this.order_no +
				//     "&invate_uid=" +
				//     this.userInfo.id,
				//   img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				// };

				const shareInfo = {}
				if (_this.info.service_type == 'order_draft') {
					shareInfo.title = `${_this.info.get_user_info.nickname}售后维修服务系统`;
					shareInfo.desc = `聚制冷精英，集行业资源，方便您我他！`;
					shareInfo.link = base.shareURL + "/orderRelease?invate_uid=" + _this.userInfo.id + "&order_no=" + _this
						.order_no;
					shareInfo.img = "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg";
				} else {
					shareInfo.title = "招募制冷维保技师合伙人 - " + _this.info.name + " " + provinceName + "-" + cityName + "-" +
						areaName;
					shareInfo.desc = `点确认接单，进单子的聊天室里，报上门检修费，先付款再维修，让师傅更放心！`;
					shareInfo.link = base.shareURL + "/RepairDetail?order_no=" + _this.order_no + "&invate_uid=" + _this
						.userInfo.id;
					shareInfo.img = "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg";
				}
				wechatConfig(shareInfo);
			},

			//确认师傅
			onChoiceShifu(item) {
				var _this = this;
				_this.serviceInfo = item;
				var deposit_money = parseFloat(_this.info.deposit_money);
				var door_money = parseFloat(item.door_money);
				_this.service_type = "sure_service_user";
				// 未支付订金
				if (_this.info.deposit_pay_status == 0) {
					_this.bj_money = door_money;
					_this.$dialog
						.confirm({
							title: "提示",
							message: "您需要补交上门费用：" + _this.bj_money + "元。",
							confirmButtonText: "确认补交",
						})
						.then(() => {
							_this.payTypeShow = true;
							// on confirm
						})
						.catch(() => {
							// on cancel
						});
					return;
				}
				// 支付订金小于上门费
				if (deposit_money < door_money) {
					_this.bj_money = door_money - deposit_money;
					_this.$dialog
						.confirm({
							title: "提示",
							message: "您需要补交上门费用：" + _this.bj_money + "元。",
							confirmButtonText: "确认补交",
						})
						.then(() => {
							_this.payTypeShow = true;
							// on confirm
						})
						.catch(() => {
							// on cancel
						});
				} else {
					_this.successShifu();
				}
			},

			//支付
			onPay() {
				var _this = this;
				var type = this.payType;
				//微信支付
				if (type == 2) {
					var data = {
						order_no: _this.order_no,
						money: _this.bj_money,
						pay_type: "door_money_add_wxpay",
					};
					addOp("/pay_order", data).then((res) => {
						if (res && res.status == 200) {
							_this.jsApiParameters = res.data;
							_this.callpay();
							_this.payTypeShow = false;
						} else {
							_this.$toast.fail(res.data.error);
						}
					});
				} else {
					//余额支付
					var user_account = parseFloat(
						_this.$cookies.get("userInfo").user_account
					);
					if (user_account >= _this.bj_money) {
						var data = {
							order_no: _this.order_no,
							money: _this.bj_money,
							pay_type: "door_money_add_account",
						};
						addOp("/pay_order", data).then((res) => {
							if (res && res.status == 200) {
								// _this.jsApiParameters = res.data;
								// _this.callpay();
								_this.successShifu();
								_this.payTypeShow = false;
							} else {
								_this.$toast.fail(res.data.error);
							}
						});
					} else {
						_this.$toast.fail("账户余额不足，请改用其他支付方式！");
					}
				}
			},

			//获取订单详情
			getOrderDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					order_no: _this.order_no,
				};
				indexOp("/service_order/show", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.info = res.data;
						_this.setShare();
						_this.service_type_active = res.data.service_type;
						if (res.data.service_id) {
							_this.getServerDetail();
						}
						// _this.getOrderLocation(res.data.province +  res.data.city + res.data.area + res.data.address)
					}
					console.log(res);
				});
			},

			//获取师傅详情
			getServerDetail() {
				var _this = this;
				var data = {
					uid: _this.info.service_id,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status) {
						_this.serviceInfo = res.data;
						_this.initMap();
					}
					console.log(res);
				});
			},

			//添加维修日志
			addServiceLog(item) {
				var _this = this;
				var data = {
					uid: _this.info.uid,
					identity_type: "user",
					order_no: _this.info.order_no,
					service_type: _this.service_type,
				};
				addOp("/service_log/create", data).then((res) => {
					console.log(res);
					_this.reload();
				});
			},

			//选择支付方式
			changePayType(type) {
				this.payType = type;
			},

			//支付
			jsApiCall() {
				var _this = this;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest",
					_this.jsApiParameters,
					function(res) {
						if (res.err_msg === "get_brand_wcpay_request:ok") {
							// alert("支付成功！");
							_this.successShifu();
							_this.payTypeShow = false;
						} else if (res.err_msg === "get_brand_wcpay_request:fail") {
							alert("支付失败！请重试！");
						}
					}
				);
			},
			callpay() {
				var _this = this;
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							_this.jsApiCall,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
						document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
					}
				} else {
					_this.jsApiCall();
				}
			},

			//确认师傅到达
			onShifuArrive() {
				var _this = this;
				_this.service_type = "user_sure_arrive";
				_this.successShifu();
			},

			//更新订单
			successShifu() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
					mobile: _this.info.mobile,
					service_id: _this.serviceInfo.service_id,
					deposit_money: _this.info.deposit_money,
					door_money: _this.serviceInfo.door_money,
					service_type: _this.service_type,
					// is_guarantee: _this.serviceInfo.is_guarantee,
					// guarantee_begin_time: _this.serviceInfo.guarantee_begin_time,
					// guarantee_end_time: _this.serviceInfo.guarantee_end_time,
					accept_door_time: _this.serviceInfo.plan_door_time,
				};
				if (_this.service_type == "service_ing") {
					if (_this.off_pay) {
						//线下支付
						if (_this.info.fittings_money) {
							data.fittings_pay_status = 2;
						}
						if (_this.info.service_money) {
							data.service_pay_status = 2;
						}
					} else {
						if (_this.info.fittings_money) {
							data.fittings_pay_status = 1;
						}
						if (_this.info.service_money) {
							data.service_pay_status = 1;
						}
					}
				}
				addOp("/service_order/update", data).then((res) => {
					if (res && res.data) {
						_this.$toast.success("操作成功！");
						if (_this.service_type != "service_ing") {
							_this.addServiceLog(_this.serviceInfo);
						} else {
							_this.reload();
						}
					} else {
						_this.$toast.fail("操作失败！");
					}
					console.log(res);
				});
			},

			//确认完成订单
			onSuccessOrder() {
				var _this = this;
				_this.service_type = "user_sure_service";
				_this.successShifu();
			},

			//支付费用
			onPayMoney() {
				var _this = this;
				if (_this.info.service_type == "service_ing") {
					_this.service_type = "service_ing";
				} else {
					_this.service_type = _this.info.service_type;
				}
				var yue = parseInt(_this.info.deposit_money);
				var fittings_money = parseInt(_this.info.fittings_money);
				var service_money = parseInt(_this.info.service_money);
				if (yue > 0 && yue >= fittings_money + service_money) {
					_this.successShifu();
				} else {
					_this.showDiaolg = true;
				}
			},

			//在线支付
			onOnlinePay() {
				var _this = this;
				_this.payTypeShow = true;
				console.log("线上支付");
			},

			// 支付维修费用
			onServerPay() {
				var _this = this;
				var type = this.payType;
				var yue = parseInt(_this.info.deposit_money);
				var fittings_money = parseInt(_this.info.fittings_money);
				var service_money = parseInt(_this.info.service_money);
				var data = {
					order_no: _this.order_no,
				};

				//微信支付
				if (type == 2) {
					if (fittings_money > 0 && service_money > 0) {
						data.pay_type = "service_fittings_money_wxpay";
						data.money = fittings_money + service_money - yue;
					} else if (fittings_money > 0 && service_money == 0) {
						data.pay_type = "fittings_money_wxpay";
						data.money = fittings_money - yue;
					} else if (fittings_money == 0 && service_money > 0) {
						data.pay_type = "service_money_wxpay";
						data.money = service_money - yue;
					}
					addOp("/pay_order", data).then((res) => {
						if (res && res.status == 200) {
							_this.jsApiParameters = res.data;
							_this.callpay();
							_this.payTypeShow = false;
						} else {
							_this.$toast.fail(res.data.error);
						}
					});
				} else {
					//余额支付
					var user_account = parseFloat(
						_this.$cookies.get("userInfo").user_account
					);
					var count = fittings_money + service_money;
					if (user_account > count) {
						if (fittings_money > 0 && service_money > 0) {
							data.pay_type = "service_fittings_money_account";
							data.money = fittings_money + service_money - yue;
						} else if (fittings_money > 0 && service_money == 0) {
							data.pay_type = "fittings_money_account";
							data.money = fittings_money - yue;
						} else if (fittings_money == 0 && service_money > 0) {
							data.pay_type = "service_money_account";
							data.money = service_money - yue;
						}
						addOp("/pay_order", data).then((res) => {
							if (res && res.status == 200) {
								// _this.jsApiParameters = res.data;
								// _this.callpay();
								_this.successShifu();
								_this.payTypeShow = false;
							} else {
								_this.$toast.fail(res.data.error);
							}
						});
					} else {
						_this.$toast.fail("账户余额不足，请改用其他支付方式！");
					}
				}
			},

			//线下支付
			onOffPay() {
				var _this = this;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "使用现金支付，平台不做担保！",
					})
					.then(() => {
						_this.off_pay = true;
						_this.successShifu();
						// on close
					})
					.catch(() => {
						// on cancel
					});
			},

			onPingjia() {
				this.pingjiaShow = true;
			},

			// 评价
			onSubPingjia() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				_this.service_type = "appraise_order";
				var data = {
					uid: _this.info.uid,
					order_no: _this.info.order_no,
					comment_desc: _this.pingjiaValue,
					comment_stars: _this.rate,
					service_id: _this.info.service_id,
				};
				addOp("/order_comment/create", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 201) {
						_this.successShifu();
					}
					console.log(res);
				});
			},

			//去对话
			toChat(toUid) {
				var _this = this;
				// var to_uid = _this.info.uid; //聊天对象 uid
				// console.log(_this.$cookies.get("userInfo"))
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					to_uid: toUid,
					from_uid: form_uid,
					chat_type: "normal",
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							to_uid: toUid,
						},
					});
				});
			},

			//订单对话
			toOrderChat(toUid) {
				var _this = this;
				if (_this.info.service_id) {
					var to_uid = _this.info.service_id;
				} else {
					var to_uid = toUid; //聊天对象 uid
				}
				// console.log(_this.$cookies.get("userInfo"))
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: toUid,
						from_uid: form_uid,
						chat_type: "order",
						order_no: _this.order_no,
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							order_no: _this.order_no,
							to_uid: toUid,
						},
					});
				});
			},

			//去师傅详情
			toShifuDetail(uid) {
				this.$router.push({
					path: "/shifuShopDetail",
					query: {
						uid: uid,
					},
				});
			},

			onChangeService(type) {
				this.service_type_active = type;
			},

			//取消订单
			onCancelOrder() {
				var _this = this;
				_this.service_type = "cancel_order";
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认取消该订单？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						var data = {
							order_no: _this.info.order_no,
						};
						addOp("/service_order/cancel_order", data).then((res) => {
							if (res && res.data.state == 1000) {
								_this.$toast.success(res.data.msg);
								_this.reload();
							} else {
								_this.$toast.fail(res.data.msg);
							}
							console.log(res);
						});
						// on close
					})
					.catch(() => {
						// on cancel
					});
			},

			// 订单退款
			onRefundBidding() {
				var _this = this;
				var data = {
					order_no: _this.info.order_no,
					uid: _this.$cookies.get("userInfo").id,
				};
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认要退款吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						addOp("/service_order/refund_money", data).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("操作成功！");
								setTimeout(() => {
									_this.$router.go(-1);
								}, 800);
							}
							console.log(res);
						});
					});
			},

			copy(text) {
				var input = document.createElement("input"); // 直接构建input
				input.value = text; // 设置内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$toast("复制成功");
			},

			onPreviewImg(arr) {
				this.previewImages = arr;
				this.previewShow = true;
				console.log(arr);
			},
		},
	};
</script>

<style scoped>
</style>