<template>
  <div class="order-release">
    <Navbar :title="'发布维修订单'"></Navbar>
    <h2 class="inpit-title">维修产品名称</h2>
    <van-field
      v-model="formData.name"
      autosize
      type="textarea"
      placeholder="请输入维修部件"
      @blur="isOver"
      :readonly="isReadonly"
    />
    <h2 class="inpit-title">商品质保</h2>
    <van-radio-group
      v-model="formData.is_time_out"
      direction="horizontal"
      checked-color="#fff"
      :disabled="isReadonly"
      @change="onTimeoutChange"
    >
      <div class="radio-item">
        <span class="left">保内</span>
        <span class="right">
          <van-radio :name="0">
            <template #icon="props">
              <i
                :class="
                  props.checked
                    ? 'iconfont iconqueren1'
                    : 'iconfont iconcircle-jf'
                "
              ></i>
            </template>
          </van-radio>
        </span>
      </div>
      <div class="radio-item">
        <span class="left">保外</span>
        <span class="right">
          <van-radio :name="1">
            <template #icon="props">
              <i
                :class="
                  props.checked
                    ? 'iconfont iconqueren1'
                    : 'iconfont iconcircle-jf'
                "
              ></i>
            </template>
          </van-radio>
        </span>
      </div>
    </van-radio-group>
    <template v-if="formData.is_time_out == 1">
      <p
        class="inpit-title"
        style="color: #007aff; font-size: 14px; padding: 10px 0"
      >
        为了更好的提供服务，保外订单需额外支付服务费：10元
      </p>
      <!-- <van-radio-group value="1" direction="horizontal" checked-color="#fff">
				<div class="radio-item">
					<span class="left">¥ 10.00</span>
					<span class="right">
						<van-radio name="1" :money="10.00">
							<template #icon="props">
								<i :class="
										props.checked
											? 'iconfont iconqueren1'
											: 'iconfont iconcircle-jf'
									"></i>
							</template>
						</van-radio>
					</span>
				</div>
			</van-radio-group> -->
    </template>
    <h2 class="inpit-title">故障描述</h2>
    <van-field
      v-model="formData.desc"
      autosize
      type="textarea"
      placeholder="请输入故障描述"
      @blur="isOver"
    />
    <h2 class="inpit-title">故障照片/产品照片</h2>
    <van-uploader
      v-model="fileList"
      multiple
      :max-count="3"
      :after-read="afterRead"
      :before-delete="deleteImg"
    />
    <h2 class="inpit-title">故障视频/产品视频</h2>
    <van-uploader
      v-model="videoList"
      multiple
      :max-count="1"
      :after-read="videoAfterRead"
      :before-delete="deleteVideo"
      accept="video/*"
    />
    <h2 class="inpit-title">维修地址</h2>
    <van-cell-group class="input-group">
      <van-field
        v-model="areaText.province"
        placeholder="省"
        readonly
        clickable
        @click="showCityPicker = true"
      />
      <van-field
        v-model="areaText.city"
        placeholder="市"
        readonly
        clickable
        @click="showCityPicker = true"
      />
      <van-field
        v-model="areaText.area"
        placeholder="区"
        readonly
        clickable
        @click="showCityPicker = true"
      />
    </van-cell-group>
    <!-- Laster City -->
    <!-- <van-popup v-model="showCityPicker" round position="bottom">
			<van-area title="选择地址" :area-list="areaList" @cancel="showCityPicker = false" @confirm="onCityConfirm" />
		</van-popup> -->
    <!-- New City -->
    <template v-if="areaList && areaList.length">
      <van-popup v-model="showCityPicker" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="areaList"
          @close="showCityPicker = false"
          @finish="onAreaFinish"
          :field-names="{ text: 'label', value: 'value', children: 'child' }"
        />
      </van-popup>
    </template>

    <div class="address-box">
      <van-field
        v-model="formData.address"
        autosize
        type="textarea"
        placeholder="请输入详细地址"
        @blur="isOver"
      />
      <div class="dingwei" @click="onShowMap">
        <van-icon name="location" />点击选择
      </div>
    </div>
    <h2 class="inpit-title">联系电话</h2>
    <van-field
      placeholder="请输入联系电话"
      v-model="formData.mobile"
      @blur="isOver"
    />
    <h2 class="inpit-title">期待上门时间</h2>
    <van-field
      placeholder="请选择期待上门时间"
      v-model="formData.accept_door_time"
      readonly
      clickable
      @click="showTimePicker = true"
    />
    <van-popup v-model="showTimePicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择上门时间"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showTimePicker = false"
        @confirm="onTimeConfirm"
      />
    </van-popup>

    <h2 class="inpit-title">预付定金</h2>
    <van-radio-group
      v-model="radio"
      direction="horizontal"
      checked-color="#fff"
      @change="onRadioChange"
    >
      <div class="radio-item" v-for="(item, index) in depositList" :key="index">
        <span class="left">¥ {{ item.money }}</span>
        <span class="right">
          <van-radio :name="index" :money="item.money">
            <template #icon="props">
              <i
                :class="
                  props.checked
                    ? 'iconfont iconqueren1'
                    : 'iconfont iconcircle-jf'
                "
              ></i>
            </template>
          </van-radio>
        </span>
      </div>
      <!-- <div class="radio-item">
        <span class="left">自定义金额</span>
        <span class="right">
          <van-radio :name="100">
            <template #icon="props">
              <i
                :class="
                  props.checked
                    ? 'iconfont iconqueren1'
                    : 'iconfont iconcircle-jf'
                "
              ></i>
            </template>
          </van-radio>
        </span>
      </div> -->
      <!-- <div class="radio-item">
        <span class="left">不预付</span>
        <span class="right">
          <van-radio :name="101">
            <template #icon="props">
              <i
                :class="
                  props.checked
                    ? 'iconfont iconqueren1'
                    : 'iconfont iconcircle-jf'
                "
              ></i>
            </template>
          </van-radio>
        </span>
      </div> -->
    </van-radio-group>

    <van-field
      v-if="depositShow"
      v-model="formData.deposit_money"
      type="number"
      placeholder="请输入预付定金"
    />
    <p class="tips">
      支付维修定金，用于后续维修费用抵扣，若取消订单定金将退还至账户余额。
    </p>
    <van-checkbox v-model="checked" class="xieyi-checkbox" icon-size="16px"
      >我已阅读并同意 <b @click.stop="xieyiShow = true">《用户交易规则》</b>
    </van-checkbox>
    <van-overlay
      :show="xieyiShow"
      @click="xieyiShow = false"
      :lock-scroll="false"
    >
      <div class="xieyi-content">
        <div class="title">《维修交易规则》</div>
        <ul>
          <li>
            1、厂商及用户如需维修服务可在平台上查询维修师傅信息、维修部件信息及选定维修师傅与服务等，根据页面提示交易流程双方进行沟通-约谈价格-约定维修日期等相关交易内容。
          </li>
          <li>
            2、在本平台发布维修服务信息，请严格遵守法律法规及社会公德。禁止发布违法虚假诈骗广告信息及二维码和外部链接。
          </li>
          onSubmitadd
          <li>
            3、为保障厂商及用户和师傅各方的利益，各方就维修费用及其他维修事宜应在平台内沟通协商，协商好上门检修费用，厂商或用户要及时支付上门费，然后师傅上门检测，判断设备故障（拍照或视频上传聊天室留证)。如需维修，各方协商好维修费用后厂商或用户付款，师傅进行维修。维修现场需拍照留证，师傅维修完毕，经厂商或用户确认订单维修完成，并对师傅的服务态度及维修技术进行评价。（上门及维修费用在平台冻结48个小时，订单确认完成师傅方可提现）
          </li>
          <li>
            4、特别提示:双方在交易过程中，选择本维修定单中的聊天室进行沟通交流，这样平台会保留记录，若发生相关纠纷可作为凭据以维护各方利益。如双方线下私自交易产生任何纠纷均与本平台无关并取消再次进入本平台交易资格。
          </li>
          <li>
            5、为了平台更好的发展和完善，师傅提现时系统自动扣订单10%的服务费。本平台只是厂商或用户和师傅沟通的桥梁，仅负责监管和留证，不干涉费用及后续保修问题，如厂商及用户与维修师傅之间因此产生的经济纠纷、责任均由双方各自承担，平台只提供交易凭证。
          </li>
          <li>
            6、任何通过本平台进行交易的行为都视为用户已同意以上条款。本平台保留基于用户反馈、流程优化等考虑对交易流程及规则进行修改的权利。
          </li>
        </ul>
      </div>
    </van-overlay>

    <van-button v-if="isover" class="btn" type="info" @click="onSubmit"
      ><i class="iconfont iconjiahao"></i> 确认发布</van-button
    >
    <van-button v-else class="btn" type="info" @click="onSubmit"
      ><i class="iconfont iconjiahao"></i> 确认发布</van-button
    >
    <!-- <Tabber></Tabber> -->
    <map-choice
      v-if="mapShow"
      @config-map="onConfigMap"
      @close-map="onCloseMap"
    ></map-choice>

    <!-- 支付方式 -->
    <van-overlay :show="payTypeShow" @click="payTypeShow = false">
      <div class="payType-mask" @click.stop>
        <div class="title">支付信息</div>
        <div class="tips">
          <van-cell
            style="border: none"
            title="预付定金"
            :value="formData.deposit_money + '元'"
          />
          <van-cell
            v-if="formData.is_time_out == 1"
            style="border: none"
            title="服务费"
            value="10元"
            label="保外服务费"
          />
          <van-cell
            style="border: none"
            title="总计"
            :value="
              formData.is_time_out == 1
                ? formData.deposit_money + 10 + '元'
                : formData.deposit_money + '元'
            "
          />
        </div>
        <div class="item" @click="changePayType(1)">
          <i class="iconfont iconyue"></i>
          <span>余额支付</span>
          <i v-if="payType == 1" class="iconfont iconqueren1 active"></i>
          <i v-else class="iconfont iconqueren1"></i>
        </div>
        <!-- <div class="item" @click="changePayType(2)">
          <i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
          <span>微信支付</span>
          <i v-if="payType == 2" class="iconfont iconqueren1 active"></i>
          <i v-else class="iconfont iconqueren1"></i>
        </div> -->
        <button class="pay-btn" @click="onPay">支付</button>
      </div>
    </van-overlay>
    <back-home></back-home>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import BackHome from "../components/backHome";
import province_list from "../utils/area";
import MapChoice from "./map_choice";
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../api";
import base from "../api/base.js";
import { login } from "../utils/login_toast";
import { wechatConfig } from "../utils/wxConfig";
import { getVue, payAccount } from "../utils/payAccount";
export default {
  components: {
    Tabber,
    Navbar,
    MapChoice,
    BackHome,
  },
  props: {},
  data() {
    return {
      message: "",
      fileList: [],
      videoList: [],
      radio: "",
      timeoutRadio: 0,
      url: base.apiURL,
      loginUrl: base.loginURL,
      showTimePicker: false, //时间选择器
      showCityPicker: false, //地址选择器
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      areaList: null,
      areaText: {
        province: "",
        city: "",
        area: "",
        address: "",
      },
      areaValue: "",

      dateValue: "",
      province: "", //省
      city: "", //市
      area: "", //区
      formData: {
        name: "",
        address: "",
        province: "",
        city: "",
        area: "",
        desc: "",
        mobile: "",
        image: [],
        video: [],
        accept_door_time: "",
        is_time_out: "",
      },
      mapShow: false, //地图组件显示

      depositList: [],
      jsApiParameters: "",
      payTypeShow: false,
      payType: 1, //1 余额支付 2微信支付
      order_no: "",
      checked: false,
      xieyiShow: false,
      depositShow: false, //自定义上门费

      userInfo: {
        id: "",
      },
      orderDraft: "",
      deposit_money: 0, //预付定金

      cascaderValue: "",
      orderdraft_no: "", //草稿订单号

      isover: true,

      isSetShare: true,

      isReadonly: false,
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    this.orderdraft_no = this.$route.query.order_no;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }

    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    this.setShare();
    if (!this.$cookies.isKey("Authorization") && !code) {
      window.location.href = this.loginUrl;
    } else if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    } else {
      this.getDeposit();
      this.getUserInfo();
      this.getServiceRegion();
      this.getOrderDraft();
    }
  },
  mounted() {
    // if (window.history && window.history.pushState) {
    //   // 向历史记录中插入了当前页
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener('popstate', this.goBack, false);
    // }
  },
  watch: {},
  methods: {
    isOver() {
      const _this = this;

      console.log(_this.formData);
      // for (let key in _this.formData) {
      // 	if(_this.formData.key == ''){
      // 		_this.isover = false;
      // 		console.log(_this.isover )
      // 		return;
      // 	}else{
      // 		_this.isover = true;
      // 		console.log(_this.isover )
      // 	}
      // 	// console.log(_this.formData[key] == '') // foo, bar
      // }
      _this.onOrderChange();
    },
    //监听用户点击页面返回按钮
    goBack(e) {
      const _this = this;
      // _this.onOrderChange()
      // _this.$dialog
      // 	.confirm({
      // 		title: "提示",
      // 		message: "您是否保存草稿订单！",
      // 		confirmButtonText: "保存",
      // 	})
      // 	.then(() => {

      // 	})
      // 	.catch(() => {
      // 		// on cancel
      // 	})
      // 	.finally(()=>{
      // 		window.history.back();
      // 		history.pushState(null, null, document.URL);
      // 	});
      // alert("页面离开")
      // ...
    },
    // 获取地区列表
    getServiceRegion() {
      var _this = this;
      indexOp("/area/all_format_child").then((res) => {
        _this.areaList = res.data;
      });
    },
    /**
     * 地区提交
     */
    onAreaFinish({ selectedOptions }) {
      this.showCityPicker = false;
      var areas = selectedOptions.map((option) => option.label);
      this.areaText.province = areas[0];
      this.areaText.city = areas[1];
      this.areaText.area = areas[2];
      // this.areaText.address 	=	areas[0] + areas[1] + areas[2];
      // this.formData.address 	=	areas[0] + areas[1] + areas[2];

      // 2021.1.16 增加地区存储
      this.areaValue = selectedOptions.map((option) => option.value);
      this.formData.province = this.areaValue[0];
      this.formData.city = this.areaValue[1];
      this.formData.area = this.areaValue[2];

      this.showCityPicker = false;
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      var _this = this;
      var api = "/user/show/1";
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          if (res.data.identity == null) {
            _this.$router.push({
              path: "/identity",
            });
            return;
          }
          _this.$cookies.set("identity", res.data.identity);
          _this.$cookies.set("userInfo", res.data);
          _this.userInfo = res.data;
        } else {
          console.log(res);
        }
      });
    },
    /**
     * 监控订单状态
     */
    onOrderChange() {
      var _this = this;
      _this.formData.service_type = "order_draft";
      if (_this.orderDraft && _this.orderDraft.hash_id) {
        _this.formData.hash_id = _this.orderDraft.hash_id;
      }
      addOp("/service_order/create", _this.formData).then((res) => {
        _this.orderDraft = res.data;
        _this.order_no = res.data.order_no;
        if (_this.isSetShare) {
          _this.setShare();
        }
        _this.isSetShare = false;
      });
    },

    //获取订单草稿
    getOrderDraft() {
      var _this = this;
      if (_this.orderdraft_no) {
        var data = {
          order_no: _this.orderdraft_no,
        };
      } else {
        var data = {
          service_type: "order_draft",
          uid: _this.userInfo.id,
        };
      }
      indexOp("/service_order/show", data).then((res) => {
        if (res && res.status == 200 && res.data) {
          if (res.data.service_type != "order_draft") {
            _this.$dialog
              .confirm({
                title: "提示",
                message: "该订单不支持编辑！",
                confirmButtonText: "确定",
              })
              .then(() => {
                _this.$router.push("/");
              })
              .catch(() => {
                _this.$router.push("/");
                // on cancel
              });
            return;
          }
          _this.orderDraft = res.data;
          _this.order_no = res.data.order_no;
          _this.formData.name = res.data.name;
          _this.formData.address = res.data.address;
          _this.formData.area = res.data.area;
          _this.formData.city = res.data.city;
          _this.formData.province = res.data.province;
          if (res.data.area_info) {
            _this.areaText.area = res.data.area_info.area_name;
          }
          if (res.data.city_info) {
            _this.areaText.city = res.data.city_info.area_name;
          }
          if (res.data.province_info) {
            _this.areaText.province = res.data.province_info.area_name;
          }
          _this.formData.mobile = res.data.mobile;
          _this.formData.desc = res.data.desc;
          _this.formData.accept_door_time = res.data.accept_door_time;
          _this.formData.deposit_money = res.data.deposit_money;
          _this.formData.is_time_out = res.data.is_time_out;
          if (res.data.deposit_money != "") {
            _this.depositList.map((item, index) => {
              if (item.money === res.data.deposit_money) {
                _this.radio = index;
              }
            });
          }
          if (res.data.image && res.data.image.length > 0) {
            _this.formData.image = res.data.image;
            for (var key in res.data.image) {
              var data = {
                url: res.data.image[key],
              };
              _this.fileList.push(data);
            }
          }
          if (res.data.uid != _this.userInfo.id) {
            _this.isReadonly = true;
          }
          _this.setShare();
          _this.isOver();
        }
        console.log(_this.formData);
      });
    },

    //分享
    setShare() {
      const _this = this;
      const shareInfo = {};
      if (_this.order_no) {
        shareInfo.title = `${
          _this.orderDraft.get_user_info
            ? _this.orderDraft.get_user_info.nickname
            : ""
        }售后维修服务系统`;
        shareInfo.desc = `聚制冷精英，集行业资源，方便您我他！`;
        shareInfo.link =
          base.shareURL +
          "/orderRelease?invate_uid=" +
          _this.userInfo.id +
          "&order_no=" +
          _this.order_no;
        shareInfo.img =
          "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg";
      } else {
        shareInfo.title = `冷鲜柜网-发布维修订单`;
        shareInfo.desc = `聚制冷精英，集行业资源，方便您我他！`;
        shareInfo.link =
          base.shareURL + "/orderRelease?invate_uid=" + _this.userInfo.id;
        shareInfo.img =
          "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg";
      }
      wechatConfig(shareInfo);
    },

    onTimeoutChange(value) {
      console.log(value);
      this.formData.is_time_out = value;
      this.isOver();
    },
    /**
     * 监控订单提交
     */
    onSubmit() {
      var _this = this;
      console.log(_this.formData, _this.formData.is_time_out === "");
      if (_this.isover) {
        if (_this.formData.name == undefined || _this.formData.name == "") {
          _this.$toast.fail("维修部件不能为空！");
          return;
        }
        if (
          _this.formData.is_time_out === undefined ||
          _this.formData.is_time_out === ""
        ) {
          _this.$toast.fail("请选择商品质保！");
          return;
        }
        if (_this.formData.desc == undefined || _this.formData.desc == "") {
          _this.$toast.fail("故障描述不能为空！");
          return;
        }
        if (_this.formData.image.length == 0) {
          _this.$toast.fail("请上传至少一张故障照片/产品照片！");
          return;
        }
        if (
          _this.formData.province == undefined ||
          _this.formData.province == ""
        ) {
          _this.$toast.fail("维修地址不能为空！");
          return;
        }
        if (_this.formData.area == undefined || _this.formData.area == "") {
          _this.$toast.fail("请选择维修地址！");
          return;
        }
        if (_this.formData.city == undefined || _this.formData.city == "") {
          _this.$toast.fail("请选择维修地址！");
          return;
        }
        if (
          _this.formData.address == undefined ||
          _this.formData.address == ""
        ) {
          _this.$toast.fail("详细地址不能为空！");
          return;
        }
        if (_this.formData.mobile == undefined || _this.formData.mobile == "") {
          _this.$toast.fail("联系电话不能为空！");
          return;
        }
        var myreg = /^[0-9]{11}$/;
        if (!myreg.test(_this.formData.mobile)) {
          _this.$toast.fail("手机号格式不正确");
          return false;
        }
        if (_this.formData.accept_door_time == undefined) {
          _this.$toast.fail("期待上门时间不能为空！");
          return;
        }
        if (_this.formData.deposit_money && _this.formData.deposit_money <= 0) {
          _this.$toast.fail("预付定金必去大于0元！");
          return;
        }
        if (
          _this.formData.deposit_money == undefined ||
          _this.formData.deposit_money == ""
        ) {
          _this.$toast.fail("请选择预付定金！");
          return;
        }
        if (!_this.checked) {
          _this.$toast.fail("请先阅读并同意《用户交易规则》");
          return;
        }
        // if (_this.formData.deposit_money == undefined || _this.formData.deposit_money == "") {
        // 	_this.$toast.loading({
        // 		message: "提交中...",
        // 		forbidClick: true,
        // 		duration: 0,
        // 	});
        // 	_this.formData.service_type = "order_bidding";
        // 	if (_this.orderDraft) {
        // 		_this.formData.order_no = _this.orderDraft.order_no;
        // 		addOp("/service_order/update", _this.formData).then((res) => {
        // 			_this.$toast.clear();
        // 			if (res && res.status == 200) {
        // 				_this.order_no = res.data.order_no;
        // 				alert("下单成功！");
        // 				_this.$router.push({
        // 					path: "/userOrderList",
        // 				});
        // 			}
        // 		});
        // 	} else {
        // 		addOp("/service_order/create", _this.formData).then((res) => {
        // 			_this.$toast.clear();
        // 			if (res && res.status == 201) {
        // 				_this.order_no = res.data.order_no;
        // 				alert("下单成功！");
        // 				_this.$router.push({
        // 					path: "/userOrderList",
        // 				});
        // 			}
        // 		});
        // 	}
        // } else {
        _this.payTypeShow = true;
        // }
      } else {
        if (_this.formData.name == undefined || _this.formData.name == "") {
          _this.$toast.fail("维修部件不能为空！");
          return;
        }
        if (
          _this.formData.is_time_out === undefined ||
          _this.formData.is_time_out === ""
        ) {
          _this.$toast.fail("请选择商品质保！");
          return;
        }
        if (_this.orderDraft) {
          _this.formData.service_type = "order_draft";
          _this.formData.hash_id = _this.orderDraft.hash_id;
          addOp("/service_order/create", _this.formData).then((res) => {
            _this.$toast.clear();
            if (res && res.status == 201) {
              _this.order_no = res.data.order_no;
              alert("保存成功！");
            }
          });
        }
      }
    },

    //单选
    onRadioChange(name) {
      console.log(name);
      if (name == 100) {
        this.depositShow = true;
        this.formData.deposit_money = "";
      } else if (name == 101) {
        this.formData.deposit_money = "";
      } else {
        this.formData.deposit_money = parseFloat(this.depositList[name].money);
        this.deposit_money = parseFloat(this.depositList[name].money);
        this.depositShow = false;
      }
      this.isOver();
    },

    //地图选择
    onConfigMap(data) {
      this.mapShow = false;
      if (data) {
        // this.formData.address = data.poiaddress + data.poiname;
        this.formData.address = data.poiname;
        this.formData.lat = data.latlng.lat;
        this.formData.lng = data.latlng.lng;
      }
      console.log(data);
    },

    //关闭地图选择
    onCloseMap() {
      this.mapShow = false;
    },

    //时间选择确认
    onTimeConfirm(value) {
      this.showTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      var h = value.getHours();
      var mm = value.getMinutes();
      var s = value.getSeconds();
      this.formData.accept_door_time =
        y + "-" + m + "-" + d + " " + h + ":" + mm;
      this.isOver();
    },

    //地区选择确认
    onCityConfirm(value, index) {
      this.showCityPicker = false;
      this.formData.province = value[0].name;
      this.formData.city = value[1].name;
      this.formData.area = value[2].name;
      console.log(value);
      // if(this.formData.address == ""){
      this.formData.address = value[0].name + value[1].name + value[2].name;
      // }
      this.isOver();
      this.mapShow = true;
    },

    onShowMap() {
      this.mapShow = true;
    },

    //获取定金列表
    getDeposit() {
      var _this = this;
      indexOp("/deposit/index").then((res) => {
        if (res) {
          _this.depositList = res.data;
        }
        console.log(res);
      });
    },

    /**
     * 图片上传
     * @param {Object} file
     */
    afterRead(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.image.push(_this.url + res.data.url);
          this.isOver();
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    /**
     * 删除已上传图片
     * @param {Object} file
     * @param {Object} detail
     */
    deleteImg(file, detail) {
      this.fileList.splice(detail.index, 1);
      this.formData.image.splice(detail.index, 1);
    },
    /**
     * 视频上传
     * @param {Object} file
     */
    videoAfterRead(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadFile(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.video.push(_this.url + res.data.url);
          this.isOver();
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    /**
     * 删除已上传视频
     * @param {Object} file
     * @param {Object} detail
     */
    deleteVideo(file, detail) {
      this.videoList.splice(detail.index, 1);
      this.formData.video.splice(detail.index, 1);
    },
    /**
     * 更新支付类型
     * @param {Object} type
     */
    changePayType(type) {
      //切换支付类型
      this.payType = type;
    },
    /**
     * 支付
     */
    onPay() {
      var _this = this;
      var type = this.payType;
      // 微信支付
      if (type == 2) {
        _this.$toast.loading({
          message: "提交中...",
          forbidClick: true,
          duration: 0,
        });
        _this.formData.order_no = _this.order_no;
        _this.formData.service_type = "order_bidding";
        addOp("/service_order/update", _this.formData).then((res) => {
          if (res && res.status == 200) {
            var data = {
              order_no: _this.order_no,
              money: _this.formData.deposit_money,
              pay_type: "deposit_money_wxpay",
            };
            addOp("/pay_order", data).then((res) => {
              _this.$toast.clear();
              if (res && res.status == 200) {
                _this.jsApiParameters = res.data;
                _this.callpay();
                _this.payTypeShow = false;
              } else {
                _this.$toast.fail(res.data.error);
              }
            });
          }
          console.log(res);
        });
      } else {
        // 余额支付
        var _this = this;
        var user_account = parseFloat(
          _this.$cookies.get("userInfo").user_account
        );
        var deposit_money = parseFloat(_this.formData.deposit_money);
        //判断余额是否充足
        // if (user_account >= deposit_money) {
        _this.$toast.loading({
          message: "提交中...",
          forbidClick: true,
          duration: 0,
        });
        if (_this.formData.is_time_out == 1) {
          deposit_money += 10; //如果选择保外，额外增加10元服务费
        }
        // _this.formData.order_no = _this.order_no;
        // _this.formData.service_type = "order_bidding";
        // _this.formData.service_type = "order_draft";
        if (_this.orderDraft) {
          _this.formData.hash_id = _this.orderDraft.hash_id;
        }
        addOp("/service_order/create", _this.formData).then((res) => {
          if (res && res.status >= 200) {
            _this.order_no = res.data.order_no;
            var data = {
              // money: 1,
              service_order_id: res.data.id,
              money: deposit_money,
              pay_type: "deposit_money_account",
              desc: "预付定金",
            };
            console.log(data);
            // return;
            addOp("/bill_order/pay_service_order", data).then((res) => {
              console.log(res);
              _this.$toast.clear();
              if (res && res.status == 200 && res.data.state == 1000) {
                // _this.formData= {
                // 	name: "",
                // 	address: "",
                // 	province: "",
                // 	city: "",
                // 	area: "",
                // 	desc: "",
                // 	mobile: "",
                // 	image: [],
                // 	accept_door_time: "",
                // 	is_time_out: 0,
                // }
                alert("下单成功！");
                _this.$router.push({
                  path: "/",
                });
                _this.payTypeShow = false;
              } else {
                if (res.data.state == 9999) {
                  payAccount(deposit_money, user_account);
                } else {
                  _this.$toast.fail(res.data.msg);
                }
                // if(res.data.)
              }
            });
          }
        });
        // } else {
        //   //余额不足
        //   payAccount(deposit_money,user_account,_this)
        // }
      }
    },

    //支付
    jsApiCall() {
      var _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        _this.jsApiParameters,
        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            alert("下单成功！");
            _this.$router.push({
              path: "/userOrderList",
            });
            _this.payTypeShow = false;
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            alert("已取消支付！");
            // _this.$router.push({
            //   path: "/userOrderList",
            // });
            _this.payTypeShow = false;
          }
        }
      );
    },
    /**
     * 支付回调
     */
    callpay() {
      var _this = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            _this.jsApiCall,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
          document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
        }
      } else {
        _this.jsApiCall();
      }
    },

    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.getDeposit();
    },

    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    isKeep() {
      const _this = this;
      let n = 0;
      console.log(_this.formData);
      for (let key in _this.formData) {
        if (_this.formData.key != "") {
          n++;
        }
      }
      if (n > 2) {
        // _this.$dialog
        // .confirm({
        // 	title: "提示",
        // 	message: "您有未填写完的订单，是否保存？",
        // 	confirmButtonText: "保存",
        // })
        // .then(() => {
        // _this.onOrderChange()
        // })
        // .catch(() => {
        // 	// on cancel
        // });
      }
    },
  },
  destroyed() {
    const _this = this;
    _this.isKeep();
    // window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))

    // window.removeEventListener('popstate', this.goBack, false);

    // _this.$dialog
    // 	.confirm({
    // 		title: "提示",
    // 		message: "您是否保存草稿订单！",
    // 		confirmButtonText: "保存",
    // 	})
    // 	.then(() => {
    // 		_this.onOrderChange()
    // 	})
    // 	.catch(() => {
    // 		// on cancel
    // 	});
    // console.log(123)
    // alert("页面离开")
  },
};
</script>

<style scoped></style>
