<template>
	<div class="changshang-index shop-detail paddingTabbar" style="background-color: #e8e8e8;">
		<Navbar :title="info.name"></Navbar>
		
		<!-- <div class="nav-box">
      <router-link
        :to="{ path: '/changshangIndex', query: { uid: uid } }"
        class="item"
        >网站首页</router-link
      >
      <router-link
        :to="{ path: '/productList', query: { uid: uid } }"
        class="item"
        >产品展示</router-link
      >
      <router-link :to="{ path: '/newsList', query: { uid: uid } }" class="item"
        >资讯中心</router-link
      >
      <router-link
        :to="{ path: '/contactUs', query: { uid: uid } }"
        class="item"
        >联系我们</router-link
      >
      <router-link :to="{ path: '/Shifu' }" class="item">服务网点</router-link>
      <router-link :to="{ path: '/shifuNearby' }" class="item"
        >附近网点</router-link
      >
    </div> -->

		<div class="swiper-box">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
				<van-swipe-item v-for="(item, index) in slideList" :key="index">
					<a :href="item.link">
						<img class="banner-img" :src="url + item.cover" alt="" />
					</a>
				</van-swipe-item>
			</van-swipe>
		</div>
		
		<div class="96vw" style="width:96vw;margin:0 auto;margin-top:10px;border-radius: 5px;overflow: hidden;">
			<div class="title-box">
				<span class="text">产品展示</span>
				<block v-if="isMe()">
					<router-link :to="{ path: '/productAdd', query: { uid: info.uid } }" class="more">添加产品</router-link>
				</block>
				<block v-else>
					<router-link :to="{ path: '/productList', query: { uid: info.uid } }" class="more">更多</router-link>
				</block>
			</div>
			<div class="list">
				<router-link :to="{ path: '/productDetail', query: { id: item.id } }" class="item"
					v-for="(item, index) in goodsList" :key="index">
					<img :src="url + item.image" alt="" />
					<span class="title">{{ item.goods_name }}</span>
				</router-link>
				<div class="empty-box" v-if="goodsList.length == 0">暂无产品数据 ~</div>
			</div>
			<div class="title-box">
				<span class="text">公司简介</span>
			</div>
			<div class="desc" v-if="info.desc">
				{{ info.desc }}
			</div>
			<div class="desc" v-else>
				<div class="empty-box">暂无数据 ~</div>
			</div>
			<!-- <div class="title-box">
				<span class="text">资讯中心</span>
				<router-link :to="{ path: '/newsList', query: { uid: info.uid } }" class="more">更多</router-link>
			</div>
			<div class="desc news-list">
				<div class="list">
					<div class="news-item" v-for="(item, index) in newsList" :key="index" @click="toNewsDetail(item.id)">
						<img :src="url + item.thumb" alt="" />
						<div class="right">
							<p class="title">{{ item.name }}</p>
							<p class="time">
								点赞：{{ item.zan_count }} 浏览量：{{ item.hits }}
							</p>
							<p class="time">发布时间：{{ item.created_at }}</p>
						</div>
					</div>
					<div class="empty-box" v-if="newsList.length == 0">暂无数据 ~</div>
				</div>
			</div> -->
			<div class="title-box">
				<span class="text">专属顾问</span>
			</div>
			<div class="desc shifu" style="padding:0;">
				<div class="list" style="display:block;">
					<div class="item" v-for="(item, index) in shifuList" :key="index" @click="toShifuDetail(item.uid)"
						v-if="item.name && item.service_info.user_info" style="width:100%;display:block;padding:15px;">
						<div class="vip-box" v-if="item.service_info.vip_level">
							<template v-if="item.service_info.vip_level.vip_level == 1">
								<i class="iconfont iconhuiyuandengji" style="color: #5f3e3e"></i>
								<span>普通会员</span>
							</template>
							<template v-else-if="item.service_info.vip_level.vip_level == 2">
								<i class="iconfont iconhuiyuandengji"></i>
								<span>黄金会员</span>
							</template>
							<template v-else-if="item.service_info.vip_level.vip_level == 3">
								<i class="iconfont iconhuiyuandengji" style="color: #bd419d"></i>
								<span>钻石会员</span>
							</template>
						</div>
						<div class="vip-box" v-else>
							<template>
								<i class="iconfont iconhuiyuandengji" style="color: #d8d8d8"></i>
								<span>体验会员</span>
							</template>
						</div>
						<div class="flex-box">
							<div class="left-box">
								<img class="avatar" v-if="item.thumb" :src="url + item.thumb" alt="" />
								<img v-else class="avatar" src="http://api.zglxgw.com/static/asset/index/images/lunbo.jpg"
									alt="" />
								<!-- <span class="name">{{item.name}}</span> -->
								<van-tag v-if="item.believe > 90" class="tag" color="#ff5722"
									text-color="#fff">金牌师傅</van-tag>
								<van-tag v-else-if="item.believe > 80 && item.believe < 90" class="tag" color="#a7a7a7"
									text-color="#fff">银牌师傅</van-tag>
								<van-tag v-else-if="item.believe < 80" class="tag" color="#7A443A"
									text-color="#fff">铜牌师傅</van-tag>
							</div>
							<div class="right-box">
								<p class="title">{{ item.name }}</p>
								<p class="mobile" v-if="userInfo && userInfo.user_vip_info">
									<i class="iconfont iconshouji"></i>
									{{ item.phone }}【{{ item.order_count }}人使用过】
								</p>
								<p class="mobile" v-else>
									<i class="iconfont iconshouji"></i>
									<span v-if="item.phone">{{ item.phone.toString().replace(reg, "$1****$2") }}【{{
			            item.order_count
			          }}人使用过】</span>
									<span v-else>暂无</span>
								</p>
								<p class="describe">
									维修范围：{{ item.scope_desc ? item.scope_desc : "暂无" }}
								</p>
								<p class="describe">
									服务区域：{{ item.service_desc ? item.service_desc : "暂无" }}
								</p>
								<van-rate v-if="item.service_info" :value="item.service_info.comment_star" :size="15"
									color="#ffd21e" void-icon="star" void-color="#eee" />
							</div>
						</div>
						<p class="position" v-if="item.address">
							<i class="iconfont iconweizhi"></i>
							{{ item.address }}
						</p>
					</div>
					<div class="empty-box" v-if="shifuList.length == 0">暂无数据 ~</div>
				</div>
			</div>
			<div class="title-box">
				<span class="text">联系我们</span>
			</div>
			<div class="desc news-list">
				<div class="contact-us">
					<p>
						{{ info.name }}
					</p>
					<p>
						<span>联系人：</span>
						{{ info.person }}
					</p>
					<div class="" style="display: flex; align-items: center; margin-bottom: 10px">
						<span style="min-width: 80px">联系方式：</span>
						<div class="btns">
							<a style="color: blue; text-decoration: underline;width:70px;"
								v-if="userInfo && userInfo.user_vip_info && info.phone" :href="`tel:${info.phone}`">
								<span>电话联系</span>
							</a>
							<a v-else :href="`javascript:;`" @click.stop="showVIp"
								style="color: blue; text-decoration: underline;width:70px;">
								<span>电话联系</span>
							</a>
							<a href="javascript:;" @click="toChat"
								style="color: blue; text-decoration: underline;width:70px;">在线联系</a>
							<a href="javascript:;" @click="toChat"
								style="color: blue; text-decoration: underline;width:70px;">投诉管理</a>
						</div>
					</div>
					<a
						:href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`">
						<span>厂商地址：</span>
						{{ info.address }}
					</a>
					<div class="btns">
						<!-- <a  class="btn" :href="info.web_url ? info.web_url : 'javascript:;'">
			    <i class="iconfont iconwangzhan1"></i>
			    <span>公司网站</span>
			  </a> -->
						<router-link class="btn" :to="{ path: '/Shifu' }">
							<i class="iconfont iconwangdian"></i>
							<span>服务网点</span>
						</router-link>
						<router-link class="btn" :to="{ path: '/shifuNearby' }">
							<i class="iconfont iconwangdian"></i>
							<span>附近网点</span>
						</router-link>
					</div>
				</div>
			</div>
			<div class="title-box">
				<span class="text">荣誉证书</span>
			</div>
			<div class="desc" v-if="info.hor_img">
				<img class="hor-img" v-for="(item, index) in info.hor_img" :key="index" :src="item" alt="" />
				<div class="empty-box" v-if="info.hor_img.length == 0">暂无数据 ~</div>
			</div>
			<div class="desc" v-else>
				<div class="empty-box">暂无数据 ~</div>
			</div>
		</div>
		
		<!-- <div class="logo">
      <img :src="url + info.thumb" alt="">
    </div> -->
		<!-- <div class="btns" v-if="userInfo && userInfo.id == info.uid">
      <router-link :to="{path:'/editShopinfo'}" class="btn">
        <van-icon name="edit" />
        <span>修改信息</span>
      </router-link>
    </div> -->
		<!-- <div class="btns">
      <a v-if="userInfo && userInfo.user_vip_info" :href="`tel:${info.phone}`" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <a v-else :href="`tel:4006912808`" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <div class="btn" @click="toChat">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div> -->
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		props: {},
		components: {
			Tabber,
			Navbar
		},
		data() {
			return {
				url: base.apiURL,
				loginURL: base.loginURL,
				slideList: "", //轮播列表
				userInfo: {
					id: "",
				},
				uid: "",
				info: "",
				goodsList: [], //产品列表
				newsList: [],
				shifuList: [],

				reg: /(\d{3})\d{4}(\d{4})/,
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.uid = this.$route.query.uid;
			this.getShopDetail();
			this.getSlide();
			this.getNewsList();
			this.getShifu();
		},
		mounted() {},
		watch: {},
		methods: {
			isMe(){
				var uinfo 	=	this.$cookies.get("userInfo");
				if(!uinfo){
					return false;
				}
				if(!this.uid){
					return false;
				}
				return uinfo.id == this.uid;
			},
			showVIp() {
				this.$dialog.confirm({
						title: '提示',
						confirmButtonText: "查看会员权益",
						message: '成为冷鲜柜黄金会员即可直接查看拨打电话',
					})
					.then(() => {
						this.$router.push("/vip")
						// on confirm
					})
					.catch(() => {
						// on cancel
					});
			},
			//去店铺详情
			toShifuDetail(uid) {
				if (uid == this.userInfo.id) {
					this.$router.push({
						path: "/myShop",
						query: {
							uid: uid,
						},
					});
				} else {
					this.$router.push({
						path: "/shifuShopDetail",
						query: {
							uid: uid,
						},
					});
				}
			},
			//获取师傅列表
			getShifu() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var api = "/company_user/service_user/" + _this.uid;
				indexOp(api).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200 && res.data) {
						_this.shifuList = res.data;
					}
					console.log(res);
				});
			},
			//获取资讯列表
			getNewsList() {
				var _this = this;
				var data = {
					service_id: this.uid,
					limit: 5
				};
				indexOp("/news/index", data).then((res) => {
					if (res && res.status == 200 && res.data) {
						_this.newsList = res.data.data;
					}
					console.log(res);
				});
			},
			//去资讯详情
			toNewsDetail(id) {
				var _this = this;
				this.$router.push({
					path: "/newDetail",
					query: {
						id: id,
					},
				});
			},
			//获取轮播
			getSlide() {
				var _this = this;
				indexOp("/slide/index?cid=1").then((res) => {
					if (res && res.status == 200) {
						_this.slideList = res.data;
					}
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-` + this.info.name,
					desc: "聚制冷精英，集行业资源，方便您我他！",
					link: base.shareURL +
						"/changshangIndex?uid=" +
						this.info.uid +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			//获取店铺详情
			getShopDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				var data = {
					uid: _this.uid,
				};
				indexOp("/company_user/show/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.info = res.data;
						_this.getProductList(res.data.uid);
						_this.setShare();
					}
					console.log(res);
				});
			},
			getProductList(id) {
				var _this = this;
				var data = {
					id: id,
					limit: 6
				};
				indexOp("/goods/index", data).then((res) => {
					if (res && res.status == 200 && res.data) {
						_this.goodsList = res.data;
					}
					console.log(res);
				});
			},
			//去对话
			toChat() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert()
					return;
				}
				var to_uid = _this.info.uid; //聊天对象 uid
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: to_uid,
						from_uid: form_uid,
						chat_type: "normal",
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							to_uid: to_uid,
						},
					});
				});
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped>
	
	.title-box {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		margin-top:10px;
	}
	
	.desc,.list {
		background-color: #fff;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	
</style>