<template>
  <!-- 用户设置 -->
  <div class="edit-userinfo">
    <Navbar :title="'个人信息'"></Navbar>
    <van-form @submit="onSubmit">
      <van-field name="uploader" label="设置头像">
        <template #input>
          <van-uploader
            v-model="uploader"
            :after-read="uploadImg"
            multiple
            :max-count="1"
          />
        </template>
      </van-field>
      <van-field
        v-model="formData.truename"
        name="truename"
        label="真实姓名"
        placeholder="请输入真实姓名"
      />
      <van-field
        v-model="formData.mobile"
        type="text"
        name="mobile"
        label="电话号码"
        placeholder="请输入电话号码"
      />
      <van-field name="sex" label="性别">
        <template #input>
          <van-radio-group
            v-model="formData.sex"
            direction="horizontal"
            icon-size="16"
          >
            <van-radio :name="1">男</van-radio>
            <van-radio :name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="area"
        :value="value"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        v-model="formData.password"
        name="password"
        type="password"
        label="登录密码"
        placeholder="请设置登录密码，用于账号登录"
      />
      <van-field
        v-model="formData.confirm_password"
        name="confirm_password"
        type="password"
        label="确认密码"
        placeholder="请再次输入登录密码"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import province_list from "../utils/area";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
import { wechatConfig } from "../utils/wxConfig";
import wx from "weixin-js-sdk";
const wxChat = wx;
export default {
  props: {},
  data() {
    return {
      formData: {
        truename:"",
        mobile:"",
        sex:"",
      },
      value: "",
      url: base.apiURL,
      showArea: false,
      areaList: province_list, // 数据格式见 Area 组件文档
      uploader: [],
      locationInfo: "",
    };
  },
  computed: {},
  created() {
    wechatConfig();
    var userInfo = this.$cookies.get("userInfo");
    if (userInfo) {
      this.formData.truename = userInfo.truename;
      this.formData.mobile = userInfo.mobile;
      this.formData.sex = userInfo.sex;
      this.formData.province = userInfo.province;
      this.formData.city = userInfo.city;
      this.formData.area = userInfo.area;
      this.formData.avatar = userInfo.avatar;
      this.formData.latitude = userInfo.latitude;
      this.formData.longitude = userInfo.longitude;
      if (userInfo.avatar) {
        var url = {
          url: userInfo.avatar,
          isImage: true
        };
        this.uploader.push(url);
      }
      if (userInfo.province && userInfo.city && userInfo.area) {
        this.value =
          userInfo.province + "-" + userInfo.city + "-" + userInfo.area;
      }
    }
  },
  mounted() {
    var _this = this;
    this.locationInfo = this.$cookies.get("LocationInfo");
    // console.log(this.locationInfo);
  },
  watch: {},
  methods: {
    onSubmit() {
      var _this = this;
      var locationInfo = this.$cookies.get("LocationInfo");
      console.log(locationInfo);
      if (_this.formData.truename == "") {
        _this.$toast.fail("真实姓名不能为空！");
        return;
      }
      if (_this.formData.mobile == "") {
        _this.$toast.fail("手机号码不能为空！");
        return;
      }
      if (_this.value == "") {
        _this.$toast.fail("地址不能为空！");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      if (locationInfo) {
        _this.formData.latitude = locationInfo.latitude;
        _this.formData.longitude = locationInfo.longitude;
      }
      addOp("/user/update/1", _this.formData).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.$cookies.set("userInfo", res.data);
          _this.$toast.success("操作成功！");
          setTimeout(() => {
            _this.$router.push("/user")
          }, 800);
        }else{
          if(res.data.errors){
            for(var key in res.data.errors){
              _this.$toast.fail(res.data.errors[key][0]);
            }
          }else{
            _this.$toast.fail(res.data.mag);
          }
        }
      });
    },
    onConfirm(values) {
      console.log(values);
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      this.formData.province = values[0].name;
      this.formData.city = values[1].name;
      this.formData.area = values[2].name;
      this.showArea = false;
    },
    uploadImg(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.avatar = _this.url + res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
  },
  components: { Navbar, Tabber },
};
</script>

<style scoped >
</style>
