<template>
  <div class="upload-video">
    <Navbar :title="'添加资讯'"></Navbar>
    <div class="title">资讯标题</div>
    <div class="input-item">
      <van-field
        v-model="formData.name"
        autosize
        type="text"
        placeholder="请输入资讯标题"
      />
    </div>
    <div class="title">封面图</div>
    <div class="upload-box">
      <van-uploader
        v-model="fileList"
        :after-read="uploadVideo"
        upload-text="请选择您要上传的封面图"
        upload-icon="photo"
        max-count="1"
        accept="image/*"
      />
    </div>
    <div class="title">资讯内容</div>
    <div class="editor-box">
        <editor @editor-input="onEditorChange"></editor>
    </div> 
    <div class="btn-box">
      <button class="btn" @click="onSubmit">提交</button>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";

import Editor from "../../components/editor"
export default {
  components: {
    Navbar,
    Tabber,
    Editor
  },
  props: {},
  data() {
    return {
      fileList: [],
      formData: {},
      userInfo: "",
      htmlValue: '',
    };
  },
  computed: {},
  created() {
    this.userInfo = this.$cookies.get("userInfo");
    var _this = this;

    var code = _this.getUrlKey("code");
    var state = _this.getUrlKey("state");
    var token = _this.$cookies.get("Authorization");
    if (!token && !code) {
      loginAlert()
      return;
    } else if (!token && code) {
      login(this.getLoginUser);
      return;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    onEditorChange(value) {
        console.log(value)
      this.htmlValue = value
    },
    uploadVideo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          console.log(res);
          _this.formData.thumb = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
      console.log(file);
    },
    onSubmit() {
      var _this = this;
      if (_this.formData.name == undefined) {
        _this.$toast.fail("资讯标题不能为空");
        return;
      }
      if (_this.formData.thumb == undefined) {
        _this.$toast.fail("请选择要上传的封面图！");
        return;
      }
      if(_this.htmlValue == ""){
        _this.$toast.fail("资讯内容不能为空！");
        return;
      }
      this.formData.content = _this.htmlValue
      console.log(_this.htmlValue)
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      addOp("/news/store", _this.formData).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 201) {
          _this.$toast.success("操作成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        }
        console.log(res);
      });
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>

<style scoped >
</style>
