<template>
  <!-- 店铺详情 -->
  <div class="shop-detail changshang-detail">
    <Navbar :title="info.name"></Navbar>
    <!-- <div class="swiper-box">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in slideList" :key="index">
          <img class="banner-img" :src="url + item.cover" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="shop-info">
      <div class="left">
        <img class="avatar" v-if="info.thumb" :src="url + info.thumb" alt="" />
        <img
          v-else
          class="avatar"
          src="http://api.zglxgw.com/static/asset/index/images/lunbo.jpg"
          alt=""
        />
      </div>
      <div class="right">
        <div class="title">{{ info.name }}</div>
        <!-- <div class="subtit">简介：{{ info.desc }}</div> -->
        <a
          :href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`"
          class="subtit"
        >
          <i class="iconfont iconweizhi"></i>{{ info.province }}{{ info.city
          }}{{ info.area }} {{ info.address }}
        </a>
        <div class="btns">
          <a
            v-if="userInfo && userInfo.user_vip_info && info.phone"
            :href="`tel:${info.phone}`"
            class="btn"
          >
            <i class="iconfont icondianhua"></i>
            <span>电话联系</span>
          </a>
          <a v-else :href="`javascript:;`" @click.stop="showVIp" class="btn">
            <i class="iconfont icondianhua"></i>
            <span>电话联系</span>
          </a>
          <div class="btn" @click="toChat">
            <i class="iconfont iconxiaoxi"></i>
            <span>在线联系</span>
          </div>
        </div>
      </div>
    </div>
    <van-tabs
      v-model="active"
      color="#2F78DC"
      line-height="1px"
      line-width="64px"
    >
      <van-tab title="厂商信息">
        <div class="tabs-box">
          <div class="card-box">
            <div class="title">服务信息</div>
            <div class="content">
              <div class="subtit">经营范围</div>
              <div class="text">{{ info.desc }}</div>
              <!-- <div class="subtit">执照证书</div>
              <div class="imgs">
                <template v-if="info.bus_img">
                  <img :key="'bus' + index" :src="info.bus_img" alt="" />
                </template>
                <template v-if="info.hor_img && info.hor_img.length > 0">
                  <img
                    v-for="(src, index) in info.hor_img"
                    :key="'hor' + index"
                    :src="src"
                    alt=""
                  />
                </template>
              </div> -->
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="商品信息">
        <div class="tabs-box">
          <div class="goods-list">
            <div class="item"></div>
            <div class="empty" v-if="pinglunList.length == 0">
              <van-empty image-size="100" description="暂无商品数据" />
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="厂商视频">
        <div class="tabs-box">
          <div class="card-box">
            <div class="title">店铺视频</div>
            <div class="list">
              <!-- <div
                class="item"
                v-for="(item, index) in pinglunList"
                :key="index"
              >
                <div class="top">
                  <img src="@/assets/image/avatar.png" alt="" />
                  <div class="right">
                    <p class="name">Super</p>
                    <p class="start">
                      <van-rate
                        :value="item.comment_stars"
                        :size="12"
                        color="#ffd21e"
                        void-icon="star"
                        void-color="#eee"
                      />
                      <span class="time">{{ item.created_at }}</span>
                    </p>
                  </div>
                </div>
                <div class="bottom">
                  {{ item.comment_desc }}
                </div>
              </div> -->
              <div class="empty">
                <van-empty image-size="100" description="暂无数据" />
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      loginURL: base.loginURL,
      active: "1",
      uid: "",
      info: "",
      pinglunList: "",
      userInfo: {
        id: "",
      },
      slideList: "",
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.uid = this.$route.query.uid;
    console.log(this.uid);
    this.getShopDetail();
    // this.getPinglun();
    // this.getSlide();
  },
  mounted() {},
  watch: {},
  methods: {
    showVIp() {
      this.$dialog
        .confirm({
          title: "提示",
          confirmButtonText: "查看会员权益",
          message: "成为冷鲜柜黄金会员即可直接查看拨打电话",
        })
        .then(() => {
          this.$router.push("/vip");
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: this.info.desc,
        link:
          base.shareURL +
          "/shifuShopDetail?uid=" +
          this.info.uid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.uid,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.setShare();
        }
        console.log(res);
      });
    },
    //获取评论列表
    getPinglun() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.uid,
      };
      indexOp("/order_comment/index", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.pinglunList = res.data.data;
        }
        console.log(res);
      });
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
    //获取轮播
    getSlide() {
      var _this = this;
      indexOp("/slide/index?cid=3").then((res) => {
        if (res && res.status == 200) {
          _this.slideList = res.data;
        }
      });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>
