<template>
	<div class="changshang-list">
		<Navbar :title="'厂商列表'"></Navbar>
		<!-- <van-search
      class="search-box"
      v-model="queryParam.keyword"
      placeholder="地区检索"
      shape="round"
      background="transparent"
      @search="onSearch"
    /> -->
		<div @click="toEditShiinfo" class="add-box">
			<i class="iconfont iconjiahao"></i>
			<span class="text">入驻平台</span>
		</div>
		<div style="padding:10px 10px 15px;">
			<div class="city-box">
				<van-icon class="icon" name="search" size="16" @click="showCityPicker=true" />
				<span @click="showCityPicker=true">{{areaText}}</span>
				
				<!-- Laster City -->
				<!-- <span @click="showPicker_1 = true">{{ pickerValue_1 }}</span>
				<span v-if="columns_2.length > 0">-</span>
				<span v-if="columns_2.length > 0" @click="showPicker_2 = true">{{
          pickerValue_2
        }}</span>
				<span v-if="columns_3.length > 0">-</span>
				<span v-if="columns_3.length > 0" @click="showPicker_3 = true">{{
          pickerValue_3
        }}</span> -->
		
				<!-- New City -->
				<template v-if="areaList && areaList.length">
					<van-popup v-model="showCityPicker" round position="bottom">
						<van-cascader v-model="cascaderValue" title="请选择所在地区" :options="areaList"
							@close="showCityPicker = false" @finish="onAreaFinish"
							:field-names="{ text: 'label', value: 'value', children: 'child' }" />
					</van-popup>
				</template>
				
				<span class="clear" @click="onChongzhi">重置</span>
			</div>
		</div>
		<van-popup v-model="showPicker_1" round position="bottom">
			<van-picker show-toolbar :columns="columns_1" @cancel="showPicker_1 = false" @confirm="onPickerConfirm_1"
				value-key="label" />
		</van-popup>
		<van-popup v-model="showPicker_2" round position="bottom">
			<van-picker show-toolbar :columns="columns_2" @cancel="showPicker_2 = false" @confirm="onPickerConfirm_2"
				value-key="label" />
		</van-popup>
		<van-popup v-model="showPicker_3" round position="bottom">
			<van-picker show-toolbar :columns="columns_3" @cancel="showPicker_3 = false" @confirm="onPickerConfirm_3"
				value-key="label" />
		</van-popup>
		<van-list v-model="loading" class="list" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<!-- <div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item.uid)">
        <div class="title-box">
          <img class="avatar" v-if="item.thumb" :src="url + item.thumb" alt="" />
          <img class="avatar" v-else src="@/assets/image/banner1.jpg" alt="" />
          <div class="info">
            <div class="title">{{ item.name }}</div>
            <div class="location">
              <i class="iconfont iconweizhi"></i>
              {{ item.address }}
            </div>
          </div>
        </div>
        <div class="desc">
          <b>厂商简介：</b>
          {{ item.desc }}
        </div>
        <div class="btn">进入</div>
      </div> -->
			<div class="item ed-1" v-for="(item, index) in list" :key="index" @click="toDetail(item.uid)">
				<div class="title-box">
					<img class="avatar" v-if="item.thumb" :src="url + item.thumb" alt="" />
					<img class="avatar" v-else src="@/assets/image/banner1.jpg" alt="" />
					<div class="info">
						<div class="title">{{ item.name }}</div>
						<div class="location">
							公司简介：
							{{ item.desc }}
						</div>
					</div>
				</div>
				<div class="desc news" v-if="item.company_info && item.company_info.news_first"
					@click.stop="$router.push({path:'/newDetail',query:{ id:item.company_info.news_first.id }})">
					<span class="tit">NEWS</span>
					<span class="text">{{item.company_info.news_first.name}}</span>
				</div>
				<div class="desc chanpin" v-if="item.company_info">
					<span
						@click.stop="$router.push({path:'/productList',query:{ uid:item.uid }})">产品：{{item.company_info.goods_count}}
						(个)</span>
					<span
						@click.stop="$router.push({path:'/newsList',query:{ uid:item.uid }})">新闻：{{item.company_info.news_count}}
						(个)</span>
				</div>
				<div class="desc dizhi">
					<span>
						<i class="iconfont iconweizhi"></i>
						{{item.address}}
					</span>
				</div>
				<template v-if="item.company_info && item.company_info.vip_level">
					<div class="btn" style="background:#f5f2f0;color:#949494;"
						v-if="item.company_info.vip_level.vip_level == 1">普通会员</div>
					<div class="btn" style="background:#fcdece;color:#ed8135;"
						v-if="item.company_info.vip_level.vip_level == 2">黄金会员</div>
					<div class="btn" style="background:#d7c5fe;color:#4c00f4;"
						v-if="item.company_info.vip_level.vip_level == 3">钻石会员</div>
					<!-- <div class="btn" style="background:#fcdece;color:#ed8135;" v-if="item.company_info.vip_level.vip_level == 1" >体验会员</div> -->
				</template>
			</div>
		</van-list>
		<!-- <div class="list"></div> -->
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import address from "../../utils/address";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				queryParam: {
					limit: 10,
				},
				url: base.apiURL,
				list: [],
				loading: false,
				finished: false,
				current_page: 0, //当前页
				last_page: "", //最后一页
				showPicker_1: false,
				columns_1: address,
				
				// Area
				areaList : null,
				areaText : '点击地区检索',
				showCityPicker:false,
				
				// 
				pickerValue_1: "地区搜索",
				showPicker_2: false,
				columns_2: [],
				pickerValue_2: "选择市",
				showPicker_3: false,
				columns_3: [],
				pickerValue_3: "选择区/县",
				userInfo: {
					id: ""
				},
				cascaderValue:""
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			// this.getShopList();
			this.setShare();
			this.getServiceRegion();
		},
		mounted() {},
		watch: {},
		methods: {
			// 获取地区列表
			getServiceRegion() {
				var _this = this;
				indexOp("/area/all_format_child").then(res => {
					_this.areaList = res.data;
				})
			},
			/**
			 * 地区提交
			 */
			onAreaFinish({
				selectedOptions
			}) {
				this.showCityPicker = false;
				this.areaText = selectedOptions.map((option) => option.label).join('-');
			
				// 2021.1.16 增加地区存储
				this.areaValue = selectedOptions.map((option) => option.value);
				this.queryParam.province = this.areaValue[0];
				this.queryParam.city = this.areaValue[1];
				this.queryParam.area = this.areaValue[2];
				
				this.getShopList();
			},
			toEditShiinfo() {
				this.$router.push({
					path: "/editShopinfo"
				})
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-制冷厂商`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/changshangList?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			onPickerConfirm_1(value) {
				console.log(value);
				this.pickerValue_1 = value.label;
				this.showPicker_1 = false;
				this.columns_2 = value.child;
				this.pickerValue_2 = "选择市";
				this.showPicker_3 = false;
				this.columns_3 = [];
				this.queryParam.province = value.label;
				this.queryParam.city = '';
				this.queryParam.area = '';
				this.getShopList();
			},
			onPickerConfirm_2(value) {
				console.log(value);
				this.pickerValue_2 = value.label;
				this.showPicker_2 = false;
				this.columns_3 = value.child;
				this.pickerValue_3 = "选择区/县";
				this.queryParam.city = value.label;
				this.queryParam.area = '';
				this.getShopList();
			},
			onPickerConfirm_3(value) {
				console.log(value);
				this.pickerValue_3 = value.label;
				this.showPicker_3 = false;
				this.queryParam.area = value.label;
				this.getShopList();
			},
			onChongzhi() {
				this.queryParam.province = '';
				this.queryParam.city = '';
				this.queryParam.area = '';
				this.pickerValue_1 = "地区搜索";
				this.pickerValue_2 = "选择市";
				this.pickerValue_3 = "选择区/县";
				this.areaText 	=	'点击地区检索';
				this.columns_2 = [];
				this.columns_3 = [];
				this.getShopList();
			},
			onSearch() {},
			getShopList() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				_this.queryParam.page = 1;
				indexOp("/company_user/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.list = res.data.data;
						_this.current_page = res.data.current_page;
						_this.last_page = res.data.last_page;
					}
					console.log(res);
				});
			},
			onLoad() {
				var _this = this;
				// if (_this.current_page == _this.last_page) {
				//   // _this.$toast("没有更多数据了");
				//   _this.finished = true
				//   _this.loading = false;
				//   return;
				// }
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.current_page + 1;
				_this.queryParam.page = page;
				indexOp("/company_user/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.last_page;
						_this.current_page = res.data.current_page;
						if (res.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							_this.finished = true
							_this.loading = false;
							return;
						}
						for (var i = 0; i < res.data.data.length; i++) {
							_this.list.push(res.data.data[i]);
							_this.loading = false;
						}
					}
				});
			},
			toDetail(uid) {
				this.$router.push({
					path: "/changshangIndex",
					query: {
						uid: uid
					}
				})
				// this.$router.push({
				//   path:"/changshangDetail",
				//   query:{
				//     uid:uid
				//   }
				// })
			},
		},
	};
</script>

<style scoped>
</style>
