<template>
  <div class="page flex-col" id="productShow" v-cloak>
    <Navbar :title="'商品详情'"></Navbar>
    <div class="box1 flex-col" v-if="info && info.id">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img
            @click="onPreviewImg(item, index)"
            class="swiper-img"
            :src="item"
            alt=""
          />
        </van-swipe-item>
        <!-- <van-swipe-item v-if="info.video">
          <video
            :src="url + info.video"
            style="width: 100%; height: 100%"
            controls
            muted
            autoplay
          ></video>
        </van-swipe-item> -->
      </van-swipe>

      <!-- <div class="group3 flex-col">
        <div class="section1 flex-row">
          <div style="display: flex">
            <span class="txt1">¥</span>
            <div class="box2">
              <span class="word1">{{ info.sale_price }}</span>
            </div>
            <div class="box3">
              <span class="txt3">原价：</span>
              <span class="txt4">¥&nbsp;{{ info.origin_price }}</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="group4 flex-col">
        <span class="word2">{{ info.content }}</span>
        <div style="display: flex; padding: 0 15px 5px">
          <span class="txt1" style="color: red; font-weight: bold">¥</span>
          <div class="box2">
            <span class="word1" style="color: red; font-weight: bold">{{
              info.sale_price
            }}</span>
          </div>
          <div class="box3">
            <span class="txt3" style="color: #000">原价：</span>
            <span class="txt4" style="color: #000"
              >¥&nbsp;{{ info.origin_price }}</span
            >
          </div>
          <div class="box3" v-if="userInfo.identity == 9">
            <span class="txt3" style="color: #000">进货价：</span>
            <span class="txt4" style="color: #000; text-decoration: none"
              >¥&nbsp;{{ info.purchase_price }}</span
            >
          </div>
        </div>
        <div class="address-box">
          <van-icon name="location" />
          <span>
            <span>{{ info.province ? info.province : "" }}</span>
            <span>{{ info.city ? `- ${info.city}` : "" }}</span>
            <span>{{ info.area ? `- ${info.area}` : "" }}</span>
            {{ info.address ? `  ${info.address}` : "" }}
          </span>
        </div>
        <div class="click-box">浏览量:{{ info.click }}</div>
      </div>
      <div class="user-box" v-if="info.user">
        <div class="left">
          <img :src="info.user.avatar" alt="" />
          <span>{{ info.user.nickname }}</span>
        </div>
        <van-button
          type="info"
          round
          size="small"
          :to="{ path: '/shop/myShopIndex', query: { uid: info.user.id } }"
          >查看他的商品</van-button
        >
      </div>
      <div class="group5">
        <div class="box5">
          <span class="word3">产品信息：</span>
          <!-- <div class="bd1">
            <div
              :class="item.id == checkedId ? 'layer1 active' : 'layer1'"
              v-for="(item, index) in sku"
              :key="index"
              @click="onTabsChange(index)"
            >
              <span class="word4"
                >{{ item.sku_name }}&nbsp;{{ item.size }}</span
              >
            </div>
          </div> -->
          <div class="bd2 flex-col"></div>
          <div class="bd3 flex-row justify-between">
            <div class="TextGroup1 flex-col">
              <div class="layer4 flex-col justify-between">
                <div class="block1">
                  <span class="word6">产品分类：</span>
                  <span class="txt6"
                    >&nbsp;{{ info.category ? info.category.name : "" }}</span
                  >
                </div>
              </div>
            </div>
            <div class="TextGroup2 flex-col">
              <div class="layer5 flex-col justify-between">
                <div class="mod1">
                  <span class="word8">产品品牌：</span>
                  <span class="word9"
                    >&nbsp;{{ info.brand ? info.brand.name : "" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group6 flex-col">
        <div class="bd4 flex-col">
          <van-tabs v-model="active">
            <van-tab title="商品介绍">
              <!-- <div
                class="content"
                @click="vhtmlPreviewImage($event)"
                style="padding-top: 10px"
                v-html="info.content"
              ></div> -->
              <div class="content">{{ info.content }}</div>
              <div class="img-list" v-if="info.image && info.image.length > 0">
                <img
                  style="width: 100%"
                  v-for="item in info.image"
                  :key="item"
                  :src="url + item"
                  alt=""
                />
              </div>
              <div
                class="video-list"
                v-if="info.video && info.video.length > 0"
              >
                <video
                  v-for="item in info.video"
                  :key="item"
                  :src="url + item"
                  style="width: 100%"
                  controls
                ></video>
              </div>
            </van-tab>
            <!-- <van-tab title="售后保障">
              <div
                class="content"
                @click="vhtmlPreviewImage($event)"
                style="padding-top: 10px"
                v-if="info.security"
                v-html="info.security"
              ></div>
            </van-tab> -->
          </van-tabs>
          <div class="layer8 flex-row justify-between">
            <div class="flex-row justify-between">
              <div class="group7 flex-col">
                <div class="outer1 flex-row">
                  <div class="ImageText1 flex-col">
                    <div
                      class="layer9 flex-col justify-between"
                      @click="toHome"
                    >
                      <div class="mod5 flex-col">
                        <van-icon name="wap-home" class="icon" />
                      </div>
                      <div class="TextGroup3 flex-col">
                        <span class="word12">首页</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group7 flex-col">
                <div class="outer1 flex-row">
                  <div class="ImageText1 flex-col">
                    <div
                      class="layer9 flex-col justify-between"
                      @click="toUser"
                    >
                      <div class="mod5 flex-col">
                        <van-icon name="manager" class="icon" />
                      </div>
                      <div class="TextGroup3 flex-col">
                        <span class="word12">个人</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group10 flex-col">
              <div class="group11 flex-row justify-between">
                <button
                  class="block3 flex-col"
                  @click="contact_we"
                  style="background-color: #6ab0f7"
                >
                  <span class="info4">服务电话</span>
                </button>
                <button
                  class="block4 flex-col"
                  @click="toChat"
                  style="background-color: #1989fa"
                >
                  <span class="info5">在线沟通</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-image-preview
      v-model="showImages"
      :images="images"
      :startPosition="startPosition"
    >
    </van-image-preview>
    <div class="btns">
      <router-link
        class="btn"
        :to="{ path: '/shop/fabu_tk', query: { id: info.id } }"
      >
        <van-icon name="records-o" />
        <span>发布同款</span>
      </router-link>
      <div class="btn" v-if="isMe()" @click="showSetting = true">
        <van-icon name="setting-o" />
        <span>操作</span>
      </div>
    </div>
    <van-action-sheet
      v-model="showSetting"
      :actions="actions"
      cancel-text="取消"
      description="选择操作"
      close-on-click-action
      @select="onSetting"
    />
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";

import { ImagePreview } from "vant";

export default {
  data() {
    return {
      url: base.apiURL,
      loginURL: base.loginURL,
      constants: {},
      active: "1",

      userInfo: {},
      pid: "",
      info: {},
      sku: [],
      defaultSku: {},
      checkedId: "",
      images: [],
      showImages: false,
      startPosition: 0,

      goodsShopInfo: null,
      showSetting: false,
      actions: [{ name: "编辑" }, { name: "删除" }],
    };
  },
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.pid = this.$route.query.id;
    this.WXlogin();
    this.getInfo();
  },
  methods: {
    isMe() {
      var uinfo = this.$cookies.get("userInfo");
      console.log(uinfo);
      if (!uinfo) {
        return false;
      }
      if (!this.info) {
        return false;
      }
      return uinfo.id == this.info.uid;
    },
    onSetting(action, index) {
      var _this = this;
      if (action.name == "编辑") {
        _this.$router.push({
          path: "/shop/edit",
          query: {
            id: _this.pid,
          },
        });
      } else if (action.name == "删除") {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "确认删除这个商品吗？",
            confirmButtonText: "确认",
            cancelButtonText: "取消",
          })
          .then(() => {
            // on close
            addOp("/product/destroy", { id: _this.pid }).then((res) => {
              if (res && res.status == 200) {
                _this.$toast.success("删除成功！");
                setTimeout(() => {
                  _this.$router.go(-1);
                }, 800);
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    isImage(src) {
      if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(src)) {
        return true;
      }
      return false;
    },
    /**
     * 富文本图片预览
     * @param {Object} event
     */
    vhtmlPreviewImage(event) {
      if (event.target.nodeName == "IMG" || event.target.nodeName == "img") {
        const img = event.target.currentSrc;
        ImagePreview([img]);
        // 预览图片办法
      }
    },
    onPreviewImg(arr, index) {
      this.showImages = true;
      this.startPosition = index;
      // console.log(arr);
    },
    onTabsChange(index) {
      const _this = this;
      _this.defaultSku = _this.sku[index];
      _this.checkedId = _this.sku[index].id;
    },
    getInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/product/info";
      indexOp(api, { id: _this.pid }).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          res.data.image.map((item) => {
            if (item.length) {
              const imgurl = _this.url + item;
              _this.images.push(imgurl);
            }
          });
          _this.getShopDetail();
          _this.setShare();
          _this.getGoodsShopInfo();

          // document.title = res.data.goods_name;
          // if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          //   var i = document.createElement("iframe");
          //   i.src = "/favicon.ico";
          //   i.style.display = "none";
          //   i.onload = () => {
          //     setTimeout(() => {
          //       i.remove();
          //     }, 9);
          //   };
          //   document.body.appendChild(i);
          // }
        }
      });
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.info.uid,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.companyInfo = res.data;
        }
        console.log(res);
      });
    },
    // 获取商品店铺详情
    getGoodsShopInfo() {
      var _this = this;
      indexOp("/goods_shop/shop_info?shop_uid=" + this.info.uid).then((res) => {
        _this.goodsShopLoading = true;
        if (res && res.status == 200) {
          if (!res.data.error) {
            _this.goodsShopInfo = res.data;
            _this.setShare();
          }
        }
      });
    },
    //分享
    setShare() {
      var _this = this;
      const shareInfo = {
        title: this.info.user.nickname + "发布的产品信息",
        desc: this.info.content,
        link:
          base.shareURL +
          "/shop/detail?id=" +
          this.pid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    toHome() {
      this.$router.push({
        path: "/shop/myShopIndex",
        query: {
          uid: this.info.uid,
          tab: 4,
        },
      });
    },
    toUser() {
      this.$router.push("/user");
    },
    contact_we() {
      var _this = this;

      if (!this.info.user) {
        _this.$toast("暂无信息");
        return false;
      }

      if (!this.info.user.mobile) {
        _this.$toast("暂无信息");
        return false;
      }

      var tel = this.info.user.mobile;
      var a = document.createElement("a");
      a.href = "tel:" + tel;
      a.click();
    },
    onLogin(value) {
      const _this = this;
      if (value == "登录查看专属价格") {
        window.location.href = _this.loginURL;
      } else {
        return;
      }
    },
    WXlogin() {
      var _this = this;
      var token = this.$cookies.get("Authorization");
      var code = this.getUrlKey("code");
      var state = this.getUrlKey("state");
      if (!token && code && state) {
        login(_this.getLoginUser);
      }
    },
    getLoginUser(userInfo) {
      // this.getUser();
      console.log(userInfo);
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert();
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
            hide_tags_box: 1,
          },
        });
      });
    },
  },
  components: {
    Navbar: Navbar,
  },
};
</script>

<style lang="less" scoped>
@ThemColor: #2f78dc;
.click-box {
  position: absolute;
  bottom: 15px;
  right: 10px;
  font-size: 12px;
  color: #666;
}
.user-box {
  padding: 15px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  .left {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.address-box {
  display: flex;
  align-items: center;
  // background: #f2f2f2;
  font-size: 12px;
  color: #666;
  padding: 0px 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  width: 80%;
}
.address-box span {
  padding: 0 2px;
}
.btns {
  bottom: 110px !important;
}

.btns {
  position: fixed;
  right: 15px;
  bottom: 80px;

  .btn {
    height: 35px;
    width: 90px;
    border-radius: 35px;
    font-size: 14px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @ThemColor;
    box-shadow: 4px 6px 28px -10px rgba(79, 77, 77, 0.5);
    box-sizing: border-box;
    color: #fff;

    span {
      line-height: 1;
    }

    i {
      margin-right: 5px;
      color: #fff;
    }
  }
}
.content {
  padding: 10px 13px;
}

.van-swipe-item {
  height: 289px;
}

.swiper-img {
  width: 100%;
  height: 289px;
  object-fit: cover;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.page {
  background-color: rgba(242, 242, 242, 1);
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 68px;
}

.box1 {
  width: 100%;
}

.group1 {
  height: 285px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb52e0823af88f739e17564b91447de3e32edd834720cd47128437af7f9486232)
    100% no-repeat;
  width: 100%;
}

.group2 {
  width: 30px;
  height: 30px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga54599dc3a632d7af79d3359d85a96d6721960314d00cda278310d50ae795dba)
    100% no-repeat;
  margin: 17px 0 0 20px;
}

.group3 {
  height: 61px;
  background: url(../../assets/image/product.jpg) 100% no-repeat;
  background-color: #ff321e;
  width: 100%;
}

.section1 {
  width: 100%;
  height: 30px;
  // margin: 15px 0 0 14px;
  padding: 15px 14px;
  display: flex;
  justify-content: space-between;
}

.txt1 {
  width: 9px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 5px;
  display: block;
}

.box2 {
  height: 28px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
  margin: 1px 0 0 11px;
}

.word1 {
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.txt2 {
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.box3 {
  height: 17px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin: 7px 0 0 10px;
}

.txt3 {
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(247, 248, 250, 1);
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
}

.txt4 {
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(247, 248, 250, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  text-decoration: line-through;
}

.box4 {
  background-color: rgba(212, 8, 31, 1);
  border-radius: 16px;
  height: 30px;
  // margin-left: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.txt5 {
  color: #fff;
  font-size: 10px;
}

.group4 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  position: relative;
}

.word2 {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  text-align: left;
  line-height: 25px;
  display: block;
  padding: 13px 13px 5px;
  font-weight: bold;
}

.group5 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin-top: 12px;
  width: 100%;
}

.box5 {
  width: 100%;
  padding: 12px 13px;
}

.word3 {
  width: 39px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  display: block;
  font-weight: bold;
}

.bd1 {
  width: 100%;
  // min-height: 63px;
  margin-top: 7px;
  // flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
}

.layer1 {
  background-color: rgba(247, 248, 250, 1);
  border-radius: 16px;
  height: 28px;
  margin: 0 4px 7px 0;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
}

.layer1.active {
  background-color: rgba(238, 10, 36, 0.1);
  color: rgba(238, 10, 36, 1);
}

.word4 {
  height: 16px;
  text-align: left;
  line-height: 16px;
  display: inline-block;
  margin: 6px 10px;
}

.layer2 {
  background-color: rgba(247, 248, 250, 1);
  border-radius: 16px;
  height: 28px;
  margin-bottom: 7px;
  width: 170px;
}

.word5 {
  width: 149px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  display: block;
  margin: 6px 0 0 12px;
}

.layer3 {
  background-color: rgba(247, 248, 250, 1);
  border-radius: 16px;
  height: 28px;
  margin-right: NaNpx;
  margin-bottom: 7px;
  width: 170px;
}

.info1 {
  width: 149px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
  display: block;
  margin: 6px 0 0 12px;
}

.bd2 {
  width: 375px;
  height: 1px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd41d8afd7fb95e159f97fcb81e64f92a7e7ad849ddccd16b1b11c7b99266f391)
    100% no-repeat;
  margin: 10px 0 0 7px;
}

.bd3 {
  // width: 331px;
  height: 35px;
  margin: 13px 0 0 2px;
}

.TextGroup1 {
  height: 35px;
  width: 50%;
}

.layer4 {
  width: 100%;
  height: 35px;
}

.block1 {
  height: 14px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.word6 {
  width: 35%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(82, 82, 83, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.txt6 {
  width: 65%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.block2 {
  height: 14px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.word7 {
  width: 35%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(82, 82, 83, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.txt7 {
  width: 65%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.TextGroup2 {
  height: 35px;
  width: 50%;
}

.layer5 {
  width: 100%;
  height: 35px;
}

.mod1 {
  height: 14px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.word8 {
  width: 35%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(82, 82, 83, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.word9 {
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
}

.mod2 {
  height: 14px;
  overflow-wrap: break-word;
  font-size: 0;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.word10 {
  width: 35%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(82, 82, 83, 1);
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.info2 {
  width: 65%;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  font-weight: 500;
}

.group6 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  margin-top: 12px;
  width: 100%;
}

.bd4 {
  width: 100%;
  margin-top: 1px;
}

.layer6 {
  background-color: rgba(255, 255, 255, 1);
  height: 44px;
  margin-left: 19px;
  width: 375px;
}

.section2 {
  width: 375px;
  height: 44px;
}

.mod3 {
  height: 44px;
  width: 188px;
}

.bd5 {
  width: 56px;
  height: 32px;
  margin: 12px 0 0 66px;
}

.word11 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(50, 50, 51, 1);
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.box6 {
  background-color: rgba(238, 10, 36, 1);
  border-radius: 1px;
  width: 40px;
  height: 3px;
  margin: 9px 0 0 8px;
}

.mod4 {
  height: 44px;
  width: 187px;
}

.txt8 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(150, 151, 153, 1);
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
  margin: 12px 0 0 66px;
}

.layer7 {
  width: 100%;
  height: 561px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng23a8c9ab7080cf5da3654492520905f7ceb0ce663969855a8696e039abf10ab6)
    100% no-repeat;
  margin-top: 8px;
}

.layer8 {
  width: 100%;
  height: 68px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.group7 {
  background-color: rgba(255, 255, 255, 1);
  height: 68px;
  width: 49px;
}

.outer1 {
  width: 49px;
  height: 52px;
  margin-top: 10px;
}

.ImageText1 {
  height: 52px;
  width: 49px;
}

.layer9 {
  width: 49px;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mod5 {
  background-color: rgba(235, 237, 240, 1);
  border-radius: 22px;
  height: 29px;
  width: 29px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 16px;
    color: #fff;
  }
}

.layer10 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.TextGroup3 {
  height: 20px;
  margin-top: 3px;
}

.word12 {
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.group8 {
  background-color: rgba(255, 255, 255, 1);
  height: 68px;
  width: 49px;
}

.group9 {
  width: 49px;
  height: 52px;
  margin-top: 10px;
}

.ImageText2 {
  height: 52px;
  width: 49px;
}

.outer2 {
  width: 49px;
  height: 52px;
}

.bd6 {
  background-color: rgba(235, 237, 240, 1);
  border-radius: 22px;
  height: 29px;
  margin-left: 10px;
  width: 29px;
}

.layer11 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin: 7px 0 0 7px;
}

.TextGroup4 {
  height: 20px;
  margin-top: 3px;
}

.info3 {
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.group10 {
  background-color: rgba(255, 255, 255, 1);
  // height: 54px;
  width: 65%;
}

.group11 {
  width: 100%;
  height: 43px;
}

.block3 {
  background-color: rgba(250, 171, 12, 1);
  border-radius: 100px 0px 0px 100px;
  height: 43px;
  width: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info4 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}

.block4 {
  background-color: rgba(238, 10, 36, 1);
  border-radius: 0px 100px 100px 0px;
  height: 43px;
  width: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info5 {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  display: block;
}
</style>
