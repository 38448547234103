<template>
  <div class="shifu-nearby">
    <Navbar :title="`附近师傅`"></Navbar>
    <van-search
      class="search-box"
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div id="map"></div>
    <div class="shifu-list">
      <div
        class="item"
        v-for="(item, index) in shifu_list"
        :key="index"
        @click="toDetail(item.id)"
      >
        <div class="left" v-if="item.service_user_info">
          <p class="title">{{ item.service_user_info.name }}</p>
          <p class="subtitle">
            <span>{{ item.distance }}km</span>
            <span>|</span>
            <span>{{ item.service_user_info.address }}</span>
          </p>
        </div>
        <div class="left" v-else>
          <p class="title">{{ item.nickname }}</p>
          <p class="subtitle">
            <span>{{ item.distance }}km</span>
            <span>|</span>
            <span v-if="item.service_user_info">{{
              item.service_user_info.address
            }}</span>
          </p>
        </div>
        <div class="right">
          <a
            v-if="item.service_user_info && userInfo.user_vip_info"
            :href="`tel:${item.service_user_info.phone}`"
            @click.stop
            class="coll"
          >
            <i class="iconfont icondianhua"></i>
          </a>
          <a v-else :href="`tel:4006912808`" class="coll" @click.stop>
            <i class="iconfont icondianhua"></i>
          </a>
          <i class="iconfont iconxiaoxi" @click.stop="toChat(item)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
export default {
  props: {},
  data() {
    return {
      value: "",
      shifu_list: "",
      map: "",
      marker: "",
      infoWindow: "",
      userInfo: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.userInfo = this.$cookies.get("userInfo");
    this.LocationInfo = this.$cookies.get("LocationInfo");
    console.log(this.$cookies.get("LocationInfo"));
    this.initMap();
    this.getShifuList(this.LocationInfo.latitude, this.LocationInfo.longitude);
  },
  watch: {},
  methods: {
    //初始化地图
    initMap() {
      var _this = this;
      //定义地图中心点坐标
      var userInfo = this.$cookies.get("userInfo");
      var lat = userInfo.latitude;
      var lng = userInfo.longitude;
      var center = new TMap.LatLng(lat, lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      _this.map = new TMap.Map(document.getElementById("map"), {
        center: center, //设置地图中心点坐标
        zoom: 12, //设置地图缩放级别
      });
      _this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      _this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
      // var control_zoom = _this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      // control_zoom.setPosition(TMap.constants.CONTROL_POSITION.BOTTOM_RIGHT);
      // var control_rotation = _this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
      // control_rotation.setPosition(TMap.constants.CONTROL_POSITION.BOTTOM_RIGHT);
      //初始化marker
      _this.marker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: _this.map,
        styles: {
          //点标注的相关样式
          myMarker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 25,
            anchor: { x: 16, y: 32 },
            src: "../image/dian.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "myMarker",
            position: new TMap.LatLng(lat, lng),
            properties: {
              title: "我的位置",
            },
          },
        ],
      });
      _this.marker.on("click", _this.clickHandler);
      //初始化infoWindow
      _this.infoWindow = new TMap.InfoWindow({
        map: _this.map,
        position: new TMap.LatLng(lat, lng),
        offset: { x: -3, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      _this.infoWindow.close();
    },
    //获取附近师傅列表
    getShifuList(latitude, longitude) {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        latitude: latitude,
        longitude: longitude,
      };
      indexOp("/gps/near_user", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.shifu_list = res.data;
          var dianArr = [];
          res.data.map((item) => {
            var title;
            if (item.service_user_info) {
              title = item.service_user_info.name;
            } else {
              title = item.nickname;
            }
            var obj = {
              id: item.id,
              styleId: "marker",
              position: new TMap.LatLng(item.latitude, item.longitude),
              properties: {
                title: title,
              },
            };
            dianArr.push(obj);
          });
          _this.marker.add(dianArr);
        }
      });
    },
    clickHandler(evt) {
      console.log(evt.geometry.properties.title);
      this.infoWindow.open(); //打开信息窗
      this.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
      this.infoWindow.setContent(evt.geometry.properties.title); //设置信息窗内容
    },
    //去店铺详情
    toDetail(uid) {
      this.$router.push({
        path: "/shifuShopDetail",
        query: {
          uid: uid,
        },
      });
    },
    //去对话
    toChat(item) {
      var _this = this;
      var to_uid = item.id; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
    onSearch(value) {
      var _this = this;
      console.log(_this.value);
      var url = "http://apis.map.qq.com/ws/geocoder/v1/";
      _this
        .$jsonp(url, {
          key: "U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX",
          output: "jsonp",
          address: _this.value,
        })
        .then((json) => {
          var location = json.result.location;
          _this.map.setCenter(new TMap.LatLng(location.lat, location.lng));
          console.log(json); //附近街道地址信息
          _this.getShifuList(location.lat, location.lng);
          _this.marker.updateGeometries([
            {
              styleId: "myMarker",
              id: "demo",
              position: new TMap.LatLng(location.lat, location.lng),
              properties: {
                title: _this.value,
              },
            },
          ]);
        })
        .catch((err) => {
          _this.$toast.fail("地区搜索失败！")
          console.log(err);
        });
    },
  },
  components: {
    Tabber,
    Navbar,
  },
};
</script>

<style scoped >
</style>
