<template>
  <!-- 注册 -->
  <div class="register">
    <Navbar :title="'注册'"></Navbar>
    <div class="form-box">
      <!-- <div class="input-item">
        <van-icon class="icon" name="manager" />
        <input type="text" placeholder="请输入账户名" />
      </div> -->
      <div class="input-item">
        <i class="icon iconfont iconFill" />
        <input v-model="fromData.mobile" type="text" placeholder="请输入手机号" />
      </div>
      <!-- <div class="input-item">
        <i class="icon iconfont iconyanzhengma" />
        <input type="text" placeholder="请输入验证码" />
      </div> -->
      <div class="input-item">
        <van-icon class="icon" name="lock" />
        <input v-model="fromData.password" type="password" placeholder="请输入密码" />
      </div>
      <div class="input-item">
        <van-icon class="icon" name="lock" />
        <input v-model="fromData.confirm_password" type="password" placeholder="请再次输入密码" />
      </div>
      <button class="sub-btn" @click="onSubmit">注册</button>
    </div>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
// import websocket from '../utils/webSocket'
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../../api";
import base from "../../api/base.js";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      fromData:{}
    };
  },
  methods:{
    onSubmit(){
      var _this = this;
      if(_this.fromData.mobile == undefined){
        _this.$toast("手机号不能为空！")
        return;
      }
      if(_this.fromData.password == undefined){
        _this.$toast("密码不能为空！")
        return;
      }
      if(_this.fromData.confirm_password != _this.fromData.password ){
        _this.$toast("两次密码输入不一致！")
        return;
      }
      addOp("/oauth/register",_this.fromData).then(res=>{
        console.log(res)
        if(res && res.status == 200){
          _this.$toast.success("注册成功！");
          _this.$cookies.set(
                "Authorization",
                res.data.token_type + " " + res.data.access_token
              );
          setTimeout(()=>{
            _this.$router.push({
              path:"/user"
            })
          },800)
        }else{
          if(res.data.errors){
            for(var key in res.data.errors){
              _this.$toast.fail(res.data.errors[key][0]);
            }
          }else{
            _this.$toast.fail('注册失败，请重试！');
          }
        }
      })
    }
  }
};
</script>

<style>
</style>