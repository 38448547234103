<template>
  <div class="sponsor-index joiner-index">
    <Navbar :title="info.company_name"></Navbar>
    <div class="organiser">
      <div class="bg">
        <div class="info">
          <div class="left">
            <img class="avatar" :src="baseUrl + info.company_logo" alt="" />
          </div>
          <div class="right">
            <div class="title">服务商名称：{{ info.company_name }}</div>
            <div class="address">服务商地址：{{ info.company_address }}</div>
            <div class="address">
              <span>联系方式：{{ info.company_phone }}</span>
              <div class="btn" @click="toChat(info.uid)">
                <i class="iconfont iconduihua"></i>
                <span>发起聊天</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desc-box">
      <div class="title-box">
        <span>企业简介</span>
      </div>
      <div class="text">
        {{ info.company_desc }}
      </div>
      <div class="title-box">
        <span>企业资质</span>
      </div>
      <div>
        <img style="width: 100%" :src="baseUrl + info.company_pic" alt="" />
        <!-- {{info.company_desc}} -->
      </div>
      <div class="title-box">
        <span>企业视频</span>
      </div>
      <div>
        <div class="video-box" v-if="info.videos">
          <video
            width="100%"
            :src="baseUrl + info.videos[0]"
            controls="controls"
          ></video>
        </div>
      </div>
      <div class="title-box">
        <span>相关图片</span>
      </div>
      <div>
        <img
          v-for="(item, index) in info.images"
          :key="index"
          style="width: 100%"
          :src="baseUrl + item"
          alt=""
        />
        <!-- {{info.company_desc}} -->
      </div>
    </div>
    <!-- <div class="tab-box">
      <div
        :class="tabActive == 1 ? 'item active' : 'item'"
        @click="changeTab(1)"
      >
        参加的展会活动
      </div>
      <div
        :class="tabActive == 2 ? 'item active' : 'item'"
        @click="changeTab(2)"
      >
        展商产品
      </div>
    </div>
    <div class="expo-list" v-if="tabActive == 1">
          <van-list
            v-model="expoloading"
            :finished="expofinished"
            :finished-text="expoList.length > 0 ? '没有更多了' : ''"
            @load="getExpo"
          >
            <div class="item" v-for="(item,index) in expoList" :key="index" @click="toExpoDetail(item)">
              <div class="banner-box">
                <img class="banner-img" v-if="item.images" :src="baseUrl + item.images[0]" alt="" />
                <div class="banner-title">
                  {{item.title}}
                </div>
                <div class="status active">{{item.format_time_text}}</div>
              </div>
              <div class="content-box">
                <div class="title" v-if="item.expo_sponsor_user">主办方：{{item.expo_sponsor_user.company_name}}</div>
                <div class="address">
                  <i class="iconfont iconweizhi"></i
                  >会展地点：{{item.address}}
                </div>
                <div class="time">
                  <div class="left">
                    <van-icon name="clock-o" />
                    展会时间：{{item.start_time}} 至 {{item.end_time}}
                  </div>
                </div>
              </div>
            </div>
            <van-empty image-size="100" v-if="expoList.length == 0" description="暂无产品数据" />
          </van-list>
    </div>
    <div class="tabs-box" v-if="tabActive == 2">
      <div class="card-box">
        <div class="title">
          <span>产品展示</span>
        </div>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="productList.length > 0 ? '没有更多了' : ''"
            @load="getProduct"
          >
            <div class="product-list">
                <div class="item" v-for="(item,index) in productList" :key="index" @click="toDetail(item.id)">
                  <img :src="baseUrl + item.images[0]" alt="" />
                  <span>{{item.goods_name}}</span>
                </div>
            </div>
            <van-empty image-size="100" v-if="productList.length == 0" description="暂无产品数据" />
          </van-list>
      </div>
    </div> -->
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      baseUrl: base.apiURL,
      loginURL: base.loginURL,
      tabActive: "1",
      userInfo: {
        id: "",
      },
      invate_uid: "",
      info: {},
      productList: [],
      expoList: [],

      finished: false,
      loading: false,

      productPage: "",
      expoPage: "",

      expofinished: false,
      expoloading: false,
      service_id: "",
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    if (this.userInfo.id == "") {
      loginAlert();
    }
    this.service_id = this.$route.query.id;
    this.getInfo();
    this.getProduct();
    this.wxLogin();
  },
  mounted() {},
  watch: {},
  methods: {
    wxLogin() {
      var code = this.getUrlKey("code");
      var state = this.getUrlKey("state");
      if (!this.$cookies.isKey("Authorization") && code) {
        login(this.getUser);
      }
    },
    getUser(userInfo) {
      this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    toChat(toId) {
      var _this = this;
      if (_this.$cookies.isKey("userInfo")) {
        var form_uid = _this.userInfo.id; //自己的 uid
        var to_uid = toId; //聊天对象 uid
        var data = {
          params: {
            to_uid: to_uid,
            from_uid: form_uid,
            chat_type: "normal",
          },
        };
        addOp("/im/build_chat", data).then((res) => {
          console.log(res);
          if (res && res.status != 500) {
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                order_no: _this.order_no,
                to_uid: to_uid,
              },
            });
          } else {
            _this.$toast.fail("对话创建失败！");
          }
        });
      } else {
        loginAlert()
      }
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-${this.info.company_name}`,
        desc: `媒体渠道，打造冷柜行业互联网+，报名成为服务商，为您带来更多的客户！`,
        link:
          base.shareURL +
          "/serviceIndex?id=" +
          this.service_id +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    onEdit() {
      this.$router.push("/joinerUserEdit");
    },
    toBack() {
      this.$router.go(-1);
    },
    changeTab(type) {
      this.tabActive = type;
    },
    getInfo() {
      var _this = this;

      var data = {
        id: _this.service_id,
      };
      indexOp("/expo_service_user/show", data).then((res) => {
        if (res.data.state == 1000) {
          _this.info = res.data.data;
          _this.setShare();
        } else {
          _this.$toast.fail(res.data.msg);
        }
      });
    },
    //获取参展商商品
    getProduct() {
      var _this = this;
      var data = {
        uid: _this.joiner_id,
        page: _this.productPage + 1,
      };
      indexOp("/expo_joiner/goods_list", data).then((res) => {
        console.log(res);
        if (res.data.state == 1000) {
          if (res.data.data.data.length > 0) {
            _this.productList = res.data.data.data;
            _this.productPage = res.data.data.current_page;
          } else {
            _this.finished = true;
          }
          _this.loading = false;
        } else {
          _this.$toast.fail(res.data.msg);
        }
      });
    },
    //获取参展商参加的展会
    getExpo() {
      var _this = this;
      var data = {
        uid: _this.joiner_id,
        page: _this.expoPage + 1,
      };
      indexOp("/expo/index", data).then((res) => {
        if (res && res.data.state == 1000) {
          if (res.data.data.data.length > 0) {
            _this.expoPage = res.data.data.current_page;
            _this.expoList = res.data.data.data;
          } else {
            _this.expofinished = true;
          }
          _this.expoloading = false;
        }
        console.log(res);
      });
    },
    toDetail(id) {
      this.$router.push({
        path: "/joinerProductDetail",
        query: {
          id: id,
        },
      });
    },
    toExpoDetail(item) {
      this.$router.push({
        path: "/expoDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped >
</style>
