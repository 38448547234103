<template>
	<!-- 账单 -->
	<div class="bill-list">
		<Navbar :title="`账单日志`"></Navbar>
		<van-list class="list" v-model="loading" :finished="finished" :finished-text="list.length > 0 ? '没有更多了' : ''"
			@load="onLoad">
			<div v-for="(item, index) in list" :key="index" @click="toDetail(item.id)">
				<div class="item">
					<div class="content">
						<div style="display:flex;align-items:center;margin-bottom:10px;justify-content: space-between;">
							<span class="title" v-if="item.type == 'door_money_add_account'">上门检修费</span>
							<span class="title" v-else-if="item.type == 'service_money_account'">维修费用</span>
							<span class="title" v-else-if="item.type == 'deposit_money_account'">预付定金</span>
							<span class="title" v-else-if="item.type == 'deposit_back_off'">预付定金退回</span>
							<span class="title" v-else-if="item.type == 'user_vip_account'">会员充值</span>
							<span class="title" v-else-if="item.type == 'system_add'">后台充值</span>
							<span class="title" v-else-if="item.type == 'account_pay'">账户充值</span>
							<span class="title" v-else-if="item.type == 'account_pay_transfer'">账户转账</span>
							<span class="title" v-else>提现</span>
							<span style="color:red;">{{ item.operator + item.money}}</span>
							<!-- <span v-if="item.status == 0" style="font-size:12px;color:red;">（等待审核）</span> 
                <span v-if="item.status == 1" style="font-size:12px;color:red;">（已支付到微信账户）</span> 
                <span v-if="item.status == 2" style="font-size:12px;color:red;">（已退回到原账户余额）</span>  -->
						</div>
						<div style="display:flex;">
							<p class="title">{{item.title}}</p>
							<!-- <span v-if="item.status == 0" style="font-size:12px;color:red;">（等待审核）</span> 
                <span v-if="item.status == 1" style="font-size:12px;color:red;">（已支付到微信账户）</span> 
                <span v-if="item.status == 2" style="font-size:12px;color:red;">（已退回到原账户余额）</span>  -->
						</div>
						<!-- <p class="time" style="margin-bottom:5px;color:red;" v-if="item.status == 0">（等待审核）</p>
              <p class="time" style="margin-bottom:5px;color:red;" v-if="item.status == 0">（等待审核）</p> -->
						<p class="time">{{ item.updated_at }}</p>
					</div>
					<!-- <span class="money" v-if="item.status == 2">+ {{ item.money }}</span> -->
					<!-- <span class="money">{{ item.money }}</span> -->
				</div>
				<!-- <template v-else-if="item.pay_type != 'withdraw' && item.status != 0">
          <div class="item">
            <div class="content">
              <div style="display:flex;">
                <p class="title" v-if="item.pay_type == 'refund_deposit'">退还预付定金</p>
                <p class="title" v-if="item.pay_type == 'system_add'">系统后台充值金额</p>
                <p class="title" v-if="item.pay_type == 'system_deduct'">系统后台扣除金额</p>
                <p class="title" v-if="item.pay_type == 'deposit_money_wxpay'">微信支付维修订单预付款</p>
                <p class="title" v-if="item.pay_type == 'door_money_add_wxpay'">微信支付上门费用</p>
                <p class="title" v-if="item.pay_type == 'service_money_wxpay'">微信支付维修费用</p>
                <p class="title" v-if="item.pay_type == 'fittings_money_wxpay'">微信支付配件费</p>
                <p class="title" v-if="item.pay_type == 'user_vip_wxpay'">微信支付年费</p>
                <p class="title" v-if="item.pay_type == 'service_fittings_money_wxpay'">微信支付配件及维修费用</p>
                <p class="title" v-if="item.pay_type == 'account_pay'">账户充值</p>

                <p class="title" v-if="item.pay_type == 'deposit_money_account'">余额支付维修订单预付款</p>
                <p class="title" v-if="item.pay_type == 'door_money_add_account'">余额支付上门费用</p>
                <p class="title" v-if="item.pay_type == 'service_money_account'">余额支付维修费用</p>
                <p class="title" v-if="item.pay_type == 'fittings_money_account'">余额支付配件费用</p>
                <p class="title" v-if="item.pay_type == 'user_vip_account'">余额支付年费</p>
                <p class="title" v-if="item.pay_type == 'service_fittings_money_account'">余额支付配件及维修费用</p>
                <p class="title" v-if="item.pay_type == 'withdraw'">提现</p>
                <p class="title" v-if="item.pay_type == 'account_pay_transfer'">转账给- <span v-if="item.get_to_user_info">{{item.get_to_user_info.nickname}}</span></p>
                <p class="title" v-if="item.pay_type == 'account_earn_transfer'">转账-来自 <span v-if="item.get_from_user_info"> {{item.get_from_user_info.nickname}}</span></p>

                <span v-if="item.status == 2" style="font-size:12px;color:red;">（已退款）</span> 
              </div>
              <p class="time" style="margin-bottom:5px;color:#666;" v-if="item.desc">备注：{{ item.desc }}</p>
              <p class="time">{{ item.pay_time }}</p>
            </div>
            <span class="money" v-if="item.pay_type == 'account_pay'">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.pay_type == 'refund_deposit'">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.pay_type == 'account_earn_transfer'">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.pay_type == 'system_add'">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.pay_type == 'deposit_money_account' && item.status == 2">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.pay_type == 'account_pay_transfer' && item.status == 2">+ {{ item.money }}</span>
            <span class="money" v-else-if="item.status == 2">+ {{ item.money }}</span>
            <span class="money" v-else>- {{ item.money }}</span>

          </div>

        </template> -->
			</div>
			<div class="empty" v-if="list.length <= 0">
				<van-empty description="暂无数据" />
			</div>
		</van-list>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		getUser,
		login
	} from "../utils/login_toast";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				list: [],
				loading: false,
				finished: false,
				current_page: 0, //当前页
				last_page: "", //最后一页
				queryParam: {},
				userInfo: {
					id: "",
				},
				invate_uid: ""
			};
		},
		computed: {},
		created() {
			var _this = this;
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.WXlogin()
			this.setShare()
		},
		mounted() {},
		watch: {},
		methods: {
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-账单日志`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/bill?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				} else {
					// this.onLoad();
				}
			},
			getLoginUser(userInfo) {
				// this.onLoad();
				console.log(userInfo);
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			onLoad() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.current_page + 1;
				_this.queryParam.page = page;
				indexOp("/bill_log/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.data.last_page;
						_this.current_page = res.data.data.current_page;
						if (res.data.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							_this.finished = true
							_this.loading = false;
							return;
						}
						for (var i = 0; i < res.data.data.data.length; i++) {
							_this.list.push(res.data.data.data[i]);
							_this.loading = false;
						}
					}
				});
			},
			toDetail(id) {
				var _this = this;
				_this.$router.push({
					path: "/billDetail",
					query: {
						id: id
					}
				})
			},
		},
	};
</script>

<style scoped>
</style>