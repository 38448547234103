/**
 * 由于wxConfig.js不易复用
 * 新的对接 Wechat ，调用wechat的js
 */
import http from './request'

var wxChat = wx

export default  {
	init(callback,errCallback) {
		let _url = "";
		if (window.__wxjs_is_wkwebview === true) {
			_url = window.location.href.split("#")[0] || window.location.href;
		} else {
			_url = window.location.href;
		}
		//发起请求
		var data = {
			url: _url,
		};
		http
			.post("http://api.zglxgw.com/getSign", data)
			.then(function(res) {
				wxChat.config({
					debug: false,
					appId: res.data.appId, // 必填，公众号的唯一标识
					timestamp: res.data.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
					signature: res.data.signature, // 必填，签名，见附录1
					jsApiList: [
						"startRecord",
						"stopRecord",
						"playVoice",
						"pauseVoice",
						"stopVoice",
						"uploadVoice",
						"downloadVoice",
						"downloadVoice",
						"getLocation",
						"updateAppMessageShareData",
						"updateTimelineShareData",
						"chooseImage",
						"uploadImage",
						"downloadImage",
						"getLocalImgData",
						"onMenuShareAppMessage"
					], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
				});
				wxChat.ready(() => {
					callback && callback(wxChat);
				});
				wxChat.error(function(res) {
					errCallback && errCallback(false);
				});
			})
			.catch(function() {});
	},
	
	setShare() {
		
	},
	
	pay() {
	
	},
	
	location(callback) {
		this.init(function(initRes){
			initRes.getLocation({
				type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
				success: function(res) {
					callback && callback(res);
				},
				fail(){
					callback && callback(false);
				}
			});
		},function(err){
			callback && callback(false);
		});
	},
	
	chooseImage(param,callback){
		this.init(function(initRes){
			initRes.chooseImage(param);
		},function(err){
			callback && callback(false);
		});
	}
};
