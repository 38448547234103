<template>
  <div class="login-box">
    <div class="content">
      <img class="logo" src="@/assets/image/logo.png" alt="" />
      <div class="tips">聚一帮靠谱的人，做一件靠谱的事</div>
      <div class="phonelogin-box" v-if="isPhoneLogin">
        <div class="form-box">
          <div class="input-item">
            <van-icon class="icon" name="manager" />
            <input
              v-model="fromData.mobile"
              type="text"
              placeholder="请输入账户名"
            />
          </div>
          <div class="input-item">
            <van-icon class="icon" name="lock" />
            <input
              v-model="fromData.password"
              type="password"
              placeholder="请输入密码"
            />
          </div>
        </div>
        <!-- <button class="sub-btn" @click="onLogin">登录</button> -->
        <div class="btns">
          <van-button type="primary" block round @click="onLogin">立即登录</van-button>
          <van-button type="default" block round @click="onWxLogin">微信登录</van-button>
        </div>
        <!-- <div class="register-box">
          <div @click="onWxLogin">立即注册</div>
        </div>
        <van-divider
          :style="{
            color: '#666',
            borderColor: '#999999',
            padding: '0 16px',
            width: '100%',
          }"
        >
          其他登录方式
        </van-divider>
        <div class="btns">
          <div class="btn" @click="onWxLogin">
            <img src="@/assets/image/wx_01.png" alt="" />
            <span>微信登录</span>
          </div>
        </div> -->
      </div>
      <div class="center" v-else>
        <div class="btns">
          <van-button
            type="primary"
            icon="wechat"
            block
            round
            @click="onWxLogin"
            >微信快速登录</van-button
          >
          <van-button type="default" block round @click="phonelogin"
            ><i class="iconfont iconFill"></i> 手 机 号 登 录</van-button
          >
        </div>
      </div>
      <div class="xieyi">注册登录即视为您同意《冷鲜柜网条例》</div>
    </div>
  </div>
</template>

<script>
import { addOp } from "../../api";
import base from "../../api/base";
import { getUser, login } from "../../utils/login";
export default {
  data() {
    return {
      fromData: {},
      loginURL: base.loginURL,
      isPhoneLogin: false,

      back: 1,
    };
  },
  created() {
    const isPhone = this.$route.query.isPhone;
    const back = this.$route.query.back;
    console.log(isPhone);
    if (back) {
      this.back = back;
    }
    if (isPhone) {
      this.isPhoneLogin = isPhone;
    }
    var token = this.$cookies.get("Authorization");
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (code && state) {
      login(back);
    }
  },
  methods: {
    phonelogin() {
      const _this = this;
      _this.isPhoneLogin = true;
    },
    onLogin() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      if (_this.fromData.mobile == undefined) {
        _this.$toast("手机号不能为空！");
        return;
      }
      if (_this.fromData.password == undefined) {
        _this.$toast("密码不能为空！");
        return;
      }
      addOp("/oauth/user_login", _this.fromData).then(async (res) => {
        if (res && res.status == 200) {
          _this.$toast.success("登录成功！");
          _this.$cookies.set(
            "Authorization",
            res.data.token_type + " " + res.data.access_token
          );
          _this.$cookies.set("login_type", res.data.login_type);
          await getUser(_this.back);
          // setTimeout(() => {
          //   _this.$router.go(-1);
          // }, 800);
        } else {
          if (res.data.errors) {
            for (var key in res.data.errors) {
              _this.$toast.fail(res.data.errors[key][0]);
            }
          } else {
            _this.$toast.fail("登陆失败，请重试！");
          }
        }
      });
    },
    onWxLogin() {
      var _this = this;
      const url = encodeURIComponent(window.location.href);
      console.log(url);
      window.location.href = _this.loginURL + "?url=" + url;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>

<style scoped>
.content{
  padding-bottom: 30px;
}
.centent .logo {
  margin-bottom: 37px;
}
.form-box{
  margin-top: 50px;
}
.tips {
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
}
.center .btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 136px;
}
.btns button {
  width: 250px;
  height: 44px;
  margin-bottom: 28px;
  font-size: 16px;
}
.center .btns button:nth-child(2) {
  color: #ababab;
  border-color: #ababab;
}
.xieyi{
  font-size: 12px;
  color:#7d7d7d;
  text-align: center;
  position: fixed;
  bottom: 20px;
}
</style>
