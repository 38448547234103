<template>
	<div class="upload-video">
		<Navbar :title="'添加产品'"></Navbar>
		<div class="step" style="width:96vw;margin:0 auto;margin-top:10px;margin-bottom: 10px;">
			<van-steps :active="stepActive" @click-step="stepActiveChange" active-icon="success" active-color="#38f">
				<van-step>分类选择</van-step>
				<van-step>基本信息</van-step>
				<van-step>规格信息</van-step>
				<van-step>补充详情</van-step>
			</van-steps>
		</div>
		<block v-if="stepActive == 0">
			<div class="class-lbox">
				<div class="left">
					<div :class="'item ' + (index == active ? 'activeItem' : '') " v-for="(item,index) in classList"
						@click="active=index">
						<van-badge>
							<div>
								{{item.name}}
							</div>
						</van-badge>
					</div>
				</div>
				<div class="right">
					<div class="children-list">
						<block v-if="classList && classList.length && classList[active] && classList[active]._child">
							<div :class="'child ' + ((item.id == cid) ? 'select' : '') "
								v-for="(item,index) in classList[active]._child"
								@click="onClassSelect(classList[active].id,classList[active].name,item.id,item.name)">
								<van-badge>
									<div style="width:100%;padding-left:10px;padding-right:10px;">
										<span class="icon"><van-icon name="passed"
												style="position: relative;top:2px;right:5px;" /></span>{{item.name}}
									</div>
								</van-badge>
							</div>
						</block>
						<van-empty v-else image="error" description="暂无数据" />
					</div>

				</div>
			</div>
		</block>
		<!-- 第一步 -->
		<block v-else-if="stepActive==1">
			<div class="title">产品名称</div>
			<div class="input-item">
				<van-field class="customInput" v-model="formData.goods_name" autosize type="text"
					placeholder="请输入产品名称" />
			</div>
			<div class="title">产品数量</div>
			<div class="input-item">
				<van-field class="customInput" v-model="formData.amount" autosize type="number" placeholder="请输入产品数量" />
			</div>
			<div class="title">产品视频（最多1个）</div>
			<div class="upload-box" style="padding: 0 15px;">
				<van-uploader :before-read="uploadSignVideo" v-model="videoList" upload-text="选择视频"
					upload-icon="video" max-count="1" accept="video/*" :before-delete="uploadSignDelete">
				</van-uploader>
			</div>
			<div class="title">产品图片（最多5张）</div>
			<div class="upload-box-1" style="padding: 0 15px;" id="uploadPic">
				<van-uploader disabled style="position: relative;" multiple
					v-model="fileList" upload-text="选择产品图片" upload-icon="photo" max-count="5" accept="image/*"
					:before-delete="uploadDelete">

				</van-uploader>
			</div>
		</block>
		<block v-else-if="stepActive==2">
			<div class="sku-list">
				<van-cell-group inset :title="'规格' + (index + 1)" v-for="(item,index) in formData.sku">
					<van-cell title="型号" label="例如:BY-420">
						<van-field class="customInput" v-model="formData.sku[index].no" autosize type="text"
							placeholder="请输入产品型号" />
					</van-cell>
					<van-cell title="名称" label="例如:制冰机">
						<van-field class="customInput" v-model="formData.sku[index].name" autosize type="text"
							placeholder="请输入型号名称" />
					</van-cell>
					<van-cell title="尺寸" label="例如:1.1m*1.2m*2m">
						<van-field class="customInput" v-model="formData.sku[index].size" autosize type="text"
							placeholder="请输入产品尺寸" />
					</van-cell>
					<van-cell title="价格" label="例如:1500">
						<van-field class="customInput" v-model="formData.sku[index].price" autosize type="number"
							placeholder="请输入售卖价格" />
					</van-cell>
					<div class="opera" @click="deleteSku(index)">
						删除此规格
					</div>
				</van-cell-group>
				<div class="add-sku" @click="formData.sku.push({
							no : '',
							name : '',
							size : '',
							price : 0.00
						})">
					添加一组规格
				</div>
			</div>
		</block>
		<block v-else-if="stepActive==3">
			<div class="title">技术介绍</div>
			<div class="editor-box">
				<editor :menus="['image']" :value="htmlValue" @editor-input="onEditorChange">
				</editor>
			</div>
			<div class="title">售后保障</div>
			<div class="editor-box">
				<editor :menus="['image']" :value="afterHtmlValue" @editor-input="onAfterEditorChange"></editor>
			</div>
		</block>

		<div class="btn-box">
			<button style="background:#ddd;margin-bottom:10px;color:#000;" v-if="stepActive>0" class="btn"
				@click="stepActive--;goScrollTop()">上一步</button>
			<button v-if="stepActive<3" class="btn" @click="stepActive++;goScrollTop()">下一步</button>
			<button class="btn" v-if="stepActive==3" @click="onSubmit">提交</button>
		</div>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import Base64 from "../../utils/Base64.js";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";

	import Editor from "../../components/editor";
	import Storage from "../../utils/Storage.js";
	import Wechat from "../../utils/Wechat.js";

	import $ from "jquery";

	export default {
		components: {
			Navbar,
			Tabber,
			Editor,
		},
		props: {},
		data() {
			return {
				stepActive: 0,

				url: base.apiURL,

				fileList: [],
				videoList: [],

				formData: {
					goods_name : '',
					amount :'',
					image: [],
					video: '',
					sku: [{
						no: '',
						name: '',
						size: '',
						price: 0.00
					}]
				},

				userInfo: "",
				htmlValue: "",
				afterHtmlValue: '',
				showPicker: false,
				showPickerTwo: false,
				columns: [],
				columnsTwo: [],
				classValue: "",
				classValueTwo: "",
				classList: null,
				id: "", //厂商id
				cid: "", //分类id

				pid: 0,

				draft: {},
				active: 0,
				edit:false,
			};
		},
		computed: {},
		created() {
			this.userInfo = this.$cookies.get("userInfo");
			var _this = this;
			console.log("用户");
			// this.userInfo = this.$cookies.get("userInfo");
			this.id = this.$route.query.id;
			var code = _this.getUrlKey("code");
			var state = _this.getUrlKey("state");
			var token = _this.$cookies.get("Authorization");
			if (!token && !code) {
				loginAlert()
				return;
			} else if (!token && code) {
				login(this.getLoginUser);
				return;
			}
			this.getClassList();

			this.edit 	=	_this.getUrlKey('edit');
			if (Storage.get('goodsDraft') && !this.edit) {
				this.$dialog
					.confirm({
						title: "提示",
						confirmButtonText: "载入草稿",
						message: "当前有草稿未保存，是否载入草稿？",
					})
					.then(() => {
						_this.loadDraft();
					})
					.catch(() => {
						// on cancel
					});
			}
			console.log('Edit:',this.edit);
			if(this.edit){
				this.parseEditData();
			}
		},
		mounted() {},
		watch: {
			fileList() {
				var _this = this;
				_this.intervalUploder();
			},
			stepActive(value) {
				var _this = this;
				if (value == '1') {
					if (!this.cid || !this.pid) {
						this.$toast('请选择分类');
						this.stepActive = 0;
						return false;
					}
					_this.intervalUploder();
				}
			},
			formData(value) {
				this.draft.formData = value;
			},
			draft(value) {
				Storage.set('goodsDraft', this.draft);
			}
		},
		methods: {
			parseEditData(){
				var data 	=	Storage.get('productEditData');
				console.log('EditData:',data);
			},
			/**
			 * 定时处理上传
			 */
			intervalUploder() {
				var _this = this;
				try {
					let s = setInterval(function() {
						let uploder = $('#uploadPic .van-uploader__upload');
						if (uploder) {
							clearInterval(s);
							_this.$nextTick(function() {
								console.log(uploder);
								uploder.css({
									color: '#000',
									border: '1px solid #ddd'
								});
								uploder.click(function() {
									_this.chooseImage();
								});
								$('#uploadPic .van-uploader__input').remove();
								$('#uploadPic .van-uploader__wrapper--disabled').css('opacity', '1');
							})

						}
					}, 100);
				} catch (e) {
					console.log('Err:', e);
				}

			},
			/**
			 * 重构上传照片
			 */
			chooseImage() {
				var _this = this;
				if (_this.formData.image.length >= 5) {
					this.$toast('最多上传5个');
					return false;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				Wechat.chooseImage({
					count: 5,
					sizeType: ['original'],
					sourceType: ['album'],
					success(vres) {
						_this.$toast.clear();
						var localIds = vres.localIds;
						if (localIds.length === 0) return;

						for (var i in localIds) {
							wx.getLocalImgData({
								localId: localIds[i],
								success: function(res) {
									console.log(res);
									var baseImage = res.localData; // localData是图片的base64数据，可以用img标签显示
									if (baseImage.indexOf('data:image') !== 0) {
										baseImage = 'data:image/jpeg;base64,' + baseImage;
									}
									baseImage = baseImage
										.replace(/\r|\n/g, '')
										.replace('data:image/jpg', 'data:image/jpeg')
										.replace('data:image/JPG', 'data:image/jpeg');

									var filename = new Date().getTime();
									filename = filename + '.jpg';

									var one = _this.blobToFile(_this.base64ToFile(baseImage),
									filename);
									_this.uploadVideo(new File([one], 'test.jpg', {
										type: one.type
									}));
								}
							})
						}

					},
					fail() {
						_this.$toast.clear();
					}
				});
			},
			dataURLtoBlob(dataurl) {
				try {
					console.error('DATAURL:', dataurl)
					var arr = dataurl.split(',');
					if (arr.length == 1) {
						arr[1] = Base64.encode(arr[0]);
						arr[0] = 'data:image/jpeg;base64';
					}
					console.error('Arr:', arr);
					var mime = arr[0].match(/:(.*?);/)[1];
					var bstr = atob(arr[1].replace(/%0A|\s/g, ''));
					var n = bstr.length;
					var u8arr = new Uint8Array(n);
					while (n--) {
						u8arr[n] = bstr.charCodeAt(n);
					}
					return new Blob([u8arr], {
						type: mime
					});
				} catch (e) {
					console.log(e);
				}

			},
			//将blob转换为file
			blobToFile(theBlob, fileName) {
				theBlob.lastModifiedDate = new Date();
				theBlob.name = fileName;
				return theBlob;
			},
			base64ToFile(urlData) {
				const arr = urlData.split(',');
				const mime = arr[0].match(/:(.*?);/)[1];
				const bytes = atob(arr[1]); // 解码base64
				let n = bytes.length;
				const ia = new Uint8Array(n);
				while (n--) {
					ia[n] = bytes.charCodeAt(n);
				}
				return new Blob([ia], {
					type: mime
				});
			},
			stepActiveChange(e) {
				this.stepActive = e;
			},
			//获取分类列表
			getClassList() {
				var _this = this;
				var data = {
					id: _this.cid,
				};
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				indexOp("/goods_type/index", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						var tmpList = res.data;
						for (var i in tmpList) {
							tmpList[i].text = tmpList[i].name;
						}
						_this.classList = tmpList;
						console.log(_this.list);
					}
				});
			},
			loadDraft() {
				var _this = this,
					draft = Storage.get('goodsDraft');

				for (var i in draft) {
					this[i] = draft[i];
				}

				this.$forceUpdate();
				this.$toast.success('草稿载入成功');

			},
			safeDraft() {
				Storage.set('goodsDraft', this.draft);
			},
			/**
			 * 删除某个SKU
			 */
			deleteSku(index) {
				if (this.formData.sku.length <= 1) {
					this.$toast('最低需保留1个');
					return false;
				}
				this.formData.sku.splice(index, 1);

				this.draft.formData = this.formData;
				this.safeDraft();
			},
			/**
			 * 去顶部
			 */
			goScrollTop() {
				document.documentElement.scrollTop = 0;
			},
			//显示二级分类选择器
			onShowPickTwo() {
				const _this = this;
				if (_this.pid <= 0) {
					_this.$toast("请先选择一级分类！");
					return;
				}
				var data = {
					pid: _this.pid,
				};
				indexOp("/goods_type/get_list", data).then((res) => {
					if (res && res.status == 200 && res.data.length > 0) {
						_this.columnsTwo = res.data;
						this.showPickerTwo = true;
					}
				});
			},
			onClassSelect(pid, pidName, cid, cidName) {
				this.classValue = pidName;
				this.pid = pid;
				this.classValueTwo = cidName;
				this.cid = cid;


				this.draft.classValue = pidName;
				this.draft.pid = pid;
				this.draft.classValueTwo = cidName;
				this.draft.cid = cid;

				this.safeDraft();
			},
			//选择二级分类
			onConfirmTwo(value) {
				var val = this.$refs.classPicker.getValues();
				this.classValueTwo = value.name;
				this.cid = value.id;
				this.showPickerTwo = false;

				this.draft.classValueTwo = value.name;
				this.draft.cid = value.id;
				this.safeDraft();
			},
			//选择一级分类
			onConfirm(value) {
				var val = this.$refs.classPicker.getValues();
				this.classValue = value.name;
				this.pid = value.id;
				this.showPicker = false;

				this.draft.classValue = value.name;
				this.draft.pid = value.id;
				this.safeDraft();
			},
			onEditorChange(value) {
				this.htmlValue = value;
				this.draft.htmlValue = value;
				this.safeDraft();
				console.log(this.draft);
			},
			onAfterEditorChange(value) {
				this.afterHtmlValue = value

				this.draft.afterHtmlValue = value;
				this.safeDraft();
			},
			uploadBefore(file) {
				console.log('BeforeFile:', file, typeof file[0]);
				if (typeof file[0] == 'undefined') {
					this.uploadVideo(file);
				} else {
					for (var i in file) {
						this.uploadVideo(file[i]);
					}
				}
			},
			uploadDelete(event, index) {
				// 删除image
				this.formData.image = this.formData.image.splice(index.index, 1);
				this.fileList = this.fileList.splice(index.index, 1);
				return true;
			},
			uploadSignVideo(file) {
				console.log('File');
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file);
				file.status = "uploading";
				file.message = "上传中...";
				console.log('beginUpload');
				uploadFile(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.videoList	=	[_this.url + res.data.url]
						_this.formData.video 	=	res.data.url;
						_this.draft.formData = _this.formData;
						_this.draft.videoList = _this.videoList;
						_this.safeDraft();
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			uploadSignDelete(){
				this.formData.video 	=	'';
				this.videoList 	=	[];
			},
			uploadVideo(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file);
				file.status = "uploading";
				file.message = "上传中...";

				var isImage = true;
				if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name)) {
					isImage = true;
				}

				if (isImage) {
					uploadImg(form_data).then((res) => {
						if (res && res.status == 200) {
							file.status = "success";
							file.message = "";

							this.fileList.push({
								url: _this.url + res.data.url,
							});

							_this.formData.image.push(res.data.url);
							_this.draft.formData = _this.formData;
							_this.draft.fileList = _this.fileList;
							_this.safeDraft();

						} else {
							file.status = "failed";
							file.message = "上传失败";
						}
					});
				} else {
					uploadFile(form_data).then((res) => {
						if (res && res.status == 200) {
							file.status = "success";
							file.message = "";
							_this.formData.image.push(res.data.url);
							_this.draft.formData = _this.formData;
							_this.draft.fileList = _this.fileList;
							_this.safeDraft();
						} else {
							file.status = "failed";
							file.message = "上传失败";
						}
					});
				}

			},
			/**
			 * 发布
			 */
			onSubmit() {
				var _this = this;
				if (_this.cid == "") {
					_this.$toast.fail("请选择产品所在分类！");
					return;
				}
				if (_this.formData.goods_name == undefined) {
					_this.$toast.fail("产品名称不能为空！");
					return;
				}
				if (_this.formData.amount == undefined) {
					_this.$toast.fail("产品数量不能为空！");
					return;
				}
				if (_this.htmlValue == "") {
					_this.$toast.fail("产品信息不能为空！");
					return;
				}

				if (_this.formData.image == undefined) {
					_this.$toast.fail("请选择要上传的产品图片！");
					return;
				}
				_this.formData.cid = _this.cid;
				_this.formData.content = _this.htmlValue;
				_this.formData.security = _this.afterHtmlValue;

				let UserInfo = this.$cookies.get("userInfo");
				if (UserInfo.identity == 2) {
					_this.formData.good_type = 1;
				} else if (UserInfo.identity == 3) {
					_this.formData.good_type = 2;
				}
				_this.formData.mobile = UserInfo.mobile;
				_this.formData.image = _this.formData.image.join(',');

				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				console.log(_this.formData);
				addOp("/goods/store", _this.formData).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.$toast.success("上传成功！");
						Storage.remove('goodsDraft');
						setTimeout(() => {
							_this.$router.go(-1);
						}, 800);
					} else {
						_this.$toast(res.data.msg);
					}
					console.log(res);
				});
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped lang="less">
	.sku-list {
		padding: 0 15px;
		border: 2px;
		border-radius: 5px;
	}

	.opera {
		width: 100%;
		text-align: center;
		font-size: 12px;
		color: red;
		height: 35px;
		line-height: 35px;
	}

	.add-sku {
		height: 35px;
		line-height: 35px;
		text-align: center;
		background-color: #9e9e9e;
		color: #fff;
		font-size: 14px;
	}

	@ThemColor: #2F78DC;

	.class-lbox {
		background: #fff;
		display: flex;
		flex-direction: row;
		height: 50vh;
		width: 96vw;
		margin: 0 auto;

		.left {
			background: #f7f8fa;
			width: 30%;

			.item {
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 15px;
				padding-left: 10px;
				padding-right: 5px;
				width: 100%;
				font-weight: bold;

				.van-badge__wrapper {
					width: 100%;
				}

				border-left:3px solid #ddd;
			}

			.activeItem {
				background: #fff;
				border-left: 3px solid #2F78DC;
			}
		}

		.right {
			width: 70%;
		}
	}

	.children-list {
		width: 100%;
		height: 100%;
		padding: 10px;
		box-sizing: border-box;
		overflow-y: scroll;

		.select {
			background-color: @ThemColor !important;

			.icon {
				display: inline-block !important;
			}
		}

		.child {
			display: inline-block;
			background: #8c8c8c;
			border: 0;
			padding: 8px;
			padding-top: 5px;
			padding-bottom: 5px;
			border-radius: 2px;
			color: #fff;
			font-size: 14px;
			margin-left: 10px;
			min-width: 80px;
			text-align: center;
			margin-bottom: 10px;

			min-width: 93%;
			height: 40px;
			text-align: center;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;

			.van-badge__wrapper {
				width: 100%;
			}

			.icon {
				display: none;
			}

		}
	}

	.addInput {
		border: 1px solid @ThemColor;
		border-radius: 5px;
	}

	.addPopup {
		width: 80vw;
		height: auto;
		display: flex;
		flex-direction: column;
		padding: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		box-sizing: border-box;

		.title {
			color: @ThemColor;
			font-weight: bold;
			font-size: 16px;
		}

		.btn {
			width: 100%;
			background: @ThemColor;
			color: #fff;
			border: 0;
			margin-top: 15px;
			height: 40px;
			line-height: 40px;
		}

	}

	.btns {
		position: fixed;
		right: 15px;
		bottom: 50px;

		.btn {
			height: 35px;
			width: 100px;
			border-radius: 35px;
			font-size: 12px;
			margin: 10px auto 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: @ThemColor;
			box-shadow: 4px 6px 28px -10px rgba(79, 77, 77, 0.50);
			box-sizing: border-box;
			color: #fff;

			span {
				line-height: 1;
			}

			i {
				margin-right: 5px;
				color: #fff;
				font-size: 14px;
			}
		}
	}

	.van-uploader__input {
		pointer-events: none !important;
	}

	.van-uploader__preview-delete {
		position: absolute;
		z-index: 999;
	}
</style>