var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-list container"},[_c('Navbar',{attrs:{"title":'我的聊天'}}),_c('div',{staticClass:"list"},[_vm._l((_vm.list),function(item,index){return _c('van-swipe-cell',{key:index,scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-button',{staticClass:"delete-button",staticStyle:{"height":"100%"},attrs:{"square":"","text":"删除","type":"danger"},on:{"click":function($event){return _vm.onLogoutChat(item.chat_id)}}})]},proxy:true}],null,true)},[_c('router-link',{staticClass:"item",attrs:{"to":{
          path: '/chat',
          query: {
            chat_id: item.chat_id,
            from_uid: item.from_uid,
            to_uid: item.to_uid,
          },
        }}},[(item.chat_user.length > 2)?[_c('div',{staticClass:"img-box"},_vm._l((item.chat_user),function(src,idx){return _c('img',{key:idx,staticClass:"avatar",attrs:{"src":src.avatar,"alt":""}})}),0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"name-box"},[_c('p',{staticClass:"name"},[_vm._l((item.chat_user),function(src,index){return (_vm.uid != src.id)?[_vm._v(_vm._s(src.nickname)+"、")]:_vm._e()})],2)]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.updated_at))])])]:[_c('div',{staticClass:"imgs"},[_vm._l((item.chat_user),function(src){return [(_vm.uid != src.id)?_c('img',{staticClass:"avatar",attrs:{"src":src.avatar,"alt":""}}):_vm._e()]})],2),_c('div',{staticClass:"right"},[_vm._l((item.chat_user),function(src,index){return _c('div',{key:index},[(_vm.uid != src.id)?_c('p',{staticClass:"name"},[_vm._v(_vm._s(src.nickname))]):_vm._e()])}),_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.updated_at))])],2)],(item.unread_count > 0)?_c('van-tag',{staticClass:"tag",attrs:{"round":"","type":"danger"}},[_vm._v(_vm._s(item.unread_count))]):_vm._e()],2)],1)}),(_vm.list.length == 0)?_c('div',{staticClass:"empty"},[_c('van-empty',{attrs:{"image-size":"100","description":"暂无对话"}})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }