<template>
  <div class="order-release">
    <Navbar :title="'店铺入驻'"></Navbar>
    <h2 class="inpit-title">店铺名称</h2>
    <van-field
      v-model="formData.name"
      autosize
      type="text"
      placeholder="请输入店铺名称"
    />
    <h2 class="inpit-title">联系人</h2>
    <van-field
      v-model="formData.person"
      autosize
      type="text"
      placeholder="请输入联系人"
    />
    <h2 class="inpit-title">联系电话</h2>
    <van-field
      v-model="formData.phone"
      autosize
      type="text"
      placeholder="请输入联系电话"
    />
    <h2 class="inpit-title">维修地址</h2>
    <van-cell-group class="input-group">
      <van-field
        v-model="formData.province"
        placeholder="省"
        readonly
        clickable
        @click="showCityPicker = true"
      />
      <van-field
        v-model="formData.city"
        placeholder="市"
        readonly
        clickable
        @click="showCityPicker = true"
      />
      <van-field
        v-model="formData.area"
        placeholder="区"
        readonly
        clickable
        @click="showCityPicker = true"
      />
    </van-cell-group>
    <van-popup v-model="showCityPicker" round position="bottom">
      <van-area
        title="选择地址"
        :area-list="areaList"
        @cancel="showCityPicker = false"
        @confirm="onCityConfirm"
      />
    </van-popup>
    <div class="address-box">
      <van-field
        v-model="formData.address"
        autosize
        type="textarea"
        placeholder="请输入详细地址"
      />
      <div class="dingwei" @click="onShowMap">
        <van-icon name="location" />点击选择
      </div>
    </div>
    <h2 class="inpit-title">店铺介绍</h2>
    <van-field
      v-model="formData.desc"
      autosize
      type="textarea"
      placeholder="请输入店铺介绍"
    />
    <h2 class="inpit-title">店铺图片</h2>
    <van-uploader
      v-model="thumbList"
      multiple
      :max-count="1"
      :after-read="thumbAfterRead"
    />
    <h2 class="inpit-title">维修范围</h2>
    <van-field
      v-model="formData.service_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺维修范围"
    />
    <h2 class="inpit-title">服务区域</h2>
    <van-field
      v-model="formData.scope_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺服务区域"
    />
    <h2 class="inpit-title">身份证照片</h2>
    <div class="upload-flex">
        <van-uploader
            v-model="firstCardList"
            multiple
            :max-count="1"
            :after-read="firstCardAfterRead"
        >
            <div slot="default">
                <img class="img" src="@/assets/image/img2.png" alt="">
            </div>
        </van-uploader>
        <van-uploader
            v-model="lostCardList"
            multiple
            :max-count="1"
            :after-read="lostCardfterRead"
        >
            <div slot="default">
                <img class="img" src="@/assets/image/img3.png" alt="">
            </div>
        </van-uploader>
    </div>
    <h2 class="inpit-title">营业执照</h2>
    <van-uploader
      v-model="busList"
      multiple
      :max-count="1"
      :after-read="busAfterRead"
    />
    <h2 class="inpit-title">荣誉证书</h2>
    <van-uploader
      v-model="horList"
      multiple
      :max-count="3"
      :after-read="horAfterRead"
    />

    <van-button class="btn" type="info" @click="onSubmit"
      ><i class="iconfont iconjiahao"></i> 确认提交</van-button
    >
    <!-- <Tabber></Tabber> -->
    <map-choice
      v-if="mapShow"
      @config-map="onConfigMap"
      @close-map="onCloseMap"
    ></map-choice>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import province_list from "../../utils/area";
import MapChoice from "../map_choice";
import { indexOp, uploadImg ,updateOp,addOp} from "../../api";
import base from "../../api/base.js";
export default {
  components: {
    Tabber,
    Navbar,
    MapChoice,
  },
  props: {},
  data() {
    return {
      message: "",
      thumbList: [],//店铺图片
      firstCardList:[],//身份证正面
      lostCardList:[],//身份证反面
      horList:[],//荣誉证书
      busList:[],//营业执照
      radio: "",
      url: base.apiURL,
      showTimePicker: false, //时间选择器
      showCityPicker: false, //地址选择器
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      areaList: province_list,

      dateValue: "",
      province: "", //省
      city: "", //市
      area: "", //区
      formData: {
        thumb: "",
        hor_img:[],
        bus_img:""
      },
      mapShow: false, //地图组件显示

      depositList: [],
    };
  },
  computed: {},
  created() {
  },
  mounted() {},
  watch: {},
  methods: {
    onSubmit() {
      var _this = this;
      console.log(_this.formData);
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      addOp("/service_user/create",_this.formData).then(res=>{
        if(res && res.status == 201){
            _this.$toast.success("提交成功！")
            setTimeout(()=>{
                _this.$router.go(-1)
            },500)
        //   _this.$router.push({
        //     path:"/RepairDetail",
        //     query:{
        //       order_no:res.data.order_no
        //     }
        //   })
        }
        console.log(res)
      })
    },
    //单选
    onRadioChange(name) {
      this.formData.deposit_money = this.depositList[name].money;
    },
    //地图选择
    onConfigMap(data) {
      this.mapShow = false;
      if (data) {
        this.formData.address = data.poiaddress + data.poiname;
        // this.formData.lat = data.latlng.lat;
        // this.formData.lng = data.latlng.lng;
      }
      console.log(data);
    },
    //关闭地图选择
    onCloseMap() {
      this.mapShow = false;
    },
    //时间选择确认
    onTimeConfirm(value) {
      this.showTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      var h = value.getHours();
      var mm = value.getMinutes();
      var s = value.getSeconds();
      this.formData.accept_door_time =
        y + "-" + m + "-" + d + " " + h + ":" + mm;
    },
    //地区选择确认
    onCityConfirm(value, index) {
      this.showCityPicker = false;
      this.formData.province = value[0].name;
      this.formData.city = value[1].name;
      this.formData.area = value[2].name;
    },
    onShowMap() {
      this.mapShow = true;
    },
    //图片上传  店铺图片
    thumbAfterRead(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.thumb = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //图片上传  身份证正面
    firstCardAfterRead(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.card_img = _this.url + res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //图片上传  身份证反面
    lostCardfterRead(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.side_img = _this.url + res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //图片上传 荣誉证书
    horAfterRead(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.hor_img.push(_this.url + res.data.url);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //图片上传 营业执照
    busAfterRead(file) {
      var _this = this;
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.bus_img = _this.url + res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
  },
};
</script>

<style scoped >
</style>
