import service from './request'
import VueCookies from 'vue-cookies'
var websock = null;
var global_callback = null;
var audio = new Audio();
var audioSrc = '../audio/tips.mp3'
import {
  Toast,
  Dialog,
  Notify 
} from 'vant';

function initWebSocket() { //初始化weosocket
  var userInfo = VueCookies.get("userInfo");
  if (userInfo) {
    service.get("/websocket").then(res => {
      var serverPort = res.data; //webSocket连接端口
      var wsuri = "ws://47.104.154.76:" + serverPort + '?uid=' + userInfo.id;
      websock = new WebSocket(wsuri);
      websock.onmessage = function (e) {
        var res = JSON.parse(e.data)
        // console.log(res);
        if (res.type == "disconnect") {
          return;
        }
        if(res.data.from_uid != userInfo.id){
          Notify({ type: 'primary', message: '收到一条新消息！' });
          audio.src = audioSrc;
          audio.play();
        }
        websocketonmessage(e);
      }
      websock.onclose = function (e) {
        websocketclose(e);
      }
      websock.onopen = function (event) {
        websocketOpen();
        console.log(event);
      }

      //连接发生错误的回调方法
      websock.onerror = function () {
        console.log("WebSocket连接发生错误");
      }

    })

  }
}

// 实际调用的方法
function sendSock(agentData, callback,) {
  global_callback = callback;
  // console.log(agentData)
  if (websock.readyState === websock.OPEN) {
    //若是ws开启状态
    websocketsend(agentData)
  } else if (websock.readyState === websock.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback);
    }, 1000);
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback);
    }, 1000);
  }
}

function messageCock(callback) {
  global_callback = callback;
}
//数据接收
function websocketonmessage(e) {
  global_callback(JSON.parse(e.data));
  console.log(e)
}

//数据发送
function websocketsend(agentData) {
  console.log(agentData)
  websock.send(JSON.stringify(agentData));
}

//关闭
function websocketclose(e) {
  console.log("connection closed (" + e.code + ")");
}

function websocketOpen(e) {
  console.log("连接成功");
}

initWebSocket();

//获取URL参数
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg);  //匹配目标参数
  if (r != null) return unescape(r[2]); return null; //返回参数值
}
export {
  initWebSocket,
  sendSock,
  messageCock
}