<template>
	<div class="order-release">
		<Navbar :title="'完善维修信息'"></Navbar>
		<h2 class="inpit-title">店铺名称</h2>
		<van-field v-model="formData.name" autosize type="text" placeholder="请输入店铺名称" @blur="onblur" />
		<h2 class="inpit-title">联系人</h2>
		<van-field v-model="formData.person" autosize type="text" placeholder="请输入联系人" @blur="onblur" />
		<h2 class="inpit-title">联系电话</h2>
		<van-field v-model="formData.phone" autosize type="text" placeholder="请输入联系电话" @blur="onblur" />
		<h2 class="inpit-title">店铺地址</h2>
		<van-cell-group class="input-group">
			<van-field v-model="fieldValue[0]" placeholder="省" readonly clickable @click="showCityPicker = true" />
			<van-field v-model="fieldValue[1]" placeholder="市" readonly clickable @click="showCityPicker = true" />
			<van-field v-model="fieldValue[2]" placeholder="区" readonly clickable @click="showCityPicker = true" />
		</van-cell-group>
		<template v-if="areaList && areaList.length">
			<van-popup v-model="showCityPicker" round position="bottom">
				<van-cascader v-model="cascaderValue" title="请选择所在地区" :options="areaList"
					@close="showCityPicker = false" @finish="onFinish"
					:field-names="{ text: 'label', value: 'value', children: 'child' }" />
			</van-popup>
		</template>

		<!-- <van-popup v-model="showCityPicker" round position="bottom">
      <van-area
        title="选择地址"
        :area-list="areaList"
        @cancel="showCityPicker = false"
        @confirm="onCityConfirm"
      />
    </van-popup> -->
		<div class="address-box">
			<van-field v-model="fieldValue[3]" autosize type="textarea" placeholder="请输入详细地址" @blur="onblur" />
			<div class="dingwei" @click="onShowMap">
				<van-icon name="location" />点击选择
			</div>
		</div>
		<h2 class="inpit-title">店铺简介</h2>
		<van-field v-model="formData.desc" autosize type="textarea" placeholder="请输入公司简介" @blur="onblur" />
		<h2 class="inpit-title">店铺图片</h2>
		<van-uploader v-model="thumbList" multiple :max-count="1" :after-read="thumbAfterRead"
			:before-delete="thumbDetele" />
		<h2 class="inpit-title">维修范围</h2>
		<span style="font-size:14px;color:#0000ff;" @click="serviceTypeShow = true">点击选择维修范围</span>
		<div class="tag-box">
			<div class="top">
				<van-tag class="item" closeable size="medium" type="primary" v-for="(item,index) in result" :key="index"
					@close="onDeleteServiceType(index)">{{item}}</van-tag>
			</div>
			<div class="bottom">
				<!-- <textarea type="text" class="tag-input" placeholder="手动输入添加，每条请用‘，’号隔开" v-model="scope_desc"></textarea> -->
				<van-field class="tag-input" v-model="scope_desc" rows="1" autosize label="" type="textarea"
					placeholder="手动输入添加，每条请用‘，’号隔开" :formatter="formatter" format-trigger="onBlur" />
			</div>
		</div>
		<!-- <van-field
      v-model="formData.scope_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺维修范围"
      @blur="onblur"
      class="desc"
    /> -->
		<h2 class="inpit-title">
			服务区域
		</h2>
		<span style="font-size:14px;color:#0000ff;" @click="onShowServiceArea">点击选择服务区域</span>
		<div class="tag-box">
			<div class="top">
				<van-tag class="item" closeable size="medium" type="primary" v-for="(item,index) in areaResult"
					:key="index" @close="onDeleteServiceArea(index)">{{item}}</van-tag>
			</div>
			<div class="bottom">
				<van-field class="tag-input" v-model="service_desc" rows="1" autosize label="" type="textarea"
					placeholder="手动输入添加，每条请用‘，’号隔开" :formatter="formatter" format-trigger="onBlur" />
			</div>
		</div>
		<!-- <van-field
      v-model="formData.service_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺服务区域"
      @blur="onblur"
    /> -->
		<h2 class="inpit-title" v-if="ShopInfo.name && ShopInfo.person">
			身份证照片<span style="color:#666;font-weight: normal;font-size:13px;">(仅做审核使用，不会在页面公开展示)</span>
		</h2>
		<!-- <h2 class="inpit-title">
      身份证照片 <span style="color:#666;font-weight: normal;font-size:13px;">(仅做审核使用，不会在页面公开展示)</span>
    </h2> -->
		<div class="upload-flex" v-if="ShopInfo.name && ShopInfo.person">
			<van-uploader v-model="firstCardList" multiple :max-count="1" :after-read="firstCardAfterRead"
				:before-delete="firstCardDetele">
				<div slot="default">
					<img class="img" src="@/assets/image/img2.png" alt="" />
				</div>
			</van-uploader>
			<van-uploader v-model="lostCardList" multiple :max-count="1" :after-read="lostCardfterRead"
				:before-delete="lostCardDetele">
				<div slot="default">
					<img class="img" src="@/assets/image/img3.png" alt="" />
				</div>
			</van-uploader>
		</div>
		<h2 class="inpit-title" v-if="ShopInfo.name && ShopInfo.person">
			营业执照
		</h2>
		<van-uploader v-model="busList" multiple :max-count="3" :after-read="busAfterRead" :before-delete="busDetele"
			v-if="ShopInfo.name && ShopInfo.person" />
		<h2 class="inpit-title" v-if="ShopInfo.name && ShopInfo.person">
			荣誉证书
		</h2>
		<van-uploader v-model="horList" multiple :max-count="3" :after-read="horAfterRead" :before-delete="horDetele"
			v-if="ShopInfo.name && ShopInfo.person" />

		<van-checkbox v-model="checked" class="xieyi-checkbox" icon-size="16px">我已阅读并同意 <b
				@click.stop="xieyiShow = true">《维修师傅入驻协议》</b>
		</van-checkbox>
		<van-overlay :show="xieyiShow" @click="xieyiShow = false" :lock-scroll="false">
			<div class="xieyi-content">
				<div class="title">《维修师傅入驻协议》</div>
				<ul>
					<li>
						若您已经入驻为冷鲜柜网平台师傅，即表示您已充分阅读、理解并同意自己与冷鲜柜网平台订立本协议，且您自愿受本协议的条款约束。
					</li>
					<li>
						1、每一个入驻平台的制冷维修师傅，都应当向平台提供真实准确的注册信息，包括但不限于真实姓名、身份证号、联系电话、地址、邮政编码等。都必须要保证自己及自己店铺里的制冷维修师傅，做事踏实细致，维修认真负责，禁止欺骗忽悠客户。
					</li>
					<li>
						2、维修接单要严格遵守流程规则，先提前跟需求者谈好上门检查费、维修费、配件费等。
					</li>
					<li>
						3、维修师傅上门检修时，要仔细检查出来问题，并且拍照或录视频发至单子里的聊天记录里，这样才可以得到上门检修费。
					</li>
					<li>
						4、检查出来问题发在单子里的聊天记录里，再跟需求者谈维修费用及配件费，要让需求者明明白白的消费。
					</li>
					<li>
						5、为了保障师傅们的利益，在单子里的在线聊天，师傅自己跟需求者洽谈维修问题及上门检修费，谈好可以让需求方先预付费用到平台，平台给担保支付维修费用。
					</li>
					<li>
						6、为了保障需求者的利益，师傅得到的费用现在设置的在48个小时内，师傅不能直接提现，过了冻结期，师傅才可以提现。
					</li>
					<li>
						7、师傅在接到维修单子信息时，可以点开单子里的在线聊天，直接跟需求者洽谈联系，在聊天记录里，就可以直接先预付费用转账收款。
					</li>
					<li>
						8、师傅也可以接到单子信息后，自己私下交易，但平台不担保支付费用。
					</li>
					<li>
						9、平台上接单完成任务有留言评价机制，师傅所获评分高的，服务态度、技术好的，推荐共享给更多需求者。
					</li>
					<li>
						10、凡入驻平台的制冷维修师傅，需自己填写公司店铺资料，资料完整的才能在平台上予以展示。
					</li>
					<li>
						11、如因维修师傅的维修质量问题，造成返工及相关损失，由该维修师傅全部自行承担责任。
					</li>
					<li>
						12、师傅须自行负责自己的师傅账号和密码，且须对在师傅账号密码下发生的所有活动（包括但不限于发布服务信息、网上点击同意各类协议、规则、参与订单报价等）承担责任。师傅有权根据需要更改登录和账户提现密码。因师傅的过错导致的任何损失由师傅自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，师傅使用的手机被他人侵入。
					</li>
					<li>
						13、师傅承诺自己在使用平台实施的所有行为遵守法律、法规、行政规章和平台的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，师傅将独立承担相应的法律责任。
					</li>
					<li>
						14、冷鲜柜网平台只是为师傅提供一个服务交易的平台，对于平台所发布的订单的合法性、真实性及其品质，以及师傅履行交易的能力等，平台一律不负任何担保责任。
					</li>
					<li>
						15、入驻平台，注册会员，身份选择，一个微信号，一个手机号码，只能注册一个会员身份。维修师傅选择身份时，只有选择维修师傅，有业务时，才可以有权接维修单。
					</li>
					<li>
						聚制冷精英，集行业资源，方便您我她，通过搭建资源共享平台，让更多优秀的制冷人才聚集在一起。
					</li>
				</ul>
			</div>
		</van-overlay>
		<van-button class="btn" type="info" @click="onSubmit"><i class="iconfont iconjiahao"></i> 确认提交</van-button>
		<!-- <Tabber></Tabber> -->
		<map-choice v-if="mapShow" @config-map="onConfigMap" @close-map="onCloseMap"></map-choice>

		<!-- 选择维修范围 -->
		<van-overlay :show="serviceTypeShow" @click="serviceTypeShow = false">
			<div class="serviceType-mask" @click.stop>
				<div class="title">选择维修范围</div>
				<van-tabs v-model="serviceTypeActive" color="#2F78DC">
					<van-tab title="商用电器">
						<div class="list">
							<van-checkbox-group v-model="result">
								<van-checkbox class="service-checkbox" label-position="left"
									v-for="(item,index) in serviceTypeList" :key="index" :name="item.service_name"
									v-if="item.service_type == 1">{{item.service_name}}</van-checkbox>
							</van-checkbox-group>
						</div>
					</van-tab>
					<van-tab title="家用电器">
						<div class="list">
							<van-checkbox-group v-model="result">
								<van-checkbox class="service-checkbox" label-position="left"
									v-for="(item,index) in serviceTypeList" :key="index" :name="item.service_name"
									v-if="item.service_type == 2">{{item.service_name}}</van-checkbox>
							</van-checkbox-group>
						</div>
					</van-tab>
				</van-tabs>
				<button class="btn" @click="onSubServiceType">提交</button>
			</div>
		</van-overlay>
		<!-- 选择维修区域 -->
		<van-overlay :show="serviceAreaShow" @click="serviceAreaShow = false">
			<div class="serviceType-mask" @click.stop>
				<div class="title">选择维修区域</div>
				<div class="list">
					<van-checkbox-group v-model="areaResult">
						<!-- 三元运算符兼容首次获取和第二次获取的数据格式不同问题 -->
						<van-checkbox class="service-checkbox" label-position="left"
							v-for="(item,index) in serviceAreaList" :key="index"
							:name="item.label ? item.label : item.area_name">{{item.label ? item.label : item.area_name}}
						</van-checkbox>
					</van-checkbox-group>
				</div>
				<button class="btn" @click="onSubServiceArea">提交</button>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import province_list from "../utils/address";
	import MapChoice from "./map_choice";
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp
	} from "../api";
	import base from "../api/base.js";
	import {
		login
	} from "../utils/login_toast";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import $ from "jquery"
	export default {
		components: {
			Tabber,
			Navbar,
			MapChoice,
		},
		props: {},
		data() {
			return {
				message: "",
				thumbList: [], //店铺图片
				firstCardList: [], //身份证正面
				lostCardList: [], //身份证反面
				horList: [], //荣誉证书
				busList: [], //营业执照
				radio: "",
				url: base.apiURL,
				showTimePicker: false, //时间选择器
				showCityPicker: false, //地址选择器
				minDate: new Date(2000, 0, 1),
				maxDate: new Date(2050, 10, 1),
				currentDate: new Date(),
				areaList: [],

				dateValue: "",
				province: "", //省
				city: "", //市
				area: "", //区
				formData: {
					name: "",
					thumb: "",
					address: "",
					desc: "",
					service_desc: "",
					scope_desc: "",
					side_img: "",
					card_img: "",

					hor_img: [],
					bus_img: [],
				},
				mapShow: false, //地图组件显示

				depositList: [],
				ShopInfo: "",
				userInfo: {
					id: ""
				},
				loginUrl: base.loginURL,
				xieyiShow: false,
				checked: true,

				serviceTypeActive: 0,
				serviceTypeList: [],
				serviceTypeShow: false,
				result: [],

				serviceAreaList: [],
				serviceAreaShow: false,
				areaResult: [],
				serviceAreaText: [],

				cascaderValue: "",
				fieldValue: [],
				areaValue: [],

				scope_desc: "",
				service_desc: ""
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			this.$cookies.set("invate_uid", invate_uid);
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && !code) {
				window.location.href = this.loginUrl;
			} else if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			} else {
				this.getShifuDetail();
			}
			this.setShare();
			this.getServiceType()
			// 获取地区
			this.getServiceRegion();

		},
		mounted() {

		},
		watch: {},
		methods: {
			formatter(value) {
				// 过滤输入的数字
				return value.replace(/，/g, ',');
			},
			onDeleteServiceArea(index) {
				var _this = this;
				_this.areaResult.splice(index, 1);
				// _this.formData.service_desc = this.areaResult.join(",")
				_this.onblur()
			},
			onShowServiceArea() {
				var _this = this;
				if (_this.serviceAreaList.length == 0) {
					_this.$toast.fail("请选先选择店铺地址，再进行此操作！");
					return;
				}
				_this.serviceAreaShow = true;
			},
			onFinish({
				selectedOptions
			}) {
				this.showCityPicker = false;
				this.fieldValue = selectedOptions.map((option) => option.label);
				this.fieldValue.push(this.fieldValue[0] + this.fieldValue[1] + this.fieldValue[2]);

				// this.formData.province = this.fieldValue[0];
				// this.formData.city = this.fieldValue[1];
				// this.formData.area = this.fieldValue[2];
				// this.formData.address = this.fieldValue[0] + this.fieldValue[1] + this.fieldValue[2]

				// 2021.1.16 增加地区存储
				this.areaValue = selectedOptions.map((option) => option.value);
				this.formData.province = this.areaValue[0];
				this.formData.city = this.areaValue[1];
				this.formData.area = this.areaValue[2];
				this.formData.address = this.fieldValue[0] + this.fieldValue[1] + this.fieldValue[2];

				// 服务区域
				this.serviceAreaList = selectedOptions[1].child;

				this.onblur();
			},
			//提交服务区域
			onSubServiceArea() {
				var _this = this;

				_this.serviceAreaShow = false;
				_this.formData.service_desc = this.areaResult.join(",");

				_this.onblur()
			},
			//删除维修范围
			onDeleteServiceType(index) {
				var _this = this;
				_this.result.splice(index, 1)
				_this.formData.scope_desc = this.result.join(",")
				_this.onblur()
			},
			//维修范围选择
			toggle(index) {
				this.$refs.checkboxes[index].toggle();
			},
			//提交服务范围
			onSubServiceType() {
				var _this = this;
				_this.serviceTypeShow = false
				_this.formData.scope_desc = this.result.join(",")
				_this.onblur()
			},
			//获取地区列表
			getServiceRegion() {
				var _this = this;
				indexOp("/area/all_format_child").then(res => {
					_this.areaList = res.data;
				})
			},
			//获取服务范围列表
			getServiceType() {
				var _this = this;
				indexOp("/service_type").then(res => {
					if (res && res.status == 200) {
						_this.serviceTypeList = res.data
					}
				})
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				_this.userInfo = userInfo;
				this.getShifuDetail();
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-师傅入驻`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/editShifuinfo?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			onblur() {
				var _this = this;
				addOp("/service_user/create", _this.formData).then((res) => {
					if (res && res.data) {}
				});
			},
			onSubmit() {
				var _this = this;
				console.log(_this.formData)
				if (!_this.checked) {
					_this.$toast.fail("请先勾选同意《维修师傅入驻协议》");
					return;
				}
				if (_this.formData.name == "") {
					_this.$toast.fail("请输入店铺名称！");
					return;
				}
				if (_this.formData.person == "") {
					_this.$toast.fail("请输入联系人！");
					return;
				}
				if (_this.formData.phone == "") {
					_this.$toast.fail("请输入联系电话！");
					return;
				}
				if (_this.formData.address == "") {
					_this.$toast.fail("请输入店铺地址！");
					return;
				}
				// if (_this.formData.desc == "") {
				// 	_this.$toast.fail("请输入店铺介绍！");
				// 	return;
				// }
				if (_this.formData.service_desc == "" && _this.service_desc == "") {
					_this.$toast.fail("请选择服务区域！");
					return;
				}
				if (_this.formData.scope_desc == "" && _this.scope_desc == "") {
					_this.$toast.fail("请选择维修范围！");
					return;
				}
				// if (_this.formData.thumb == "") {
				// 	_this.$toast.fail("请上传店铺图片！");
				// 	return;
				// }
				_this.formData.service_desc = _this.formData.service_desc + ',' + _this.service_desc;
				_this.formData.scope_desc = _this.formData.scope_desc + ',' + _this.scope_desc;
				_this.formData.service_desc = _this.formData.service_desc.replace(/^(\s|,)+|(\s|,)+$/g, "");
				_this.formData.scope_desc = _this.formData.scope_desc.replace(/^(\s|,)+|(\s|,)+$/g, "");
				// console.log(_this.formData)
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				// return;
				addOp("/service_user/create", _this.formData).then((res) => {
					_this.$toast.clear();
					if (res && res.data) {
						_this.$toast.success("提交成功！");
						setTimeout(() => {
							_this.$router.push("/user");
						}, 500);
						//   _this.$router.push({
						//     path:"/RepairDetail",
						//     query:{
						//       order_no:res.data.order_no
						//     }
						//   })
					}
					console.log(res);
				});
			},
			/**
			 * 获取师傅详情
			 */
			getShifuDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var userInfo = _this.userInfo;
				var data = {
					uid: userInfo.id,
				};
				indexOp("/service_user/show", data).then((res) => {
					_this.$toast.clear();
					if (res && res.data) {
						_this.ShopInfo = res.data;
						_this.formData.name = res.data.name;
						_this.formData.person = res.data.person;
						_this.formData.phone = res.data.phone;
						_this.formData.province = res.data.province;
						_this.formData.city = res.data.city;
						_this.formData.area = res.data.area;
						_this.province = res.data.province;
						_this.city = res.data.city;
						_this.area = res.data.area;
						_this.formData.address = res.data.address;
						_this.formData.desc = res.data.desc;
						_this.formData.service_desc = res.data.service_desc;
						// 2022.1.16 修复 Bug
						_this.areaResult = res.data.service_desc ? res.data.service_desc.split(',') : [];
						_this.formData.scope_desc = res.data.scope_desc;
						// 2022.1.16 修复 Bug
						_this.result = res.data.scope_desc ? res.data.scope_desc.split(",") : [];
						_this.formData.card_img = res.data.card_img;
						_this.formData.thumb = res.data.thumb;
						_this.formData.side_img = res.data.side_img;

						// 2022.1.16 新增 处理获取师傅信息之后的页面兼容
						if (res.data.province_info) {
							_this.fieldValue[0] = res.data.province_info.area_name;
						}

						if (res.data.city_info) {
							_this.fieldValue[1] = res.data.city_info.area_name;
						}

						if (res.data.area_info) {
							_this.fieldValue[2] = res.data.area_info.area_name;
						}

						if (res.data.address) {
							_this.fieldValue[3] = res.data.address;
						} else {
							var tmpAddress = '';
							if (res.data.province_info) {
								tmpAddress += res.data.province_info.area_name;
							}

							if (res.data.city_info) {
								tmpAddress += res.data.city_info.area_name;
							}

							if (res.data.area_info) {
								tmpAddress += res.data.area_info.area_name;
							}
							_this.fieldValue[3] = tmpAddress;
						}

						// 来获取该地区相同区域的地区
						if (res.data.area) {
							_this.getAreaSameArea(res.data.area, function(res) {
								if (res && res.data) {
									_this.serviceAreaList = res.data;
								}
							});
						}

						// ----- Insert End -----


						if (res.data.thumb) {
							var data = {
								url: _this.url + res.data.thumb,
							};
							_this.thumbList.push(data);
						}
						if (res.data.card_img) {
							var data = {
								url: res.data.card_img,
							};
							_this.firstCardList.push(data);
						}
						if (res.data.side_img) {
							var data = {
								url: res.data.side_img,
							};
							_this.lostCardList.push(data);
						}
						if (res.data.hor_img && res.data.hor_img.length > 0) {
							_this.formData.hor_img = res.data.hor_img;
							for (var key in res.data.hor_img) {
								var data = {
									url: res.data.hor_img[key],
								};
								_this.horList.push(data);
							}
						}
						if (res.data.bus_img && res.data.bus_img.length > 0) {
							_this.formData.bus_img = res.data.bus_img;
							res.data.bus_img.map((item) => {
								var data = {
									url: item,
								};
								_this.busList.push(data);
							})

							console.log(_this.busList)
						}
					}
				});
			},
			/**
			 * 获取相同身份下的所有区域
			 */
			getAreaSameArea: function(area_id, callback) {
				indexOp('/area/get_same_area', {
					area_id: area_id
				}).then((res) => {
					return typeof callback == 'function' ? callback(res) : res;
				});
			},
			//单选
			onRadioChange(name) {
				this.formData.deposit_money = this.depositList[name].money;
			},
			//地图选择
			onConfigMap(data) {
				this.mapShow = false;
				if (data) {
					this.formData.address = data.poiaddress + data.poiname;
					// this.formData.lat = data.latlng.lat;
					// this.formData.lng = data.latlng.lng;
				}
				console.log(data);
			},
			//关闭地图选择
			onCloseMap() {
				this.mapShow = false;
			},
			//时间选择确认
			onTimeConfirm(value) {
				this.showTimePicker = false;
				var y = value.getFullYear();
				var m = value.getMonth() + 1;
				var d = value.getDate();
				var h = value.getHours();
				var mm = value.getMinutes();
				var s = value.getSeconds();
				this.formData.accept_door_time =
					y + "-" + m + "-" + d + " " + h + ":" + mm;
			},
			//地区选择确认
			onCityConfirm(value, index) {
				this.showCityPicker = false;
				this.formData.province = value[0].name;
				this.formData.city = value[1].name;
				this.formData.area = value[2].name;
				this.formData.address = value[0].name + value[1].name + value[2].name
				this.onblur();
			},
			onShowMap() {
				this.mapShow = true;
			},
			//图片上传  店铺图片
			thumbAfterRead(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.thumb = res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  店铺图片
			thumbDetele(file) {
				var _this = this;
				_this.thumbList = [];
				_this.formData.thumb = "";
				console.log(file);
			},
			//图片上传  身份证正面
			firstCardAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.card_img = _this.url + res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  身份证正面
			firstCardDetele(file) {
				var _this = this;
				_this.firstCardList = [];
				_this.formData.card_img = "";
				console.log(file);
			},
			//图片上传  身份证反面
			lostCardfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.side_img = _this.url + res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  身份证反面
			lostCardDetele(file) {
				var _this = this;
				_this.lostCardList = [];
				_this.formData.side_img = "";
				console.log(file);
			},
			//图片上传 荣誉证书
			horAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.hor_img.push(_this.url + res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  荣誉证书
			horDetele(file, detail) {
				var _this = this;
				_this.horList.splice(detail.index, 1);
				_this.formData.hor_img.splice(detail.index, 1);
			},
			//图片上传 营业执照
			busAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.bus_img.push(_this.url + res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  营业执照
			busDetele(file, detail) {
				var _this = this;
				_this.busList.splice(detail.index, 1);
				_this.formData.bus_img.splice(detail.index, 1);
			},
		},
	};
</script>

<style scoped>
	.serviceType-mask {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 400px;
		background: #fff;
		border-radius: 8px;
		padding: 0 10px;
	}

	.serviceType-mask .title {
		text-align: center;
		padding: 10px 0;
		border-bottom: 1px solid #f2f2f2;
	}

	.serviceType-mask .list {
		margin-top: 10px;
		padding: 0 20px;
		height: 210px;
		overflow-y: auto;
	}

	.serviceType-mask .btn {
		color: #fff;
		text-align: center;
		border: none;
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}

	.service-checkbox {
		justify-content: space-between;
		padding: 5px 0;
	}

	.tag-box {
		padding: 5px;
		border: 1px solid #c9c9c9;
		margin-top: 5px;
		min-height: 80px;
	}

	.tag-box .item {
		margin-right: 5px;
		margin-bottom: 5px;
	}

	.tag-box .tag-input {
		width: 100%;
		background: transparent;
		border: none;
		font-size: 14px;
		padding: 0;
	}
</style>