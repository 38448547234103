<template>
	<div class="sponsor-index">
		<Navbar :title="'主办方主页'"></Navbar>
		<div class="organiser">
			<div class="bg">
				<div class="info">
					<div class="left">
						<img class="avatar" :src="baseUrl + info.company_logo" alt="" />
					</div>
					<div class="right">
						<div class="title">主办方名称：{{ info.company_name }}</div>
						<div class="address">主办方地址：{{ info.company_address }}</div>
						<div class="address">
							<span>联系方式：{{ info.company_phone }}</span>
							<div class="btn" @click="toChat(info.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="desc-box">
			<div class="title-box">
				<span>企业简介</span>
			</div>
			<div class="text">{{ info.company_desc }}</div>
		</div>
		<div class="expo-list">
			<div class="title-box">
				<span>展会列表</span>
			</div>
			<van-list v-model="loading" :finished="finished" :finished-text="expoList.length > 0 ? '没有更多了' : ''"
				@load="getExpoList">
				<div class="item" v-for="(item, index) in expoList" :key="index" @click="toDetail(item.id)">
					<div class="banner-box">
						<img class="banner-img" :src="baseUrl + item.images[0]" alt="" />
						<div class="banner-title">{{ item.title }}</div>
						<div class="status active">{{ item.format_time_text }}</div>
					</div>
					<div class="content-box">
						<div class="title">
							主办方：{{ item.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							<i class="iconfont iconweizhi"></i>展会地点：{{ item.address }}
						</div>
						<div class="time">
							<div class="left">
								<van-icon name="clock-o" />展会时间：{{ item.start_time }} 至
								{{ item.end_time }}
							</div>
							<div class="right"><van-icon name="friends-o" />20家报名</div>
						</div>
					</div>
				</div>
				<van-empty v-if="expoList.length == 0" description="暂无数据" />
			</van-list>
		</div>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				info: {},
				baseUrl: base.apiURL,
				loginURL: base.loginURL,

				expoList: [],
				loading: false,
				finished: false,
				tabActive: "1",
				userInfo: {
					id: "",
				},
				invate_uid: "",

				page: "",
				sponsor_id: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			if (this.userInfo.id == "") {
				loginAlert()
			}
			this.sponsor_id = this.$route.query.id;
			this.getShow();
		},
		mounted() {},
		watch: {},
		methods: {
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-会展中心`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL +
						"/joinerIndex?id=" +
						this.sponsor_id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			toChat(toId) {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = toId; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					loginAlert()
				}
			},

			getShow() {
				var _this = this;
				var data = {
					uid: _this.sponsor_id,
				};
				indexOp("/expo_sponsor_user/info", data).then((res) => {
					if (res.data.state == 1000) {
						_this.info = res.data.data;
						_this.setShare();
					} else {
						_this.$toast.fail(res.data.msg);
						_this.$router.go(-1);
					}
				});
			},
			//获取展会列表
			getExpoList() {
				var _this = this;
				var data = {
					page: _this.page + 1,
					uid: _this.sponsor_id,
				};
				indexOp("/expo_sponsor_user/expo_list", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.expoList = res.data.data.data;
							_this.page = res.data.data.current_page;
						} else {
							_this.finished = true;
						}
						_this.loading = false;
					}
					console.log(res);
				});
			},
			toDetail(id) {
				this.$router.push({
					path: "/expoDetail",
					query: {
						id: id,
					},
				});
			},
		},
	};
</script>

<style scoped>
</style>