import http from '@/utils/request'
import VueCookies from 'vue-cookies'
import base from "../api/base"
import * as socketApi from './webSocket'
import global from '../global/global'
import {
  Toast,
  Dialog
} from 'vant';
import router from '../router';
//登录
export function login(back) {
  let Back = 1
  if(back){
    Back = back
  }
  var code = getUrlKey("code");
  var state = getUrlKey("state");
  const invate_uid = VueCookies.get("invate_uid");
  //判断地址栏参数
  if (code && state) {
    //登录
    http.get(`/oauth/callback`, {
        params: {
          code: code,
          state: state,
          invate_uid: invate_uid
        }
      })
      .then(async function (res) {
        console.log(res)
        if (res && res.status == 200 && res.data.access_token) {
          Toast.success("登陆成功！")
          VueCookies.set(
            "Authorization",
            res.data.token_type + " " + res.data.access_token
          );
          VueCookies.set("login_type", res.data.login_type);
          //获取用户信息
          await getUser(Back)
        } else {
          Toast.fail(res.data.msg)
          // window.location.href = "http://www.zglxgw.com/";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
//获取地址栏参数
function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) || null
  );
};
//获取用户信息
export function getUser(back) {
  http.get("/user/show/1").then(async res => {
    if (res && res.status == 200) {
      VueCookies.set("userInfo", res.data)
      VueCookies.set("identity", res.data.identity)
      addAccount(res.data.id)
      await socketApi.initWebSocket();
      var date = new Date();
      var createdTime = new Date(res.data.created_at)
      // console.log(date.getHours())
      // console.log(createdTime.getHours())
      console.log('是否设置身份',res.data.identity == null)
      const back_o = getUrlKey("back")
      console.log(back_o)
      if (date.getHours() == createdTime.getHours()) {
        if (res.data.identity == null) {
          // const back_o = getUrlKey("back")
          if(back_o){
            router.push({
              path:"/identity",
              query:{
                back:back_o
              }
            });
          }else{
            router.push({
              path:"/identity",
              query:{
                back:back
              }
            });
          }
          
        } else {
          if(back == 1){
            router.go(-back);
          }else{
            router.push("/"+back)
          }
        }
      } else {
        if(back == 1){
          router.go(-back);
        }else{
          router.push("/"+back)
        }
      }
    }
  })
};

function addAccount(id) {
  var _this = this;
  var data = {
    parent_uid: id,
  };
  http.post("/login_log/store", data).then((res) => {});
};
// login();