<template>
  <div class="repair-detail shifu-bidding">
    <Navbar :title="'维修详情'"></Navbar>
    <div class="order-info">
      <div class="title-box">
        <span>订单信息</span>
        <span class="time">发布时间：{{ info.created_at }}</span>
      </div>
      <div class="center">
        <div class="describe">
          <div class="left">
            <p class="item tit">维修产品名称：{{ info.name }}</p>
            <p class="item">维修描述：{{ info.desc }}</p>
            <p class="item">
              商品质保：{{ info.is_time_out == 0 ? "保内" : "保外" }}
            </p>
          </div>
          <div class="right" v-if="info.get_user_info">
            <img :src="info.get_user_info.avatar" alt="" />
            <span>{{ info.get_user_info.nickname }}</span>
          </div>
        </div>
        <div class="img-box" v-if="info.image && info.image.length > 0">
          <span>故障产品照片：</span>
          <div class="imgs">
            <img
              v-for="(item, index) in info.image"
              :key="index"
              :src="item"
              alt=""
              @click="onPreviewImg(info.image)"
            />
          </div>
        </div>
        <p class="position">
          <i class="iconfont iconweizhi"></i>
          <a
            v-if="info.lat && info.lng"
            :href="`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${info.lat},${info.lng};addr:${info.address}&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp`"
          >
            {{ info.province }}{{ info.city }}{{ info.area }}{{ info.address }}
          </a>
          <a
            v-else
            :href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`"
          >
            {{ info.province }}{{ info.city }}{{ info.area }}{{ info.address }}
          </a>
        </p>
        <!-- <div class="position">
          <i class="iconfont iconshouji"></i>{{ info.mobile }}
        </div> -->

        <a :href="`tel:${info.mobile}`" @click.stop class="position">
          <i class="iconfont iconshouji"></i>{{ info.mobile }}
        </a>
      </div>
      <div class="status-box" v-if="info.deposit_pay_status == 1">
        <span class="status">预付定金</span>
        <span class="icon"><i class="iconfont iconrenminbi"></i></span>
      </div>
      <div class="status-box" v-else>
        <span class="status">未预付费用</span>
        <span class="icon"><i class="iconfont iconrenminbi"></i></span>
      </div>

      <div class="coll" @click="toChat">
        <i class="iconfont iconduihua"></i>
        <span>在线聊天</span>
      </div>
    </div>

    <div class="baojia">
      <div class="title-box">
        <span>报价维修</span>
      </div>
      <div class="input-item">
        <span class="tit">上门检修费</span>
        <input v-model="formData.door_money" type="number" />
      </div>
      <div class="input-item">
        <span class="tit">预计上门时间</span>
        <input
          v-model="formData.plan_door_time"
          type="text"
          readonly
          clickable
          @click="showTimePicker = true"
        />
        <van-popup v-model="showTimePicker" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择上门时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="showTimePicker = false"
            @confirm="onTimeConfirm"
          />
        </van-popup>
      </div>
      <div class="input-item">
        <span class="tit">联系人</span>
        <input v-model="shopFrom.person" type="text" />
      </div>
      <div class="input-item">
        <span class="tit">联系电话</span>
        <input v-model="shopFrom.phone" type="number" />
      </div>
      <!-- <div class="input-item">
          <span class="tit">是否保修</span>
          <van-radio-group
            v-model="formData.is_guarantee"
            direction="horizontal"
          >
            <van-radio name="1" style="font-size: 12px" icon-size="16px"
              >是</van-radio
            >
            <van-radio name="0" style="font-size: 12px" icon-size="16px"
              >否</van-radio
            >
          </van-radio-group>
        </div>
        <div class="input-item" v-if="formData.is_guarantee == 1">
          <span class="tit">承诺保修期</span>
          <input
            v-model="formData.guarantee_begin_time"
            type="text"
            placeholder="保修开始时间"
            readonly
            clickable
            @click="showStartTimePicker = true"
          />
          <van-popup v-model="showStartTimePicker" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择保修期开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showStartTimePicker = false"
              @confirm="onStartTimeConfirm"
            />
          </van-popup>
        </div>
        <div class="input-item" v-if="formData.is_guarantee == 1">
          <span class="tit"></span>
          <input
            v-model="formData.guarantee_end_time"
            type="text"
            placeholder="保修结束时间"
            readonly
            clickable
            @click="showEndTimePicker = true"
          />
          <van-popup v-model="showEndTimePicker" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择保修期开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showEndTimePicker = false"
              @confirm="onEndTimeConfirm"
            />
          </van-popup>
        </div> -->
      <van-button class="btn" type="info" @click="setShop"
        ><i class="iconfont iconqueren1"></i> 报价维修</van-button
      >
    </div>
    <van-image-preview v-model="previewShow" :images="previewImages">
    </van-image-preview>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
import {loginAlert} from "../utils/loginAlert";
export default {
  components: { Navbar, Tabber },
  props: {},
  data() {
    return {
      order_no: "", //订单id
      info: "", //订单信息
      formData: {
        is_guarantee: "0",
      },
      radio: "",
      showTimePicker: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      showStartTimePicker: false,
      showEndTimePicker: false,
      userInfo: "",
      previewImages: [],
      previewShow: false,
      shopFrom: {
        person: "",
        phone: "",
      },
      shopDetail: "",
    };
  },
  computed: {},
  created() {
    if (!this.$cookies.isKey("Authorization")) {
      login(this.getUser);
    }
    this.userInfo = this.$cookies.get("userInfo");
    this.order_no = this.$route.query.order_no;
    this.getOrderDetail();
    this.getShopDetail();
  },
  mounted() {},
  watch: {},
  methods: {
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      indexOp("/service_user/show", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.shopDetail = res.data;

          if (res.data) {
            _this.shopFrom.person = res.data.person;
            _this.shopFrom.phone = res.data.phone;
          }
        }
        console.log(res);
      });
    },
    onSubmit() {
      var _this = this;
      _this.formData.order_no = _this.order_no;
      _this.formData.service_id = _this.userInfo.id;
      addOp("/order_bidding/create", _this.formData).then((res) => {
        if (res && res.status == 201) {
          _this.$toast.success("报价成功！");

          _this.$router.push({
            path: "/shifuRepairDetail",
            query: {
              order_no: res.data.order_no,
            },
          });
        } else {
          _this.$toast.fail("报价失败！" + res.data.error);
        }
      });
    },
    setShop() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      console.log(_this.shopFrom);
      if (_this.formData.door_money == undefined) {
        _this.$toast("请输入上门检修费！");
        return;
      }
      if (_this.formData.plan_door_time == undefined) {
        _this.$toast("请选择上门时间！");
        return;
      }
      if (_this.shopFrom.person == undefined) {
        _this.$toast("请输入联系人！");
        return;
      }
      if (_this.shopFrom.phone == undefined) {
        _this.$toast("请输入联系电话！");
        return;
      }

      if (_this.shopDetail) {
        _this.onSubmit();
        return;
      }

      addOp("/service_user/create", _this.shopFrom).then((res) => {
        _this.$toast.clear();
        if (res && res.data) {
          _this.onSubmit();
        }
        console.log(res);
      });
    },
    //获取订单详情
    getOrderDetail() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
      };
      indexOp("/service_order/show", data).then((res) => {
        if (res && res.status) {
          _this.info = res.data;
        }
        console.log(res);
      });
    },
    //时间选择确认
    onTimeConfirm(value) {
      this.showTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      var h = value.getHours();
      var mm = value.getMinutes();
      var s = value.getSeconds();
      this.formData.plan_door_time = y + "-" + m + "-" + d + " " + h + ":" + mm;
    },
    //选择保修期结束之间
    onEndTimeConfirm(value) {
      this.showEndTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      this.formData.guarantee_end_time = y + "-" + m + "-" + d;
    },
    //选择保修期开始时间
    onStartTimeConfirm(value) {
      this.showStartTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      this.formData.guarantee_begin_time = y + "-" + m + "-" + d;
    },
    onConfirm(date) {
      this.calendarShow = false;
      // this.date = this.formatDate(date);
      console.log(data);
    },
    onPreviewImg(arr) {
      this.previewImages = arr;
      this.previewShow = true;
      console.log(arr);
    },

    //去对话
    toChat() {
      var _this = this;
      if (_this.$cookies.isKey("userInfo")) {
        var form_uid = _this.userInfo.id; //自己的 uid
        var to_uid = _this.info.uid; //聊天对象 uid
        var data = {
          params: {
            to_uid: to_uid,
            from_uid: form_uid,
            chat_type: "order",
            order_no: _this.order_no,
          },
        };
        addOp("/im/build_chat", data).then((res) => {
          console.log(res);
          if (res && res.status != 500) {
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                order_no: _this.order_no,
                to_uid: to_uid,
              },
            });
          } else {
            _this.$toast.fail("对话创建失败！");
          }
        });
      } else {
        loginAlert()
      }
    },

    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      this.getOrderDetail();
      this.getShopDetail();
    },
  },
};
</script>

<style scoped >
</style>
