<template>
	<div :class="styleClass" id="map" :style="mapStyle">
		<span v-if="!status" :style="defaultTextStyle">{{defaultText}}</span>
	</div>
</template>

<script>
	export default {
		name: 'TencentMapMarker',
		props: {
			status: {},
			mapStyle: {
				type: String,
				default: 'width:100%;'
			},
			defaultText: {
				type: String,
				default: '地图初始化中...'
			},
			defaultTextStyle: {
				type: String,
				default: 'font-size:14px;'
			},
			polylineLayer: {},
			coord: {},
			markerList: {},
			styleClass: {}
		},
		data() {
			return {

			};
		},
		computed: {

		},
		created() {

		},
		mounted() {
			this.initMap();
		},
		watch: {

		},
		methods: {
			initMap() {
				if (!this.status) {
					return false;
				}
				var center = new TMap.LatLng(this.coord.lat, this.coord.lng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map('map', {
					center: center
				});

				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						"myStyle": new TMap.MarkerStyle({
							"width": 25, // 点标记样式宽度（像素）
							"height": 35, // 点标记样式高度（像素）
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							"anchor": {
								x: 16,
								y: 32
							},
							color: '#ff0000', // 标注点文本颜色
							size: 16, // 标注点文本文字大小
							direction: 'bottom', // 标注点文本文字相对于标注点图片的方位
							offset: {
								x: 0,
								y: 8
							}, // 标注点文本文字基
						})
					},
					//点标记数据数组
					geometries: this.markerList
				});

				let control = map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
				control.setPosition(TMap.constants.CONTROL_POSITION.BOTTOM_RIGHT);

				if (this.polylineLayer) {
					new TMap.MultiPolyline({
						id: 'polyline-layer', //图层唯一标识
						map: map, //设置折线图层显示到哪个地图实例中
						//折线样式定义
						styles: {
							'style_blue': new TMap.PolylineStyle({
								'color': '#3777FF', //线填充色
								'width': 6, //折线宽度
								'borderWidth': 5, //边线宽度
								'borderColor': '#FFF', //边线颜色
								'lineCap': 'butt' //线端头方式
							}),
							'style_red': new TMap.PolylineStyle({
								'color': '#CC0000', //线填充色
								'width': 6, //折线宽度
								'borderWidth': 5, //边线宽度
								'borderColor': '#CCC', //边线颜色
								'lineCap': 'round' //线端头方式
							})
						},
						//折线数据定义
						geometries: [{ //第1条线
								'id': 'pl_1', //折线唯一标识，删除时使用
								'styleId': 'style_blue', //绑定样式名
								'paths': [new TMap.LatLng(40.038540, 116.272389), new TMap.LatLng(40.038844,
									116.275210), new TMap.LatLng(40.041407, 116.274738)]
							},
							{ //第2条线
								'id': 'pl_2',
								'styleId': 'style_red',
								'paths': [new TMap.LatLng(40.039492, 116.271893), new TMap.LatLng(40.041562,
									116.271421), new TMap.LatLng(40.041957, 116.274211)]
							}
						]
					});
				}

			}
		},
		components: {

		}

	}
</script>
<style scoped>

</style>