<template>
	<div id="wangeditor">
		<div ref="editorElem" style="text-align: left"></div>
	</div>
</template>

<script>
	let editorInst;
	import E from 'wangeditor'
	import {
		indexOp,
		deleteOp,
		updateOp,
		showOp,
		addOp,
		uploadImg
	} from '../api/index'
	import base from "../api/base.js";
	const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E;
	import Wechat from "../utils/Wechat.js";
	
	
	class AlertMenu extends BtnMenu {
	    constructor(editor) {
	      // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
	        const $elem = E.$(
	            `<div class="w-e-menu" data-title="图片"><i class="w-e-icon-image"></i></div>`
	        )
	        super($elem, editor)
	    }
		dataURLtoBlob(dataurl) {
			try {
				console.error('DATAURL:', dataurl)
				var arr = dataurl.split(',');
				if (arr.length == 1) {
					arr[1] = Base64.encode(arr[0]);
					arr[0] = 'data:image/jpeg;base64';
				}
				console.error('Arr:', arr);
				var mime = arr[0].match(/:(.*?);/)[1];
				var bstr = atob(arr[1].replace(/%0A|\s/g, ''));
				var n = bstr.length;
				var u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new Blob([u8arr], {
					type: mime
				});
			} catch (e) {
				console.log(e);
			}
		
		}
		//将blob转换为file
		blobToFile(theBlob, fileName) {
			theBlob.lastModifiedDate = new Date();
			theBlob.name = fileName;
			return theBlob;
		}
		base64ToFile(urlData) {
			const arr = urlData.split(',');
			const mime = arr[0].match(/:(.*?);/)[1];
			const bytes = atob(arr[1]); // 解码base64
			let n = bytes.length;
			const ia = new Uint8Array(n);
			while (n--) {
				ia[n] = bytes.charCodeAt(n);
			}
			return new Blob([ia], {
				type: mime
			});
		}
	    // 菜单点击事件
	    clickHandler(editor) {
			var _this 	=	this;
			console.log('eee:',this.editor);
	        Wechat.chooseImage({
				count: 30,
				sizeType: ['original'],
				sourceType: ['album'],
				success(vres) {
					var localIds = vres.localIds;
					if (localIds.length === 0) return;
				
					for (var i in localIds) {
						wx.getLocalImgData({
							localId: localIds[i],
							success: function(res) {
								console.log(res);
								var baseImage = res.localData; // localData是图片的base64数据，可以用img标签显示
								if (baseImage.indexOf('data:image') !== 0) {
									baseImage = 'data:image/jpeg;base64,' + baseImage;
								}
								baseImage = baseImage
									.replace(/\r|\n/g, '')
									.replace('data:image/jpg', 'data:image/jpeg')
									.replace('data:image/JPG', 'data:image/jpeg');
				
								var filename = new Date().getTime();
								filename = filename + '.jpg';
				
								var one = _this.blobToFile(_this.base64ToFile(baseImage),
								filename);
								
								var formData = new FormData()
								formData.append('file', new File([one], 'test.jpg', {
										type: one.type
									}))
								
								uploadImg(
									formData
								).then(res => {
									let imgUrl = base.apiURL+res.data.url
									_this.editor.txt.append( `<img src="${imgUrl}" style="width:100%;">` );
								})
							}
						})
					}
				
				},
				fail() {
					_this.$toast.clear();
				}
			});
	    }
	    // 菜单是否被激活（如果不需要，这个函数可以空着）
	    // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
	    // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
	    tryChangeActive() {
	        // 激活菜单
	        // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
	        // 2. this.this.isActive === true
	        this.active()
	        // // 取消激活菜单
	        // // 1. 菜单 DOM 节点会删掉 .w-e-active
	        // // 2. this.this.isActive === false
	        // this.unActive()
	    }
	}
	
	export default {
		name: 'Editor',
		data() {
			return {
				editorContent: '',
				editor: null,
				url: base.apiURL,
			}
		},
		props: {
			menus: {
				type: Array,
				default: []
			},
			value: {
				type: String,
				default: ''
			}
		}, //接收父组件的方法
		watch: {
			// value(newVal) {
			// 	this.setHtml(newVal);
			// 	// this.editor.selection.moveCursor(this.editor.editorElem.elems[0],false);
			// },
			// defaultValue(newVal) {
			// 	this.setHtml(newVal);
			// 	// this.editor.selection.moveCursor(this.editor.editorElem.elems[0],false);
			// },
			menus(value) {

			}
		},
		created() {},
		mounted() {
			const that = this
			
			// 菜单 key ，各个菜单不能重复
			const menuKey = 'alertMenuKey' 

			this.editor = new E(this.$refs.editorElem)
			// this.editor.create()
			//创建富文本实例
			this.editor.config.onchange = (html) => {
				// html  带P标签的文本
				// this.getText() 不带P标签的文本
				// console.log(html)
				//   html = this.getHtml()
				this.editorContent = html
				this.$emit('change')
				this.$emit('editor-input', html)
			}
			this.editor.config.zIndex = 1
			this.editor.config.menus = this.editor.config.menus.concat(menuKey);
			// 隐藏“网络图片”tab
			this.editor.config.showLinkImg = false
			this.editor.config.focus = false
			this.editor.config.uploadImgServer = '你的上传图片的接口'
			this.editor.config.uploadFileName = '你自定义的文件名'
			this.editor.config.uploadImgHeaders = {
				Accept: '*/*',
				// 'Authorization':'Bearer ' + token    //头部token
			}
			this.editor.config.menus = [
				//菜单配置
				'head',
				'bold',
				'fontSize',
				'fontName',
				'italic',
				'underline',
				'strikeThrough',
				'indent',
				'lineHeight',
				'foreColor',
				'backColor',
				'link',
				'list',
				'justify',
				'quote',
				'emoticon',
				'image',
				// 'video',
				'table',
				'code',
				'splitLine',
				'undo',
				'redo',
			]
			console.log(this.editor);
			// this.editor.config.uploadImage.maxNumberOfFiles 	=	10;
			if (this.menus && this.menus.length) {
				var tmpMnus 	=	this.menus;
				if( tmpMnus.indexOf('image') != '-1' ){
					tmpMnus.splice( tmpMnus.indexOf('image'), 1 );
					this.editor.config.menus = tmpMnus;
					// 自定义上传图片按钮
					this.editor.menus.extend(menuKey, AlertMenu)
					this.editor.config.menus.push(menuKey)
				}
			}
			
			
			
			//下面是最重要的的方法
			this.editor.config.customUploadImg = function(files, insert) {
				for (var i in files) {
					const file = files[i]
					const extName = that.getExtName(file.name)
					var formData = new FormData()
					formData.append('file', file)
					uploadImg(formData).then(res => {
						const imgUrl = that.url + res.data.url
						insert(imgUrl)
					})
				}

			}
			this.editor.create()
			if (this.value) {
				this.setHtml(this.value)
			}
			// console.log(this.defaultValue);
			// if (this.defaultValue) {
			// 	this.setHtml(this.defaultValue)
			// }
		},
		methods: {
			// 获取内容(html)
			getHtml() {
				return this.editor.txt.html()
			},
			// 获取内容(纯文本)
			getText() {
				return this.editor.txt.text()
			},
			// 设置内容(html)
			setHtml(text) {
				this.editor.txt.html(text)
			},
			// 追加内容(html)
			appendHtml(text) {
				this.editor.txt.append(text)
			},
			// 清空内容
			clear() {
				this.editor.txt.clear()
			},
			/**
			 * 获取后缀名
			 */
			getExtName(fileName) {
				let index = fileName.lastIndexOf('.')
				if (index > -1) {
					return fileName.substring(index + 1)
				} else {
					return ''
				}
			},
		},
	}
</script>

<style scoped lang="less">
	.w-e-toolbar {
		flex-wrap: wrap !important;
	}

	.w-e-text {
		p {
			white-space: normal !important;
		}
	}
</style>