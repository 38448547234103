<template>
    <div class="publishWrap">
        <div class="top-navbar">
            <van-icon name="arrow-left" @click="toBack" />
            <span class="text">发布信息</span>
        </div>
        <div class="publishContent">
            <!-- <van-field
                v-model="formData.content"
                autosize
                type="textarea"
                placeholder="请填写你的经验心得或者需要什么帮助"
            /> -->
            <div class="editor-box">
                <editor @editor-input="onEditorChange" ></editor>
            </div> 
            <div class="upload-img" >
                <p class="tit">上传图片</p>
                <div class="imgs">
                    <van-uploader
                        v-model="fileList"
                        multiple
                        :max-count="3"
                        :after-read="afterRead"
                    />
                </div>
            </div>

            <div class="upload-img" >
                <p class="tit">上传视频</p>
                <div class="imgs">
                    <van-uploader
                        accept="video/*"
                        v-model="VideofileList"
                        multiple
                        :max-count="1"
                        :after-read="afterReadVideo"
                    />
                    <video src=""></video>
                </div>
            </div>
        </div>
        <van-button class="publishbtn" type="info" @click="onSubmit"><i class="iconfont iconjiahao"></i> 确认发布</van-button>
    </div>
</template>
<script>
import Navbar from "../../components/Navbar";
import Editor from "../../components/editor"
import { uploadFile,uploadImg,addOp } from "../../api";
import base from "../../api/base.js";
export default {
    data(){
        return{
            fileList: [],
            VideofileList: [],
            url: base.apiURL,
            picturesList:[],
            formData:{
                category_id:'',
                content:'',
                pictures: '',
                video:''
            },
            htmlValue: '',
        }
    },
    components: {
        Navbar,
        Editor
    },
    created(){
        this.formData.category_id = this.$route.query.id;
        console.log(this.formData.category_id)
    },
    methods:{
        onEditorChange(value) {
            console.log(value)
        this.htmlValue = value
        },
        toBack() {
            this.$router.go(-1);
        },

         //图片上传
        afterRead(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadImg(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.picturesList.push(_this.url + res.data.url);
                    console.log()
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },

        //视频上传
        afterReadVideo(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadFile(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.formData.video = _this.url + res.data.url;
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },

        onSubmit(){
            var _this = this;
            if (_this.htmlValue == '') {
                _this.$toast.fail("发布内容不能为空");
                return;
            }
            _this.formData.pictures =  _this.picturesList.join(",")
            _this.formData.content = _this.htmlValue
            addOp("/interflow/release", _this.formData).then((res) => {
                _this.$toast.clear();
                console.log(res)
                if (res && res.status == 201) {
                     _this.$toast.success("操作成功！");
                     _this.$router.go(-1);
                }
            });
        }

    }
}
</script>
<style lang="less" scope>
.publishWrap{
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-bottom: 60px;
    .publishContent{
        padding: 10px;
        .van-cell {
            background: transparent;
            border: 1px solid #C9C9C9;
            padding: 5px 10px;
            margin-bottom: 10px;
        }

        .upload-img {
            padding: 10px 16px;
            background: #F2F0F0;
            margin-top: 5px;

            .tit {
                font-size: 12px;
                color: #646566;
                margin-bottom: 10px;
            }

            .van-uploader__upload {
                background: #D8D8D8;
            }

            .van-icon {
                color: #fff;
            }
        }
        
    }
    .publishbtn{
        margin: 100px auto;
        width: 300px;
        display: block;
    }
}

</style>