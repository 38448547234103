<template>
  <!-- 店铺详情 -->
  <div class="shop-detail paddingTabbar">
    <Navbar :title="title"></Navbar>
    <div
      class="shop-bg"
      style="background: linear-gradient(rgb(47, 120, 220), rgb(242, 242, 242))"
    ></div>
    <div class="clear"></div>

    <div class="shop-tab">
      <div class="tabs-box">
        <div class="card-box">
          <div class="search-box">
            <div class="input-box">
              <van-icon class="icon" name="search" />
              <van-search
                left-icon=""
                v-model="productQuery.content"
                class="input"
                placeholder="产品搜索"
                @search="onSearch"
                @clear="onInputClear"
              />
            </div>
          </div>
          <div class="product-list-box">
            <div
              class="item"
              v-for="item in goodsList"
              :key="item.id"
              @click="toDetail(item.id)"
            >
              <img
                class="product-img"
                v-if="item.image && item.image.length > 0"
                :src="url + item.image[0]"
                alt=""
              />
              <img
                class="product-img"
                v-else
                src="@/assets/image/banner1.jpg"
                alt=""
              />
              <p class="product-content">
                {{ item.content }}
              </p>
              <p class="product-price">¥{{ item.sale_price }}</p>
              <div
                class="userinfo-box"
                v-if="item.user"
                @click.stop="
                  $router.push({
                    path: '/shop/myShopIndex',
                    query: { uid: item.user.id },
                  })
                "
              >
                <img class="avatar-img" :src="item.user.avatar" alt="" />
                <div class="user-name">
                  {{ item.user.nickname }}
                </div>
              </div>
            </div>
          </div>
          <div @click="onMore" class="more-btn" v-if="goodsList.length >= 10">
            查看更多
          </div>
          <div
            style="margin: 0 auto"
            class="empty"
            v-if="goodsList.length == 0"
          >
            <van-empty image-size="100" description="暂无产品数据" />
          </div>
        </div>
      </div>
      <div class="btns">
        <router-link
          class="btn"
          :to="{ path: '/shop/fabu', query: { id: uid } }"
        >
          <van-icon name="plus" />
          <span>发布产品</span>
        </router-link>
      </div>
    </div>

    <!-- <van-popup v-model="treeSelectShow" round position="bottom">
      <van-picker
        ref="classPicker"
        show-toolbar
        :columns="columns"
        @cancel="treeSelectShow = false"
        @confirm="onConfirm"
        value-key="name"
      />
    </van-popup> -->
    <!-- <Tabber></Tabber> -->
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  inject: ["reload"],
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      title: "产品列表",
      url: base.apiURL,
      loginURL: base.loginURL,
      active: "3",
      uid: "",
      info: "",
      pinglunList: "",
      userInfo: {
        id: "",
      },
      slideList: "",
      videoList: "",
      newsList: "",
      queryParam: {},
      per_page: "",
      last_page: "",
      page: "",
      productQuery: {
        content: "",
        status: "success",
        good_type: "",
      },
      columns: [
        {
          name: "全部",
          children: [
            {
              name: "全部",
              id: "all",
            },
          ],
        },
      ],
      goodsList: [],
      goodsListLeft: [],
      goodsListRight: [],
      classValue: "",
      treeSelectShow: false,
      currentPage: 1,

      goodsShopInfo: null,
      goodsShopLoading: false,
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    this.productQuery.good_type = this.$route.query.type;
    this.uid = this.$route.query.uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    var shareTitle = "冷鲜柜网";
    if (this.productQuery.good_type == "exchange") {
      shareTitle = "冷鲜柜网-冷链易货";
      this.title = "冷链易货";
    }
    if (this.productQuery.good_type == "rent") {
      shareTitle = "冷鲜柜网-设备租赁";
      this.title = "设备租赁";
    }
    if (this.productQuery.good_type == "sale") {
      shareTitle = "冷鲜柜网-推荐商家";
      this.title = "推荐商家";
    }
    // 先设置，分享连接
    let shareInfo = {
      title: shareTitle,
      desc: "聚制冷精英，集行业资源，方便您我他！",
      link:
        base.shareURL +
        "/shop/index?uid=" +
        this.$route.query.uid +
        "&tab=" +
        this.active +
        "&invate_uid=" +
        this.userInfo.id +
        "&type=" +
        this.productQuery.good_type,
      img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
    };
    wechatConfig(shareInfo);

    this.getProductList();
  },
  mounted() {},
  watch: {},
  methods: {
    onMore() {
      var _this = this;
      if (_this.page == _this.last_page) {
        _this.$toast("没有更多数据了");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var page = _this.page + 1;
      _this.productQuery.page = page;
      indexOp("/product/index", _this.productQuery).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.last_page = res.data.last_page;
          _this.page = res.data.current_page;
          if (res.data.data.length <= 0) {
            _this.$toast("没有更多数据了");
            return;
          }
          for (var i = 0; i < res.data.data.length; i++) {
            _this.goodsList.push(res.data.data[i]);
          }
        }
      });
    },
    toDetail(id) {
      this.$router.push({
        path: "/shop/detail",
        query: {
          id: id,
        },
      });
    },
    isMe() {
      var uinfo = this.$cookies.get("userInfo");
      console.log(uinfo);
      if (!uinfo) {
        return false;
      }
      if (!this.goodsShopInfo) {
        return false;
      }
      return uinfo.id == this.goodsShopInfo.uid;
    },
    toProductDetail(item) {
      this.$router.push({
        path: "/productDetail",
        query: {
          id: item.id,
          tab: 4,
        },
      });
    },
    onSearch(value) {
      console.log(value);
      this.getProductList();
    },
    onInputClear() {
      this.productQuery.content = "";
      this.getProductList();
    },
    onclear() {
      this.classValue = "";
    },
    //获取产品列表
    getProductList() {
      var _this = this;
      indexOp("/product/index", _this.productQuery).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          var data = res.data.data;
          _this.last_page = res.data.last_page;
          _this.page = res.data.current_page;
          _this.goodsList = data;
        }
      });
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert();
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
    //获取轮播
    getSlide() {
      var _this = this;
      indexOp("/slide/index?cid=3").then((res) => {
        if (res && res.status == 200) {
          _this.slideList = res.data;
        }
      });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    goBack() {
      this.$router.go(-1);
    },
    toEditShop() {
      this.$router.push("/editShifuinfo");
    },
    toUploadVideo() {
      this.$router.push({
        path: "/upload",
      });
    },
    getVideoposter() {
      var _this = this;
      this.videoList.forEach((item) => {
        let video = document.createElement("video");
        let canvas = document.createElement("canvas");
        // video.setAttribute("crossOrigin", "anonymous"); //处理跨域
        video.setAttribute("src", _this.url + item.video_url);
        video.setAttribute("width", 350);
        video.setAttribute("height", 190);
        video.setAttribute("controls", "controls");
        video.setAttribute("autoplay", "autoplay");
        // video.currentTime = 1;
        video.addEventListener("canplay", function () {
          setTimeout(() => {
            canvas.width = video.width;
            canvas.height = video.height;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, video.width, video.height); //绘制canvas
            var dataURL = canvas.toDataURL("image/png"); //转换为base64
            item.cover = dataURL;
            //     console.log(dataURL);
          }, 200);
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
@ThemColor: #2f78dc;
.more-btn {
  width: 100px;
  display: block;
  margin: 0 auto;
  color: #666;
  font-size: 16px;
  text-align: center;
  padding-top: 15px;
  text-decoration: none;
}
.product-list-box {
  display: flex;
  flex-wrap: wrap;
}
.product-list-box::after {
  content: "";
  display: block;
  clear: both;
}
.product-list-box .item {
  width: 50%;
  // float: left;
  border-bottom: none !important;
  padding: 5px !important;
}
.product-list-box .item .product-img {
  width: 100%;
  height: 160px;
  border-radius: 15px;
  object-fit: cover;
}
.product-list-box .item .product-content {
  font-size: 14px;
  padding: 0 5px;
  display: -webkit-box; /* 必须设置此项才能生效 */
  -webkit-line-clamp: 2; /* 限制最大行数 */
  -webkit-box-orient: vertical; /* 垂直布局 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  margin-bottom: 5px;
}
.product-list-box .item .product-price {
  padding: 0 5px;
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
}
.product-list-box .item .userinfo-box {
  display: flex;
  align-items: center;
}
.product-list-box .item .userinfo-box .avatar-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}
.product-list-box .item .userinfo-box .user-name {
  font-size: 12px;
  color: #666;
  width: 70%;
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  white-space: nowrap;
}
.btns {
  bottom: 110px !important;
}

.btns {
  position: fixed;
  right: 15px;
  bottom: 80px;

  .btn {
    height: 35px;
    width: 120px;
    border-radius: 35px;
    font-size: 14px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @ThemColor;
    box-shadow: 4px 6px 28px -10px rgba(79, 77, 77, 0.5);
    box-sizing: border-box;
    color: #fff;

    span {
      line-height: 1;
    }

    i {
      margin-right: 10px;
      color: #fff;
    }
  }
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.shop-detail .tabs-box .card-box .product-list .item {
  width: 33%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.product-list .item img {
  width: 100%;
  height: 65px;
  object-fit: cover;
  margin-bottom: 5px;
}

.product-list .item span {
  font-size: 14px;
}

.search-box {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input-box {
    width: 100%;
    position: relative;
    // background: #f2f2f2;

    .icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }

    .van-cell {
      padding: 0;
      border-radius: 35px;
      line-height: 35px;
      font-size: 14px;
    }

    .input {
      width: 100%;
      background: #f2f2f2;
      border-radius: 35px;
      height: 35px;
      border: none;
      font-size: 14px;
      padding-left: 35px;

      .van-search__content {
        background: #f2f2f2;
        padding-left: 0;
      }
    }
  }

  .type-btn {
    width: 27%;
    height: 35px;
    border-radius: 35px;
    background: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 5px;
    }
  }
}

.type {
  padding: 5px 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
