<template>
	<!-- 注册 -->
	<div class="chongzhi"
		style="background-color#fff;background-image:  linear-gradient(to bottom, #2F78DC , #fff);min-height:100vh;padding-bottom:50px;box-sizing:border-box;">
		<Navbar :title="'账户余额'"></Navbar>
		<div class="top balanceTop">
			<div class="block" v-if="userInfo && userInfo.identity == 2">
				<p class="title">维修费</p>
				<div class="input-box">
					<span>¥ {{userInfo.service_account}}</span>
				</div>
			</div>
			<div class="block">
				<p class="title">货款</p>
				<div class="input-box">
					<span>¥ {{userInfo.goods_account}}</span>
				</div>
			</div>
			<div class="block">
				<p class="title">充值余额</p>
				<div class="input-box">
					<span>¥ {{userInfo.user_account}}</span>
				</div>
			</div>
			<div class="block">
				<p class="title">待入账</p>
				<div class="input-box">
					<span>¥ {{userInfo.freeze_account}}</span>
				</div>
			</div>
		</div>
		<div class="bottom balanceBottom">
			<van-grid>
				<van-grid-item url="/chongzhi" icon="gold-coin-o" text="账户充值" />
				<van-grid-item url="/tixian" icon="after-sale" text="账户提现" />
				<van-grid-item url="/zhuanzhang" icon="cash-back-record" text="账户转账" />
				<van-grid-item url="/bill" icon="balance-list-o" text="账单日志" />
			</van-grid>
		</div>
		<div class="balanceHistory">
			<div class="balanceHistoryTitle">
				<van-icon name="bar-chart-o" style="margin-right: 5px;position: relative;top:2px;" />近期记录（更多请点击上方账单日志查看）
			</div>
			<div class="balanceHistoryList">
				<block v-if="!billList">
					<van-empty description="暂无数据" />
				</block>
				<van-cell-group v-else>
					<block v-for="(item,index) in billList">
						<van-cell icon="shop-o" :value="item.operator + item.money" :label="item.created_at">
							<template #value>
								{{ item.operator}}{{item.money}}
							</template>
							<template #title>
								<span style="font-weight: bold;font-size: 14px;">{{ getTypeText(item.type) }}</span>
							</template>
						</van-cell>
					</block>
				</van-cell-group>
			</div>
		</div>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	// import websocket from '../utils/webSocket'
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp,
		uploadFile
	} from "../api";
	import base from "../api/base.js";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		login
	} from "../utils/login_toast";
	export default {
		components: {
			Navbar,
		},
		data() {
			return {
				money: "",
				jsApiParameters: "",
				userInfo: {
					id: "",
				},
				invate_uid: "",
				billList: null,
			};
		},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.WXlogin()
			this.setShare()
		},
		methods: {
			getTypeText(type) {
				switch (type) {
					case 'system_service_fee_account':
						return '保外订单服务费';
						break;
					case 'deposit_money_wxpay':
						return '微信支付定金';
						break;
					case 'door_money_add_wxpay':
						return '微信支付追加上门检修费用';
						break;
					case 'service_money_wxpay':
						return '微信支付维修费用';
						break;
					case 'fittings_money_wxpay':
						return '微信支付配件费用';
						break;
					case 'deposit_money_account':
						return '余额支付定金';
						break;
					case 'door_money_add_account':
						return '余额支付追加上门检修费用';
						break;
					case 'service_money_account':
						return '余额支付维修费用';
						break;
					case 'fittings_money_account':
						return '余额支付配件费用';
						break;
					case 'account_pay':
						return '账户充值';
						break;
					case 'user_vip_wxpay':
						return '微信支付年费';
						break;
					case 'user_vip_account':
						return '余额支付年费';
						break;
					case 'withdraw':
						return '提现';
						break;
					case 'service_fittings_money_wxpay':
						return '微信支付配件及维修费用';
						break;
					case 'service_fittings_money_account':
						return '余额支付配件及维修费用';
						break;
					case 'account_pay_transfer':
						return '转账支出';
						break;
					case 'account_earn_transfer':
						return '转账收入';
						break;
					default:
						return '其他';
						break;
				}
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-账户余额`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/accountBalance?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				} else {
					this.getUser();
				}
			},
			getLoginUser(userInfo) {
				this.getUser();
				console.log(userInfo);
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			getUser() {
				var _this = this;
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var api = "/user/show/1";
				indexOp(api).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						if (res.data.identity == null) {
							_this.$router.push({
								path: "/identity",
							});
							return;
						}
						_this.$cookies.set("identity", res.data.identity);
						_this.$cookies.set("userInfo", res.data);
						_this.userInfo = res.data;
					} else {
						console.log(res);
					}
				});

				//  加载10条账单日志 
				indexOp("/bill_log/index", {
					page: 1
				}).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.data.last_page;
						_this.current_page = res.data.data.current_page;
						if (res.data.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							_this.finished = true
							_this.loading = false;
							return;
						}
						_this.billList = res.data.data.data
						_this.loading = false;
					}
				});

			},
			pay() {
				var _this = this;
				var data = {
					money: _this.money,
					pay_type: "account_pay",
				};
				_this.$toast.loading({
					message: "请稍等...",
					forbidClick: true,
					duration: 0,
				});
				addOp("/pay_order", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.jsApiParameters = res.data;
						_this.callpay();
						// _this.payTypeShow = false;
					} else {
						_this.$toast.fail(res.data.error);
					}
				});
			},
			//支付
			jsApiCall() {
				var _this = this;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest",
					_this.jsApiParameters,
					function(res) {
						if (res.err_msg === "get_brand_wcpay_request:ok") {
							alert("充值成功！");
							_this.$router.go(-1);
						} else if (res.err_msg === "get_brand_wcpay_request:fail") {
							alert("支付失败！请重试！");
						}
					}
				);
			},
			callpay() {
				var _this = this;
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							_this.jsApiCall,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
						document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
					}
				} else {
					_this.jsApiCall();
				}
			},
		},
	};
</script>

<style lang="less">
	.top {
		.block {
			width: 40%;
			display: inline-block;
			margin-bottom: 20px;
		}
	}
</style>