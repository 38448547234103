<template>
  <div class="product">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">公告详情</span>
    </div>
    <div class="content">
      <div class="title-box">
        {{ info.title }}
        <p
          style="
            font-size: 12px;
            color: #666;
            margin-top: 5px;
            font-weight: normal;
          "
        >
          发布时间：{{ info.updated_at }}
        </p>
      </div>
      <div class="detail-box">
        <div v-html="info.contents"></div>
      </div>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import Tabber from "../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
import { wechatConfig } from "../utils/wxConfig";
import { login } from "../utils/login_toast";
export default {
  inject: ["reload"],
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      loginUrl: base.loginURL,
      userInfo: {
        id: "",
      },
      info: "",
      nid: "",
      zanInfo: "",
      pingjiaShow: false, //评价弹出
      pingjiaValue: "", //评价
      pinglunList: "",
      tid: "",
      comment_id: "",
      slideList: [],
    };
  },
  computed: {},
  created() {
    
    this.nid = this.$route.query.id;
    this.getNewInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: "聚制冷精英，集行业资源，方便您我他！",
        link:
          base.shareURL +
          "/topic?id=" +
          this.nid,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取资讯详情
    getNewInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/topic/show/" + _this.nid;
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
