<template>
	<!-- 账单 -->
	<div class="bill-list">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">账单流水</span>
			<!-- <span style="font-size: 12px" @click="toAdd">添加用户</span> -->
			<span style="font-size: 12px"><span style="position:relative;top:2px;right:3px;"><van-icon
						name="replay" /></span>导出数据</span>
			<!-- <van-icon name="setting"  /> -->
		</div>


		<van-collapse v-model="collapse">
			<van-collapse-item title="标题1" :name="ditem.date" v-for="(ditem,dindex) in list">
				<template #title>
					<span style="font-size: 16px;font-weight: bold;">{{ditem.date}}</span>
				</template>
				<div class="list" style="padding:0px;">
					<div class="item" v-for="(item,index) in ditem.data">
						<div class="content">
							<div
								style="display:flex;align-items:center;margin-bottom:10px;justify-content: space-between;">
								<span class="title" v-if="item.type == 'door_money_add_account'">上门检修费</span>
								<span class="title" v-else-if="item.type == 'service_money_account'">维修费用</span>
								<span class="title" v-else-if="item.type == 'deposit_money_account'">预付定金</span>
								<span class="title" v-else-if="item.type == 'deposit_back_off'">预付定金退回</span>
								<span class="title" v-else-if="item.type == 'user_vip_account'">会员充值</span>
								<span class="title" v-else-if="item.type == 'system_add'">后台充值</span>
								<span class="title" v-else-if="item.type == 'account_pay'">账户充值</span>
								<span class="title" v-else-if="item.type == 'account_pay_transfer'">账户转账</span>
								<span class="title" v-else>提现</span>
								<span style="color:red;">{{ item.operator + item.money}}</span>
							</div>
							<div style="display:flex;flex-direction: column;">
								<p class="title vctitle" v-for="(titem,index) in (item.title.replaceAll('，',',')).split(',')">{{titem}}</p>
							</div>
							<p class="time">{{ item.updated_at }}</p>
						</div>
					</div>
				</div>
			</van-collapse-item>
		</van-collapse>
		<div class="more" @click="loadMore()">加载更多</div>

		<!-- <van-list class="list" v-model="loading" :finished="finished" :finished-text="list.length > 0 ? '没有更多了' : ''">
			<div v-for="(item, index) in list" :key="index" @click="toDetail(item.id)">
				<div class="item">
					<div class="content">
						<div style="display:flex;align-items:center;margin-bottom:10px;justify-content: space-between;">
							<span class="title" v-if="item.type == 'door_money_add_account'">上门检修费</span>
							<span class="title" v-else-if="item.type == 'service_money_account'">维修费用</span>
							<span class="title" v-else-if="item.type == 'deposit_money_account'">预付定金</span>
							<span class="title" v-else-if="item.type == 'deposit_back_off'">预付定金退回</span>
							<span class="title" v-else-if="item.type == 'user_vip_account'">会员充值</span>
							<span class="title" v-else-if="item.type == 'system_add'">后台充值</span>
							<span class="title" v-else-if="item.type == 'account_pay'">账户充值</span>
							<span class="title" v-else-if="item.type == 'account_pay_transfer'">账户转账</span>
							<span class="title" v-else>提现</span>
							<span style="color:red;">{{ item.operator + item.money}}</span>
						</div>
						<div style="display:flex;">
							<p class="title">{{item.title}}</p>
						</div>
						<p class="time">{{ item.updated_at }}</p>
					</div>
				</div>
			</div>
			<div class="empty" v-if="list.length <= 0">
				<van-empty description="暂无数据" />
			</div>
		</van-list> -->
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				list: [],
				loading: false,
				finished: false,
				current_page: 1, //当前页
				last_page: "", //最后一页
				queryParam: {
					page: 1
				},
				userInfo: {
					id: "",
				},
				invate_uid: "",
				collapse : ['1']
			};
		},
		computed: {},
		created() {
			var _this = this;
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.WXlogin()
			this.setShare();
			this.onLoad();
		},
		mounted() {},
		watch: {},
		methods: {
			loadMore(){
				this.queryParam.page+=1;
				this.onLoad();
			},
			/**
			 * 返回上级
			 */
			toBack() {
				this.$router.go(-1);
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-账单日志`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/bill?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				} else {
					// this.onLoad();
				}
			},
			getLoginUser(userInfo) {
				// this.onLoad();
				console.log(userInfo);
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			onLoad() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});

				indexOp("/bill_log/getFilterMonth", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						let result = res.data;
						_this.last_page = result.last_page;
						_this.current_page = result.current_page;
						if (result.data.length <= 0) {
							_this.$toast("没有更多数据了");
							_this.finished = true
							_this.loading = false;
							return;
						}
						for (var i = 0; i < result.data.length; i++) {
							_this.list.push(result.data[i]);
							_this.loading = false;
							_this.collapse.push(result.data[i].date);
						}
					}
				});
			},
			toDetail(id) {
				var _this = this;
				_this.$router.push({
					path: "/billDetail",
					query: {
						id: id
					}
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.list {
		.content {
			.title {
				font-weight:bold;
				color:#000;
			}
			.vctitle {
				font-weight:normal!important;
				color:#999!important;
			}
		}
	}
	.more {
		height : 30px;
		line-height:30px;
		width:100%;
		text-align:center;
	}
</style>