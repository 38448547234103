<template>
	<!-- 店铺详情 -->
	<div class="shop-detail paddingTabbar">

		<div class="shop-bg" style="background: linear-gradient(rgb(81, 149, 255), rgb(242, 242, 242));"></div>
		<div class="clear"></div>

		<div class="shop-container">
			<div class="n">
				<span style="font-weight: bold;">{{ goodsShopInfo ? goodsShopInfo.name : '-' }}</span>
				<div class="vip-box" v-if="info.service_info && info.service_info.vip_level">
					<template v-if="info.service_info.vip_level.vip_level == 1">
						<i class="iconfont iconhuiyuandengji" style="color: #5f3e3e"></i>
						<span>普通会员</span>
					</template>
					<template v-else-if="info.service_info.vip_level.vip_level == 2">
						<i class="iconfont iconhuiyuandengji"></i>
						<span>黄金会员</span>
					</template>
					<template v-else-if="info.service_info.vip_level.vip_level == 3">
						<i class="iconfont iconhuiyuandengji" style="color: #bd419d"></i>
						<span>钻石会员</span>
					</template>
				</div>
				<div class="vip-box" v-else>
					<template>
						<i class="iconfont iconhuiyuandengji" style="color: #d8d8d8"></i>
						<span>体验会员</span>
					</template>
				</div>
			</div>

		</div>

		<div class="shop-tab">
			<van-tabs v-model="active" color="#2F78DC" line-height="1px" line-width="64px" @change="onTabChange">
				<van-tab title="产品" name="4">
					<div class="tabs-box">
						<div class="card-box">
							<div class="search-box">
								<div class="input-box">
									<van-icon class="icon" name="search" />
									<van-search left-icon="" v-model="productQuery.keyword" class="input"
										placeholder="产品搜索" @search="onSearch" @clear="onInputClear" />
								</div>
								<div class="type-btn" @click="treeSelectShow = true">
									<span>产品分类</span>
									<van-icon name="arrow-down" />
								</div>
							</div>
							<div class="type" @click="treeSelectShow = true" v-if="classValue">
								<span>产品分类：{{ classValue }}</span>
								<van-icon name="close" size="16px" @click.stop="onclear" />
							</div>
							<GoodsList :deleteStatus="isMe()" v-if="goodsList" :left="goodsListLeft" :right="goodsListRight"
								@click="toProductDetail" @del="productDelete"></GoodsList>
							<div style="margin: 0 auto" class="empty" v-if="goodsList.length == 0">
								<van-empty image-size="100" description="暂无产品数据" />
							</div>
						</div>
					</div>
				</van-tab>
			</van-tabs>
			<div class="btns" v-if="active == 4 && isMe()">
				<router-link class="btn" :to="{ path: '/productAdd', query: { id: uid } }">
					<van-icon name="plus" />
					<span>添加产品</span>
				</router-link>
			</div>
		</div>

		<van-popup v-model="treeSelectShow" round position="bottom">
			<van-picker ref="classPicker" show-toolbar :columns="columns" @cancel="treeSelectShow = false"
				@confirm="onConfirm" value-key="name" />
		</van-popup>
		<!-- <Tabber></Tabber> -->
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	import GoodsList from "../../components/GoodsList.vue";
	export default {
		inject: ["reload"],
		components: {
			Navbar,
			Tabber,
			GoodsList
		},
		props: {},
		data() {
			return {
				url: base.apiURL,
				loginURL: base.loginURL,
				active: "3",
				uid: "",
				info: "",
				pinglunList: "",
				userInfo: {
					id: "",
				},
				slideList: "",
				videoList: "",
				newsList: "",
				queryParam: {},
				per_page: "",
				last_page: "",
				page: "",
				productQuery: {
					keyword: "",
				},
				columns: [{
					name: "全部",
					children: [{
						name: "全部",
						id: "all"
					}]
				}],
				goodsList: [],
				goodsListLeft: [],
				goodsListRight: [],
				classValue: "",
				treeSelectShow: false,
				currentPage: 1,
				
				goodsShopInfo : null,
				goodsShopLoading : false,
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if(this.$route.query.tab){
				this.active = this.$route.query.tab;
			}else{
				this.active = 3;
			}
			if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			
			// 先设置，分享连接
			let shareInfo = {
				title: '我的店铺',
				desc: "聚制冷精英，集行业资源，方便您我他！",
				link: base.shareURL +
					"/shifuShopDetail?uid=" +
					this.$route.query.uid +
					"&tab=" +
					this.active +
					"&invate_uid=" +
					this.userInfo.id,
				img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
			};
			wechatConfig(shareInfo);
			
			this.uid = this.$route.query.uid;
			this.getPinglun();
			this.getProductList();
			this.getGoodsType();
			this.getGoodsShopInfo();

		},
		mounted() {},
		watch: {},
		methods: {
			isMe(){
				var uinfo 	=	this.$cookies.get("userInfo");
				console.log(uinfo);
				if(!uinfo){
					return false;
				}
				if(!this.goodsShopInfo){
					return false;
				}
				return uinfo.id == this.goodsShopInfo.uid;
			},
			toProductDetail(item) {
				this.$router.push({
					path: "/productDetail",
					query: {
						id: item.id,
						tab : 4
					},
				});
			},
			productDelete(id){
				var _this 	=	this;
				var api 	=	'/goods/destroy/' + id;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认删除这个商品吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						// on close
						addOp(api).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("删除成功！");
								setTimeout(() => {
									_this.reload();
								}, 800);
							}
							console.log(res);
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			//分类选择
			onConfirm(value) {
				var val = this.$refs.classPicker.getValues();
				console.log(val);
				this.classValue = value[0] + " / " + value[1];
				this.treeSelectShow = false;
				if (val[1].id == "all") {
					this.productQuery.cid = "";
				} else {
					this.productQuery.cid = val[1].id;
				}
				this.getProductList();
			},
			onSearch(value) {
				console.log(value);
				this.getProductList();
			},
			onInputClear() {
				this.productQuery.keyword = "";
				this.getProductList();
			},
			onclear() {
				this.classValue = "";
			},
			toSendProduct() {
				this.$router.push({});
			},
			//获取产品分类
			getGoodsType() {
				var _this = this;
				var data = {
					id: _this.uid,
				};
				indexOp("/goods_type/index", data).then((res) => {
					if (res && res.status == 200) {
						res.data.map((item) => {
							if (item._child && item._child.length > 0) {
								var arr = [];
								item._child.map((child) => {
									child.text = child.name;
									arr.push(child);
								});
								item.text = item.name;
								item.children = arr;
								_this.columns.push(item);
							}
						});
					}
				});
			},
			//获取产品列表
			getProductList() {
				var _this = this;
				_this.productQuery.id = _this.uid;
				indexOp("/goods/index", _this.productQuery).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						var data = res.data;
						_this.goodsList = data;
						for (var i in data) {
							let tmpKey = i % 2;
							if (tmpKey == 0) {
								_this.goodsListLeft.push(data[i]);
							} else {
								_this.goodsListRight.push(data[i]);
							}
						}
					}
				});
			},
			//查看更多
			onMore() {
				var _this = this;
				if (_this.page == _this.last_page) {
					_this.$toast("没有更多数据了");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.page + 1;
				_this.queryParam.page = page;
				indexOp("/news/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
						if (res.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							return;
						}
						for (var i = 0; i < res.data.data.length; i++) {
							_this.newsList.push(res.data.data[i]);
						}
					}
				});
			},
			//获取资讯列表
			getNewsList() {
				var _this = this;
				var data = {
					service_id: this.uid,
				};
				_this.queryParam.service_id = _this.uid;
				indexOp("/news/index", data).then((res) => {
					if (res && res.status == 200) {
						_this.newsList = res.data.data;
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
						_this.per_page = res.data.per_page;
					}
					console.log(res);
				});
			},
			//去资讯详情
			toNewsDetail(id) {
				var _this = this;
				this.$router.push({
					path: "/newDetail",
					query: {
						id: id,
					},
				});
			},
			toSendNews() {
				this.$router.push({
					path: "/newAdd",
				});
			},
			onVideoDelete(id) {
				var _this = this;
				var api = "/video/destroy/" + id;

				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认删除这条视频吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						// on close
						indexOp(api).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("删除成功！");
								setTimeout(() => {
									_this.reload();
								}, 800);
							}
							console.log(res);
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			onTabChange(name) {
				var _this 	=	this;
				
				// this.$router.replace({
				// 	path: "/myShop",
				// 	query : {
				// 		uid : this.$route.query.uid,
				// 		tab : name
				// 	}
				// });
				
				return false;
				
				if(!this.goodsShopLoading){
					return false;
				}
				if (name == '4') {
					if (!this.goodsShopInfo && this.isMe()) {
						_this.$dialog
							.confirm({
								title: "提示",
								message: "你还没有申请开通店铺，不可售卖产品，是否申请0元入驻？",
								confirmButtonText: "我要入驻",
								cancelButtonText: "暂不入驻",
							})
							.then(() => {
								_this.$router.push({
									path: "/GoodsShopSettle",
									query: {},
								});
							})
							.catch(() => {
								_this.$route.go('-1');
							});
					}
					return false;
				}
				this.active = name;
				this.setShare();
				this.setUrl(name);
			},
			setUrl(active) {
				let tmpRoute = this.$route,
					query = tmpRoute.query,
					path = tmpRoute.path;

			

			},
			//分享
			setShare() {
				let shareInfo = {
					title: `冷鲜柜网-` + this.info.name,
					desc: "聚制冷精英，集行业资源，方便您我他！",
					link: base.shareURL +
						"/shifuShopDetail?uid=" +
						this.info.uid +
						"&tab=" +
						this.active +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				if(this.goodsShopInfo){
					shareInfo.title 	=	this.goodsShopInfo.name;
				}else if( this.info ){
					shareInfo.title 	=	this.info.name;
				}
				wechatConfig(shareInfo);
			},
			//获取店铺详情
			getShopDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				var data = {
					uid: _this.uid,
				};
				indexOp("/service_user/show", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.info = res.data;
						_this.setShare();
					}
				});
			},
			//获取评论列表
			getPinglun() {
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				var data = {
					uid: _this.uid,
				};
				indexOp("/order_comment/index", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.pinglunList = res.data.data;
					}
				});
			},
			getVideo() {
				var _this = this;
				var data = {
					uid: _this.uid,
				};
				indexOp("/video/index", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.videoList = res.data.data;
						// _this.getVideoposter();
					}
				});
			},
			getGoodsShopInfo() {
				var _this 	=	this;
				indexOp("/goods_shop/shop_info?shop_uid=" + this.userInfo.id).then((res) => {
					_this.goodsShopLoading 	=	true;
					if (res && res.status == 200) {
						if(!res.data.error){
							_this.goodsShopInfo = res.data;
						}
					}
					this.checkOpenShop();
				});
			},
			checkOpenShop(){
				var _this 	=	this;
				if (this.active == '4') {
					if (!this.goodsShopInfo && this.isMe()) {
						_this.$dialog
							.confirm({
								title: "提示",
								message: "你还没有申请开通店铺，不可售卖产品，是否申请0元入驻？",
								confirmButtonText: "我要入驻",
								cancelButtonText: "暂不入驻",
							})
							.then(() => {
								_this.$router.push({
									path: "/GoodsShopSettle",
									query: {},
								});
							})
							.catch(() => {
								_this.$router.back()
							});
					}
					return false;
				}
			},
			//去对话
			toChat() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert()
					return;
				}
				var to_uid = _this.info.uid; //聊天对象 uid
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: to_uid,
						from_uid: form_uid,
						chat_type: "normal",
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							to_uid: to_uid,
						},
					});
				});
			},
			//获取轮播
			getSlide() {
				var _this = this;
				indexOp("/slide/index?cid=3").then((res) => {
					if (res && res.status == 200) {
						_this.slideList = res.data;
					}
				});
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			goBack() {
				this.$router.go(-1);
			},
			toEditShop() {
				this.$router.push("/editShifuinfo");
			},
			toUploadVideo() {
				this.$router.push({
					path: "/upload",
				});
			},
			getVideoposter() {
				var _this = this;
				this.videoList.forEach((item) => {
					let video = document.createElement("video");
					let canvas = document.createElement("canvas");
					// video.setAttribute("crossOrigin", "anonymous"); //处理跨域
					video.setAttribute("src", _this.url + item.video_url);
					video.setAttribute("width", 350);
					video.setAttribute("height", 190);
					video.setAttribute("controls", "controls");
					video.setAttribute("autoplay", "autoplay");
					// video.currentTime = 1;
					video.addEventListener("canplay", function() {
						setTimeout(() => {
							canvas.width = video.width;
							canvas.height = video.height;
							canvas
								.getContext("2d")
								.drawImage(video, 0, 0, video.width, video.height); //绘制canvas
							var dataURL = canvas.toDataURL("image/png"); //转换为base64
							item.cover = dataURL;
							//     console.log(dataURL);
						}, 200);
					});
				});
			},
		},
	};
</script>

<style scoped lang="less">
	@ThemColor: #2f78dc;

	.btns {
		bottom: 110px !important;
	}



	.btns {
		position: fixed;
		right: 15px;
		bottom: 80px;

		.btn {
			height: 35px;
			width: 120px;
			border-radius: 35px;
			font-size: 14px;
			margin: 10px auto 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: @ThemColor;
			box-shadow: 4px 6px 28px -10px rgba(79, 77, 77, 0.5);
			box-sizing: border-box;
			color: #fff;

			span {
				line-height: 1;
			}

			i {
				margin-right: 10px;
				color: #fff;
			}
		}
	}

	.product-list {
		display: flex;
		flex-wrap: wrap;
		padding: 10px;
	}

	.shop-detail .tabs-box .card-box .product-list .item {
		width: 33%;
		padding: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: none;
	}

	.product-list .item img {
		width: 100%;
		height: 65px;
		object-fit: cover;
		margin-bottom: 5px;
	}

	.product-list .item span {
		font-size: 14px;
	}

	.search-box {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.input-box {
			width: 70%;
			position: relative;
			// background: #f2f2f2;

			.icon {
				position: absolute;
				left: 10px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}

			.van-cell {
				padding: 0;
				border-radius: 35px;
				line-height: 35px;
				font-size: 14px;
			}

			.input {
				width: 100%;
				background: #f2f2f2;
				border-radius: 35px;
				height: 35px;
				border: none;
				font-size: 14px;
				padding-left: 35px;

				.van-search__content {
					background: #f2f2f2;
					padding-left: 0;
				}
			}
		}

		.type-btn {
			width: 27%;
			height: 35px;
			border-radius: 35px;
			background: #fff;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				margin-right: 5px;
			}
		}
	}

	.type {
		padding: 5px 15px;
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>