<template>
  <div class="product">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">产品详情</span>
      <van-icon
        name="delete"
        @click="onDelete"
        v-if="userInfo && info.uid == userInfo.id"
      />
    </div>
    <div class="content">
      <div class="banner-box">
        <img class="banner-img" :src="url + info.image" alt="" />
      </div>
      <div class="title-box">{{ info.goods_name }}</div>
      <div class="detail-box">
        <div class="title">产品价格：¥{{ info.show_price }}</div>
        <div class="title">产品数量：{{ info.amount }}</div>
        <div class="title">商品详情</div>
        <div v-html="info.content"></div>
      </div>
    </div>
    <div class="btns">
      <a
        v-if="userInfo && userInfo.user_vip_info && companyInfo.phone"
        :href="`tel:${companyInfo.phone}`"
        class="btn"
      >
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <a v-else :href="`javascript:;`" @click.stop="showVIp" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <div class="btn" @click="toChat">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      pid: "", //产品id
      info: "",
      companyInfo: "",
      userInfo: {
        id: "",
      },
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.pid = this.$route.query.id;
    this.getInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    showVIp() {
      this.$dialog
        .confirm({
          title: "提示",
          confirmButtonText: "查看会员权益",
          message: "成为冷鲜柜黄金会员即可直接查看拨打电话",
        })
        .then(() => {
          this.$router.push("/vip");
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.info.uid,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.companyInfo = res.data;
        }
        console.log(res);
      });
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.goods_name,
        desc: "聚制冷精英，集行业资源，方便您我他！",
        link:
          base.shareURL +
          "/peijian_productDetail?id=" +
          this.pid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    getInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/goods/show/" + _this.pid;
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.getShopDetail();
          _this.setShare();
        }
        console.log(res);
      });
    },
    onDelete() {
      var _this = this;
      var api = "/goods/destroy/" + _this.info.id;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除产品？",
        })
        .then(() => {
          _this.$toast.loading({
            message: "",
            forbidClick: true,
          });
          addOp(api).then((res) => {
            _this.$toast.clear();
            if (res && res.status == 200) {
              _this.$toast.success("操作成功！");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 800);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toBack() {
      this.$router.go(-1);
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
  },
};
</script>

<style scoped >
</style>
