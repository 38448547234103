<template>
	<div class="home container expo-detail">
		<Navbar :title="info.title"></Navbar>

		<div class="swiper-box" style="padding:0px;">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true"
				style="border-radius: 0px;padding:0px;border:0px;margin:0;height:auto!important;">
				<van-swipe-item v-for="(item, index) in info.images" :key="index"
					style="width:100%;height:auto!important;">
					<a>
						<img :src="baseUrl + item" alt="" style="height:100%!important;" />
					</a>
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="content">
			<div class="title-box">
				<div class="title">{{ info.title }}</div>
				<div class="time">
					<div class="left">
						<van-icon name="clock-o" style="margin-right: 5px" size="14" />
						{{ info.start_time.substring(0,10) }} 至 {{ info.end_time.substring(0,10) }}
					</div>
					<div class="right">{{ getExpoStatus(info) }}</div>
				</div>
			</div>
			<div class="visit-box">
				<div class="content" style="height:94px;border-radius:5px;overflow:hidden;">
					<img v-if="info.images && expoType != 'yugao'" :src="baseUrl + info.images[0]" alt="" style="width:70%;" />
					<template v-if=" expoType == 'yugao' " >
						<div class="right-box" @click="toJoinExpo" style="width:50%;">
							<span class="tit">参展商</span>
							<span class="subtit">Exhibitor</span>
							<span class="btn">申请成为参展商</span>
						</div>
					</template>
					<template v-else>
						<div class="right-box" @click="toJoinExpo" style="width:30%;">
							<span class="tit">参展商</span>
							<span class="subtit">Exhibitor</span>
							<span class="btn">我是参展商</span>
						</div>
					</template>
					
					<!--  -->
					<template v-if="expoType == 'yugao'">
						<div class="right-box" @click="toJoinVisit" style="background:#FFBA00;width:50%;" v-if="!vositInfo">
							<span class="tit">门票</span>
							<span class="subtit">Admission Fee</span>
							<span class="btn">免费领取门票</span>
						</div>
						<div class="right-box" @click="toJoinVisit" style="background:#FFBA00;width:50%;" v-else>
							<span class="tit">门票</span>
							<span class="subtit">Admission Fee</span>
							<span class="btn">查看我的门票</span>
						</div>
					</template>
					
				</div>
			</div>
			<!-- <div class="organiser">
				<div class="info bg" v-if="info.expo_sponsor_user">
					<div class="left">
						<img class="avatar" :src="baseUrl + info.expo_sponsor_user.company_logo" alt="" />
						<div class="btn" @click="toSponsorIndex(info.uid)">访问主页</div>
					</div>
					<div class="right">
						<div class="title">
							主办方名称：{{ info.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							主办方地址：{{ info.expo_sponsor_user.company_address }}
						</div>
						<div class="address">
							<span>联系方式：{{ info.expo_sponsor_user.company_phone }}</span>
							<div class="btn" @click="toChat(info.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
			</div> -->

			<!-- <div class="expo-info" v-if="info && info.videos[0]">
				<div class="title" v-if="info.videos">展会视频：</div>
				<div class="video-box" v-if="info.videos">
					<video :src="baseUrl + info.videos[0]" controls="controls"></video>
				</div>
				<div class="title">展会介绍：</div>
				<div class="desc-box">
					{{ info.desc }}
				</div>
				<div class="title">合作单位：</div>
				<div class="company-box">
					<a href="javascript:;">{{ info.partner }}</a>
				</div>
			</div> -->

			<div class="partin-list">
				<van-tabs v-model="tabActive">
					<van-tab title="展会详情"></van-tab>
					<van-tab title="参展商"></van-tab>
				</van-tabs>
				<template v-if="tabActive == 1">
					<template v-if="info.expo_join_data && info.expo_join_data.length > 0 ">
						<div class="item" v-for="(item, index) in info.expo_join_data" :key="index"
							style="flex-wrap: wrap;">
							<div style="width:100%;display:flex;" @click="toJoinerIndex(item.join_uid)">
								<div class="left" style="margin-right:10px;">
									<img class="avatar" v-if="item.expo_join_user"
										:src="baseUrl + item.expo_join_user.company_logo" alt="" style="width: 50PX;
								height: 50PX;
								border-radius: 50%;margin-bottom:0px;" />
									<!-- <div class="btn" @click="toJoinerIndex(item.join_uid)">
																访问主页
															</div> -->
								</div>
								<div class="right" style="width:70%;">
									<div class="title" style="font-size:18px;">
										{{ item.expo_join_user.company_name }}
									</div>
									<div class="address" style="color:coral;font-weight:bold;">
										展位号：{{ item.expo_join_user.zhanwei ? item.zhanwei : '---' }}
									</div>
									<!-- <div class="address">
																参展商地址：{{ item.expo_join_user.company_address }}
															</div>
															<div class="address">
																<span>联系方式：{{ item.expo_join_user.company_phone }}</span>
																<div class="btn" @click="toChat(item.join_uid)">
																	<i class="iconfont iconduihua"></i>
																	<span>发起聊天</span>
																</div>
															</div> -->
								</div>
							</div>
							<div class="hz-product" style="width:100%;flex:1;display" v-if="item.goodsList">
								<div class="list" v-for="(citem,cindex) in item.goodsList" @click="toProduct(citem)">
									<img class="avatar" v-if="citem.images[0]" :src="baseUrl + citem.images[0]"
										alt="" />
									<p style="font-size:13px;">{{ citem.goods_name }}</p>
								</div>
							</div>
						</div>
					</template>
					<van-empty v-else description="暂无数据" />
				</template>
				<template v-else>
					<div style="background:#fff;width:100%;padding:10px;box-sizing: border-box;:26px;position:relative;top:-10px;border-top:1px solid #eee;line-height:25px;">
						<p v-html="info.content"></p>
					</div>
				</template>

				<!-- <div class="more-btn">点击加载更多</div> -->
			</div>

			<!-- <div class="partin-list service">
				<div class="title">
					<span class="left">服务商</span>
					<span class="right" @click="toJoinService">申请成为服务商</span>
				</div>
				<div class="service-title">
					<span :class="service_tab == '1' ? 'item active' : 'item'"
						@click="onChangeServiceTab('1')">搭建商</span>
					<span :class="service_tab == '2' ? 'item active' : 'item'"
						@click="onChangeServiceTab('2')">租赁商</span>
				</div>

				<van-list v-model="loading" :finished="finished" :finished-text="service_list.length > 0 ? '没有更多了' : ''"
					@load="getServiceList">
					<div class="item" v-for="(item, index) in service_list" :key="index"
						@click="toServiceIndex(item.id)">
						<div class="left">
							<img class="avatar" :src="baseUrl + item.images[0]" alt="" />
							<div class="btn" style="background: #f7b500" @click="toServiceIndex(item.id)">
								访问主页
							</div>
						</div>
						<div class="right">
							<div class="title">服务商名称：{{ item.company_name }}</div>
							<div class="address">服务商地址：{{ item.company_address }}</div>
							<div class="address">
								<span>联系方式：{{ item.company_phone }}</span>
								<div class="btn" @click="toChat(item.uid)">
									<i class="iconfont iconduihua"></i>
									<span>发起聊天</span>
								</div>
							</div>
						</div>
					</div>
					<van-empty v-if="service_list.length == 0" description="暂无数据" />
				</van-list>

				 <div class="more-btn">点击加载更多</div> 
			</div> -->
		</div>
		<van-popup v-model="vositShow" closeable >
			<div style="padding:30px;">
				<vue-qr v-if="vositInfo && vositShow" :text='vositInfo.uuid' :size="200"></vue-qr>
				<div class="" style="text-align:center;height:40px;line-height:40px;color:red;font-weight:bold;">您的门票二维码</div>
			</div>
		</van-popup>
		
		<!-- <Tabber></Tabber> -->
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		loginAlert
	} from "../../utils/loginAlert";

	import QRcode from "qrcode";
	import $ from "jquery";
	import VueQr from 'vue-qr';


	export default {
		components: {
			Tabber,
			Navbar,
			VueQr 
		},
		props: {},
		data() {
			return {
				expo_id: "",
				info: {},
				baseUrl: base.apiURL,
				// baseUrl: 'http://api.zglxgw.com/',
				loginURL: base.loginURL,
				userInfo: {
					id: "",
				},
				invate_uid: "",
				service_tab: "1",
				service_list: [],
				loading: false,
				finished: false,
				page: "",

				vositInfo: null,
				vositShow : false,
				
				expoType : 'wangqi',

				tabActive: 0,
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.expo_id = this.$route.query.id;
			this.getInfo();
			this.wxLogin();
			this.loadVositInfo();
		},
		mounted() {},
		watch: {},
		methods: {
			loadVositInfo() {
				var _this = this;
				indexOp('/expo_vistor/my', {
					expo_id: this.expo_id,
					uid: this.userInfo ? this.userInfo.id : ''
				}).then(function(res) {
					if (res.data.state == 1000) {
						_this.vositInfo = res.data.data;
					}
				});
			},
			toProduct(item) {
				this.$router.push({
					path: "/productIndex",
					query: {
						id: item.id,
					},
				});
			},
			getServiceList() {
				var _this = this;
				var data = {
					expo_id: _this.expo_id,
					page: _this.page + 1,
				};
				if (_this.service_tab == "1") {
					data.builder = "t";
				} else if (_this.service_tab == "2") {
					data.render = "t";
				}
				indexOp("/expo_service_user/index", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.service_list = res.data.data.data;
							_this.page = res.data.data.current_page;
						} else {
							_this.finished = true;
						}
						_this.loading = false;
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			onChangeServiceTab(tab) {
				this.service_tab = tab;
				this.getServiceList();
			},
			toServiceIndex(id) {
				this.$router.push({
					path: "/serviceIndex",
					query: {
						id: id,
					},
				});
			},
			toJoinerIndex(id) {
				var _this = this;
				this.$router.push({
					path: "/joinerIndex",
					query: {
						id: id,
					},
				});
				return;
				if (id == _this.userInfo.id) {
					this.$router.push({
						path: "/joinerUser",
						query: {
							id: id,
						},
					});
				} else {
					this.$router.push({
						path: "/joinerIndex",
						query: {
							id: id,
						},
					});
				}
			},
			getExpoStatus(info) {
				var _this = this;
				var current_time = new Date().getTime();
				var start_time = new Date(info.start_time).getTime();
				var end_time = new Date(info.end_time).getTime();

				if (current_time < start_time) {
					return "报名中";
				} else if (current_time > start_time && current_time < end_time) {
					return "活动中";
				} else {
					return "活动结束";
				}
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			toChat(toId) {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = toId; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					loginAlert()
				}
			},
			//分享
			setShare() {
				if(!this.info){
					return false;
				}
				console.log(this.info.start_time);
				const shareInfo = {
					title: `会展资讯-${this.info.title}(${this.info.start_time.substring(0,10)}日见)`,
					desc: `媒体合作，互相传播，资源共享，合作共赢，共同发展！`,
					link: base.shareURL +
						"/expoDetail?id=" +
						this.expo_id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			getInfo() {
				var _this = this;
				var data = {
					id: _this.expo_id,
				};
				indexOp("/expo/show", data).then((res) => {
					if (res && res.data.state == 1000) {
						_this.info = res.data.data;
						// _this.getServiceList()
						_this.setShare();
						
						var infoTime 	=	(new Date(res.data.data.start_time)).getTime(),
							nowTime 	=	(new Date()).getTime();
						if( infoTime > nowTime ){
							_this.expoType 	=	'yugao';
						}else{
							_this.expoType = 'previous';
						}
						
					} else {
						_this.$toast.fail(res.data.msg);
						_this.$router.go(-1);
					}
					console.log(res);
				});
			},
			toSponsorIndex(id) {
				this.$router.push({
					path: "/sponsorIndex",
					query: {
						id: id,
					},
				});
			},
			//申请参观
			toJoinVisit() {

				if (this.vositInfo) {

					this.vositShow 	=	true;

					return false;
				}

				if (this.userInfo.id == "") {
					loginAlert()
					return;
				}
				this.$router.push({
					path: "/joinVisit",
					query: {
						id: this.expo_id,
					},
				});
			},
			//申请参展
			toJoinExpo() {
				if (this.userInfo.id == "") {
					loginAlert()
					return;
				}
				this.$router.push({
					path: "/joinExpo",
					query: {
						id: this.expo_id,
						scene : this.expoType
					},
				});
			},
			//申请服务商
			toJoinService() {
				if (this.userInfo.id == "") {
					loginAlert()
					return;
				}
				this.$router.push({
					path: "/joinServiceExpo",
					query: {
						id: this.expo_id,
					},
				});
			},
		},
	};
</script>

<style scoped>
</style>