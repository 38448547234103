<template>
  <div class="identity">
    <Navbar :title="'设置身份'"></Navbar>
    <img class="logo" style="margin-bottom:30px;" src="@/assets/image/logo.png" alt="" />
    <!-- <div class="title">设置身份</div> -->
    <div class="tips">聚一帮靠谱的人，做一件靠谱事</div>
    <div class="btns-box">
      <div :class="active === 2 ? 'item active' : 'item'" @click="setIdentity(2)">
        <p class="title"><i class="iconfont iconweixiu1"></i>我是维修师傅 - 我要接单挣钱</p>
        <p class="subtit">您可以：承接维修订单，上门维修，方便挣钱</p>
        <div class="radio-btn">
          <i class="iconfont iconqueren1" v-if="active === 2"></i>
          <i class="iconfont iconquan" v-else></i>
        </div>
      </div>
      <div :class="active === 0 ? 'item active' : 'item'" @click="setIdentity(0)">
        <p class="title"><i class="iconfont iconyonghu"></i>我是普通用户 - 我要找维修</p>
        <p class="subtit">您可以：找维修师傅，方便快捷维修</p>
        <div class="radio-btn">
          <i class="iconfont iconqueren1" v-if="active === 0"></i>
          <i class="iconfont iconquan" v-else></i>
        </div>
      </div>
      <div :class="active === 1 ? 'item active' : 'item'" @click="setIdentity(1)">
        <p class="title"><i class="iconfont iconyonghu"></i>我是制冷厂商 - 我要找维修</p>
        <p class="subtit">您可以：找维修师傅，方便快捷维修，维护客户资源</p>
        <div class="radio-btn">
          <i class="iconfont iconqueren1" v-if="active === 1"></i>
          <i class="iconfont iconquan" v-else></i>
        </div>
      </div>
    </div>
    <div style="height:64px;width:100%;">
      <div class="success-tips" v-show="active !== ''">
        请确认你选择的身份是：{{active === 0 ? '普通用户' : active === 1 ? '制冷厂商' : '维修师傅'}}<br/>
        确认之后，不可直接更换身份
      </div>
    </div>
    <van-button type="primary" block round style="width:80%;margin-top:40px;font-size:16px;" @click="onSubmit">确认选择</van-button>
    <div class="xieyi">注册登录即视为您同意《冷鲜柜网条例》</div>
    <!-- <div class="btns">
      <div class="btn" @click="setIdentity(0)">
        <img src="@/assets/image/user.png" alt="" />
        <span>终端用户</span>
      </div>
      <div class="btn" @click="setIdentity(1)">
        <img src="@/assets/image/user1.png" alt="" />
        <span>制冷厂商</span>
      </div>
      <div class="btn" @click="setIdentity(2)">
        <img src="@/assets/image/shifu.png" alt="" />
        <span>维修师傅</span>
      </div>
      <div class="btn" @click="setIdentity(3)">
        <img src="@/assets/image/shifu.png" alt="" />
        <span>模具配件</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
export default {
  components: { Navbar, Tabber },
  data() {
    return {
      peijianDetail: "",
      shopDetail: "",
      companyDetail: "",
      userInfo: "",

      active: 2,
      back: 'user',
    };
  },
  created() {
    const back = this.$route.query.back
    if(back){
      this.back = back
    }
  },
  methods: {
    onSubmit(){
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      if(_this.active === ''){
        _this.$toast.fail("必须选择一个身份！");
        return;
      }
      var data = {
        identity: _this.active,
      };
      addOp("/user/update/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.userInfo = res.data;
          _this.$cookies.set("identity", res.data.identity);
          _this.$cookies.set("userInfo", res.data);
          if(_this.back){
            _this.$router.push({
              path:"/" + _this.back
            })
          }else{
            _this.$router.push({
              path:"/user"
            })
          }
          // if (res.data.identity == 0) {
          //   if (!res.data.mobile) {
          //     _this.$router.push("/editUserinfo");
          //   } else {
          //     _this.$router.push("/user");
          //   }
          // } else if (res.data.identity == 1) {
          //   _this.getCompanyDetail();
          // } else if (res.data.identity == 2) {
          //   _this.getShopDetail();
          // } else if (res.data.identity == 3) {
          //   _this.getPeijianDetail();
          // }
        }
      });
    },
    setIdentity(type) {
      var _this = this;
      _this.active = type;
    },
    //获取配件模具详情
    getPeijianDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/fitting_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.peijianDetail = res.data;
          if (_this.peijianDetail == "") {
            _this.$router.push("/editPeijianinfo");
          } else {
            _this.$router.push("/user");
          }
        }
      });
    },
    //获取制冷厂商详情
    getCompanyDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        if (res && res.status == 200) {
          _this.companyDetail = res.data;
          if (_this.companyDetail == "") {
            _this.$router.push("/editShopinfo");
          } else {
            _this.$router.push("/user");
          }
        }
      });
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/service_user/show", data).then((res) => {
        if (res && res.status == 200) {
          _this.shopDetail = res.data;
          if (_this.shopDetail == "") {
            _this.$router.push("/editShifuinfo");
          } else {
            _this.$router.push("/user");
          }
        }
        console.log(res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
}
.btns-box{
  width: 100%;
  margin-top: 37px;
  .item{
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;

    .title{
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
      width: 90%;

      .iconfont{
        margin-right:5px;
      }
    }
    .subtit{
      font-size: 12px;
      color: #67C23A;
      width: 90%;
    }
    .radio-btn{
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      .iconfont{
        font-size: 18px;
        color: #f5f5f5;
      }
      .iconqueren1{
        color:#67C23A;
      }
    }
  }
  .item.active{
    background: #F5F7FA;
  }
}
.success-tips{
  width: 90%;
  background: #F5F7FA;
  font-size: 13px;
  color:#F56C6C;
  padding: 15px 20px;
  border-radius: 8px;
  margin: 0 auto;
}
.xieyi{
  font-size: 12px;
  color:#7d7d7d;
  text-align: center;
  margin-top: 50px;
  // position: fixed;
  // bottom: 20px;
}
</style>