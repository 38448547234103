<template>
  <!-- 注册 -->
  <div class="chongzhi">
    <Navbar :title="'账户转账'"></Navbar>
    <div class="top">
      <p class="title">转账金额</p>
      <div class="input-box">
        <span>¥</span>
        <input type="text" v-model="money" placeholder="" />
      </div>
      <p class="msg">
        账户余额：{{ userInfo.user_account ? userInfo.user_account : 0 }} 元
      </p>
    </div>
    <div class="bottom" style="background: #fff; margin-top: 10px">
      <p class="title">转账手机号</p>
      <input type="text" v-model="phone" placeholder="请输入转账手机号" />
    </div>
    <div class="bottom-box">
      <a href="javascript:;" class="xiadan-btn" @click="onShow">确认转账</a>
    </div>
    <!-- 转账 -->
    <van-overlay :show="transferShow">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="onTransferClose" />
            <div class="title">身份验证</div>
            <input
              class="money-input"
              type="password"
              placeholder="请输入登录密码"
              v-model="password"
            />
            <div class="btns">
              <div class="btn" @click="pay">确认</div>
            </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
// import websocket from '../utils/webSocket'
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../api";
import base from "../api/base.js";
import {loginAlert} from "../utils/loginAlert";
export default {
  inject: ["reload"],
  components: {
    Navbar,
  },
  data() {
    return {
      money: "",
      jsApiParameters: "",
      radio: "1",
      userInfo: "",
      shopDetail: "",
      companyDetail: "",
      phone: "",
      password:"",
      transferShow:false
    };
  },
  created() {
    var _this = this;
    if (this.$cookies.isKey("userInfo")) {
      this.getUser();
    } else {
      loginAlert()
      return;
    }
  },
  methods: {
    onShow(){
      var _this = this;
      if (_this.userInfo.is_password != 1) {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "为了您的账户安全，请先去设置登录密码。",
            confirmButtonText: "去设置",
          })
          .then(() => {
            _this.$router.push({
              path: "/editUserinfo",
            });
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      if(_this.money == ""){
        _this.$toast("请输入转账金额！");
        return;
      }
      if (_this.money < 1) {
        _this.$toast("提现金额不得低于1元！");
        return;
      }
      if (parseFloat(_this.money) > parseFloat(_this.userInfo.user_account)) {
        _this.$toast("转账金额不得大于账户余额！");
        return;
      }
      if(_this.phone == ""){
        _this.$toast("请输入转账手机号！");
        return;
      }
      _this.transferShow = true;
    },
    onTransferClose(){
      var _this = this;
      _this.transferShow = false;
    },
    pay() {
      var _this = this;
      if(_this.password == ""){
        _this.$toast("请输入登录密码！");
        return;
      }
      var data = {
        money: _this.money,
        mobile: _this.phone,
        password:_this.password,
      };
      _this.$toast.loading({
        message: "请稍等...",
        forbidClick: true,
        duration: 0,
      });
      addOp("/user_account/transfer", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.$toast.success("操作成功！");
          _this.reload();
        } else {
          _this.$toast.fail(res.data.error);
        }
      });
    },
    getUser() {
      var _this = this;
      var api = "/user/show/1";
      indexOp(api).then((res) => {
        if (res && res.status == 200) {
          if (res.data.identity == null) {
                _this.$router.push({
                    path: "/identity",
                });
                return;
            }
          _this.$cookies.set("identity", res.data.identity);
          _this.$cookies.set("userInfo", res.data);
          _this.userInfo = res.data;
          _this.getShopDetail();
          _this.getcompanyDetail();
        }
      });
    },

    //获取店铺详情
    getShopDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/service_user/show", data).then((res) => {
        if (res && res.status == 200) {
          _this.shopDetail = res.data;
        }
        console.log(res);
      });
    },
    //获取厂商详情
    getcompanyDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        if (res && res.status == 200) {
          _this.companyDetail = res.data;
        }
        console.log(res);
      });
    },
    //支付
    jsApiCall() {
      var _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        _this.jsApiParameters,
        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            alert("充值成功！");
            _this.$router.go(-1);
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            alert("支付失败！请重试！");
          }
        }
      );
    },
    callpay() {
      var _this = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            _this.jsApiCall,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
          document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
        }
      } else {
        _this.jsApiCall();
      }
    },
  },
};
</script>

<style>
</style>