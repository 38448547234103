import http from '@/utils/request'
import VueCookies from 'vue-cookies'
import base from "../api/base"
import * as socketApi from './webSocket'
import {
	Toast,
	Dialog
} from 'vant';
import router from '../router';
//登录
export function login(funLogin) {
	var code = getUrlKey("code");
	var state = getUrlKey("state");
	const uidStr = VueCookies.get("Authorization");
	const invate_uid = VueCookies.get("invate_uid");
	//判断地址栏参数
	if (code && state) {
		Dialog.close()
		//登录
		http.get(`/oauth/callback`, {
				params: {
					code: code,
					state: state,
					invate_uid: invate_uid
				}
			})
			.then(async function(res) {
				if (res && res.status == 200 && res.data.access_token) {
					Toast.success("登陆成功！")
					VueCookies.set(
						"Authorization",
						res.data.token_type + " " + res.data.access_token
					);
					//获取用户信息
					await getUser(funLogin)
					// console.log(uinfo)
				} else {
					Toast.fail(res.data.error)
					// window.location.href = "http://www.zglxgw.com/";
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}
}
//获取地址栏参数
function getUrlKey(name) {
	return (
		decodeURIComponent(
			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(
				/\+/g, "%20")) || null
	);
};
//获取用户信息
export function getUser(funLogin) {
	var userInfo;
	Dialog.close()
	http.get("/user/show/1").then(async res => {
		if (res && res.status == 200) {
			if (res.data.identity == null) {
				const back = getUrlKey("back")
				if (back) {
					router.push({
						path: "/identity",
						query: {
							back: back
						}
					});
				} else {
					router.push({
						path: "/identity",
					});

				}

				return;
			}
			userInfo = res.data
			VueCookies.set("userInfo", res.data)
			VueCookies.set("identity", res.data.identity)
			// window.reload();
			funLogin(res.data)
			await socketApi.initWebSocket();
		}
	})
	return userInfo
};
// login();