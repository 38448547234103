<template>
  <div class="video-swiper">
    <van-swipe
      class="my-swipe swiper-container"
      vertical
      :show-indicators="false"
      :loop="false"
      @change="slideChange"
      @click="onSlideClick"
    >
      <van-swipe-item class="swiper-item">
        <video
          class="video"
          ref="video"
          src="../assets/image/x.mp4"
          @click="onPlay($event)"
        ></video>
        <div class="bottom-box">
          <div class="avatar-box">
            <img src="../assets/image/avatar.png" alt="" />
            <span class="name">小徐维修店</span>
          </div>
          <div class="title">测试测试测试测试是</div>
        </div>
      </van-swipe-item>
      <van-swipe-item class="swiper-item">
        <video class="video" ref="video" src="../assets/image/x.mp4"></video>
        <div class="bottom-box">
          <div class="avatar-box">
            <img src="../assets/image/avatar.png" alt="" />
            <span class="name">小徐维修店</span>
          </div>
          <div class="title">测试测试测试测试是</div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- <swiper
      :options="swiperOption"
      :not-next-tick="notNextTick"
      ref="mySwiper"
      @slideChange="slideChange"
      class="swiper-container"
    >
      <swiper-slide class="swiper-item" ref="swiperSlider">
        <video ref="video" src="../assets/image/x.mp4"></video>
        <div class="bottom-box">
          <div class="avatar-box">
            <img src="../assets/image/avatar.png" alt="" />
            <span class="name">小徐维修店</span>
          </div>
          <div class="title">测试测试测试测试是</div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-item" ref="swiperSlider">
        <video
          ref="video"
          src="../assets/image/x.mp4"
          controls="controls"
        ></video>
      </swiper-slide>
    </swiper> -->
  </div>
</template>

<script>
import base from "../api/base";
export default {
  components: {},
  props: {},
  data() {
    var _this = this;
    return {
      notNextTick: true,
      swiperOption: {
        // swiper options 所有的配置同swiper官方api配置
        direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        // autoplay:2000,
        // swiper callbacks
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        onTransitionStart(swiper) {
          console.log(swiper);
        },

        // more Swiper configs and callbacks...
        // ...
      },
      url: base.apiURL,
      loginURL: base.loginURL,
      uid: "",
      userInfo: "",
      videoList: "",
      activeIndex: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {
    this.userInfo = this.$cookies.get("userInfo");
    this.uid = this.$route.query.uid;
    // this.getlist();
  },
  mounted() {},
  watch: {},
  methods: {
    onPlay(e) {
      var video = e.target;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    onSlideClick(e) {
      console.log(e);
    },
    slideChange(index) {
      console.log(index);
      var video = document.getElementsByClassName("video");
      console.log(video)
      if(video.length > index){
        video[index].play()
        for(let i = 0;i < video.length;i++){
          if(i!=index){
            console.log(i)
            video[i].pause()
          }
        }
      }
    },
    getlist() {
      var _this = this;
      var data = {
        uid: _this.uid,
      };
      indexOp("/video/index", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.videoList = res.data.data;
          // _this.getVideoposter();
        }
      });
    },
  },
};
</script>

<style scoped lang="less" >
.my-swipe .van-swipe-item {
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .bottom-box {
    position: absolute;
    width: 70%;
    bottom: 40px;
    left: 0;
    padding: 0 20px;
    color: #fff;
    .avatar-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .name {
        font-size: 16px;
      }
    }
    .title {
      font-size: 14px;
    }
  }
}
.video-swiper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-item {
    width: 100%;
    height: 100%;
    position: relative;

    .bottom-box {
      position: absolute;
      width: 70%;
      bottom: 40px;
      left: 0;
      padding: 0 20px;
      color: #fff;

      .avatar-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .name {
          font-size: 16px;
        }
      }
      .title {
        font-size: 14px;
      }
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      width: 100%;
    }
  }
}
</style>
