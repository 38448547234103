<template>
	<div class="home container expo-detail">
		<Navbar :title="'免费领取门票'"></Navbar>

		<!-- <div class="swiper-box">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true">
				<van-swipe-item v-for="(item, index) in info.images" :key="index">
					<a>
						<img :src="baseUrl + item" alt="" />
					</a>
				</van-swipe-item>
			</van-swipe>
		</div> -->
		<div class="content">
			<div class="title-box">
				<div class="title">{{ info.title }}</div>
				<div class="time">
					<div class="left">
						<van-icon name="clock-o" />{{ info.start_time.substring(0,10) }} 至
						{{ info.end_time.substring(0,10) }}
					</div>
					<div class="right">{{ info.format_time_text }}</div>
				</div>
			</div>

			<div class="organiser">
				<div class="info bg" v-if="info.expo_sponsor_user">
					<div class="left">
						<img class="avatar" :src="baseUrl + info.expo_sponsor_user.company_logo" alt="" />
						<!-- <div class="btn" @click="toSponsorIndex(info.uid)">访问主页</div> -->
					</div>
					<div class="right">
						<div class="title">
							主办方名称：{{ info.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							主办方地址：{{ info.expo_sponsor_user.company_address }}
						</div>
						<div class="address">
							<span>联系方式：{{ info.expo_sponsor_user.company_phone }}</span>
							<div class="btn" @click="toChat(info.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-box">
				<!-- <div class="title">申请成为参观者</div> -->
				<div class="input-item">
					<div class="label" style="width: 32%">真实姓名：</div>
					<input class="input" type="text" placeholder="" v-model="user_name" />
				</div>
				<!-- <div class="input-item">
					<div class="label" style="width: 32%">企业名称：</div>
					<input class="input" type="text" placeholder="" v-model="company_name" />
				</div>
				<div class="input-item">
					<div class="label" style="width: 32%">感兴趣的产品：</div>
					<textarea class="input" type="text" placeholder="" style="height: 50px" v-model="goods"></textarea>
				</div>
				<div class="input-item">
					<div class="label" style="width: 32%">经营的类目：</div>
					<textarea class="input" type="text" placeholder="" style="height: 50px"
						v-model="goods_class"></textarea>
				</div> -->
				<div class="input-item">
					<div class="label" style="width: 32%">联系方式：</div>
					<input class="input" type="text" placeholder="" v-model="mobile" />
				</div>
				<!-- <div class="input-item">
					<div class="label" style="width: 32%">所在城市：</div>
					<input class="input" type="text" placeholder="" v-model="city_address" readonly
						@click="showCityPicker = true" />
					<van-popup v-model="showCityPicker" round position="bottom">
						<van-area title="选择地址" :area-list="areaList" @cancel="showCityPicker = false"
							@confirm="onCityConfirm" />
					</van-popup>
				</div>
				<div class="input-item">
					<div class="label" style="width: 32%">详细地址：</div>
					<textarea class="input" type="text" placeholder="" style="height: 50px"
						v-model="address"></textarea>
				</div>

				<div class="tip-box">
					<div>友情提示：</div>
					<div style="text-indent: 20px">
						您的申请，需要主办方审核同意后方可正常展示，具体事项，可以与主办方直接沟通。
					</div>
					<div style="text-indent: 20px">
						当主办方确认您参展后，您的信息可在展会信息下方进行展出。
					</div>
				</div> -->
				<div class="sub-btn" @click="onSubmit">提交报名</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import province_list from "../../utils/area";
	import {
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				afterRead: "",
				radio: "",
				baseUrl: base.apiURL,
				loginURL: base.loginURL,
				areaList: province_list,

				info: {},
				expo_id: "",
				joinerInfo: {},
				userInfo: {
					id: "",
				},
				invate_uid: "",

				logoImgList: [],
				picImgList: [],

				user_name: "",
				company_name: "", //公司名称
				province: "", //公司地址-省
				city: "", //公司地址-市
				area: "", //公司地址-区
				goods: "",
				goods_class: "",
				mobile: "",

				city_address: "",

				showCityPicker: false,
				address: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			if (this.userInfo.id == "") {
				loginAlert()
			}
			this.expo_id = this.$route.query.id;
			this.wxLogin();
			this.getExpoInfo();
			// this.getUserInfo()
			this.setShare();
		},
		mounted() {},
		watch: {},
		methods: {
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-免费领取门票`,
					desc: `媒体合作，共助发展，一次参展，将享受365天的宣传服务！`,
					link: base.shareURL +
						"/joinExpo?id=" +
						this.expo_id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			toChat(toId) {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = toId; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					loginAlert()
				}
			},
			onSubmit() {
				var _this = this;
				if (_this.user_name == "") {
					_this.$toast.fail("真实姓名不能为空！");
					return;
				}
				if (_this.mobile == "") {
					_this.$toast.fail("联系方式不能为空！");
					return;
				}
				var data = {
					expo_id: _this.expo_id,
					user_name: _this.user_name,
					// company_name: _this.company_name,
					mobile: _this.mobile,
					// province: _this.province,
					// city: _this.city,
					// area: _this.area,
					// address: _this.address,
					// goods: _this.goods,
					// goods_class: _this.goods_class,
				};
				console.log(data);
				// return;
				addOp("/expo_vistor/store", data).then((res) => {
					if (res && res.data.state == 1000) {
						// _this.$toast.success("报名成功，请等待主办方审核！")
						_this.$dialog
							.alert({
								title: "提示",
								message: "领取成功，您可在展会详情点击查看门票，查看自己的门票！",
								confirmButtonText: "知道了",
							})
							.then(() => {
								_this.$router.go(-1);
								// on confirm
							})
							.catch(() => {
								// on cancel
							});
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			joinExpo() {
				var _this = this;
				var data = {
					expo_id: _this.expo_id,
				};
				console.log(data);
				// return;
				addOp("/expo_joiner/join_expo", data).then((res) => {
					if (res && res.data.state == 1000) {
						_this.$toast.success(res.data.msg);
						setTimeout(() => {
							_this.$router.go(-1);
						}, 800);
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},

			//选择城市
			onCityConfirm(value, index) {
				this.showCityPicker = false;
				this.province = value[0].name;
				this.city = value[1].name;
				this.area = value[2].name;
				this.city_address =
					value[0].name + "-" + value[1].name + "-" + value[2].name;
				this.address = value[0].name + value[1].name + value[2].name + " ";
			},
			//logo图片上传
			afterReadLogo(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.company_logo = res.data.url;
						console.log(res);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//资质图片上传
			afterReadPic(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.company_pic = res.data.url;
						console.log(res);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//获取展会详情
			getExpoInfo() {
				var _this = this;
				var data = {
					id: _this.expo_id,
				};
				indexOp("/expo/show", data).then((res) => {
					console.log(res);
					if (res && res.data.state == 1000) {
						_this.info = res.data.data;
					}
				});
			},
			//获取用户参展商信息
			getUserInfo() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/expo_joiner/show", data).then((res) => {
					if (res && res.data.state == 1000) {
						_this.joinerInfo = res.data.data;

						_this.company_name = res.data.data.company_name;
						_this.company_phone = res.data.data.company_phone;
						_this.company_address = res.data.data.company_address;
						_this.company_desc = res.data.data.company_desc;
						_this.company_logo = res.data.data.company_logo;
						_this.company_province = res.data.data.company_province;
						_this.company_city = res.data.data.company_city;
						_this.company_area = res.data.data.company_area;
						_this.company_pic = res.data.data.company_pic;
						_this.address =
							res.data.data.company_province +
							"-" +
							res.data.data.company_city +
							"-" +
							res.data.data.company_area;
						if (res.data.data.company_pic) {
							var pic_img = {
								url: _this.baseUrl + res.data.data.company_pic,
							};
							_this.picImgList.push(pic_img);
						}
						if (res.data.data.company_logo) {
							var logo_img = {
								url: _this.baseUrl + res.data.data.company_logo,
							};
							_this.logoImgList.push(logo_img);
						}
					}
				});
			},
			toSponsorIndex(id) {
				this.$router.push({
					path: "/sponsorIndex",
					query: {
						id: id,
					},
				});
			},
		},
	};
</script>

<style scoped>
</style>