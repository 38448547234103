// 导入axios
import Vue from 'vue'
import axios from "axios"
import router from '@/router'
import base from '../api/base'
import VueCookies from 'vue-cookies'
import {
	Toast,
	Dialog
} from 'vant';

function getUrlKey(name) {
	return (
		decodeURIComponent(
			(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
				location.href
			) || [, ""])[1].replace(/\+/g, "%20")
		) || null
	);
}

function loginalert(msg) {
	let loginURL = base.loginURL
	let Msg = ''
	if (!msg) {
		Msg = '您还未登录，请先登录！'
	}
	if (getUrlKey('code') && getUrlKey('state') ) {
		return false;
	}
	Dialog
		.confirm({
			title: "提示",
			message: Msg,
			confirmButtonText: "微信登录",
			cancelButtonText: "账号登录",
		})
		.then(() => {
			const url = encodeURIComponent(window.location.href);
			window.location.href = loginURL + "?url=" + url;
			VueCookies.remove("Authorization");
			VueCookies.remove("userInfo");
			VueCookies.remove("identity");
		})
		.catch(() => {
			const pahtname = window.location.pathname.slice(1)
			const search = window.location.search; //获取url中"?"符后的字串
			console.log(pahtname)
			router.push({
				path: "/login",
				query: {
					isPhone: true,
					back: pahtname + search
				}
			});
			VueCookies.remove("Authorization");
			VueCookies.remove("userInfo");
			VueCookies.remove("identity");
		});
}
//1. 创建新的axios实例，
const service = axios.create({
	baseURL: base.apiURL,
	// baseURL:"/api",
	timeout: 60 * 1000
})

// 请求拦截器
service.interceptors.request.use(config => {
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等
	const token = VueCookies.get("Authorization");
	// const token = window.sessionStorage.getItem("Authorization")
	if (token) {
		config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
	}
	// console.log(config)
	// if(invate_uid){
	//   config.params = {'invate_uid':invate_uid };
	// }
	return config;
}, error => {
	return Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
	//接收到响应数据并成功后的一些共有的处理，关闭loading等
	// console.log(response)
	return response
}, error => {
	/***** 接收到异常响应的处理开始 *****/
	// console.log(error.response)
	if (error && error.response) {
		// 1.公共错误处理
		if (error.response.data.state == "401") {
			loginalert()
			return;
		}
		if (error.response.data.message == "Unauthenticated.") {
			loginalert('您的登录状态已过期！请重新登陆。')
			return;
		}
		if (error.response.data.message == "Token not provided") {
			loginalert('您的登录状态已过期！请重新登陆。')
			return;
		}
		// if (error.response.data.message == "未登录") {
		//   // VueCookies.remove("Authorization");
		//   window.sessionStorage.removeItem("Authorization")
		//   notification['error']({
		//     message: "提示",
		//     top: "200px",
		//     description: `您还没有登陆，请先登录！`,
		//     key: "1"
		//   });
		//   router.push({
		//     path: "/login"
		//   })
		//   return;
		// }
	} else {
		// 超时处理
		if (JSON.stringify(error).includes('timeout')) {
			// message.error('服务器响应超时，请刷新当前页');

			Toast.fail("服务器响应超时，请刷新当前页");
		} else {
			Toast.fail("服务器响应超时，请刷新当前页");
		}
	}

	// message.error(error.message)
	/***** 处理结束 *****/
	//如果不需要错误处理，以上的处理过程都可省略
	return Promise.resolve(error.response)
})
export default service