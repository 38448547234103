<template>
	<div class="upload-video">
		<Navbar :title="'添加产品'"></Navbar>
		<!-- <div class="title">产品分类</div>
		<div class="input-item">
			<van-field v-model="formData.category" type="text" placeholder="请选择产品所在分类" />
		</div> -->
		<div class="title">产品名称</div>
		<div class="input-item">
			<van-field v-model="formData.goods_name" autosize type="text" placeholder="请输入产品名称" />
		</div>
		<div class="title">产品图片</div>
		<div class="upload-box">
			<van-uploader v-model="fileList" :after-read="uploadVideo" upload-text="请选择您要上传的产品图片" upload-icon="photo"
				max-count="1" :before-delete="deleteImg" accept="image/*" />
		</div>
		<div class="title">产品介绍</div>
		<div class="editor-box">
			<editor @editor-input="onEditorChange"></editor>
		</div>
		<div class="btn-box">
			<button class="btn" @click="onSubmit">提交</button>
		</div>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";

	import Editor from "../../components/editor";
	import GoodsList from "../../components/GoodsList";
	
	export default {
		components: {
			Navbar,
			Tabber,
			Editor,
			GoodsList
		},
		props: {},
		data() {
			return {
				fileList: [],
				formData: {
					goods_name: "",
					images: [],
					content: "",
				},
				userInfo: "",
				htmlValue: '',
				// 售后保障
				afterHtmlValue : '',
				showPicker: false,
				columns: [],
				classValue: "",
				id: "", //厂商id
				cid: "", //分类id
			};
		},
		computed: {},
		created() {
			this.userInfo = this.$cookies.get("userInfo");
			this.id = this.$route.query.id
		},
		mounted() {},
		watch: {},
		methods: {
			onEditorChange(value) {
				this.htmlValue = value
				console.log(value)
			},
			uploadVideo(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						console.log(res);
						_this.formData.images.push(res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
				console.log(file);
			},
			//删除以上传图片
			deleteImg(file, detail) {
				this.fileList.splice(detail.index, 1);
				this.formData.images.splice(detail.index, 1);
			},
			onSubmit() {
				var _this = this;
				if (_this.formData.goods_name == "") {
					_this.$toast.fail("产品名称不能为空！");
					return;
				}
				if (_this.htmlValue == "") {
					_this.$toast.fail("产品介绍不能为空！");
					return;
				}
				
				if (_this.formData.images.length == 0) {
					_this.$toast.fail("请至少上传一张产品图片！");
					return;
				}
				_this.formData.content = _this.htmlValue;

				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				addOp("/expo_joiner/create_goods", _this.formData).then((res) => {
					_this.$toast.clear();
					console.log(res)
					if (res && res.data.state == 1000) {
						_this.$toast.success(res.data.msg);
						setTimeout(() => {
							_this.$router.go(-1);
						}, 800);
					}
				});
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped>
</style>