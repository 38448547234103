<template>
  <div class="changshang-index peijian">
    <div class="nav-box">
      <div class="nav-box">
        <router-link
          :to="{ path: '/peijianIndex', query: { uid: uid } }"
          class="item"
          >网站首页</router-link
        >
        <!-- <router-link :to="{path:'/changshangIndex', query:{ uid:uid }}" class="item">公司简介</router-link> -->
        <router-link
          :to="{ path: '/peijian_productList', query: { uid: uid } }"
          class="item"
          >产品展示</router-link
        >
        <router-link
          :to="{ path: '/peijian_newsList', query: { uid: uid } }"
          class="item"
          >资讯中心</router-link
        >
        <router-link
          :to="{ path: '/peijian_videoList', query: { uid: uid } }"
          class="item"
          >视频中心</router-link
        >
        <router-link
          :to="{ path: '/peijian_contactUs', query: { uid: uid } }"
          class="item"
          >联系我们</router-link
        >
      </div>
    </div>

    <div class="swiper-box">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in slideList" :key="index">
          <img class="banner-img" :src="url + item.cover" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="title-box">
      <span class="text">联系我们</span>
    </div>
    <div class="contact-us">
      <p>
        {{ info.name }}
      </p>
      <p>
        <span>联系人：</span>
        {{ info.person }}
      </p>
      <p>
        <span>联系方式：</span>
        {{ info.phone }}
      </p>
      <a
        :href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`"
      >
        <span>厂商地址：</span>
        {{ info.province + info.city + info.area + info.address }}
      </a>
    </div>
    <div class="btns">
      <!-- <div class="btn" @click="toChat">
        <i class="iconfont iconwangdian"></i>
        <span>服务网点</span>
      </div>
      <div class="btn" @click="toChat">
        <i class="iconfont iconwangdian"></i>
        <span>附近网点</span>
      </div> -->
      <a
        v-if="userInfo && userInfo.user_vip_info && info.phone"
        :href="`tel:${info.phone}`"
        class="btn"
      >
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <a v-else :href="`javascript:;`" @click.stop="showVIp" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <div class="btn" @click="toChat">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  props: {},
  components: { Tabber },
  data() {
    return {
      url: base.apiURL,
      loginURL: base.loginURL,
      slideList: "", //轮播列表
      userInfo: {
        id: "",
      },
      uid: "",
      info: "",
      goodsList: "", //产品列表
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }

    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.uid = this.$route.query.uid;
    console.log(this.uid);
    this.getShopDetail();
    this.getSlide();
  },
  mounted() {},
  watch: {},
  methods: {
    showVIp() {
      this.$dialog
        .confirm({
          title: "提示",
          confirmButtonText: "查看会员权益",
          message: "成为冷鲜柜黄金会员即可直接查看拨打电话",
        })
        .then(() => {
          this.$router.push("/vip");
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取轮播
    getSlide() {
      var _this = this;
      indexOp("/slide/index?cid=1").then((res) => {
        if (res && res.status == 200) {
          _this.slideList = res.data;
        }
      });
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: "聚制冷精英，集行业资源，方便您我他！",
        link:
          base.shareURL +
          "/peijian_contactUs?uid=" +
          this.uid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.uid,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.getProductList(res.data.id);
          _this.setShare();
        }
        console.log(res);
      });
    },
    getProductList(id) {
      var _this = this;
      var data = {
        id: id,
      };
      indexOp("/goods/index", data).then((res) => {
        if (res && res.status == 200) {
          _this.goodsList = res.data;
        }
        console.log(res);
      });
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>

<style scoped >
</style>
