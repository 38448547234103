<template>
    <div class="vip">
        <van-nav-bar class="navber" fixed placeholder z-index="99" :border="false">
            <template #title>
                <span class="title">充值会员</span>
            </template>
            <template #left>
                <van-icon @click="goBack" name="arrow-left" color="#DDAE88" size="15px" />
            </template>
        </van-nav-bar>
        <div class="top-box">
            <img :src="userInfo.avatar" alt="" />
            <div class="right">
                <div class="top">
                    <span class="name" v-if="userInfo.nickname">{{
                            userInfo.nickname
                    }}</span>
                    <span class="name" v-else-if="userInfo.truename">{{
                            userInfo.truename
                    }}</span>
                    <span class="name" v-else>完善个人信息</span>
                    <span class="shenfen" v-if="userInfo.identity == 0">终端用户</span>
                    <span class="shenfen" v-if="userInfo.identity == 1">制冷厂商</span>
                    <span class="shenfen" v-if="userInfo.identity == 2">维修师傅</span>
                </div>
                <div class="bottom" v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level != 0">
                    会员到期时间：{{ userInfo.user_vip_info.end_time }}
                </div>
                <div class="bottom" v-else-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 0">
                    会员已到期，请续费
                </div>
                <!-- <div class="bottom" v-else>会员到期时间：2021年12月12日</div> -->
            </div>
        </div>
        <div class="bottom-box">
            <div class="card">
                <div class="top">
                    <i class="iconfont iconzuanshi"></i>
                    <span>权益加榜</span>
                </div>
                <div class="bottom">充值会员，享受更多权益</div>
            </div>
            <div class="title">超值选择：</div>
            <div class="choice" v-if="userInfo.identity == 1">
                <div class="item" @click="onChange(2)">
                    <span class="tit">黄金会员</span>
                    <span class="price"><b>¥</b>99.90</span>
                    <span class="time">有效期：一年</span>
                    <i class="iconfont iconqueren1" v-if="type == 2"></i>
                </div>
                <div class="item">
                    <!--  @click="onChange(3)" -->
                    <span class="tit">钻石会员</span>
                    <span class="price">暂不开放</span>
                    <!-- <span class="price"><b>¥</b>999.90</span> -->
                    <span class="time">有效期：一年</span>
                    <i class="iconfont iconqueren1" v-if="type == 3"></i>
                </div>
            </div>
            <div class="choice" v-else>
                <div class="item" @click="onChange(1)">
                    <span class="tit">普通会员</span>
                    <span class="price"><b>¥</b>9.90</span>
                    <span class="time">有效期：一年</span>
                    <i class="iconfont iconqueren1" v-if="type == 1"></i>
                </div>
                <!-- <div class="item" @click="onChange(2)">
                    <span class="tit">黄金会员</span>
                    <span class="price"><b>¥</b>99.90</span>
                    <span class="time">有效期：一年</span>
                    <span class="time">暂不开放</span>
                    <i class="iconfont iconqueren1" v-if="type == 2"></i>
                </div> -->
            </div>
            <template v-if="userInfo.identity === 0">
                <template>
                    <div class="title">普通会员权益：</div>
                    <div class="text">
                        1.发布信息找师傅维修（免收服务费）
                    </div>
                    <div class="text">
                        2.享受普通会员对应的专属产品价格
                    </div>
                    <div class="text">
                        3.享受维修费优惠及技术咨询
                    </div>
                </template>
            </template>
            <template v-else-if="userInfo.identity === 1">
                <div class="title">会员协议：</div>

                <div class="text" style="color:#DDAE88;">
                    确认付款前，请仔细阅读，付年服务费99.9元。加入冷鲜柜网的可以查看全国各地区，现有制冷维修师傅的电话号码并及时联系，平台不担保交易。想让平台替咱们监督师傅维修的，还需要下单发布需求信息，让师傅在线接单，在线交流，用系统交易，这里可以记录着你们的聊天交易过程。
                </div>

                <div class="title">黄金会员权益：</div>
                <div class="text">
                    1.发布信息找师傅维修（每单服务费10元）
                </div>
                <div class="text">
                    2.系统支持确认接单的维修师傅，可自动同步至厂商家自己的售后维修服务网点
                </div>
                <div class="text">
                    3.售后维修每单全程跟踪服务
                </div>
                <div class="text">
                    4.单单都有维修聊天记录可查
                </div>
                <div class="text">
                    5.配专属客服服务客户
                </div>
                <div class="text">
                    6.支持查看全国各地的维修师傅电话
                </div>
                <div class="text">
                    7.支持宣传自己的产品及企业
                </div>
                <div class="text">
                    8.自动加入全国冷企大全商业库
                </div>
            </template>
            <template v-else>
                <div class="title">会员协议：</div>

                <div class="text" style="color:#DDAE88;">
                    确认付款前，请仔细阅读，这个只是代表咱们加入冷鲜柜网的会员年服务费，不代表师傅等级级别，每一个入会员的师傅都要实实在在的做事，用自己的技术为客户做好服务，不能坑蒙客户，否则封号曝光处理，并且不退服务费。
                </div>
                <div class="title">普通会员权益：</div>
                <div class="text">
                    1.享受优先承接维修订单
                </div>
                <div class="text">
                    2.可加入全国(含本地)城市服务群成员，享受资源共享，技术交流及沟通指导，打赏提问
                </div>
                <div class="text">
                    3.享受普通会员对应的专属产品价格
                </div>
                <div class="text">
                    4.享受预购产品福利
                </div>
                <div class="text">
                    5.可申请城市合伙人
                </div>
                <div class="text">
                    6.有权聚集自己的客户资源
                </div>
            </template>
        </div>

        <div class="btn-box">
            <button class="pay-btn" @click="payTypeShow = true">立即充值</button>
        </div>

        <!-- 支付方式 -->
        <van-overlay :show="payTypeShow" @click="payTypeShow = false">
            <div class="payType-mask" @click.stop>
                <div class="title">请选择支付方式</div>
                <div class="item" @click="changePayType(1)">
                    <i class="iconfont iconyue"></i>
                    <span>余额支付</span>
                    <i v-if="payType == 1" class="iconfont iconqueren1 active"></i>
                    <i v-else class="iconfont iconqueren1"></i>
                </div>
                <!-- <div class="item" @click="changePayType(2)">
          <i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
          <span>微信支付</span>
          <i v-if="payType == 2" class="iconfont iconqueren1 active"></i>
          <i v-else class="iconfont iconqueren1"></i>
        </div> -->
                <button class="pay-btn" @click="onPay">支付</button>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import { getVue, payAccount } from "../utils/payAccount"
import {
    indexOp,
    updateOp,
    deleteOp,
    addOp,
    showOp,
    uploadImg,
    uploadFile,
} from "../api/index";
import base from "../api/base";
import { wechatConfig } from "../utils/wxConfig";
import { getUser, login } from "../utils/login_toast";
export default {
    props: {},
    data() {
        return {
            style: {
                width: "88%",
            },
            value: "",
            showSend: false,
            showMore: false,
            userInfo: {
                id: "",
            },
            type: '', //1 黄金会员 2 铂金会员 3 砖石会员
            payTypeShow: false,
            payType: 1,
            jsApiParameters: "",
            invate_uid: ""
        };
    },
    computed: {},
    created() {
        var invate_uid = this.$route.query.invate_uid;
        if (invate_uid) {
            this.$cookies.set("invate_uid", invate_uid);
        }
        if (this.$cookies.isKey("userInfo")) {
            this.userInfo = this.$cookies.get("userInfo");
        }
        this.WXlogin()
    },
    mounted() { },
    watch: {},
    methods: {
        WXlogin() {
            var _this = this;
            var token = this.$cookies.get("Authorization");
            var code = this.getUrlKey("code");
            var state = this.getUrlKey("state");
            if (!token && code && state) {
                login(_this.getLoginUser);
            } else {
                this.getUser();
            }
        },
        getLoginUser(userInfo) {
            this.getUser();
            console.log(userInfo);
        },
        getUrlKey(name) {
            return (
                decodeURIComponent(
                    (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                        location.href
                    ) || [, ""])[1].replace(/\+/g, "%20")
                ) || null
            );
        },
        //分享
        setShare() {
            const shareInfo = {
                title: `冷鲜柜网-会员权益`,
                desc: `聚制冷精英，集行业资源，方便您我他！`,
                link: base.shareURL + "/vip?invate_uid=" + this.userInfo.id,
                img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
            };
            wechatConfig(shareInfo);
        },
        getUser() {
            var _this = this;
            var api = "/user/show/1";
            indexOp(api).then((res) => {
                if (res && res.status == 200) {
                    if (res.data.identity == null) {
                        _this.$router.push({
                            path: "/identity",
                        });
                        return;
                    }
                    _this.$cookies.set("identity", res.data.identity);
                    _this.userInfo = res.data;
                    _this.setShare();
                }
            });
        },
        goBack() {
            this.$router.go(-1);
        },
        onChange(index) {
            this.type = index;
        },
        changePayType(type) {
            //切换支付类型
            this.payType = type;
        },
        //支付
        onPay() {
            var _this = this;
            var type = this.payType;
            if (_this.type == '') {
                _this.$toast.fail("请选择会员！")
                return;
            }
            if (_this.type == 1) {
                var money = 9.9;
            }
            if (_this.type == 2) {
                var money = 99.9;
            }
            if (_this.type == 3) {
                var money = 999.9;
            }
            // 微信支付
            if (type == 2) {
                _this.$toast.loading({
                    message: "提交中...",
                    forbidClick: true,
                    duration: 0,
                });
                var data = {
                    money: money,
                    pay_type: "user_vip_wxpay",
                };
                addOp("/pay_order", data).then((res) => {
                    _this.$toast.clear();
                    if (res && res.status == 200) {
                        _this.jsApiParameters = res.data;
                        _this.callpay();
                        _this.payTypeShow = false;
                    } else {
                        _this.$toast.fail(res.data.error);
                    }
                });
            } else {
                // 余额支付
                var _this = this;
                var user_account = parseFloat(_this.userInfo.user_account);
                //判断余额是否充足
                // if (user_account >= money) {
                _this.$toast.loading({
                    message: "提交中...",
                    forbidClick: true,
                    duration: 0,
                });
                // var data = {
                //   money: money,
                //   pay_type: "user_vip_account",
                // };
                var data = {
                    vip_level: _this.type,
                }
                var api = "/bill_order/recharge_vip"
                addOp(api, data).then((res) => {
                    console.log(res);
                    _this.$toast.clear();
                    if (res && res.status == 200 && res.data.state == 1000) {
                        alert("充值成功！");
                        _this.$router.push({
                            path: "/user",
                        });
                        _this.payTypeShow = false;
                    } else {
                        if (res.data.state == 9999) {
                            payAccount(money, user_account)
                        } else if (res.data.state == 1005) {
                            _this.$dialog
                                .confirm({
                                    title: "提示",
                                    message: "账户余额不足，请先进行充值！",
                                    confirmButtonText: "去充值",
                                })
                                .then(() => {
                                    _this.$router.push("/chongzhi");
                                })
                                .catch(() => {
                                    // on cancel
                                });

                        } else {
                            _this.$toast.fail(res.data.msg);

                        }
                    }
                });
                // } else {
                //   _this.$toast.fail("账户余额不足，请改用其他支付方式！");
                // }
            }
        },
        //支付
        jsApiCall() {
            var _this = this;
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                _this.jsApiParameters,
                function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        alert("充值成功！");
                        _this.$router.push({
                            path: "/user",
                        });
                        _this.payTypeShow = false;
                    } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                        alert("支付失败！请重试！");
                    }
                }
            );
        },
        callpay() {
            var _this = this;
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener(
                        "WeixinJSBridgeReady",
                        _this.jsApiCall,
                        false
                    );
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
                    document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
                }
            } else {
                _this.jsApiCall();
            }
        },
    },
    components: {
        Tabber,
        Navbar,
    },
};
</script>

<style>

</style>