import { render, staticRenderFns } from "./joiner_product_detail.vue?vue&type=template&id=6cfdfc75&scoped=true"
import script from "./joiner_product_detail.vue?vue&type=script&lang=js"
export * from "./joiner_product_detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfdfc75",
  null
  
)

export default component.exports