<template>
  <!-- 用户设置 -->
  <div class="user-edit">
    <Navbar :title="'设置'"></Navbar>
    <div class="content">
      <div class="item" @click="onShowToast">
        <div class="left">
          <i class="iconfont iconshenfen-tianchong"></i>
          <span class="title">申请变更身份</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </div>
      <router-link to="/editUserinfo" class="item">
        <div class="left">
          <i class="iconfont iconshezhi"></i>
          <span class="title">完善个人信息</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <router-link to="/editShopinfo" class="item" v-if="userInfo.identity == 1">
        <div class="left">
          <i class="iconfont icondianpushezhi"></i>
          <span class="title">完善厂商信息</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <router-link to="/editShifuinfo" class="item" v-if="userInfo.identity == 2">
        <div class="left">
          <i class="iconfont iconweixiu1"></i>
          <span class="title">完善维修信息</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <router-link to="/editPeijianinfo" class="item" v-if="userInfo.identity == 3">
        <div class="left">
          <i class="iconfont icondianpushezhi"></i>
          <span class="title">完善厂商信息</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <router-link to="/EditPassword" class="item">
        <div class="left">
          <i class="iconfont iconxiugaimima"></i>
          <span class="title">修改密码</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <router-link to="/account" class="item">
        <div class="left">
          <i class="iconfont iconqiehuanzhanghao"></i>
          <span class="title">切换账号</span>
        </div>
        <i class="iconfont iconjiantou"></i>
      </router-link>
      <van-button class="logout" type="danger" @click="onLogout" block>退出登录</van-button>
    </div>


    <van-popup v-model="show">
      <div class="mask-box">
        <div class="title">变更身份请长按图片识别二维码添加客服微信进行申请。</div>
        <img src="../assets/image/kefu.png" alt="">
      </div>
    </van-popup>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
export default {
  props: {},
  data() {
    return {
      userInfo:"",
      show:false
    };
  },
  computed: {},
  created() {
    this.userInfo = this.$cookies.get("userInfo");
  },
  mounted() {},
  watch: {},
  methods: {
    onShowToast(){
      this.show = true
    },
    onLogout(){
      var _this = this;
      _this.$dialog.confirm({
        title: '提示',
        message: '是否要退出该账号？',
      })
        .then(() => {
          // on confirm
          _this.$cookies.remove("Authorization");
          _this.$cookies.remove("userInfo");
          _this.$cookies.remove("identity");
          _this.$router.push({
            path:"/login",
            query:{
              back: 'user'
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  components: { Navbar, Tabber },
};
</script>

<style scoped >
.mask-box{
  padding: 20px;
  border-radius: 10px;
}
.mask-box .title{
  font-size: 14px;
  margin-bottom: 20px;

}
.mask-box img{
  width: 250px;
}
</style>
