<template>
	<!-- <div
    :class="
      userInfo && userInfo.user_vip_info && userInfo.user_vip_info.vip_level > 0
        ? ' user vipUser'
        : 'user'
    "
  > -->
	<div class="user vipUser">
		<div class="top-box">
			<div class="top-navbar">
				<van-icon name="arrow-left" @click="toBack" />
				<span class="text">我的</span>
				<!-- <span style="font-size: 12px" @click="toEditUset">设置</span> -->
			</div>
			<div class="user-info" v-if="userInfo">
				<img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
				<img v-else src="@/assets/image/shifu.png" alt="" />
				<div class="center" style="padding-bottom:0;">
					<p class="name" v-if="userInfo.nickname">{{ userInfo.nickname }}</p>
					<p class="name" v-else-if="userInfo.truename">
						{{ userInfo.truename }}
					</p>
					<p class="name" v-else>请去完善个人信息</p>
					<!-- <p class="uid" style="color: #fff; font-size: 12px" v-if="userInfo">
            用户ID：{{ userInfo.id }}
          </p> -->
					<p class="uid" style="color: #DFAC7A; font-size: 10px" v-if="userInfo.identity == 2">维修师傅</p>
					<p class="uid" style="color: #DFAC7A; font-size: 10px" v-if="userInfo.identity == 1">制冷厂商</p>
					<p class="uid" style="color: #DFAC7A; font-size: 10px" v-if="userInfo.identity == 0">终端用户</p>
					<p class="uid" style="color: #DFAC7A; font-size: 10px" v-if="userInfo.identity == 3">配件模具厂商</p>
					<p class="uid" style="color: #DFAC7A; font-size: 10px" v-if="userInfo.identity == 9">客服</p>
					<!-- <div
            @click="show = true"
            class="vip-box"
            v-if="userInfo.user_vip_info"
          >
            <div class="left" style="padding: 0; margin: 0; border: none">
              <p class="tit" v-if="userInfo.user_vip_info.vip_level == 1">
                普通会员
              </p>
              <p class="tit" v-else-if="userInfo.user_vip_info.vip_level == 2">
                黄金会员
              </p>
              <p class="tit" v-else-if="userInfo.user_vip_info.vip_level == 3">
                钻石会员
              </p>
            </div>
          </div>
          <router-link to="/vip" class="vip-box active" v-else>
            <div class="left">
              <p class="tit">体验会员</p>
            </div>
            <div class="right">
              <p>立即升级</p>
            </div>
          </router-link> -->
					<!-- <p class="shenfen" v-if="userInfo.identity == 2">维修师傅</p>
          <p class="shenfen" v-else-if="userInfo.identity == 1">制冷厂商</p>
          <p class="shenfen" v-else-if="userInfo.identity == 0">终端用户</p>
          <p class="shenfen" v-else-if="userInfo.identity == 3">配件模具厂商</p>
          <p class="shenfen" v-else-if="userInfo.identity == 9">客服</p> -->
					<p class="shenfen" style="top: 50%;transform: translateY(-50%);" @click="toEditUset">编辑</p>
				</div>
			</div>
			<div class="user-info" v-else>
				<img src="@/assets/image/user.png" alt="" />
				<div class="center">
					<router-link to="/login" class="name">点击登录</router-link>
				</div>
			</div>
		</div>
		<!-- <div
      class="vip-time"
      v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level > 0"
    >
      <span>会员到期时间：{{ userInfo.user_vip_info.end_time }}</span>
      <span style="color: rgba(2, 0, 1, 0.69)" @click="$router.push('/vip')"
        >会员权益 >>></span
      >
    </div> -->
		<div class="bottom-box">
			<div class="zh-box">
				<div class="left">
					<i class="iconfont iconqianbaoqiandaiyue"></i>
				</div>
				<div class="right">
					<router-link to="/accountBalance" class="item">
						<p class="money" v-if="userInfo">{{ (parseFloat(userInfo.user_account) + parseFloat(userInfo.service_account) + parseFloat(userInfo.goods_account)).toFixed(2) }}</p>
						<p class="money" v-else>0.00</p>
						<p class="tit">账户余额</p>
					</router-link>
					<router-link to="/freezeLog" class="item">
						<p class="money" v-if="userInfo">{{ userInfo.freeze_account }}</p>
						<p class="money" v-else>0.00</p>
						<p class="tit">待入账</p>
					</router-link>
					<div class="item">
						<p class="money" v-if="userInfo">{{ userInfo.user_score }}</p>
						<p class="money" v-else>0</p>
						<p class="tit">积分</p>
					</div>
				</div>
			</div>


			<template v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 1">
				<div class="vip-card ordinary" @click="onShowVipCard">
					<span class="text">普通会员到期时间：{{userInfo.user_vip_info.end_time}}</span>
					<van-icon name="arrow" />
				</div>
			</template>
			<template v-else-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 2">
				<div class="vip-card glod" @click="onShowVipCard">
					<span class="text">黄金会员到期时间：{{userInfo.user_vip_info.end_time}}</span>
					<van-icon name="arrow" />
				</div>
			</template>
			<template v-else>
				<div class="vip-card default" @click="$router.push('/vip')">
					<span class="text">当前是体验会员，立即升级会员享受专属权利</span>
					<van-icon name="arrow" />
				</div>
			</template>

			<template v-if="showVipCard">
				<template v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level">

					<!-- 判断会员等级 1：普通会员 2：黄金会员 -->
					<template v-if="userInfo.user_vip_info.vip_level == 1">

						<div class="vipinfo-box" v-if="userInfo.identity == 0" @click="$router.push('/vip')">
							<p class="title text-color">普通会员 - 终端用户</p>
							<p class="subtit">1.发布信息找维修师傅<span class="text-color">（免收服务费）</span></p>
							<p class="subtit">2.享受普通会员对应的<span class="text-color">专属产品价格</span></p>
							<p class="subtit">3.支持<span class="text-color">查看全国各地的维修师傅电话</span></p>
							<p class="time">会员到期时间：{{userInfo.user_vip_info.end_time}}</p>
							<img class="vip-bg" src="../../assets/image/vip-bg.png" alt="">
						</div>
						<div class="vipinfo-box" v-else-if="userInfo.identity == 2" @click="$router.push('/vip')">
							<p class="title text-color">普通会员 - 维修师傅</p>
							<div :class="isShowInfo ? '' : 'info-box'">
								<p class="subtit">1.享受<span class="text-color">优先承接</span>维修订单</p>
								<p class="subtit">2.可加入<span
										class="text-color">全国(含本地)</span>城市服务群成员，享受资源共享，技术交流及沟通指导，打赏提问</p>
								<p class="subtit">3.享受普通会员对应的<span class="text-color">专属产品价格</span></p>
								<p class="subtit">4.享受预购产品福利</p>
								<p class="subtit">5.可申请城市合伙人</p>
								<p class="subtit">6.有权聚集自己的客户资源</p>
							</div>
							<p class="time">会员到期时间：{{userInfo.user_vip_info.end_time}}</p>
							<img class="vip-bg" src="../../assets/image/vip-bg.png" alt="">
							<div class="right-bottom-btn" @click.stop="showMore"><van-icon name="question-o" size="13px"
									style="margin-right:5px;" /> 了解更多</div>
						</div>
					</template>
					<template v-else>
						<div class="vipinfo-box glod" @click="$router.push('/vip')">
							<p class="title text-color">黄金会员 -
								{{userInfo.identity === 0 ? '终端用户' : userInfo.identity === 2 ? '维修师傅' : '厂商用户' }}
							</p>
							<div :class="isShowInfo ? '' : 'info-box'">
								<p class="subtit">1.发布信息找维修师傅<span class="text-color">（每单服务费10元）</span></p>
								<p class="subtit" style="width:70%">2.系统支持确认接单的维修师傅，可自动同步至厂商家自己的售后维修服务网点</p>
								<p class="subtit">3.售后维修每单全程跟踪服务</p>
								<p class="subtit">4.单单都有维修聊天记录可查</p>
								<p class="subtit">5.配专属客服服务客户</p>
								<p class="subtit">6.支持查看全国各地的维修师傅电话</p>
								<p class="subtit">7.支持宣传自己的产品及企业</p>
								<p class="subtit">8.自动加入全国冷企大全商业库</p>
							</div>
							<p class="time">会员到期时间：{{userInfo.user_vip_info.end_time}}</p>
							<img class="vip-bg" src="../../assets/image/vip-bg.png" alt="">
							<div class="right-bottom-btn" @click.stop="showMore"><van-icon name="question-o" size="13px"
									style="margin-right:5px;" /> 了解更多</div>
						</div>
					</template>
				</template>
				<template v-else>
					<!-- 体验会员 -->
					<div class="vipinfo-box default" v-if="userInfo.identity === 0" @click="$router.push('/vip')">
						<p class="title text-color">体验会员 - 终端用户</p>
						<p class="subtit">1.发布信息找师傅维修（仅支持保外维修，收服务费每单20元）</p>
						<p class="subtit">2.享受体验会员对应的产品价格</p>
						<p class="subtit">3.支持查看接单维修师傅的电话</p>
						<div class="right-top-btn" @click="$router.push('/vip')">
							<img class="icon" src="../../assets/image/vip-icon.png" alt="">
							<span>升级普通会员</span>
						</div>
					</div>
					<div class="vipinfo-box default" v-else-if="userInfo.identity === 1" @click="$router.push('/vip')">
						<p class="title text-color">体验会员 - 厂商用户</p>
						<p class="subtit">1.发布信息找师傅维修（支持保内/外维修，收服务费每单20元，保内厂商付款，保外用户付款）</p>
						<p class="subtit">2.享受体验会员对应的服务及产品推广</p>
						<p class="subtit">3.不支持查看全国各地的维修师傅电话</p>
						<div class="right-top-btn" @click="$router.push('/vip')">
							<img class="icon" src="../../assets/image/vip-icon.png" alt="">
							<span>升级普通会员</span>
						</div>
					</div>
					<div class="vipinfo-box default" v-else-if="userInfo.identity === 2" @click="$router.push('/vip')">
						<p class="title text-color">体验会员 - 维修师傅</p>
						<p class="subtit">1.维修接单</p>
						<p class="subtit">2.可加入本地城市服务群成员，享受资源共享及技术交流沟通</p>
						<p class="subtit">3.享受体验会员对应的产品价格</p>
						<p class="subtit">4.享受预购产品福利</p>
						<div class="right-top-btn" @click="$router.push('/vip')">
							<img class="icon" src="../../assets/image/vip-icon.png" alt="">
							<span>升级普通会员</span>
						</div>
					</div>
				</template>
			</template>
			<template v-if="userInfo.identity == 2 && shopDetail != ''">
				<div class="user-notice" v-if="
            !shopDetail.name ||
            !shopDetail.person ||
            !shopDetail.phone ||
            !shopDetail.address ||
            !shopDetail.desc ||
            !shopDetail.scope_desc ||
            !shopDetail.service_desc
          ">
					<span class="text"><van-icon class="icon" name="warning-o" />您的入驻资料不完整，请尽快完善。</span>
					<router-link to="/editShifuinfo" class="btn">去完善</router-link>
				</div>
			</template>
			
			<!-- <div class="user-notice" v-if="!goodsShop">
				<span class="text"><van-icon class="icon" name="warning-o" />想要售卖全新/二手商品？</span>
				<router-link to="/GoodsShopSettle?me=1" class="btn">点击开通</router-link>
			</div>
			<div class="user-notice" v-else>
				<span class="text"><van-icon class="icon" name="warning-o" />您已开通商品售卖通道</span>
				<router-link :to="{
					path : '/myShop',
					query : {
						uid : userInfo.id,
						tab : 4
					}
				}" class="btn">点击管理</router-link>
			</div> -->

			<div class="order-box" v-if="userInfo && userInfo.identity != '3'">
				<div class="title-box">
					<span class="tit">我的订单</span>
					<router-link class="more" to="/shifuOrderList">查看全部订单 > </router-link>
				</div>
				<van-grid :column-num="4">
					<van-grid-item url="/shifuOrderList?tab=1&status=take_order" icon="https://static.op-wx.cn/zglxgw.com/image/take_order.png?v=3" text="已接单" />
					<van-grid-item url="/shifuOrderList?tab=2&status=service_ing" icon="https://static.op-wx.cn/zglxgw.com/image/service_end.png?v=3" text="维修中" />
					<van-grid-item url="/shifuOrderList?tab=3&status=service_end" icon="https://static.op-wx.cn/zglxgw.com/image/appraise_order%20%281%29.png?v=3" text="维修完成" />
					<van-grid-item url="/shifuOrderList?tab=4&status=appraise_order" icon="https://static.op-wx.cn/zglxgw.com/image/appraise_order.png?v=3" text="已评价" />
				</van-grid>
			</div>
			<!-- <div  @click="toMyExpo" class="card-item" >
        <van-icon class="icon" name="shop-o" />
        <span class="title">展会主办方</span>
      </div>
      <div  @click="toMyJoiner" class="card-item" >
        <van-icon class="icon" name="shop-o" />
        <span class="title">展会参展商</span>
      </div> -->


      <!-- <router-link
        :to="{ path: '/shop/myShopIndex', query: { uid: userInfo.id } }"
        class="card-item"
      >
        <van-icon class="icon" name="list-switch" />
        <span class="title">我的商品</span>
      </router-link> -->
			<van-grid :column-num="3">
				<van-grid-item :to="{ path: '/shop/myShopIndex', query: { uid: userInfo.id } }" icon="list-switch" text="我的产品" />
				<van-grid-item url="/chatList" icon="chat-o" text="我的聊天" />
				<van-grid-item  @click="toShopDetail" icon="shop-o" text="我的主页" />
				<van-grid-item url="/upload" icon="video-o" text="上传视频" />
				<van-grid-item url="/noticeLog" icon="star-o" text="流程规则" />
				<van-grid-item url="/noticeLog" icon="share-o" text="邀请注册" />
				<van-grid-item url="/myInviter" icon="friends-o" text="我的邀请" />
			</van-grid>

			<!-- <router-link to="/chatList" class="card-item">
				<van-icon class="icon" name="chat-o" />
				<span class="title">我的聊天</span>
			</router-link>
			<router-link to="/tixian" class="card-item">
				<van-icon class="icon" name="after-sale" />
				<span class="title">no - 账户提现</span>
			</router-link>
			<router-link to="/bill" class="card-item">
				<van-icon class="icon" name="bill-o" />
				<span class="title">no - 账单日志</span>
			</router-link>
			<div @click="toShopDetail" class="card-item" v-if="shopDetail">
				<van-icon class="icon" name="shop-o" />
				<span class="title">我的店铺</span>
			</div>
			<router-link to="/upload" class="card-item" v-if="shopDetail">
				<van-icon class="icon" name="video-o" />
				<span class="title">上传视频</span>
			</router-link>
			<router-link to="/noticeLog" class="card-item">
				<van-icon class="icon" name="star-o" />
				<span class="title">流程规则</span>
			</router-link>
			<router-link to="/inviteReg" class="card-item">
				<van-icon class="icon" name="share-o" />
				<span class="title">邀请注册</span>
			</router-link>

			<router-link to="/myInviter" class="card-item">
				<van-icon class="icon" name="friends-o" />
				<span class="title">我的邀请</span>
			</router-link> -->
		</div>
		<!-- 会员信息 -->
		<van-overlay :show="show" @click="show = false">
			<div class="wrapper" @click.stop>
				<div class="vip-info" v-if="userInfo.user_vip_info">
					<div class="title">我的会员信息</div>
					<div class="text" v-if="userInfo.user_vip_info.vip_level == 1">
						会员等级：普通会员
					</div>
					<div class="text" v-if="userInfo.user_vip_info.vip_level == 2">
						会员等级：黄金会员
					</div>
					<div class="text" v-if="userInfo.user_vip_info.vip_level == 3">
						会员等级：钻石会员
					</div>
					<div class="text">
						开始时间：{{ userInfo.user_vip_info.created_at }}
					</div>
					<div class="text">
						到期时间：{{ userInfo.user_vip_info.end_time }}
					</div>
				</div>
			</div>
		</van-overlay>
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Tabber,
		},
		props: {},
		data() {
			return {
				userInfo: {
					id: "",
				},
				shopDetail: "",
				show: false,

				isShowInfo: false,

				showVipCard: false,
				
				goodsShop : false
			};
		},
		computed: {},
		created() {
			var _this = this;
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}

			var code = _this.getUrlKey("code");
			var state = _this.getUrlKey("state");
			var token = _this.$cookies.get("Authorization");
			if (!token && !code) {
				loginAlert()
				return;
			} else if (!token && code) {
				login(this.getLoginUser);
				return;
			} else {
				_this.getUser();
			}
			_this.setShare();
			
			_this.checkOpen();
		},
		mounted() {},
		watch: {},
		methods: {
			checkOpen(){
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				console.log('checkOpen');
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/goods_shop/shop_info?shop_uid=" + this.userInfo.id).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						if( !res.data.error ){
							_this.goodsShop 	=	res.data;
						}
						console.log('CheckOpen:',res.data,'----');
					}
				});
			},
			onShowVipCard() {
				this.showVipCard = !this.showVipCard
			},
			showMore(e) {
				const _this = this;
				_this.isShowInfo = !_this.isShowInfo
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-个人中心`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/user?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			toMyExpo() {
				var _this = this;
				indexOp("/expo_sponsor_user/show").then((res) => {
					if (res.data.state == 1000) {
						_this.$router.push("/sponsorUser");
					} else if (res.data.state == 1005 && res.data.msg == "数据不存在") {
						_this.$router.push("/sponsorUserEdit");
					}
				});
			},
			toMyJoiner() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/expo_joiner/show", data).then((res) => {
					if (res.data.state == 1000) {
						_this.$router.push("/joinerUser");
					} else if (res.data.state == 1005 && res.data.msg == "数据不存在") {
						_this.$router.push("/joinerUserEdit");
					}
				});
			},
			toEditUset() {
				var _this = this;
				if (!_this.$cookies.isKey("Authorization")) {
					_this.$router.push("/login");
					return;
				}
				_this.$router.push("/userEdit");
			},
			onToast() {
				var _this = this;
				_this.$dialog
					.alert({
						title: "提示",
						message: "您好师傅，为了保障冷链厂商家及用户的利益，咱们收到的待入账余额在48小时后，才可以让您随时提现。",
						confirmButtonText: "确定",
					})
					.then(() => {
						// _this.$router.push("/login");
						// on close
					})
					.catch(() => {
						// on cancel
					});
			},
			//获取店铺详情
			getShopDetail() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status == 200) {
						_this.shopDetail = res.data;
						// if ((_this.shopDetail == "")) {
						//   _this.$dialog
						//     .alert({
						//       title: "提示",
						//       message: "您还未完善公司信息，请先去完善。",
						//       confirmButtonText: "去完善",
						//     })
						//     .then(() => {
						//       _this.$router.push("/editShifuinfo");
						//       // on close
						//     })
						//     .catch(() => {
						//       // on cancel
						//     });
						// }
					}
					console.log(res);
				});
			},
			getUser() {
				var _this = this;
				var api = "/user/show/1";
				indexOp(api).then((res) => {
					if (res && res.status == 200) {
						if (res.data.identity == null) {
							_this.$router.push({
								path: "/identity",
							});
							return;
						}
						_this.$cookies.set("identity", res.data.identity);
						_this.$cookies.set("userInfo", res.data);
						_this.userInfo = res.data;
						_this.getShopDetail();
					}
				});
			},
			toBack() {
				this.$router.go(-1);
			},
			toShopDetail() {
				var _this = this;
				_this.$router.push({
					path: "/myShop",
					query: {
						uid: _this.userInfo.id,
					},
				});
				// if (_this.shopDetail) {
				// 	_this.$router.push({
				// 		path: "/myShop",
				// 		query: {
				// 			uid: _this.userInfo.id,
				// 		},
				// 	});
				// }else{
				// 	_this.$dialog
				// 		.alert({
				// 			title: "提示",
				// 			message: "请先完善您的维修账号信息~",
				// 			confirmButtonText: "确定",
				// 		})
				// 		.then(() => {
				// 			_this.$router.push("/editShifuinfo");
				// 			// on close
				// 		})
				// 		.catch(() => {
				// 			// on cancel
				// 		});
				// }
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped lang="less">
	.vip-info {
		width: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 20px;
		background: #fff;
		border-radius: 10px;

		.title {
			text-align: center;
			font-size: 15px;
			padding-bottom: 20px;
			border-bottom: 1px solid #f2f2f2;
			margin-bottom: 10px;
		}

		.text {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}

	.vipinfo-box {
		padding: 15px;
		background: linear-gradient(169deg, #EBEEFC 0%, #F9F0FC 100%);
		border-radius: 8px;
		margin-bottom: 10px;
		position: relative;

		.title {
			font-size: 14px;
			margin-bottom: 8px;
			font-weight: 500;
		}

		.subtit {
			font-size: 11px;
			color: #616364;
			line-height: 20px;
		}

		.time {
			font-size: 9px;
			color: #7f7f7f;
			margin-top: 26px;
		}

		.text-color {
			color: #5A6FEE;
		}

		.vip-bg {
			position: absolute;
			width: 77px;
			height: 86px;
			right: 20px;
			top: 40%;
			transform: translateY(-50%);
			z-index: 1;
			opacity: .8;
		}

		.right-top-btn {
			position: absolute;
			display: flex;
			align-items: center;
			top: 14px;
			right: 12px;

			.icon {
				width: 16px;
				height: 16px;
				margin-right: 5px;
			}

			span {
				font-size: 9px;
				color: #3A4A6C;
			}
		}

		.right-bottom-btn {
			position: absolute;
			display: flex;
			align-items: center;
			bottom: 15px;
			right: 12px;
			color: #DFAC7A;
			font-size: 10px;
		}
	}

	.vipinfo-box.glod {
		background: linear-gradient(169deg, #F2DAC3 0%, #FFF1E4 100%);

		.text-color {
			color: #AE7E50;
		}
	}

	.vipinfo-box.default {
		background: linear-gradient(169deg, #F3F2FA 0%, #E3EEF4 100%);

		.text-color {
			color: #3A4A6C;
		}
	}

	.info-box {
		height: 80px;
		overflow: hidden;
		position: relative;
		z-index: 2;
	}

	.vip-card {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 8px;
		margin-bottom: 10px;

		.text {
			font-size: 12px;
		}
	}

	.vip-card.default {
		background: linear-gradient(169deg, #F3F2FA 0%, #E3EEF4 100%);
		color: #67C23A;
	}

	.vip-card.glod {
		background: linear-gradient(169deg, #F2DAC3 0%, #FFF1E4 100%);
		color: #AE7E50;
	}

	.vip-card.ordinary {
		background: linear-gradient(169deg, #F3F2FA 0%, #E3EEF4 100%);
		color: #5A6FEE;
	}
</style>