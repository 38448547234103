<template>
  <div class="mineWrap">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">个人中心</span>
    </div>
    <div class="mineMessageList">
      <van-tabs :active="active" @change="onChange">
        <van-tab title="我发布的">
          <div
            class="mineMessageItem"
            v-for="(item, index) in techMessageList"
            :key="index"
            @click="toDetail(item.id)"
          >
            <div class="mineMessageItemTop">
              <img :src="item.uinfo.avatar" alt="" />
              <h3>{{ item.uinfo.nickname }}</h3>
            </div>
            <div class="mineMessageItemCenter">
              <div class="mineMessageItemCenterText">
                <p v-html="item.content"></p>
              </div>
              <div class="mineMessageItemCenterPic" v-if="item.pictures">
                <div v-for="(it, ind) in item.pictures" :key="ind">
                  <van-image
                    style="margin-right: 5px"
                    width="100"
                    height="100"
                    :src="it"
                  />
                </div>
              </div>
              <div class="mineMessageItemCenterPic" v-if="item.video">
                <video
                  autoplay
                  width="100"
                  height="100"
                  :src="item.video"
                ></video>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="我打赏的">
          <div
            class="mineMessageItem"
            v-for="(item, index) in priceMessageList"
            :key="index"
            @click="toDetail(item.id)"
          >
            <div class="mineMessageItemTop">
              <img :src="item.uinfo.avatar" alt="" />
              <h3>{{ item.uinfo.nickname }}</h3>
            </div>
            <div class="mineMessageItemCenter">
              <div class="mineMessageItemCenterText">
                <p v-html="item.content"></p>
              </div>
              <div class="mineMessageItemCenterPic" v-if="item.pictures">
                <div v-for="(it, ind) in item.pictures" :key="ind">
                  <van-image
                    style="margin-right: 5px"
                    width="100"
                    height="100"
                    :src="it"
                  />
                </div>
              </div>
              <div class="mineMessageItemCenterPic" v-if="item.video">
                <video
                  autoplay
                  width="100"
                  height="100"
                  :src="item.video"
                ></video>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Navbar from "../../components/Navbar";
import { indexOp } from "../../api/index";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
import base from "../../api/base";
export default {
  data() {
    return {
      active: 0,
      techMessageList: [],
      priceMessageList: [],
    };
  },
  components: {
    Navbar,
  },
  created() {
    this.getTechnologyContentList();
    this.getMyPriceList();
  },
  methods: {
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-技术交流`,
        desc: `有任何问题和需要帮助的地方都可以在这里发布得到解决！`,
        link: base.shareURL + "/messageInfo?id=" + this.messageid,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    toBack() {
      this.$router.go(-1);
    },
    onChange(event) {
      console.log(event);
    },

    // 获取技术交流帖子列表
    getTechnologyContentList() {
      var _this = this;
      var token = _this.$cookies.get("Authorization");
      _this.userInfo = this.$cookies.get("userInfo");
      if (!token) {
        loginAlert()
        return;
      } else {
        var data = {
          category_id: _this.categoryid,
          uid: _this.userInfo.id,
        };
        indexOp("/interflow/get_lists", data).then((res) => {
          console.log(res.data.data);
          _this.techMessageList = res.data.data;
        });
      }
    },

    toDetail(id) {
      var _this = this;
      _this.$router.push({
        path: "/messageInfo",
        query: {
          id: id,
        },
      });
    },

    // 获取打赏帖子列表
    getMyPriceList() {
      var _this = this;
      indexOp("/interflow_admire/get").then((res) => {
        _this.priceMessageList = res.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mineWrap {
  .mineMessageList {
    .mineMessageItem {
      width: 96%;
      padding: 20px 20px 0 20px;
      background-color: #fff;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      margin: 10px auto;
      .mineMessageItemTop {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 10px;
        }
        h3 {
          font-size: 16px;
        }
      }
      .mineMessageItemCenter {
        padding: 15px 0;
        .mineMessageItemCenterText {
          padding-bottom: 10px;
          p {
            font-size: 14px;
            color: #666;
          }
        }
        .mineMessageItemCenterPic {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}
</style>