<template>
  <div>
    <van-tabbar
      v-model="active"
      placeholder
      route
      :active-color="'#2F78DC'"
      z-index="999"
    >
      <van-tabbar-item :to="{ path: '/' }">
        <template #icon="props">
          <div :class="props.active ? 'item active' : 'item'">
            <i class="iconfont iconshouye" style="font-size:18px !important"></i>
            <span>首页</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/Shifu' }">
        <template #icon="props">
          <div :class="props.active ? 'item active' : 'item'">
            <i class="iconfont iconweixiu"></i>
            <span>维修师傅</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/Repair' }">
        <template #icon="props">
          <div :class="props.active ? 'item active' : 'item'">
            <i class="iconfont iconrenwu" style="font-size:22px !important"></i>
            <span>维修大厅</span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="{ path: '/user' }" :dot="false">
        <template #icon="props">
          <div :class="props.active ? 'item active' : 'item'">
            <i class="iconfont icongerenzhongxin"></i>
            <span>个人中心</span>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../api";
export default {
  props: {},
  data() {
    return {
      active: 1,
      identity: "",
      path: "",
      badge: 0,
      userInfo: {
        user_vip_info: {},
      },
    };
  },
  computed: {},
  created() {
    var _this = this;
    this.path = this.$route.path;
    this.identity = this.$cookies.get("identity");
    var userInfo = this.$cookies.get("userInfo");
    _this.userInfo = userInfo;
    // console.log(userInfo);
  },
  mounted() {
    this.socketApi.messageCock(this.messageCallback);

    // if(!userInfo){
    //         var userInfo = this.$cookies.get("userInfo");
    //         if(!userInfo){
    //             var api = "/user/show/1"
    //             indexOp(api).then(res=>{
    //               _this.identity = res.data.identity
    //                 _this.$cookies.set("identity",res.data.identity)
    //                 if(res && res.status == 200){
    //                     _this.userInfo = res.data
    //                     _this.$cookies.set("userInfo",res.data);
    //                 }
    //             })
    //         }else{
    //             _this.userInfo = userInfo
    //         }
    // }
  },
  watch: {},
  methods: {
    messageCallback(data) {
      this.badge = this.badge + 1;
      // console.log(data);
    },
  },
  components: {},
};
</script>

<style lang="less">
.van-tabbar{height:64px !important;
  .iconfont{font-size: 20px !important;}
}
</style>
<style scoped lang="less">
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    background: red;
    border-radius: 8px;
    padding: 2px;
    color: #fff;
    padding: 2px 4px;
  }
  i {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #2c2c2c;
  }
  span {
    font-size: 14px;
    color: #2c2c2c;
  }
}
.item.active {
  i {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #2f78dc;
  }
  span {
    font-size: 14px;
    color: #2f78dc;
  }
}
#VIP.item.active {
  i {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #dfac7a;
  }
  span {
    font-size: 12px;
    color: #dfac7a;
  }
}
</style>