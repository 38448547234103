import service from '@/utils/request'
import base from './base'
import VueCookies from 'vue-cookies'
var url = base.apiURL;
//修改
export function updateOp(ApiUrl, id, parameter) {
	var aurl;
	if (id == "") {
		aurl = url + ApiUrl
	} else {
		aurl = url + ApiUrl + '/' + id
	}
	return service({
		url: aurl,
		method: 'PUT',
		data: _param(parameter)
	})
}

//删除
export function deleteOp(ApiUrl, id) {
	return service({
		url: url + ApiUrl + '/' + id,
		method: 'DELETE'
	})
}

//添加
export function addOp(ApiUrl, parameter) {
	return service({
		url: url + ApiUrl,
		method: 'POST',
		data: _param(parameter)
	})
}

//获取列表
export function indexOp(ApiUrl, parameter = {}) {
	if(ApiUrl.substr(0,1) == '/'){
		ApiUrl 	=	url + ApiUrl;
	}
	return service({
		url: ApiUrl,
		method: 'get',
		params: _param(parameter)
	})
}

//获取详情
export function showOp(ApiUrl, id) {
	return service({
		url: url + ApiUrl + '/' + id,
		method: 'get'
	})
}

//图片上传
export function uploadImg(parameter) {
	return service({
		url: url + '/uploads/img',
		method: 'POST',
		data: parameter
	})
}

//文件上传
export function uploadFile(parameter) {
	return service({
		url: url + '/uploads/upload_file',
		method: 'POST',
		data: parameter
	})
}

//获取地址
export function locationOp(address) {
	return service({
		url: "https://apis.map.qq.com/ws/geocoder/v1/?address=北京市海淀区彩和坊路海淀西大街74号&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77",
		method: 'get'
	})
}

function _param(param) {
	if (!param) {
		param = {}
	}
	const invate_uid = VueCookies.get("invate_uid");
	if (invate_uid) {
		param.invate_uid = invate_uid;
	}
	return param
}
