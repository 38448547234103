<template>
  <div class="changshang-index news-list peijian">
    <div class="nav-box">
      <div class="nav-box">
        <router-link
          :to="{ path: '/peijianIndex', query: { uid: uid } }"
          class="item"
          >网站首页</router-link
        >
        <!-- <router-link :to="{path:'/changshangIndex', query:{ uid:uid }}" class="item">公司简介</router-link> -->
        <router-link
          :to="{ path: '/peijian_productList', query: { uid: uid } }"
          class="item"
          >产品展示</router-link
        >
        <router-link
          :to="{ path: '/peijian_newsList', query: { uid: uid } }"
          class="item"
          >资讯中心</router-link
        >
        <router-link
          :to="{ path: '/peijian_videoList', query: { uid: uid } }"
          class="item"
          >视频中心</router-link
        >
        <router-link
          :to="{ path: '/peijian_contactUs', query: { uid: uid } }"
          class="item"
          >联系我们</router-link
        >
      </div>
    </div>

    <div class="swiper-box">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in slideList" :key="index">
          <img class="banner-img" :src="url + item.cover" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="title-box">
      <span class="text">资讯列表</span>
      <div class="right" v-if="userInfo && userInfo.id == uid">
        <router-link class="more" :to="{ path: '/peijian_newAdd' }"
          >添加资讯</router-link
        >
      </div>
    </div>
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      finished-text=""
      @load="onLoad"
    >
      <div
        class="news-item"
        v-for="(item, index) in list"
        :key="index"
        @click="toNewsDetail(item.id)"
      >
        <img :src="url + item.thumb" alt="" />
        <div class="right">
          <p class="title">{{ item.name }}</p>
          <p class="time">点赞：{{ item.zan_count }} 浏览量：{{ item.hits }}</p>
          <p class="time">发布时间：{{ item.created_at }}</p>
        </div>
      </div>
      <div class="empty-box" v-if="list.length == 0">暂无数据 ~</div>
    </van-list>
    <div class="btns">
      <a
        v-if="userInfo && userInfo.user_vip_info && info.phone"
        :href="`tel:${info.phone}`"
        class="btn"
      >
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <a v-else :href="`tel:4006912808`" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <div class="btn" @click="toChat">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import {loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      loginURL: base.loginURL,
      slideList: "", //轮播列表
      userInfo: {
        id: "",
      },
      uid: "",
      info: "",
      treeSelectShow: false,
      items: [
        {
          // 导航名称
          text: "制冷产品",
          // 该导航下所有的可选项
          children: [
            {
              // 名称
              text: "冰箱",
              id: 1,
            },
            {
              text: "冰柜",
              id: 2,
            },
          ],
        },
      ],
      activeId: 1,
      activeIndex: 0,
      value: "",
      cid: "",
      queryParam: {},
      columns: "",
      list: [],
      loading: false,
      finished: false,
      current_page: 0, //当前页
      last_page: "", //最后一页
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.uid = this.$route.query.uid;
    this.queryParam.service_id = this.$route.query.uid;
    this.getSlide();
    this.getShopDetail();
    // this.getList()
    // this.setShare();
  },
  mounted() {},
  watch: {},
  methods: {
    //去资讯详情
    toNewsDetail(id) {
      var _this = this;
      this.$router.push({
        path: "/peijian_newDetail",
        query: {
          id: id,
        },
      });
    },
    //获取资讯列表
    getList() {
      var _this = this;
      indexOp("/news/index", _this.queryParam).then((res) => {
        if (res && res.status == 200) {
          _this.list = res.data.data;
          _this.current_page = res.data.current_page;
          _this.last_page = res.data.last_page;
          if (res.data.data.length <= res.data.per_page) {
            _this.finished = true;
          }
        }
        console.log(res);
      });
    },
    //获取轮播
    getSlide() {
      var _this = this;
      indexOp("/slide/index?cid=1").then((res) => {
        if (res && res.status == 200) {
          _this.slideList = res.data;
        }
      });
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: this.info.desc,
        link:
          base.shareURL +
          "/peijian_newsList?uid=" +
          this.uid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var data = {
        uid: _this.uid,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.setShare();
        }
        console.log(res);
      });
    },
    //去对话
    toChat() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      var to_uid = _this.info.uid; //聊天对象 uid
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "normal",
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        console.log(res);
        //跳转
        _this.$router.push({
          path: "/chat",
          query: {
            chat_id: res.data.chat_id,
            to_uid: to_uid,
          },
        });
      });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //分类选择
    onChangeSelect(data) {
      console.log(data);
    },

    onLoad() {
      var _this = this;
      // if (_this.current_page == _this.last_page) {
      //   // _this.$toast("没有更多数据了");
      //   _this.finished = true
      //   _this.loading = false;
      //   return;
      // }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var page = _this.current_page + 1;
      _this.queryParam.page = page;
      indexOp("/news/index", _this.queryParam).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.last_page = res.data.last_page;
          _this.current_page = res.data.current_page;
          if (res.data.data.length <= 0) {
            // _this.$toast("没有更多数据了");
            _this.finished = true;
            _this.loading = false;
            return;
          }
          if (res.data.data.length < res.data.per_page) {
            _this.finished = true;
          }
          for (var i = 0; i < res.data.data.length; i++) {
            _this.list.push(res.data.data[i]);
            _this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped >
</style>
