<template>
	<div class="container shifu">
		<van-nav-bar class="navber">
			<template #left>
				<van-icon @click="goBack" name="arrow-left" color="#fff" size="15px" />
			</template>
			<template #title>
				<span class="title">维修师傅</span>
			</template>
			<template #right>
				<div class="right" @click="toNearbyShifu">
					<van-icon name="location" size="15px" color="#fff" />
					<span style="font-size: 12px; color: #fff; margin-left: 5px">附近师傅</span>
				</div>
			</template>
		</van-nav-bar>
		<div @click="toEditShiinfo" class="add-box">
			<i class="iconfont iconjiahao"></i>
			<span class="text">入驻平台</span>
		</div>
		<!-- <van-search
      class="search-box"
      v-model="searchValue"
      placeholder="地区检索"
      shape="round"
      background="transparent"
      @search="onSearch"
      readonly
      @click="showCityPicker = true"
    />
    <van-popup v-model="showCityPicker" round position="bottom">
      <van-area
        title="选择地址"
        :area-list="areaList"
        @cancel="showCityPicker = false"
        @confirm="onCityConfirm"
      />
    </van-popup> -->

		<!-- 地区检索Html -->
		<div class="city-box" @click="showCityPicker = true">
			<!-- <van-icon class="icon" name="search" size="16" />
			<span @click="showPicker_1 = true">{{ pickerValue_1 }}</span>
			<span v-if="columns_2.length > 0">-</span>
			<span v-if="columns_2.length > 0" @click="showPicker_2 = true">{{
        pickerValue_2
      }}</span>
			<span v-if="columns_3.length > 0">-</span>
			<span v-if="columns_3.length > 0" @click="showPicker_3 = true">{{
        pickerValue_3
      }}</span> -->
			<!-- Picker To vanPopup -->
			<van-icon class="icon" name="search" size="16" />{{ areaText }}
		</div>
		<!-- 地区检索区域 -->
		<template v-if="areaList && areaList.length">
			<van-popup v-model="showCityPicker" round position="bottom">
				<van-cascader v-model="cascaderValue" title="请选择所在地区" :options="areaList"
					@close="showCityPicker = false" @finish="onAreaFinish"
					:field-names="{ text: 'label', value: 'value', children: 'child' }" />
			</van-popup>
		</template>
		<!-- 未知区域 -->
		<van-popup v-model="showPicker_1" round position="bottom">
			<van-picker show-toolbar :columns="columns_1" @cancel="showPicker_1 = false" @confirm="onPickerConfirm_1"
				value-key="label" />
		</van-popup>
		<van-popup v-model="showPicker_2" round position="bottom">
			<van-picker show-toolbar :columns="columns_2" @cancel="showPicker_2 = false" @confirm="onPickerConfirm_2"
				value-key="label" />
		</van-popup>
		<van-popup v-model="showPicker_3" round position="bottom">
			<van-picker show-toolbar :columns="columns_3" @cancel="showPicker_3 = false" @confirm="onPickerConfirm_3"
				value-key="label" />
		</van-popup>
		<template v-if="userInfo.identity == 2 && shopDetail != ''">
			<div class="shifu-notice" v-if="
          !shopDetail.name ||
          !shopDetail.person ||
          !shopDetail.phone ||
          !shopDetail.address ||
          !shopDetail.desc ||
          !shopDetail.scope_desc ||
          !shopDetail.service_desc
        ">
				<span class="text">
					<van-icon class="icon" name="warning-o" />您的入驻资料不完整，请尽快完善。
				</span>
				<router-link to="/editShifuinfo" class="btn">去完善</router-link>
			</div>
		</template>
		<div class="select-box">
			<div class="status-box" @click="showStatusPicker_1 = true">
				<span class="status">{{ statusText_1 }}</span>
				<span class="icon"><i class="iconfont iconyou"></i></span>
			</div>
			<van-popup v-model="showStatusPicker_1" round position="bottom">
				<van-picker show-toolbar value-key="title" :columns="columns" @cancel="showStatusPicker_1 = false"
					@confirm="onConfirm_1" />
			</van-popup>
			<div class="status-box" @click="showStatusPicker_2 = true">
				<span class="status">{{ statusText_2 }}</span>
				<span class="icon"><i class="iconfont iconyou"></i></span>
			</div>
			<van-popup v-model="showStatusPicker_2" round position="bottom">
				<van-picker show-toolbar value-key="title" :columns="columns2" @cancel="showStatusPicker_2 = false"
					@confirm="onConfirm_2" />
			</van-popup>
			<div class="status-box" @click="showStatusPicker_3 = true">
				<span class="status">{{ statusText_3 }}</span>
				<span class="icon"><i class="iconfont iconpaixu"></i></span>
			</div>
			<van-popup v-model="showStatusPicker_3" round position="bottom">
				<van-picker show-toolbar value-key="title" :columns="columns3" @cancel="showStatusPicker_3 = false"
					@confirm="onConfirm_3" />
			</van-popup>
			<span style="font-size: 12px; color: #666" @click="onChongzhi">重置</span>
		</div>

		<div class="list">
			<div class="item" v-for="(item, index) in shifuList" :key="index" @click="toShifuDetail(item.uid)"
				v-if="item.name && item.service_info.user_info">
				<div class="vip-box" v-if="item.service_info.vip_level">
					<template v-if="item.service_info.vip_level.vip_level == 1">
						<i class="iconfont iconhuiyuandengji" style="color: #5f3e3e"></i>
						<span>普通会员</span>
					</template>
					<template v-else-if="item.service_info.vip_level.vip_level == 2">
						<i class="iconfont iconhuiyuandengji"></i>
						<span>黄金会员</span>
					</template>
					<template v-else-if="item.service_info.vip_level.vip_level == 3">
						<i class="iconfont iconhuiyuandengji" style="color: #bd419d"></i>
						<span>钻石会员</span>
					</template>
				</div>
				<div class="vip-box" v-else>
					<template>
						<i class="iconfont iconhuiyuandengji" style="color: #d8d8d8"></i>
						<span>体验会员</span>
					</template>
				</div>
				<!-- <a
          v-if="userInfo && userInfo.user_vip_info"
          :href="`tel:${item.phone}`"
          @click.stop
          class="coll"
        >
          <i class="iconfont icondianhua1"></i>
        </a>
        <a v-else :href="`tel:4006912808`" class="coll" @click.stop>
          <i class="iconfont icondianhua1"></i>
        </a>
        <div @click.stop="toChat(item)" class="coll" style="right: 70px">
          <i class="iconfont iconxiaoxi"></i>
        </div> -->
				<!-- <div class="status">预付上门费用</div> -->
				<div class="flex-box">
					<div class="left-box">
						<img class="avatar" v-if="item.thumb" :src="url + item.thumb" alt="" />
						<img v-else class="avatar" src="http://api.zglxgw.com/static/asset/index/images/lunbo.jpg"
							alt="" />
						<!-- <span class="name">{{item.name}}</span> -->
						<van-tag v-if="item.believe > 90" class="tag" color="#ff5722" text-color="#fff">金牌师傅</van-tag>
						<van-tag v-else-if="item.believe > 80 && item.believe < 90" class="tag" color="#a7a7a7"
							text-color="#fff">银牌师傅</van-tag>
						<van-tag v-else-if="item.believe < 80" class="tag" color="#7A443A" text-color="#fff">铜牌师傅
						</van-tag>
					</div>
					<div class="right-box">
						<p class="title">{{ item.name }}</p>
						<p class="mobile">
							<i class="iconfont iconshouji"></i>
							{{ item.phone | isVip(userInfo) }}【{{ item.order_count }}人使用过】
						</p>
						<!-- <p class="mobile" v-if="userInfo && userInfo.user_vip_info">
              <i class="iconfont iconshouji"></i>
              {{ item.phone }}【{{ item.order_count }}人使用过】
            </p> -->
						<!-- <p class="mobile" v-else>
              <i class="iconfont iconshouji"></i>
              <span v-if="item.phone"
                >{{ item.phone.toString().replace(reg, "$1****$2") }}【{{
                  item.order_count
                }}人使用过】</span
              >
              <span v-else>暂无</span>
            </p> -->
						<p class="describe">
							维修范围：{{
                item.scope_desc ? formatNull(item.scope_desc) : "暂无"
              }}
						</p>
						<p class="describe">
							服务区域：{{
                item.service_desc ? formatNull(item.service_desc) : "暂无"
              }}
						</p>
						<van-rate v-if="item.service_info" :value="item.service_info.comment_star" :size="15"
							color="#ffd21e" void-icon="star" void-color="#eee" />
					</div>
				</div>
				<p class="position" v-if="item.address">
					<i class="iconfont iconweizhi"></i>
					{{ item.address }}
				</p>
			</div>
			<a href="javascript:;" class="more-btn" @click="onMore"
				v-if="page != last_page && shifuList.length > 0">查看更多
			</a>
			<div class="empty" v-if="shifuList.length <= 0">
				<van-empty description="暂无数据" />
			</div>
		</div>
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import province_list from "../utils/area";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import address from "../utils/address";
	import {
		getUser,
		login
	} from "../utils/login_toast";
	import {
		loginAlert
	} from "../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				searchValue: "", //搜索内容
				screenValueOne: 0,
				screenValueTwo: 0,
				screenValueThree: 0,
				url: base.apiURL,
				loginURL: base.loginURL,
				showStatusPicker_1: false,
				statusText_1: "师傅级别",
				showStatusPicker_2: false,
				statusText_2: "会员等级",
				showStatusPicker_3: false,
				statusText_3: "信用值",
				columns: [{
						title: "金牌师傅",
						value: "90",
					},
					{
						title: "银牌师傅",
						value: "80",
					},
					{
						title: "铜牌师傅",
						value: "60",
					},
				],
				columns2: [{
						title: "普通会员",
						value: "1",
					},
					{
						title: "黄金会员",
						value: "2",
					},
				],
				columns3: [{
						title: "升序",
						value: "desc",
					},
					{
						title: "降序",
						value: "asc",
					},
				],
				shifuList: "", //师傅列表
				page: 1, //当前页数
				last_page: "", //最后一页
				showCityPicker: false, //地址选择器
				areaList: null, //地址选择器列表
				areaText: "点击地区筛选",
				province: "", //省
				city: "", //市
				area: "", //县
				queryParam: {
					limit: 10,
					province: "",
					city: "",
					area: "",
				},
				userInfo: {
					id: "",
				},
				reg: /(\d{3})\d{4}(\d{4})/,

				showPicker_1: false,
				columns_1: address,
				pickerValue_1: "地区搜索",
				showPicker_2: false,
				columns_2: [],
				pickerValue_2: "选择市",
				showPicker_3: false,
				columns_3: [],
				pickerValue_3: "选择区/县",

				shopDetail: "",
				cascaderValue: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getLoginUser);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
				if (this.userInfo.identity == 2) {
					this.getShifuInfo();
				}
			}
			var province = this.$route.query.province;
			var city = this.$route.query.city;
			var area = this.$route.query.area;
			this.queryParam.province = province;
			this.queryParam.city = city;
			this.queryParam.area = area;
			this.getDefaultSearch();
			// if (this.userInfo && this.userInfo.province) {
			//   this.queryParam.province = this.userInfo.province;
			// }
			this.getShifu();
			this.setShare();
			// 获取地区
			this.getServiceRegion();
			console.log(this.userInfo)
		},
		mounted() {},
		watch: {},
		methods: {
			// 获取地区列表
			getServiceRegion() {
				var _this = this;
				indexOp("/area/all_format_child").then((res) => {
					_this.areaList = res.data;
				});
			},
			/**
			 * 地区提交
			 */
			onAreaFinish({
				selectedOptions
			}) {
				this.showCityPicker = false;
				this.areaText = selectedOptions.map((option) => option.label).join("-");

				// 2021.1.16 增加地区存储
				this.areaValue = selectedOptions.map((option) => option.value);
				this.queryParam.province = this.areaValue[0];
				this.queryParam.city = this.areaValue[1];
				this.queryParam.area = this.areaValue[2];

				this.getShifu();
			},
			//获取默认地址搜索
			getDefaultSearch() {
				var _this = this;
				var province = _this.$route.query.province;
				var city = _this.$route.query.city;
				var area = _this.$route.query.area;
				if (province) {
					this.pickerValue_1 = province;
					this.columns_1.map((item) => {
						if (item.label == province) {
							this.columns_2 = item.child;

							if (city) {
								this.columns_2.map((i) => {
									if (i.label == city) {
										this.columns_3 = i.child;
									}
								});
							}
						}
					});
				}
				if (city) {
					this.pickerValue_2 = city;
				}
				if (area) {
					this.pickerValue_3 = area;
				}
			},
			//获取师傅信息
			getShifuInfo() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status == 200) {
						_this.shopDetail = res.data;
					}
					console.log(res);
				});
			},
			toEditShiinfo() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert();
					return;
				}

				_this.$router.push({
					path: "/editShifuinfo",
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-维修师傅`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/shifu?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			//获取师傅列表
			getShifu() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				indexOp("/service_user/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.shifuList = res.data.data;
					}
					console.log(res);
				});
			},
			//模糊搜索
			onSearch(val) {
				console.log(val);
			},
			//查看更多
			onMore() {
				var _this = this;
				if (_this.page == _this.last_page) {
					_this.$toast("没有更多数据了");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.page + 1;
				_this.queryParam.page = page;
				indexOp("/service_user/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
						if (res.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							return;
						}
						for (var i = 0; i < res.data.data.length; i++) {
							_this.shifuList.push(res.data.data[i]);
						}
					}
				});
			},
			//选择地址
			onCityConfirm(value) {
				this.queryParam.province = value[0].name;
				this.queryParam.city = value[1].name;
				this.queryParam.area = value[2].name;
				this.searchValue =
					value[0].name + "-" + value[1].name + "-" + value[2].name;
				this.getShifu();
				this.showCityPicker = false;
			},
			//去店铺详情
			toShifuDetail(uid) {
				if (uid == this.userInfo.id) {
					this.$router.push({
						path: "/myShop",
						query: {
							uid: uid,
						},
					});
				} else {
					this.$router.push({
						path: "/shifuShopDetail",
						query: {
							uid: uid,
						},
					});
				}
			},
			onConfirm_1(value) {
				this.showStatusPicker_1 = false;
				this.statusText_1 = value.title;
				this.queryParam.believe = value.value;
				this.getShifu();
			},
			onConfirm_2(value) {
				this.showStatusPicker_2 = false;
				this.statusText_2 = value.title;
				this.queryParam.vip_level = value.value;
				this.getShifu();
			},
			onConfirm_3(value) {
				this.showStatusPicker_3 = false;
				this.statusText_3 = value.title;
				this.queryParam.orderSort = value.value;
				this.getShifu();
			},
			onChongzhi() {
				this.statusText_1 = "师傅等级";
				this.statusText_2 = "会员等级";
				this.statusText_3 = "信用值";
				this.queryParam.believe = "";
				this.queryParam.vip_level = "";
				this.queryParam.orderSort = "";
				this.queryParam.province = "";
				this.queryParam.city = "";
				this.queryParam.area = "";
				this.pickerValue_1 = "地区搜索";
				this.pickerValue_2 = "选择市";
				this.pickerValue_3 = "选择区/县";
				this.columns_2 = [];
				this.columns_3 = [];
				// this.getShifu();

				this.$router.push({
					path: "/shifu",
				});
			},
			goBack() {
				this.$router.go(-1);
			},
			toNearbyShifu() {
				var _this = this;
				if (_this.userInfo) {
					this.$router.push("/shifuNearby");
				} else {
					loginAlert("您还未登录，需登录后才能获取附近师傅！");
				}
			},
			onPickerConfirm_1(value) {
				console.log(value);
				this.pickerValue_1 = value.label;
				this.showPicker_1 = false;
				this.columns_2 = value.child;
				this.pickerValue_2 = "选择市";
				this.showPicker_3 = false;
				this.columns_3 = [];
				this.queryParam.province = value.label;
				this.queryParam.city = "";
				this.queryParam.area = "";
				// this.getShifu();
				this.$router.push({
					path: "/shifu",
					query: {
						province: value.label,
						city: "",
						area: "",
					},
				});
			},
			onPickerConfirm_2(value) {
				console.log(value);
				this.pickerValue_2 = value.label;
				this.showPicker_2 = false;
				this.columns_3 = value.child;
				this.pickerValue_3 = "选择区/县";
				this.queryParam.city = value.label;
				this.queryParam.area = "";
				// this.getShifu();

				this.$router.push({
					path: "/shifu",
					query: {
						province: this.pickerValue_1,
						city: value.label,
						area: "",
					},
				});
			},
			onPickerConfirm_3(value) {
				console.log(value);
				this.pickerValue_3 = value.label;
				this.showPicker_3 = false;
				this.queryParam.area = value.label;
				this.$router.push({
					path: "/shifu",
					query: {
						province: this.pickerValue_1,
						city: this.pickerValue_2,
						area: value.label,
					},
				});
			},
			//去对话
			toChat(data) {
				var _this = this;
				console.log(data);
				var to_uid = data.uid; //聊天对象 uid
				// console.log(_this.$cookies.get("userInfo"))
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert();
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: to_uid,
						from_uid: form_uid,
						chat_type: "normal",
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					_this.$toast.clear();
					console.log(res);
					if (res && res.status == 200) {
						//跳转
						_this.$router.push({
							path: "/chat",
							query: {
								chat_id: res.data.chat_id,
								to_uid: to_uid,
							},
						});
					} else {
						_this.$toast.fail("对话创建失败！");
					}
				});
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			getLoginUser() {},
			/**
			 * 去除Null字符串显示，仅限于可以格式化 xx,xx,的格式
			 */
			formatNull(str) {
				str = str.split(",");
				for (var i in str) {
					if (str[i] == "null") {
						str.splice(i, 1);
					}
				}
				return str.join(",");
			},
		},
		filters: {
			isVip(val, userInfo) {
				const _this = this;
				let reg = /(\d{3})\d{4}(\d{4})/
				let newText = val.toString().replace(reg, "$1****$2")
				//判断是不是会员 不是则隐藏
				// if(userInfo.identity == 1 ){
				//   return val
				// }
				if (userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 2) {
					let end_date = new Date(userInfo.user_vip_info.end_time)
					let end_time = end_date.getTime()
					let date = new Date()
					let now_time = date.getTime()
					if (end_time > now_time) {
						return val
					} else {
						return newText
					}
				} else {
					return newText
				}


			},
		}
	};
</script>

<style scoped>
</style>