<template>
  <div class="product">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">资讯详情</span>
      <van-icon
        name="delete"
        @click="onDelete"
        v-if="info.uid == userInfo.id"
      />
    </div>
    <div class="content">
      <div class="banner-box">
        <img class="banner-img" :src="url + info.thumb" alt="" />
      </div>
      <div class="title-box">
        {{ info.name }}
        <p
          style="
            font-size: 12px;
            color: #666;
            margin-top: 5px;
            font-weight: normal;
          "
        >
          发布时间：{{ info.created_at }}
        </p>
        <p
          style="
            font-size: 12px;
            color: #666;
            margin-top: 5px;
            font-weight: normal;
          "
        >
          点赞：{{ info.zan_count }} 浏览量：{{ info.hits }}
        </p>
      </div>
      <div class="detail-box">
        <div v-html="info.content"></div>
        <div style="margin-top: 10px; font-size: 12px">
          法律顾问：河南亚太人律师事务所 联系电话：15515999255
          QQ、微信：82860623
        </div>
      </div>

      <div class="banner-box" style="margin-top: 10px">
        <div class="swiper-box">
          <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in slideList" :key="index">
              <a style="display: block; width: 100%" :href="item.link">
                <img
                  style="width: 100%; height: 173px"
                  :src="url + item.cover"
                  alt=""
                />
              </a>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="pinglun-box" v-if="pinglunList.length > 0">
        <div class="title">评论</div>
        <div class="list">
          <div class="item" v-for="(item, index) in pinglunList" :key="index">
            <div class="cent" v-if="item.get_user_info">
              <img class="avatar" :src="item.get_user_info.avatar" alt="" />
              <div class="name">
                <span>{{ item.get_user_info.nickname }}</span>
              </div>
              <van-icon
                name="delete-o"
                class="delete-item"
                @click="onDeletePinglun(item.id)"
                v-if="item.uid == userInfo.id"
              />
            </div>
            <div class="cont-text">
              {{ item.content }}
            </div>
            <div class="cont-time">
              <span>{{ item.created_at }}</span>
              <span class="btn" @click="onShowPinglun(item.uid, item.id)"
                >回复</span
              >
            </div>
            <div
              class="info"
              v-if="item.get_comment_list && item.get_comment_list.length > 0"
            >
              <div v-for="(d, k) in item.get_comment_list" :key="k">
                <div class="text" v-if="d.to_uid == d.uid">
                  <span>{{ d.get_user_info.nickname }}</span
                  >：
                  {{ d.content }}
                </div>
                <div class="text" v-else>
                  <span>{{ d.get_user_info.nickname }}</span> 回复
                  <span>{{ d.get_to_user_info.nickname }}</span
                  >：
                  {{ d.content }}
                </div>
                <div class="time">
                  <span>{{ d.created_at }}</span>
                  <div>
                    <span class="btn" @click="onShowPinglun(d.uid, item.id)"
                      >回复</span
                    >
                    <span
                      class="btn"
                      @click="onDeletePinglun(d.id)"
                      v-if="d.uid == userInfo.id"
                      style="margin-left:10px;"
                      >删除</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <div class="dianzan" @click="onZan">
        <van-icon v-if="zanInfo" name="good-job" size="22" color="#2F78DC" />
        <van-icon v-else name="good-job-o" size="22" />
      </div>
      <div class="dianzan" @click="onShowPinglun(info.uid)">
        <van-icon name="smile-comment-o" size="22" />
      </div>
    </div>

    <!-- 评价 -->
    <van-overlay :show="pingjiaShow" @click="pingjiaShow = false">
      <div class="pingjia-mask" @click.stop>
        <!-- <div class="title">评价</div> -->
        <span class="tips">评论</span>
        <textarea
          v-model="pingjiaValue"
          class="pingjia-input"
          cols="30"
          rows="4"
          placeholder="请输入评论"
        ></textarea>
        <button class="pay-btn" @click="onSubPingjia">提交</button>
      </div>
    </van-overlay>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  inject: ["reload"],
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      loginUrl: base.loginURL,
      userInfo: {
        id: "",
      },
      info: "",
      nid: "",
      zanInfo: "",
      pingjiaShow: false, //评价弹出
      pingjiaValue: "", //评价
      pinglunList: "",
      tid: "",
      comment_id: "",
      slideList: [],
    };
  },
  computed: {},
  created() {
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    var invate_uid = this.$route.query.invate_uid;
    if(invate_uid){
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.nid = this.$route.query.id;
    this.getNewInfo();
    this.getPinglunList();
    this.getSlide();
  },
  mounted() {},
  watch: {},
  methods: {
    //删除评论
    onDeletePinglun(id) {
      var _this = this;
      var api = "/news_comment/destroy/" + id;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除这条评论？",
          confirmButtonText: "确认",
        })
        .then(() => {
          indexOp(api).then((res) => {
            console.log(res);
            _this.$toast.success("删除成功！");
            setTimeout(() => {
              _this.reload();
            }, 500);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取轮播
    getSlide() {
      var _this = this;
      indexOp("/slide/index?cid=4").then((res) => {
        if (res && res.status == 200) {
          _this.slideList = res.data;
        }
      });
    },
    //评论列表
    getPinglunList() {
      var _this = this;
      indexOp("/news_comment/index", { news_id: _this.nid }).then((res) => {
        if (res && res.status == 200) {
          _this.pinglunList = res.data;
        }
      });
    },
    onShowPinglun(tid, comment_id) {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      _this.pingjiaShow = true;
      _this.tid = tid;
      _this.comment_id = comment_id;
    },
    // 评价
    onSubPingjia() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      if (_this.pingjiaValue == "") {
        _this.$toast.fail("评论内容不能为空！");
        return;
      }
      var data = {
        news_id: _this.nid,
        to_uid: _this.tid,
        content: _this.pingjiaValue,
      };
      if (_this.comment_id) {
        data.comment_id = _this.comment_id;
      } else {
        data.comment_id = 0;
      }
      addOp("/news_comment/create", data).then((res) => {
        if (res && res.status == 201) {
          _this.$toast.success("评论成功！");
          _this.pingjiaShow = false;
          setTimeout(() => {
            _this.reload();
          }, 500);
        }
        console.log(res);
      });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.getNewInfo();
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    isZan() {
      var _this = this;
      var data = {
        news_id: _this.info.id,
      };
      indexOp("/zan_log/show", data).then((res) => {
        if (res && res.status == 200) {
          _this.zanInfo = res.data;
        }
        console.log(res);
      });
    },
    //点赞
    onZan() {
      var _this = this;

      if (!_this.$cookies.isKey("userInfo")) {
        loginAlert()
        return;
      }
      if (_this.zanInfo) {
        _this.$toast("您已经点过赞了！");
        return;
      }
      var data = {
        news_id: _this.info.id,
      };
      addOp("/zan_log/create", data).then((res) => {
        console.log(res);
        if (res && res.status == 201) {
          _this.$toast.success("点赞成功！");
          setTimeout(() => {
            _this.reload();
          }, 800);
        }
      });
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: "聚制冷精英，集行业资源，方便您我他！",
        link:
          base.shareURL +
          "/newDetail?id=" +
          this.nid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取资讯详情
    getNewInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/news/show/" + _this.nid;
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.setShare();
          if (_this.userInfo.id) {
            _this.isZan();
          }
        }
      });
    },
    onDelete() {
      var _this = this;
      var api = "/news/destroy/" + _this.info.id;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除这条资讯？",
        })
        .then(() => {
          addOp(api).then((res) => {
            if (res && res.status == 200) {
              _this.$toast.success("操作成功！");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 800);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
