<template>
  <div class="chat" id="chatContainer" ref="chat">
    <!-- <Navbar v-if="orderInfo.name" :title="orderInfo.name"></Navbar>
    <Navbar v-else :title="`与${title}的对话`"></Navbar>
     -->
    <van-nav-bar class="navber" fixed placeholder>
      <template #left>
        <van-icon @click="goBack" name="arrow-left" color="#fff" size="15px" />
      </template>
      <template #title>
        <span class="title" v-if="orderInfo.name">{{ orderInfo.name }}</span>
        <span class="title" v-else>{{ `与${title}的对话` }}</span>
      </template>
      <template #right>
        <div class="right" @click="goHome">
          <span style="font-size: 12px; color: #fff; margin-left: 5px"
            >返回首页</span
          >
        </div>
      </template>
    </van-nav-bar>
    <!-- <div class="top-box">
      <div class="title-box">
        <span>订单信息</span>
        <span class="time">发布时间：2小时前</span>
      </div>
      <div class="content">
        <p class="tit">维修部件：海尔冰箱 - 001款</p>
        <p>维修描述：冰箱制冷异常，起雾出水漏电起雾出水漏电…</p>
      </div>
      <div class="open-box">
        <span class="open-btn">展开 <van-icon name="arrow-down" /></span>
      </div>
    </div> -->
    <div class="chat-content">
      <div class="notice-box">
        <i class="iconfont iconjinggao"></i>
        <span v-if="order_no"
          >友情提示：请遵守当地法规，禁止使用系统讨论敏感扰乱社会秩序的行为。为了保证您的权益，建议不要使用系统外的通讯方式进行交易，否则，我们无法为您保证您的权益不受到侵犯。</span
        >
        <span v-else
          >【温馨提示】为保障您和他人的权益，根据有关法律与平台规则，本次沟通交流信息将会被平台记录，感谢您的理解！</span
        >
      </div>
      <div class="list" :style="liststyle">
        <!-- <div class="time">2020年2月22日 22:22:22</div> -->
        <div
          v-for="(item, index) in chat_list"
          :key="index"
          :class="item.from_uid == userInfo.id ? 'item right' : 'item'"
        >
          <template v-if="item.from_uid == userInfo.id">
            <div class="right">
              <p class="name" v-if="userInfo.identity != 9">
                {{ userInfo.nickname }}
              </p>
              <p class="name" v-else>{{ userInfo.kefu_name }}</p>
              <p class="content" v-if="item.type == 'text'">
                {{ item.content }}
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </p>
              <p class="content" v-else-if="item.type == 'img'">
                <img
                  :src="item.content"
                  alt=""
                  @click="onPreviewImg(item.content)"
                />
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </p>
              <p class="content" v-else-if="item.type == 'video'">
                <video
                  :src="item.content"
                  controls="controls"
                  width="180"
                  height="150"
                ></video>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
                <!-- <button class="sync-btn" v-if="!item.status || item.status == 0"
									@click="onSyncVideo(item, index)">
									视频同步
								</button> -->
              </p>

              <div
                class="content"
                v-else-if="item.type == 'local'"
                @click="onLocationDetail(item.content)"
              >
                <div class="location-box">
                  <p class="title">{{ item.content.poiname }}</p>
                  <p class="subtitle">{{ item.content.poiaddress }}</p>
                  <img src="@/assets/image/location.png" alt="" />
                </div>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>

              <div class="content" v-else-if="item.type == 'order'">
                <router-link
                  :to="{
                    path: '/RepairDetail',
                    query: { order_no: item.content.order_no },
                  }"
                  class="order-box"
                >
                  <p class="title">维修产品名称：{{ item.content.name }}</p>
                  <p class="subtitle">维修描述：{{ item.content.desc }}</p>
                  <p class="subtitle">维修地址：{{ item.content.address }}</p>
                  <div
                    class="imgs"
                    v-if="item.content.images && item.content.images.length > 0"
                  >
                    <img
                      v-for="(src, index) in item.content.images"
                      :key="index"
                      :src="src"
                      alt=""
                    />
                  </div>
                </router-link>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>
              <div class="content" v-else-if="item.type == 'voice'">
                <div class="audio-box" @click="onPalyAudio(item.content.url)">
                  <div class="icon">
                    <span>{{ item.content.time }}"</span>
                    <i class="iconfont iconyinpin"></i>
                  </div>
                  <i class="iconfont iconbofang play" v-if="audioPlay"></i>
                  <i class="iconfont iconzantingtingzhi play" v-else></i>
                </div>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>
              <div class="content" v-else-if="item.type == 'transfer'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name.nickname }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>
              <div class="content" v-else-if="item.type == 'pay_door_money'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>
              <div class="content" v-else-if="item.type == 'pay_service_money'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
                <span class="delete-btn" @click="onDeleteChat(item, index)"
                  >删除</span
                >
              </div>

              <!-- 完善保修信息对话框 -->
              <div class="content" v-else-if="item.type == 'edit_guarantee'">
                <div class="zhuanzhang" style="background: #007aff">
                  <van-icon class="icon" name="records" />
                  <div class="cont">
                    <p class="tit" style="font-size: 14px">
                      邀请师傅完善保修信息
                    </p>
                  </div>
                </div>
              </div>
              <!-- 保修信息-->
              <div class="content" v-else-if="item.type == 'guarantee'">
                <div class="zhuanzhang" style="background: #007aff">
                  <van-icon class="icon" name="description" />
                  <div class="cont">
                    <p class="tit">保修信息</p>
                    <p class="tit">
                      是否保修：{{
                        item.content.is_guarantee == 0 ? "否" : "是"
                      }}
                    </p>
                    <p class="tit" v-if="item.content.is_guarantee == 1">
                      保修时长：{{ item.content.guarantee_time }}个月
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{
                        item.content.guarantee_remark
                          ? item.content.guarantee_remark
                          : "无"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <p class="time">{{ item.date }}</p>
            </div>
            <img
              class="avatar"
              :src="userInfo.avatar"
              alt=""
              v-if="userInfo.identity != 9"
              @click="toUser(item)"
            />
            <img class="avatar" v-else src="../assets/image/kefu.jpg" alt="" />
          </template>
          <template v-else>
            <div
              v-for="(s, i) in fromUserArr"
              v-if="s.id == item.from_uid"
              :key="'1' + i"
            >
              <img
                class="avatar"
                v-if="s.id == item.from_uid && s.identity == 9"
                src="../assets/image/kefu.jpg"
                alt=""
              />
              <img
                class="avatar"
                v-else-if="s.id == item.from_uid"
                :src="s.avatar"
                alt=""
                @click="toUser(item)"
              />
            </div>
            <!-- <img
              class="avatar"
              v-for="(s, i) in fromUserArr"
              :key="'1' + i"
              v-if="s.id == item.from_uid && s.identity != 9"
              :src="s.avatar"
              alt=""
              @click="toUser(item)"
            /> -->
            <div class="right">
              <p
                class="name"
                v-for="(s, i) in fromUserArr"
                :key="'2' + i"
                v-if="s.id == item.from_uid"
              >
                <span v-if="s.id == item.from_uid && s.identity == 9">
                  {{ s.kefu_name }}</span
                >
                <span v-else-if="s.id == item.from_uid"> {{ s.nickname }}</span>
              </p>
              <!-- <p
                class="name"
                v-for="(s, i) in fromUserArr"
                :key="'2' + i"
                v-if="s.id == item.from_uid"
              >
                {{ s.nickname }}
              </p> -->
              <p class="content" v-if="item.type == 'text'">
                {{ item.content }}
              </p>
              <p class="content" v-else-if="item.type == 'img'">
                <img
                  :src="item.content"
                  alt=""
                  @click="onPreviewImg(item.content)"
                />
              </p>
              <p class="content" v-else-if="item.type == 'video'">
                <video
                  :src="item.content"
                  controls="controls"
                  width="180"
                  height="150"
                ></video>
              </p>

              <div
                class="content"
                v-else-if="item.type == 'local'"
                @click="onLocationDetail(item.content)"
              >
                <div class="location-box">
                  <p class="title">{{ item.content.poiname }}</p>
                  <p class="subtitle">{{ item.content.poiaddress }}</p>
                  <img src="@/assets/image/location.png" alt="" />
                </div>
              </div>

              <div class="content" v-else-if="item.type == 'order'">
                <router-link
                  :to="{
                    path: '/RepairDetail',
                    query: { order_no: item.content.order_no },
                  }"
                  class="order-box"
                >
                  <p class="title">维修产品名称：{{ item.content.name }}</p>
                  <p class="subtitle">故障描述：{{ item.content.desc }}</p>
                  <p class="subtitle">维修地址：{{ item.content.address }}</p>
                  <div
                    class="imgs"
                    v-if="item.content.images && item.content.images.length > 0"
                  >
                    <img
                      v-for="(src, index) in item.content.images"
                      :key="index"
                      :src="src"
                      alt=""
                    />
                  </div>
                </router-link>
              </div>
              <div class="content" v-else-if="item.type == 'voice'">
                <div
                  class="audio-box"
                  @click="onPalyAudio(index, item.content.url)"
                >
                  <div class="icon">
                    <span>{{ item.content.time }}"</span>
                    <i class="iconfont iconyinpin"></i>
                  </div>
                  <i
                    class="iconfont iconbofang play"
                    :id="'bofang_' + index"
                  ></i>
                  <i
                    class="iconfont iconzantingtingzhi play"
                    :id="'zanting_' + index"
                    style="display: none"
                  ></i>
                </div>
              </div>

              <div class="content" v-else-if="item.type == 'transfer'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name.nickname }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="content" v-else-if="item.type == 'pay_door_money'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="content" v-else-if="item.type == 'pay_service_money'">
                <div class="zhuanzhang" @click="toBill">
                  <van-icon class="icon" name="cash-back-record" />
                  <div class="cont">
                    <p class="money">¥ {{ item.content.money }}</p>
                    <p
                      class="tit"
                      style="margin-bottom: 2px"
                      v-if="item.content && item.content.name"
                    >
                      转账给{{ item.content.name }}
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{ item.content.desc }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- 完善保修信息对话框 -->
              <div class="content" v-else-if="item.type == 'edit_guarantee'">
                <div
                  class="zhuanzhang"
                  @click="onEditGuarantee"
                  style="background: #007aff"
                >
                  <van-icon class="icon" name="records" />
                  <div class="cont">
                    <p class="tit" style="font-size: 14px">
                      邀请师傅完善保修信息
                    </p>
                  </div>
                </div>
              </div>
              <!-- 保修信息-->
              <div class="content" v-else-if="item.type == 'guarantee'">
                <div class="zhuanzhang" style="background: #007aff">
                  <van-icon class="icon" name="description" />
                  <div class="cont">
                    <p class="tit">保修信息</p>
                    <p class="tit">
                      是否保修：{{
                        item.content.is_guarantee == 0 ? "否" : "是"
                      }}
                    </p>
                    <p class="tit" v-if="item.content.is_guarantee == 1">
                      保修时长：{{ item.content.guarantee_time }}个月
                    </p>
                    <p
                      class="tit"
                      style="
                        -webkit-line-clamp: 2;
                        font-size: 10px;
                        opacity: 0.8;
                      "
                    >
                      备注：{{
                        item.content.guarantee_remark
                          ? item.content.guarantee_remark
                          : "无"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <template
                v-if="
                  (item.content.to_uid == userInfo.id ||
                    item.from_uid == userInfo.id) &&
                  (item.type == 'pay_door_money' ||
                    item.type == 'pay_service_money' ||
                    item.type == 'transfer')
                "
              >
                <p
                  class="time"
                  v-if="getDate(item.date)"
                  style="color: red; width: 220px; text-align: left"
                >
                  该转账在：{{
                    getNowDate(item.date)
                  }}已转入冻结余额，预计在：{{
                    getEndDate(item.date)
                  }}解冻到账户余额后可提现。
                </p>
              </template>
              <p class="time">{{ item.date }}</p>
            </div>
          </template>
        </div>
        <!-- <div class="item right">
          <div class="right">
            <p class="name">用户名</p>
            <div class="content">
              <div class="zhuanzhang">
                <van-icon class="icon" name="cash-back-record" />
                <div class="cont">
                  <p class="money">¥ 99999</p>
                  <p class="tit">转账给小徐转账给小徐转账给小徐转账给小徐</p>
                  <p class="tit" style="-webkit-line-clamp:2;font-size:10px;opacity:.8;">备注：转账给小徐转账给小徐转账给小徐转账给小徐</p>
                </div>
              </div>
              <span class="delete-btn" @click="onDeleteChat">删除</span>
            </div>
            <p class="time">2020-05-26</p>
          </div>
          <img class="avatar" src="@/assets/image/avatar.png" alt="" />
        </div> -->
      </div>
    </div>
    <!-- <div
      class="bottom-box"
      v-if="
        orderInfo.service_type != 'user_sure_service' &&
        orderInfo.service_type != 'appraise_order' &&
        orderInfo.service_type != 'pay_money' &&
        orderInfo.service_type != 'cancel_order'
      "
    > -->
    <div class="bottom-box" v-if="orderInfo.service_type != 'appraise_order'">
      <div class="tags-box" v-if="tagsBoxShow">
        我想：
        <span @click="onSendOrder" v-if="orderInfo">发送当前订单</span>
        <span @click="onShare">邀请别人进入</span>
        <!-- <span
          @click="onPayService"
          v-if="orderInfo && orderInfo.uid == userInfo.id"
          >订单付款</span
        > -->
        <span
          @click="onOrderServiceEnd"
          v-if="
            (orderInfo && orderInfo.service_id == userInfo.id) ||
            userInfo.identity == 9 ||
            orderInfo.real_uid == userInfo.id
          "
          >维修完成</span
        >
        <span
          @click="onOrderEnd"
          v-if="
            (orderInfo && orderInfo.uid == userInfo.id) ||
            userInfo.identity == 9 ||
            orderInfo.real_uid == userInfo.id
          "
          >确认维修完成</span
        >
        <span
          @click="onPingjiaShow"
          v-if="orderInfo && orderInfo.uid == userInfo.id"
          >订单评价</span
        >
        <span
          @click="onGuarantee"
          v-if="orderInfo && orderInfo.uid == userInfo.id"
          >邀请师傅填写保修信息</span
        >
        <!-- <span @click="onTest">添加</span> -->
      </div>
      <div class="input-box">
        <van-cell-group :style="style" id="chat-input">
          <van-field
            v-model="value"
            :autosize="{ minHeight: 24, maxHeight: 100 }"
            type="textarea"
            rows="1"
            @input="onChange"
            @focus="onFocus"
            placeholder="可在此输入聊天内容"
          />
        </van-cell-group>
        <div class="btns" id="more-btn">
          <van-button
            type="info"
            class="send-btn"
            v-if="showSend"
            @click="onSend"
            >发送</van-button
          >
          <van-icon
            class="more-btn"
            name="add"
            v-if="!showSend"
            @click="onMore"
          />
        </div>
      </div>
      <div class="more-box" id="more-box" v-show="showMore">
        <!-- <div class="item" >
					<van-icon name="photo" />
					<input type="file" accept="image/*" @change="onUploadImg" />
					<span class="text">图片</span>
				</div> -->
        <div class="item" @click="onUploadImg">
          <van-icon name="photo" />
          <span class="text">图片</span>
        </div>
        <div class="item">
          <van-icon name="video" />
          <input type="file" accept="video/*" @change="onUploadVideo" />
          <span class="text">视频</span>
        </div>
        <div class="item" @click="sendLocation">
          <van-icon name="map-marked" />
          <span class="text">位置</span>
        </div>
        <div class="item" @click="sendVoice">
          <i class="iconfont iconyuyin" style="font-size: 28px"></i>
          <span class="text">语音</span>
        </div>
        <div class="item" @click="transferShow = true">
          <i class="iconfont iconzhuanzhang" style="font-size: 28px"></i>
          <span class="text">转账</span>
        </div>
        <div
          class="item"
          @click="chatSetShow = true"
          v-if="
            (orderInfo && orderInfo.uid == userInfo.id) ||
            userInfo.identity == 9
          "
        >
          <span class="text">人员管理</span>
          <i class="iconfont iconzu3779" style="font-size: 28px"></i>
        </div>
        <div
          class="item"
          @click="onPayDoorMoney"
          v-if="
            (orderInfo.service_id && orderInfo.uid == userInfo.id) ||
            userInfo.identity == 9 ||
            orderInfo.real_uid == userInfo.id
          "
        >
          <van-icon name="gold-coin" />
          <span class="text">支付上门费</span>
        </div>
        <div
          class="item"
          @click="onPayService"
          v-if="
            (orderInfo.service_id && orderInfo.uid == userInfo.id) ||
            userInfo.identity == 9 ||
            orderInfo.real_uid == userInfo.id
          "
        >
          <van-icon name="gold-coin" />
          <span class="text">支付维修费</span>
        </div>
        <div
          class="item"
          @click="onLogoutChat"
          v-if="orderInfo.uid != userInfo.id"
        >
          <span class="text">退出聊天</span>
          <i class="iconfont icontuichu" style="font-size: 28px"></i>
        </div>
      </div>
      <van-popup
        v-model="voiceShow"
        :overlay="false"
        position="bottom"
        :closeable="true"
        @click-close-icon="onClosePopup"
      >
        <div class="voice-box">
          <!-- <button
            @touchstart="startVoice"
            @touchmove="moveVoice"
            @touchend="endVoice"
          >
            录制
          </button> -->
          <span class="time" v-if="voiceText != '点击录制'"
            >{{ outTime }}秒</span
          >
          <div class="recording" v-if="!playVoice" @click="startVoice">
            <i class="iconfont iconyuyin"></i>
            <span class="text">{{ voiceText }}</span>
          </div>
          <div class="recording" v-else @click="onPlayVoice">
            <i class="iconfont iconyuyin"></i>
            <span class="text">{{ voiceText }}</span>
          </div>
          <div class="btns">
            <button class="btn" @click="voiceCancel">重录</button>
            <button class="btn" @click="sendVoiceMsg">发送</button>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="bottom-box" v-else style="text-align: center; font-size: 14px">
      该订单已完成
    </div>
    <map-choice
      v-if="mapShow"
      @config-map="onConfigMap"
      @close-map="onCloseMap"
    ></map-choice>
    <van-image-preview v-model="previewShow" :images="previewImages">
    </van-image-preview>
    <!-- 转账 -->
    <van-overlay :show="transferShow">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="onTransferClose" />
          <template v-if="transferStatus == 1">
            <div class="title">选择转账对象</div>
            <div class="obj-box">
              <div
                class="item"
                v-for="(item, index) in fromUserArr"
                :key="index"
                v-if="item.id != userInfo.id"
                @click="onTransferObj(item)"
              >
                <img :src="item.avatar" alt="" />
                <span class="name">{{ item.nickname }}</span>
                <van-icon
                  v-if="transferId == item.id"
                  name="success"
                  class="icon"
                />
              </div>
            </div>
            <div class="btns">
              <div class="btn" @click="onTransfer_1">下一步</div>
            </div>
          </template>
          <template v-if="transferStatus == 2">
            <div class="title">输入转账金额</div>
            <input
              class="money-input"
              type="number"
              placeholder="请输入转账金额"
              v-model="transferMoney"
              style="margin-bottom: 10px"
            />
            <input
              class="money-input"
              type="text"
              placeholder="请输入转账备注"
              v-model="transferDesc"
            />

            <div class="btns">
              <div class="btn" @click="onTransfer_2">下一步</div>
            </div>
          </template>
          <template v-if="transferStatus == 3">
            <div class="title">身份验证</div>
            <input
              class="money-input"
              type="password"
              placeholder="请输入登录密码"
              v-model="password"
            />

            <div class="btns">
              <div class="btn" @click="onTransfer_3">确认</div>
            </div>
          </template>
        </div>
      </div>
    </van-overlay>
    <!-- 支付维修费 -->
    <van-overlay :show="payserviceShow">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="onPayserviceClose" />
          <template v-if="payserviceStatus == 1">
            <div class="title">维修师傅</div>
            <div class="obj-box">
              <div class="item">
                <img :src="service_info.avatar" alt="" />
                <span class="name">{{ service_info.nickname }}</span>
                <van-icon name="success" class="icon" />
              </div>
            </div>
            <!-- <div class="title">支付维修费用</div> -->
            <input
              style="margin-top: 10px; font-size: 14px"
              class="money-input"
              type="number"
              placeholder="请输入维修费用"
              v-model="payserviceMoney"
            />
            <input
              style="margin-top: 10px; font-size: 14px"
              class="money-input"
              type="text"
              placeholder="请输入备注"
              v-model="payServicDesc"
            />

            <div class="btns">
              <button
                class="btn"
                @click="onPayservice_1"
                :disabled="payServiceBtnDis"
              >
                确认支付
              </button>
            </div>
          </template>
          <template v-if="payserviceStatus == 2">
            <div class="title">身份验证</div>
            <input
              class="money-input"
              type="password"
              placeholder="请输入登录密码"
              v-model="payservicPassword"
            />

            <div class="btns">
              <div class="btn" @click="onPayservice_2">确认</div>
            </div>
          </template>
        </div>
      </div>
    </van-overlay>
    <!-- 对话人员管理 -->
    <van-overlay :show="chatSetShow" @click="chatSetShow = false">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="chatSetShow = false" />
          <div class="title">成员管理</div>
          <div class="obj-box">
            <div
              class="item"
              v-for="(item, index) in fromUserArr"
              :key="index"
              v-if="item.id != userInfo.id"
              @click="onTransferObj(item)"
            >
              <img :src="item.avatar" alt="" />
              <span class="name">{{ item.nickname }}</span>
              <van-icon
                v-if="transferId == item.id"
                name="success"
                class="icon"
              />
            </div>
          </div>
          <div class="btns">
            <div class="btn" @click="onChatUserSet">移出对话</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 完善账号 -->
    <van-overlay :show="accountSetShow" @click="accountSetShow = false">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon
            class="close"
            name="cross"
            @click="accountSetShow = false"
          />
          <div class="title">完善账户</div>
          <input
            v-if="userInfo && !userInfo.mobile"
            class="money-input"
            type="text"
            placeholder="请设置联系电话"
            v-model="mobile"
            style="margin-bottom: 5px; font-size: 14px"
          />
          <input
            class="money-input"
            type="password"
            placeholder="请设置登录密码"
            v-model="accountPassword"
            style="margin-bottom: 5px; font-size: 14px"
          />
          <input
            class="money-input"
            type="password"
            placeholder="请再次确认登录密码"
            v-model="confirm_password"
            style="margin-bottom: 5px; font-size: 14px"
          />
          <div class="btns">
            <div class="btn" @click="setAccount">提交</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 支付上门费 -->
    <van-overlay :show="payDoorShow">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="onPayDoorClose" />
          <template v-if="payDoorShow == 1">
            <div class="title">支付上门费</div>
            <div class="obj-box">
              <div class="item">
                <img :src="service_info.avatar" alt="" />
                <span class="name">{{ service_info.nickname }}</span>
                <van-icon name="success" class="icon" />
              </div>
            </div>
            <!-- <div class="title">支付维修费用</div> -->
            <input
              style="margin-top: 10px; font-size: 14px"
              class="money-input"
              type="number"
              placeholder="请输入上门费用"
              v-model="payDoorMoney"
            />
            <input
              style="margin-top: 10px; font-size: 14px"
              class="money-input"
              type="text"
              placeholder="请输入备注"
              v-model="payDoorDesc"
            />
            <div style="font-size: 12px; color: #666; margin-top: 10px">
              注意：上门费用要填写与师傅商量好的费用；如：师傅上门检修费用100元，就填写100，支付时系统会自动扣除或退还该订单所预付定金（定金多退少补原则）。
            </div>
            <!-- 增加服务费 -->
            <!-- <p
							style="margin-top:18px;font-size: 16px;font-weight: 700;color:#444;margin-bottom:14px;border-top:1px solid #f1f1f1;padding-top:14px;">
							平台管理费 <span style="font-size: 12px;color:#999;font-weight: 500;">用于平台运行和维护</span>
						</p>
						<van-radio-group v-model="serverMoney" direction="horizontal" checked-color="#fff">
							<div class="radio-item">
								<span class="left">¥10</span>
								<span class="right">
									<van-radio name="10" money="10"><template #icon="props"><i
												:class="serverMoney == 10 ? 'iconfont iconqueren1' : 'iconfont iconcircle-jf' "></i></template></van-radio>
								</span>
							</div>
							<div class="radio-item">
								<span class="left">¥20</span>
								<span class="right">
									<van-radio name="20" money="20"><template #icon="props"><i
												:class="serverMoney == 20 ? 'iconfont iconqueren1' : 'iconfont iconcircle-jf' "></i></template></van-radio>
								</span>
							</div>
						</van-radio-group> -->
            <!-- 增加服务费 -->
            <p style="text-align: center; font-size: 28px; font-weight: bold">
              {{ getNowPay() }}
            </p>
            <p
              style="
                margin-top: 18px;
                font-size: 16px;
                color: #444;
                margin-bottom: 14px;
                border-top: 1px solid #f1f1f1;
                padding-top: 8px;
              "
            >
              上门费
              <span style="float: right; color: #999; font-weight: 500"
                >+ ¥{{ Number(payDoorMoney || 0) }}</span
              >
            </p>
            <p
              style="
                margin-top: 18px;
                font-size: 16px;
                color: #444;
                margin-bottom: 14px;
                border-top: 1px solid #f1f1f1;
                padding-top: 8px;
              "
            >
              平台管理费
              <span style="float: right; color: #999; font-weight: 500"
                >+ ¥{{ Number(serverMoney) }}</span
              >
            </p>
            <p
              style="
                margin-top: 18px;
                font-size: 16px;
                color: #444;
                margin-bottom: 14px;
                border-top: 1px solid #f1f1f1;
                padding-top: 8px;
              "
            >
              预付定金
              <span style="float: right; color: #999; font-weight: 500"
                >- ¥{{ orderInfo ? orderInfo.deposit_money || 0 : 0 }}</span
              >
            </p>
            <div class="btns">
              <button
                class="btn"
                @click="onPayDoor_1"
                :disabled="payDoorBtnDis"
              >
                确认支付
              </button>
            </div>
          </template>
          <!-- <template v-if="payDoorStatus == 2">
            <div class="title">身份验证</div>
            <input
              class="money-input"
              type="password"
              placeholder="请输入登录密码"
              v-model="payDoorPassword"
            />

            <div class="btns">
              <div class="btn" @click="onPayDoor_2">确认</div>
            </div>
          </template> -->
        </div>
      </div>
    </van-overlay>

    <!-- 支付方式 -->
    <van-overlay :show="payTypeShow" @click="payTypeShow = false">
      <div class="payType-mask" @click.stop>
        <div class="title">请选择支付方式</div>
        <div class="item" @click="changePayType(1)">
          <i class="iconfont iconyue"></i>
          <span>余额支付</span>
          <i v-if="payType == 1" class="iconfont iconqueren1 active"></i>
          <i v-else class="iconfont iconqueren1"></i>
        </div>
        <!-- <div class="item" @click="changePayType(2)">
          <i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
          <span>微信支付</span>
          <i v-if="payType == 2" class="iconfont iconqueren1 active"></i>
          <i v-else class="iconfont iconqueren1"></i>
        </div> -->
        <button class="pay-btn" @click="onPay">支付</button>
      </div>
    </van-overlay>

    <!-- 评价 -->
    <van-overlay :show="pingjiaShow" @click="pingjiaShow = false">
      <div class="pingjia-mask" @click.stop>
        <div class="title">服务评价</div>
        <!-- <img src="@/assets/image/shifu.png" alt="" />
        <span class="name">{{ serviceInfo.name }}</span> -->
        <span class="tips">给师傅打分</span>
        <van-rate v-model="rate" size="24" color="#ffd21e" />
        <textarea
          v-model="pingjiaValue"
          class="pingjia-input"
          cols="30"
          rows="4"
          placeholder="请输入对师傅的评价"
        ></textarea>
        <button class="pay-btn" @click="onSubPingjia">提交</button>
      </div>
    </van-overlay>
    <!-- 完善保修信息 -->
    <van-overlay :show="guaranteeShow" @click="guaranteeShow = false">
      <div class="wrapper" @click.stop>
        <div class="transfer-box">
          <van-icon class="close" name="cross" @click="guaranteeShow = false" />
          <div class="title">完善保修信息</div>
          <van-field name="is_guarantee" label="是否保修">
            <template #input>
              <van-radio-group
                v-model="guaranteeForm.is_guarantee"
                direction="horizontal"
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="guaranteeForm.is_guarantee == 1">
            <van-field
              v-model.number="guaranteeForm.guarantee_time"
              center
              clearable
              label="保修时长"
              placeholder="请输入1-12"
            >
              <template #button>
                <span>个月</span>
              </template>
            </van-field>
          </template>
          <van-field
            v-model="guaranteeForm.guarantee_remark"
            label="备注"
            placeholder="请输入备注"
          ></van-field>
          <div class="btns">
            <!-- <div class="btn" @click="setGuarantee">提交</div> -->
            <van-button
              style="width: 180px"
              type="info"
              @click="setGuarantee"
              round
              block
              :loading="guaranteeBtnLoading"
              >提交</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>

    <Driver
      v-if="stepStatus"
      storageName="chat"
      :steps="steps"
      @complete="driverComplete"
    ></Driver>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import $ from "jquery";
import Recorderx, { ENCODE_TYPE } from "recorderx";
const rc = new Recorderx();
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import Driver from "../components/Driver";

// import websocket from '../utils/webSocket'
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../api";
import base from "../api/base.js";
import MapChoice from "./map_choice";
import { wechatConfig } from "../utils/wxConfig";
import { login } from "../utils/login_toast";
import { getVue, payAccount } from "../utils/payAccount";
import { loginAlert } from "../utils/loginAlert";

import Storage from "../utils/Storage.js";

export default {
  inject: ["reload"],
  props: {},
  data() {
    return {
      style: {
        width: "88%",
      },
      value: "",
      showSend: false,
      showMore: false,
      chat_id: "", //对话id
      chat_list: [], //聊天记录列表
      uid: "",
      url: base.apiURL,
      loginURL: base.loginURL,
      liststyle: {
        "padding-bottom": "120px",
      },
      order_no: "",
      to_uid: "",
      mapShow: false,
      toUserInfo: {
        nickname: "",
      },
      userInfo: "",
      voiceShow: false,
      outTime: 0,
      timer: null,
      localId: "",
      voiceText: "点击开始录制",
      playVoice: false,
      stopVoice: false,
      serverId: "",

      audio: new Audio(),
      previewImages: [],
      previewShow: false,
      // wx: wx,
      fromUserArr: [],
      fromIdArr: [],
      title: "",

      audioPlay: true,
      orderInfo: "",

      transferShow: false,
      transferId: "", //转账对象id
      transferStatus: 1,
      transferMoney: "",
      transferDesc: "",
      password: "",
      chatSetShow: false, //成员管理
      from_uid: "",

      payserviceShow: false,
      payserviceId: "", //转账对象id
      payserviceStatus: 1,
      payserviceMoney: "",
      payservicPassword: "",
      service_info: "",
      payServicDesc: "支付维修费用",

      accountSetShow: false,
      accountPassword: "",
      confirm_password: "",
      mobile: "",

      jsApiParameters: "",

      //支付上门费
      payDoorShow: false,
      payDoorId: "", //转账对象id
      payDoorStatus: 1,
      payDoorMoney: "",
      payDoorPassword: "",
      payDoorDesc: "支付上门检修费",

      payTypeShow: false,
      payType: 1, //1 余额支付 2微信支付

      payServiceType: "", //支付类型 door:上门费 deposit:预付定金 service:维修费
      bj_money: "", //需补交订金

      pingjiaShow: false, //评价弹出
      rate: 4, //评分
      pingjiaValue: "", //评价
      payDoorBtnDis: false,
      payServiceBtnDis: false,

      guaranteeShow: false, //保修信息弹框

      guaranteeForm: {
        is_guarantee: 1,
        guarantee_time: "",
        guarantee_remark: "",
      },

      showGuarantee: true,
      guaranteeBtnLoading: false,

      // 平台服务费
      serverMoney: 10,

      // 引导
      stepStatus: false,
      steps: [
        {
          element: "#chat-input",
          popover: {
            title: "发送信息",
            description: "点击这里可弹出键盘，输入您要发送的聊天信息~",
            position: "top",
          },
        },
        {
          element: "#more-btn",
          popover: {
            title: "展开更多",
            description: "点击这里可以展开/收缩更多功能面板。",
            position: "left",
          },
        },
        {
          element: "#more-box",
          popover: {
            title: "更多操作",
            description:
              "此处为更多功能区域，可转账、支付上门费用、发送照片等操作，点击按钮即可使用~",
            position: "top",
          },
        },
      ],

      tagsBoxShow: true,
    };
  },
  computed: {},
  created() {
    var _this = this;
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    this.chat_id = this.$route.query.chat_id;
    this.order_no = this.$route.query.order_no;
    this.to_uid = this.$route.query.to_uid;
    this.from_uid = this.$route.query.from_uid;
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
      this.mobile = this.$cookies.get("userInfo").mobile;
    }
    if (this.getUrlKey("hide_tags_box")) {
      this.tagsBoxShow = false;
    }
    if (!this.$cookies.isKey(`userInfo`) && !code) {
      loginAlert();
      return;
    } else if (!this.$cookies.isKey(`userInfo`)) {
      login(this.getLoginUser);
    } else {
      this.getOrder();
      this.getUserInfo();
      this.getChatUsers();
    }
    var invate_uid = this.$route.query.invate_uid;
    this.$cookies.set("invate_uid", invate_uid);

    // this.payDoorShow 	=	1;
  },
  mounted() {
    var that = this;
    // window.scrollTo(0, 50000);
    //设置滚动条在底部
    this.socketApi.messageCock(this.messageCallback);
    if (!Storage.get("chatDriverStatus")) {
      setTimeout(() => {
        that.showMore = true;
        that.stepStatus = true;
      }, 300);
    }
  },
  watch: {},
  updated() {},
  methods: {
    /**
     * 获取现在应该支付的钱
     */
    getNowPay() {
      // this.orderInfo.deposit_money 	=	100;
      if (!this.payDoorMoney) {
        return "¥" + Number(this.serverMoney);
      }
      var now = Number(this.payDoorMoney || 0) + Number(this.serverMoney);
      if (parseFloat(this.orderInfo.deposit_money) < 0.1) {
        return (
          "" +
          Number(this.payDoorMoney || 0) +
          "+" +
          Number(this.serverMoney) +
          "=¥" +
          now
        );
      }

      if (now > parseFloat(this.orderInfo.deposit_money)) {
        now = now - parseFloat(this.orderInfo.deposit_money);
        return (
          "" +
          Number(this.payDoorMoney || 0) +
          "+" +
          Number(this.serverMoney) +
          "-" +
          this.orderInfo.deposit_money +
          "=¥" +
          now
        );
      } else {
        now = "0.00";
      }
      return now;
    },
    /**
     * 步骤引导没了
     */
    driverComplete() {
      this.showMore = false;
      Storage.set("chatDriverStatus", 1);
    },
    getDate(msgDate) {
      const _this = this;
      const date = new Date();
      const nowDate = date.getTime();
      const magDate = new Date(msgDate).getTime();
      const endTime = magDate + 48 * 60 * 60 * 1000;
      if (endTime > nowDate) {
        return true;
      } else {
        return false;
      }
    },
    getNowDate(msgDate) {
      const _this = this;
      const date = new Date();
      const magDate = new Date(msgDate);
      var year = magDate.getFullYear();
      var month = magDate.getMonth() + 1;
      var dates = magDate.getDate();
      var h = magDate.getHours();
      h = h < 10 ? "0" + h : h;
      var m = magDate.getMinutes();
      m = m < 10 ? "0" + m : m;
      var s = magDate.getSeconds();
      s = s < 10 ? "0" + s : s;
      return year + "年" + month + "月" + dates + "日 " + h + ":" + m + ":" + s;
    },
    getEndDate(msgDate) {
      const _this = this;
      const date = new Date();
      const magDate = new Date(msgDate).getTime();
      const endTime = magDate + 48 * 60 * 60 * 1000;
      const endDate = new Date(endTime);
      console.log(endDate);
      var year = endDate.getFullYear();
      var month = endDate.getMonth() + 1;
      var dates = endDate.getDate();
      var h = endDate.getHours();
      h = h < 10 ? "0" + h : h;
      var m = endDate.getMinutes();
      m = m < 10 ? "0" + m : m;
      var s = endDate.getSeconds();
      s = s < 10 ? "0" + s : s;
      return year + "年" + month + "月" + dates + "日 " + h + ":" + m + ":" + s;
    },
    //师傅完善保修信息
    onEditGuarantee() {
      const _this = this;
      if (_this.orderInfo.service_id != _this.userInfo.id) {
        _this.$toast("只能由维修师傅填写");
        return;
      }
      if (_this.orderInfo.is_guarantee > 0) {
        _this.$toast("已填写过维修信息");
        return;
      }
      _this.guaranteeShow = true;
    },
    setGuarantee() {
      const _this = this;
      const { is_guarantee, guarantee_time, guarantee_remark } =
        _this.guaranteeForm;
      if (is_guarantee == "") {
        _this.$toast("请选择是否保修！");
        return;
      }
      if (is_guarantee == 1 && guarantee_time == "") {
        _this.$toast("保修时长不能为空！");
        return;
      }
      if (is_guarantee == 1 && guarantee_time < 1) {
        _this.$toast("保修时长不得小于1个月！");
        return;
      }
      if (is_guarantee == 1 && guarantee_time > 12) {
        _this.$toast("保修时长不得大于12个月！");
        return;
      }
      const data = {
        order_no: _this.orderInfo.order_no,
        is_guarantee: is_guarantee,
        guarantee_time: guarantee_time,
        guarantee_remark: guarantee_remark,
      };
      _this.guaranteeBtnLoading = true;
      addOp("/service_order/save_guarantee", data).then((res) => {
        if (res && res.status == 200) {
          console.log(res);
          var msg = {
            desc: "保修信息",
            is_guarantee: is_guarantee,
            guarantee_time: guarantee_time,
            guarantee_remark: guarantee_remark,
          };
          _this.onSendMessage(msg, "guarantee");
          _this.getOrder();
          _this.guaranteeShow = false;
          _this.guaranteeBtnLoading = false;
        }
      });
    },
    //邀请师傅完善保修信息
    onGuarantee() {
      const _this = this;
      if (_this.showGuarantee) {
        _this.showGuarantee = false;
        if (_this.orderInfo.is_guarantee > 0) {
          _this.$toast("已填写过维修信息");
          return;
        }
        var msg = {
          desc: "用户发起完善保修信息",
        };
        _this.onSendMessage(msg, "edit_guarantee");
        setTimeout(() => {
          _this.showGuarantee = true;
        }, 1000);
      }
    },
    getChatUsers() {
      var _this = this;
      var data = {
        chat_id: _this.chat_id,
      };
      indexOp("/im/get_chat_users", data).then((res) => {
        if (res && res.status == 200) {
          _this.fromUserArr = res.data;
          res.data.map((item) => {
            var id = item.id;
            _this.fromIdArr.push(id);
          });
        }
      });
    },
    toBill() {
      this.$router.push("/bill");
    },
    onPingjiaShow() {
      this.payServiceType = "pingjia";
      this.pingjiaShow = true;
    },
    // 评价
    onSubPingjia() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        uid: _this.userInfo.id,
        order_no: _this.order_no,
        comment_desc: _this.pingjiaValue,
        comment_stars: _this.rate,
        service_id: _this.orderInfo.service_id,
      };
      addOp("/order_comment/create", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 201) {
          _this.payServiceType = "pingjia";
          _this.setServiceLog(_this.payServiceType);
          _this.$toast.success("评价成功！");
        }
        console.log(res);
      });
    },
    changePayType(type) {
      this.payType = type;
    },
    //支付维修费
    successPayService() {
      var _this = this;
      // var data = {
      //   order_no: _this.order_no,
      //   service_type: "service_ing",
      //   service_money:_this.payserviceMoney,
      //   service_pay_status: 1,
      // };
      // addOp("/service_order/update", data).then((res) => {
      // if (res && res.data) {
      _this.$toast.success("支付成功！");
      var msg = {
        desc: _this.payServicDesc,
        money: _this.payserviceMoney,
        name: _this.service_info.nickname,
        to_uid: _this.orderInfo.service_id,
      };
      _this.onSendMessage(msg, "pay_service_money");
      _this.getOrder();
      // _this.setServiceLog(_this.payServiceType);
      _this.onPayserviceClose();
      _this.$dialog
        .confirm({
          title: "提示",
          message: "是否邀请师傅填写保修信息？",
          confirmButtonText: "确认",
        })
        .then(() => {
          // _this.$router.push({
          //   path: "/userRepairDetail",
          //   query: {
          //     order_no: _this.orderInfo.order_no,
          //   },
          // });
          _this.onGuarantee();
        })
        .catch(() => {
          // on cancel
        });
      // _this.pingjiaShow = true;
      //   } else {
      //     _this.$toast.fail("支付失败！");
      //   }
      //   console.log(res);
      // });
    },
    setServiceLog(type) {
      var _this = this;
      console.log(type);
      var data = {
        uid: _this.userInfo.id,
        order_no: _this.order_no,
        service_type: _this.orderInfo.service_type,
        identity_type: "user",
      };
      if (type == "door") {
        data.content = "支付上门检修费：" + _this.payDoorMoney + "元";
      }
      if (type == "service") {
        data.content = "支付维修费：" + _this.payserviceMoney + "元";
      }
      if (type == "pingjia") {
        data.content = _this.pingjiaValue;
        data.service_type = "appraise_order";
      }
      addOp("/service_log/create", data).then((res) => {
        // _this.reload();
        if (type == "pingjia") {
          _this.uploadOrder("appraise_order");
        }
        if (type == "service") {
          _this.uploadOrder("service_ing");
        }
        console.log(res);
      });
    },
    //更新订单
    uploadOrder(status) {
      var _this = this;
      var data = {
        order_no: _this.order_no,
        service_type: status,
      };
      addOp("/service_order/update", data).then((res) => {
        if (res && res.data) {
          _this.reload();
        } else {
        }
        console.log(res);
      });
    },
    //支付
    onPay() {
      var _this = this;
      var type = this.payType;
      // 微信支付
      if (type == 2) {
        _this.$toast.loading({
          message: "提交中...",
          forbidClick: true,
          duration: 0,
        });
        var data = {};
        //支付上门费
        if (_this.payServiceType == "door") {
          data.order_no = _this.order_no;
          data.money = _this.bj_money;
          data.pay_type = "door_money_add_wxpay";
        }
        if (_this.payServiceType == "service") {
          data.order_no = _this.order_no;
          data.money = _this.bj_money;
          data.pay_type = "service_money_wxpay";
        }
        addOp("/pay_order", data).then((res) => {
          _this.$toast.clear();
          if (res && res.status == 200) {
            _this.jsApiParameters = res.data;
            _this.callpay(_this.payServiceType);
            _this.payTypeShow = false;
          } else {
            _this.$toast.fail(res.data.error);
          }
        });
      } else {
        // 余额支付
        var _this = this;
        var user_account = parseFloat(
          _this.$cookies.get("userInfo").user_account
        );
        var money = parseFloat(_this.bj_money);
        var data = {};

        if (_this.payServiceType == "door") {
          data.service_order_id = _this.orderInfo.id;
          data.money = _this.bj_money;
          data.pay_type = "door_money_add_account";
          data.desc = "支付上门费用";
        }
        if (_this.payServiceType == "service") {
          data.service_order_id = _this.orderInfo.id;
          data.money = _this.bj_money;
          data.pay_type = "service_money_account";
          data.desc = "支付维修费用";
        }
        //判断余额是否充足
        // if(user_account < money){
        //   _this.$toast.fail("账户余额不足，请改用其他支付方式！");
        //   return;
        // }
        _this.$toast.loading({
          message: "提交中...",
          forbidClick: true,
          duration: 0,
        });
        addOp("/bill_order/pay_service_order", data).then((res) => {
          console.log(res);
          _this.$toast.clear();
          if (res && res.status == 200 && res.data.state == 1000) {
            // _this.$toast.success("操作成功！");
            if (_this.payServiceType == "door") {
              _this.onPayServiceDoor();
            } else if (_this.payServiceType == "service") {
              _this.successPayService();
            }
            _this.getUserInfo();
            _this.payTypeShow = false;
            // setTimeout(()=>{
            //   _this.reload()
            // },800)
          } else {
            if (res.data.state == 9999) {
              payAccount(money, user_account);
            } else {
              _this.$toast.fail(res.data.msg);
            }
          }
        });
      }
    },
    onPayDoorMoney() {
      var _this = this;
      if (!_this.orderInfo.service_id) {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "该订单还没有师傅接单。",
            confirmButtonText: "确认",
          })
          .then(() => {
            // _this.$router.push({
            //   path: "/userRepairDetail",
            //   query: {
            //     order_no: _this.orderInfo.order_no,
            //   },
            // });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        //获取维修师傅信息
        var api = "/user/user_show/" + _this.orderInfo.service_id;
        indexOp(api).then((res) => {
          if (res && res.status == 200) {
            _this.service_info = res.data;
            _this.payDoorShow = true;
            if (_this.orderInfo.door_pay_status == 1) {
              _this.serverMoney = 0;
            }
          }
        });
      }
    },
    onPayDoor_1() {
      var _this = this;
      if (_this.payDoorMoney == "") {
        _this.$toast("请填写上门费用！");
        return;
      }
      var goToMoney = Number(_this.serverMoney) + Number(_this.payDoorMoney);
      var deposit_money = parseFloat(_this.orderInfo.deposit_money); //预付定金
      var door_money = parseFloat(_this.payDoorMoney); //需支付上门费
      var user_account = parseFloat(
        _this.$cookies.get("userInfo").user_account
      ); //账户余额
      _this.payDoorBtnDis = true;
      if (
        _this.orderInfo.uid != _this.userInfo.id &&
        _this.orderInfo.real_uid != _this.userInfo.id &&
        _this.userInfo.identity == 9
      ) {
        if (deposit_money >= goToMoney) {
          _this.payServiceType = "door";
          _this.onPayServiceDoor();
        } else {
          _this.payDoorBtnDis = false;
          _this.$dialog
            .alert({
              title: "提示",
              message: "该订单预付定金不足，请联系下单人支付！",
            })
            .then(() => {
              // on close
            });
        }
        return;
      }
      _this.onPayServiceDoor();
    },
    //支付上门费给师傅
    onPayServiceDoor() {
      var _this = this;
      var bj_money = 0;
      var user_account = parseFloat(
        _this.$cookies.get("userInfo").user_account
      ); //账户余额
      var deposit_money = parseFloat(_this.orderInfo.deposit_money); //预付定金
      var boorMoney = Number(_this.serverMoney) + Number(_this.payDoorMoney); //上门费
      if (deposit_money < boorMoney) {
        bj_money = (boorMoney * 1000 - deposit_money * 1000) / 1000;
      } else {
        bj_money = boorMoney;
      }
      var data = {
        service_order_id: _this.orderInfo.id,
        pay_type: "door_money_add_account",
        money: boorMoney,
        smoney: _this.serverMoney,
        desc: `支付上门费用 (包含服务费${_this.serverMoney}元)`,
      };
      // var api = "/service_order/pay_door_money";
      var api = "/bill_order/pay_service_order";
      addOp(api, data).then((res) => {
        if (res && res.status == 200 && res.data.state == 1000) {
          _this.payDoorBtnDis = false;
          _this.$toast.success("支付成功！");
          var msg = {
            desc: _this.payDoorDesc,
            money: _this.payDoorMoney,
            name: _this.service_info.nickname,
            to_uid: _this.orderInfo.service_id,
          };
          _this.onSendMessage(msg, "pay_door_money");
          _this.getOrder();
          // _this.setServiceLog(_this.payServiceType);
          _this.onPayDoorClose();
        } else {
          _this.payDoorBtnDis = false;
          if (res && res.data && res.data.state == 9999) {
            payAccount(bj_money, user_account);
          } else {
            _this.$toast.fail(res.data.msg);
          }
        }
        console.log(res);
      });
    },
    // onPayDoor_2(){
    //   var _this = this;
    //   if (_this.payDoorPassword == "") {
    //     _this.$toast("请输入登录密码！");
    //     return;
    //   }
    //   var data = {
    //     order_no: _this.order_no,
    //     door_money: _this.payDoorMoney,
    //   };
    //   addOp("/service_order/pay_door_money", data).then((res) => {
    //     if (res && res.status == 200) {
    //       _this.$toast.success("支付成功!");
    //       // var msg = {
    //       //   money: _this.transferMoney,
    //       //   to_uid: _this.transferId,
    //       //   name: _this.$cookies.get(`fromuser_${_this.transferId}`),
    //       // };
    //       // _this.onSendMessage(msg, "transfer");
    //       _this.onPayDoorClose();
    //     } else {
    //       _this.$toast.fail(res.data.error);
    //     }
    //   });
    // },
    onPayDoorClose() {
      this.payDoorStatus = 1;
      this.payDoorId = "";
      this.payDoorMoney = "";
      this.payDoorPassword = "";
      this.payServiceType = "";
      this.bj_money = "";
      this.payDoorShow = false;
    },
    //视频同步
    onSyncVideo(item, index) {
      var _this = this;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认将该视频同步到店铺视频？",
          confirmButtonText: "确认",
        })
        .then(() => {
          var api = "/im/update_status/" + item.id;
          var data = {
            status: 1,
          };
          // console.log(item.content.split("com")[1])
          var video_url = item.content.split("com")[1];
          addOp(api, data).then((res) => {
            console.log(res);
            if (res.data == 1) {
              _this.createVideo(video_url);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    createVideo(video) {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
        video_url: video,
        name: _this.orderInfo.name + "-维修记录",
      };
      addOp("/video/create", data).then((res) => {
        // _this.$toast.clear();
        if (res && res.status == 201) {
          // _this.$toast.success("同步成功！");
          // setTimeout(() => {
          // 	_this.reload();
          // }, 800);
        } else {
        }
        console.log(res);
      });
    },
    //完善账户信息
    setAccount() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      if (_this.accountPassword == "") {
        _this.$toast.fail("请输入登录密码！");
        return;
      }
      if (_this.accountPassword !== _this.confirm_password) {
        _this.$toast.fail("两次输入密码不一致！");
        return;
      }
      var data = {
        password: _this.accountPassword,
        confirm_password: _this.confirm_password,
        mobile: _this.mobile,
      };
      console.log(data);
      addOp("/user/update/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.userInfo = res.data;
          _this.$cookies.set("userInfo", res.data);
          _this.$toast.success("操作成功！");
          _this.accountSetShow = false;
        } else {
          if (res.data.errors) {
            for (var key in res.data.errors) {
              _this.$toast.fail(res.data.errors[key][0]);
            }
          } else {
            _this.$toast.fail("操作失败，请重试");
          }
        }
      });
    },
    //余额支付维修费
    onPayService() {
      var _this = this;
      if (!_this.orderInfo.service_id) {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "您还未选择维修师傅，请先选择师傅后在进行付款！",
            confirmButtonText: "去选择",
          })
          .then(() => {
            _this.$router.push({
              path: "/userRepairDetail",
              query: {
                order_no: _this.orderInfo.order_no,
              },
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        //获取维修师傅信息
        var api = "/user/user_show/" + _this.orderInfo.service_id;
        indexOp(api).then((res) => {
          if (res && res.status == 200) {
            _this.service_info = res.data;
            this.payserviceShow = true;
          }
        });
      }
    },
    onPayservice_1() {
      var _this = this;
      if (_this.payserviceMoney == "") {
        _this.$toast("请输入支付金额！");
        return;
      }
      if (parseFloat(_this.payserviceMoney) < 0.01) {
        _this.$toast("支付金额不得小于0.01元");
        return;
      }

      _this.payServiceBtnDis = true;
      var bj_money = 0;
      var user_account = parseFloat(
        _this.$cookies.get("userInfo").user_account
      ); //账户余额
      var deposit_money = parseFloat(_this.orderInfo.deposit_money); //预付定金
      var payserviceMoney = parseFloat(_this.payserviceMoney); //维修费
      if (deposit_money < payserviceMoney) {
        bj_money = (payserviceMoney * 1000 - deposit_money * 1000) / 1000;
      } else {
        bj_money = payserviceMoney;
      }
      var data = {
        service_order_id: _this.orderInfo.id,
        pay_type: "service_money_account",
        money: payserviceMoney,
        desc: "支付维修费用",
      };
      // var api = "/service_order/pay_door_money";
      var api = "/bill_order/pay_service_order";
      addOp(api, data).then((res) => {
        _this.payServiceBtnDis = false;
        if (res && res.status == 200 && res.data.state == 1000) {
          _this.$toast.success("支付成功！");
          _this.successPayService();
        } else {
          if (res.data.state == 9999) {
            payAccount(bj_money, user_account);
          } else {
            _this.$toast.fail(res.data.msg);
          }
        }
        console.log(res);
      });
    },
    onPayservice_2() {
      var _this = this;
      if (_this.payservicPassword == "") {
        _this.$toast("请输入登录密码！");
        return;
      }
      var data = {
        order_no: _this.order_no,
        money: _this.payserviceMoney,
        password: _this.payservicPassword,
      };
      addOp("/user_account/order_pay", data).then((res) => {
        if (res && res.status == 200) {
          _this.$toast.success("支付成功!");
          var msg = {
            desc: "支付维修费",
            money: _this.payserviceMoney,
            name: _this.service_info.nickname,
            to_uid: _this.orderInfo.service_id,
          };
          _this.onSendMessage(msg, "pay_service_money");
          _this.onPayserviceClose();
        } else {
          _this.$toast.fail(res.data.error);
        }
        console.log(res);
      });
    },
    onPayserviceClose() {
      this.payserviceStatus = 1;
      this.payserviceId = "";
      this.payserviceMoney = "";
      this.payservicPassword = "";
      this.payServiceType = "";
      this.bj_money = "";
      this.payserviceShow = false;
    },
    onTest() {
      var _this = this;
      var uid = "1155";
      var data = {
        content: "1",
        date: "2021/2/23上午8:55:23",
        from_uid: "1155",
      };
      if (_this.fromIdArr.indexOf(uid) == -1) {
        if (!_this.$cookies.isKey(`fromuser_${uid}`)) {
          var api = "/user/user_show/" + uid;
          indexOp(api).then((r) => {
            if (r && r.status == 200) {
              var data = r.data;
              data.from_id = uid;
              _this.fromIdArr.push(uid);
              _this.fromUserArr.push(data);
              _this.$cookies.set(`fromuser_${uid}`, data);
            }
          });
        } else {
          _this.fromIdArr.push(uid);
          _this.fromUserArr.push(_this.$cookies.get(`fromuser_${uid}`));
        }
      }
      _this.chat_list.push(data);
      _this.scrollToBottom();
    },
    onOrderServiceEnd() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
        service_type: "service_end",
        service_user: true,
      };
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认订单已维修完成？",
          confirmButtonText: "确认",
        })
        .then(() => {
          addOp("/service_order/update", data).then((res) => {
            if (res && res.data) {
              _this.$toast.success("操作成功！");
              _this.addServiceLog("service_end");
            } else {
              _this.$toast.fail("操作失败！");
            }
            console.log(res);
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    onOrderEnd() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
        service_type: "user_sure_service",
      };
      if (_this.userInfo.identity == 9) {
        data.service_user = true;
      }
      if (!_this.orderInfo.service_id) {
        _this.$toast.fail("该订单不允许确认完成");
        return false;
      }
      // var pmessage 	=	`上门检修费${_this.orderInfo.door_money}元+维修费${_this.orderInfo.service_money}元`;
      // if(_this.orderInfo.is_guarantee == 0){
      // 	pmessage 	+=	'+服务费20元';
      // }
      // pmessage 	+=	'=' + (parseFloat(_this.orderInfo.door_money) + parseFloat(_this.orderInfo.service_money) +  parseFloat((_this.orderInfo.is_guarantee == 0 ? 20 : 0) )) + '元';

      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认完成订单？",
          confirmButtonText: "确认",
        })
        .then(() => {
          addOp("/service_order/update", data).then((res) => {
            if (res && res.data) {
              _this.$toast.success("操作成功！");
              _this.addServiceLog("user_sure_service");
            } else {
              _this.$toast.fail("操作失败！");
            }
            console.log(res);
          });
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },

    //添加维修日志
    addServiceLog(item) {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
        identity_type: "user",
        order_no: _this.order_no,
        service_type: item,
      };
      addOp("/service_log/create", data).then((res) => {
        console.log(res);
        _this.reload();
      });
    },
    getUserInfo() {
      var _this = this;
      var api = "/user/show/1";
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          if (res.data.identity == null) {
            _this.$router.push({
              path: "/identity",
            });
            return;
          }
          _this.$cookies.set("identity", res.data.identity);
          _this.$cookies.set("userInfo", res.data);
          _this.userInfo = res.data;

          // 如果是vip，平台服务费为10，反之20
          if (res.data.user_vip_info && res.data.user_vip_info.vip_level != 0) {
            _this.serverMoney = 10;
          } else {
            _this.serverMoney = 20;
          }
        } else {
          console.log(res);
        }
      });
    },
    //成员管理
    onChatUserSet() {
      var _this = this;
      if (_this.transferId == "") {
        _this.$toast("请选择移出对象！");
        return;
      }
      var data = {
        chat_id: _this.chat_id,
        repel_uid: _this.transferId,
      };
      addOp("/im/repel_chat", data).then((res) => {
        if (res && res.status == 200 && res.data == 1) {
          _this.$toast.success("操作成功！");
          _this.$cookies.remove(`fromuser_${_this.transferId}`);
          setTimeout(() => {
            _this.reload();
          }, 1000);
        } else {
          _this.$toast.fail("该成员已移出！");
        }
        console.log(res);
      });
    },
    onTransferClose() {
      this.transferStatus = 1;
      this.transferId = "";
      this.transferMoney = "";
      this.password = "";
      this.transferShow = false;
    },
    //转账操作
    onTransfer_1() {
      var _this = this;
      if (_this.userInfo.is_password == 1) {
        if (_this.transferStatus == 1) {
          if (_this.transferId == "") {
            _this.$toast("请先选择转账对象");
            return;
          }
          _this.transferStatus = 2;
        }
      } else {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "为了您的账户安全，请先去设置登录密码。",
            confirmButtonText: "去设置",
          })
          .then(() => {
            // _this.$router.push({
            //   path: "/editUserinfo",
            // });
            _this.accountSetShow = true;
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    onTransfer_2() {
      var _this = this;
      if (_this.transferStatus == 2) {
        if (_this.transferMoney == "") {
          _this.$toast("请输入转账金额！");
          return;
        }
        if (parseFloat(_this.transferMoney) < 0.01) {
          _this.$toast("转账金额不得小于0.01元");
          return;
        }
        if (_this.transferDesc == "") {
          _this.$toast("请输入转账备注！");
          return;
        }
        var user_account = parseFloat(_this.userInfo.user_account);
        var transferMoney = parseFloat(_this.transferMoney);
        if (user_account < transferMoney) {
          //转账金额大于账户余额，计算相差金额
          var chajia = parseFloat(transferMoney - user_account).toFixed(1);
          console.log(chajia);
          _this.$dialog
            .confirm({
              title: "提示",
              message: "您的余额不足，还差" + chajia + "元，请先充值。",
              confirmButtonText: "微信充值",
            })
            .then(() => {
              // _this.$router.push({
              //   path: "/chongzhi",
              // });
              _this.recharge(chajia, "transfer");
              // on confirm
            })
            .catch(() => {
              // on cancel
            });
          return;
        }
        _this.transferStatus = 3;
      }
    },
    onTransfer_3() {
      var _this = this;
      if (_this.password == "") {
        _this.$toast("请输入登录密码！");
        return;
      }
      var data = {
        to_uid: _this.transferId,
        money: _this.transferMoney,
        desc: _this.transferDesc,
        password: _this.password,
        service_order_id: _this.orderInfo.id,
      };
      addOp("/bill_order/user_transfer", data).then((res) => {
        if (res && res.status == 200 && res.data.state == 1000) {
          _this.$toast.success("转账成功!");
          var msg = {
            desc: _this.transferDesc,
            money: _this.transferMoney,
            to_uid: _this.transferId,
            name: _this.$cookies.get(`fromuser_${_this.transferId}`),
          };
          _this.onSendMessage(msg, "transfer");
          _this.onTransferClose();
        } else {
          _this.$toast.fail(res.data.msg);
        }
        console.log(res);
      });
    },
    //账户充值
    recharge(money, type) {
      var _this = this;
      var data = {
        money: money,
      };
      _this.$toast.loading({
        message: "请稍等...",
        forbidClick: true,
        duration: 0,
      });
      addOp("/bill_order/user_account_recharge", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.jsApiParameters = res.data.data.pay_param;
          _this.callpay(type);
          // _this.payTypeShow = false;
        } else {
          _this.$toast.fail(res.data.error);
        }
      });
    },
    //支付
    jsApiCall(type) {
      var _this = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        _this.jsApiParameters,
        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            // alert("充值成功！");
            // setTimeout(()=>{
            //   _this.reload()
            // },500)
            if (type == "transfer") {
              _this.transferStatus = 3;
            } else if (type == "service") {
              _this.successPayService();
              _this.payTypeShow = false;
            } else if (type == "door") {
              _this.onPayServiceDoor();
              _this.payTypeShow = false;
            }
          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            alert("充值失败！请重试！");
          }
        }
      );
    },
    callpay(type) {
      var _this = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            _this.jsApiCall(type),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall(type));
          document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall(type));
        }
      } else {
        _this.jsApiCall(type);
      }
    },
    //获取转账对象信息
    onTransferObj(item) {
      this.transferId = item.id;
      console.log(item);
    },
    //获取登录返回的用户数据
    getLoginUser(userInfo) {
      var _this = this;
      _this.userInfo = userInfo;
      _this.getOrder();
      _this.getChatUsers();
    },
    wxChat(info) {
      var _this = this;
      var _url = window.location.href.split("#")[0];
      //发起请求
      var data = {
        url: _url,
      };
      addOp("/getSign", data)
        .then(function (res) {
          // alert(res)
          wx.config({
            debug: false,
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: [
              "startRecord",
              "stopRecord",
              "playVoice",
              "pauseVoice",
              "stopVoice",
              "uploadVoice",
              "downloadVoice",
              "downloadVoice",
              "getLocation",
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "chooseImage",
              "uploadImage",
              "downloadImage",
              "getLocalImgData",
            ], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
          });

          wx.ready(() => {
            wx.getLocation({
              type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                var speed = res.speed; // 速度，以米/每秒计
                var accuracy = res.accuracy; // 位置精度
                // alert("lat:"+latitude+"lng:"+longitude )
                VueCookies.set("LocationInfo", res);
                console.log(res);
              },
            });

            const share_title = info.title;
            const share_desc = info.desc;
            const share_link = info.link;
            const share_img = info.img;
            // wx.showOptionMenu();
            wx.updateTimelineShareData({
              title: share_title, // 分享标题
              link: share_link, // 分享链接
              imgUrl: share_img, // 分享图标
              success: function () {
                console.log("设置成功");
                // alert("成功！")
              },
            });
            wx.updateAppMessageShareData({
              title: share_title, // 分享标题
              desc: share_desc, // 分享描述
              link: share_link, // 分享链接
              imgUrl: share_img, // 分享图标
              success: function () {
                console.log("设置成功");
                // alert("成功！")
              },
            });
          });
          wx.error(function (res) {
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        })
        .catch(function () {});
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `与${this.title}对话`,
        desc: `聚制冷精英，集行业资源，方便您我他！`,
        link:
          base.shareURL +
          "/chat?chat_id=" +
          this.chat_id +
          "&to_uid=" +
          this.to_uid +
          "&from_uid=" +
          this.from_uid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      this.wxChat(shareInfo);
    },
    //图片预览
    onPreviewImg(src) {
      this.previewImages = [src];
      this.previewShow = true;
    },
    //设置页面保持底部
    scrollToBottom() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.$refs.chat.scrollHeight;
        document.body.scrollTop = this.$refs.chat.scrollHeight;
      });
    },
    getToUser() {
      var _this = this;
      var id = _this.to_uid;
      var api = "/user/user_show/" + id;
      if (!_this.$cookies.isKey(`fromuser_${id}`)) {
        var api = "/user/user_show/" + id;
        indexOp(api).then((res) => {
          if (res && res.status == 200) {
            var data = res.data;
            data.from_id = id;
            _this.fromUserArr.push(data);
            _this.fromIdArr.push(id);
            _this.$cookies.set(`fromuser_${id}`, data);
          }
        });
      } else {
        _this.fromUserArr.push(_this.$cookies.get(`fromuser_${id}`));
      }
    },
    //获取用户信息
    getUser() {
      var _this = this;
      var toUserInfo;
      var userArr = [];
      // _this.fromUserArr = [];
      _this.chat_list.map((item) => {
        userArr.push(item.from_uid.toString());
      });
      var newArr = Array.from(new Set(userArr));
      console.log(newArr);
      // _this.fromIdArr = newArr;
      newArr.map((id) => {
        console.log(_this.fromIdArr.indexOf(id.toString()));
        if (_this.fromIdArr.indexOf(id.toString()) == -1) {
          if (!_this.$cookies.isKey(`fromuser_${id}`)) {
            var api = "/user/user_show/" + id;
            indexOp(api).then((res) => {
              if (res && res.status == 200) {
                var data = res.data;
                data.from_id = id;
                _this.fromUserArr.push(data);
                _this.fromIdArr.push(id);
                _this.$cookies.set(`fromuser_${id}`, data);
              }
            });
          } else {
            _this.fromIdArr.push(id);
            _this.fromUserArr.push(_this.$cookies.get(`fromuser_${id}`));
          }
        }
      });
      console.log(_this.fromUserArr);
    },
    //发送信息
    onSend() {
      var _this = this;
      // e.preventDefault();
      if (this.value == "") {
        _this.$toast("不能发送空白信息！");
        return;
      }
      _this.onSendMessage(_this.value, "text");
      this.value = "";
      this.style.width = "88%";
      this.showSend = false;
    },
    //获取聊天历史记录
    getChatLog() {
      let _this = this;
      var data = {
        chat_id: _this.chat_id,
      };
      indexOp("/im/chat_info", data).then((res) => {
        _this.chat_list = [];
        if (res && res.status == 200) {
          for (var key in res.data) {
            var data = res.data[key].message.data;
            data.id = res.data[key].id;
            data.status = res.data[key].status;
            _this.chat_list.push(data);
          }
          // if (res.data.length > 0) {
          //   _this.getUser();
          // } else {
          //   _this.getToUser();
          // }
          _this.scrollToBottom();
        } else {
          console.log(res);
          if (res.data.error == "您已经被清退当前聊天室，无法再次进入") {
            _this.$dialog
              .alert({
                title: "提示",
                message: "您已经被清退当前聊天室，无法再次进入",
                confirmButtonText: "确认",
              })
              .then(() => {
                _this.$router.go(-1);
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
            return;
          }
          //创建新房间
          var form_uid = _this.userInfo.id; //自己的 uid
          var to_uid = _this.to_uid; //聊天对象 uid
          var data = {
            params: {
              to_uid: to_uid,
              from_uid: form_uid,
              chat_type: "normal",
            },
          };
          addOp("/im/build_chat", data).then((res) => {
            console.log(res);
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                order_no: _this.order_no,
                to_uid: to_uid,
              },
            });
          });
        }
      });
    },
    //上传图片
    onUploadImg(value) {
      var _this = this;
      wx.chooseImage({
        count: 9, // 默认9
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          const tempFilePaths = res.localIds;
          console.log(tempFilePaths);
          _this.images = tempFilePaths;
          tempFilePaths.forEach((element) => {
            _this.WXuploadImage(element);
          });
        },
      });
      return;
      // var file = value.target.files[0];
      // var form_data = new FormData();
      // form_data.append("file", file);
      // _this.$toast.loading({
      // 	message: "上传中..",
      // 	forbidClick: true,
      // 	duration: 0,
      // });
      // uploadImg(form_data).then((res) => {
      // 	_this.$toast.clear();
      // 	if (res && res.status == 200) {
      // 		var imgUrl = _this.url + res.data.url;
      // 		_this.onSendMessage(imgUrl, "img");
      // 	} else {
      // 		_this.$toast.fail("发送失败！请重试。");
      // 	}
      // });
    },
    WXuploadImage(url) {
      const _this = this;
      wx.uploadImage({
        localId: url, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log(serverId);
          var data = {
            media_id: serverId,
          };
          indexOp("/download_file/wechat_media", data).then((res) => {
            if (res && res.status === 200 && res.data.state == 1000) {
              var imgUrl = res.data.data.url;
              _this.onSendMessage(imgUrl, "img");
            }
            console.log(res);
          });
          // _this.downloadImage(serverId)
        },
      });
    },
    //上传视频
    onUploadVideo(value) {
      var _this = this;
      var file = value.target.files[0];
      var form_data = new FormData();
      form_data.append("file", file);
      _this.$toast.loading({
        message: "上传中..",
        forbidClick: true,
        duration: 0,
      });
      uploadFile(form_data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200 && res.data.url) {
          var vidoeUrl = _this.url + res.data.url;
          _this.onSendMessage(vidoeUrl, "video");

          if (_this.userInfo.identity == 2) {
            _this.createVideo(res.data.url);
          }
        } else {
          if (res.data.message) {
            _this.$toast.fail(res.data.message);
          } else {
            _this.$toast.fail("上传失败！请重试。");
          }
        }
      });
    },
    //发送位置
    sendLocation() {
      var _this = this;
      _this.mapShow = true;
    },
    //地图选择
    onConfigMap(data) {
      this.mapShow = false;
      var msg = {
        poiname: data.poiname,
        poiaddress: data.poiaddress,
        lat: data.latlng.lat,
        lng: data.latlng.lng,
        cityname: data.cityname,
      };
      console.log(data);
      this.onSendMessage(msg, "local");
    },
    //关闭地图选择
    onCloseMap() {
      this.mapShow = false;
    },
    //查看地址详情
    onLocationDetail(data) {
      var _this = this;
      console.log(data);
      window.location.href = `https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${data.lat},${data.lng};title:${data.poiname};addr:${data.poiaddress}&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp`;
    },
    //发送订单
    onSendOrder() {
      var _this = this;
      var order_no = _this.$route.query.order_no;
      if (!order_no) {
        _this.$toast("暂无订单信息！");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        order_no: order_no,
      };
      indexOp("/service_order/show", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          var msg = {
            address: res.data.address,
            name: res.data.name,
            desc: res.data.desc,
            mobile: res.data.mobile,
            images: res.data.image,
            order_no: res.data.order_no,
          };
          _this.onSendMessage(msg, "order");
        }
      });
    },
    //发送语音
    sendVoice() {
      var _this = this;
      _this.voiceShow = true;
      _this.showMore = false;
    },
    startVoice() {
      var _this = this;
      if (_this.voiceText == "点击开始录制") {
        wx.startRecord({
          success: () => {
            _this.timer = setInterval(() => {
              _this.outTime++;
            }, 1000);
            _this.vicoeEnd();
            _this.voiceText = "点击结束";
          },
        });
      } else if (_this.voiceText == "点击结束") {
        wx.stopRecord({
          success: function (res) {
            // alert("暂停成功");
            console.log("成功:" + res.localId);
            _this.localId = res.localId;
            _this.voiceText = "点击播放";
            _this.playVoice = true;
            clearInterval(_this.timer);
          },
          fail: function (error) {
            // alert('死啦停不了')
            alert("失败：" + error);
          },
        });
      }
    },
    // 60秒监听
    vicoeEnd() {
      let _this = this;
      wx.onVoiceRecordEnd({
        // 录音时间超过一分钟没有停止的时候会执行 complete 回调
        complete: function (res) {
          console.log("60秒停止录音");
          clearInterval(_this.timer);
          _this.localId = res.localId;
          _this.voiceText = "点击播放";
          _this.playVoice = true;
          // _this.upVoice();
        },
      });
    },
    moveVoice() {
      console.log("move");
    },
    endVoice() {
      var _this = this;
      wx.stopRecord({
        success: function (res) {
          // alert("暂停成功");
          console.log("成功:" + res.localId);
          _this.localId = res.localId;
          _this.voiceText = "点击播放";
          _this.playVoice = true;
          clearInterval(_this.timer);
        },
        fail: function (error) {
          // alert('死啦停不了')
          alert("失败：" + error);
        },
      });
    },
    //播放刚录制语音
    onPlayVoice() {
      let _this = this;
      console.log(_this.localId);
      if (_this.stopVoice) {
        wx.stopVoice({
          localId: _this.localId, // 需要停止的音频的本地ID，由stopRecord接口获得
        });
        _this.stopVoice = !_this.stopVoice;
        _this.voiceText = "点击播放";
      } else {
        wx.playVoice({
          localId: _this.localId, // 需要播放的音频的本地ID，由stopRecord接口获得
        });
        _this.voiceText = "点击停止";
        _this.stopVoice = !_this.stopVoice;
        _this.onVoicePlayEnd();
      }
    },
    //自动监听播放结束
    onVoicePlayEnd() {
      var _this = this;
      wx.onVoicePlayEnd({
        success: function (res) {
          _this.localId = res.localId; // 返回音频的本地ID
          _this.stopVoice = !_this.stopVoice;
          _this.voiceText = "点击播放";
        },
      });
    },
    //取消录制
    voiceCancel() {
      var _this = this;
      _this.localId = "";
      _this.voiceText = "点击开始录制";
      _this.playVoice = false;
      _this.stopVoice = false;
      _this.outTime = 0;
      clearInterval(_this.timer);
      wx.stopRecord({
        success: function (res) {},
        fail: function (error) {},
      });
    },
    onClosePopup() {
      var _this = this;
      wx.stopRecord({
        success: function (res) {},
        fail: function (error) {},
      });
      _this.localId = "";
      _this.voiceText = "点击开始录制";
      _this.playVoice = false;
      _this.stopVoice = false;
      _this.outTime = 0;
      _this.voiceShow = false;
      _this.liststyle = {
        "padding-bottom": "100px",
      };
      clearInterval(_this.timer);
    },
    sendVoiceMsg() {
      var _this = this;
      _this.upVoice();
    },
    // 上传语音
    upVoice() {
      let _this = this;
      // alert(_this.localId)
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      wx.uploadVoice({
        localId: _this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          _this.serverId = res; // 返回音频的服务器端ID
          // alert(res.serverId)
          // _this.server_id =
          console.log("server_id:", res.serverId);
          var data = {
            server_id: res.serverId,
          };
          addOp("/uploads/upload_voice", data).then((res) => {
            _this.$toast.clear();
            var msg = {
              url: _this.url + "/" + res.data,
              time: _this.outTime,
            };
            _this.onSendMessage(msg, "voice");
            _this.localId = "";
            _this.voiceText = "点击开始录制";
            _this.playVoice = false;
            _this.stopVoice = false;
            _this.outTime = 0;
            _this.voiceShow = false;
            _this.liststyle = {
              "padding-bottom": "100px",
            };
            console.log(res);
          });
        },
      });
    },
    //发送消息
    onSendMessage(data, type) {
      var _this = this;
      // let date = new Date().toLocaleTimeString('chinese',{hour12:false});
      let date = new Date().toLocaleDateString();
      let time = new Date().toLocaleTimeString("chinese", {
        hour12: false,
      });
      let msg = {
        room: _this.chat_id,
        content: data, //内容
        type: type, //内容类型
        date: date + " " + time,
        from_uid: _this.userInfo.id,
      };
      console.log(msg);
      _this.socketApi.sendSock(msg, this.sendCallback);
    },
    //消息发送
    sendCallback(res) {
      var _this = this;
      var msg = res;
      console.log(res, "消息发送");

      // if (_this.fromIdArr.indexOf(msg.data.from_uid.toString()) == -1) {
      //   if (!_this.$cookies.isKey(`fromuser_${msg.data.from_uid}`)) {
      //     var api = "/user/user_show/" + msg.data.from_uid;
      //     indexOp(api).then((r) => {
      //       if (r && r.status == 200) {
      //         var data = r.data;
      //         data.from_id = msg.data.from_uid;
      //         _this.fromIdArr.push(msg.data.from_uid);
      //         _this.fromUserArr.push(data);
      //         _this.$cookies.set(`fromuser_${msg.data.from_uid}`, data);
      //       }
      //     });
      //   } else {
      //     _this.fromIdArr.push(msg.data.from_uid);
      //     _this.fromUserArr.push(
      //       _this.$cookies.get(`fromuser_${msg.data.from_uid}`)
      //     );
      //   }
      // }
      if (msg.data.room == _this.chat_id) {
        if (
          msg.data.type == "pay_door_money" ||
          msg.data.type == "pay_service_money" ||
          msg.data.type == "transfer"
        ) {
          if (
            msg.data.content.to_uid == _this.userInfo.id ||
            msg.data.content.from_uid == _this.userInfo.id
          ) {
            let tit =
              msg.data.type === "pay_door_money"
                ? "上门费用"
                : msg.data.type === "pay_service_money"
                ? "维修费用"
                : "转账";
            _this.$dialog
              .confirm({
                title: "提示",
                message: `收到${tit}${msg.data.content.money}元，该转账48小时后可提现到微信`,
                confirmButtonText: "确认",
              })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
          }
        }
        var message = res.data;
        message.id = res.chat_id;
        this.chat_list.push(message);
        _this.scrollToBottom();
      }
    },
    //消息接收
    messageCallback(res) {
      var _this = this;
      var msg = res;
      console.log(res, "消息接收");
      if (msg.data.room == _this.chat_id) {
        if (
          msg.data.type == "pay_door_money" ||
          msg.data.type == "pay_service_money" ||
          msg.data.type == "transfer"
        ) {
          if (
            msg.data.content.to_uid == _this.userInfo.id ||
            msg.data.content.from_uid == _this.userInfo.id
          ) {
            let tit =
              msg.data.type === "pay_door_money"
                ? "上门费用"
                : msg.data.type === "pay_service_money"
                ? "维修费用"
                : "转账";
            _this.$dialog
              .confirm({
                title: "提示",
                message: `收到${tit}${msg.data.content.money}元，该转账48小时后可提现到微信`,
                confirmButtonText: "确认",
              })
              .then(() => {
                // on confirm
              })
              .catch(() => {
                // on cancel
              });
          }
        }
        if (_this.fromIdArr.includes(msg.data.from_uid)) {
          _this.chat_list.push(msg.data);
          _this.scrollToBottom();
        } else {
          _this.getChatUsers();
          _this.chat_list.push(msg.data);
          _this.scrollToBottom();
        }
      }
      // if (msg.data.room == _this.chat_id) {
      //   if (_this.fromIdArr.indexOf(msg.data.from_uid.toString()) == -1) {
      //     if (!_this.$cookies.isKey(`fromuser_${msg.data.from_uid}`)) {
      //       var api = "/user/user_show/" + msg.data.from_uid;
      //       indexOp(api).then((r) => {
      //         if (r && r.status == 200) {
      //           var data = r.data;
      //           data.from_id = msg.data.from_uid;
      //           _this.$cookies.set(`fromuser_${msg.data.from_uid}`, data);
      //           _this.fromIdArr.push(msg.data.from_uid);
      //           _this.fromUserArr.push(data);
      //         }
      //       });
      //     } else {
      //       _this.fromIdArr.push(msg.data.from_uid);
      //       _this.fromUserArr.push(
      //         _this.$cookies.get(`fromuser_${msg.data.from_uid}`)
      //       );
      //     }
      //   }
      //   _this.chat_list.push(msg.data);
      //   _this.scrollToBottom();
      // }
    },
    onChange(value) {
      if (value) {
        this.style.width = "80%";
        this.showSend = true;
      } else {
        this.style.width = "88%";
        this.showSend = false;
      }
    },
    onFocus() {
      this.showMore = false;
      if (this.showMore) {
        this.liststyle = {
          "padding-bottom": "240px",
        };
      } else {
        this.liststyle = {
          "padding-bottom": "100px",
        };
      }
    },
    onMore() {
      this.showMore = !this.showMore;
      if (this.showMore) {
        this.liststyle = {
          "padding-bottom": "240px",
        };
      } else {
        this.liststyle = {
          "padding-bottom": "100px",
        };
      }
    },
    onPalyAudio(e, url) {
      var _this = this;
      // console.log(e);
      var url = url;
      if (_this.audioPlay) {
        _this.audio.src = url;
        _this.audio.play();
        _this.audioPlay = false;
        $("#bofang_" + e).hide();
        $("#zanting_" + e).show();
        _this.audio.addEventListener(
          "ended",
          function () {
            $("#bofang_" + e).show();
            $("#zanting_" + e).hide();
            console.log("over");
          },
          false
        );
      } else {
        $("#bofang_" + e).show();
        $("#zanting_" + e).hide();
        _this.audioPlay = true;
        _this.audio.pause();
      }
    },
    audioListener() {},
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getfromUser(id) {
      try {
        var _this = this;
        // if (!_this.$cookies.isKey(`fromuser_${id}`)) {
        var api = "/user/user_show/" + id;
        indexOp(api).then((res) => {
          if (res && res.status == 200) {
            var data = res.data;
            console.log(id);
            data.from_id = id;
            // _this.$cookies.set(`fromuser_${id}`, data);
            if (_this.title == "") {
              _this.title = res.data.nickname;
              _this.setShare();
              console.log(_this.title);
            }
            // console.log(_this.fromUserArr.indexOf(data)); // 2
          }
        });
        // } else {
        //   _this.title = _this.$cookies.get(`fromuser_${id}`).nickname;
        // }
      } catch (e) {
        // Error
      }
    },
    onDeleteChat(item, index) {
      var _this = this;
      console.log(item);
      var data = {
        id: item.id,
      };
      addOp("/im/destroy", data).then((res) => {
        if (res && res.status == 200) {
          _this.chat_list.splice(index, 1);
        }
      });
    },
    onShare() {
      this.$dialog
        .alert({
          title: "提示",
          message: "点击右上角 ··· 按钮分享给好友，即可邀请进入该聊天。",
          confirmButtonText: "知道了",
          allowHtml: true,
        })
        .then(() => {
          // on close
        });
    },
    getOrder() {
      var _this = this;
      var chatId = _this.chat_id.split("_");
      if (chatId.length > 1) {
        var order_no = chatId[0];
        var data = {
          order_no: order_no,
        };
        indexOp("/service_order/show", data).then((res) => {
          _this.orderInfo = res.data;
          _this.order_no = res.data.order_no;
          _this.getChatLog();
          // this.getToUser();
          var shareTitle = res.data.name + " ";
          var area = [];
          if (res.data.province_info) {
            area.push(res.data.province_info.area_name);
          }
          if (res.data.city_info) {
            area.push(res.data.city_info.area_name);
          }
          if (res.data.area_info) {
            area.push(res.data.area_info.area_name);
          }
          shareTitle += area.join("-");
          const shareInfo = {
            title: shareTitle,
            desc: `在线聊天，记录着师傅的检修、维修过程。`,
            link:
              base.shareURL +
              "/chat?chat_id=" +
              _this.chat_id +
              "&to_uid=" +
              _this.to_uid +
              "&order_no=" +
              _this.order_no,
            img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
          };
          _this.wxChat(shareInfo);
        });
      } else {
        _this.getChatLog();
        _this.getfromUser(_this.to_uid);
      }
    },
    onLogoutChat() {
      var _this = this;
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出该聊天吗？",
          confirmButtonText: "确认",
          allowHtml: true,
        })
        .then(() => {
          var data = {
            chat_id: _this.chat_id,
          };
          addOp("/im/logout_chat", data).then((res) => {
            if (res && res.data == 1) {
              _this.$router.push("/user");
            }
          });
          // on close
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push("/");
    },
    toUser(item) {
      var _this = this;
      console.log(item);
      if (_this.orderInfo && _this.orderInfo.uid == item.from_uid) {
        _this.$router.push({
          path: "/RepairDetail",
          query: {
            order_no: _this.orderInfo.order_no,
          },
        });
        return;
      }
      var data = {
        uid: item.from_uid,
      };
      indexOp("/service_user/show", data).then((res) => {
        if (res && res.status == 200 && res.data) {
          _this.$router.push({
            path: "/shifuShopDetail",
            query: {
              uid: item.from_uid,
            },
          });
        } else {
          _this.$toast.fail("该用户暂无店铺信息。");
        }
        console.log(res);
      });
    },
  },
  components: {
    Tabber,
    Navbar,
    MapChoice,
    Driver,
  },
};
</script>

<style scoped>
#app {
  background: #fff;
}
</style>
