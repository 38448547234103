import { render, staticRenderFns } from "./shifu_bidding_end.vue?vue&type=template&id=30a2acdc&scoped=true"
import script from "./shifu_bidding_end.vue?vue&type=script&lang=js"
export * from "./shifu_bidding_end.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a2acdc",
  null
  
)

export default component.exports