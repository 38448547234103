<template>
  <div class="chat-list container">
    <Navbar :title="'我的聊天'"></Navbar>
    <div class="list">
      <van-swipe-cell v-for="(item, index) in list" :key="index">
        <router-link
          class="item"
          :to="{
            path: '/chat',
            query: {
              chat_id: item.chat_id,
              from_uid: item.from_uid,
              to_uid: item.to_uid,
            },
          }"
        >
          <template v-if="item.chat_user.length > 2">
            <div class="img-box">
              <img
                class="avatar"
                v-for="(src, idx) in item.chat_user"
                :key="idx"
                :src="src.avatar"
                alt=""
              />
            </div>
            <div class="right">
              <div class="name-box">
                <p class="name">
                  <template
                    v-for="(src, index) in item.chat_user"
                    v-if="uid != src.id"
                    >{{ src.nickname }}、</template
                  >
                </p>
              </div>

              <p class="time">{{ item.updated_at }}</p>
            </div>
          </template>
          <template v-else>
            <div class="imgs">
              <template v-for="src in item.chat_user">
                <img
                  class="avatar"
                  v-if="uid != src.id"
                  :src="src.avatar"
                  alt=""
                />
              </template>
            </div>
            <div class="right">
              <div v-for="(src, index) in item.chat_user" :key="index">
                <p class="name" v-if="uid != src.id">{{ src.nickname }}</p>
              </div>

              <p class="time">{{ item.updated_at }}</p>
            </div>
          </template>
          <van-tag
            class="tag"
            round
            type="danger"
            v-if="item.unread_count > 0"
            >{{ item.unread_count }}</van-tag
          >
        </router-link>
        <template #right>
          <van-button
            @click="onLogoutChat(item.chat_id)"
            square
            text="删除"
            type="danger"
            class="delete-button"
            style="height: 100%"
          />
        </template>
      </van-swipe-cell>
      <div class="empty" v-if="list.length == 0">
        <van-empty image-size="100" description="暂无对话" />
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
import { loginAlert } from "../utils/loginAlert";
export default {
  inject: ["reload"],
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      list: "", //对话列表
      uid: "",
      avatarList: [],
    };
  },
  computed: {},
  created() {
    var _this = this;
    if (this.$cookies.isKey("userInfo")) {
      this.uid = this.$cookies.get("userInfo").id;
    } else {
      loginAlert()
      return;
    }
    this.getCathList();
  },
  mounted() {},
  watch: {},
  methods: {
    getCathList() {
      var _this = this;
      var data = {
        uid: _this.uid,
      };
      indexOp("/im/get_chat", data).then((res) => {
        if (res && res.status == 200) {
          _this.list = res.data;
          console.log(res);
        }
      });
    },
    onLogoutChat(chat_id) {
      var _this = this;
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要删除该聊天吗？",
          confirmButtonText: "确认",
          allowHtml: true,
        })
        .then(() => {
          var data = {
            chat_id: chat_id,
          };
          addOp("/im/logout_chat", data).then((res) => {
            if (res && res.data == 1) {
              _this.$toast.success("删除成功！");
              _this.reload();
            }
          });
          // on close
        });
    },
  },
};
</script>
