<template>
  <div class="sponsor-index sponsor-user">
    <Navbar :title="'主办方个人中心'"></Navbar>
    <div class="organiser">
      <div class="bg">
        <div class="info" v-if="info.company_name == ''">
          <div class="left">
            <img class="avatar" src="../../assets/image/avatar.png" alt="" />
          </div>
          <div class="right">
            <div class="title">
              欢迎加入（冷鲜柜网）会展中心<br />媒体合作，互相传播
            </div>
            <div class="title">请完善你们主办方信息</div>
          </div>
        </div>
        <div class="info" v-else>
          <div class="status-box" v-if="info.status == 'SUCCESS'">
            {{ info.status_text }}
          </div>
          <div
            class="status-box"
            v-if="info.status == 'WAITING'"
            style="background: #d9d9d9"
          >
            {{ info.status_text }}
          </div>
          <div class="status-box" v-if="info.status == 'FAIL'">
            {{ info.status_text }}
          </div>
          <div class="left">
            <img class="avatar" :src="baseUrl + info.company_logo" alt="" />
          </div>
          <div class="right">
            <div class="title">主办方名称：{{ info.company_name }}</div>
            <div class="address">主办方地址：{{ info.company_address }}</div>
            <div class="address">
              <span>联系方式：{{ info.company_phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-box">
      <div class="input-item">
        <div class="label">企业名称：</div>
        <input
          class="input"
          type="text"
          placeholder=""
          v-model="company_name"
          :readonly="info.status == 'WAITING'"
        />
      </div>
      <div class="input-item">
        <div class="label">企业简介：</div>
        <textarea
          class="input"
          type="text"
          placeholder=""
          style="height: 50px"
          v-model="company_desc"
          :readonly="info.status == 'WAITING'"
        ></textarea>
      </div>
      <div class="input-item">
        <div class="label">企业资质：</div>
        <van-uploader
          multiple
          v-model="picImgList"
          :max-count="1"
          :after-read="afterReadPic"
          :accept="'image/*'"
          :disabled="info.status == 'WAITING'"
        />
      </div>
      <div class="input-item">
        <div class="label">企业Logo：</div>
        <van-uploader
          v-model="logoImgList"
          :max-count="1"
          :after-read="afterReadLogo"
          :accept="'image/*'"
          :disabled="info.status == 'WAITING'"
        />
      </div>
      <div class="input-item">
        <div class="label">联系方式：</div>
        <input
          class="input"
          type="text"
          placeholder=""
          v-model="company_phone"
          :readonly="info.status == 'WAITING'"
        />
      </div>
      <div class="input-item">
        <div class="label">所在城市：</div>
        <input
          class="input"
          type="text"
          placeholder=""
          v-model="address"
          readonly
          v-if="info.status == 'WAITING'"
        />
        <input
          class="input"
          type="text"
          placeholder=""
          v-model="address"
          readonly
          @click="showCityPicker = true"
          v-else
        />
        <van-popup v-model="showCityPicker" round position="bottom">
          <van-area
            title="选择地址"
            :area-list="areaList"
            @cancel="showCityPicker = false"
            @confirm="onCityConfirm"
          />
        </van-popup>
      </div>
      <div class="input-item">
        <div class="label">详细地址：</div>
        <textarea
          class="input"
          type="text"
          placeholder=""
          style="height: 50px"
          v-model="company_address"
          :readonly="info.status == 'WAITING'"
        ></textarea>
      </div>

      <div class="tip-box">
        <div>友情提示：</div>
        <div style="text-indent: 20px">
          请保证您填写的资料真实有效，我们将会为您严格保密，提交审核后耐心等待，资料准确无误的前提下，一般三个工作日内将会审核通过，请耐心等待。
        </div>
      </div>

      <div class="btn-box">
        <div class="sub-btn" v-if="info.status == 'WAITING'">
          等待审核，不允许修改
        </div>
        <div
          class="sub-btn"
          @click="onSubmit"
          v-else-if="info.status == 'SUCCESS'"
        >
          修改资料，重新提交
        </div>
        <div
          class="sub-btn"
          @click="onSubmit"
          v-else-if="info.status == 'FAIL'"
        >
          修改资料，重新提交
        </div>
        <div class="sub-btn" @click="onSubmit" v-else>提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import province_list from "../../utils/area";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      afterRead: "",
      radio: "",
      areaList: province_list,
      logoImgList: [],
      picImgList: [],

      company_name: "", //公司名称
      company_phone: "", //联系方式
      company_address: "", //公司地址
      company_desc: "", //公司简介
      company_logo: "", //公司Logo
      company_province: "", //公司地址-省
      company_city: "", //公司地址-市
      company_area: "", //公司地址-区
      company_pic: "",

      showCityPicker: false,
      address: "",
      info: {
        company_name: "",
      },
      baseUrl: base.apiURL,
      loginURL: base.loginURL,
      userInfo: {
        id: "",
      },
      invate_uid: "",
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    if (this.userInfo.id == "") {
      loginAlert()
    }
    this.getInfo();
    this.setShare();
    this.wxLogin();
  },
  mounted() {},
  watch: {},
  methods: {
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-会展中心`,
        desc: `媒体合作，互相传播，资源共享，共同发展！`,
        link: base.shareURL + "/sponsorUserEdit",
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    wxLogin() {
      var code = this.getUrlKey("code");
      var state = this.getUrlKey("state");
      if (!this.$cookies.isKey("Authorization") && code) {
        login(this.getUser);
      }
    },
    getUser(userInfo) {
      this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //获取信息
    getInfo() {
      var _this = this;
      indexOp("/expo_sponsor_user/show").then((res) => {
        if (res && res.data.state == 1000) {
          _this.info = res.data.data;

          _this.company_name = res.data.data.company_name;
          _this.company_phone = res.data.data.company_phone;
          _this.company_address = res.data.data.company_address;
          _this.company_desc = res.data.data.company_desc;
          _this.company_logo = res.data.data.company_logo;
          _this.company_province = res.data.data.company_province;
          _this.company_city = res.data.data.company_city;
          _this.company_area = res.data.data.company_area;
          _this.company_pic = res.data.data.company_pic;
          _this.address =
            res.data.data.company_province +
            "-" +
            res.data.data.company_city +
            "-" +
            res.data.data.company_area;
          if (res.data.data.company_pic) {
            var pic_img = {
              url: _this.baseUrl + res.data.data.company_pic,
            };
            _this.picImgList.push(pic_img);
          }
          if (res.data.data.company_logo) {
            var logo_img = {
              url: _this.baseUrl + res.data.data.company_logo,
            };
            _this.logoImgList.push(logo_img);
          }
        }
        console.log(_this.info);
      });
    },
    onSubmit() {
      var _this = this;
      if (_this.company_name == "") {
        _this.$toast.fail("企业名称不能为空！");
        return;
      }
      if (_this.company_desc == "") {
        _this.$toast.fail("企业简介不能为空！");
        return;
      }
      if (_this.company_phone == "") {
        _this.$toast.fail("联系方式不能为空！");
        return;
      }
      if (_this.company_province == "") {
        _this.$toast.fail("请选择所在地区！");
        return;
      }
      if (_this.company_address == "") {
        _this.$toast.fail("详细地址不能为空！");
        return;
      }
      var data = {
        company_name: _this.company_name,
        company_phone: _this.company_phone,
        company_desc: _this.company_desc,
        company_province: _this.company_province,
        company_city: _this.company_city,
        company_area: _this.company_area,
        company_address: _this.company_address,
        company_logo: _this.company_logo,
        company_pic: _this.company_pic,
      };
      console.log(data);
      // return;
      addOp("/expo_sponsor_user/store", data).then((res) => {
        if (res && res.data.state == 1000) {
          _this.$toast.success(res.data.msg);
          setTimeout(() => {
            _this.$router.push("/sponsorUser");
          }, 800);
        } else {
          _this.$toast.fail(res.data.msg);
        }
        console.log(res);
      });
    },
    //选择城市
    onCityConfirm(value, index) {
      this.showCityPicker = false;
      this.company_province = value[0].name;
      this.company_city = value[1].name;
      this.company_area = value[2].name;
      this.address = value[0].name + "-" + value[1].name + "-" + value[2].name;
      this.company_address =
        value[0].name + value[1].name + value[2].name + " ";
    },
    //logo图片上传
    afterReadLogo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.company_logo = res.data.url;
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //资质图片上传
    afterReadPic(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.company_pic = res.data.url;
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
  },
};
</script>

<style scoped >
</style>
