<template>
	<div class="zulin" ref="zulin">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">冷柜租用</span>
			<span style="float:right;font-size: 14px;" @click="ruleShow=true" >租赁规则</span>
		</div>
		<!-- <div class="top_title">
            <h3>病毒来袭，强势抗“疫”</h3>
        </div> -->
		<!-- 会员判断 -->
		<!-- <div class="is-vip" :class="{'not-vip': !isVip}" @click="goToVip">
            {{isVip ? '您是平台黄金会员，最长可以免费使用两个月' : '您还不是平台黄金会员，租赁需自费，点击开通会员'}}
        </div> -->
		<van-notice-bar left-icon="volume-o" text="点击查看已提交租赁记录" mode="link" @click="recordShow=true;loadRecordList()" />

		<van-popup round v-model="recordShow" position="bottom" closeable>
			<div class="zl-record-container">
				
				<div class="zl-list">
					<block v-if="recordList && recordList.length">
						<van-cell-group inset>
							<van-cell title="单元格" v-for="(item,index) in recordList">
								<template #title>
									<p style="font-weight: bold;font-size:14px;">{{item.created_at}}</p>
								</template>
								<template #label>
									<p style="font-size:14px;font-weight:bold;color:#000!important;">
										租赁商品：
									</p>
									<block v-if="item.goodsInfo">
										<p v-for="(gitem,gindex) in item.goodsInfo">
											{{gitem.title}}
										</p>
									</block>
								</template>

								<div>
									<van-tag mark type="danger" v-if="item.status == '0'">待审核</van-tag>
									<van-tag mark type="primary" v-if="item.status == '1'">进行中</van-tag>
									<van-tag mark type="primary" v-if="item.status == '2'">已完成</van-tag>
									<van-tag mark type="warning" v-if="item.status == '3'">已驳回</van-tag>
								</div>
								<div>
									<van-button @click="dowloadContract(item)" type="primary" size="small">合同下载</van-button>
								</div>

							</van-cell>
						</van-cell-group>
					</block>
					<block v-else>
						<van-empty description="暂无数据" />
					</block>
				</div>
			</div>
		</van-popup>

		<van-popup round v-model="detailShow" position="bottom" closeable>
			<div class="zl-record-container" style="padding-top:0px;overflow-y: hidden;">
				<div class="zl-title">
					详情
				</div>
				<div class="content zl-content" @click="vhtmlPreviewImage($event)" style="padding-top:10px;"
					v-if="detailContent" v-html="detailContent">
				</div>
			</div>
		</van-popup>

		<!-- 选择冷柜 -->
		<div class="zulin-box">
			<div class="select-box">
				<div class="select-box-title" style="font-weight: bold;text-align: center;">选择想要租赁的冷柜</div>
				<div class="select-box-main">
					<van-checkbox-group v-model="formData.goods_id">
						<van-checkbox :name="item.id" v-for="(item,index) in goodsList">
							<div class="select-box-item" style="margin-bottom:20px;">
								<img :src="url + item.image" alt="">
								<div class="select-box-item-info">
									<p>{{item.title}}</p>
									<p>可租数量：{{item.can_zu_number}}</p>
								</div>
								<div class="select-box-item-button" @click.stop="viewDetail(item)">查看详情</div>
							</div>
						</van-checkbox>
					</van-checkbox-group>
				</div>
			</div>
		</div>
		<!-- 租赁信息 -->
		<div class="zulin-box form" v-if="formShow">
			<div class="select-box">
				<div class="select-box-title" style="font-weight: bold;text-align:center;">租赁信息</div>
				<div class="select-box-main">
					<van-form>
						<van-field v-model="formData.truename" name="truename" label="租赁人" maxlength="10"
							placeholder="请输入租赁人姓名" :disabled="!isVip" />
						<van-field v-model="formData.mobile" type="text" name="mobile" label="手机号码" maxlength="11"
							placeholder="请输入手机号码" :disabled="!isVip" />
					</van-form>
					<p @click="onSubmit"
						style="width:100%;height:60px;background:#0186f3;color:#fff;width:100%;height:40px;display:flex;align-items: center;justify-content: center;margin:0 auto;margin-top:10px;border-radius:5px;margin-top:20px;">
						立即提交</p>
					<p @click="formShow=false"
						style="width:100%;height:60px;background:#9ba0a5;color:#fff;width:100%;height:40px;display:flex;align-items: center;justify-content: center;margin:0 auto;margin-top:10px;border-radius:5px;margin-top:10px;">
						关闭</p>
				</div>
			</div>
		</div>
		<div class="marsk" v-if="ruleShow || formShow"></div>
		<!-- 租赁规则 -->
		<div class="rule" v-if="ruleShow">
			<h3 style="text-align:center;font-weight:bold;">租赁规则</h3>
			<p>1. 本次活动仅针对郑州及其周边区域。</p>
			<p>2. 免费使用仅针对平台黄金会员。</p>
			<p>3. 非黄金会员可以在开通黄金会员后申请，或者自费租赁，自费租赁请直接联系冷柜哥（微信号：18838032012）或者
				<span @click="viewUserCode" style="color:#0186f3;font-weight: 700;"> 点击此处 </span>
				长按识别图中二维码添加冷柜哥微信。
			</p>
			<p>4. 自费租赁的费用需要在取货现场根据设备新旧确认。</p>
			<p>5. 租赁需自行到平台仓库取走冷柜或者物流到付。</p>
			<p>6. 自租赁方收到冷柜后直到冷柜运输返回到平台仓库之间，冷柜的非质量问题损坏需要租赁方承担维修费用。</p>
			<p>7. 使用时间：2022-12-22 ~ 2023-2-22</p>
			<p>8. 租赁到期之前，需要自行把冷柜送到平台仓库。</p>
			<p>9. 最终解释权归平台所有。</p>
			<p @click="ruleShow=false"
				style="width:10vw;height:60px;background:#0186f3;color:#fff;width:80px;height:35px;display:flex;align-items: center;justify-content: center;margin:0 auto;margin-top:10px;border-radius:5px;">
				我知道了</p>
		</div>
		<!-- 设备详情 -->
		<div style="height:90px"></div>
		<!-- 底部bar -->
		<div class="bottom-bar">
			<div class="bottom-bar-left">
				<p style="height: 100%;
    display: flex;
    align-items: center;">已选择：<span>{{ formData.goods_id.length }}个商品</span></p>
				<!-- <p>租期：{{ formData.time || '未选择租赁时间' }}</p> -->
			</div>
			<div class="bottom-bar-button" @click="confirmUpload">下一步</div>
		</div>
	</div>
</template>
<script>
	import Navbar from "../components/Navbar";
	import province_list from "../utils/area";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		addOp,
		indexOp
	} from "../api/index";
	import {
		ImagePreview
	} from 'vant';
	import {
		parseTime
	} from '../utils/time'
	import base from "../api/base.js"
	export default {
		data() {
			return {
				formData: {
					goods_id: '',
					truename: "",
					mobile: "",
					goods_id: []
				},
				value: "",
				showArea: false,
				areaList: province_list, // 数据格式见 Area 组件文档
				showIdentityPicker: false,
				identity: ["终端用户", "制冷厂商", "维修师傅", "模具配件"],
				userInfo: {},
				isVip: false,
				selectTime: false,
				minDate: new Date(2022, 11, 22),
				maxDate: new Date(2023, 1, 22),
				scrollTimer: null,

				ruleShow: false,
				formShow: false,
				goodsList: null,
				url: base.apiURL,
				
				recordShow: false,
				recordList: null,
				
				detailShow : false,
				detailContent : null,
			};
		},
		components: {
			Navbar,
		},
		methods: {
			viewDetail(item) {
				this.detailContent 	=	item.content;
				this.detailShow 	=	true;
			},
			onConfirmTime(e) {
				this.formData.time = `${parseTime(e[0], '{y}-{m}-{d}')} ~ ${parseTime(e[1], '{y}-{m}-{d}')}`
				this.formData.startTime = parseTime(e[0], '{y}-{m}-{d}')
				this.formData.endTime = parseTime(e[1], '{y}-{m}-{d}')
				this.selectTime = false
			},
			confirmUpload() {
				console.log(this.formData);
				if (this.formData.goods_id.length < 1) {
					this.$toast.fail('请选择想要租赁的商品');
					return false;
				}

				this.formShow = true;
				return false;

				// 确认提交
				if (this.isVip) {
					this.onSubmit()
				} else {
					this.$dialog.confirm({
						title: "提示",
						message: "您还未开通黄金会员，非平台黄金会员租赁冷柜需要支付租赁费用，建议您开通黄金会员后提交！",
						confirmButtonText: "去开通会员",
						cancelButtonText: "取消",
					}).then(() => {
						this.$router.push({
							path: "/vip",
							query: {
								back: 'zulin'
							}
						});
					}).catch(() => {

					})
				}
			},
			goToVip() {
				if (this.isVip) {
					return false
				}
				this.$router.push({
					path: "/vip",
					query: {
						back: 'zulin'
					}
				})
			},
			viewUserCode() {
				ImagePreview(['http://api.zglxgw.com/uploads/images/20221225/63a826bc4b28d.jpg']);
			},
			setShare() {
				const shareInfo = {
					title: `【冷柜免费用-冷鲜柜网`,
					desc: `疫情当下，冷鲜柜网为助力经济复苏，特批备用冷柜供有需求者使用，冷柜免费使用两个月，不收租赁费用，如有需要点击提交申请。`,
					link: "http://www.zglxgw.com/zulin",
					img: "http://api.zglxgw.com/uploads/images/20221225/63a80e5b5eb21.jpg",
				};
				wechatConfig(shareInfo);
			},
			toBack() {
				this.$router.go(-1);
				this.$router.push('/');
			},
			onSubmit() {
				var _this = this;
				if (this.formData.truename == "") {
					this.$toast.fail("真实姓名不能为空！");
					return;
				}
				if (this.formData.mobile == "") {
					this.$toast.fail("手机号码不能为空！");
					return;
				}
				this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var tmp = this.formData;
				tmp.goods_id = tmp.goods_id.join(',');
				addOp("/zulin/add", tmp).then((res) => {
					this.$toast.clear();
					if (res && res.status == 201) {
						localStorage.setItem('isAddZulin', 1)
						this.$toast.success("提交成功！请耐心等待客服联系");
						setTimeout(() => {
							_this.formShow = false;
						}, 1000);
					} else {
						this.$toast.fail("提交失败，请稍后重试");
					}
				});
			},

			onConfirmIdent(value) {
				console.log(value);
				this.formData.chooseIdentity = value;
				this.showIdentityPicker = false;
			},
			getGoodsList() {
				var _this = this;
				indexOp('/zulin_goods/get').then((res) => {
					console.log('===================');
					console.log('Res:', res);
					_this.goodsList = res.data.rows;
				});
			},
			loadRecordList() {
				var _this = this;
				indexOp('/zulin/get').then((res) => {
					console.log('===================');
					console.log('Res:', res);
					_this.recordList = res.data.rows;
				});
			},
			dowloadContract(item){
				if(!item.contract){
					this.$toast.fail("暂无合同数据");
					return false;
				}
				window.open(item.contract);
			}
		},
		created() {
			this.setShare()
			var token = this.$cookies.get("Authorization");
			this.getGoodsList();
			this.isVip = true;
			if (!token) {
				this.$dialog.confirm({
						title: "提示",
						message: "您还未登录，请先去登陆！",
						confirmButtonText: "去登陆",
						cancelButtonText: "取消",
					}).then(() => {
						this.$router.push({
							path: "/login",
							query: {
								back: 'zulin'
							}
						});
					})
					.catch(() => {
						this.$router.back();
					})
				return;
			} else {
				if (this.$cookies.isKey("userInfo")) {
					this.userInfo = this.$cookies.get("userInfo");
					// if (this.userInfo.user_vip_info && this.userInfo.user_vip_info.vip_level == 2) {
					// 	this.isVip = true
					// } else {
					// 	this.$dialog.confirm({
					// 		title: "温馨提示",
					// 		message: '该活动仅针对平台黄金会员，您还不是黄金会员，请先开通黄金会员。',
					// 		confirmButtonText: "去开通",
					// 		cancelButtonText: "取消",
					// 	}).then(() => {
					// 		this.$router.push("/vip");
					// 	}).catch(() => {

					// 	});
					// }
				}
			}

		},
	};
</script>
<style lang="less" scoped>
	.zulin {
		background: url("../assets/image/yqbg.jpg") no-repeat;
		background-position: center;
		height: 100%;
		width: 100%;
		overflow-y: auto;
		background-size: cover;
		position: fixed;

		.top_title {
			text-align: center;
			color: #fff;
		}

		&-box {
			width: calc(100% - 30px);
			padding: 20px 10px 10px 10px;
			margin: 15px auto;
			background: #ebf5ff;
			border-radius: 14px;
			border: 3px solid #117de9;
		}

		&-detail {
			width: calc(100% - 30px);
			margin: 15px auto;
			background: #117de9;
			border-radius: 14px;
			border: 3px solid #117de9;
			font-size: 0;
			overflow: hidden;

			>img {
				width: 100%
			}

			.select-box-title {
				text-align: center;
				line-height: 50px;
				background-color: #ebf5ff;
			}
		}
	}

	.marsk {
		background: rgba(0, 0, 0, 0.8);
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
	}

	.rule {
		padding: 0 14px;
		margin-top: 10px;
		margin: 0 15px;
		width: calc(100% - 30px);
		background-color: #ebf5ff;
		border-radius: 14px;
		border: 3px solid #117de9;
		position: fixed;
		left: 0;
		top: 15vh;
		height: 75vh;
		overflow: hidden;
		overflow-y: scroll;
		z-index: 1001;

		h3 {
			font-size: 14px;
			margin-bottom: 10px;
			color: #444;
			font-weight: 500;
		}

		p {
			margin: 5px;
			font-size: 14px;
			color: #444;
			line-height: 26px;
			margin: 4px 0;
		}
	}

	.select-box {
		margin: 0 4px 14px 4px;
		width: calc(100% - 8px);


		&-title {
			font-weight: 500;
			color: #444;
			font-size: 14px;
		}

		&-main {
			margin-top: 16px;
		}

		&-item {
			display: flex;
			align-items: center;

			>img {
				width: 100px;
				height: 60px;
				border-radius: 10px;
				object-fit: cover;
			}

			&-info {
				flex: 1;
				margin-right: 10px;
				margin-left: 10px;

				p {
					&:nth-of-type(1) {
						font-size: 16px;
						color: #333;
						margin-bottom: 5px;
						font-weight: 700;
					}

					&:nth-of-type(2) {
						font-size: 14px;
						color: #888;
					}
				}
			}

			&-button {
				height: 50px;
				line-height: 50px;
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				color: #1989fa;
				padding-right: 10px
			}
		}
	}

	.bottom-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		display: flex;
		padding: 14px 20px;
		box-sizing: border-box;
		background-color: #fff;
		z-index: 999;
		border-top: 1px solid #f1f1f1;

		&-left {
			flex: 1;

			p {
				&:nth-of-type(1) {
					font-size: 16px;
					color: #333;
					margin-bottom: 5px;
					font-weight: 700;

					span {
						color: #1989fa
					}
				}

				&:nth-of-type(2) {
					font-size: 14px;
					color: #888;
				}
			}
		}

		&-button {
			width: 120px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			color: #fff;
			background-color: #1989fa;
			border-radius: 100px;
		}
	}

	.form {
		position: fixed;
		left: 15px;
		top: 25vh;
		height: 50vh;
		overflow: hidden;
		overflow-y: scroll;
		z-index: 1001;
	}

	.is-vip {
		margin: 15px auto;
		width: calc(100% - 30px);
		padding: 14px;
		box-sizing: border-box;
		border: 3px solid #106dca;
		background-color: #ebf5ff;
		color: #1989fa;
		font-size: 14px;
		border-radius: 14px;
		font-weight: 700;
	}

	.not-vip {
		border-color: #eb9a3f;
		background-color: #fff9f1;
		color: #eb9a3f;
	}
</style>
<style lang="less">
	.zulin {
		.van-radio {
			margin-bottom: 16px;
			border: 2px solid #dcebfa;
			border-radius: 14px;
			padding: 10px;
			box-sizing: border-box;
		}

		.van-radio[aria-checked=true] {
			border-color: #1989fa;
			background-color: #dcebfa;
		}

		.van-radio__label {
			flex: 1
		}

		.van-cell {
			background-color: #dcebfa !important;
			margin-bottom: 14px;
			border-radius: 8px;
		}

		.van-field__label {
			color: #333 !important;
			font-weight: 700;
			font-size: 14px;
		}

		.van-field__control {
			color: #333 !important;
			font-size: 14px;
		}

		input.van-field__control::-webkit-input-placeholder {
			color: #888;
		}
	}
</style>