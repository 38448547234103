<template>
</template>

<script>
	import "driver.js/dist/driver.min.css";
	import DriverObject from "driver.js";
	const t = 1;
	export default {
		name : 'Driver',
		props: {
			storageName: {
				type: String,
				default: ''
			},
			steps: {
				type: Array,
				default: []
			},
		},
		data() {
			return {

			};
		},
		computed: {

		},
		created() {
			let flag = localStorage.getItem(this.storageName);
			console.log('flag:',flag);
			if (this.steps) {
				this.showTips();
			}
		},
		mounted() {

		},
		watch: {

		},
		methods: {
			toHome() {
				this.$router.push("/")
			},
			showTips() {
				var that 	=	this;
				const driver = new DriverObject({
					className: 'driverClass',
					animate: false, 
					nextBtnText:'下一步',
					prevBtnText : '上一步',
					doneBtnText : '完成',
					closeBtnText : '关闭',
					onNext(e){
						var status 	=	driver.hasNextStep();
						if(!status){
							that.$emit('complete');
						}
					},
					onReset(e){
						that.$emit('complete');
					}
				});
				driver.defineSteps(this.steps);
				driver.start();
				
				
			}
		},
		components: {

		}

	}
</script>
<style scoped>
	
</style>