import http from './request'
import base from '../api/base'
import VueCookies from 'vue-cookies'
import Storage from "./Storage";
// import wx from "weixin-js-sdk";
//自定义分享
var wxChat = wx
export function isIOS() {
	let isIphone = navigator.userAgent.includes("iPhone");
	let isIpad = navigator.userAgent.includes("iPad");
	return isIphone || isIpad;
}
export function wechatConfig(info) {
	const _that = this;
	//判断苹果手机
	let _url = "";
	if (window.__wxjs_is_wkwebview === true) {
		_url = window.location.href.split("#")[0] || window.location.href;
	} else {
		_url = window.location.href;
	}
	//发起请求
	var data = {
		url: _url,
	};
	console.log('Data:',data);
	// developer
	// data.url 	=	'http://www.zglxgw.com';
	
	http
		.post("http://api.zglxgw.com/getSign", data)
		.then(function(res) {
			wxChat.config({
				debug: false,
				appId: res.data.appId, // 必填，公众号的唯一标识
				timestamp: res.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
				signature: res.data.signature, // 必填，签名，见附录1
				jsApiList: [
					"startRecord",
					"stopRecord",
					"playVoice",
					"pauseVoice",
					"stopVoice",
					"uploadVoice",
					"downloadVoice",
					"downloadVoice",
					"getLocation",
					"updateAppMessageShareData",
					"updateTimelineShareData",
					"chooseImage",
					"uploadImage",
					"downloadImage",
					"getLocalImgData",
					"onMenuShareAppMessage"
				], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
			});

			wxChat.ready(() => {
				console.log('ready',Storage.get('LocationInfo'));
				// 如果存在就不获取位置了
				if( !Storage.get('LocationInfo') ){
					wxChat.getLocation({
						type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
						success: function(res) {
							var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
							var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
							var speed = res.speed; // 速度，以米/每秒计
							var accuracy = res.accuracy; // 位置精度
							// alert("lat:"+latitude+"lng:"+longitude )
							VueCookies.set("LocationInfo", res)
							// 给storage存一份地理位置
							Storage.set('LocationInfo', res);
							setUser(res)
							// console.log(res)
						},fail(err){
							console.log('err:',err);
						}
					});
				}

				const share_title = info.title;
				const share_desc = info.desc;
				const share_link = info.link;
				const share_img = info.img;
				// wx.showOptionMenu();
				wxChat.updateTimelineShareData({
					title: share_title, // 分享标题
					link: share_link, // 分享链接
					imgUrl: share_img, // 分享图标
					success: function() {
						// console.log("分享成功")
						// alert("成功！")
					}
				});
				wxChat.updateAppMessageShareData({
					title: share_title, // 分享标题
					desc: share_desc, // 分享描述
					link: share_link, // 分享链接
					imgUrl: share_img, // 分享图标
					success: function() {
						// console.log("设置成功")
						// alert("成功！")
					}
				});
			});
			wxChat.error(function(res) {
				// console.log(res)
				// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
			});
		})
		.catch(function() {});
}

function setUser(res) {
	// console.log(123)
	if (VueCookies.isKey("Authorization")) {
		var data = {
			latitude: res.latitude,
			longitude: res.longitude,
		}
		http.post("/user/update/1", data).then(res => {})
	} else {

		// console.log(456)
	}
}

// wechatConfig();