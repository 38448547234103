<template>
  <div class="invitereg">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">邀请好友</span>
    </div>
    <div class="top_title">
      <h3>聚制冷精英，集行业资源</h3>
      <p>方便您我他</p>
    </div>
    <div class="inviterForm">
      <van-form @submit="onSubmit">
        <van-field
          v-model="formData.truename"
          name="truename"
          label="真实姓名"
          placeholder="请输入真实姓名"
        />
        <van-field
          v-model="formData.mobile"
          type="text"
          name="mobile"
          label="电话号码"
          placeholder="请输入电话号码"
        />
        <van-field name="sex" label="性别">
          <template #input>
            <van-radio-group
              v-model="formData.sex"
              direction="horizontal"
              icon-size="16"
            >
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          name="area"
          :value="value"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirmArea"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="formData.chooseIdentity"
          readonly
          clickable
          label="身份"
          placeholder="请选择身份"
          @click="showIdentityPicker = true"
        />
        <van-popup v-model="showIdentityPicker" round position="bottom">
          <van-picker
            :columns="identity"
            @cancel="showIdentityPicker = false"
            @confirm="onConfirmIdent"
            show-toolbar
          />
        </van-popup>
        <van-field
          v-model="formData.password"
          name="password"
          type="password"
          label="登录密码"
          placeholder="请设置登录密码，用于账号登录"
        />
        <van-field
          v-model="formData.confirm_password"
          name="confirm_password"
          type="password"
          label="确认密码"
          placeholder="请再次输入登录密码"
        />
        <div style="margin: 30px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar";
import province_list from "../utils/area";
import { addOp } from "../api/index";
import { loginAlert } from "../utils/loginAlert";
export default {
  data() {
    return {
      username: "",
      password: "",
      formData: {
        truename: "",
        mobile: "",
        sex: "",
        chooseIdentity: "",
        password: "",
        confirm_password: "",
      },
      value: "",
      showArea: false,
      areaList: province_list, // 数据格式见 Area 组件文档
      showIdentityPicker: false,
      identity: ["终端用户", "制冷厂商", "维修师傅", "模具配件"],
    };
  },
  components: {
    Navbar,
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    onSubmit(values) {
      var _this = this;
      if (_this.formData.truename == "") {
        _this.$toast.fail("真实姓名不能为空！");
        return;
      }
      if (_this.formData.mobile == "") {
        _this.$toast.fail("手机号码不能为空！");
        return;
      }
      if (_this.value == "") {
        _this.$toast.fail("地址不能为空！");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      addOp("/user/update/1", _this.formData).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.$cookies.set("userInfo", res.data);
          _this.$toast.success("操作成功！");
          setTimeout(() => {
            _this.$router.push("/user");
          }, 800);
        } else {
          if (res.data.errors) {
            for (var key in res.data.errors) {
              _this.$toast.fail(res.data.errors[key][0]);
            }
          } else {
            _this.$toast.fail("操作失败，请重试");
          }
        }
      });
    },

    onConfirmArea(values) {
      console.log(values);
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      this.formData.province = values[0].name;
      this.formData.city = values[1].name;
      this.formData.area = values[2].name;
      this.showArea = false;
    },

    onConfirmIdent(value) {
      console.log(value);
      this.formData.chooseIdentity = value;
      this.showIdentityPicker = false;
    },
  },
  created() {
    var _this = this;
    var token = _this.$cookies.get("Authorization");
    if (!token) {
      loginAlert()
      return;
    }
  },
};
</script>
<style lang="less" scoped>
.invitereg {
  background: url("../assets/image/yqbg.jpg") no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
  .top_title {
    text-align: center;
    color: #fff;
  }
  .inviterForm {
    width: 92%;
    padding: 20px;
    margin: 20px auto;
    background: #fff;
    border-radius: 10px;
  }
}
</style>