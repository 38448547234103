<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view v-if="isRouterAlive" :key="$route.fullPath"> </router-view>
    <!-- </keep-alive> -->
    <notice></notice>
  </div>
</template>

<script>
import Notice from "./components/notice.vue";
// import Notice from "../src/components/notice"
export default {
  name: "App",
  components: {
    Notice,
    // Notice
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  computed: {},
  beforeCreate() {},
  created() {
    // console.log(this.$route.meta.keepAlive)
  },
  mounted() {
    const _this = this;
    // setTimeout(()=>{
    //  	_this.isShowLogin()
    // },2000)
  },
  updated() {},
  watch: {},
  methods: {
    isShowLogin() {
      const _this = this;
      if (
        !_this.$cookies.isKey("showLoginAlert") &&
        !_this.$cookies.isKey("Authorization")
      ) {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "您还未登录，是否前去登录？",
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
          })
          .then(() => {
            _this.$router.push("/login");
          })
          .catch(() => {
            _this.$cookies.set("showLoginAlert", "false", "2d");
          });
      }
    },
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>
<style lang="less">
// .van-uploader__preview-delete {
// 	z-index: 999!important;
// }

#productShow .content img {
  width: 100% !important;
  height: 100% !important;
}
/**
	 * 此处调整优先级，以 .repair-detail .bottom-btn的优先级9999为最低递增
	 */
// 遮罩层
.van-overlay {
  // z-index: 999!important;
}
// 地区选择层
.van-popup {
  // z-index: 10001!important;
}
// 登录提示层
.van-dialog {
  z-index: 10002 !important;
}
// 悬浮图片
.van-image-preview {
  z-index: 10003 !important;
}
// 地区选择类似 tabs 底部 空出55，防止底部菜单遮挡
// .van-tabs__content {
// 	padding-bottom: 55px!important;
// 	box-sizing: border-box!important;
// }
// 地区选择层底部空出10，美化
.van-tabs__content {
  padding-bottom: 10px !important;
  box-sizing: border-box !important;
}
.payType-mask .tips .van-cell__value {
  color: red;
}
</style>
