<template>
	<div class="sponsor-index sponsor-user">
		<Navbar :title="'主办方个人中心'"></Navbar>
		<div class="organiser">
			<div class="bg">
				<div class="info">
					<div class="status-box" v-if="info.status == 'SUCCESS'">
						{{ info.status_text }}
					</div>
					<div class="status-box" v-if="info.status == 'WAITING'" style="background: #d9d9d9">
						{{ info.status_text }}
					</div>
					<div class="status-box" v-if="info.status == 'FAIL'">
						{{ info.status_text }}
					</div>
					<div class="left">
						<img class="avatar" v-if="info.company_logo" :src="baseUrl + info.company_logo" alt="" />
					</div>
					<div class="right">
						<div class="title">{{ info.company_name }}</div>
						<div class="address">{{ info.company_address }}</div>
						<div class="address">
							<span>{{ info.company_phone }}</span>
							<div class="edit-btn" @click="toEdit">重新编辑</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="desc-box">
			<div class="title-box">
				<span>简介</span>
			</div>
			<div class="text">{{ info.company_desc }}</div>
		</div>
		<div class="expo-list">
			<div class="title-box">
				<span>展会列表</span>
				<span class="btn" @click="addExpo">发布新展会>></span>
			</div>
			<van-list v-model="loading" :finished="finished" :finished-text="expoList.length > 0 ? '没有更多了' : ''"
				@load="getExpoList">
				<div class="item" v-for="(item, index) in expoList" :key="index" @click="toDetail(item)">
					<div class="banner-box">
						<img class="banner-img" :src="baseUrl + item.images[0]" alt="" />
						<div class="banner-title">{{ item.title }}</div>
						<div class="status active" v-if="item.status == 'SUCCESS'">
							{{ getExpoStatus(item) }}
						</div>
						<div class="status active" v-else-if="item.status == 'WAITING'">
							等待审核
						</div>
						<div class="status active" v-else-if="item.status == 'FAIL'">
							拒绝审核
						</div>
						<div class="status" style="top: 60px; background: #666" @click.stop="toEditExpo(item.id)">
							重新编辑
						</div>
						<!-- <div class="status active" style="top: 85px; background: #fa6400"
							@click.stop="toAddService(item)">
							删除
						</div> -->
					</div>
					<div class="content-box">
						<div class="title">
							{{ item.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							<i class="iconfont iconweizhi"></i>{{ item.address }}
						</div>
						<div class="time">
							<div class="left">
								<van-icon name="clock-o" />{{ item.start_time.substring(0,10) }} 至
								{{ item.end_time.substring(0,10) }}
							</div>
							<!-- <div class="right">
								<van-icon name="friends-o" />{{
                  item.expo_join_data_count
                }}家报名
							</div> -->
						</div>
					</div>
				</div>
				<van-empty v-if="expoList.length == 0" description="暂无数据" />
			</van-list>
		</div>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				info: {},
				baseUrl: base.apiURL,
				loginURL: base.loginURL,

				expoList: [],
				loading: false,
				finished: false,
				userInfo: {
					id: "",
				},
				invate_uid: "",

				page: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			// if (this.userInfo.id == "") {
			// 	loginAlert()
			// }
			this.getShow();
			this.setShare();
			this.wxLogin();
			this.getExpoList()
		},
		mounted() {},
		watch: {},
		methods: {
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-会展中心`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL +
						"/sponsorIndex?id=" +
						this.userInfo.id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
				this.$dialog.close();
				this.getShow();
				this.setShare();
				this.getExpoList();
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			getExpoStatus(info) {
				var _this = this;
				var current_time = new Date().getTime();
				var start_time = new Date(info.start_time).getTime();
				var end_time = new Date(info.end_time).getTime();

				if (current_time < start_time) {
					return "报名中";
				} else if (current_time > start_time && current_time < end_time) {
					return "活动中";
				} else {
					return "活动结束";
				}
			},
			getShow() {
				var _this = this;
				indexOp("/expo_sponsor_user/show").then((res) => {
					if (res.data.state == 1000) {
						_this.info = res.data.data;
					} else if (res.data.state == 1005 && res.data.msg == "数据不存在") {
						_this.$router.push("/sponsorUserEdit");
					}
				});
			},
			//获取展会列表
			getExpoList() {
				var _this = this;
				var data = {
					page: _this.page + 1,
				};
				indexOp("/expo_sponsor_user/expo_list", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.expoList = res.data.data.data;
							_this.page = res.data.data.current_page;
						} else {
							_this.finished = true;
						}
						_this.loading = false;
					}
					console.log(res);
				});
			},
			//toEdit 去编辑个人信息
			toEdit() {
				this.$router.push("/sponsorUserEdit");
			},
			addExpo() {
				this.$router.push("/sponsorExpoAdd");
			},
			toDetail(item) {
				if (item.status == "SUCCESS") {
					this.$router.push({
						path: "/sponsorExpoDetail",
						query: {
							id: item.id,
						},
					});
				} else {
					this.$router.push({
						path: "/sponsorExpoEdit",
						query: {
							id: item.id,
						},
					});
				}
			},
			toAddService(item) {
				var _this = this;
				if (item.status == "SUCCESS") {
					this.$router.push({
						path: "/sponsorExpoAddService",
						query: {
							id: item.id,
						},
					});
				} else {
					_this.$dialog
						.confirm({
							title: "提示",
							message: "展会暂时没有通过审核，请耐心等待！",
							confirmButtonText: "确认",
							cancelButtonText: "取消",
						})
						.then(() => {});
				}
			},
			toEditExpo(id) {
				this.$router.push({
					path: "/sponsorExpoEdit",
					query: {
						id: id,
					},
				});
			},
		},
	};
</script>

<style scoped>
</style>