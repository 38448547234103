<template>
	<div>
		<Navbar title="0元入驻"></Navbar>
		<div class="zero-conta">
			<div class="title">申请入驻店铺</div>
			<div class="zero-from">
				<!-- <div class="label">店铺名称*</div> -->
				<div class="input">
					<input type="text" v-model="shopName" placeholder="店铺名称">
				</div>
			</div>
			<div class="bt" @click="apply">0元入驻</div>
		</div>
	</div>
	
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	export default {
		components: {
			Tabber,
			Navbar
		},
		props: {},
		data() {
			return {
				userInfo: "",
				list: "",
				shopName : '',
			};
		},
		computed: {},
		created() {
			// this.WXlogin();
			this.userInfo = this.$cookies.get("userInfo");
			
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && !code) {
				loginAlert('登录后可申请，请先登录', 1, '点击前往登录')
				return;
			} else if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			} else if( this.$cookies.isKey("userInfo") ){
				this.checkOpen();
			}
		},
		mounted() {},
		watch: {},
		methods: {
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				} else {
					this.onLoad();
				}
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			apply(){
				var _this 	=	this;
				if(!this.shopName){
					this.$toast.fail('请输入店铺名称');
					return false;
				}
				addOp("/goods_shop/create",{
					name : _this.shopName
				}).then((res) => {
					var data 	=	res.data;
					if (data && !data.error) {
						_this.$toast.success('申请成功');
						setTimeout(function(){
							window.location.href 	=	'/myShop?uid=' + _this.userInfo.id +  '&tab=4';
						},400);
					}else{
						_this.$toast.fail(data.error);
					}
				}).catch((err)=>{
					console.log(err);
					_this.$toast.fail('提交失败');
				});
			},
			checkOpen(){
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				console.log('checkOpen');
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/goods_shop/shop_info?shop_uid=" + this.userInfo.id).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						if(!res.data.error){
							if( _this.getUrlKey('back') ){
								_this.$router.push({
									path : '/' + _this.getUrlKey()
								});
							}else if( _this.getUrlKey('me') ){
								window.location.href 	=	'/myShop?uid=' + _this.userInfo.id +  '&tab=4';
							}else{
								_this.$router.go(-1);
							}
						}
					}
				});
			},
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
				_this.checkOpen();
			}
		}
	}
</script>

<style>
</style>