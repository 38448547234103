<template>
    <div class="usercard-box">
      <div class="top-box">
        <div class="left">
          <img class="avatar" src="../assets/image/avatar.png" alt="">
        </div>
        <div class="right">
          <p class="name">小徐</p>
          <p class="desc">用户ID：4656</p>
          <p class="desc">维修人员</p>
        </div>
      </div>
      <van-cell-group class="cell-box">
        <van-cell>
          <template #title>
            <span class="title">联系方式：</span>
            <span class="content">15703806315</span>
          </template>
        </van-cell>
        <van-cell>
          <template #title class="item">
            <span class="title">联系地址：</span>
            <span class="content">郑州市中原区郑地璞园郑州市中原区郑地璞园郑州市中原区郑地璞园郑州市中原区郑地璞园</span>
          </template>
        </van-cell>
      </van-cell-group>

      <div class="btn-box">
        <van-icon class="chat-icon" name="chat-o" />
        <span class="tit">发消息</span>
      </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {

    },
    components: {

    },
};
</script>

<style scoped lang="less">
.usercard-box{
  .top-box{
    background: #fff;
    padding: 20px 15px;
    display: flex;
    margin-bottom:20px;

    .left{
      margin-right: 15px;
      .avatar{
        width: 60px;
        height: 60px;
        border-radius: 4px;
      }
    }
    .right{
      font-size: 14px;
      p{
        margin-bottom: 5px;
      }
      .name{
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.cell-box{
  .title{
    font-size: 14px;
    width: 22%;
  }
  .content{
    font-size: 14px;
    width: 75%;
  }
}
.van-cell__title{
  display: flex;
}

.btn-box{
  margin-top: 20px;
  display: flex;
  background: #fff;
  padding: 15px 15px;
  justify-content: center;
  align-items: center;

  .chat-icon{
    font-size: 20px;
    margin-right: 10px;
    color:#5e7192;
  }
  .tit{
    font-size: 16px;
    color:#5e7192;
  }
}
</style>
