<template>
	<div class="sponsor-index sponsor-user">
		<Navbar :title="'发布新展会'"></Navbar>
		<div class="organiser">
			<div class="bg">
				<div class="info">
					<!-- <div class="status-box" v-if="info.status == 'SUCCESS'">{{ info.status_text }}</div>
            <div class="status-box" v-if="info.status == 'WAITING'" style="background:#D9D9D9;">{{ info.status_text }}</div>
            <div class="status-box" v-if="info.status == 'FAIL'">{{ info.status_text }}</div> -->
					<div class="left">
						<img class="avatar" :src="baseUrl + info.company_logo" alt="" />
					</div>
					<div class="right">
						<div class="title">主办方名称：{{ info.company_name }}</div>
						<div class="address">
							主办方地址：{{ info.company_address }}
						</div>
						<div class="address">
							<span>联系方式：{{ info.company_phone }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-box">
			<div class="input-item">
				<div class="label">展会封面：</div>
				<van-uploader style="width:100%" multiple v-model="ImgList" :after-read="afterReadImg" :before-delete="deleteImg"
					:accept="'image/*'" />
			</div>
			<div class="input-item">
				<div class="label">展会名称：</div>
				<input class="input" type="text" placeholder="" v-model="title">
			</div>
			<div class="input-item">
				<div class="label">联系方式：</div>
				<input class="input" type="text" placeholder="" v-model="phone">
			</div>
			<div class="input-item">
				<div class="label">展会简介：</div>
				<textarea class="input" type="text" placeholder="" style="height:50px;" v-model="desc"></textarea>
			</div>
			<!-- <div class="input-item">
                <div class="label">视频：</div>
                <van-uploader  multiple v-model="videoList" :after-read="afterReadVideo" :before-delete="deleteVideo" :accept="'video/*'"  />
            </div> -->
			<div class="input-item">
				<div class="label">开始时间：</div>
				<input class="input" type="text" placeholder="" readonly v-model="start_time"
					@click="showStartTimePicker = true">
				<van-popup v-model="showStartTimePicker" round position="bottom">
					<van-datetime-picker v-model="currentDate" type="datetime" title="选择展会开始时间" :min-date="minDate"
						:max-date="maxDate" @confirm="onStartTimeConfirm" />
				</van-popup>
			</div>
			<div class="input-item">
				<div class="label">结束时间：</div>
				<input class="input" type="text" placeholder="" readonly v-model="end_time"
					@click="showEndTimePicker = true">
				<van-popup v-model="showEndTimePicker" round position="bottom">
					<van-datetime-picker v-model="currentDate" type="datetime" title="选择展会结束时间" :min-date="minDate"
						:max-date="maxDate" @confirm="onEndTimeConfirm" />
				</van-popup>
			</div>
			<div class="input-item">
				<div class="label">展会地址：</div>
				<input class="input" type="text" placeholder="" readonly v-model="address_text"
					@click="showCityPicker = true">
				<van-popup v-model="showCityPicker" round position="bottom">
					<van-area title="选择地址" :area-list="areaList" @cancel="showCityPicker = false"
						@confirm="onCityConfirm" />
				</van-popup>
			</div>
			<div class="input-item">
				<div class="label">详细地址：</div>
				<textarea class="input" type="text" placeholder="" style="height:50px;" v-model="address"></textarea>
			</div>
			<div class="input-item">
				<div class="label">合作单位：</div>
				<textarea class="input" type="text" placeholder="" style="height:50px;" v-model="partner"></textarea>
			</div>
			
			<div class="input-item">
				<div class="label">展会详情</div>
				<editor :menus="['image']" @editor-input="onEditorChange"></editor>
			</div>

			<div class="tip-box">
				<div>友情提示：</div>
				<div style="text-indent: 20px;">保证您填写的资料真实有效，提交审核后耐心等待，资料准确无误的前提下，一般三个工作日内将会审核通过，请耐心等待。</div>
			</div>

			<div class="btn-box">
				<div class="sub-btn" @click="onSubmit">提交申请</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import province_list from "../../utils/area";
	import Editor from "../../components/editor"
	export default {
		components: {
			Tabber,
			Navbar,
			Editor
		},
		props: {},
		data() {
			return {
				afterRead: "",
				radio: "",
				baseUrl: base.apiURL,
				areaList: province_list,
				info: {},

				title: "",
				phone: "",
				desc: "",
				images: [],
				videos: [],
				province: "",
				city: "",
				area: "",
				address: "",
				partner: "",
				start_time: "",
				end_time: "",
				htmlValue : "",

				showCityPicker: false,
				ImgList: [],
				videoList: [],
				time_text: "",
				address_text: "",
				timeShow: false,

				minDate: new Date(2020, 1, 1),
				maxDate: new Date(2050, 1, 1),
				currentDate: new Date(),

				showStartTimePicker: false,
				showEndTimePicker: false
			};
		},
		computed: {},
		created() {
			this.getShow()
		},
		mounted() {},
		watch: {},
		methods: {
			onEditorChange(value) {
				this.htmlValue = value
				console.log(value)
			},
			onStartTimeConfirm(date) {
				this.start_time = this.formatDate(date)
				console.log(date)
				this.showStartTimePicker = false;
			},
			onEndTimeConfirm(date) {
				this.end_time = this.formatDate(date)
				console.log(date)
				this.showEndTimePicker = false;
			},
			onSubmit() {
				var _this = this;
				if (_this.title == "") {
					_this.$toast.fail("展会名称不能为空！");
					return;
				}
				if (_this.phone == "") {
					_this.$toast.fail("联系方式不能为空！");
					return;
				}
				if (_this.desc == "") {
					_this.$toast.fail("展会简介不能为空！");
					return;
				}
				if (_this.images.length == 0) {
					_this.$toast.fail("至少上传一张图片！");
					return;
				}
				if (_this.start_time == "") {
					_this.$toast.fail("请选择展会开始时间！");
					return;
				}
				if (_this.end_time == "") {
					_this.$toast.fail("请选择展会结束时间！");
					return;
				}
				if (_this.province == "" || _this.city == "" || _this.area == "") {
					_this.$toast.fail("请选择展会地址！");
					return;
				}
				if (_this.address == "") {
					_this.$toast.fail("详细地址不能为空！");
					return;
				}
				if(_this.htmlValue == ''){
					_this.$toast.fail("展会详情不能为空！");
					return;
				}
				var data = {
					title: _this.title,
					phone: _this.phone,
					desc: _this.desc,
					images: _this.images,
					videos: _this.videos,
					province: _this.province,
					city: _this.city,
					area: _this.area,
					address: _this.address,
					partner: _this.partner,
					start_time: _this.start_time,
					end_time: _this.end_time,
					content : _this.htmlValue
				}
				console.log(data)
				addOp("/expo_sponsor_user/create_expo", data).then(res => {
					if (res && res.data.state == 1000) {
						_this.$toast.success(res.data.msg)
						setTimeout(() => {
							_this.$router.go(-1)
						}, 800)
					} else {
						_this.$toast.fail(res.data.msg)
					}
				})
			},
			getShow() {
				var _this = this;
				indexOp("/expo_sponsor_user/show").then(res => {
					if (res.data.state == 1000) {
						_this.info = res.data.data
					}
				})
			},
			//选择会展时间
			onTimeConfirm(date) {
				const [start, end] = date;
				this.time_text = `${this.formatDate(start)} - ${this.formatDate(end)}`;
				this.start_time = this.formatDate(start)
				this.end_time = this.formatDate(end)
				console.log(date)
				this.timeShow = false;
			},
			formatDate(date) {
				return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
			},
			//选择城市
			onCityConfirm(value, index) {
				this.showCityPicker = false;
				this.province = value[0].name;
				this.city = value[1].name;
				this.area = value[2].name;
				this.address_text = value[0].name + "-" + value[1].name + "-" + value[2].name
				this.address = value[0].name + value[1].name + value[2].name + " "
			},
			//logo图片上传
			afterReadImg(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.images.push(res.data.url)
						console.log(res)
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//删除以上传图片
			deleteImg(file, detail) {
				this.ImgList.splice(detail.index, 1);
				this.images.splice(detail.index, 1);
			},
			//视频上传
			afterReadVideo(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadFile(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.videos.push(res.data.url)
						console.log(res)
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//删除以上传视频
			deleteVideo(file, detail) {
				this.videoList.splice(detail.index, 1);
				this.videos.splice(detail.index, 1);
			},
		},
	};
</script>

<style scoped>
</style>