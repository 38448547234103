<template>
	<div class="product">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">产品详情</span>
			<van-icon name="edit" @click="onEdit" v-if="userInfo && info.uid == userInfo.id" />
		</div>
		<div class="content">
			<van-swipe class="banner-box" :autoplay="3000" :show-indicators="false">
				<van-swipe-item v-for="(item, index) in info.images" :key="index">
					<img class="banner-img" :src="url + item" alt="" />
				</van-swipe-item>
			</van-swipe>
			<!-- <div class="banner-box">
        <img class="banner-img" :src="url + info.images" alt="" />
      </div> -->
			<div class="title-box">{{ info.goods_name }}</div>
			<div class="detail-box">
				<div class="title">商品详情</div>
				<div v-html="info.content"></div>
			</div>
		</div>
		<!-- <div class="btns">
      <a v-if="userInfo && userInfo.user_vip_info && companyInfo.phone" :href="`tel:${companyInfo.phone}`" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <a v-else :href="`tel:4006912808`" class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </a>
      <div class="btn" @click="toChat">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div> -->
		<!-- <Tabber></Tabber> -->
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Navbar,
			Tabber,
		},
		props: {},
		data() {
			return {
				url: base.apiURL,
				pid: "", //产品id
				info: "",
				companyInfo: "",
				userInfo: {
					id: "",
				},
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.pid = this.$route.query.id;
			this.getInfo();
		},
		mounted() {},
		watch: {},
		methods: {
			onEdit() {
				this.$router.push({
					path: "/joinerProductEdit",
					query: {
						id: this.pid,
					},
				});
			},
			//获取店铺详情
			getShopDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				var data = {
					uid: _this.info.uid,
				};
				indexOp("/company_user/show/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.companyInfo = res.data;
					}
					console.log(res);
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-` + this.info.goods_name,
					desc: "媒体合作，共助发展，一次参展，将享受365天的宣传服务！",
					link: base.shareURL +
						"/productIndex?id=" +
						this.pid +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			getInfo() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					id: _this.pid,
				};
				indexOp("/expo_joiner/show_goods", data).then((res) => {
					_this.$toast.clear();
					if (res && res.data.state == 1000) {
						_this.info = res.data.data;
						// _this.getShopDetail();
						_this.setShare();
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			onDelete() {
				var _this = this;
				var api = "/goods/destroy/" + _this.info.id;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认删除产品？",
					})
					.then(() => {
						_this.$toast.loading({
							message: "",
							forbidClick: true,
						});
						addOp(api).then((res) => {
							_this.$toast.clear();
							if (res && res.status == 200) {
								_this.$toast.success("操作成功！");
								setTimeout(() => {
									_this.$router.go(-1);
								}, 800);
							}
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			toBack() {
				this.$router.go(-1);
			},
			//去对话
			toChat() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert()
					return;
				}
				var to_uid = _this.info.uid; //聊天对象 uid
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: to_uid,
						from_uid: form_uid,
						chat_type: "normal",
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							to_uid: to_uid,
						},
					});
				});
			},
		},
	};
</script>

<style scoped>
</style>