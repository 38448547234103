<template>
  <div class="home container repair">
    <Navbar :title="'我的订单'"></Navbar>
    <!-- <div class="add-box" @click="toRelease">
      <i class="iconfont iconjiahao"></i>
      <span class="text">发布需求</span>
    </div> -->

    <div class="tab-box">
      <van-tabs v-model="active" color="#2F78DC" @change="onTabsChange">
        <van-tab
          v-for="(tab, index) in statusList"
          :key="index"
          :title="tab.title"
          :name="index"
        >
          <div class="tab-item">
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in list"
                :key="index"
                @click="toDetail(item)"
              >
                <div class="status" v-if="item.deposit_pay_status == 1">
                  已预付订金
                </div>
                <div class="status" v-else style="background: #d6d6d6">
                  在线聊天转账
                </div>
                <div class="flex">
                  <div class="left-box" v-if="item.get_user_info">
                    <img
                      class="avatar"
                      :src="item.get_user_info.avatar"
                      alt=""
                    />
                    <span class="name">{{ item.get_user_info.nickname }}</span>
                    <van-tag
                      class="tag"
                      color="#FF4D4F"
                      text-color="#fff"
                      v-if="item.service_type == 'order_bidding'"
                      >待接单</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#FF4D4F"
                      text-color="#fff"
                      v-else-if="item.service_type == 'sure_service_user'"
                      >已确认师傅</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#ffdc30"
                      text-color="#fff"
                      v-else-if="item.service_type == 'take_order'"
                      >已接单</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#FF4D4F"
                      text-color="#fff"
                      v-else-if="item.service_type == 'arrive_order'"
                      >师傅已到达</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#795548"
                      text-color="#fff"
                      v-else-if="item.service_type == 'user_sure_arrive'"
                      >师傅已到达</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#3f69de"
                      text-color="#fff"
                      v-else-if="item.service_type == 'service_ing'"
                      >维修中</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#8e8e8e"
                      text-color="#fff"
                      v-else-if="item.service_type == 'service_end'"
                      >维修完成</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#d6d6d6"
                      text-color="#fff"
                      v-else-if="item.service_type == 'user_sure_service'"
                      >已确认完成</van-tag
                    >
                    <van-tag
                      class="tag"
                      color="#8bc34a"
                      text-color="#fff"
                      v-else-if="item.service_type == 'appraise_order'"
                      >已评价</van-tag
                    >
                    <!-- <van-tag
                      class="tag"
                      color="#bababa"
                      text-color="#fff"
                      v-else-if="item.service_type == 'pay_money'"
                      >订单结束</van-tag
                    > -->
                    <van-tag
                      class="tag"
                      color="#999999"
                      text-color="#fff"
                      v-else-if="item.service_type == 'cancel_order'"
                      >订单取消</van-tag
                    >
                  </div>
                  <div class="right-box">
                    <p class="title">维修产品名称：{{ item.name }}</p>
                    <p class="describe">故障描述：{{ item.desc }}</p>
                    <p class="time">发布时间：{{ item.created_at }}</p>
                    <p class="position">
                      <i class="iconfont iconweizhi"></i>
                      {{ item.address }}
                    </p>
                  </div>
                </div>
              </div>
              <a
                href="javascript:;"
                class="more-btn"
                @click="onMore"
                v-if="total > 10"
                >查看更多</a
              >
              <div class="empty" v-if="list.length <= 0">
                <van-empty description="暂无数据" />
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <!-- <van-search
        class="search-box"
        v-model="searchValue"
        placeholder="地区检索"
        shape="round"
        background="transparent"
      />
      <div class="select-box">
        <div class="status-box">
          <span class="status">预付上门费用</span>
          <span class="icon"><i class="iconfont iconrenminbi"></i></span>
        </div>
      </div> -->
      <!-- <van-dropdown-menu active-color="#2F78DC">
        <van-dropdown-item
          title="订单状态"
          v-model="screenValueOne"
          :options="option"
        />
        <van-dropdown-item
          title="会员等级"
          v-model="screenValueTwo"
          :options="option2"
        />
        <van-dropdown-item
          title="定金状态"
          v-model="screenValueThree"
          :options="option3"
        />
      </van-dropdown-menu> -->
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
export default {
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      active: 0,
      searchValue: "", //搜索内容
      screenValueOne: 0,
      screenValueTwo: 0,
      screenValueThree: 0,
      statusList: [
        {
          title: "全部",
          value: "",
        },
        // {
        //   title: "待选择",
        //   value: "order_bidding",
        // },
        // {
        //   title: "已确认",
        //   value: "sure_service_user",
        // },
        {
          title: "已接单",
          value: "take_order",
        },
        // {
        //   title: "已到达",
        //   value: "arrive_order",
        // },
        {
          title: "维修中",
          value: "service_ing",
        },
        {
          title: "维修完成",
          value: "service_end",
        },
        {
          title: "已评价",
          value: "appraise_order",
        },
        {
          title: "订单完成",
          value: "pay_money",
        },
        {
          title: "已取消订单",
          value: "cancel_order",
        },
      ],
      list: "", //订单列表
      status: "",
      total: "", //数据总数
      page: 1,
      last_page: 0,
      queryParam: {
        limit: 10,
        identity:2
      },
      userInfo: "",
    };
  },
  computed: {},
  created() {
    this.userInfo = this.$cookies.get("userInfo");
    this.status = this.$route.query.status;
    this.active = parseInt(this.$route.query.tab);
    this.getOrderList();
  },
  mounted() {},
  watch: {},
  methods: {
    toDetail(item) {
      var _this = this;
      if (item.uid == _this.userInfo.id) {
        this.$router.push({
          path: "/userRepairDetail",
          query: {
            order_no: item.order_no,
          },
        });
      } else {
        this.$router.push({
          path: "/shifuRepairDetail",
          query: {
            order_no: item.order_no,
          },
        });
      }
    },
    toRelease() {
      this.$router.push({
        path: "/orderRelease",
      });
    },
    getOrderList() {
      var _this = this;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      _this.queryParam.service_type = _this.status;
      indexOp("/service_order/my_order", _this.queryParam).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.list = res.data.data;
          _this.total = res.data.total;
          _this.last_page = res.data.last_page;
          _this.page = res.data.current_page;
        }
      });
    },
    //查看更多
    onMore() {
      var _this = this;
      if (_this.page == _this.last_page) {
        _this.$toast("没有更多数据了");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var page = _this.page + 1;
      _this.queryParam.page = page;
      _this.queryParam.service_type = _this.status;
      indexOp("/service_order/my_order", _this.queryParam).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.last_page = res.data.last_page;
          _this.page = res.data.current_page;
          if (res.data.data.length <= 0) {
            _this.$toast("没有更多数据了");
            return;
          }
          for (var i = 0; i < res.data.data.length; i++) {
            _this.list.push(res.data.data[i]);
          }
        }
      });
    },
    onTabsChange(value) {
      console.log(value);
      this.status = this.statusList[value].value;
      this.getOrderList();
    },
  },
};
</script>
