<template>
  <div class="technologyWrap">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">技术交流</span>
    </div>
    <div class="technologyTitleTab">
      <van-tabs :active="index" @change="onChange" data-index="index">
        <van-tab
          v-for="(item, index) in techCateList"
          :key="index"
          :title="item.category_name"
          :name="index"
        >
          <div
            class="technologyItem"
            v-for="(it, ind) in techMessageList"
            :key="ind"
          >
            <div class="technologyItemTop">
              <img :src="it.uinfo.avatar" alt="" />
              <h3>{{ it.uinfo.nickname }}</h3>
            </div>
            <div class="technologyItemCenter" @click="toContentXq(it.id)">
              <div class="technologyItemCenterText">
                <p v-html="it.content"></p>
              </div>
              <div class="technologyItemCenterPic" v-if="it.pictures != ''">
                <div v-for="(ite, inx) in it.pictures" :key="inx">
                  <van-image
                    style="margin-right: 5px"
                    width="100"
                    height="100"
                    :src="ite"
                  />
                </div>
              </div>
              <div class="technologyItemCenterPic" v-if="it.video">
                <video
                  controls="controls"
                  width="100"
                  height="100"
                  :src="it.video"
                ></video>
              </div>
            </div>
            <div class="technologyItemBottom">
              <van-row gutter="20" style="text-align: center">
                <van-col
                  span="8"
                  class="technologyItemBottomItem"
                  @click="setShare"
                >
                  <van-icon size="25px" name="cash-back-record" />
                  <p>{{ it.admire_count }}</p>
                </van-col>
                <van-col
                  span="8"
                  class="technologyItemBottomItem"
                  @click="toCommentList(it.id)"
                >
                  <van-icon size="25px" name="chat-o" />
                  <p>{{ it.comment_count }}</p>
                </van-col>
                <van-col
                  span="8"
                  class="technologyItemBottomItem"
                  @click="toPointPraise(it.id)"
                >
                  <van-icon size="25px" name="good-job-o" v-if="isDianZan" />
                  <van-icon name="good-job" size="25px" color="red" v-else />
                  <p>{{ it.zan_count }}</p>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="technologyBottomFix">
      <div class="messageFb">
        <router-link :to="{ path: '/messageMine' }">
          <img src="@/assets/image/user2.png" alt="" />
        </router-link>
      </div>
      <div class="technologyuser">
        <router-link
          :to="{ path: '/messagePublish', query: { id: categoryid } }"
        >
          <img src="@/assets/image/fb.png" alt="" />
        </router-link>
      </div>
    </div>
    <Tabber></Tabber>
  </div>
</template>
<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import base from "../../api/base";
import { indexOp, addOp } from "../../api/index";
import { wechatConfig } from "../../utils/wxConfig";
import { login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";

export default {
  data() {
    return {
      index: "0",
      techCateList: [],
      techMessageList: [],
      categoryid: "",
      userInfo: {
        id:""
      },
      isDianZan: true,
      picturesList: [],
      loginUrl: base.loginURL,
    };
  },
  components: {
    Navbar,
    Tabber,
  },
  created() {
    var _this = this;
    var token = _this.$cookies.get("Authorization");
    var code = _this.getUrlKey("code");
    var state = _this.getUrlKey("state");
    if (_this.$cookies.isKey("userInfo")) {
      _this.userInfo = _this.$cookies.get("userInfo");
    }
    if (!token) {
      loginAlert()
      return;
    }
    _this.getTechnologyjlList();
    _this.getTechnologyContentList();
  },
  methods: {
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.getTechnologyjlList();
      _this.getTechnologyContentList();
    },

    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    toBack() {
      this.$router.go(-1);
    },

    onChange(e) {
      var _this = this;
      _this.categoryid = _this.techCateList[e].id;
      _this.getTechnologyContentList();
      _this.index = e
      console.log(_this.index)
    },

    //分享
    setShare() {
      var _this = this;
      var tit;
      if(_this.index == 0){
        tit = "技术交流";
      }
      const shareInfo = {
        title: `冷鲜柜网-技术交流`,
        desc: `有任何问题和需要帮助的地方都可以在这里发布得到解决！`,
        link: base.shareURL + "/technologyIndex?uid=" + this.userInfo.id + "&tab=" + _this.index,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },

    // 获取技术交流分类列表
    getTechnologyjlList() {
      var _this = this;
      var token = _this.$cookies.get("Authorization");
      if (!token) {
        loginAlert()
        return;
      } else {
        indexOp("/interflow_category/get_lists").then((res) => {
          // console.log(res.data.data)
          _this.techCateList = res.data.data;
          console.log(_this.index)
          if(_this.$route.query.tab){
            _this.index = _this.$route.query.tab
          }
          _this.categoryid = _this.techCateList[_this.index].id;
        });
      }
    },

    // 获取技术交流帖子列表
    getTechnologyContentList() {
      var _this = this;
      var data = {
        category_id: _this.categoryid,
        // uid:_this.userInfo.id
      };
      indexOp("/interflow/get_lists", data).then((res) => {
        console.log(res.data.data);
        _this.techMessageList = res.data.data;
        _this.setShare();
      });
    },

    // 点赞
    toPointPraise(id) {
      var _this = this;
      _this.isDianZan = true;
      var data = {
        other_id: id,
        type: "interflow",
      };
      addOp("/interflow_zan/create", data).then((res) => {
        console.log(res);
        if (res && res.status == 201) {
          _this.isDianZan = false;
          _this.getTechnologyContentList();
          console.log(_this.isDianZan);
        }
      });
    },

    // 去评论
    toCommentList(id) {
      var _this = this;
      _this.$router.push({
        path: "/messageInfo",
        query: {
          id: id,
        },
      });
    },

    toContentXq(id) {
      var _this = this;
      _this.$router.push({
        path: "/messageInfo",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="less" scope>
.technologyWrap {
  .technologyTitleTab {
    .technologyItem {
      width: 96%;
      padding: 20px 20px 0 20px;
      background-color: #fff;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      margin: 10px auto;
      .technologyItemTop {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-right: 10px;
        }
        h3 {
          font-size: 16px;
        }
      }
      .technologyItemCenter {
        padding: 15px 0;
        border-bottom: 1px solid #eee;
        .technologyItemCenterText {
          padding-bottom: 10px;
          p {
            font-size: 14px;
            color: #666;
          }
        }
        .technologyItemCenterPic {
          display: flex;
          flex-direction: row;
        }
      }
      .technologyItemBottom {
        margin: 10px 0 10px 25px;
        .technologyItemBottomItem {
          display: flex;
          align-items: center;
          p {
            color: #666;
            padding-left: 5px;
          }
        }
      }
    }
  }
  .technologyBottomFix {
    position: fixed;
    bottom: 80px;
    right: 10px;
    img {
      width: 40px;
      height: 40px;
      display: block;
      margin-bottom: 10px;
    }
  }
}
</style>