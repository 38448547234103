<template>
  <!-- 账单 -->
  <div class="bill-detail">
    <Navbar :title="`账单详情`"></Navbar>
    <div class="top">
        <!-- <van-icon class="icon" name="gold-coin" /> -->
        <!-- <span class="money" v-if="info.pay_type == 'account_pay'">+ {{ info.money }}</span>
        <span class="money" v-else-if="info.pay_type == 'account_earn_transfer'">+ {{ info.money }}</span>
        <span class="money" v-else-if="info.pay_type == 'system_add'">+ {{ info.money }}</span> -->
        <span class="money" >{{ info.operator + info.money }}</span>
    </div>
    <div class="info-box">
        <!-- <div class="item">
            <span class="tit">交易类型:</span>
            <p class="text" v-if="info.pay_type == 'system_add'">系统后台充值金额</p>
            <p class="text" v-if="info.pay_type == 'system_deduct'">系统后台扣除金额</p>
            <p class="text" v-if="info.pay_type == 'deposit_money_wxpay'">微信支付维修订单预付款</p>
            <p class="text" v-if="info.pay_type == 'door_money_add_wxpay'">微信支付上门费用</p>
            <p class="text" v-if="info.pay_type == 'service_money_wxpay'">微信支付维修费用</p>
            <p class="text" v-if="info.pay_type == 'fittings_money_wxpay'">微信支付配件费</p>
            <p class="text" v-if="info.pay_type == 'user_vip_wxpay'">微信支付年费</p>
            <p class="text" v-if="info.pay_type == 'service_fittings_money_wxpay'">微信支付配件及维修费用</p>
            <p class="text" v-if="info.pay_type == 'account_pay'">账户充值</p>

            <p class="text" v-if="info.pay_type == 'deposit_money_account'">余额支付维修订单预付款</p>
            <p class="text" v-if="info.pay_type == 'door_money_add_account'">余额支付上门费用</p>
            <p class="text" v-if="info.pay_type == 'service_money_account'">余额支付维修费用</p>
            <p class="text" v-if="info.pay_type == 'fittings_money_account'">余额支付配件费用</p>
            <p class="text" v-if="info.pay_type == 'user_vip_account'">余额支付年费</p>
            <p class="text" v-if="info.pay_type == 'service_fittings_money_account'">余额支付配件及维修费用</p>
            <p class="text" v-if="info.pay_type == 'withdraw'">提现</p>
            <p class="text" v-if="info.pay_type == 'account_pay_transfer'">转账账户</p>
            <p class="text" v-if="info.pay_type == 'account_earn_transfer'">收账账户</p>
        </div> -->
        <!-- <div class="item" v-if="info.pay_type == 'account_pay_transfer' || info.pay_type == 'account_earn_transfer'">
            <span class="tit">转账人：</span>
            <span class="text" v-if="info.get_from_user_info">{{info.get_from_user_info.nickname}}</span>
        </div>
        <div class="item" v-if="info.pay_type == 'account_pay_transfer' || info.pay_type == 'account_earn_transfer'">
            <span class="tit">收款人：</span>
            <span class="text" v-if="info.get_to_user_info">{{info.get_to_user_info.nickname}}</span>
        </div> -->
        <!-- <div class="item" v-if="info.status == 2">
            <span class="tit">交易状态：</span>
            <span class="text">已退款</span>
        </div> -->
        <div class="item">
            <span class="tit">交易类型：</span>
            <span class="text" v-if="info.type == 'door_money_add_account'">上门检修费</span>
            <span class="text" v-if="info.type == 'service_money_account'">维修费用</span>
            <span class="text" v-if="info.type == 'deposit_money_account'">预付定金</span>
            <span class="text" v-if="info.type == 'deposit_back_off'">预付定金退回</span>
            <span class="text" v-if="info.type == 'user_vip_account'">会员充值</span>
            <span class="text" v-if="info.type == 'system_add'">后台充值</span>
            <span class="text" v-if="info.type == 'account_pay'">账户充值</span>
            <span class="text" v-if="info.type == 'account_pay_transfer'">账户转账</span>
        </div>
        <div class="item">
            <span class="tit">交易说明：</span>
            <span class="text">{{info.title}}</span>
        </div>
        <div class="item">
            <span class="tit">支付时间：</span>
            <span class="text">{{info.updated_at}}</span>
        </div>
        <div class="item">
            <span class="tit">交易单号：</span>
            <span class="text">{{info.order_no}}</span>
        </div>
    </div>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
export default {
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      info: "",
      bid:"",
      orderInfo:""
    };
  },
  computed: {},
  created() {
      this.bid = this.$route.query.id;
      this.getBillDetail()
  },
  mounted() {},
  watch: {},
  methods: {
    getBillDetail() {
      var _this = this;
      var api = "/bill_log/show/" + _this.bid
      indexOp(api).then((res) => {
        _this.info = res.data.data;
        console.log(res);
        _this.getOrderInfo()
        
      });
    },
    getOrderInfo(){
      var _this = this;
      var data = {
        order_no: _this.info.order_no,
      };
      indexOp("/service_order/show", data).then((res) => {
        if (res && res.status) {
          _this.orderInfo = res.data;
        }
        console.log(res);
      });
    },
  },
};
</script>

<style scoped >
</style>
