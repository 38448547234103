<template>
	<div class="home container">
		<div class="add-box" @click="toAddOrder">
			<i class="iconfont iconjiahao"></i>
		</div>
		<div class="swiper-box">
			<div class="top-nav">
				<img src="@/assets/logo.png" alt="冷鲜柜网" style="height:18px;">
				<div class="position">
					<i class="iconfont iconweizhi"></i>
					<span v-if="userInfo">{{ userInfo.area }}</span>
					<span v-else>{{ location.city }}</span>
				</div>
			</div>
			<div class="swiper-box-bg"
				:style="{ 'background': `linear-gradient(to bottom, ${swiperBgColor}, #f2f2f2)` }">
			</div>
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false" @change="onChangeSwiper">
				<van-swipe-item v-for="(item, index) in slideList" :key="index">
					<a :href="item.link">
						<img :src="url + item.cover" alt="" />
					</a>
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="gropu-box" style="flex-wrap: wrap;">
			<router-link :to="{ path: '/shifu' }" class="item">
				<img class="icon" src="@/assets/image/menu_bg_1.png" alt="" />
				<span class="name">制冷维修</span>
			</router-link>
			<router-link :to="{ path: '/changshangList' }" class="item">
				<img class="icon" src="@/assets/image/menu_bg_2.png" alt="" />
				<span class="name">冷企大全</span>
			</router-link>
			<router-link class="item" :to="{ path: '/expoIndex' }">
				<img class="icon" src="@/assets/image/menu_bg_3.png" alt="" />
				<span class="name">会展物流</span>
			</router-link>
			<router-link class="item" :to="{ path: '/shop/index?type=exchange' }">
				<img class="icon" src="@/assets/menu_car.png" alt="" /> 
				<span class="name">冷链易货</span>
			</router-link>
			<router-link class="item" :to="{ path: '/peijianList' }">
				<img class="icon" src="@/assets/image/menu_bg_4.png" alt="" />
				<span class="name">配件模具</span>
			</router-link>
			<router-link :to="{  path: '/shop/index?type=rent' }" class="item">
				<img class="icon" src="@/assets/image/menu_bg_5.png" alt="" />
				<span class="name">设备租赁</span>
			</router-link>
			<router-link :to="{ path: '/knowledge/index' }" class="item">
				<img class="icon" src="@/assets/image/icon_know.png" alt="" />
				<span class="name">知识库</span>
			</router-link>
			<router-link class="item" :to="{  path: '/shop/index?type=sale' }">
				<img class="icon" src="@/assets/image/menu_recommend.png" alt="" />
				<span class="name">推荐商家</span>
			</router-link>
		</div>
		<div class="news-box">
			<div class="icon-box">
				<i class="iconfont icongonggao"></i>
			</div>
			<van-swipe class="news-swipe" :autoplay="3000" :show-indicators="false" :vertical="true">
				<van-swipe-item v-for="(item, index) in topicList" :key="index"><span
						style="flex:1">{{ item.title }}</span><span class="news-detail">查看详情</span></van-swipe-item>
			</van-swipe>
		</div>
		<div class="tab-box" style="min-height:700px">
			<div class="tab-tit">
				<span>维修大厅</span>
				<van-search class="search-box" v-model="searchValue" placeholder="输入地区关键词搜索" shape="round"
					background="transparent" @search="onSearch" />
			</div>
			<div class="tab-item">
				<div class="list">
					<div class="item" v-for="(item, index) in orderList" :key="index" @click="toDetail(item)">
						<div class="status" v-if="item.deposit_pay_status == 1 && item.door_pay_status == 0">
							已预付订金
						</div>
						<div class="status" v-else-if="item.door_pay_status == 1 || service_pay_status == 1">
							在线聊天交易
						</div>
						<div class="status" v-else-if="
                            (item.door_pay_status == 0 &&
                                item.service_type == 'user_sure_service') ||
                            item.service_type == 'appraise_order' ||
                            item.service_type == 'pay_money'
                        ">
							在线聊天交易
						</div>
						<div class="status" v-else style="background: #d6d6d6">
							在线聊天转账
						</div>
						<div class="flex">
							<div class="left-box">
								<img class="avatar" v-if="item.get_user_info" :src="item.get_user_info.avatar" alt="" />
								<span class="name" v-if="item.get_user_info">{{
                                        item.get_user_info.nickname
                                }}</span>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-if="item.service_type == 'order_bidding'">待接单</van-tag>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-else-if="item.service_type == 'sure_service_user'">已确认师傅</van-tag>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-else-if="item.service_type == 'take_order'">已接单</van-tag>
								<van-tag class="tag" color="#795548" text-color="#fff"
									v-else-if="item.service_type == 'arrive_order'">师傅已到达</van-tag>
								<van-tag class="tag" color="#795548" text-color="#fff"
									v-else-if="item.service_type == 'user_sure_arrive'">师傅已到达</van-tag>
								<van-tag class="tag" color="#2F78DC" text-color="#fff"
									v-else-if="item.service_type == 'service_ing'">维修中</van-tag>
								<van-tag class="tag" color="#d6d6d6" text-color="#fff"
									v-else-if="item.service_type == 'service_end'">维修完成</van-tag>
								<van-tag class="tag" color="#d6d6d6" text-color="#fff"
									v-else-if="item.service_type == 'user_sure_service'">已确认完成</van-tag>
								<van-tag class="tag" color="#8bc34a" text-color="#fff"
									v-else-if="item.service_type == 'appraise_order'">已评价</van-tag>
								<!-- <van-tag
                                    class="tag"
                                    color="#bababa"
                                    text-color="#fff"
                                    v-else-if="item.service_type == 'pay_money'"
                                    >订单结束</van-tag
                                > -->
							</div>
							<div class="right-box">
								<p class="title">维修产品名称：{{ item.name }}</p>
								<p class="describe">故障描述：{{ item.desc }}</p>
								<p class="time">发布时间：{{ item.created_at }}</p>
								<p class="time">
									所在地区：{{
                                        item.province_info ? item.province_info.area_name : "暂无"
                                    }}-{{ item.city_info ? item.city_info.area_name : "暂无" }}-{{
                                        item.area_info ? item.area_info.area_name : "暂无"
                                    }}
								</p>
								<p class="position">
									<i class="iconfont iconweizhi"></i>
									{{ item.address }}
								</p>
							</div>
						</div>
						<div class="service-avatar" v-if="
                            item.service_type == 'order_bidding' &&
                            item.get_order_bidding.length > 0
                        ">
							<span></span>
							<div v-for="(src, index) in item.get_order_bidding" :key="index"
								@click.stop="toShopDetail(src.service_id)">
								<img :src="src.service_info.user_info.avatar" alt="" />
							</div>
						</div>
					</div>
					<div @click="onMore" class="more-btn">查看更多</div>
				</div>
			</div>
		</div>
		<div class="tab-box">
			<div class="tab-tit">
				<span class="active">合作媒体</span>
			</div>
			<div class="media-box">
				<swiper class="swiper" :options="swiperOption">
					<swiper-slide class="item" v-for="(item, index) in hezuoList" :key="index">
						<a :href="item.link"><img :src="url + item.cover" alt="" /></a>
					</swiper-slide>
				</swiper>
			</div>
		</div>
		<div class="tab-box">
			<div class="tab-tit">
				<span class="active">入驻热线</span>
			</div>
			<div class="tab-item">
				<div class="list">
					<p style="font-size:16px;color:#444;text-align: center;">400-6912-808 <span
							style="font-size:14px;color:#888">全国热线</span></p>
					<p style="font-size:16px;color:#444;text-align: center;margin-top:10px">188-3803-2012 <span
							style="font-size:14px;color:#888">微信同号</span></p>
					<a href="tel:18838032012" class="more-btn"
						style="color:#2F78DC;border-top:1px solid #f1f1f1;width:100%;margin-top:20px">拨打电话</a>
				</div>
			</div>
		</div>
		<a href="https://beian.miit.gov.cn"
			style="margin:20px 0;text-align: center;font-size: 13px;color:#aaa;margin-bottom:20px;width: 100%;display: block;">ICP备案：豫ICP16016704号</a>
		<Tabber></Tabber>

		<!-- 完善个人资料 -->
		<van-overlay :show="setServiceShow" @click="setServiceShow = false">
			<div class="setService-mask" @click.stop>
				<div class="title">完善个人信息</div>
				<div class="input-item">
					<label class="tit">真实姓名:</label>
					<input type="text" placeholder="请输入真实姓名" v-model="truename" />
				</div>
				<div class="input-item">
					<label class="tit">联系电话:</label>
					<input type="text" placeholder="请输入联系电话" v-model="mobile" />
				</div>
				<div class="input-item">
					<label class="tit">所在地区:</label>
					<input type="text" placeholder="请选择所在地区" v-model="address" readonly @click="showArea = true" />
				</div>
				<button class="btn" @click="onSetServiceSubmit">保存资料</button>
			</div>
		</van-overlay>
		<van-popup v-model="showArea" position="bottom">
			<van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
		</van-popup>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide,
		directive
	} from "vue-awesome-swiper";

	// import style (>= Swiper 6.x)
	import "swiper/swiper-bundle.css";

	// import style (<= Swiper 5.x)
	import "swiper/swiper.less";
	import Swiper2, {
		Navigation,
		Pagination,
		EffectFade,
		Autoplay
	} from "swiper";
	Swiper2.use([Navigation, Pagination, EffectFade, Autoplay]);

	import Tabber from "../components/Tabber";
	import province_list from "../utils/area";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		getUser,
		login
	} from "../utils/login_toast";
	import {
		loginAlert
	} from "../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Swiper,
			SwiperSlide,
		},
		directives: {
			swiper: directive,
		},
		props: {},
		data() {
			return {
				url: base.apiURL,
				active: 0,
				searchValue: "", //搜索内容
				tabsActive: 1,
				topicList: "", //公告列表
				slideList: "", //轮播列表
				hezuoList: "", //轮播列表
				orderList: "", //维修订单列表
				shifuList: "", //维修师傅
				queryParam: {},
				userInfo: {
					id: "",
				},
				reg: /(\d{3})\d{4}(\d{4})/,
				location: "", //当前区域

				mobile: "",
				truename: "",
				province: "",
				city: "",
				area: "",
				address: "",
				setServiceShow: false,
				showArea: false,
				areaList: province_list, // 数据格式见 Area 组件文档
				locationInfo: "",

				swiperOption: {
					slidesPerView: 2,
					spaceBetween: 15,
					loop: true,
					autoplay: {
						delay: 1500,
						disableOnInteraction: false,
					},
				},
				page: 1,
				last_page: "", //最后一页
				queryParam: {
					limit: 10,
				},
				loginURL: base.loginURL,
				swiperBgColor: '#2F78DC'
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
				this.truename = this.userInfo.truename;
				this.mobile = this.userInfo.mobile;
				this.province = this.userInfo.province;
				this.city = this.userInfo.city;
				this.area = this.userInfo.area;
				this.address =
					this.userInfo.province +
					"-" +
					this.userInfo.city +
					"-" +
					this.userInfo.area;
			}
			this.WXlogin();
			this.setShare();
			this.getTopic();
			this.getSlide();
			this.getOrder();
			// this.getShifu();
			this.getHezuo();
			this.getLocation();
		},
		mounted() {
			this.locationInfo = this.$cookies.get("LocationInfo");
		},
		watch: {},
		methods: {
			onChangeSwiper(index) {
				this.swiperBgColor = this.slideList[index].description || '#2F78DC'
			},
			toTopicDetail(info) {
				var _this = this;
				console.log(info);
				_this.$router.push({
					path: "/topic",
					query: {
						id: info.id,
					},
				});
			},
			//查看更多
			onMore() {
				var _this = this;
				if (_this.page == _this.last_page) {
					_this.$toast("没有更多数据了");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.page + 1;
				_this.queryParam.page = page;
				indexOp("/service_order/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
						if (res.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							return;
						}
						for (var i = 0; i < res.data.data.length; i++) {
							_this.orderList.push(res.data.data[i]);
						}
					}
				});
			},
			onSwiper(swiper) {
				console.log(swiper);
			},
			onSlideChange() {
				console.log("slide change");
			},
			onConfirm(values) {
				console.log(values);
				this.address = values
					.filter((item) => !!item)
					.map((item) => item.name)
					.join("-");
				this.province = values[0].name;
				this.city = values[1].name;
				this.area = values[2].name;
				this.showArea = false;
			},
			onSetServiceSubmit() {
				var _this = this;
				if (_this.truename == "") {
					_this.$toast.fail("真实姓名不能为空！");
					return;
				}
				if (_this.mobile == "") {
					_this.$toast.fail("联系电话不能为空！");
					return;
				}
				if (_this.address == "") {
					_this.$toast.fail("所在地区不能为空！");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					truename: _this.truename,
					mobile: _this.mobile,
					province: _this.province,
					city: _this.city,
					area: _this.area,
					latitude: _this.locationInfo.latitude,
					longitude: _this.locationInfo.longitude,
				};

				addOp("/user/update/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.$cookies.set("userInfo", res.data);
						_this.$toast.success("保存成功！");
						setTimeout(() => {
							_this.$router.push("/orderRelease");
						}, 800);
					} else {
						if (res.data.errors) {
							for (var key in res.data.errors) {
								_this.$toast.fail(res.data.errors[key][0]);
							}
						} else {
							_this.$toast.fail("操作失败，请重试");
						}
					}
				});
			},
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				}
			},
			getLoginUser(userInfo) {
				console.log(userInfo);
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},

			//添加订单
			toAddOrder() {
				var _this = this;
				var token = _this.$cookies.get("Authorization");
				if (!token) {
					loginAlert("您还未登录，请先登录后再发布订单！")
					return;
				}
				if (
					!this.userInfo.truename ||
					!this.userInfo.mobile ||
					!this.userInfo.province
				) {
					_this.setServiceShow = true;
					return;
				}
				this.$router.push({
					path: "/orderRelease",
				});
			},

			//获取公告
			getTopic() {
				var _this = this;
				indexOp("/topic/index").then((res) => {
					if (res && res.status == 200) {
						_this.topicList = res.data;
					}
				});
			},

			//获取轮播
			getSlide() {
				var _this = this;
				indexOp("/slide/index?cid=1").then((res) => {
					if (res && res.status == 200) {
						_this.slideList = res.data;
					}
				});
			},

			//获取合作媒体
			getHezuo() {
				var _this = this;
				indexOp("/slide/index?cid=2").then((res) => {
					if (res && res.status == 200) {
						_this.hezuoList = res.data;
					}
				});
			},

			//获取维修订单
			getOrder(keyword) {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					limit: 10,
					keyword: keyword,
				};
				indexOp("/service_order/index", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.orderList = res.data.data;
					}
				});
			},

			//获取师傅列表
			getShifu(keyword) {
				var _this = this;
				var data = {
					limit: 10,
					keyword: keyword,
				};
				indexOp("/service_user/index", data).then((res) => {
					if (res && res.status == 200) {
						// if(_this.userInfo && _this.userInfo.user_vip_info){
						_this.shifuList = res.data.data;
						// }else{
						//   var data = res.data.data;
						//   data.map(item=>{
						//     var phone = item.phone.toString();
						//     // item.phone = phone.replace(_this.reg, "$1****$2")
						//     console.log(phone)
						//   })
						// }
					}
				});
			},

			//去订单详情
			toDetail(order) {
				var _this = this;
				var identity = _this.$cookies.get("identity");
				var uid;
				if (_this.$cookies.isKey("userInfo")) {
					uid = _this.$cookies.get("userInfo").id;
					if (order.uid == uid) {
						this.$router.push({
							path: "/userRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else if (order.service_id == uid) {
						this.$router.push({
							path: "/shifuRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else {
						this.$router.push({
							path: "/RepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					}
				} else {
					this.$router.push({
						path: "/RepairDetail",
						query: {
							order_no: order.order_no,
						},
					});
				}
			},

			//师傅详情
			toShopDetail(uid) {
				console.log(uid);
				this.$router.push({
					path: "/shifuShopDetail",
					query: {
						uid: uid,
					},
				});
			},

			//tabs切换
			onTabs(index) {
				this.tabsActive = index;
			},

			toShifu() {
				this.$router.push("/Shifu");
			},

			//搜索地区
			onSearch(value) {
				var _this = this;
				// _this.getShifu(value);
				_this.getOrder(value);
			},

			getLocation() {
				var _this = this;
				var geolocation = new qq.maps.Geolocation(
					"U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX",
					"地图-mapApp"
				);
				var options = {
					timeout: 8000,
				};
				geolocation.getIpLocation(_this.showPosition, _this.showErr);
			},

			showPosition(position) {
				this.location = position;
				// alert(position);
				console.log(position);
			},

			showErr() {
				alert("定位失败");
				console.log("定位失败");
			},
		},
	};
</script>
<style scoped>
	.setService-mask {
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		padding: 20px;
		border-radius: 10px;
	}

	.setService-mask .title {
		text-align: center;
		margin-bottom: 10px;
	}

	.setService-mask .input-item {
		display: flex;
		border-bottom: 1px solid #f2f2f2;
		padding: 10px 0;
	}

	.setService-mask .input-item .tit {
		font-size: 14px;
		width: 30%;
	}

	.setService-mask .input-item input {
		width: 70%;
		font-size: 14px;
		border: none;
	}

	.setService-mask .btn {
		margin: 20px auto 0;
		display: flex;
		justify-content: center;
		color: #fff;
		background: #1989fa;
		border: none;
		width: 100px;
		line-height: 30px;
		text-align: center;
		border-radius: 30px;
		font-size: 14px;
	}
	
	.home .gropu-box .item {
		width:25%;
	}
</style>