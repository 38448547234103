<template>
	<!-- 账单 -->
	<div class="bill-list">
		<Navbar :title="`待入账`"></Navbar>
		<van-notice-bar color="#1989fa" background="#ecf9ff" wrapable :scrollable="false">
			您好师傅，为了保障冷链厂商家及用户的利益，咱们收到的待入账余额在48小时后，才可以让您随时提现。
		</van-notice-bar>
		<ul class="list">
			<li class="item" v-for="(item, index) in list" :key="index">
				<div class="content">
					<p class="title" v-if="item.status == 1">待入账 <span style="font-size:12px;">（已到账户余额）</span></p>
					<p class="title" v-if="item.status == 0">待入账 <span
							style="font-size:12px;">（入账时间：{{item.arrive_time}}）</span></p>
					<p class="time" style="margin-bottom:5px;color:#666;" v-if="item.desc">备注：{{ item.desc }}</p>
					<p class="time">{{ item.created_at }}</p>
				</div>
				<span class="money">{{ item.freeze_account }}</span>
			</li>
			<div class="empty" v-if="list.length <= 0">
				<van-empty description="暂无数据" />
			</div>
		</ul>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import {
		loginAlert
	} from "../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				list: "",
				userInfo: ""
			};
		},
		computed: {},
		created() {
			var _this = this;
			if (this.$cookies.isKey("userInfo")) {
				_this.userInfo = this.$cookies.get("userInfo")
				this.getBillLog();
			} else {
				loginAlert()
				return;
			}
		},
		mounted() {},
		watch: {},
		methods: {
			getBillLog() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id
				}
				indexOp("/freeze_log/index", data).then((res) => {
					_this.list = res.data;
					console.log(res);
				});
			},
		},
	};
</script>

<style scoped>
</style>