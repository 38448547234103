<template>
	<div class="home container expo-detail">
		<Navbar :title="info.title"></Navbar>

		<van-tabs v-model="active">
			<van-tab title="展会预览"></van-tab>
			<van-tab title="参展商"></van-tab>
		</van-tabs>


		<template v-if="active==0">
			<div class="swiper-box" style="padding:0px;">
				<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true"
					style="border-radius: 0px;padding:0px;border:0px;margin:0;">
					<van-swipe-item v-for="(item, index) in info.images" :key="index" style="width:100%;">
						<a>
							<img :src="baseUrl + item" alt="" />
						</a>
					</van-swipe-item>
				</van-swipe>
			</div>
			<div class="content">
				<div class="title-box">
					<div class="title">{{ info.title }}</div>
					<div class="time">
						<div class="left">
							<van-icon name="clock-o" style="margin-right: 5px" size="14" />
							{{ info.start_time.substring(0,10) }} 至 {{ info.end_time.substring(0,10) }}
						</div>
						<div class="right">{{ getExpoStatus(info) }}</div>
					</div>
				</div>
				<!-- <div class="visit-box">
					<div class="content">
						<img v-if="info.images" :src="baseUrl + info.images[0]" alt="" />
						<div class="right-box" @click="toJoinExpo">
							<span class="tit">参展商</span>
							<span class="subtit">I want to visit</span>
							<span class="btn">我是参展商</span>
						</div>
					</div>
				</div> -->
				<div class="detail">
					<p v-html="info.content"></p>
				</div>
			</div>
		</template>

		<template v-else>
			<div class="content">
				<div class="partin-list">
					<!-- <div class="title">
						<span class="left">参展商</span>
						<span class="right" @click="
				  $router.push({
				    path: '/sponsorJoinerAdmin',
				    query: { id: info.id },
				  })
				">参展商管理</span>
					</div> -->
					<div class="item" v-for="(item, index) in info.expo_join_data" :key="index"
						style="flex-wrap: wrap;">
						<!-- <div class="left">
							<img class="avatar" v-if="item.expo_join_user" :src="baseUrl + item.expo_join_user.company_logo"
								alt="" />
							<div class="btn" @click="tojoinerDetail(item.join_uid)">
								访问主页
							</div>
						</div> -->
						<div class="right" style="width:100%;">
							<div class="status" v-if="item.status == 'WAITING'">等待确认</div>
							<div class="status" style="background: #6dd400" v-if="item.status == 'SUCCESS'">
								确认参展
							</div>
							<div class="status" style="background: red" v-if="item.status == 'FAIL'">
								拒绝参展
							</div>
							<div class="title">
								参展商名称：{{ item.expo_join_user.company_name }}
							</div>
							<div class="address">
								参展商地址：{{ item.expo_join_user.company_address }}
							</div>
							<div class="address">
								<span>联系方式：{{ item.expo_join_user.company_phone }}</span>
								<div class="btn" @click="toChat(item.join_uid)">
									<i class="iconfont iconduihua"></i>
									<span>发起聊天</span>
								</div>
							</div>
						</div>
						<div class="opera" style="width:100%;" v-if="item.status == 'WAITING'">
							<div class=""
								style="width:100%;background:#ececec;height:35px;display: flex;align-items: center;justify-content: center;margin-top:10px;"
								@click="joinSuccess(item.expo_id,item.join_uid)">审核通过</div>
							<div class=""
								style="width:100%;background:#ea3f3f;color:#fff;height:35px;display: flex;align-items: center;justify-content: center;margin-top:10px;"
								@click="joinFail(item.expo_id,item.join_uid)">审核拒绝</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<!-- <div class="swiper-box">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true">
				<van-swipe-item v-for="(item, index) in info.images" :key="index">
					<a>
						<img :src="baseUrl + item" alt="" />
					</a>
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="content">
			<div class="title-box">
				<div class="title">{{ info.title }}</div>
				<div class="time">
					<div class="left">
						<van-icon name="clock-o" />展会时间：{{ info.start_time }} 至
						{{ info.end_time }}
					</div>
					<div class="right">{{ getExpoStatus(info) }}</div>
				</div>
			</div>

			<div class="organiser">
				<div class="info bg" v-if="info.expo_sponsor_user">
					<div class="left">
						<img class="avatar" :src="baseUrl + info.expo_sponsor_user.company_logo" alt="" />
						<div class="btn">访问主页</div>
					</div>
					<div class="right">
						<div class="title">
							主办方名称：{{ info.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							主办方地址：{{ info.expo_sponsor_user.company_address }}
						</div>
						<div class="address">
							<span>联系方式：{{ info.expo_sponsor_user.company_phone }}</span>
							<div class="btn" @click="toChat(info.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="expo-info">
				<div class="title" v-if="info.videos && info.videos.length > 0">
					展会视频：
				</div>
				<div class="video-box" v-if="info.videos && info.videos.length > 0">
					<video :src="baseUrl + info.videos[0]" controls="controls"></video>
				</div>
				<div class="title">展会介绍：</div>
				<div class="desc-box">
					{{ info.desc }}
				</div>
				<div class="title">合作单位：</div>
				<div class="company-box">
					<a href="javascript:;">{{ info.partner }}</a>
				</div>
			</div>

			<div class="partin-list">
				<div class="title">
					<span class="left">参展商</span>
					<span class="right" @click="
              $router.push({
                path: '/sponsorJoinerAdmin',
                query: { id: info.id },
              })
            ">参展商管理</span>
				</div>
				<template v-if="info.expo_join_data && info.expo_join_data.length > 0">
					<div class="item" v-for="(item, index) in info.expo_join_data" :key="index">
						<div class="left">
							<img class="avatar" v-if="item.expo_join_user"
								:src="baseUrl + item.expo_join_user.company_logo" alt="" />
							<div class="btn" @click="tojoinerDetail(item.join_uid)">
								访问主页
							</div>
						</div>
						<div class="right">
							<div class="status" v-if="item.status == 'WAITING'">等待确认</div>
							<div class="status" style="background: #6dd400" v-if="item.status == 'SUCCESS'">
								确认参展
							</div>
							<div class="status" style="background: red" v-if="item.status == 'FAIL'">
								拒绝参展
							</div>
							<div class="title">
								参展商名称：{{ item.expo_join_user.company_name }}
							</div>
							<div class="address">
								参展商地址：{{ item.expo_join_user.company_address }}
							</div>
							<div class="address">
								<span>联系方式：{{ item.expo_join_user.company_phone }}</span>
								<div class="btn" @click="toChat(item.join_uid)">
									<i class="iconfont iconduihua"></i>
									<span>发起聊天</span>
								</div>
							</div>
						</div>
					</div>
				</template>
				<van-empty v-else description="暂无数据" />
			</div> -->

		<!-- <div class="partin-list service">
				<div class="title">
					<span class="left">服务商</span>
					<span class="right" @click="
              $router.push({
                path: '/sponsorExpoAddService',
                query: { id: info.id },
              })
            ">服务商管理</span>
				</div>
				<div class="service-title">
					<span :class="service_tab == '1' ? 'item active' : 'item'"
						@click="onChangeServiceTab('1')">搭建商</span>
					<span :class="service_tab == '2' ? 'item active' : 'item'"
						@click="onChangeServiceTab('2')">租赁商</span>
				</div>
				<van-list
                v-model="loading"
                :finished="finished"
                :finished-text="service_list.length > 0 ? '没有更多了' : ''"
                @load="getServiceList"
                >
				<div class="item" v-for="(item, index) in service_list" :key="index" @click="toServiceIndex(item.id)">
					<div class="left">
						<img class="avatar" :src="baseUrl + item.images[0]" alt="" />
						<div class="btn" style="background: #f7b500" @click="toServiceIndex(item.id)">
							访问主页
						</div>
					</div>
					<div class="right">
						<div class="status" v-if="item.status == 'WAITING'">等待确认</div>
						<div class="status" style="background: #6dd400" v-if="item.status == 'SUCCESS'">
							确认参展
						</div>
						<div class="status" style="background: red" v-if="item.status == 'FAIL'">
							拒绝参展
						</div>
						<div class="title">服务商名称：{{ item.company_name }}</div>
						<div class="address">服务商地址：{{ item.company_address }}</div>
						<div class="address">
							<span>联系方式：{{ item.company_phone }}</span>
							<div class="btn" @click="toChat(item.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
				<van-empty v-if="service_list.length == 0" description="暂无数据" />
				</van-list>

				<div class="more-btn">点击加载更多</div>
			</div> -->

		<!-- <div class="partin-list">
				<div class="title">
					<span class="left">参观者</span>
					<span class="right" @click="
              $router.push({
                path: '/sponsorVisitAdmin',
                query: { id: info.id },
              })
            ">参观者管理</span>
				</div>
				<div class="item" v-for="(item, index) in visit_list" :key="index">
					<div class="left">
						<img class="avatar" src="../../assets/image/avatar.png" alt="" />
						<div class="btn" @click="tojoinerDetail(item.join_uid)">访问主页</div>
					</div>
					<div class="right">
						<div class="status" v-if="item.status == 'WAITING'">等待确认</div>
						<div class="status" style="background: #6dd400" v-if="item.status == 'SUCCESS'">
							确认参展
						</div>
						<div class="status" style="background: red" v-if="item.status == 'FAIL'">
							拒绝参展
						</div>
						<div class="title">企业名称：{{ item.company_name }}</div>
						<div class="address">企业地址：{{ item.address }}</div>
						<div class="address">
							<span>联系方式：{{ item.mobile }}</span>
							<div class="btn" @click="toChat(item.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div>
						</div>
					</div>
				</div>
				<van-empty v-if="visit_list.length == 0" description="暂无数据" />
			</div> -->
		<!-- </div> -->
		<div class="btn-box" style="bottom:105px;">
			<div class="btns" @click="toEdit">
				<van-icon name="edit" />
				<span>修改</span>
			</div>
			<div class="btns" @click="onDelete">
				<van-icon name="delete-o" />
				<span>删除</span>
			</div>
		</div>
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {

				active: 0,

				expo_id: "",
				baseUrl: base.apiURL,
				info: {},
				userInfo: {
					id: "",
				},
				invate_uid: "",
				service_tab: "1",
				service_list: [],
				loading: false,
				finished: false,
				page: "",

				visit_list: [],
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.expo_id = this.$route.query.id;
			this.getInfo();
			this.setShare();
			this.wxLogin();
			this.getVisitList();
		},
		mounted() {},
		watch: {},
		methods: {
			joinSuccess(expoId,joinId) {
				var _this = this;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认审核通过吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						var data = {
							expo_id: expoId,
							join_uid : joinId,
							status : 'SUCCESS'
						};
						addOp("/expo_joiner/update", data).then((res) => {
							if (res && res.data.state == 1000) {
								_this.getInfo();
							} else {
								_this.$toast.fail(res.data.msg);
							}
						});
					});
			},
			joinFail(expoId,joinId) {
				var _this = this;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认审核通过吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						var data = {
							expo_id: expoId,
							join_uid : joinId,
							status : 'FAIL'
						};
						addOp("/expo_joiner/update", data).then((res) => {
							if (res && res.data.state == 1000) {
								_this.getInfo();
							} else {
								_this.$toast.fail(res.data.msg);
							}
						});
					});
			},
			//获取参观者列表
			getVisitList() {
				var _this = this;
				var data = {
					expo_id: _this.expo_id,
				};
				indexOp("/expo_vistor/index", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.visit_list = res.data.data.data;
							// _this.page = res.data.data.current_page
						} else {
							// _this.finished = true;
						}
						// _this.loading = false
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			//获取服务商列表
			getServiceList() {
				var _this = this;
				var data = {
					expo_id: _this.expo_id,
					// page : _this.page + 1
				};
				if (_this.service_tab == "1") {
					data.builder = "t";
				} else if (_this.service_tab == "2") {
					data.render = "t";
				}
				indexOp("/expo_service_user/index", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.service_list = res.data.data.data;
							_this.page = res.data.data.current_page;
						} else {
							// _this.finished = true;
						}
						// _this.loading = false
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			onChangeServiceTab(tab) {
				this.service_tab = tab;
				this.getServiceList();
			},
			toServiceIndex(id) {
				this.$router.push({
					path: "/serviceIndex",
					query: {
						id: id,
					},
				});
			},
			//访问参展商主页
			tojoinerDetail(id) {
				var _this = this;
				this.$router.push({
					path: "/joinerIndex",
					query: {
						id: id,
					},
				});
			},
			getExpoStatus(info) {
				var _this = this;
				var current_time = new Date().getTime();
				var start_time = new Date(info.start_time).getTime();
				var end_time = new Date(info.end_time).getTime();

				if (current_time < start_time) {
					return "报名中";
				} else if (current_time > start_time && current_time < end_time) {
					return "活动中";
				} else {
					return "活动结束";
				}
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			toChat(toId) {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = toId; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					loginAlert()
				}
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-会展中心`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL +
						"/expoDetail?id=" +
						this.expo_id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			getInfo() {
				var _this = this;
				var data = {
					id: _this.expo_id,
				};
				indexOp("/expo_sponsor_user/show_expo", data).then((res) => {
					if (res && res.data.state == 1000) {
						_this.info = res.data.data;
						_this.getServiceList();
					} else {
						_this.$toast.fail(res.data.msg);
					}
					console.log(res);
				});
			},
			toEdit() {
				this.$router.push({
					path: "/sponsorExpoEdit",
					query: {
						id: this.expo_id,
					},
				});
			},
			onDelete() {
				var _this = this;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认删除这条展会信息吗？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						var data = {
							id: _this.expo_id,
						};
						addOp("/expo_sponsor_user/destroy_expo", data).then((res) => {
							if (res && res.data.state == 1000) {
								_this.$toast.success("删除成功！");
								setTimeout(() => {
									_this.$router.go(-1);
								}, 800);
							} else {
								_this.$toast.fail(res.data.msg);
							}
							console.log(res);
						});
					});
			},
		},
	};
</script>

<style scoped>
	.btn-box {
		position: fixed;
		bottom: 80px;
		right: 20px;
	}

	.btn-box .btns {
		width: 70px;
		height: 30px;
		background: #007aff;
		color: #fff;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		border-radius: 30px;
		box-shadow: 1px 1px 1px 1px #f2f2f2;
	}

	.btn-box .btns i {
		margin-right: 5px;
		font-size: 14px;
	}
</style>