<template>
	<div id="productList" :class="status ? '' : 'none'">
		<div class="product-list">
			<div class="left">
				<div class="item" v-for="(item, index) in left" :key="index" style="position: relative;">
					<div class="imgbox" @click="toClick(item)" >
						<template v-if="item[thumbField]">
							<block v-if="isImage(item[thumbField])">
								<img :src="url + (typeof item[thumbField] != 'string' ? item[thumbField][0] : item[thumbField] )" alt="" />
							</block>
							<block v-else>
								<video :src="url + item.image_cover" controls muted style="width: 100%;height:100%"></video>
							</block>
						</template>
						<svg v-else t="1692942186882" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="4080" width="200" height="200">
							<path
								d="M87 160h850c13.255 0 24 10.745 24 24v656c0 13.255-10.745 24-24 24H87c-13.255 0-24-10.745-24-24V184c0-13.255 10.745-24 24-24z"
								fill="#C5C5C5" fill-opacity=".45" p-id="4081"></path>
							<path
								d="M111 864l128.17-128.361c22.092-22.125 57.936-22.151 80.06-0.06 22.124 22.091 57.968 22.065 80.06-0.06l120.576-120.756c39.023-39.081 102.34-39.128 141.42-0.105L911 864H111z"
								fill="#ABABAB" fill-opacity=".574" p-id="4082"></path>
							<path d="M321 487m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#ABABAB"
								fill-opacity=".574" p-id="4083"></path>
							<path d="M71 160h882a8 8 0 0 1 8 8v104H63V168a8 8 0 0 1 8-8z" fill="#ABABAB"
								fill-opacity=".5" p-id="4084"></path>
							<path d="M287 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4085"></path>
							<path d="M207 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4086"></path>
							<path d="M127 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4087"></path>
						</svg>
					</div>
					<div class="bottom" @click="toClick(item)" >
						<div class="title">{{ item.goods_name }}</div>
					</div>
					<div class="flex" @click="toClick(item)" >
						<div class="price" v-if="showPrice">
							<div class="old">¥{{ item.get_sku[0] ? item.get_sku[0].price : '0.00' }}</div>
							<div class="now">
								¥{{ item.get_sku[0] ? (parseFloat(item.get_sku[0].price)+100) : '0.00' }}
							</div>
						</div>
						<div class="view" v-if="showPreviewIcon">
							<svg t="1687106701783" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="4478" width="200" height="200">
								<path d="M512 512m-447.8 0a447.8 447.8 0 1 0 895.6 0 447.8 447.8 0 1 0-895.6 0Z"
									fill="#1989fa" p-id="4479" data-spm-anchor-id="a313x.7781069.0.i0" class="selected">
								</path>
								<path
									d="M298.8 423.8l-17.1-10 17.1 10z m426.4 0l17.1-10-17.1 10z m0 176.4l-17.1-10 17.1 10z m-426.4 0l17.1-10-17.1 10z m17.1-166.4c88-150.3 304.3-150.3 392.3 0l34.1-20C639 237.4 385 237.4 281.8 413.8l34.1 20z m392.2 0c28.3 48.3 28.3 108.2 0 156.5l34.1 20c35.5-60.6 35.5-135.8 0-196.4l-34.1 19.9z m0 156.4c-88 150.3-304.3 150.3-392.3 0l-34.1 20c103.3 176.4 357.2 176.4 460.5 0l-34.1-20z m-392.2 0c-28.3-48.3-28.3-108.2 0-156.5l-34.1-20c-35.5 60.6-35.5 135.8 0 196.4l34.1-19.9z m270-76.6c0 41.9-33.4 75.4-74 75.4v39.5c62.9 0 113.5-51.7 113.5-114.9h-39.5zM512 589c-40.5 0-73.9-33.5-73.9-75.4h-39.5c0 63.2 50.5 114.9 113.4 114.9V589z m-73.9-75.4c0-42 33.4-75.4 73.9-75.4v-39.5c-63 0-113.4 51.7-113.4 115h39.5z m73.9-75.4c40.5 0 74 33.5 74 75.4h39.5c0-63.2-50.5-115-113.5-115v39.6z"
									fill="#FFFFFF" p-id="4480"></path>
							</svg>
						</div>
					</div>
					<div v-if="editStatus" style="background-color: #c1c1c1;color:#fff;height:30px;display: flex;align-items: center;justify-content: center;" @click="edit(item)">编辑</div>
					<div  v-if="deleteStatus"  style="background-color: #525252;color:#fff;height:30px;display: flex;align-items: center;justify-content: center;"  @click="del(item.id)">删除</div>
				</div>
			</div>
			<div class="right">
				<div class="item" v-for="(item, index) in right" :key="index" style="position: relative;">
					<div class="imgbox" @click="toClick(item)" >
						<template v-if="item[thumbField]">
							<block v-if="isImage(item[thumbField])">
								<img :src="url + (typeof item[thumbField] != 'string' ? item[thumbField][0] : item[thumbField] )" alt="" />
							</block>
							<block v-else>
								<video :src="url + item.image_cover" controls muted style="width: 100%;height:100%"></video>
							</block>
						</template>
						<svg v-else t="1692942186882" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="4080" width="200" height="200">
							<path
								d="M87 160h850c13.255 0 24 10.745 24 24v656c0 13.255-10.745 24-24 24H87c-13.255 0-24-10.745-24-24V184c0-13.255 10.745-24 24-24z"
								fill="#C5C5C5" fill-opacity=".45" p-id="4081"></path>
							<path
								d="M111 864l128.17-128.361c22.092-22.125 57.936-22.151 80.06-0.06 22.124 22.091 57.968 22.065 80.06-0.06l120.576-120.756c39.023-39.081 102.34-39.128 141.42-0.105L911 864H111z"
								fill="#ABABAB" fill-opacity=".574" p-id="4082"></path>
							<path d="M321 487m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#ABABAB"
								fill-opacity=".574" p-id="4083"></path>
							<path d="M71 160h882a8 8 0 0 1 8 8v104H63V168a8 8 0 0 1 8-8z" fill="#ABABAB"
								fill-opacity=".5" p-id="4084"></path>
							<path d="M287 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4085"></path>
							<path d="M207 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4086"></path>
							<path d="M127 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000"
								fill-opacity=".302" p-id="4087"></path>
						</svg>
					</div>
					<div class="bottom" @click="toClick(item)" >
						<div class="title">{{ item.goods_name }}</div>
					</div>
					<div class="flex" @click="toClick(item)" >
						<div class="price" v-if="showPrice">
							<div class="old">¥{{ item.get_sku[0] ? item.get_sku[0].price : '0.00' }}</div>
							<div class="now">
								¥{{ item.get_sku[0] ? (parseFloat(item.get_sku[0].price)+100) : '0.00' }}
							</div>
						</div>
						<div class="view" v-if="showPreviewIcon">
							<svg t="1687106701783" class="icon" viewBox="0 0 1024 1024" version="1.1"
								xmlns="http://www.w3.org/2000/svg" p-id="4478" width="200" height="200">
								<path d="M512 512m-447.8 0a447.8 447.8 0 1 0 895.6 0 447.8 447.8 0 1 0-895.6 0Z"
									fill="#1989fa" p-id="4479" data-spm-anchor-id="a313x.7781069.0.i0" class="selected">
								</path>
								<path
									d="M298.8 423.8l-17.1-10 17.1 10z m426.4 0l17.1-10-17.1 10z m0 176.4l-17.1-10 17.1 10z m-426.4 0l17.1-10-17.1 10z m17.1-166.4c88-150.3 304.3-150.3 392.3 0l34.1-20C639 237.4 385 237.4 281.8 413.8l34.1 20z m392.2 0c28.3 48.3 28.3 108.2 0 156.5l34.1 20c35.5-60.6 35.5-135.8 0-196.4l-34.1 19.9z m0 156.4c-88 150.3-304.3 150.3-392.3 0l-34.1 20c103.3 176.4 357.2 176.4 460.5 0l-34.1-20z m-392.2 0c-28.3-48.3-28.3-108.2 0-156.5l-34.1-20c-35.5 60.6-35.5 135.8 0 196.4l34.1-19.9z m270-76.6c0 41.9-33.4 75.4-74 75.4v39.5c62.9 0 113.5-51.7 113.5-114.9h-39.5zM512 589c-40.5 0-73.9-33.5-73.9-75.4h-39.5c0 63.2 50.5 114.9 113.4 114.9V589z m-73.9-75.4c0-42 33.4-75.4 73.9-75.4v-39.5c-63 0-113.4 51.7-113.4 115h39.5z m73.9-75.4c40.5 0 74 33.5 74 75.4h39.5c0-63.2-50.5-115-113.5-115v39.6z"
									fill="#FFFFFF" p-id="4480"></path>
							</svg>
						</div>
					</div>
					<div v-if="editStatus" style="background-color: #c1c1c1;color:#fff;height:30px;display: flex;align-items: center;justify-content: center;" @click="edit(item)">编辑</div>
					<div v-if="deleteStatus" style="background-color: #525252;color:#fff;height:30px;display: flex;align-items: center;justify-content: center;" @click="del(item.id)">删除</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import base from "../api/base";
	import $ from "jquery";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";

	export default {
		name: 'GoodsList',
		props: {
			status: {
				type: Boolean,
				default: true
			},
			deleteStatus : {
				type: Boolean,
				default: false
			},
			editStatus : {
				type: Boolean,
				default: false
			},
			left: {
				type: Array
			},
			right: {
				type: Array
			},
			showPrice : {
				type: Boolean,
				default: true
			},
			thumbField :{
				type : String,
				default : 'image_cover'
			},
			showPreviewIcon : {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				url: base.apiURL,
			}
		},
		computed: {

		},
		created() {},
		mounted() {

		},
		updated() {

		},
		watch: {

		},
		methods: {
			isImage(src){
				if(typeof src != 'string'){
					src 	=	src[0];
				}
				console.log('src:',src);
				if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(src)) {
					return true;
				}
				return false;
			},
			toClick(item) {
				this.$emit('click', item);
			},
			del(id){
				this.$emit('del', id);
			},
			edit(id){
				this.$emit('edit', id);
			}
		},
		components: {}
	}
</script>

<style lang="less">
	.none {
		display: none;
	}

	.product-list {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: row;
		box-sizing: border-box;

		.right {
			margin-left: 2vw;
		}

		.left,
		.right {
			width: 43vw !important;

			.item {
				padding: 0px !important;
				border-radius: 5px;
				overflow: hidden;
				width: 100% !important;
				display: flex;
				box-sizing: border-box;
				border: 1px;
				background: #F6F6F6;
				margin-bottom: 10px;
				border: 1px solid #FAFAFA;
				justify-content: center !important;
				flex-direction: column !important;

				.imgbox {
					border-radius: 3px;

					img {
						width: 100%;
						height: auto !important;
					}
				}

				.bottom {
					width: 100%;

					.title {
						width: 165px;
						vertical-align: center;
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						color: #333;
						padding-top: 3px;
						padding-bottom: 5px;
						border: none;
					}
				}

				.flex {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 10px;
					box-sizing: border-box;
					width: 100%;
					padding-top: 3px;

					.price {
						width: 60%;

						.old {
							font-size: 15px;
							color: #ff3000;
							font-weight: 600;
						}

						.now {
							font-size: 13px;
							font-weight: 400;
							text-decoration: line-through;
							color: #c4c4c4;
						}
					}
				}

				.view {
					width: 30px;

					svg {
						width: 30px;
						height: 30px;
					}
				}
			}
		}


	}
</style>