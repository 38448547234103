import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	wechatConfig,
	isIOS
} from '../utils/wxConfig'

import Home from '../views/Home.vue'
import Repair from '../views/Repair.vue'
import Shifu from '../views/Shifu.vue'
import RepairDetail from '../views/RepairDetail.vue'
import RepairShare from '../views/RepairShare.vue'
import shifuBidding from '../views/shifu_bidding.vue'
import shifuBiddingEnd from '../views/shifu_bidding_end.vue'
import orderRelease from '../views/order_release.vue'
import mapChoice from '../views/map_choice.vue'
import chat from '../views/chat.vue'
import topic from '../views/topic.vue'
import userCard from '../views/user_card.vue'
// import chat from '../views/chat_copy.vue'
import ZuLin from '../views/zulin.vue'
//师傅身份
import shifuOrderList from '../views/Shifu/order_list.vue'
import shifuRepairDetail from '../views/Shifu/order_detail.vue'
import shifuUser from '../views/Shifu/user.vue'
import shifuShopDetail from '../views/shop/shop_detail.vue'
import shifuSettle from '../views/Shifu/settle.vue'
import editShopinfo from '../views/edit_shopinfo.vue'

//用户身份
import userOrderList from '../views/User/order_list.vue'
import userRepairDetail from '../views/User/order_detail.vue'
import yhUser from '../views/User/user.vue'
import editUserinfo from '../views/edit_userinfo.vue'
import editShifuinfo from '../views/edit_shifuinfo.vue'
import userIndex from "../views/shop/user_shop.vue"

import vip from '../views/vip.vue'
import chatList from '../views/chat_list.vue'
import login from "../views/Login/Login.vue"
import register from "../views/Login/register.vue"
import EditPassword from "../views/Login/edit_password.vue"
import identity from "../views/identity.vue"
import chongzhi from "../views/chongzhi.vue"
import user from "../views/user.vue"
import userEdit from "../views/user_edit.vue"
import tixian from "../views/tixian.vue"
import bill from "../views/bill.vue"
import billDetail from "../views/bill_detail.vue"
import shifuNearby from "../views/shifu_nearby.vue"
import inviteReg from "../views/inviteReg.vue"
import myInviter from "../views/myInviter.vue"

import cityChoice from "../components/cityChoice.vue"
import myShop from "../views/shop/my_shop.vue"
import uploadVideo from "../views/shop/upload_video.vue"
import videoList from "../views/video_detail.vue"

//厂商
import changshangList from "../views/ChangShang/list.vue"
import changshangDetail from "../views/ChangShang/detail.vue"
import changshangIndex from "../views/ChangShang/index.vue"
// import productDetail from "../views/ChangShang/product_detail.vue"
import productDetail from "../views/ChangShang/product_detail_new.vue"
import productList from "../views/ChangShang/product_list.vue"
import productAdd from "../views/ChangShang/product_add.vue"
import productEdit from "../views/ChangShang/product_edit.vue"
import newDetail from "../views/ChangShang/new_detail.vue"
import newAdd from "../views/ChangShang/new_add.vue"
import classAdd from "../views/ChangShang/class_add.vue"
import classList from "../views/ChangShang/class_list.vue"
import newsList from "../views/ChangShang/new_list.vue"
import contactUs from "../views/ChangShang/contact_us.vue"
import csBillLog from "../views/ChangShang/bill_log.vue"

import userManage from "../views/ChangShang/user_manage.vue"
import userManageAdd from "../views/ChangShang/user_manage_add.vue"
import userManageEdit from "../views/ChangShang/user_manage_edit.vue"

//配件模具
import peijianList from "../views/peijian/list.vue"
import peijianDetail from "../views/peijian/detail.vue"
import peijianIndex from "../views/peijian/index.vue"
import peijian_productDetail from "../views/peijian/product_detail.vue"
import peijian_productList from "../views/peijian/product_list.vue"
import peijian_productAdd from "../views/peijian/product_add.vue"
import peijian_newDetail from "../views/peijian/new_detail.vue"
import peijian_newAdd from "../views/peijian/new_add.vue"
import peijian_classAdd from "../views/peijian/class_add.vue"
import peijian_classList from "../views/peijian/class_list.vue"
import peijian_newsList from "../views/peijian/new_list.vue"
import peijian_contactUs from "../views/peijian/contact_us.vue"
import editPeijianInfo from "../views/edit_peijianinfo.vue"

import peijian_videoList from "../views/peijian/video_list.vue"

import account from "../views/account.vue"
import accountAdd from "../views/account_add.vue"
import accountBalance from "../views/account_balance.vue"
import zhuanzhang from "../views/zhuanzhang.vue"
import freezeLog from "../views/freeze_log.vue"

import noticeLog from "../views/notice_log.vue"

// 技术交流
import technologyIndex from "../views/technology/index.vue"
import messagePublish from "../views/technology/publish.vue"
import messageMine from "../views/technology/mine.vue"
import messageInfo from "../views/technology/messageInfo.vue"

//会展中心
import expoIndex from "../views/expo/index"
import expoList from "../views/expo/expo_list"
import expoDetail from "../views/expo/expo_detail"
import joinServiceExpo from "../views/expo/join_service_expo"
import joinExpo from "../views/expo/join_expo"
import joinVisit from "../views/expo/join_visit"
import expoNewsDetail from "../views/expo/expo_new_detail"
//主办方页面
import sponsorIndex from "../views/expo/sponsor_index"
import sponsorUser from "../views/expo/sponsor_user"
import sponsorUserEdit from "../views/expo/sponsor_user_edit"
import sponsorExpoAdd from "../views/expo/sponsor_expo_add"
import sponsorExpoEdit from "../views/expo/sponsor_expo_edit"
import sponsorExpoDetail from "../views/expo/sponsor_expo_detail"
import sponsorExpoAddService from "../views/expo/sponsor_expo_service_add"
import sponsorJoinerAdmin from "../views/expo/sponsor_joiner_admin"
import sponsorVisitAdmin from "../views/expo/sponsor_visit_admin"
//参展商页面
import joinerIndex from "../views/expo/joiner_index"
import joinerUser from "../views/expo/joiner_user"
import joinerUserEdit from "../views/expo/joiner_user_edit"
import joinerProductAdd from "../views/expo/joiner_product_add"
import joinerProductEdit from "../views/expo/joiner_product_edit"
import joinerProductDetail from "../views/expo/joiner_product_detail"
import productIndex from "../views/expo/product_index"


import GoodsShopSettle from "../views/GoodsShop/Settle";



//  知识库
import KnowledgeIndex from "../views/knowledge/index";
import KnowledgeDetail from "../views/knowledge/detail";

//服务商页面

Vue.use(VueRouter)
import serviceIndex from "../views/expo/service_index"
import uploadImage from "../views/uploadImage"

//新商品发布 
import shopIndex from "../views/shop/index.vue"
import shopProductfabu from "../views/shop/fabu.vue"
import shopProductfabuTk from "../views/shop/fabu_tk.vue"
import shopProductDetail from "../views/shop/product_detail.vue"
import shopProductEdit from "../views/shop/product_edit.vue"
import myShopIndex from "../views/shop/my_shop_new.vue"

const routes = [
	// 新商品发布
	{
		path: '/shop/edit',
		name: '/shop/edit',
		component: shopProductEdit,
		meta: {
			title: '产品修改'
		}
	},
	{
		path: '/shop/index',
		name: '/shop/index',
		component: shopIndex,
	},
	{
		path: '/shop/myShopIndex',
		name: '/shop/myShopIndex',
		component: myShopIndex,
	},
	{
		path: '/shop/fabu',
		name: '/shop/fabu',
		component: shopProductfabu,
		meta: {
			title: '产品发布'
		}
	},
	{
		path: '/shop/fabu_tk',
		name: '/shop/fabu_tk',
		component: shopProductfabuTk,
		meta: {
			title: '产品发布'
		}
	},
	//商品详情
	{
		path: '/shop/detail',
		name: '/shop/detail',
		component: shopProductDetail,
		meta: {
			title: '产品详情'
		}
	},

	// 知识库
	{
		path: '/knowledge/index',
		name: '/knowledge/index',
		component: KnowledgeIndex,
		meta: {
			title: '知识库'
		}
	},
	{
		path: '/knowledge/detail',
		name: '/knowledge/detail',
		component: KnowledgeDetail,
		meta: {
			title: '知识库'
		}
	}

	, {
		path: '/',
		name: 'Home',
		component: Home
	}, {
		path: '/GoodsShopSettle',
		name: 'GoodsShopSettle',
		component: GoodsShopSettle
	}, {
		path: '/uploadImage',
		name: 'uploadImage',
		component: uploadImage
	},
	{
		path: '/peijian_videoList',
		name: 'peijian_videoList',
		component: peijian_videoList
	},
	{
		path: '/userIndex',
		name: 'userIndex',
		component: userIndex
	}, {
		path: '/userCard',
		name: 'userCard',
		component: userCard
	},
	{
		path: '/topic',
		name: 'topic',
		component: topic
	},
	{
		path: '/userManage',
		name: 'userManage',
		component: userManage
	},
	{
		path: '/userManageAdd',
		name: 'userManageAdd',
		component: userManageAdd
	},
	{
		path: '/userManageEdit',
		name: 'userManageEdit',
		component: userManageEdit
	},
	{
		path: '/Repair',
		name: 'Repair',
		component: Repair,
		meta: {
			keepAlive: false // 不需要缓存
		}
	},
	{
		path: '/RepairShare',
		name: 'RepairShare',
		component: RepairShare,
		meta: {
			keepAlive: false // 不需要缓存
		}
	},
	{
		path: '/RepairDetail',
		name: 'RepairDetail',
		component: RepairDetail
	},
	{
		path: '/Shifu',
		name: 'Shifu',
		component: Shifu,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/zulin',
		name: 'ZuLin',
		component: ZuLin,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/shifuUser',
		name: 'shifuUser',
		component: shifuUser
	},
	{
		path: '/yhUser',
		name: 'yhUser',
		component: yhUser
	},
	{
		path: '/shifuBidding',
		name: 'shifuBidding',
		component: shifuBidding
	},
	{
		path: '/shifuBiddingEnd',
		name: 'shifuBiddingEnd',
		component: shifuBiddingEnd
	},
	{
		path: '/chat',
		name: 'chat',
		component: chat
	},
	{
		path: '/orderRelease',
		name: 'orderRelease',
		component: orderRelease
	},
	{
		path: '/mapChoice',
		name: 'mapChoice',
		component: mapChoice
	},
	{
		path: '/shifuRepairDetail',
		name: 'shifuRepairDetail',
		component: shifuRepairDetail
	},
	{
		path: '/shifuOrderList',
		name: 'shifuOrderList',
		component: shifuOrderList
	},
	{
		path: '/userOrderList',
		name: 'userOrderList',
		component: userOrderList
	},
	{
		path: '/userRepairDetail',
		name: 'userRepairDetail',
		component: userRepairDetail
	},
	{
		path: '/shifuShopDetail',
		name: 'shifuShopDetail',
		component: shifuShopDetail
	},
	{
		path: '/vip',
		name: 'vip',
		component: vip
	},
	{
		path: '/shifuSettle',
		name: 'shifuSettle',
		component: shifuSettle
	},
	{
		path: '/chatList',
		name: 'chatList',
		component: chatList
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/register',
		name: 'register',
		component: register
	},
	{
		path: '/identity',
		name: 'identity',
		component: identity
	},
	{
		path: '/chongzhi',
		name: 'chongzhi',
		component: chongzhi
	},
	{
		path: '/user',
		name: 'user',
		component: user
	},
	{
		path: '/inviteReg',
		name: 'inviteReg',
		component: inviteReg
	},
	{
		path: '/myInviter',
		name: 'myInviter',
		component: myInviter
	},
	{
		path: '/userEdit',
		name: 'userEdit',
		component: userEdit
	},
	{
		path: '/editShopinfo',
		name: 'editShopinfo',
		component: editShopinfo
	},
	{
		path: '/editUserinfo',
		name: 'editUserinfo',
		component: editUserinfo
	},
	{
		path: '/editShifuinfo',
		name: 'editShifuinfo',
		component: editShifuinfo
	},
	{
		path: '/EditPassword',
		name: 'EditPassword',
		component: EditPassword
	},
	{
		path: '/tixian',
		name: 'tixian',
		component: tixian
	},
	{
		path: '/bill',
		name: 'bill',
		component: bill
	},
	{
		path: '/shifuNearby',
		name: 'shifuNearby',
		component: shifuNearby
	},
	{
		path: '/cityChoice',
		name: 'cityChoice',
		component: cityChoice
	},
	{
		path: '/myShop',
		name: 'myShop',
		component: myShop,
		meta: {
			title: '产品详情'
		}
	},
	{
		path: '/upload',
		name: 'uploadVideo',
		component: uploadVideo
	},
	{
		path: '/changshangList',
		name: 'changshangList',
		component: changshangList
	},
	{
		path: '/changShang/BillLog',
		name: 'changshangBillLog',
		component: csBillLog
	},
	{
		path: '/changshangDetail',
		name: 'changshangDetail',
		component: changshangDetail
	},
	{
		path: '/changshangIndex',
		name: 'changshangIndex',
		component: changshangIndex
	},
	{
		path: '/productDetail',
		name: 'productDetail',
		component: productDetail
	},
	{
		path: '/newDetail',
		name: 'newDetail',
		component: newDetail
	},
	{
		path: '/productList',
		name: 'productList',
		component: productList
	},
	{
		path: '/productAdd',
		name: 'productAdd',
		component: productAdd
	},
	{
		path: '/productEdit',
		name: 'productEdit',
		component: productEdit
	},
	{
		path: '/newAdd',
		name: 'newAdd',
		component: newAdd
	},
	{
		path: '/classAdd',
		name: 'classAdd',
		component: classAdd
	},
	{
		path: '/newsList',
		name: 'newsList',
		component: newsList
	},
	{
		path: '/contactUs',
		name: 'contactUs',
		component: contactUs
	},
	{
		path: '/classList',
		name: 'classList',
		component: classList
	},
	{
		path: '/account',
		name: 'account',
		component: account
	},
	{
		path: '/accountAdd',
		name: 'accountAdd',
		component: accountAdd
	},
	{
		path: '/videoList',
		name: 'videoList',
		component: videoList
	},
	{
		path: '/accountBalance',
		name: 'accountBalance',
		component: accountBalance
	},
	{
		path: '/peijianList',
		name: 'peijianList',
		component: peijianList
	},
	{
		path: '/peijianDetail',
		name: 'peijianDetail',
		component: peijianDetail
	},
	{
		path: '/peijianIndex',
		name: 'peijianIndex',
		component: peijianIndex
	},
	{
		path: '/peijian_productDetail',
		name: 'peijian_productDetail',
		component: peijian_productDetail
	},
	{
		path: '/peijian_productList',
		name: 'peijian_productList',
		component: peijian_productList
	},
	{
		path: '/peijian_productAdd',
		name: 'peijian_productAdd',
		component: peijian_productAdd
	},
	{
		path: '/peijian_newDetail',
		name: 'peijian_newDetail',
		component: peijian_newDetail
	},
	{
		path: '/peijian_newAdd',
		name: 'peijian_newAdd',
		component: peijian_newAdd
	},
	{
		path: '/peijian_classAdd',
		name: 'peijian_classAdd',
		component: peijian_classAdd
	},
	{
		path: '/peijian_classList',
		name: 'peijian_classList',
		component: peijian_classList
	},
	{
		path: '/peijian_newsList',
		name: 'peijian_newsList',
		component: peijian_newsList
	},
	{
		path: '/peijian_contactUs',
		name: 'peijian_contactUs',
		component: peijian_contactUs
	},
	{
		path: '/editPeijianInfo',
		name: 'editPeijianInfo',
		component: editPeijianInfo
	},
	{
		path: '/noticeLog',
		name: 'noticeLog',
		component: noticeLog
	},
	{
		path: '/zhuanzhang',
		name: 'zhuanzhang',
		component: zhuanzhang
	},
	{
		path: '/freezeLog',
		name: 'freezeLog',
		component: freezeLog
	},
	{
		path: '/billDetail',
		name: 'billDetail',
		component: billDetail
	},
	{
		path: '/technologyIndex',
		name: 'technologyIndex',
		component: technologyIndex
	},
	{
		path: '/messagePublish',
		name: 'messagePublish',
		component: messagePublish
	},
	{
		path: '/messageMine',
		name: 'messageMine',
		component: messageMine
	},
	{
		path: '/messageInfo',
		name: 'messageInfo',
		component: messageInfo
	},
	{
		path: '/expoIndex',
		name: 'expoIndex',
		component: expoIndex
	},
	{
		path: '/expoList',
		name: 'expoList',
		component: expoList
	},
	{
		path: '/expoDetail',
		name: 'expoDetail',
		component: expoDetail
	},
	{
		path: '/expoNewsDetail',
		name: 'expoNewsDetail',
		component: expoNewsDetail
	},
	{
		path: '/joinServiceExpo',
		name: 'joinServiceExpo',
		component: joinServiceExpo
	},
	{
		path: '/joinExpo',
		name: 'joinExpo',
		component: joinExpo
	},
	{
		path: '/sponsorIndex',
		name: 'sponsorIndex',
		component: sponsorIndex
	},
	{
		path: '/sponsorUser',
		name: 'sponsorUser',
		component: sponsorUser
	},
	{
		path: '/sponsorUserEdit',
		name: 'sponsorUserEdit',
		component: sponsorUserEdit
	},
	{
		path: '/sponsorExpoAdd',
		name: 'sponsorExpoAdd',
		component: sponsorExpoAdd
	},
	{
		path: '/sponsorExpoDetail',
		name: 'sponsorExpoDetail',
		component: sponsorExpoDetail
	},
	{
		path: '/sponsorExpoEdit',
		name: 'sponsorExpoEdit',
		component: sponsorExpoEdit
	},
	{
		path: '/sponsorExpoAddService',
		name: 'sponsorExpoAddService',
		component: sponsorExpoAddService
	},
	{
		path: '/sponsorJoinerAdmin',
		name: 'sponsorJoinerAdmin',
		component: sponsorJoinerAdmin
	},
	{
		path: '/sponsorVisitAdmin',
		name: 'sponsorVisitAdmin',
		component: sponsorVisitAdmin
	},
	{
		path: '/joinerIndex',
		name: 'joinerIndex',
		component: joinerIndex
	},
	{
		path: '/joinerUser',
		name: 'joinerUser',
		component: joinerUser
	},
	{
		path: '/joinerUserEdit',
		name: 'joinerUserEdit',
		component: joinerUserEdit
	},
	{
		path: '/joinerProductAdd',
		name: 'joinerProductAdd',
		component: joinerProductAdd
	},
	{
		path: '/joinerProductEdit',
		name: 'joinerProductEdit',
		component: joinerProductEdit
	},
	{
		path: '/joinerProductDetail',
		name: 'joinerProductDetail',
		component: joinerProductDetail
	},
	{
		path: '/productIndex',
		name: 'productIndex',
		component: productIndex
	},
	{
		path: '/serviceIndex',
		name: 'serviceIndex',
		component: serviceIndex
	},
	{
		path: '/joinVisit',
		name: 'joinVisit',
		component: joinVisit
	},
	{
		path: '*',
		name: '404',
		redirect: "/"
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

//路由导航守卫
// router.beforeEach((to, from, next) => {
//   if (isIOS()) { //如果是ios就在刚进入时就调用wx.config
//     if (from.path === '/chat') {
//       wechatConfig() //请求服务器数据后wx.config
//     }
//   }
//   next();
// });
export default router