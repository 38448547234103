<template>
  <!-- 注册 -->
  <div class="register">
    <Navbar :title="'修改密码'"></Navbar>
    <div class="form-box">
      <!-- <div class="input-item">
        <van-icon class="icon" name="manager" />
        <input type="text" placeholder="请输入账户名" />
      </div> -->
      <!-- <div class="input-item">
        <i class="icon iconfont iconFill" />
        <input v-model="fromData.mobile" type="text" placeholder="请输入手机号" />
      </div> -->
      <!-- <div class="input-item">
        <i class="icon iconfont iconyanzhengma" />
        <input type="text" placeholder="请输入验证码" />
      </div> -->
      <div class="input-item">
        <van-icon class="icon" name="lock" />
        <input
          v-model="fromData.password"
          type="password"
          placeholder="请输入新密码"
        />
      </div>
      <div class="input-item">
        <van-icon class="icon" name="lock" />
        <input
          v-model="fromData.confirm_password"
          type="password"
          placeholder="请再次输入密码"
        />
      </div>
      <button class="sub-btn" @click="onSubmit">修改</button>
    </div>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
// import websocket from '../utils/webSocket'
import { indexOp, uploadImg, updateOp, addOp, uploadFile } from "../../api";
import base from "../../api/base.js";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      fromData: {},
    };
  },
  methods: {
    onSubmit() {
      var _this = this;
      if (_this.fromData.password == undefined) {
        _this.$toast("新密码不能为空！");
        return;
      }
      if (_this.fromData.confirm_password != _this.fromData.password) {
        _this.$toast("两次密码输入不一致！");
        return;
      }
      addOp("/user/update/1", _this.fromData).then((res) => {
        console.log(res);
        if (res && res.status == 200) {
          _this.$toast.success("修改成功！请重新登录。");
          _this.$cookies.remove("Authorization");
          setTimeout(() => {
            _this.$router.push({
              path: "/login",
            });
          },800);
        } else {
          if (res.data.errors) {
            for (var key in res.data.errors) {
              _this.$toast.fail(res.data.errors[key][0]);
            }
          } else {
            _this.$toast.fail("修改失败，请重试！");
          }
        }
      });
    },
  },
};
</script>

<style>
</style>