<template>
  <div
    class="share-background"
    style="padding-bottom: 50px; box-sizing: border-box"
  >
    <div class="bigTitle">您有一个新订单！</div>
    <TencentMapMarker
      :status="mapParams.status"
      :styleClass="mapParams.styleClass"
      :markerList="mapParams.markers"
      :coord="mapParams.coord"
      :polylineLayer="1"
      :key="mapParams.key"
    ></TencentMapMarker>
    <div class="coords">
      您的位置距离目的地&nbsp;<van-tag type="danger"
        >{{ distance == "-" ? "-" : distance / 1000 }}KM</van-tag
      >，预计驾车时间约&nbsp;<van-tag type="danger">{{ duration }}</van-tag
      >&nbsp;分钟。<span class="gps" @click="gpsClick"
        ><van-icon name="guide-o" size="20px" />一键导航</span
      >
    </div>
    <div class="orderInfo">
      <van-cell-group>
        <van-cell>
          <template #title>
            <span style="font-size: 16px; font-weight: bold">
              {{ info.name }}
            </span>
            <van-tag type="danger">{{
              info.is_time_out == 1 ? "保外" : "保内"
            }}</van-tag>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <van-icon name="todo-list-o" size="12" />
            <span style="color: #969799; font-size: 14px">描述信息</span>
          </template>
          <template #label>
            <span style="font-size: 14px; color: #000">{{ info.desc }}</span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <van-icon name="todo-list-o" size="12" />
            <span style="color: #969799; font-size: 14px">详细位置</span>
          </template>
          <template #label>
            <span style="font-size: 14px; color: #000">
              {{ info.province_info ? info.province_info.area_name : ""
              }}{{ info.city_info ? info.city_info.area_name : ""
              }}{{ info.area_info ? info.area_info.area_name : ""
              }}{{ info.address }}
            </span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <van-icon name="todo-list-o" size="12" />
            <span style="color: #969799; font-size: 14px">用户电话</span>
          </template>
          <template #label>
            <span style="font-size: 14px; color: #000">
              <a
                v-if="info.service_id == userInfo.id || userInfo.identity == 9"
                :href="`tel:${info.mobile}`"
                @click.stop
              >
                {{ info.mobile }}
              </a>
              <a v-else href="javascript:;" @click.stop="showVIp">
                <span v-if="info && info.mobile">{{
                  info.mobile.toString().replace(reg, "$1****$2")
                }}</span
                ><span v-else>暂无</span>
              </a>
            </span>
          </template>
        </van-cell>

        <van-cell>
          <template #title>
            <van-icon name="todo-list-o" size="12" />
            <span style="color: #969799; font-size: 14px">故障图片</span>
          </template>
          <template #label>
            <div class="gzimg">
              <van-image
                width="10rem"
                height="10rem"
                fit="fill"
                v-for="(item, index) in info.image"
                :key="index"
                :src="item"
                @click="previewImage"
              />
              <!-- <img v-for="(item, index) in info.image" :key="index" :src="item" alt=""
								@click="onPreviewImg(info.image)" /> -->
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <van-icon name="todo-list-o" size="12" />
            <span style="color: #969799; font-size: 14px">故障视频</span>
          </template>
          <template #label>
            <span
              style="font-size: 14px; color: #000"
              @click="previewVideo(info.video)"
              >点击查看</span
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <div
      class="jd"
      v-if="
        userInfo.identity === 9 ||
        info.service_id === userInfo.id ||
        info.real_uid === userInfo.id
      "
    >
      <van-button class="jingjia-btn" type="info" @click="toChat"
        >进入聊天室</van-button
      >
    </div>
    <div class="jd" v-else>
      <van-button
        class="jingjia-btn"
        type="info"
        @click="toJingjia"
        v-if="info.service_type == 'order_bidding'"
      >
        确认接单</van-button
      >
      <van-button
        class="jingjia-btn"
        type="info"
        @click="toChat"
        v-else-if="
          info.service_type == 'service_ing' && info.service_id == userInfo.id
        "
        >进入聊天室</van-button
      >
      <van-button
        class="jingjia-btn"
        type="info"
        v-else-if="info.service_type == 'service_end'"
        disabled
        >维修完成
      </van-button>
      <van-button class="jingjia-btn" type="info" v-else disabled
        >正在进行中</van-button
      >
    </div>

    <van-overlay :show="setServiceShow" @click="setServiceShow = false">
      <div class="setService-mask" @click.stop>
        <div class="title">完善师傅信息</div>
        <div class="input-item">
          <label class="tit">店铺名称:</label>
          <input
            type="text"
            placeholder="请输入维修店铺名称"
            v-model="service_name"
          />
        </div>
        <div class="input-item">
          <label class="tit">联系人:</label>
          <input
            type="text"
            placeholder="请输入维修店铺联系人"
            v-model="service_person"
          />
        </div>
        <div class="input-item">
          <label class="tit">联系电话:</label>
          <input
            type="text"
            placeholder="请输入维修店铺联系电话"
            v-model="service_phone"
          />
        </div>
        <div class="input-item">
          <label class="tit">所在地区:</label>
          <input
            type="text"
            placeholder="请选择所在地区"
            v-model="areaText[3]"
            readonly
            @click="showArea = true"
          />
        </div>
        <button class="btn" @click="onSetServiceSubmit">保存资料</button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import notice from "../components/notice";
import TencentMapMarker from "../components/TencentMapMarker";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
import { getUser, login } from "../utils/login_toast";
import { wechatConfig } from "../utils/wxConfig";
import province_list from "../utils/area";
import { loginAlert } from "../utils/loginAlert";
import Wechat from "../utils/Wechat";

import { ImagePreview } from "vant";

// import VConsole from "VConsole";
// new VConsole();

export default {
  components: {
    notice,
    TencentMapMarker,
  },
  props: {},
  data() {
    return {
      showVideo: false, // 展示视频
      videoSrc: "", // 视频地址
      order_no: "", //订单id
      info: "", //订单信息
      statusList: [
        {
          index: "order_bidding",
          text: "待接单",
        },
        {
          index: "sure_service_user",
          text: "已确认师傅",
        },
        {
          index: "take_order",
          text: "已接单",
        },
        {
          index: "arrive_order",
          text: "师傅已到达",
        },
        {
          index: "user_sure_arrive",
          text: "确认师傅到达",
        },
        {
          index: "service_ing",
          text: "维修中",
        },
        {
          index: "service_end",
          text: "维修完成",
        },
        {
          index: "user_sure_service",
          text: "确认完成",
        },
        {
          index: "appraise_order",
          text: "已评价",
        },
        // {
        //   index: "pay_money",
        //   text: "订单结束",
        // },
        {
          index: "cancel_order",
          text: "订单取消",
        },
      ],
      identity: "1",
      jingjiaDetail: "",
      uid: "", //自己的id
      payTypeShow: false,
      payType: 1, //1 余额支付 2微信支付
      bj_money: 0, //待补缴金额
      serviceInfo: "",
      jsApiParameters: "",
      shopDetail: "", //店铺信息
      service_type_active: "",
      previewImages: [],
      previewShow: false,
      userInfo: {
        id: "",
      },
      loginURL: base.loginURL,
      to_login: false,
      reg: /(\d{3})\d{4}(\d{4})/,
      jingjiaShow: false,
      xieyiShow: false,

      setServiceShow: false,

      service_name: "",
      service_person: "",
      service_phone: "",
      province: "",
      city: "",
      area: "",
      address: "",
      showArea: false,
      areaList: [], // 数据格式见 Area 组件文档
      areaText: [],

      // 地图所需要的一些数据
      mapParams: {
        styleClass: "map",
        status: false,
        coord: {
          lat: "",
          lng: "",
        },
        markers: [],
        key: 123,
      },

      // 方案的时效
      distance: "-",
      duration: "-",
      distParams: null,
    };
  },
  computed: {},
  created() {
    const _this = this;

    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    var userInfo = this.$cookies.get("userInfo");
    if (!this.$cookies.isKey("Authorization") && !code) {
      this.toLogin();
      return;
    } else if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    } else if (!userInfo && this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    this.order_no = this.$route.query.order_no;
    this.identity = this.$cookies.get("identity");
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
      this.uid = this.$cookies.get("userInfo").id;
    }
    this.getShopDetail();
    this.getOrderDetail();
    // this.getServiceRegion();
  },
  mounted() {},
  watch: {},
  methods: {
    gpsClick() {
      var _this = this;
      if (!this.distParams) {
        _this.$toast.fail("暂无坐标数据");
        return false;
      }
      var href =
        "https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:" +
        _this.distParams[1].lat +
        "," +
        _this.distParams[1].lng +
        ";title:客户位置;addr:" +
        _this.info.full_address +
        "&key=4ZJBZ-QXYCK-W24JM-AOMAK-KWNEE-WRB2D&referer=myapp";
      window.open(href);
    },
    previewVideo(val) {
      if (!val) {
        _this.$toast("暂无视频");
        return false;
      }
      window.open(val[0]);
    },
    previewImage(src) {
      ImagePreview(this.info.image);
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      var data = {
        uid: _this.uid,
      };
      indexOp("/service_user/show", data).then((res) => {
        if (res && res.status == 200) {
          _this.shopDetail = res.data;
          _this.service_name = res.data.name;
          _this.service_person = res.data.person;
          _this.service_phone = res.data.phone;
          _this.province = res.data.province;
          _this.city = res.data.city;
          _this.area = res.data.area;
          if (_this.province) {
            _this.address =
              res.data.province + "-" + res.data.city + "-" + res.data.area;
          }
        }
        // console.log(res);
      });
    },
    //获取师傅详情
    getServerDetail() {
      var _this = this;
      var data = {
        uid: _this.info.service_id,
      };
      indexOp("/service_user/show", data).then((res) => {
        if (res && res.status) {
          _this.serviceInfo = res.data;
        }
      });
    },
    //去对话
    toChat() {
      var _this = this;
      if (_this.$cookies.isKey("userInfo")) {
        var form_uid = _this.userInfo.id; //自己的 uid
        var to_uid = _this.info.uid; //聊天对象 uid
        var data = {
          params: {
            to_uid: to_uid,
            from_uid: form_uid,
            chat_type: "order",
            order_no: _this.order_no,
          },
        };
        addOp("/im/build_chat", data).then((res) => {
          if (res && res.status != 500) {
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                order_no: _this.order_no,
                to_uid: to_uid,
              },
            });
          } else {
            _this.$toast.fail("对话创建失败！");
          }
        });
      } else {
        this.toLogin();
      }
    },
    //完善维修师傅资料
    onSetServiceSubmit() {
      var _this = this;
      if (_this.service_name == "") {
        _this.$toast.fail("店铺名称不能为空！");
        return;
      }
      if (_this.service_person == "") {
        _this.$toast.fail("店铺联系人不能为空！");
        return;
      }
      if (_this.service_phone == "") {
        _this.$toast.fail("店铺联系电话不能为空！");
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        name: _this.service_name,
        person: _this.service_person,
        phone: _this.service_phone,
      };
      addOp("/service_user/create", data).then((res) => {
        _this.$toast.clear();
        if (res && res.data) {
          _this.onSuccessOrder();
        }
      });
    },
    //师傅确认接单
    onSuccessOrder() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
        service_type: "take_order",
      };
      addOp("/service_order/take_order", data).then((res) => {
        if (res && res.data) {
          if (res.data.error) {
            _this.$toast.fail(res.data.error.replace(/[a-zA-z|\.|\/]*/gi, ""));
            return false;
          }
          _this.$dialog
            .confirm({
              title: "接单成功",
              message:
                "是否立即拨打用户电话？了解维修细节，用户位置，维修时间等。",
              confirmButtonText: "拨打电话",
            })
            .then(() => {
              _this.contact_we();
            })
            .catch(() => {
              // on cancel
            });

          _this.$toast.success("接单成功！");
          _this.setServiceLog();
          _this.toChat();
        } else {
          _this.$toast.fail(res.data.error);
        }
      });
    },
    contact_we() {
      var tel = this.info.mobile;
      console.log("拨打电话" + tel);
      var a = document.createElement("a");
      a.href = "tel:" + tel;
      a.click();
    },
    setServiceLog() {
      var _this = this;
      var data = {
        uid: _this.uid,
        order_no: _this.info.order_no,
        service_type: "take_order",
        identity_type: "service",
      };
      addOp("/service_log/create", data).then((res) => {
        // _this.reload();
        // console.log(res);
      });
    },

    onShowXieyi() {
      this.xieyiShow = true;
    },

    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    //分享
    setShare() {
      var vip = "";
      if (this.info.user_vip_info && this.info.user_vip_info.vip_level == 1) {
        vip = "普通会员";
      } else if (
        this.info.user_vip_info &&
        this.info.user_vip_info.vip_level == 2
      ) {
        vip = "黄金会员";
      } else if (
        this.info.user_vip_info &&
        this.info.user_vip_info.vip_level == 3
      ) {
        vip = "钻石会员";
      } else {
        vip = "体验会员";
      }
      var title = "招募制冷维保技师合伙人- " + this.info.name,
        provinceName = this.info.province_info
          ? this.info.province_info.area_name
          : "暂无",
        cityName = this.info.city_info ? this.info.city_info.area_name : "暂无",
        areaName = this.info.area_info ? this.info.area_info.area_name : "暂无";

      const shareInfo = {
        title:
          "招募制冷维保技师合伙人- " +
          this.info.name +
          " " +
          provinceName +
          "-" +
          cityName +
          "-" +
          areaName,
        desc: "点确认接单，进单子的聊天室里，报上门检修费，先付款再维修，让师傅更放心！",
        link:
          base.shareURL +
          "/RepairDetail?order_no=" +
          this.order_no +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },

    getOrderDetail() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
      };
      indexOp("/service_order/show", data).then((res) => {
        if (res && res.status) {
          _this.info = res.data;
          _this.service_type_active = res.data.service_type;
          _this.setShare();
          if (res.data.service_id) {
            _this.getServerDetail();
          }

          console.log("mapParams:", this.mapParams);

          // 开始获取规划路线的距离
          Wechat.location(function (lres) {
            // lres = {};
            // lres.latitude = 39.958972;
            // lres.longitude = 116.39852

            if (!lres) {
              _this.$toast.fail("获取地理位置失败");
              return false;
            }
            // 请求驾车距离
            indexOp("/tencent_map_lbs/directionDriving", {
              start: {
                lat: lres.latitude,
                lng: lres.longitude,
              },
              end: {
                lat: res.data.lat,
                lng: res.data.lng,
              },
              address: _this.info.full_address,
            }).then(function (dires) {
              console.log("Dires:", dires);

              if (dires && dires.status == 200) {
                _this.distance = dires.data[0].routes[0].distance;
                _this.duration = dires.data[0].routes[0].duration;

                _this.distParams = dires.data;

                _this.mapParams.coord = {
                  lat: dires.data[1].lat,
                  lng: dires.data[1].lng,
                };
                _this.mapParams.markers = [
                  {
                    id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                    styleId: "myStyle", //指定样式id
                    position: new TMap.LatLng(
                      dires.data[1].lat,
                      dires.data[1].lng
                    ), //点标记坐标位置
                    content: "客户终点位置",
                    properties: {
                      //自定义属性
                      title: "客户位置",
                    },
                  },
                ];

                _this.mapParams.status = true;
                _this.mapParams.key = Math.random();
              }
            });
          });
        }
      });
    },

    //去报价
    toJingjia() {
      var _this = this;
      if (!_this.$cookies.isKey("userInfo")) {
        this.toLogin();
        return;
      }
      var userInfo = this.$cookies.get("userInfo");
      if (userInfo.identity == 2 || userInfo.identity == 0) {
        console.log(this.shopDetail);
        if (this.shopDetail && this.shopDetail.name && this.shopDetail.person) {
          _this.onSuccessOrder();
        } else {
          _this.setServiceShow = true;
        }
      } else {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "您不是维修师傅，不能参与报价，可去个人中心修改身份信息！",
            confirmButtonText: "去修改",
          })
          .then(() => {
            this.$router.push({
              path: "/identity",
            });
            // on confirm
          });
      }
    },

    toLogin() {
      var _this = this;
      loginAlert("登陆后查看维修订单详情，请先登录", 1, "点击前往登录");
    },

    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.identity = userInfo.identity;
      if (userInfo.identity == 2) {
        this.uid = userInfo.id;
        this.getJingjiaDetail();
        this.getShopDetail();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@ThemColor: #2f78dc;

.share-background {
  // background-image: url(https://static.op-wx.cn/zglxgw.com/RepairShare/background.png);
  min-height: 100vh;
  min-width: 100vw;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .bigTitle {
    height: 5vh;
    background: @ThemColor;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .orderInfo {
    width: 100vw;
  }

  .jd {
    width: 100vw;
    height: 6vh;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;

    button {
      width: 100%;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .map {
    height: 50vh;
    background: #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.setService-mask {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 20px;
  border-radius: 10px;
  z-index: 1500;
}

.setService-mask .title {
  text-align: center;
  margin-bottom: 10px;
}

.setService-mask .input-item {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
}

.setService-mask .input-item .tit {
  font-size: 14px;
  width: 30%;
}

.setService-mask .input-item input {
  width: 70%;
  font-size: 14px;
  border: none;
}

.setService-mask .btn {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #1989fa;
  border: none;
  width: 100px;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
}

.van-overlay {
  z-index: 2000 !important;
}

// .v
.coords {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #fff;
  line-height: 28px;
  position: relative;

  .gps {
    background-color: #2f78dc;
    padding: 0px;
    color: #fff;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 10px;
    bottom: 0px;
  }
}
</style>
