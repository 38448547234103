<template>
  <div class="home container expo-detail">
    <Navbar :title="'申请成为服务商'"></Navbar>

    <div class="swiper-box">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in info.images" :key="index">
          <a>
            <img :src="baseUrl + item" alt="" />
          </a>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="content">
      <div class="title-box">
        <div class="title">{{ info.title }}</div>
        <div class="time">
          <div class="left">
            <van-icon name="clock-o" />展会时间：{{ info.start_time }} 至
            {{ info.end_time }}
          </div>
          <div class="right">{{ info.format_time_text }}</div>
        </div>
      </div>

      <div class="organiser">
        <div class="info bg" v-if="info.expo_sponsor_user">
          <div class="left">
            <img
              class="avatar"
              :src="baseUrl + info.expo_sponsor_user.company_logo"
              alt=""
            />
            <div class="btn" @click="toSponsorIndex(info.uid)">访问主页</div>
          </div>
          <div class="right">
            <div class="title">
              主办方名称：{{ info.expo_sponsor_user.company_name }}
            </div>
            <div class="address">
              主办方地址：{{ info.expo_sponsor_user.company_address }}
            </div>
            <div class="address">
              <span>联系方式：{{ info.expo_sponsor_user.company_phone }}</span>
              <div class="btn" @click="toChat(info.uid)">
                <i class="iconfont iconduihua"></i>
                <span>发起聊天</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-box">
        <div class="title">申请成为服务商</div>
        <div class="input-item">
          <div class="label">企业名称：</div>
          <input
            class="input"
            type="text"
            placeholder=""
            v-model="company_name"
          />
        </div>
        <div class="input-item">
          <div class="label">企业简介：</div>
          <textarea
            class="input"
            type="text"
            placeholder=""
            style="height: 50px"
            v-model="company_desc"
          ></textarea>
        </div>
        <div class="input-item">
          <div class="label">图片：</div>
          <van-uploader
            multiple
            v-model="ImgList"
            :after-read="afterReadImg"
            :before-delete="deleteImg"
            :accept="'image/*'"
          />
        </div>
        <div class="input-item">
          <div class="label">视频：</div>
          <van-uploader
            multiple
            v-model="videoList"
            :after-read="afterReadVideo"
            :before-delete="deleteVideo"
            :accept="'video/*'"
          />
        </div>
        <div class="input-item">
          <div class="label">联系方式：</div>
          <input
            class="input"
            type="text"
            placeholder=""
            v-model="company_phone"
          />
        </div>
        <div class="input-item">
          <div class="label">所在城市：</div>
          <input
            class="input"
            type="text"
            placeholder=""
            v-model="address"
            readonly
            @click="showCityPicker = true"
          />
          <van-popup v-model="showCityPicker" round position="bottom">
            <van-area
              title="选择地址"
              :area-list="areaList"
              @cancel="showCityPicker = false"
              @confirm="onCityConfirm"
            />
          </van-popup>
        </div>
        <div class="input-item">
          <div class="label">详细地址：</div>
          <textarea
            class="input"
            type="text"
            placeholder=""
            style="height: 50px"
            v-model="company_address"
          ></textarea>
        </div>
        <div class="input-item">
          <div class="label">服务范围：</div>
          <van-checkbox
            v-model="builder"
            icon-size="16px"
            style="margin-top: 4px; margin-right: 15px; font-size: 12px"
            >搭建商</van-checkbox
          >
          <van-checkbox
            v-model="render"
            icon-size="16px"
            style="margin-top: 4px; font-size: 12px"
            >服务商</van-checkbox
          >
        </div>
        <div class="input-item">
          <div class="label">企业资质：</div>
          <van-uploader
            multiple
            v-model="picImgList"
            :max-count="1"
            :after-read="afterReadPic"
            :accept="'image/*'"
          />
        </div>
        <div class="input-item">
          <div class="label">企业Logo：</div>
          <van-uploader
            v-model="logoImgList"
            :max-count="1"
            :after-read="afterReadLogo"
            :accept="'image/*'"
          />
        </div>

        <div class="tip-box">
          <div>友情提示：</div>
          <div style="text-indent: 20px">
            您的申请，需要主办方审核同意后方可正常展示，具体事项，可以与主办方直接沟通。
          </div>
        </div>

        <div class="sub-btn" @click="onSubmit">提交申请</div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import province_list from "../../utils/area";
import { login } from "../../utils/login_toast";
import { wechatConfig } from "../../utils/wxConfig";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Tabber,
    Navbar,
  },
  props: {},
  data() {
    return {
      afterRead: "",
      radio: "",
      baseUrl: base.apiURL,
      loginURL: base.loginURL,
      areaList: province_list,

      logoImgList: [],
      picImgList: [],

      company_name: "", //公司名称
      company_phone: "", //联系方式
      company_address: "", //公司地址
      company_desc: "", //公司简介
      company_logo: "", //公司Logo
      company_province: "", //公司地址-省
      company_city: "", //公司地址-市
      company_area: "", //公司地址-区
      company_pic: "",
      images: [],
      videos: [],
      builder: false,
      render: false,

      ImgList: [],
      videoList: [],

      showCityPicker: false,
      address: "",

      info: {},
      expo_id: "",
      joinerInfo: {},
      userInfo: {
        id: "",
      },
      invate_uid: "",
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    if (this.userInfo.id == "") {
      loginAlert()
    }
    this.expo_id = this.$route.query.id;
    this.getExpoInfo();
    this.setShare();
    this.wxLogin();
  },
  mounted() {},
  watch: {},
  methods: {
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-申请服务商`,
        desc: `媒体渠道，打造冷柜行业互联网+，报名成为服务商，为您带来更多的客户！`,
        link:
          base.shareURL +
          "/jounServiceExpo?id=" +
          this.expo_id +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    wxLogin() {
      var code = this.getUrlKey("code");
      var state = this.getUrlKey("state");
      if (!this.$cookies.isKey("Authorization") && code) {
        login(this.getUser);
      }
    },
    getUser(userInfo) {
      this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    toChat(toId) {
      var _this = this;
      if (_this.$cookies.isKey("userInfo")) {
        var form_uid = _this.userInfo.id; //自己的 uid
        var to_uid = toId; //聊天对象 uid
        var data = {
          params: {
            to_uid: to_uid,
            from_uid: form_uid,
            chat_type: "normal",
          },
        };
        addOp("/im/build_chat", data).then((res) => {
          console.log(res);
          if (res && res.status != 500) {
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                order_no: _this.order_no,
                to_uid: to_uid,
              },
            });
          } else {
            _this.$toast.fail("对话创建失败！");
          }
        });
      } else {
        loginAlert()
      }
    },
    toSponsorIndex(id) {
      this.$router.push({
        path: "/sponsorIndex",
        query: {
          id: id,
        },
      });
    },
    onSubmit() {
      var _this = this;
      if (_this.company_name == "") {
        _this.$toast.fail("企业名称不能为空！");
        return;
      }
      if (_this.company_desc == "") {
        _this.$toast.fail("企业简介不能为空！");
        return;
      }
      if (_this.company_phone == "") {
        _this.$toast.fail("联系方式不能为空！");
        return;
      }
      if (_this.company_province == "") {
        _this.$toast.fail("请选择所在地区！");
        return;
      }
      if (_this.company_address == "") {
        _this.$toast.fail("详细地址不能为空！");
        return;
      }
      var data = {
        expo_id: _this.expo_id,
        company_name: _this.company_name,
        company_phone: _this.company_phone,
        company_desc: _this.company_desc,
        company_province: _this.company_province,
        company_city: _this.company_city,
        company_area: _this.company_area,
        company_address: _this.company_address,
        company_logo: _this.company_logo,
        builder: _this.builder ? "t" : "f",
        render: _this.render ? "t" : "f",
        images: _this.images,
        videos: _this.videos,
        company_pic: _this.company_pic,
      };
      console.log(data);
      // return;
      addOp("/expo_service_user/store", data).then((res) => {
        if (res && res.data.state == 1000) {
          _this.$toast.success(res.data.msg);
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        } else {
          _this.$toast.fail(res.data.msg);
        }
        console.log(res);
      });
    },
    //选择城市
    onCityConfirm(value, index) {
      this.showCityPicker = false;
      this.company_province = value[0].name;
      this.company_city = value[1].name;
      this.company_area = value[2].name;
      this.address = value[0].name + "-" + value[1].name + "-" + value[2].name;
      this.company_address =
        value[0].name + value[1].name + value[2].name + " ";
    },
    //logo图片上传
    afterReadLogo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.company_logo = res.data.url;
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //资质图片上传
    afterReadPic(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.company_pic = res.data.url;
          // _this.company_pic.push(res.data.url)
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //图片上传
    afterReadImg(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.images.push(res.data.url);
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //删除以上传图片
    deleteImg(file, detail) {
      this.ImgList.splice(detail.index, 1);
      this.images.splice(detail.index, 1);
    },
    //视频上传
    afterReadVideo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadFile(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.videos.push(res.data.url);
          console.log(res);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //删除以上传视频
    deleteVideo(file, detail) {
      this.videoList.splice(detail.index, 1);
      this.videos.splice(detail.index, 1);
    },
    getExpoInfo() {
      var _this = this;
      var data = {
        id: _this.expo_id,
      };
      indexOp("/expo/show", data).then((res) => {
        console.log(res);
        if (res && res.data.state == 1000) {
          _this.info = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped >
</style>
