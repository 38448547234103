import { render, staticRenderFns } from "./RepairDetail.vue?vue&type=template&id=3efd1074&scoped=true"
import script from "./RepairDetail.vue?vue&type=script&lang=js"
export * from "./RepairDetail.vue?vue&type=script&lang=js"
import style0 from "./RepairDetail.vue?vue&type=style&index=0&id=3efd1074&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3efd1074",
  null
  
)

export default component.exports