<template>
  <div class="product peijian">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">资讯详情</span>
      <van-icon
        name="delete"
        @click="onDelete"
        v-if="info.uid == userInfo.id"
      />
    </div>
    <div class="content">
      <div class="banner-box">
        <img class="banner-img" :src="url + info.thumb" alt="" />
      </div>
      <div class="title-box" style="background:#fff;color:#333;">
        {{ info.name }}
        <p
          style="
            font-size: 12px;
            color: #666;
            margin-top: 5px;
            font-weight: normal;
          "
        >
          发布时间：{{ info.created_at }}
        </p>
        <p
          style="
            font-size: 12px;
            color: #666;
            margin-top: 5px;
            font-weight: normal;
          "
        >
          点赞：{{ info.zan_count }}  浏览量：{{info.hits}}
        </p>
      </div>
      <div class="detail-box">
        <div v-html="info.content"></div>
      </div>
    </div>
    <!-- <div class="btns">
      <div class="btn">
        <i class="iconfont icondianhua"></i>
        <span>电话联系</span>
      </div>
      <div class="btn">
        <i class="iconfont iconxiaoxi"></i>
        <span>在线联系</span>
      </div>
    </div> -->
    <div class="btn-group">
      <div class="dianzan" @click="onZan">
        <van-icon v-if="zanInfo" name="good-job" size="22" color="#2F78DC"/>  
        <van-icon v-else name="good-job-o" size="22" />  
      </div>  
    </div> 
    <Tabber></Tabber>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
export default {
  inject: ["reload"],
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      url: base.apiURL,
      userInfo: {
        id: "",
      },
      info: "",
      nid: "",
      zanInfo:""
    };
  },
  computed: {},
  created() {
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    var invate_uid = this.$route.query.invate_uid;
    if(invate_uid){
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.nid = this.$route.query.id;
    this.getNewInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.getNewInfo();
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    isZan(){
      var _this = this;
      var data = {
        news_id:_this.info.id
      }
      indexOp("/zan_log/show",data).then(res=>{
        if(res && res.status == 200){
          _this.zanInfo = res.data
        }
        console.log(res)
      })
    },
    //点赞
    onZan(){
      var _this = this;
      if(_this.zanInfo){
        _this.$toast("您已经点过赞了！");
        return;
      }
      var data = {
        news_id:_this.info.id
      }
      addOp("/zan_log/create",data).then(res=>{
        console.log(res)
        if(res && res.status == 201){
          _this.$toast.success("点赞成功！");
          setTimeout(()=>{
            _this.reload()
          },800)
        }
      })
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-` + this.info.name,
        desc: "聚制冷精英，集行业资源，方便您我他！",
        link:
          base.shareURL +
          "/peijian_newDetail?id=" +
          this.nid +
          "&invate_uid=" +
          this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    //获取资讯详情
    getNewInfo() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/news/show/" + _this.nid;
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.info = res.data;
          _this.setShare();
          if(_this.userInfo.id){
            _this.isZan()
          }
        }
      });
    },
    onDelete() {
      var _this = this;
      var api = "/news/destroy/" + _this.info.id;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除这条资讯？",
        })
        .then(() => {
          addOp(api).then((res) => {
            if (res && res.status == 200) {
              _this.$toast.success("操作成功！");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 800);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
