<template>
  <div class="repair-detail bidding-end">
    <!-- <Navbar :title="'维修详情'"></Navbar> -->
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">维修详情</span>
      <van-button
        type="danger"
        size="mini"
        style="width: 60px"
        @click="onOrderChange"
        v-if="info.service_type == 'take_order'"
        >取消订单</van-button
      >
    </div>
    <div class="order-info">
      <div class="title-box">
        <span>订单信息</span>
        <span class="time">发布时间：{{ info.created_at }}</span>
      </div>
      <div class="center">
        <div class="describe">
          <div class="left">
            <p class="item tit" @click="copy(`维修产品名称：${info.name}`)">
              维修产品名称：{{ info.name }}
              <i
                class="iconfont iconfuzhi"
                style="font-size: 12px; color: #999; margin-left: 5px"
              ></i>
            </p>
            <p class="item" @click="copy(`维修描述：${info.desc}`)">
              维修描述：{{ info.desc }}
              <i
                class="iconfont iconfuzhi"
                style="font-size: 12px; color: #999; margin-left: 5px"
              ></i>
            </p>
            <p class="item">
              商品质保：{{ info.is_time_out == 1 ? "保外" : "保内" }}
            </p>
          </div>
          <div class="right" v-if="info.get_user_info">
            <img :src="info.get_user_info.avatar" alt="" />
            <span>{{ info.get_user_info.nickname }}</span>
          </div>
        </div>
        <div class="img-box" v-if="info.image && info.image.length > 0">
          <span>故障产品照片：</span>
          <div class="imgs">
            <img
              v-for="(item, index) in info.image"
              :key="index"
              :src="item"
              alt=""
              @click="onPreviewImg(info.image)"
            />
          </div>
        </div>

        <p class="position">
          <span>地图导航：</span>
          <a
            v-if="info.lat && info.lng"
            :href="`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${info.lat},${info.lng};addr:${info.address}&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp`"
          >
            {{ info.full_address }}
          </a>
          <a
            v-else
            :href="`https://apis.map.qq.com/uri/v1/search?keyword=${info.address}&region=${info.city}&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`"
          >
            {{ info.full_address }}
          </a>

          <i
            class="iconfont iconfuzhi"
            style="font-size: 12px; color: #999; margin-left: 5px"
            @click.stop="
              copy(info.full_address)
            "
          ></i>
        </p>
        <a :href="`tel:${info.mobile}`" class="position">
          <span>联系电话：</span>{{ info.mobile }}
          <i
            class="iconfont iconfuzhi"
            style="font-size: 12px; color: #999; margin-left: 5px"
            @click.stop="copy(info.mobile)"
          ></i>
        </a>
      </div>
      <div style="display:flex;">
        <div class="status-box" v-if="info.deposit_pay_status == 1">
          <span class="status">已预付定金</span>
          <span class="icon"><i class="iconfont iconrenminbi"></i></span>
        </div>
        <div class="status-box" v-else>
          <span class="status">未预付费用</span>
          <span class="icon"><i class="iconfont iconrenminbi"></i></span>
        </div>
        <div class="status-box" style="margin-left:5px;" v-if="info.part_status_alias" >
          <span class="status">{{info.part_status_alias}}</span>
          <span class="icon"><van-icon name="underway-o" /></span>
        </div>
      </div>

      <div class="coll" @click="showRepairRule">
        <!-- <i class="iconfont iconduihua"></i> -->
        <span style="font-size:12px;">维修交易规则</span>
      </div>
    </div>

    <!-- <div class="order-process" style="padding:15px;font-size:14px;">先预付维修定金，师傅干活更放心，只要事情处理好，维修费用不能少。</div> -->

    <div class="map-route">
      <div id="map"></div>
    </div>

    <div class="order-process" style="padding: 15px; font-size: 14px;text-align:center;" v-if="userInfo.identity == 2">
      <!-- 先预付维修费用，师傅干活更放心！<br/>
      平台担保支付费用，不抽成不收信息费。<br/>
      师傅收到费用在冻结余额，<br/>
      完成任务48个小时后可以随时提现到微信。<br/>
      招募省市区县乡制冷精英合伙人，<br/>
      会员制，只聚集诚实守信，做事靠谱的您。<br/> -->
      先付款后上门检查，查出来问题拍照或录视频发单子里的聊天记录里，先付款再维修，师傅干活更安心！<br/><br/>
      平台担保支付，费用在冻结余额，完成任务48个小时后，可以随时提现到微信余额。<br/><br/>
      私下交易，下次取消接单资格，师傅提现时，系统自动扣服务费百分之十。
    </div>
    <!-- <div
      class="form-box"
      v-if="
        info.service_type == 'sure_service_user' ||
        info.service_type == 'take_order' ||
        info.service_type == 'arrive_order' ||
        info.service_type == 'service_ing' ||
        info.service_type == 'user_sure_arrive'
      "
    >
      <div class="title-box">
        <span>订单进程</span>
      </div>
      <template v-if="info.service_type == 'take_order' && paidanShow">
        <van-field
          label="当前状态"
          placeholder="请选择当前状态"
          value="排单中"
          readonly
          clickable
        />
        <van-field
          label="上门时间"
          placeholder="请选择当前状态"
          :value="info.accept_door_time"
          readonly
          clickable
        />
        <van-button class="btn" type="info" @click="paidanShow = false"
          ><i class="iconfont iconqueren1"></i> 下一步</van-button
        >
      </template>
      <template v-else>
        <van-field
          label="当前状态"
          v-model="statusValue"
          placeholder="请选择当前状态"
          @click="showPicker = true"
          readonly
          clickable
        />
        <van-cell-group
          v-if="
            formData.service_type != 'take_order' &&
            formData.service_type != 'user_sure_arrive'
          "
        >
          <van-field
            :autosize="{ maxHeight: 100, minHeight: 25 }"
            v-model="formData.content"
            rows="1"
            label="补充说明"
            type="textarea"
            placeholder="请输入补充说明"
          />
        </van-cell-group>
        <van-cell-group v-if="formData.service_type == 'service_ing'">
          <van-field
            :autosize="{ maxHeight: 100, minHeight: 25 }"
            v-model="formData.service_money"
            rows="1"
            label="维修费用"
            type="number"
            placeholder="请输入维修费用"
          />
        </van-cell-group>
        <van-cell-group v-if="formData.service_type == 'service_ing'">
          <van-field
            :autosize="{ maxHeight: 100, minHeight: 25 }"
            v-model="formData.fittings_money"
            rows="1"
            label="配件费用"
            type="number"
            placeholder="请输入配件费用（若无配件费用，可不填）"
          />
        </van-cell-group>
        <van-cell-group v-if="formData.service_type == 'service_ing'">
          <van-field name="is_guarantee" label="是否保修">
            <template #input>
              <van-radio-group
                v-model="formData.is_guarantee"
                direction="horizontal"
              >
                <van-radio name="1" icon-size="16px">是</van-radio>
                <van-radio name="0" icon-size="16px">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group v-if="formData.is_guarantee == 1">
          <van-field
            :autosize="{ maxHeight: 100, minHeight: 25 }"
            v-model="formData.guarantee_begin_time"
            rows="1"
            label="开始时间"
            type="textarea"
            placeholder="保修开始时间"
            readonly
            clickable
            @click="showStartTimePicker = true"
          />
          <van-popup v-model="showStartTimePicker" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择保修期开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showStartTimePicker = false"
              @confirm="onStartTimeConfirm"
            />
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="formData.is_guarantee == 1">
          <van-field
            :autosize="{ maxHeight: 100, minHeight: 25 }"
            v-model="formData.guarantee_end_time"
            rows="1"
            label="结束时间"
            type="textarea"
            placeholder="保修结束时间"
            readonly
            clickable
            @click="showEndTimePicker = true"
          />
          <van-popup v-model="showEndTimePicker" round position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择保修期开始时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="showEndTimePicker = false"
              @confirm="onEndTimeConfirm"
            />
          </van-popup>
        </van-cell-group>
        <div
          class="upload-img"
          v-if="
            formData.service_type != 'take_order' &&
            formData.service_type != 'user_sure_arrive'
          "
        >
          <p class="tit">进程图片</p>
          <div class="imgs">
            <van-uploader
              v-model="fileList"
              multiple
              :max-count="3"
              :after-read="afterRead"
            />
          </div>
        </div>
        <div
          class="upload-img"
          v-if="
            formData.service_type != 'take_order' &&
            formData.service_type != 'user_sure_arrive'
          "
        >
          <p class="tit">上传视频</p>
          <div class="imgs">
            <van-uploader
              accept="video/*"
              v-model="VideofileList"
              multiple
              :max-count="1"
              :after-read="afterReadVideo"
            />
          </div>
        </div>

        <van-button
          class="btn"
          type="info"
          @click="onSubmit"
          v-if="formData.service_type != 'user_sure_arrive'"
          ><i class="iconfont iconqueren1"></i> 提交</van-button
        >
      </template>
    </div> -->

    <div class="order-process" v-if="false">
      <div class="title-box">
        <span>订单进程</span>
      </div>

      <div class="status-box">
        <!-- <van-steps direction="vertical" :active="stepsActive" active-color="#2F78DC" @click-step="onStep">
          <van-step v-for="(item, index) in statusList" :key="index">
            <p>{{ item.text }} + {{index}}</p>
            <div class="status-info">
              <p class="text">操作时间：2021年5月6日15:04:33</p>
              <p class="text">补充说明：</p>
              <div class="img-div">
                <p class="text">故障产品照片：</p>
                <div class="imgs">
                  <img src="../../assets/image/avatar.png" alt="">
                </div>
              </div>
            </div>
          </van-step>
        </van-steps> -->
        <div class="logistics">
          <div class="box">
            <ul class="list">
              <li
                :class="service_type_active === item.index ? 'active' : ''"
                v-for="(item, index) in statusList"
                :key="index"
                @click="onChangeService(item.index)"
              >
                <div class="content">{{ item.text }}</div>
                <!-- <div class="desc">补充说明：</div> -->
              </li>
            </ul>
          </div>
        </div>
        <!-- <span
          :class="service_type_active === item.index ? 'active' : ''"
          v-for="(item, index) in statusList"
          :key="index"
          @click="onChangeService(item.index)"
          >{{ item.text }}</span
        > -->
      </div>

      <template v-if="info.get_service_log && info.get_service_log.length > 0">
        <div v-for="(item, index) in info.get_service_log" :key="index">
          <template v-if="item.service_type == service_type_active">
            <div class="item">操作时间：{{ item.updated_at }}</div>
            <div class="item" v-if="item.content">
              补充说明：{{ item.content }}
            </div>
            <div class="item" v-else>补充说明：无</div>
            <div class="img-box" v-if="item.images && item.images.length > 0">
              <span>故障产品照片：</span>
              <div class="imgs">
                <img
                  v-for="(src, index) in item.images"
                  :key="index"
                  :src="src"
                  alt=""
                  @click="onPreviewImg(item.images)"
                />
              </div>
            </div>
            <div class="img-box" v-if="item.video_url">
              <span>维修视频：</span>
            </div>
            <div class="video-box" v-if="item.video_url">
              <video
                :src="item.video_url"
                style="width: 100%"
                controls="controls"
                poster="@/assets/image/banner1.jpg"
              ></video>
            </div>
          </template>
        </div>
      </template>

      <div
        class="item"
        v-if="info.service_type == 'cancel_order'"
        style="color: red"
      >
        该订单已取消
      </div>
      <div
        class="item"
        v-if="
          info.service_type == 'order_bidding' && info.deposit_pay_status == 1
        "
      >
        预付定金：<b style="color: red">{{ info.deposit_money }}</b
        >元
      </div>
    </div>

    <div class="bottom-box" v-if="false">
      <!-- <i class="iconfont iconzhengque"></i> -->
      <div class="title-box">
        <span>维修报价</span>
      </div>
      <p class="item">
        <span class="tit">上门检修费：</span>
        <span class="price"
          >¥{{ jingjiaDetail.door_money
          }}<van-tag
            v-if="info.door_pay_status == 0"
            type="danger"
            style="font-size: 10px"
          >
            未支付
          </van-tag>
          <van-tag v-else type="success" style="font-size: 10px"
            >已支付</van-tag
          ></span
        >
      </p>
      <p class="item">
        <span class="tit">预计上门时间：</span>
        <span class="price">{{ jingjiaDetail.plan_door_time }}</span>
      </p>
      <p class="item">
        <span class="tit">维修费用：</span>
        <span class="price" v-if="info.service_money > 0">
          ¥{{ info.service_money }}
          <van-tag
            v-if="info.service_pay_status == 0"
            type="danger"
            style="font-size: 10px"
          >
            未支付
          </van-tag>
          <van-tag v-else type="success" style="font-size: 10px"
            >已支付</van-tag
          >
        </span>
        <span class="price" v-else>暂定</span>
      </p>
      <p class="item">
        <span class="tit">配件费用：</span>
        <span class="price" v-if="info.fittings_money > 0">
          ¥{{ info.fittings_money }}
          <van-tag
            v-if="info.fittings_pay_status == 0"
            type="danger"
            style="font-size: 10px"
          >
            未支付
          </van-tag>
          <van-tag v-else type="success" style="font-size: 10px"
            >已支付</van-tag
          >
        </span>
        <span class="price" v-else>暂定</span>
      </p>
      <!-- <p class="item" v-if="jingjiaDetail.is_guarantee == 1">
        <span class="tit">承诺保质期：</span>
        <span class="price"
          >{{ jingjiaDetail.guarantee_begin_time }} -
          {{ jingjiaDetail.guarantee_end_time }}</span
        >
      </p>
      <p class="item" v-else>
        <span class="tit">承诺保质期：</span>
        <span class="price">不保修</span>
      </p> -->
    </div>

    <div class="jingjia" v-if="false">
      <div class="title-box">
        <span>报价信息</span>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in info.get_order_bidding"
          :key="index"
          v-if="item.service_id == uid"
        >
          <template>
            <!-- <div class="status">保证金</div> -->
            <div class="flex-box">
              <div class="left-box">
                <img class="avatar" src="@/assets/image/shifu.png" alt="" />
                <!-- <span class="name" v-if="item.service_info">{{
                item.service_info.service_info.person
              }}</span> -->
                <van-tag
                  v-if="item.service_info.service_info.believe > 90"
                  class="tag"
                  color="#ff5722"
                  text-color="#fff"
                  >金牌师傅</van-tag
                >
                <van-tag
                  v-else-if="
                    item.service_info.service_info.believe > 80 &&
                    item.service_info.service_info.believe < 90
                  "
                  class="tag"
                  color="#a7a7a7"
                  text-color="#fff"
                  >银牌师傅</van-tag
                >
                <van-tag
                  v-else-if="item.service_info.service_info.believe < 80"
                  class="tag"
                  color="#7A443A"
                  text-color="#fff"
                  >铜牌师傅</van-tag
                >
                <van-rate
                  :value="item.service_info.comment_star"
                  :size="14"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                />
              </div>
              <div class="right-box">
                <p class="title" v-if="item.service_info">
                  {{ item.service_info.service_info.name }}
                </p>
                <div class="price-box">
                  <p>
                    上门检修费：<b>¥{{ item.door_money }}</b>
                  </p>
                  <p>
                    预计上门时间：<b>{{ item.plan_door_time }}</b>
                  </p>
                  <!-- <p>
                    承诺保质期：
                    <b v-if="item.is_guarantee == 1">
                      {{ item.guarantee_begin_time }} -
                      {{ item.guarantee_end_time }}
                    </b>
                    <b v-else>不保修</b>
                  </p> -->
                </div>
                <p class="describe" v-if="item.service_info">
                  维修范围：{{ item.service_info.service_info.service_desc }}
                </p>
                <!-- <div class="coll">
                <i class="iconfont icondianhua1"></i>
              </div> -->
              </div>
            </div>
          </template>

          <!-- <van-button class="btn" type="info" ><i class="iconfont iconqueren1"></i> 选择该师傅</van-button> -->
        </div>
        <div
          class="empty-box"
          v-if="info.get_order_bidding && info.get_order_bidding.length <= 0"
        >
          <van-empty description="暂无数据" />
        </div>
      </div>
    </div>
    <!-- <Tabber></Tabber> -->
    <!-- <div class="bottom-btn">
      <van-button class="jingjia-btn" type="info" disabled>已参与报价</van-button>
    </div> -->
    <!-- <div class="bottom-block"></div> -->
    <van-image-preview
      v-model="previewShow"
      :images="previewImages"
    ></van-image-preview>

    <div class="bottom-btn" style="z-index:9999;">
      <van-button class="jingjia-btn" type="info" @click="toChat"
        >进入聊天室</van-button
      >
    </div>
    <!-- <div class="order-change">
      <van-button class="btn" type="danger">退单</van-button>
    </div> -->
    <Tabber></Tabber>
		<notice :xieyiShow.sync="xieyiShow"></notice>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import notice from "../../components/notice";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
  locationOp,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
export default {
  components: { Navbar, Tabber , notice },
  inject: ["reload"],
  props: {},
  data() {
    return {
      order_no: "", //订单id
      info: "", //订单信息
      url: base.apiURL,
      statusList: [
        {
          index: "order_bidding",
          text: "待接单",
        },
        {
          index: "sure_service_user",
          text: "已确认师傅",
        },
        {
          index: "take_order",
          text: "已接单",
        },
        {
          index: "arrive_order",
          text: "师傅已到达",
        },
        {
          index: "user_sure_arrive",
          text: "确认师傅到达",
        },
        {
          index: "service_ing",
          text: "维修中",
        },
        {
          index: "service_end",
          text: "维修完成",
        },
        {
          index: "user_sure_service",
          text: "确认完成",
        },
        {
          index: "appraise_order",
          text: "已评价",
        },
        // {
        //   index: "pay_money",
        //   text: "订单结束",
        // },
        // {
        //   index: "cancel_order",
        //   text: "订单取消",
        // },
      ],
      choiceStatus: [
        {
          current: "sure_service_user",
          text: "确认接单",
          after: "take_order",
        },

        {
          current: "take_order",
          text: "我已到达",
          after: "arrive_order",
        },
        {
          current: "arrive_order",
          text: "等待用户确认支付上门费用",
          after: "user_sure_arrive",
        },
        {
          current: "user_sure_arrive",
          text: "正在检修",
          after: "service_ing",
        },
        {
          current: "service_ing",
          text: "我已修好",
          after: "service_end",
        },
      ],
      jingjiaDetail: "",
      statusValue: "", //订单状态
      status: "", //订单状态
      textarea: "",
      formData: {
        service_type: "",
        content: "",
        images: [],
        video_url: "",
      }, //表单数据
      fileList: [],
      VideofileList: [],
      uid: "",
      service_type_active: "",
      previewImages: [],
      previewShow: false,
      showTimePicker: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
      showStartTimePicker: false,
      showEndTimePicker: false,
      paidanShow: true,
      userInfo: "",
      map: "",
      marker: "",
      infoWindow: "",
      stepsActive: "",

      xieyiShow:false, //显示维修交易规则
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if(invate_uid){
      this.$cookies.set("invate_uid", invate_uid);
    }
    this.order_no = this.$route.query.order_no;
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
      this.uid = this.$cookies.get("userInfo").id;
    }
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    } else {
      this.getOrderDetail();
      this.getJingjiaDetail();
    }
  },
  mounted() {
    // this.getLocation()
  },
  watch: {},
  methods: {
    showRepairRule(){
      this.xieyiShow = true
    },
    //师傅退单
    onOrderChange() {
      var _this = this;
      var data = {
        order_no: _this.order_no,
      };
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认取消该订单？",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        })
        .then(() => {
          _this.outChat()
          addOp("/service_order/service_change", data).then((res) => {
            if(res && res.data == 1){
              _this.$toast.success("退单成功！")
              _this.setServiceLog()
              setTimeout(()=>{
                _this.$router.go(-1);
              },800)
            }
          });
        });
    },
    outChat(){
      var _this = this;
      var data = {
        chat_id : _this.info.order_no + "_" + _this.info.uid
      }
      addOp("/im/logout_chat", data).then((res) => {
        if (res && res.data == 1) {
        }
      });
    },
    setServiceLog() {
      var _this = this;
      var data = {
        uid: _this.uid,
        order_no: _this.info.order_no,
        service_type: "refund_order",
        identity_type: "service",
      };
      addOp("/service_log/create", data).then((res) => {
        // _this.reload();
        console.log(res);
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    onStep(index) {
      var _this = this;
      _this.stepsActive = index;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
      _this.identity = userInfo.identity;
      this.getOrderDetail();
      this.getJingjiaDetail();
    },

    //初始化地图
    initMap() {
      var _this = this;
      //定义地图中心点坐标
      // var userInfo = this.$cookies.get("userInfo");
      var lat = _this.userInfo.latitude;
      var lng = _this.userInfo.longitude;
      var shifu_lat = _this.info.get_user_info.latitude;
      var shifu_lng = _this.info.get_user_info.longitude;
      var center = new TMap.LatLng(lat, lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      _this.map = new TMap.Map(document.getElementById("map"), {
        center: center, //设置地图中心点坐标
        zoom: 11, //设置地图缩放级别
      });
      _this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      _this.map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
      //初始化marker
      _this.marker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: _this.map,
        styles: {
          //点标注的相关样式
          myMarker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src:
              "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 25,
            anchor: { x: 16, y: 32 },
            src: "../image/dian.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "myMarker",
            position: new TMap.LatLng(lat, lng),
            properties: {
              title: "我的位置",
            },
          },
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(shifu_lat, shifu_lng),
            properties: {
              title: "用户位置",
            },
          },
        ],
      });
      _this.marker.on("click", _this.clickHandler);
      //初始化infoWindow
      _this.infoWindow = new TMap.InfoWindow({
        map: _this.map,
        position: new TMap.LatLng(lat, lng),
        offset: { x: -3, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      _this.infoWindow.close();
    },

    clickHandler(evt) {
      console.log(evt.geometry.properties.title);
      this.infoWindow.open(); //打开信息窗
      this.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
      this.infoWindow.setContent(evt.geometry.properties.title); //设置信息窗内容
    },

    //取消报价
    onCancelBidding() {
      var _this = this;
      var data = {
        order_no: _this.info.order_no,
      };
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认取消该报价？",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        })
        .then(() => {
          indexOp("/order_bidding/destroy", data).then((res) => {
            if (res && res.status == 200) {
              _this.$toast.success("操作成功！");
              setTimeout(() => {
                _this.$router.go(-1);
              }, 800);
            }
            console.log(res);
          });
        });
    },

    //分享
    setShare() {
      var vip = "";
      if (this.info.user_vip_info && this.info.user_vip_info.vip_level == 1) {
        vip = "普通会员";
      } else if (
        this.info.user_vip_info &&
        this.info.user_vip_info.vip_level == 2
      ) {
        vip = "黄金会员";
      } else if (
        this.info.user_vip_info &&
        this.info.user_vip_info.vip_level == 3
      ) {
        vip = "钻石会员";
      } else {
        vip = "体验会员";
      }
      const shareInfo = {
        title:
          "招募制冷维保技师合伙人 - " +
          this.info.name +
          " " +
          this.info.province +
          "-" +
          this.info.city +
          "-" +
          this.info.area,
        desc: "点确认接单，进单子的聊天室里，报上门检修费，先付款再维修，让师傅更放心！",
        link:
          base.shareURL +
          "/RepairDetail?order_no=" +
          this.order_no +
          "&invate_uid=" +
          this.uid,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },

    //提交维修记录
    onSubmit() {
      var _this = this;
      if (_this.formData.service_type == "user_sure_arrive") {
        _this.$toast("等待用户确认到达。");
        return;
      }
      if (_this.formData.service_type != "take_order") {
        if (_this.formData.content == "") {
          _this.$toast("请输入补充说明！");
          return;
        }
        if (
          _this.formData.images.length == 0 &&
          _this.formData.video_url.length == 0
        ) {
          _this.$toast("请上传维修图片或上传视频！");
          return;
        }
      }
      if (
        _this.formData.service_type == "service_end" &&
        _this.info.service_money > 0 &&
        _this.info.service_pay_status == 0
      ) {
        _this.$dialog
          .alert({
            title: "提示",
            message:
              "用户还未支付维修费用或配件费用，请提醒用户支付后再进行下一步操作。",
          })
          .then(() => {
            // on close
          });
        return;
      }
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        order_no: _this.info.order_no,
        service_type: _this.formData.service_type,
      };
      if (_this.formData.service_type == "service_ing") {
        if (_this.formData.service_money == undefined) {
          _this.$toast("请填写维修费用！");
          return;
        }
        if (_this.formData.fittings_money == undefined) {
          data.fittings_money = 0;
        } else {
          data.fittings_money = _this.formData.fittings_money;
        }
        data.service_money = _this.formData.service_money;
        data.is_guarantee = _this.formData.is_guarantee;
        if (_this.formData.is_guarantee == 1) {
          if (_this.formData.guarantee_begin_time == undefined) {
            _this.$toast("请选择保修开始时间！");
            return;
          }
          if (_this.formData.guarantee_end_time == undefined) {
            _this.$toast("请选择保修结束时间！");
            return;
          }
          data.guarantee_begin_time = _this.formData.guarantee_begin_time;
          data.guarantee_end_time = _this.formData.guarantee_end_time;
        }
      }
      addOp("/service_order/update", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.setServiceLog();
          _this.$toast.success("操作成功！");
        }
        console.log(res);
      });
    },

    //获取订单详情
    getOrderDetail() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var data = {
        order_no: _this.order_no,
      };
      indexOp("/service_order/show", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status) {
          _this.info = res.data;
          _this.setShare();
          _this.service_type_active = res.data.service_type;
          _this.getStatus();
          _this.initMap();
        }
        console.log(res);
      });
    },

    getLocation() {
      var _this = this;
      locationOp().then((res) => {
        console.log(res);
      });
    },

    getJingjiaDetail() {
      var _this = this;
      var data = {
        service_id: _this.uid,
        order_no: _this.order_no,
      };
      indexOp("/order_bidding/show", data).then((res) => {
        if (res && res.status == 200) {
          _this.jingjiaDetail = res.data;
        }
        console.log(res);
      });
    },

    getStatus() {
      var _this = this;
      for (var i = 0; i < _this.statusList.length; i++) {
        if (_this.statusList[i].index == _this.info.service_type) {
          _this.stepsActive = i;
        }
      }
      // _this.statusList.map(item=>{
      //   if(item.index == _this.info.service_type){
      //     _this.statusValue = item.text
      //     _this.status = item.index
      //   }
      //   console.log(item)
      // })
      var choiceStatus = _this.choiceStatus;
      for (var i = 0; i < choiceStatus.length; i++) {
        if (choiceStatus[i].current == _this.info.service_type) {
          _this.statusValue = choiceStatus[i].text;
          _this.formData.service_type = choiceStatus[i].after;
        }
      }
    },

    // setServiceLog() {
    //   var _this = this;
    //   var data = {
    //     uid: _this.uid,
    //     order_no: _this.info.order_no,
    //     service_type: _this.formData.service_type,
    //     identity_type: "service",
    //     content: _this.formData.content,
    //     images: _this.formData.images,
    //     video_url: _this.formData.video_url,
    //   };
    //   addOp("/service_log/create", data).then((res) => {
    //     _this.reload();
    //     console.log(res);
    //   });
    // },

    //图片上传
    afterRead(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.images.push(_this.url + res.data.url);
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },

    //视频上传
    afterReadVideo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadFile(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          _this.formData.video_url = _this.url + res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },

    //去对话
    toChat() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var to_uid = _this.info.uid; //聊天对象 uid
      // console.log(_this.$cookies.get("userInfo"))
      var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
      var data = {
        params: {
          to_uid: to_uid,
          from_uid: form_uid,
          chat_type: "order",
          order_no: _this.order_no,
        },
      };
      addOp("/im/build_chat", data).then((res) => {
        _this.$toast.clear();
        console.log(res);
        if (res && res.status == 200) {
          //跳转
          _this.$router.push({
            path: "/chat",
            query: {
              chat_id: res.data.chat_id,
              order_no: _this.order_no,
              to_uid: to_uid,
            },
          });
        }
      });
    },

    onChangeService(type) {
      this.service_type_active = type;
    },

    copy(text) {
      var input = document.createElement("input"); // 直接构建input
      input.value = text; // 设置内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast("复制成功");
    },

    onPreviewImg(arr) {
      this.previewImages = arr;
      this.previewShow = true;
      console.log(arr);
    },

    //选择保修期结束之间
    onEndTimeConfirm(value) {
      this.showEndTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      this.formData.guarantee_end_time = y + "-" + m + "-" + d;
    },

    //选择保修期开始时间
    onStartTimeConfirm(value) {
      this.showStartTimePicker = false;
      var y = value.getFullYear();
      var m = value.getMonth() + 1;
      var d = value.getDate();
      this.formData.guarantee_begin_time = y + "-" + m + "-" + d;
    },
  },
};
</script>

<style scoped>
.order-change {
  position: fixed;
  right: 0;
  bottom: 130px;
  background: #fff;
  padding: 10px;
}
.order-change .btn {
  height: 30px;
  width: 80px;
  font-size: 14px;
}
.status-info .text {
  font-size: 12px;
}
.status-info .img-div {
  display: flex;
}
.status-info .img-div .imgs {
  display: flex;
}
.status-info .img-div .imgs img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}
.logistics {
  height: 100%;
  padding: 20px;
}

.box {
  overflow: hidden;
  position: relative;
}

.box:before {
  content: " ";
  background-color: #f3f3f3;
  display: block;
  position: absolute;
  top: -8px;
  left: 20px;
  width: 10px;
  height: 4px;
}

.list {
  margin: 0;
  padding: 0;
  padding-left: 8px;
  list-style: none;
  margin-top: -8px;
  margin-top: 0px;
}

.list li {
  border-left: 2px solid #d9d9d9;
  text-align: left;
  height: 45px;
  display: block;
}

.list li:before {
  /* 流程点的样式 */
  content: "";
  border: 3px solid #f3f3f3;
  background-color: #d9d9d9;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: -8px;
  margin-bottom: 10px;
}

.list li .content {
  text-align: left;
  margin-left: 25px;
  font-size: 14px;
  margin-top: -30px;
}
.list li .desc {
  text-align: left;
  margin-left: 25px;
  font-size: 14px;
  margin-top: -30px;
}
.list li .time {
  text-align: left;
  margin-left: 25px;
  font-size: 10px;
  margin-top: 5px;
}

.list li.active:before {
  background-color: #2f78dc;
  border-color: #f8e9e4;
}

.list li.active .content {
  color: #2f78dc;
  font-weight: bold;
}
</style>
