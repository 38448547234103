<template>
	<div class="home container expo-index">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">会展中心</span>
			<van-popover placement="bottom-end" v-model="adminShow" trigger="click" :actions="adminActions"
				@select="adminOnSelect">
				<template #reference>
					<span style="font-size: 13px">管理中心</span>
				</template>
			</van-popover>
		</div>
		<div class="swiper-box">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
				<van-swipe-item v-for="(item, index) in slideList" :key="index">
					<a :href="item.link">
						<img :src="url + item.cover" alt="" />
					</a>
				</van-swipe-item>
			</van-swipe>
			<!-- <div class="news-box">
				<div class="icon-box">
					<i class="iconfont icongonggao"></i>
				</div>
				<van-swipe class="news-swipe" :autoplay="3000" :show-indicators="false" :vertical="true">
					<van-swipe-item v-for="(item, index) in topicList" :key="index" @click="toNewDetail(item.id)">{{
            item.title
          }}</van-swipe-item>
				</van-swipe>
			</div> -->
		</div>
		<!-- <div class="rk">
			<div class="item zbf" @click="goPage('/sponsorUser')">
				<van-icon name="user-circle-o" size="25" color="#fff" /><span style="margin-left:5px;">我是主办方</span>
			</div>
			<div class="item account" @click="myUser">
				<van-icon name="shop-o" size="25" color="#fff" /><span style="margin-left:5px;">我是参展商</span>
			</div>
		</div> -->
		<div class="tab-box" style="background:none;width:92vw;padding:0px;">
			<div style="border-radius:5px;overflow:hidden;">
				<van-tabs v-model="tabActive" @change="tabChange" color="#2F78DC">
					<van-tab title="展会预告"></van-tab>
					<van-tab title="往届查询"></van-tab>
				</van-tabs>
			</div>
			<!-- <div class="tab-tit">
				<span class="active">最新会展</span>
			</div> -->
			<div class="expo-list" style="padding:0px;">
				<div class="item" style="position: relative;" v-for="(item,index) in expoList" :key="index" @click="toDetail(item)">
					<div class="banner-box">
						<img class="banner-img" v-if="item.images" :src="url + item.images[0]" alt="">
						<div class="banner-title">{{item.title}}</div>
						<div class="status active">{{getExpoStatus(item)}}</div>
					</div>
					<div class="content-box">
						<div class="title" v-if="item.expo_sponsor_user">主办方：{{item.expo_sponsor_user.company_name}}
						</div>
						<div class="address"> <i class="iconfont iconweizhi"></i>{{item.address}}</div>
						<div class="time">
							<div class="left"><van-icon name="clock-o" />{{item.start_time.substring(0,10)}} 至
								{{item.end_time.substring(0,10)}}
							</div>
							<!-- <div class="right"><van-icon name="friends-o" />{{item.expo_join_data_count}}家报名</div> -->
						</div>
					</div>
					
					<div class="receive" v-if="tabActive == 0" style="position:absolute;bottom:0px;right:0px;background:#FFBA00;height:55px;width:55px;display: flex;align-items: center;justify-content: center;color:#fff;border-top-left-radius: 5px;;">领取<br>门票</div>
					
				</div>
				<van-empty image-size="100" v-if="expoList.length == 0" description="暂无展会数据" />

				<router-link :to="{
					path : '/expoList',
					query : expoListForm
				}" class="more-btn">查看更多</router-link>
			</div>
		</div>
		<div class="tab-box">
			<div class="tab-tit">
				<span class="active">合作媒体</span>
			</div>
			<div class="media-box">
				<swiper class="swiper" :options="swiperOption">
					<swiper-slide class="item" v-for="(item, index) in hezuoList" :key="index">
						<a :href="item.link"><img :src="url + item.cover" alt="" /></a>
					</swiper-slide>
				</swiper>
				<!-- <a
          v-for="(item, index) in hezuoList"
          :key="index"
          :href="item.link"
        >
          <img :src="url + item.cover" alt="" />
        </a> -->
			</div>
		</div>
		<!-- <div class="tab-box">
      <div class="tab-tit">
        <span class="active">合作媒体</span>
      </div>
      <div class="media-box" v-if="sponsorList.length >= 3">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="item" v-for="(item, index) in sponsorList" :key="index">
            <img :src="url + item.company_logo" alt="" @click="toSponsor(item)" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="media-box" v-else>
        <img style="width:100px;height:70px;margin-right:15px;" v-for="(item, index) in sponsorList" :key="index" :src="url + item.company_logo" alt="" @click="toSponsor(item)" />
      </div>
    </div> -->
		<!-- <Tabber></Tabber> -->
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide,
		directive
	} from "vue-awesome-swiper";

	// import style (>= Swiper 6.x)
	import "swiper/swiper-bundle.css";

	// import style (<= Swiper 5.x)
	import "swiper/swiper.less";
	import Swiper2, {
		Navigation,
		Pagination,
		EffectFade,
		Autoplay
	} from 'swiper'
	Swiper2.use([Navigation, Pagination, EffectFade, Autoplay])

	import Tabber from "../../components/Tabber";
	import province_list from "../../utils/area";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	export default {
		components: {
			Tabber,
			Swiper,
			SwiperSlide,
		},
		directives: {
			swiper: directive,
		},
		props: {},
		data() {
			return {
				url: base.apiURL,
				// url: 'http://api.zglxgw.com/',
				active: 0,
				searchValue: "", //搜索内容
				tabsActive: 1,
				topicList: "", //公告列表
				slideList: "", //轮播列表
				hezuoList: "", //轮播列表
				orderList: "", //维修订单列表
				shifuList: "", //维修师傅
				queryParam: {},
				userInfo: {
					id: "",
				},
				reg: /(\d{3})\d{4}(\d{4})/,
				location: "", //当前区域

				mobile: "",
				truename: "",
				province: "",
				city: "",
				area: "",
				address: "",
				setServiceShow: false,
				showArea: false,
				areaList: province_list, // 数据格式见 Area 组件文档
				locationInfo: "",

				swiperOption: {
					slidesPerView: 3,
					spaceBetween: 15,
					loop: true,
					autoplay: {
						delay: 1500,
						disableOnInteraction: false,
					},
				},

				expoList: [],
				sponsorList: [],

				adminShow: false,
				adminActions: [{
					text: '主办方',
					icon: 'user-circle-o'
				}, {
					text: '参展商',
					icon: 'shop-o'
				}],

				tabActive: 0,

				expoListForm: {
					scene: 'yugao'
				}
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.setShare();
			this.getTopic();
			this.getSlide();
			this.getHezuo();
			this.getExpoList()
			this.getSponsor()
		},
		mounted() {},
		watch: {},
		methods: {
			/**
			 * TabChagne
			 */
			tabChange(e) {
				if (e == 0) {
					this.expoListForm.scene = 'yugao';
				} else {
					this.expoListForm.scene = 'previous';
				}
				this.expoList = null;
				this.getExpoList();
			},
			adminOnSelect(e, index) {
				switch (index) {
					case 0:
						this.goPage('/sponsorUser');
						break;

					case 1:
						this.myUser();
						break;
				}
				console.log(e, index);
			},
			myUser() {
				var _this = this;
				if (!_this.userInfo) {
					_this.$toast.fail('请先登录');
					return false;
				}
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/expo_joiner/show", data).then((res) => {
					if (!res.data.data) {
						_this.$toast.fail('请先申请参与展会');
						return false;
					}
					_this.$router.push({
						path: '/joinerUser',
						query: {}
					})
					console.log(res);
				});
			},
			goPage(url, query = {}) {
				this.$router.push({
					path: url,
					query: query
				})
			},
			/**
			 * 返回上级
			 */
			toBack() {
				this.$router.go(-1);
			},
			getExpoStatus(info) {
				var _this = this;
				var current_time = new Date().getTime()
				var start_time = new Date(info.start_time).getTime()
				var end_time = new Date(info.end_time).getTime()

				if (current_time < start_time) {
					return "报名中"
				} else if (current_time > start_time && current_time < end_time) {
					return "活动中"
				} else {
					return "活动结束"
				}
			},
			toNewDetail(id) {
				this.$router.push({
					path: "/expoNewsDetail",
					query: {
						id: id
					}
				})
			},
			toSponsor(item) {
				var _this = this;
				if (item.uid == _this.userInfo.id) {
					this.$router.push({
						path: "/sponsorUser",
						query: {
							id: item.uid
						}
					})
				} else {
					this.$router.push({
						path: "/sponsorIndex",
						query: {
							id: item.uid
						}
					})
				}
			},
			toDetail(item) {
				var _this = this;

				return this.$router.push({
					path: "/expoDetail",
					query: {
						id: item.id
					}
				});

				if (item.uid == _this.userInfo.id) {
					this.$router.push({
						path: "/sponsorExpoDetail",
						query: {
							id: item.id
						}
					})
				} else {
					this.$router.push({
						path: "/expoDetail",
						query: {
							id: item.id
						}
					})
				}

			},
			//获取会展列表
			getExpoList() {
				var _this = this;
				indexOp("/expo/index", this.expoListForm).then(res => {
					if (res && res.status == 200 && res.data.state == 1000) {
						_this.expoList = res.data.data.data
					} else {
						_this.$toast.fail(res.data.msg)
					}
					console.log(res)
				})
			},

			//获取公告
			getSponsor() {
				var _this = this;
				indexOp("/expo_sponsor_user/index").then((res) => {
					console.log(res)
					if (res && res.status == 200) {
						_this.sponsorList = res.data.data.data;
					}
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-会展中心`,
					desc: `媒体合作，互相传播，资源共享，共同发展！`,
					link: base.shareURL + "/expoIndex?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},

			//获取公告
			getTopic() {
				var _this = this;
				// indexOp("/topic/index").then((res) => {
				//   if (res && res.status == 200) {
				//     _this.topicList = res.data;
				//   }
				// });
				indexOp("/news/index?category_id=4&page=1").then((res) => {
					if (res && res.status == 200) {
						_this.topicList = res.data.data.data;
					}
				});
			},

			//获取轮播
			getSlide() {
				var _this = this;
				indexOp("/slide/index?cid=1").then((res) => {
					if (res && res.status == 200) {
						_this.slideList = res.data;
					}
				});
			},

			//获取合作媒体
			getHezuo() {
				var _this = this;
				// indexOp("/expo_sponsor_user/index?recommend=t").then((res) => {
				//   if (res && res.status == 200) {
				//     _this.hezuoList = res.data.data.data;
				//   }
				// });

				indexOp("/slide/index?cid=2").then((res) => {
					if (res && res.status == 200) {
						_this.hezuoList = res.data;
					}
				});
			},
		},
	};
</script>
<style scoped>
	.setService-mask {
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		padding: 20px;
		border-radius: 10px;
	}

	.setService-mask .title {
		text-align: center;
		margin-bottom: 10px;
	}

	.setService-mask .input-item {
		display: flex;
		border-bottom: 1px solid #f2f2f2;
		padding: 10px 0;
	}

	.setService-mask .input-item .tit {
		font-size: 14px;
		width: 30%;
	}

	.setService-mask .input-item input {
		width: 70%;
		font-size: 14px;
		border: none;
	}

	.setService-mask .btn {
		margin: 20px auto 0;
		display: flex;
		justify-content: center;
		color: #fff;
		background: #1989fa;
		border: none;
		width: 100px;
		line-height: 30px;
		text-align: center;
		border-radius: 30px;
		font-size: 14px;
	}
</style>