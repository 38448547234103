<template>
  <div>
    <div class="city-box">
      <van-icon class="icon" name="search" size="20" />
      <span @click="showPicker_1 = true">{{pickerValue_1}}</span>
      <span v-if="columns_2.length > 0">-</span>
      <span v-if="columns_2.length > 0" @click="showPicker_2 = true">{{pickerValue_2}}</span>
      <span v-if="columns_3.length > 0">-</span>
      <span v-if="columns_3.length > 0" @click="showPicker_3 = true">{{pickerValue_3}}</span>
    </div>
    <van-popup v-model="showPicker_1" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_1"
        @cancel="showPicker_1 = false"
        @confirm="onConfirm_1"
        value-key="label"
      />
    </van-popup>
    <van-popup v-model="showPicker_2" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_2"
        @cancel="showPicker_2 = false"
        @confirm="onConfirm_2"
        value-key="label"
      />
    </van-popup>
    <van-popup v-model="showPicker_3" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns_3"
        @cancel="showPicker_3 = false"
        @confirm="onConfirm_3"
        value-key="label"
      />
    </van-popup>
  </div>
</template>

<script>
import address from '../utils/address'
export default {
  props: {},
  data() {
    return {
        showPicker_1:false,
        columns_1:address,
        pickerValue_1:'地区搜索',
        showPicker_2:false,
        columns_2:[],
        pickerValue_2:'选择市',
        showPicker_3:false,
        columns_3:[],
        pickerValue_3:'选择区/县',

        queryParam:{
            province:"",
            city:"",
            area:""
        }
    };
  },
  computed: {},
  created() {
      console.log(address)
  },
  mounted() {},
  watch: {},
  methods: {
      onConfirm_1(value){
          console.log(value)
          this.pickerValue_1 = value.label;
          this.showPicker_1 = false;
          this.columns_2 = value.child;
          this.pickerValue_2 = '选择市';
          this.showPicker_3 = false;
          this.columns_3 = [];
          this.queryParam.province = value.label
      },
      onConfirm_2(value){
          console.log(value)
          this.pickerValue_2 = value.label;
          this.showPicker_2 = false;
          this.columns_3 = value.child
          this.pickerValue_3 = '选择区/县';
          this.queryParam.city = value.label
      },
      onConfirm_3(value){
          console.log(value)
          this.pickerValue_3 = value.label;
          this.showPicker_3 = false;
          this.queryParam.area = value.label
      },
      
  },
  components: {},
};
</script>

<style scoped >
</style>
