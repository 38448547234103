<template>
    <div class="sponsor-index expo-detail">
        <Navbar :title="'参观者管理'"></Navbar>
        <div class="expo-list" style="padding:10px;">
            <div class="item" @click="toDetail">
                <div class="banner-box">
                    <img class="banner-img" v-if="info.images" :src="baseUrl + info.images[0]" alt="">
                    <div class="banner-title">{{ info.title }}</div>
                    <div class="status" style="background:#FFBA00;" v-if="expo_status == '报名中'">报名中</div>
                    <div class="status active" v-if="expo_status == '活动中'">活动中</div>
                    <div class="status" v-if="expo_status == '活动结束'">活动结束</div>
                    <div class="status" style="top:40px;background:#666;" @click.stop="toEdit">重新编辑</div>
                </div> 
                <div class="content-box" v-if="info.expo_sponsor_user">
                    <div class="title">主办方：{{ info.expo_sponsor_user.company_name }}</div>
                    <div class="address"> <i class="iconfont iconweizhi"></i>展会地点：{{info.address}}</div>
                    <div class="time">
                        <div class="left"><van-icon name="clock-o" />展会时间：{{info.start_time}} 至 {{info.end_time}}</div>
                        <div class="right"><van-icon name="friends-o" />{{info.expo_join_data.length}}家报名</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-box">
            <div :class="tabActive == 1 ? 'item active' : 'item'" style="padding-top:0;" >参观者管理</div>
            <!-- <div :class="tabActive == 2 ? 'item active' : 'item'" style="padding-top:0;" @click="changeTab(2)">新增服务商</div> -->
        </div>
        <div class="content">
            <div class="partin-list service">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="visit_list.length > 0 ? '没有更多了' : ''"
                    @load="getVisitList"
                    >
                    <div class="item" v-for="(item,index) in visit_list" :key="index" @click="onShenhe(item)">
                        <div class="left">
                            <img class="avatar" src="../../assets/image/avatar.png" alt="">
                        </div>
                        <div class="right">
                            <div class="status" v-if="item.status == 'WAITING'">等待确认</div>
                            <div class="status" style="background:#6DD400;" v-if="item.status == 'SUCCESS'">确认参展</div>
                            <div class="status" style="background:red;" v-if="item.status == 'FAIL'" >拒绝参展</div>
                            <div class="title">企业名称：{{item.company_name}}</div>
                            <div class="address">真实姓名：{{item.user_name}}</div>
                            <div class="address">企业地址：{{item.address}}</div>
                            <div class="address">
                                <span>联系方式：{{item.mobile}}</span>
                            </div>
                        </div>
                    </div>
                    <van-empty v-if="visit_list.length == 0" description="暂无数据" />
                </van-list>
            </div>
        </div>

        
    <!-- 审核服务商 -->
    <van-overlay :show="show" @click="show = false">
      <div class="setService-mask" @click.stop>
        <div class="title">审核资格</div>
        <div class="input-item">
          <label class="tit">参观者:</label>
          <input type="text" placeholder="参观者" v-model="service_name" readonly />
        </div>
        <div class="input-item">
            <label class="tit">审核状态:</label>
            <input
                type="text"
                placeholder="请选择审核状态"
                v-model="status_text"
                readonly
                @click="showStatus = true"
            />
        </div>
        <!-- <div class="input-item">
          <label class="tit">备注:</label>
          <input type="text" placeholder="请填写备注" v-model="remark" />
        </div> -->
        <button class="btn" @click="onSubmitShenhe">确认</button>
      </div>
    </van-overlay>
    <van-popup v-model="showStatus" position="bottom">
        <van-picker
            title="审核状态"
            show-toolbar
            :columns="statusList"
            @confirm="onConfirm"
            @cancel="showStatus = false"
        />
    </van-popup>
    </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import province_list from "../../utils/area";
export default {
    components: {
        Tabber,
        Navbar,
    },
    inject: ["reload"],
    props: {

    },
    data() {
        return {
            tabActive:"1",
            baseUrl : base.apiURL,
            areaList: province_list,
            info:{},
            expo_id:"",
            logoImgList:[], 
            picImgList:[],

            company_name: "",    //公司名称
            company_phone: "",   //联系方式
            company_address: "", //公司地址
            company_desc: "",    //公司简介
            company_logo: "",    //公司Logo
            company_province: "",   //公司地址-省
            company_city: "",   //公司地址-市
            company_area:"",    //公司地址-区
            company_pic:"",
            images:[],
            videos:[],
            builder:false,
            render:false,

            ImgList:[],
            videoList:[],

            showCityPicker:false,
            address:"",

            expo_status:"",

            show:false,

            service_name:"",
            status_text:"",
            status:"",

            showStatus:false,
            statusList:[
                {text:"等待确认",value:"WAITING"},
                {text:"确认参展",value:"SUCCESS"},
                {text:"拒绝参展",value:"FAIL"},
            ],
            remark:"",
            
            loading:false,
            finished:false,
            page:"",

            visit_list:[],


        };
    },
    computed: {
    },
    created() {
        this.expo_id = this.$route.query.id
        this.getInfo()
    },
    mounted() {

    },
    watch: {

    },
    methods: {
        //获取参观者列表
        getVisitList(){
            var _this = this;
            var data = {
                expo_id : _this.expo_id,
                page : _this.page + 1
            }
            indexOp("/expo_vistor/index",data).then(res=>{
                if(res && res.data.state == 1000){
                if(res.data.data.data.length > 0){
                    _this.visit_list = res.data.data.data
                    _this.page = res.data.data.current_page
                }else{
                    _this.finished = true;
                }
                    _this.loading = false
                }else{
                    _this.$toast.fail(res.data.msg)
                }
                console.log(res)
            })
        },
        onShenhe(item){
            var _this = this;
            if(item.status == 'WAITING'){
                _this.service_name = item.company_name
                _this.service_id = item.id
                _this.show = true
            }
        },
        onConfirm(value){
            this.status_text = value.text;
            this.status = value.value
            console.log(value)
            this.showStatus = false
        },
        onSubmitShenhe(info){
            var _this = this;
            var data = {
                id: _this.service_id,
                status : _this.status,
                expo_id : _this.expo_id
            }
            addOp("/expo_vistor/update",data).then(res=>{
                _this.show = false
                if(res && res.data.state == 1000){
                    _this.reload()
                    _this.$toast.success(res.data.msg)
                }else{
                    _this.$toast.fail(res.data.msg)
                }
                console.log(res)
            })
        },
        toJoinerIndex(id){
            this.$router.push({
                path:"/joinerIndex",
                query:{
                    id : id
                }
            })
        },
        toDetail(){
            this.$router.push({
                path:"/sponsorExpoDetail",
                query:{
                    id : this.expo_id
                }
            })
        },
        toEdit(){
            this.$router.push({
                path:"/sponsorExpoEdit",
                query:{
                    id : this.expo_id
                }
            })
        },
        getInfo(){
            var _this = this;
            var data = {
                id : _this.expo_id
            }
            indexOp("/expo_sponsor_user/show_expo",data).then(res=>{
                if(res && res.data.state == 1000){
                    _this.info = res.data.data
                    _this.expo_status = _this.getExpoStatus(res.data.data)
                    console.log(_this.expo_status)
                }else{
                    _this.$toast.fail(res.data.msg)
                }
                console.log(res)
            })
        },
        getExpoStatus(info){
            var _this = this;
            var current_time = new Date().getTime()
            var start_time = new Date(info.start_time).getTime()
            var end_time = new Date(info.end_time).getTime()

            if(current_time < start_time){
                return "报名中"
            }else if(current_time > start_time && current_time < end_time){
                return "活动中"
            }else{
                return "活动结束"
            }
        },
        changeTab(type){
            this.tabActive = type
        },
        onSubmit(){
            var _this = this;
            if(_this.company_name == ""){
                _this.$toast.fail("企业名称不能为空！");
                return;
            }
            if(_this.company_desc == ""){
                _this.$toast.fail("企业简介不能为空！");
                return;
            }
            if(_this.company_phone == ""){
                _this.$toast.fail("联系方式不能为空！");
                return;
            }
            if(_this.company_province == ""){
                _this.$toast.fail("请选择所在地区！");
                return;
            }
            if(_this.company_address == ""){
                _this.$toast.fail("详细地址不能为空！");
                return;
            }
            var data = {
                expo_id: _this.expo_id,
                company_name : _this.company_name,
                company_phone : _this.company_phone,
                company_desc : _this.company_desc,
                company_province : _this.company_province,
                company_city : _this.company_city,
                company_area : _this.company_area,
                company_address : _this.company_address,
                company_logo: _this.company_logo,
                builder: _this.builder ? "t" : "f",
                render: _this.render ? "t" : "f",
                images: _this.images,
                videos: _this.videos,
                company_pic: _this.company_pic
            }
            console.log(data)
            // return;
            addOp("/expo_service_user/store",data).then(res=>{
                if(res && res.data.state == 1000){
                    _this.$toast.success(res.data.msg);
                    setTimeout(()=>{
                        _this.tabActive = 1;
                        _this.getInfo()
                    },800)
                }else{
                    _this.$toast.fail(res.data.msg);
                }
                console.log(res)
            })
        },
        //选择城市
        onCityConfirm(value, index) {
            this.showCityPicker = false;
            this.company_province = value[0].name;
            this.company_city = value[1].name;
            this.company_area = value[2].name;
            this.address = value[0].name + "-" + value[1].name + "-" + value[2].name
            this.company_address = value[0].name + value[1].name + value[2].name + " "
        },
        //logo图片上传
        afterReadLogo(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadImg(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.company_logo = res.data.url
                    console.log(res)
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },
        //资质图片上传
        afterReadPic(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadImg(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.company_pic = res.data.url
                    // _this.company_pic.push(res.data.url)
                    console.log(res)
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },
        //图片上传
        afterReadImg(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadImg(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.images.push(res.data.url)
                    console.log(res)
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },
        //删除以上传图片
        deleteImg(file,detail){
            this.ImgList.splice(detail.index,1);
            this.images.splice(detail.index,1);
        },
        //视频上传
        afterReadVideo(file) {
            var _this = this;
            console.log(file);
            var form_data = new FormData();
            form_data.append("file", file.file);
            file.status = "uploading";
            file.message = "上传中...";
            uploadFile(form_data).then((res) => {
                if (res && res.status == 200) {
                    file.status = "success";
                    file.message = "";
                    _this.videos.push(res.data.url)
                    console.log(res)
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            });
        },
        //删除以上传视频
        deleteVideo(file,detail){
            this.videoList.splice(detail.index,1);
            this.videos.splice(detail.index,1);
        },
    },
};
</script>

<style scoped >
.form-box .input{
    background: none;
}
.setService-mask {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 20px;
  border-radius: 10px;
}
.setService-mask .title {
  text-align: center;
  margin-bottom: 10px;
}
.setService-mask .input-item {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
}
.setService-mask .input-item .tit {
  font-size: 14px;
  width: 30%;
}
.setService-mask .input-item input {
  width: 70%;
  font-size: 14px;
  border: none;
}
.setService-mask .btn {
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #1989fa;
  border: none;
  width: 100px;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
  font-size: 14px;
}
</style>
