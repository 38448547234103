<template>
  <div class="home container expo-index">
    <Navbar :title="'展会列表'"></Navbar>
    <div class="search-box">
        <van-search v-model="value" background="#f2f2f2" placeholder="请输入搜索关键词" />
    </div>
    <div class="expo-list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="expoList.length > 0 ? '没有更多了' : ''"
        @load="getExpoList"
      >
        <div class="item" v-for="(item,index) in expoList" :key="index" @click="toDetail(item)">
            <div class="banner-box">
                <img class="banner-img" v-if="item.images" :src="baseUrl + item.images[0]" alt="">
                <div class="banner-title">{{item.title}}</div>
                <div class="status active">{{getExpoStatus(item)}}</div>
            </div> 
            <div class="content-box">
                <div class="title" v-if="item.expo_sponsor_user">主办方：{{item.expo_sponsor_user.company_name}}</div>
                <div class="address"> <i class="iconfont iconweizhi"></i>会展地点：{{item.address}}</div>
                <div class="time">
                    <div class="left"><van-icon name="clock-o" />展会时间：{{item.start_time}} 至 {{item.end_time}}</div>
                    <div class="right"><van-icon name="friends-o" />{{item.expo_join_data_count}}家报名</div>
                </div>
            </div>
        </div>
        <van-empty image-size="100" v-if="expoList.length == 0" description="暂无展会数据" />
      </van-list>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>

import Tabber from "../../components/Tabber";
import Navbar from "../../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
export default {
  components: {
    Tabber,
    Navbar
  },
  directives: {
  },
  props: {},
  data() {
    return {
        value:"",
        expoList:[],
        loading:false,
        finished:false,
        page:"",
        baseUrl:base.apiURL,

        userInfo:{
          id:""
        },
        invate_uid:"",
    };
  },
  computed: {},
  created() {
    var invate_uid = this.$route.query.invate_uid;
    if(invate_uid){
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.setShare()
  },
  mounted() {
  },
  watch: {},
  methods: {
    getExpoStatus(info){
        var _this = this;
        var current_time = new Date().getTime()
        var start_time = new Date(info.start_time).getTime()
        var end_time = new Date(info.end_time).getTime()

        if(current_time < start_time){
            return "报名中"
        }else if(current_time > start_time && current_time < end_time){
            return "活动中"
        }else{
            return "活动结束"
        }
    },
    //分享
    setShare() {
        const shareInfo = {
            title: `冷鲜柜网-会展中心`,
            desc: `媒体合作，互相传播，资源共享，合作共赢，共同发展！`,
            link: base.shareURL + "/expoList?invate_uid=" + this.userInfo.id,
            img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
        };
        wechatConfig(shareInfo);
    },
    //获取会展列表
    getExpoList(){
      var _this = this;
      var data = {
        page : _this.page + 1
      }
      indexOp("/expo/index",data).then(res=>{
        if(res && res.status == 200 && res.data.state == 1000){
          if(res.data.data.data.length > 0){
            _this.expoList = res.data.data.data
            _this.page = res.data.data.current_page
          }else{
            _this.finished = true;
          }
          _this.loading = false
        }else{
          _this.$toast.fail(res.data.msg)
        }
        console.log(res)
      })
    },
    toDetail(item){
      if(item.uid == this.userInfo.id){
        this.$router.push({
          path:"/sponsorExpoDetail",
          query:{
            id: item.id
          }
        })
      }else{
        this.$router.push({
          path:"/expoDetail",
          query:{
            id: item.id
          }
        })
      }
    },
  },
};
</script>
