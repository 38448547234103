<template>
	<div class="home container repair">
		<Navbar :title="'维修大厅'"></Navbar>
		<div class="add-box" @click="toRelease">
			<i class="iconfont iconjiahao"></i>
			<span class="text">发布需求</span>
		</div>
		<div style="height:15px"></div>
		<div class="tab-box">
			<van-search class="search-box" v-model="queryParam.keyword" placeholder="输入地区关键词搜索" shape="round"
				background="transparent" @search="onSearch" />
			<div style="height:15px"></div>
			<div class="select-box">
				<div class="status-box" @click="showStatusPicker_1 = true">
					<span class="status">{{ statusText_1 }}</span>
					<span class="icon"><i class="iconfont iconyou"></i></span>
				</div>
				<van-popup v-model="showStatusPicker_1" round position="bottom">
					<van-picker show-toolbar value-key="title" :columns="columns" @cancel="showStatusPicker_1 = false"
						@confirm="onConfirm_1" />
				</van-popup>
				<!-- <div class="status-box" @click="showStatusPicker_2 = true">
          <span class="status">{{statusText_2}}</span>
          <span class="icon"><i class="iconfont iconyou"></i></span>
        </div>
        <van-popup v-model="showStatusPicker_2" round position="bottom">
          <van-picker
            show-toolbar
            value-key="title"
            :columns="columns2"
            @cancel="showStatusPicker_2 = false"
            @confirm="onConfirm_2"
          />
        </van-popup> -->
				<div class="status-box" @click="showStatusPicker_3 = true">
					<span class="status">{{ statusText_3 }}</span>
					<span class="icon"><i class="iconfont iconyou"></i></span>
				</div>
				<van-popup v-model="showStatusPicker_3" round position="bottom">
					<van-picker show-toolbar value-key="title" :columns="columns3" @cancel="showStatusPicker_3 = false"
						@confirm="onConfirm_3" />
				</van-popup>
				<span style="font-size: 12px; color: #666" @click="onChongzhi">重置</span>
			</div>
			<div class="tab-item">
				<div class="list">
					<div class="item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
						<div class="status" v-if="item.deposit_pay_status == 1 && item.door_pay_status == 0">
							已预付订金
						</div>
						<div class="status" v-else-if="item.door_pay_status == 1 || service_pay_status == 1">
							在线聊天交易
						</div>
						<div class="status" v-else-if="
                (item.door_pay_status > 0 &&
                  item.service_type == 'user_sure_service') ||
                item.service_type == 'appraise_order' ||
                item.service_type == 'pay_money'
              ">
							在线聊天交易
						</div>
						<div class="status" v-else style="background: #d6d6d6">
							在线聊天转账
						</div>
						<div class="flex">
							<div class="left-box">
								<img class="avatar" v-if="item.get_user_info" :src="item.get_user_info.avatar" alt="" />
								<span class="name" v-if="item.get_user_info">{{
                  item.get_user_info.nickname
                }}</span>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-if="item.service_type == 'order_bidding'">待接单</van-tag>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-else-if="item.service_type == 'sure_service_user'">已确认师傅</van-tag>
								<van-tag class="tag" color="#ffdc30" text-color="#fff"
									v-else-if="item.service_type == 'take_order'">已接单</van-tag>
								<van-tag class="tag" color="#FF4D4F" text-color="#fff"
									v-else-if="item.service_type == 'arrive_order'">师傅已到达</van-tag>
								<van-tag class="tag" color="#795548" text-color="#fff"
									v-else-if="item.service_type == 'user_sure_arrive'">师傅已到达</van-tag>
								<van-tag class="tag" color="#3f69de" text-color="#fff"
									v-else-if="item.service_type == 'service_ing'">维修中</van-tag>
								<van-tag class="tag" color="#8e8e8e" text-color="#fff"
									v-else-if="item.service_type == 'service_end'">维修完成</van-tag>
								<van-tag class="tag" color="#d6d6d6" text-color="#fff"
									v-else-if="item.service_type == 'user_sure_service'">已确认完成</van-tag>
								<van-tag class="tag" color="#8bc34a" text-color="#fff"
									v-else-if="item.service_type == 'appraise_order'">已评价</van-tag>
								<!-- <van-tag
                  class="tag"
                  color="#bababa"
                  text-color="#fff"
                  v-else-if="item.service_type == 'pay_money'"
                  >订单结束</van-tag
                > -->
								<!-- <div class="service-user">
                <a href="javascript:;">
                  <img class="img" src="@/assets/image/avatar.png" alt="">
                </a>
              </div> -->
							</div>
							<div class="right-box">
								<p class="title">维修产品名称：{{ item.name }}</p>
								<p class="describe">故障描述：{{ item.desc }}</p>
								<p class="time">发布时间：{{ item.created_at }}</p>
								<p class="time" v-if="item.province_info && item.city_info && item.area_info">
									所在地区：{{
                    item.province_info.area_name
                      ? item.province_info.area_name
                      : "暂无"
                  }}-{{
                    item.city_info.area_name
                      ? item.city_info.area_name
                      : "暂无"
                  }}-{{
                    item.area_info.area_name ? item.area_info.area_name : ""
                  }}
								</p>
								<p class="position">
									<i class="iconfont iconweizhi"></i>
									{{ item.full_address }}
								</p>
							</div>
						</div>
						<div class="service-avatar" v-if="
                item.service_type == 'order_bidding' &&
                item.get_order_bidding.length > 0
              ">
							<span></span>
							<div v-for="(src, index) in item.get_order_bidding" :key="index"
								@click.stop="toShopDetail(src.service_id)">
								<img :src="src.service_info.user_info.avatar" alt="" />
							</div>
						</div>
					</div>
					<a href="javascript:;" class="more-btn" @click="onMore" v-if="page != last_page">查看更多</a>
					<div class="empty" v-if="list.length <= 0">
						<van-empty description="暂无数据" />
					</div>
				</div>
			</div>
		</div>
		<Tabber></Tabber>

		<!-- 完善个人资料 -->
		<van-overlay :show="setServiceShow" @click="setServiceShow = false">
			<div class="setService-mask" @click.stop>
				<div class="title">完善个人信息</div>
				<div class="input-item">
					<label class="tit">真实姓名:</label>
					<input type="text" placeholder="请输入真实姓名" v-model="truename" />
				</div>
				<div class="input-item">
					<label class="tit">联系电话:</label>
					<input type="text" placeholder="请输入联系电话" v-model="mobile" />
				</div>
				<div class="input-item">
					<label class="tit">所在地区:</label>
					<input type="text" placeholder="请选择所在地区" v-model="address" readonly @click="showArea = true" />
				</div>
				<button class="btn" @click="onSetServiceSubmit">保存资料</button>
			</div>
		</van-overlay>
		<van-popup v-model="showArea" position="bottom">
			<van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
		</van-popup>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		loginAlert
	} from "../utils/loginAlert";
	import province_list from "../utils/area";
	export default {
		components: {
			Tabber,
			Navbar,
		},
		props: {},
		data() {
			return {
				active: 0,
				searchValue: "", //搜索内容
				showStatusPicker_1: false,
				statusText_1: "订单状态",
				showStatusPicker_2: false,
				statusText_2: "会员等级",
				showStatusPicker_3: false,
				statusText_3: "定金状态",
				columns: [{
						title: "待接单",
						value: "order_bidding",
					},
					{
						title: "已确认师傅",
						value: "sure_service_user",
					},
					{
						title: "已接单",
						value: "take_order",
					},
					{
						title: "师傅已到达",
						value: "arrive_order",
					},
					{
						title: "维修中",
						value: "service_ing",
					},
					{
						title: "维修完成",
						value: "service_end",
					},
					{
						title: "待评价",
						value: "user_sure_service",
					},
					{
						title: "已评价",
						value: "appraise_order",
					},
					{
						title: "订单完成",
						value: "pay_money",
					},
				],
				columns2: [{
						title: "体验会员",
						value: "order_bidding",
					},
					{
						title: "黄金会员",
						value: "order_bidding",
					},
					{
						title: "白金会员",
						value: "order_bidding",
					},
				],
				columns3: [{
						title: "预付定金",
						value: "1",
					},
					{
						title: "未预付费用",
						value: "0",
					},
				],
				list: "", //订单列表
				page: 1,
				last_page: "", //最后一页
				queryParam: {
					limit: 10,
				},
				userInfo: {
					id: "",
				},

				mobile: "",
				truename: "",
				province: "",
				city: "",
				area: "",
				address: "",
				setServiceShow: false,
				showArea: false,
				areaList: province_list, // 数据格式见 Area 组件文档
				locationInfo: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
				this.truename = this.userInfo.truename;
				this.mobile = this.userInfo.mobile;
				this.province = this.userInfo.province;
				this.city = this.userInfo.city;
				this.area = this.userInfo.area;
				this.address =
					this.userInfo.province +
					"-" +
					this.userInfo.city +
					"-" +
					this.userInfo.area;
			}
			if (this.userInfo && this.userInfo.city) {
				this.queryParam.city = this.userInfo.city;
			}
			this.getOrderList();
			this.setShare();
		},
		mounted() {
			this.locationInfo = this.$cookies.get("LocationInfo");
		},
		watch: {},
		methods: {
			onConfirm(values) {
				console.log(values);
				this.address = values
					.filter((item) => !!item)
					.map((item) => item.name)
					.join("-");
				this.province = values[0].name;
				this.city = values[1].name;
				this.area = values[2].name;
				this.showArea = false;
			},
			onSetServiceSubmit() {
				var _this = this;
				if (_this.truename == "") {
					_this.$toast.fail("真实姓名不能为空！");
					return;
				}
				if (_this.mobile == "") {
					_this.$toast.fail("联系电话不能为空！");
					return;
				}
				if (_this.address == "") {
					_this.$toast.fail("所在地区不能为空！");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				console.log(_this.locationInfo);
				var data = {
					truename: _this.truename,
					mobile: _this.mobile,
					province: _this.province,
					city: _this.city,
					area: _this.area,
					latitude: _this.locationInfo ? _this.locationInfo.latitude : "",
					longitude: _this.locationInfo ? _this.locationInfo.longitude : "",
				};

				addOp("/user/update/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.$cookies.set("userInfo", res.data);
						_this.$toast.success("保存成功！");
						setTimeout(() => {
							_this.$router.push("/orderRelease");
						}, 800);
					} else {
						if (res.data.errors) {
							for (var key in res.data.errors) {
								_this.$toast.fail(res.data.errors[key][0]);
							}
						} else {
							_this.$toast.fail(res.data.mag);
						}
					}
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-维修大厅`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/Repair?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			toDetail(order) {
				var _this = this;
				var uid = "";
				var identity = _this.$cookies.get("identity");
				if (_this.$cookies.isKey("userInfo")) {
					uid = _this.$cookies.get("userInfo").id;
				}
				// var data = {
				//   service_id:uid,
				//   order_no : order.order_no
				// }
				// indexOp("/order_bidding/show",data).then(res=>{
				//   console.log(res)
				// })
				if (_this.$cookies.isKey("userInfo") && identity == 2) {
					if (order.uid == uid) {
						this.$router.push({
							path: "/userRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else if (order.service_id == uid) {
						this.$router.push({
							path: "/shifuRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else {
						this.$router.push({
							path: "/RepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					}
				} else {
					if (order.service_id == uid) {
						console.log(uid);
						this.$router.push({
							path: "/shifuRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else if (order.uid == uid) {
						this.$router.push({
							path: "/userRepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					} else {
						this.$router.push({
							path: "/RepairDetail",
							query: {
								order_no: order.order_no,
							},
						});
					}
				}
			},
			toRelease() {
				var _this = this;
				var token = _this.$cookies.get("Authorization");
				var userinfo = _this.$cookies.get("userInfo");
				if (!token) {
					loginAlert()
					return;
				}
				if (
					!this.userInfo.truename ||
					!this.userInfo.mobile ||
					!this.userInfo.province
				) {
					_this.setServiceShow = true;
					return;
				}
				this.$router.push({
					path: "/orderRelease",
				});
			},
			getOrderList(keyword) {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				indexOp("/service_order/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.list = res.data.data;
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
					}
					console.log(res);
				});
			},
			//模糊搜索
			onSearch(val) {
				this.queryParam.keyword = val;
				this.getOrderList();
			},
			//查看更多
			onMore() {
				var _this = this;
				if (_this.page == _this.last_page) {
					_this.$toast("没有更多数据了");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var page = _this.page + 1;
				_this.queryParam.page = page;
				indexOp("/service_order/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.last_page = res.data.last_page;
						_this.page = res.data.current_page;
						if (res.data.data.length <= 0) {
							_this.$toast("没有更多数据了");
							return;
						}
						for (var i = 0; i < res.data.data.length; i++) {
							_this.list.push(res.data.data[i]);
						}
					}
				});
			},
			onConfirm_1(value) {
				this.showStatusPicker_1 = false;
				this.statusText_1 = value.title;
				this.queryParam.service_type = value.value;
				this.getOrderList();
			},
			onConfirm_2(value) {
				this.showStatusPicker_1 = false;
				this.statusText_1 = value.title;
				this.queryParam.service_type = value.value;
				this.getOrderList();
			},
			onConfirm_3(value) {
				this.showStatusPicker_3 = false;
				this.statusText_3 = value.title;
				this.queryParam.deposit_pay_status = value.value;
				this.getOrderList();
			},
			onChongzhi() {
				this.statusText_1 = "订单状态";
				// this.statusText_2 = "会员等级";
				this.statusText_3 = "定金状态";
				this.queryParam.service_type = "";
				this.queryParam.deposit_pay_status = "";
				this.queryParam.keyword = "";
				// this.queryParam.orderSort = '';
				this.getOrderList();
			},
			//师傅详情
			toShopDetail(uid) {
				console.log(uid);
				this.$router.push({
					path: "/shifuShopDetail",
					query: {
						uid: uid,
					},
				});
			},
		},
	};
</script>

<style scoped>
	.setService-mask {
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		padding: 20px;
		border-radius: 10px;
	}

	.setService-mask .title {
		text-align: center;
		margin-bottom: 10px;
	}

	.setService-mask .input-item {
		display: flex;
		border-bottom: 1px solid #f2f2f2;
		padding: 10px 0;
	}

	.setService-mask .input-item .tit {
		font-size: 14px;
		width: 30%;
	}

	.setService-mask .input-item input {
		width: 70%;
		font-size: 14px;
		border: none;
	}

	.setService-mask .btn {
		margin: 20px auto 0;
		display: flex;
		justify-content: center;
		color: #fff;
		background: #1989fa;
		border: none;
		width: 100px;
		line-height: 30px;
		text-align: center;
		border-radius: 30px;
		font-size: 14px;
	}
</style>