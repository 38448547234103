<template>
	<div class="repair-detail" style="padding-bottom: 20px">
		<Navbar :title="'维修详情'"></Navbar>
		<div class="order-info">
			<div class="title-box">
				<span>订单信息</span>
				<span class="time">发布时间：{{ info.created_at }}</span>
			</div>
			<div class="center">
				<div class="describe">
					<div class="left">
						<p class="item tit">维修产品名称：{{ info.name }}</p>
						<p class="item">维修描述：{{ info.desc }}</p>
						<p class="item">
							商品质保：{{ info.is_time_out == 1 ? "保外" : "保内" }}
						</p>
					</div>
					<div class="right" v-if="info.get_user_info">
						<img :src="info.get_user_info.avatar" alt="" />
						<span>{{ info.get_user_info.nickname }}</span>
					</div>
				</div>
				<div class="img-box" v-if="info.image && info.image.length > 0">
					<span>故障产品照片：</span>
					<div class="imgs">
						<img v-for="(item, index) in info.image" :key="index" :src="item" alt=""
							@click="onPreviewImg(info.image)" />
					</div>
				</div>
				<div class="img-box" v-if="info.video && info.video.length > 0">
					<span  @click="previewVideo(info.video);">故障视频：<span style="font-weight: bold;">点击查看</span></span>
				</div>
				<p class="position">
					<span>地图导航：</span>
					<!-- <i class="iconfont iconweizhi"></i> -->
					<a v-if="info.lat && info.lng"
						:href="`https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${info.lat},${info.lng};addr:${info.address}&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp`">
						{{ info.full_address }}
					</a>
					<a v-else :href="`https://apis.map.qq.com/uri/v1/search?keyword=${
              info.address
            }&region=${
              info.city_info ? info.city_info.area_name : ''
            }&referer=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX`">
						{{ info.full_address }}
					</a>
				</p>
				<!-- <div class="position">
          <i class="iconfont iconshouji"></i>{{ info.mobile }}
        </div> -->
				<p class="position">
					<span>用户电话：</span>
					<a v-if="info.service_id == userInfo.id || userInfo.identity == 9" :href="`tel:${info.mobile}`"
						@click.stop>
						{{ info.mobile }}
					</a>
					<a v-else href="javascript:;" @click.stop="showVIp">
						<span v-if="info && info.mobile">{{
              info.mobile.toString().replace(reg, "$1****$2")
            }}</span>
						<span v-else>暂无</span>
					</a>
				</p>
				<p class="position" v-if="userInfo.identity == 9">
					<span>下单人电话：</span>
					<a :href="`tel:${info.get_user_info.mobile}`" @click.stop>
						{{ info.get_user_info.mobile }}
					</a>
				</p>
			</div>
			<div style="display:flex;">
				<div class="status-box" v-if="info.door_pay_status == 1">
					<span class="status">已付上门费</span>
					<span class="icon"><i class="iconfont iconrenminbi"></i></span>
				</div>
				<div class="status-box" v-else-if="info.deposit_pay_status == 1">
					<span class="status">已预付定金</span>
					<span class="icon"><i class="iconfont iconrenminbi"></i></span>
				</div>
				<div class="status-box" v-else>
					<span class="status">未预付费用</span>
					<span class="icon"><i class="iconfont iconrenminbi"></i></span>
				</div>
				<div class="status-box" style="margin-left:5px;" v-if="info.part_status_alias">
					<span class="status">{{info.part_status_alias}}</span>
					<span class="icon"><van-icon name="underway-o" /></span>
				</div>
			</div>
			<div class="coll" @click="toChat" v-if="
          info.service_type == 'order_bidding' ||
          info.uid == userInfo.id ||
          info.service_id == userInfo.id ||
          userInfo.identity == 9
        ">
				<i class="iconfont iconduihua"></i>
				<span>在线聊天</span>
			</div>
			<div class="doortime">
				<p>上门时间：{{ info.accept_door_time }}</p>
			</div>
		</div>

		<!-- <div class="order-process" style="padding:15px;font-size:14px;">先预付维修定金，师傅干活更放心，只要事情处理好，维修费用不能少。</div> -->

		<div class="order-process" style="padding: 15px; font-size: 14px; text-align: center"
			v-if="userInfo.identity == 2">
			<!-- 先预付维修费用，师傅干活更放心！<br/>
      平台担保支付费用，不抽成不收信息费。<br/>
      师傅收到费用在冻结余额，<br/>
      完成任务48个小时后可以随时提现到微信。<br/>
      招募省市区县乡制冷精英合伙人，<br/>
      会员制，只聚集诚实守信，做事靠谱的您。<br/> -->
			先付款后上门检查，查出来问题拍照或录视频发单子里的聊天记录里，先付款再维修，师傅干活更安心！<br /><br />
			平台担保支付，费用在冻结余额，完成任务48个小时后，可以随时提现到微信余额。<br /><br />
			私下交易，下次取消接单资格，师傅提现时，系统自动扣服务费百分之十。
		</div>

		<div class="order-process" v-if="userInfo.identity == 9">
			<!-- <div class="title-box">
        <span>订单进程</span>
        <span class="animation-btn" @click="onShowXieyi">接单流程</span>
      </div>
      <div class="status-box">
        <span
          :class="service_type_active === item.index ? 'active' : ''"
          v-for="(item, index) in statusList"
          :key="index"
          @click="onChangeService(item.index)"
          >{{ item.text }}</span
        >
      </div>
      <template v-if="info.get_service_log && info.get_service_log.length > 0">
        <div v-for="(item, index) in info.get_service_log" :key="index">
          <template v-if="item.service_type == service_type_active">
            <div class="item">操作时间：{{ item.updated_at }}</div>
            <div class="item" v-if="item.content">
              补充说明：{{ item.content }}
            </div>
            <div class="item" v-else>补充说明：无</div>
            <div class="img-box" v-if="item.images && item.images.length > 0">
              <span>故障产品照片：</span>
              <div class="imgs">
                <img
                  v-for="(src, index) in item.images"
                  :key="index"
                  :src="src"
                  alt=""
                  @click="onPreviewImg(item.images)"
                />
              </div>
            </div>
            <div class="img-box" v-if="item.video_url">
              <span>维修视频：</span>
            </div>
            <div
              class="video-box"
              v-if="item.video_url"
              @click="handleShowVideo(item.video_url)"
            >
              <van-icon name="play-circle-o" />
              <img src="@/assets/image/banner1.jpg" />
            </div>
          </template>
        </div>
      </template>
      <div class="item" v-if="info.service_type == 'cancel_order'">
        该订单已取消。
      </div> -->

			<div class="item" style="padding-top: 15px">
				预付定金:<b style="color: red">{{ info.deposit_money }}</b>元
			</div>
		</div>

		<!-- <div class="jingjia" v-if="identity == 1 && uid == info.uid">
      <div class="title-box">
        <span>报价信息</span>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in info.get_order_bidding"
          :key="index"
        >
          <div class="status">保证金</div>
          <div class="flex-box">
            <div class="left-box">
              <img class="avatar" src="@/assets/image/shifu.png" alt="" />
              <van-tag class="tag" color="#7A443A" text-color="#fff"
                >铜牌师傅</van-tag
              >
              <van-rate
                :value="2"
                :size="14"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
            </div>
            <div class="right-box">
              <p class="title" v-if="item.service_info">
                {{ item.service_info.name }}
              </p>
              <div class="price-box">
                <p>
                  上门服务费：<b>¥{{ item.door_money }}</b>
                </p>
                <p>
                  预计上门时间：<b>{{ item.plan_door_time }}</b>
                </p>
                <p v-if="item.is_guarantee == 1">
                  承诺保质期：<b
                    >{{ item.guarantee_begin_time }} -
                    {{ item.guarantee_end_time }}
                  </b>
                </p>
                <p v-else>承诺保修期：<b>不保修</b></p>
              </div>
              <p class="describe" v-if="item.service_info">
                维修范围：{{ item.service_info.service_desc }}
              </p>
              <div class="coll">
                <i class="iconfont icondianhua1"></i>
              </div>
            </div>
          </div>

          <van-button class="btn" type="info" @click="onChoiceShifu(item)"
            ><i class="iconfont iconqueren1"></i> 选择该师傅</van-button
          >
        </div>
        <div class="empty" v-if="info.get_order_bidding.length == 0">
          <van-empty image-size="100" description="暂无师傅报价" />
        </div>
      </div>
    </div>
    <div class="jingjia" v-else-if="identity == 2 && uid == info.service_id">
      <div class="title-box">
        <span>报价信息</span>
      </div>
      <div class="list">
        <div class="item">
          <div class="status">保证金</div>
          <div class="flex-box">
            <div class="left-box">
              <img class="avatar" src="@/assets/image/avatar.png" alt="" />
              <van-tag class="tag" color="#7A443A" text-color="#fff"
                >铜牌师傅</van-tag
              >
              <van-rate
                :value="2"
                :size="14"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
            </div>
            <div class="right-box">
              <p class="title">{{jingjiaDetail.name}}</p>
              <div class="price-box">
                <p>上门服务费：<b>¥{{ jingjiaDetail.door_money }}</b></p>
                <p>预计上门时间：<b>{{ jingjiaDetail.plan_door_time }}</b></p>
                <p>承诺保质期：
                  <b v-if="jingjiaDetail.is_guarantee == 1">
                    {{ jingjiaDetail.guarantee_begin_time }} - {{ jingjiaDetail.guarantee_end_time }}
                  </b>
                  <b v-else>不保修</b>
                </p>
              </div>
              <p class="describe" v-if="item.service_info">
                维修范围：{{ jingjiaDetail.service_info.service_desc }}
              </p>
              <div class="coll">
                <i class="iconfont icondianhua1"></i>
              </div>
            </div>
          </div>

          <van-button v-if="identity == 1" class="btn" type="info" ><i class="iconfont iconqueren1"></i> 选择该师傅</van-button>
        </div>
      </div>
    </div> -->
		<div class="jingjia" v-if="userInfo.identity == 9">
			<div class="title-box">
				<span>师傅信息</span>
			</div>
			<div class="list" v-if="serviceInfo">
				<div class="item" v-if="serviceInfo" @click="toShifuDetail(serviceInfo.id)">
					<!-- <div class="status">保证金</div> -->
					<div class="flex-box">
						<div class="left-box">
							<img class="avatar" v-if="serviceInfo.service_info"
								:src="serviceInfo.service_info.user_info.avatar" alt="" />
							<img class="avatar" v-else src="../assets/image/shifu.png" alt="" />
							<van-tag v-if="serviceInfo.believe > 90" class="tag" color="#ff5722" text-color="#fff">金牌师傅
							</van-tag>
							<van-tag v-else-if="serviceInfo.believe > 80 && serviceInfo.believe < 90" class="tag"
								color="#a7a7a7" text-color="#fff">银牌师傅</van-tag>
							<van-tag v-else-if="serviceInfo.believe < 80" class="tag" color="#7A443A" text-color="#fff">
								铜牌师傅</van-tag>
							<van-rate v-if="serviceInfo.service_info" :value="serviceInfo.service_info.comment_star"
								:size="14" color="#ffd21e" void-icon="star" void-color="#eee" />
						</div>
						<div class="right-box">
							<p class="title">
								{{ serviceInfo.name }}
							</p>
							<p class="describe">
								联系人：{{ serviceInfo.person ? serviceInfo.person : "暂无" }}
							</p>
							<p class="describe">
								联系电话：{{ serviceInfo.phone ? serviceInfo.phone : "暂无" }}
							</p>
							<p class="describe">
								所在地区：{{
                  serviceInfo.province_info
                    ? serviceInfo.province_info.area_name
                    : ""
                }}
								{{
                  serviceInfo.city_info ? serviceInfo.city_info.area_name : ""
                }}
								{{
                  serviceInfo.area_info ? serviceInfo.area_info.area_name : ""
                }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-box" v-if="false">
			<div class="title-box">
				<span>订单私密信息</span>
			</div>
			<p class="item">
				<span class="tit">上门维修费：</span>
				<span class="price">¥{{ info.door_money }}</span>
			</p>
			<p class="item">
				<span class="tit">维修费用：</span>
				<span class="price">¥{{ info.service_money }}</span>
			</p>
			<p class="item">
				<span class="tit">配件费用：</span>
				<span class="price">¥{{ info.fittings_money }}</span>
			</p>
		</div>
		<div class="bottom-btn" v-if="
        userInfo.identity === 9 ||
        info.service_id === userInfo.id ||
        info.real_uid === userInfo.id
      ">
			<van-button class="jingjia-btn" type="info" @click="toChat">进入聊天室</van-button>
		</div>
		<div class="bottom-btn" v-else>
			<van-button class="jingjia-btn" type="info" @click="toJingjia" v-if="info.service_type == 'order_bidding'">
				确认接单</van-button>
			<van-button class="jingjia-btn" type="info" @click="toChat" v-else-if="
          info.service_type == 'service_ing' && info.service_id == userInfo.id
        ">进入聊天室</van-button>
			<van-button class="jingjia-btn" type="info" v-else-if="info.service_type == 'service_end'" disabled>维修完成
			</van-button>
			<van-button class="jingjia-btn" type="info" v-else disabled>正在进行中</van-button>
		</div>

		<van-overlay :show="jingjiaShow" @click="jingjiaShow = false" :lock-scroll="false">
			<div class="xieyi-content">
				<div class="title">提示</div>
				<div class="content">
					为避免费用混淆，系统建议您先行与客户沟通费用，再进行报价。
				</div>
				<div class="btns">
					<button class="btn" @click="toBidding">直接报价</button>
					<button class="btn" @click="toUserChat">在线聊天</button>
				</div>
			</div>
		</van-overlay>
		<!-- <div class="bottom-btn" v-if="to_login">
      <van-button class="jingjia-btn" type="info" @click="toLogin"
        >参与报价</van-button
      >
    </div> -->
		<div class="bottom-block" v-if="identity == 2"></div>
		<!-- <Tabber></Tabber> -->
		<!-- 支付方式 -->
		<van-overlay :show="payTypeShow" @click="payTypeShow = false">
			<div class="payType-mask" @click.stop>
				<div class="title">请选择支付方式</div>
				<div class="item" @click="changePayType(1)">
					<i class="iconfont iconyue"></i>
					<span>余额支付</span>
					<i v-if="payType == 1" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<div class="item" @click="changePayType(2)">
					<i class="iconfont iconweixinzhifu" style="color: #0cab01"></i>
					<span>微信支付</span>
					<i v-if="payType == 2" class="iconfont iconqueren1 active"></i>
					<i v-else class="iconfont iconqueren1"></i>
				</div>
				<button class="pay-btn" @click="onPay">支付</button>
			</div>
		</van-overlay>
		<van-image-preview v-model="previewShow" :images="previewImages">
		</van-image-preview>

		<div class="bottom-btn" v-if="info.service_type == 'order_bidding' && jingjiaDetail != ''">
			<van-button class="jingjia-btn" type="info" @click="onCancelBidding">取消报价</van-button>
		</div>

		<Tabber></Tabber>
		<notice :xieyiShow.sync="xieyiShow"></notice>

		<van-overlay :show="showVideo">
			<div class="videoDialog">
				<div class="close" @click="closeVideoDialog">
					<van-icon name="cross" />
				</div>
				<video :src="videoSrc" style="width: 100%" controls="controls" ref="video"
					poster="@/assets/image/banner1.jpg" x5-video-orientation="portraint" webkit-playsinline="true"
					playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5"
					x5-video-player-fullscreen="true" x5-video-ignore-metadata="true"></video>
			</div>
		</van-overlay>

		<!-- 完善师傅资料 -->
		<van-overlay :show="setServiceShow" @click="setServiceShow = false">
			<div class="setService-mask" @click.stop>
				<div class="title">完善师傅信息</div>
				<div class="input-item">
					<label class="tit">店铺名称:</label>
					<input type="text" placeholder="请输入维修店铺名称" v-model="service_name" />
				</div>
				<div class="input-item">
					<label class="tit">联系人:</label>
					<input type="text" placeholder="请输入维修店铺联系人" v-model="service_person" />
				</div>
				<div class="input-item">
					<label class="tit">联系电话:</label>
					<input type="text" placeholder="请输入维修店铺联系电话" v-model="service_phone" />
				</div>
				<div class="input-item">
					<label class="tit">所在地区:</label>
					<input type="text" placeholder="请选择所在地区" v-model="areaText[3]" readonly @click="showArea = true" />
				</div>
				<button class="btn" @click="onSetServiceSubmit">保存资料</button>
			</div>
		</van-overlay>
		<!-- LastArea -->
		<!-- <van-popup v-model="showArea" position="bottom">
			<van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
		</van-popup> -->
		<block v-if="areaList && areaList.length">
			<van-popup v-model="showArea" round position="bottom">
				<van-cascader title="请选择所在地区" :options="areaList" @close="showArea = true" @finish="onFinish"
					:field-names="{ text: 'label', value: 'value', children: 'child' }" />
			</van-popup>
		</block>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import notice from "../components/notice";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	import {
		getUser,
		login
	} from "../utils/login_toast";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import province_list from "../utils/area";
	import {
		loginAlert
	} from "../utils/loginAlert";
	export default {
		components: {
			Navbar,
			Tabber,
			notice,
		},
		props: {},
		data() {
			return {
				showVideo: false, // 展示视频
				videoSrc: "", // 视频地址
				order_no: "", //订单id
				info: "", //订单信息
				statusList: [{
						index: "order_bidding",
						text: "待接单",
					},
					{
						index: "sure_service_user",
						text: "已确认师傅",
					},
					{
						index: "take_order",
						text: "已接单",
					},
					{
						index: "arrive_order",
						text: "师傅已到达",
					},
					{
						index: "user_sure_arrive",
						text: "确认师傅到达",
					},
					{
						index: "service_ing",
						text: "维修中",
					},
					{
						index: "service_end",
						text: "维修完成",
					},
					{
						index: "user_sure_service",
						text: "确认完成",
					},
					{
						index: "appraise_order",
						text: "已评价",
					},
					// {
					//   index: "pay_money",
					//   text: "订单结束",
					// },
					{
						index: "cancel_order",
						text: "订单取消",
					},
				],
				identity: "1",
				jingjiaDetail: "",
				uid: "", //自己的id
				payTypeShow: false,
				payType: 1, //1 余额支付 2微信支付
				bj_money: 0, //待补缴金额
				serviceInfo: "",
				jsApiParameters: "",
				shopDetail: "", //店铺信息
				service_type_active: "",
				previewImages: [],
				previewShow: false,
				userInfo: {
					id: "",
				},
				loginURL: base.loginURL,
				to_login: false,
				reg: /(\d{3})\d{4}(\d{4})/,
				jingjiaShow: false,
				xieyiShow: false,

				setServiceShow: false,

				service_name: "",
				service_person: "",
				service_phone: "",
				province: "",
				city: "",
				area: "",
				address: "",
				showArea: false,
				areaList: [], // 数据格式见 Area 组件文档
				areaText: [],
			};
		},
		computed: {},
		created() {
			const _this = this;
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			var userInfo 	=	this.$cookies.get("userInfo");
			if (!this.$cookies.isKey("Authorization") && !code) {
				this.toLogin()
				return;
			} else if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			}else if( !userInfo && this.$cookies.isKey("Authorization") && code ){
				login(this.getUser);
			}
			this.order_no = this.$route.query.order_no;
			this.identity = this.$cookies.get("identity");
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
				this.uid = this.$cookies.get("userInfo").id;
			}
			this.getOrderDetail();
			// if (this.identity == 2) {
			this.getShopDetail();
			// }
			if (this.$cookies.isKey("userInfo")) {
				// console.log(this.userInfo);
				this.getJingjiaDetail();
			}
			this.getServiceRegion();
		},
		mounted() {},
		watch: {},
		methods: {
			/**
			 * 预览视频
			 */
			previewVideo(e){
				if(e[0]){
					window.open(e[0]);
				}
			},
			//获取师傅详情
			getServerDetail() {
				var _this = this;
				var data = {
					uid: _this.info.service_id,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status) {
						_this.serviceInfo = res.data;
					}
					console.log(res);
				});
			},
			//获取地区列表
			getServiceRegion() {
				var _this = this;
				indexOp("/area/all_format_child").then((res) => {
					_this.areaList = res.data;
				});
			},
			// 修改师父资料地区提交
			onFinish({
				selectedOptions
			}) {
				this.areaText = selectedOptions.map((option) => option.label);
				this.areaText.push(
					this.areaText[0] + "-" + this.areaText[1] + "-" + this.areaText[2]
				);

				this.areaValue = selectedOptions.map((option) => option.value);
				this.province = this.areaValue[0];
				this.city = this.areaValue[1];
				this.area = this.areaValue[2];

				this.showArea = false;
			},
			showVIp() {
				this.$dialog
					.confirm({
						title: "提示",
						confirmButtonText: "查看会员权益",
						message: "成为冷鲜柜黄金会员即可直接查看拨打电话",
					})
					.then(() => {
						this.$router.push("/vip");
						// on confirm
					})
					.catch(() => {
						// on cancel
					});
			},
			onConfirm(values) {
				console.log(values);
				this.address = values
					.filter((item) => !!item)
					.map((item) => item.name)
					.join("-");
				this.province = values[0].name;
				this.city = values[1].name;
				this.area = values[2].name;
				this.showArea = false;
			},
			//完善维修师傅资料
			onSetServiceSubmit() {
				var _this = this;
				if (_this.service_name == "") {
					_this.$toast.fail("店铺名称不能为空！");
					return;
				}
				if (_this.service_person == "") {
					_this.$toast.fail("店铺联系人不能为空！");
					return;
				}
				if (_this.service_phone == "") {
					_this.$toast.fail("店铺联系电话不能为空！");
					return;
				}
				if (_this.province == "") {
					_this.$toast.fail("所在地区不能为空！");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					name: _this.service_name,
					person: _this.service_person,
					phone: _this.service_phone,
					province: _this.province,
					city: _this.city,
					area: _this.area,
				};
				addOp("/service_user/create", data).then((res) => {
					_this.$toast.clear();
					if (res && res.data) {
						_this.onSuccessOrder();
					}
				});
			},
			//师傅确认接单
			onSuccessOrder() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
					service_type: "take_order",
				};
				addOp("/service_order/take_order", data).then((res) => {
					if (res && res.data) {
						if (res.data.error) {
							_this.$toast.fail(res.data.error.replace(/[a-zA-z|\.|\/]*/gi, ""));
							return false;
						}
						_this.$dialog
							.confirm({
								title: "接单成功",
								message: "是否立即拨打用户电话？了解维修细节，用户位置，维修时间等。",
								confirmButtonText: "拨打电话",
							})
							.then(() => {
								_this.contact_we();
							})
							.catch(() => {
								// on cancel
							});

						_this.$toast.success("接单成功！");
						_this.setServiceLog();
						_this.toChat();
					} else {
						_this.$toast.fail(res.data.error);
					}
				});
			},
			contact_we() {
				var tel = this.info.mobile;
				console.log("拨打电话" + tel);
				var a = document.createElement("a");
				a.href = "tel:" + tel;
				a.click();
			},
			setServiceLog() {
				var _this = this;
				var data = {
					uid: _this.uid,
					order_no: _this.info.order_no,
					service_type: "take_order",
					identity_type: "service",
				};
				addOp("/service_log/create", data).then((res) => {
					// _this.reload();
					// console.log(res);
				});
			},
			// 打开视频弹窗，并将视频地址赋值
			handleShowVideo(video_url) {
				this.videoSrc = video_url;
				this.showVideo = true;
				setTimeout(() => {
					this.$refs.video.play();
				}, 10);
			},

			// 关闭视频弹窗， 并情况视频地址
			closeVideoDialog() {
				this.showVideo = false;
				this.videoSrc = "";
			},

			onShowXieyi() {
				this.xieyiShow = true;
			},

			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},

			//分享
			setShare() {
				var vip = "";
				if (this.info.user_vip_info && this.info.user_vip_info.vip_level == 1) {
					vip = "普通会员";
				} else if (
					this.info.user_vip_info &&
					this.info.user_vip_info.vip_level == 2
				) {
					vip = "黄金会员";
				} else if (
					this.info.user_vip_info &&
					this.info.user_vip_info.vip_level == 3
				) {
					vip = "钻石会员";
				} else {
					vip = "体验会员";
				}
				var title = "招募制冷维保技师合伙人- " + this.info.name,
					provinceName = this.info.province_info ?
					this.info.province_info.area_name :
					"暂无",
					cityName = this.info.city_info ? this.info.city_info.area_name : "暂无",
					areaName = this.info.area_info ? this.info.area_info.area_name : "暂无";

				const shareInfo = {
					title: "招募制冷维保技师合伙人- " +
						this.info.name +
						" " +
						provinceName +
						"-" +
						cityName +
						"-" +
						areaName,
					desc: "点确认接单，进单子的聊天室里，报上门检修费，先付款再维修，让师傅更放心！",
					link: base.shareURL +
						"/RepairDetail?order_no=" +
						this.order_no +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},

			getOrderDetail() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
				};
				indexOp("/service_order/show", data).then((res) => {
					if (res && res.status) {
						_this.info = res.data;
						_this.service_type_active = res.data.service_type;
						_this.setShare();
						if (res.data.service_id) {
							_this.getServerDetail();
						}
						console.log("Info", res.data);
					}
				});
			},

			//去报价
			toJingjia() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					this.toLogin()
					return;
				}
				var userInfo = this.$cookies.get("userInfo");
				if (userInfo.identity == 2 || userInfo.identity == 0) {
					if (
						this.shopDetail &&
						this.shopDetail.name &&
						this.shopDetail.person &&
						this.shopDetail.phone
						 // && this.shopDetail.city
					) {
						_this.onSuccessOrder();
					} else {
						_this.setServiceShow = true;
					}
				} else {
					_this.$dialog
						.confirm({
							title: "提示",
							message: "您不是维修师傅，不能参与报价，可去个人中心修改身份信息！",
							confirmButtonText: "去修改",
						})
						.then(() => {
							this.$router.push({
								path: "/identity",
							});
							// on confirm
						});
				}
			},

			toBidding() {
				this.$router.push({
					path: "/shifuBidding",
					query: {
						order_no: this.info.order_no,
					},
				});
			},

			//获取店铺详情
			getShopDetail() {
				var _this = this;
				var data = {
					uid: _this.uid,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status == 200) {
						_this.shopDetail = res.data;
						_this.service_name = res.data.name;
						_this.service_person = res.data.person;
						_this.service_phone = res.data.phone;
						_this.province = res.data.province;
						_this.city = res.data.city;
						_this.area = res.data.area;
						if (_this.province) {
							_this.address =
								res.data.province + "-" + res.data.city + "-" + res.data.area;
						}
					}
					// console.log(res);
				});
			},

			//获取报价信息
			getJingjiaDetail() {
				var _this = this;
				var service_id = _this.userInfo.id;
				var data = {
					service_id: service_id,
					order_no: _this.order_no,
				};
				indexOp("/order_bidding/show", data).then((res) => {
					if (res && res.status == 200) {
						_this.jingjiaDetail = res.data;
					}
					// console.log(res);
				});
			},

			//取消报价
			onCancelBidding() {
				var _this = this;
				var data = {
					order_no: _this.info.order_no,
				};
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认取消该报价？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then(() => {
						indexOp("/order_bidding/destroy", data).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("操作成功！");
								setTimeout(() => {
									_this.$router.go(-1);
								}, 800);
							}
							// console.log(res);
						});
					});
			},

			//去对话
			toUserChat() {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = _this.info.uid; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					this.toLogin()
				}
			},

			//去对话
			toChat() {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = _this.info.uid; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "order",
							order_no: _this.order_no,
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					this.toLogin()
				}
			},

			onChoiceShifu(item) {
				var _this = this;
				_this.serviceInfo = item;
				var deposit_money = parseFloat(_this.info.deposit_money);
				var door_money = parseFloat(item.door_money);
				// 未支付订金
				if (_this.info.deposit_pay_status == 0) {
					_this.bj_money = door_money;
					_this.$dialog
						.confirm({
							title: "提示",
							message: "您需要补交上门费用：" + _this.bj_money + "元。",
							confirmButtonText: "确认补交",
						})
						.then(() => {
							_this.payTypeShow = true;
							// on confirm
						})
						.catch(() => {
							// on cancel
						});
					return;
				}
				// 支付订金小于上门费
				if (deposit_money < door_money) {
					_this.bj_money = door_money - deposit_money;
					_this.$dialog
						.confirm({
							title: "提示",
							message: "您需要补交上门费用：" + _this.bj_money + "元。",
							confirmButtonText: "确认补交",
						})
						.then(() => {
							_this.payTypeShow = true;
							// on confirm
						})
						.catch(() => {
							// on cancel
						});
				} else {
					_this.successShifu();
				}
			},

			onPay() {
				var _this = this;
				var type = this.payType;
				if (type == 2) {
					var data = {
						order_no: _this.order_no,
						money: _this.bj_money,
						pay_type: "door_money_add_wxpay",
					};
					addOp("/pay_order", data).then((res) => {
						if (res && res.status == 200) {
							_this.jsApiParameters = res.data;
							_this.callpay();
							_this.payTypeShow = false;
						} else {
							_this.$toast.fail(res.data.error);
						}
					});
				}
			},

			//添加维修日志
			addServiceLog(item) {
				var _this = this;
				var data = {
					uid: _this.info.uid,
					identity_type: "user",
					order_no: _this.info.order_no,
					service_type: "sure_service_user",
				};
				addOp("/service_log/create", data).then((res) => {
					// console.log(res);
				});
			},

			changePayType(type) {
				this.payType = type;
			},

			//支付
			jsApiCall() {
				var _this = this;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest",
					_this.jsApiParameters,
					function(res) {
						if (res.err_msg === "get_brand_wcpay_request:ok") {
							// alert("支付成功！");
							_this.successShifu();
							_this.payTypeShow = false;
						} else if (res.err_msg === "get_brand_wcpay_request:fail") {
							alert("支付失败！请重试！");
						}
					}
				);
			},

			callpay() {
				var _this = this;
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							_this.jsApiCall,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
						document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
					}
				} else {
					_this.jsApiCall();
				}
			},

			//确认师傅
			successShifu() {
				var _this = this;
				var data = {
					order_no: _this.order_no,
					mobile: _this.info.mobile,
					service_id: _this.serviceInfo.service_id,
					deposit_money: _this.info.deposit_money,
					door_money: _this.serviceInfo.door_money,
					service_money: "",
					fittings_money: "",
					service_type: "sure_service_user",
					is_guarantee: _this.serviceInfo.is_guarantee,
					guarantee_begin_time: _this.serviceInfo.guarantee_begin_time,
					guarantee_end_time: _this.serviceInfo.guarantee_end_time,
					accept_door_time: _this.serviceInfo.plan_door_time,
				};
				addOp("/service_order/update", data).then((res) => {
					if (res && res.data) {
						_this.$toast.success("操作成功！");
						_this.addServiceLog(_this.serviceInfo);
						_this.reload();
					} else {
						_this.$toast.fail("操作失败！");
					}
					console.log(res);
				});
			},

			onChangeService(type) {
				this.service_type_active = type;
			},

			onPreviewImg(arr) {
				this.previewImages = arr;
				this.previewShow = true;
				// console.log(arr);
			},

			toLogin() {
				var _this = this;
				loginAlert('登陆后查看维修订单详情，请先登录', 1, '点击前往登录')
			},

			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
				_this.identity = userInfo.identity;
				if (userInfo.identity == 2) {
					this.uid = userInfo.id;
					this.getJingjiaDetail();
					this.getShopDetail();
				}
			},
		},
	};
</script>
<style scoped lang="less">
	.setService-mask {
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		padding: 20px;
		border-radius: 10px;
	}

	.setService-mask .title {
		text-align: center;
		margin-bottom: 10px;
	}

	.setService-mask .input-item {
		display: flex;
		border-bottom: 1px solid #f2f2f2;
		padding: 10px 0;
	}

	.setService-mask .input-item .tit {
		font-size: 14px;
		width: 30%;
	}

	.setService-mask .input-item input {
		width: 70%;
		font-size: 14px;
		border: none;
	}

	.setService-mask .btn {
		margin: 20px auto 0;
		display: flex;
		justify-content: center;
		color: #fff;
		background: #1989fa;
		border: none;
		width: 100px;
		line-height: 30px;
		text-align: center;
		border-radius: 30px;
		font-size: 14px;
	}

	.doortime {
		font-size: 14px;
		font-weight: bold;
		margin-top: 10px;
	}

	.status-box img {
		width: 100%;
	}

	.video-box {
		position: relative;

		img {
			width: 100%;
			height: 120;
			border-radius: 2px;
			object-fit: cover;
		}

		i {
			position: absolute;
			top: 60px;
			left: 50%;
			color: #fff;
			font-size: 50px;
			margin-left: -25px;
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 50%;
		}
	}

	.videoDialog {
		width: 100%;
		margin: 46px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100% - 46px);
		position: relative;

		video {
			max-height: 100%;
			// object-fit: fill;
		}

		.close {
			position: absolute;
			right: 10px;
			top: 0px;
			color: #fff;
			font-size: 30px;
			cursor: pointer;
			z-index: 9999;
		}
	}

	.repair-detail .bottom-btn {
		z-index: 999;
	}
</style>