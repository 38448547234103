<template>
  <div class="map-box">
    <!-- <div class="map" ref="map"></div> -->
    <div class="top-navbar">
      <van-icon class="left-icon" name="arrow-left" @click="onClose" />
      <div class="text">选择地址</div>
      <button class="config-btn" @click="onConfig">确认</button>
    </div> 
    <iframe id="mapPage" width="100%" height="100%" frameborder=0
      src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX&referer=地图-mapApp">
    </iframe>
    <!-- <button class="sub-btn">确定</button> -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      location:""
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.Tmap();
    var _this = this;
    window.addEventListener('message', function(event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          console.log('location', loc);
          _this.location = loc
        }
    }, false);
  },
  watch: {},
  methods: {
    onConfig(){
      var _this = this;
      if(_this.location == ""){
        _this.$toast.fail("请选择地址！")
        return;
      }
      _this.$emit("config-map",_this.location)
    },
    onClose(){
      this.$emit("close-map")
    },
    Tmap() {
      var _this = this;
      var geolocation = new qq.maps.Geolocation(
        "U5LBZ-JMZC6-UWSSL-EI7JM-5UYL5-PXBWX",
        "地图-mapApp"
      );
      geolocation.getLocation(function (res) {
        console.log(res);
        var lat = res.lat;
        var lng = res.lng;
        var center = new TMap.LatLng(lat, lng);
        var mapDev = _this.$refs.map;
        var map = new TMap.Map(mapDev, {
          center: center, //设置地图中心点坐标
          zoom: 12, //设置地图缩放级别
        });

        var markerLayer = new TMap.MultiMarker({
          map: map, //指定地图容器
          //样式定义
          styles: {
            //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            myStyle: new TMap.MarkerStyle({
              width: 35, // 点标记样式宽度（像素）
              height: 35, // 点标记样式高度（像素）
              src: "./image/icon1.png", //图片路径
              //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
              anchor: { x: 16, y: 32 },
            }),
          },
          //点标记数据数组
          geometries: [
            {
              id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
              styleId: "myStyle", //指定样式id
              position: new TMap.LatLng(lat, lng), //点标记坐标位置
              properties: {
                //自定义属性
                title: "marker1",
              },
            }
          ],
        });
      },{timeout:9000});
      // console.log(a)
    },
  },
  components: {},
};
</script>

<style lang="less">
.map {
  width: 100%;
  height: 500px;
}
.map-box{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.sub-btn{
  /* position: absolute;
  bottom: 100px;
  z-index: 999; */
}
.top-navbar{
  width: 100%;
  height: 50px;
  background: #2F78DC;
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color:#fff;
  position: relative;
  .text{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
.config-btn{
  border-radius: 5px;
  border: none;
  background: #fff;
  color:#2F78DC;
  font-size: 13px;
  padding: 5px 20px;
}
</style>
