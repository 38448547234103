<template>
  <div class="myinviter">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">我的邀请</span>
    </div>
    <div class="myinvitertop">
      <div class="myinvitertopleft">
        <h3>邀请人数</h3>
        <p>{{ myinviterTotal }}人</p>
      </div>
      <div class="myinvitertopright">
        <h3>新增人数</h3>
        <p>{{ addCount }}人</p>
      </div>
    </div>
    <div class="myinviterlist">
      <div class="myinviterlisttitle">
        <span>邀请列表</span>
      </div>
      <div class="myinviterlistcontent">
        <!-- <div class="myinviteritem" v-for="(item,index) in myinviterList" :key="index">
                    <div class="myinviteritemleft">
                        <img :src="item.avatar" alt="">
                    </div>
                    <div class="myinviteritemright">
                        <div class="myinviteritemrightname">
                            <p>{{item.nickname}}</p>
                        </div>
                        <div class="myinviteritemrightxfzj" v-if="item.pay_order != null">
                            <p>最近消费金额：{{item.pay_order.money}}元</p>
                        </div>
                        <div class="myinviteritemrighttime" v-if="item.pay_order != null">
                            <p>最近消费时间：{{item.pay_order.updated_at}}</p>
                        </div>
                    </div>
                </div> -->
        <van-tabs v-model="active" color="#007aff" @change="onChange">
          <van-tab title="全部">
            <div
              class="myinviteritem"
              v-for="(item, index) in myinviterList"
              :key="index"
            >
              <div class="myinviteritemleft">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="myinviteritemright">
                <div class="myinviteritemrightname">
                  <p>
                    <span>{{ item.nickname }}</span>
                  </p>
                  <!-- <van-icon name="setting-o" class="set-icon" /> -->
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>
                    用户身份：<van-tag type="primary">{{
                      item.identity_name
                    }}</van-tag>
                  </p>
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>联系方式：{{ item.mobile }}</p>
                </div>
              </div>
            </div>
            <div class="empty" v-if="myinviterList.length <= 0">
              <van-empty description="暂无数据" />
            </div>
          </van-tab>
          <van-tab title="终端用户">
            <div
              class="myinviteritem"
              v-for="(item, index) in myinviterList"
              :key="index"
            >
              <div class="myinviteritemleft">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="myinviteritemright">
                <div class="myinviteritemrightname">
                  <p>
                    <span>{{ item.nickname }}</span>
                  </p>
                  <!-- <van-icon name="setting-o" class="set-icon" /> -->
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>
                    用户身份：<van-tag type="primary">{{
                      item.identity_name
                    }}</van-tag>
                  </p>
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>联系方式：{{ item.mobile }}</p>
                </div>
              </div>
            </div>
            <div class="empty" v-if="myinviterList.length <= 0">
              <van-empty description="暂无数据" />
            </div>
          </van-tab>
          <van-tab title="制冷商家">
            <div
              class="myinviteritem"
              v-for="(item, index) in myinviterList"
              :key="index"
            >
              <div class="myinviteritemleft">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="myinviteritemright">
                <div class="myinviteritemrightname">
                  <p>
                    <span>{{ item.nickname }}</span>
                  </p>
                  <!-- <van-icon name="setting-o" class="set-icon" /> -->
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>
                    用户身份：<van-tag type="primary">{{
                      item.identity_name
                    }}</van-tag>
                  </p>
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>联系方式：{{ item.mobile }}</p>
                </div>
              </div>
            </div>
            <div class="empty" v-if="myinviterList.length <= 0">
              <van-empty description="暂无数据" />
            </div>
          </van-tab>
          <van-tab title="维修师傅">
            <div
              class="myinviteritem"
              v-for="(item, index) in myinviterList"
              :key="index"
            >
              <div class="myinviteritemleft">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="myinviteritemright">
                <div class="myinviteritemrightname">
                  <p>
                    <span>{{ item.nickname }}</span>
                  </p>
                  <!-- <van-icon name="setting-o" class="set-icon" /> -->
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>
                    用户身份：<van-tag type="primary">{{
                      item.identity_name
                    }}</van-tag>
                  </p>
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>联系方式：{{ item.mobile }}</p>
                </div>
              </div>
            </div>
            <div class="empty" v-if="myinviterList.length <= 0">
              <van-empty description="暂无数据" />
            </div>
          </van-tab>
          <van-tab title="模具配件">
            <div
              class="myinviteritem"
              v-for="(item, index) in myinviterList"
              :key="index"
            >
              <div class="myinviteritemleft">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="myinviteritemright">
                <div class="myinviteritemrightname">
                  <p>
                    <span>{{ item.nickname }}</span>
                  </p>
                  <!-- <van-icon name="setting-o" class="set-icon" /> -->
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>
                    用户身份：<van-tag type="primary">{{
                      item.identity_name
                    }}</van-tag>
                  </p>
                </div>
                <div class="myinviteritemrightxfzj">
                  <p>联系方式：{{ item.mobile }}</p>
                </div>
              </div>
            </div>
            <div class="empty" v-if="myinviterList.length <= 0">
              <van-empty description="暂无数据" />
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <van-overlay :show="typeShow" @click="typeShow = false">
      <div class="payType-mask" @click.stop>
        <div class="title">请选择分类</div>
        <van-radio-group v-model="radio" class="radio-box">
          <van-radio name="1">终端用户</van-radio>
          <van-radio name="2">制冷商家</van-radio>
          <van-radio name="3">维修师傅</van-radio>
          <van-radio name="4">模具配件</van-radio>
        </van-radio-group>
        <button class="pay-btn">保存</button>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Navbar from "../components/Navbar";
import base from "../api/base";
import { indexOp } from "../api/index";
import { loginAlert } from "../utils/loginAlert";
export default {
  data() {
    return {
      active: 0,
      myinviterList: [],
      userInfo: "",
      addCount: "",
      myinviterTotal: "",

      typeShow: false,
      radio: "1",
    };
  },
  components: {
    Navbar,
  },
  methods: {
    onChange(value) {
      var _this = this;
      // _this.active = value
      console.log(value);
      _this.getMyInviterList(value);
    },
    toBack() {
      this.$router.go(-1);
    },

    getMyInviterList(value) {
      var _this = this;
      var data = {};
      if (value != 0) {
        data.user_type = value - 1;
      }
      _this.userinfo = _this.$cookies.get("userInfo");
      indexOp("/user/my_invate", data).then((res) => {
        if (res && res.status == 200) {
          console.log(res.data);
          _this.addCount = res.data.add_count;
          _this.myinviterTotal = res.data.data.total;
          _this.myinviterList = res.data.data.data;
        }
      });
    },
  },
  created() {
    var _this = this;
    var token = _this.$cookies.get("Authorization");

    if (!token) {
      loginAlert()
      return;
    } else {
      _this.getMyInviterList();
    }
  },
};
</script>
<style lang="less" scoped>
.myinviter {
  .myinvitertop {
    width: 100%;
    height: 160px;
    background: #2f78dc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .myinvitertopleft {
      width: 160px;
      height: 84px;
      background: #fff;
      margin-left: 15px;
      border-radius: 10px;
      h3 {
        font-size: 15px;
        margin-left: 20px;
      }
      p {
        font-size: 14px;
        color: #666;
        margin-left: 20px;
      }
    }
    .myinvitertopright {
      width: 160px;
      height: 84px;
      background: #fff;
      margin-right: 15px;
      border-radius: 10px;
      h3 {
        font-size: 15px;
        margin-left: 20px;
      }
      p {
        font-size: 14px;
        color: #666;
        margin-left: 20px;
      }
    }
  }
  .myinviterlist {
    .myinviterlisttitle {
      padding: 10px 15px 0;
      font-size: 16px;
      background: #fff;
    }
    .myinviteritem {
      width: 92%;
      height: 120px;
      background: #fff;
      border-radius: 10px;
      margin: 10px auto;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .myinviteritemleft {
        width: 75px;
        height: 75px;
        margin-right: 20px;
        img {
          width: 75px;
          height: 75px;
          border-radius: 50%;
        }
      }
      .myinviteritemright {
        width: 70%;
        font-size: 14px;
        line-height: 26px;
        .myinviteritemrightname {
          font-weight: bold;
          position: relative;
          p {
            display: flex;
            width: 100%;
            span {
              width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .set-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
          }
        }
        .myinviteritemrightxfzj {
          color: #666;
        }
        .myinviteritemrighttime {
          color: #666;
        }
      }
    }
  }
}
.radio-box {
  padding: 0 10px;
  .van-radio {
    padding: 5px 0;
  }
}
</style>