<template>
	<div class="order-release">
		<Navbar :title="'完善公司信息'"></Navbar>
		<h2 class="inpit-title">公司名称</h2>
		<van-field v-model="formData.name" autosize type="text" placeholder="请输入公司名称" @blur="onblur" />
		<h2 class="inpit-title">设置身份</h2>
		<van-field v-model="typeValue" type="text" placeholder="请设置身份" readonly clickable @click="showType = true" />
		<van-popup v-model="showType" round position="bottom">
			<van-picker show-toolbar :columns="typeColume" @cancel="showPicker_2 = false" @confirm="onPickerConfirm" />
		</van-popup>
		<h2 class="inpit-title">联系人</h2>
		<van-field v-model="formData.person" autosize type="text" placeholder="请输入联系人" @blur="onblur" />
		<h2 class="inpit-title">联系电话</h2>
		<van-field v-model="formData.phone" autosize type="text" placeholder="请输入联系电话" @blur="onblur" />
		<h2 class="inpit-title">公司地址</h2>
		<van-cell-group class="input-group">
			<van-field v-model="areaText[0]" placeholder="省" readonly clickable @click="showCityPicker = true" />
			<van-field v-model="areaText[1]" placeholder="市" readonly clickable @click="showCityPicker = true" />
			<van-field v-model="areaText[2]" placeholder="区" readonly clickable @click="showCityPicker = true" />
		</van-cell-group>
		<template v-if="areaList && areaList.length">
			<van-popup v-model="showCityPicker" round position="bottom">
				<van-cascader v-model="cascaderValue" title="请选择所在地区" :options="areaList"
					@close="showCityPicker = false" @finish="onFinish"
					:field-names="{ text: 'label', value: 'value', children: 'child' }" />
			</van-popup>
		</template> 
		<!-- <van-popup v-model="showCityPicker" round position="bottom">
			<van-area title="选择地址" :area-list="areaList" @cancel="showCityPicker = false" @confirm="onCityConfirm" />
		</van-popup> -->
		<div class="address-box">
			<van-field v-model="areaText[3]" autosize type="textarea" placeholder="请输入详细地址" @blur="onblur" />
			<div class="dingwei" @click="onShowMap">
				<van-icon name="location" />点击选择
			</div>
		</div>
		<h2 class="inpit-title">公司简介</h2>
		<van-field v-model="formData.desc" autosize type="textarea" placeholder="请输入公司简介" @blur="onblur" />
		<!-- <h2 class="inpit-title">技术介绍</h2>
    <van-field
      v-model="formData.technology"
      autosize
      type="textarea"
      placeholder="请输入技术介绍"
      @blur="onblur"
    /> -->
		<h2 class="inpit-title">店铺图片</h2>
		<van-uploader v-model="thumbList" multiple :max-count="1" :after-read="thumbAfterRead"
			:before-delete="thumbDetele" />
		<h2 class="inpit-title">荣誉证书</h2>
		<van-uploader v-model="horList" multiple :max-count="3" :after-read="horAfterRead" :before-delete="horDetele" />
		<!-- <h2 class="inpit-title">维修范围</h2>
    <van-field
      v-model="formData.service_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺维修范围"
    />
    <h2 class="inpit-title">服务区域</h2>
    <van-field
      v-model="formData.scope_desc"
      autosize
      type="textarea"
      placeholder="请输入店铺服务区域"
    /> -->

		<van-button class="btn" type="info" @click="onSubmit"><i class="iconfont iconjiahao"></i> 确认提交</van-button>
		<!-- <Tabber></Tabber> -->
		<map-choice v-if="mapShow" @config-map="onConfigMap" @close-map="onCloseMap"></map-choice>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	import province_list from "../utils/area";
	import MapChoice from "./map_choice";
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp
	} from "../api";
	import base from "../api/base.js";
	import {
		login
	} from "../utils/login_toast";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	export default {
		components: {
			Tabber,
			Navbar,
			MapChoice,
		},
		props: {},
		data() {
			return {
				message: "",
				thumbList: [], //店铺图片
				firstCardList: [], //身份证正面
				lostCardList: [], //身份证反面
				horList: [], //荣誉证书
				busList: [], //营业执照
				radio: "",
				url: base.apiURL,
				showTimePicker: false, //时间选择器
				showCityPicker: false, //地址选择器
				minDate: new Date(2000, 0, 1),
				maxDate: new Date(2050, 10, 1),
				currentDate: new Date(),
				areaList: [],
				areaText:[],

				dateValue: "",
				province: "", //省
				city: "", //市
				area: "", //区
				formData: {
					name: "",
					thumb: "",
					hor_img: [],
				},
				mapShow: false, //地图组件显示

				depositList: [],
				showType: false,
				typeColume: [{
						value: "factory",
						text: "配件厂家",
					},
					{
						value: "shop",
						text: "配件商家",
					},
				],
				typeValue: "",
				userInfo: {
					id: "",
				},
				invate_uid: "",
				loginUrl: base.loginURL,
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			this.$cookies.set("invate_uid", invate_uid);
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
				this.getShifuDetail();
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && !code) {
				window.location.href = this.loginUrl;
			} else if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			} else {
				this.getShifuDetail();
			}
			this.setShare();
			// 获取地区
			this.getServiceRegion();
		},
		mounted() {},
		watch: {},
		methods: {
			//获取地区列表
			getServiceRegion() {
				var _this = this;
				indexOp("/area/all_format_child").then(res => {
					_this.areaList = res.data;
				})
			},
			onFinish({
				selectedOptions
			}) {
				this.showCityPicker = false;
				this.areaText = selectedOptions.map((option) => option.label);
				this.areaText.push(this.areaText[0] + this.areaText[1] + this.areaText[2]);
			
				// this.formData.province = this.fieldValue[0];
				// this.formData.city = this.fieldValue[1];
				// this.formData.area = this.fieldValue[2];
				// this.formData.address = this.fieldValue[0] + this.fieldValue[1] + this.fieldValue[2]
			
				// 2021.1.16 增加地区存储
				this.areaValue = selectedOptions.map((option) => option.value);
				this.formData.province = this.areaValue[0];
				this.formData.city = this.areaValue[1];
				this.formData.area = this.areaValue[2];
				this.formData.address = this.areaText[0] + this.areaText[1] + this.areaText[2];
			
				this.onblur();
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
				this.getShifuDetail();
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-配件厂商入驻`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/editPeijianinfo?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			onPickerConfirm(value) {
				var _this = this;
				console.log(value);
				_this.typeValue = value.text;
				_this.formData.type = value.value;
				_this.showType = false;
				_this.onblur();
			},
			onblur() {
				var _this = this;
				addOp("/fitting_user/create", _this.formData).then((res) => {
					if (res && res.data) {}
				});
			},
			onSubmit() {
				var _this = this;
				console.log(_this.formData);
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				addOp("/fitting_user/create", _this.formData).then((res) => {
					_this.$toast.clear();
					if (res && res.data) {
						_this.$toast.success("提交成功！");
						setTimeout(() => {
							_this.$router.push("/user");
						}, 500);
						//   _this.$router.push({
						//     path:"/RepairDetail",
						//     query:{
						//       order_no:res.data.order_no
						//     }
						//   })
					}
					console.log(res);
				});
			},
			getShifuDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/fitting_user/show/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.data) {
						_this.formData.name = res.data.name;
						_this.formData.person = res.data.person;
						_this.formData.phone = res.data.phone;
						_this.formData.province = res.data.province;
						_this.formData.city = res.data.city;
						_this.formData.area = res.data.area;
						_this.province = res.data.province;
						_this.city = res.data.city;
						_this.area = res.data.area;
						_this.formData.address = res.data.address;
						_this.formData.desc = res.data.desc;
						_this.formData.thumb = res.data.thumb;
						_this.formData.web_url = res.data.web_url;
						if (res.data.type == "factory") {
							_this.typeValue = "配件厂家"
						} else if (res.data.type == "shop") {
							_this.typeValue = "配件商家"
						}
						if (res.data.thumb) {
							var data = {
								url: _this.url + res.data.thumb,
							};
							_this.thumbList.push(data);
						}
						if (res.data.hor_img) {
							_this.formData.hor_img = res.data.hor_img;
							for (var key in res.data.hor_img) {
								var data = {
									url: res.data.hor_img[key],
								};
								_this.horList.push(data);
							}
						}
						
						// 处理地区数据
						if(res.data.province_info){
							_this.areaText[0] 	=	res.data.province_info.area_name;
						}
						if(res.data.city_info){
							_this.areaText[1] 	=	res.data.city_info.area_name;
						}
						if(res.data.area_info){
							_this.areaText[2] 	=	res.data.area_info.area_name;
						}
						if(res.data.address){
							_this.areaText[3] 	=	res.data.address;
						}
						
					}
				});
			},
			//单选
			onRadioChange(name) {
				this.formData.deposit_money = this.depositList[name].money;
			},
			//地图选择
			onConfigMap(data) {
				this.mapShow = false;
				if (data) {
					this.formData.address = data.poiaddress + data.poiname;
					// this.formData.lat = data.latlng.lat;
					// this.formData.lng = data.latlng.lng;
				}
				console.log(data);
			},
			//关闭地图选择
			onCloseMap() {
				this.mapShow = false;
			},
			//时间选择确认
			onTimeConfirm(value) {
				this.showTimePicker = false;
				var y = value.getFullYear();
				var m = value.getMonth() + 1;
				var d = value.getDate();
				var h = value.getHours();
				var mm = value.getMinutes();
				var s = value.getSeconds();
				this.formData.accept_door_time =
					y + "-" + m + "-" + d + " " + h + ":" + mm;
			},
			//地区选择确认
			onCityConfirm(value, index) {
				this.showCityPicker = false;
				this.formData.province = value[0].name;
				this.formData.city = value[1].name;
				this.formData.area = value[2].name;
				this.formData.address = value[0].name + value[1].name + value[2].name;
			},
			onShowMap() {
				this.mapShow = true;
			},
			//图片上传  店铺图片
			thumbAfterRead(file) {
				var _this = this;
				console.log(file);
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.thumb = res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  店铺图片
			thumbDetele(file) {
				var _this = this;
				_this.thumbList = [];
				_this.formData.thumb = "";
				console.log(file);
			},
			//图片上传  身份证正面
			firstCardAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.card_img = _this.url + res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  身份证正面
			firstCardDetele(file) {
				var _this = this;
				_this.firstCardList = [];
				_this.formData.card_img = "";
				console.log(file);
			},
			//图片上传  身份证反面
			lostCardfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.side_img = _this.url + res.data.url;
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  身份证反面
			lostCardDetele(file) {
				var _this = this;
				_this.lostCardList = [];
				_this.formData.side_img = "";
				console.log(file);
			},
			//图片上传 荣誉证书
			horAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						var arr = [];
						arr.push(res.data.url);
						_this.formData.hor_img.push(_this.url + res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  荣誉证书
			horDetele(file, detail) {
				var _this = this;
				_this.horList.splice(detail.index, 1);
				_this.formData.hor_img.splice(detail.index, 1);
			},
			//图片上传 营业执照
			busAfterRead(file) {
				var _this = this;
				var form_data = new FormData();
				form_data.append("file", file.file);
				file.status = "uploading";
				file.message = "上传中...";
				uploadImg(form_data).then((res) => {
					if (res && res.status == 200) {
						file.status = "success";
						file.message = "";
						_this.formData.bus_img.push(_this.url + res.data.url);
					} else {
						file.status = "failed";
						file.message = "上传失败";
					}
				});
			},
			//图片删除  营业执照
			busDetele(file, detail) {
				var _this = this;
				_this.busList.splice(detail.index, 1);
				_this.formData.bus_img.splice(detail.index, 1);
			},
		},
	};
</script>

<style scoped>
</style>
