import http from '@/utils/request'
import VueCookies from 'vue-cookies'
import base from "../api/base"
import * as socketApi from './webSocket'
import {
	Toast,
	Dialog
} from 'vant';
import router from '../router';


// 提示用户登录弹框
export function loginAlert(msg, flag = 0, confirmText = '微信登录') {
	let loginURL = base.loginURL
	let Msg = ''
	if (!msg) {
		Msg = '您还未登录，是否先去登录？'
	} else {
		Msg = msg
	}
	// console.log(VueCookies.isKey("showLoginAlert"))
	// if(!VueCookies.isKey("showLoginAlert")){
	Dialog
		.confirm({
			title: "提示",
			message: Msg,
			confirmButtonText: confirmText,
			cancelButtonText: "取消",
			showCancelButton: !flag
		})
		.then(() => {
			const path = window.location.pathname.slice(1)
			var search = window.location.search; //获取url中"?"符后的字串
			var url = ''
			if (search.indexOf("?") != -1) { //判断是否有参数
				url = encodeURIComponent(window.location.href + '&back=' + path + search);
			} else {
				url = encodeURIComponent(window.location.href + '?back=' + path + search);
			}
			// console.log(window.location.href + '&back=' + path + search)
			// return false;
			window.location.href = loginURL + "?url=" + url;

			// window.location.href = loginURL;
			// VueCookies.remove("Authorization");
			// VueCookies.remove("userInfo");
			// VueCookies.remove("identity");
			// router.push("/login");
		})
		.catch(() => {
			// router.push({
			//   path:"/login",
			//   query:{
			//     isPhone:true
			//   }
			// });
			// VueCookies.remove("Authorization");
			// VueCookies.remove("userInfo");
			// VueCookies.remove("identity");
			// VueCookies.set("showLoginAlert",'false',"2d")
		});
	// }
}