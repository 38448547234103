<template>
  <div>
    <van-button type="primary" @click="chooseImage">主要按钮</van-button>
    <van-uploader multiple :max-count="2" :after-read="afterRead">
      <van-button icon="plus" type="primary">上传文件</van-button>
    </van-uploader>
    <img v-for="(item,index) in images" :key="index" :src="item" alt="">
    <input type="file" multiple="multiple">
  </div>
</template>

<script>
	import base from "../api/base";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
export default {
  props: {},
  data() {
    return {
      fileList: [],
      images:[]
    };
  },
  computed: {},
  created() {
    this.setShare()
  },
  mounted() {},
  watch: {},
  methods: {
    afterRead(file){
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网`,
        desc: `聚制冷精英，集行业资源，方便您我他！`,
        link: base.shareURL,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    chooseImage(){
      var _this = this;

      wx.chooseImage({
        count: 9,
        sizeType: ['original', 'compressed'],
        sourceType: ['album', 'camera'],
        success (res) {
          // tempFilePath可以作为img标签的src属性显示图片
          const tempFilePaths = res.localIds
          console.log(tempFilePaths)
          _this.images = tempFilePaths
          tempFilePaths.forEach(element => {
            _this.uploadImage(element)
          });
        },
      })
    },
    uploadImage(url){
      wx.uploadImage({
        localId: url, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log(serverId)
          var data = {
            media_id : serverId
          }
          indexOp('/download_file/wechat_media',data).then(res=>{
            console.log(res)
          })
          // _this.downloadImage(serverId)
        }
      });
    },
    downloadImage(serverId){
      const _this = this;
      wx.downloadImage({
        serverId: serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var localId = res.localId; // 返回图片下载后的本地ID
          console.log(localId)
          // wx.getLocalImgData({
          //   localId: localId, // 图片的localID
          //   success: function (res) {
          //     var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
          //     console.log(localData)
          //     _this.images.push(localData)
          //   }
          // });
        }
      });
    }
  },
  components: {},
};
</script>

<style scoped >
</style>
