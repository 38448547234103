<template>
	<div class="sponsor-index joiner-index">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">{{ info.company_name }}</span>
			<van-icon name="edit" @click="onEdit" />
		</div>
		<div class="organiser">
			<div class="bg">
				<div class="info">
					<div class="left">
						<img class="avatar" v-if="info.company_logo" :src="baseUrl + info.company_logo" alt="" />
					</div>
					<div class="right">
						<div class="title">参展商名称：{{ info.company_name }}</div>
						<div class="address">参展商地址：{{ info.company_address }}</div>
						<div class="address">
							<span>联系方式：{{ info.company_phone }}</span>
							<!-- <div class="btn" @click="toChat(info.uid)">
								<i class="iconfont iconduihua"></i>
								<span>发起聊天</span>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="desc-box">
			<div class="title-box">
				<span>企业简介</span>
			</div>
			<div class="text">
				{{ info.company_desc }}
			</div>
		</div>
		<div class="tab-box">
			<div :class="tabActive == 1 ? 'item active' : 'item'" @click="changeTab(1)">
				参加的展会活动
			</div>
			<div :class="tabActive == 2 ? 'item active' : 'item'" @click="changeTab(2)">
				展商产品
			</div>
		</div>
		<div class="expo-list" v-if="tabActive == 1">
			<van-list v-model="expoloading" :finished="expofinished" :finished-text="expoList.length > 0 ? '没有更多了' : ''"
				@load="getExpo">
				<div class="item" v-for="(item, index) in expoList" :key="index" @click="toExpoDetail(item)">
					<div class="banner-box">
						<img class="banner-img" v-if="item.images" :src="baseUrl + item.images[0]" alt="" />
						<div class="banner-title">
							{{ item.title }}
						</div>
						<div class="status active">{{ item.format_time_text }}</div>
					</div>
					<div class="content-box">
						<div class="title" v-if="item.expo_sponsor_user">
							主办方：{{ item.expo_sponsor_user.company_name }}
						</div>
						<div class="address">
							<i class="iconfont iconweizhi"></i>会展地点：{{ item.address }}
						</div>
						<div class="time">
							<div class="left">
								<van-icon name="clock-o" />
								展会时间：{{ item.start_time }} 至 {{ item.end_time }}
							</div>
							<!-- <div class="right"><van-icon name="friends-o" />20家报名</div> -->
						</div>
					</div>
				</div>
				<van-empty image-size="100" v-if="expoList.length == 0" description="暂无产品数据" />
			</van-list>
		</div>
		<div class="tabs-box" v-if="tabActive == 2">
			<div class="card-box">
				<div class="title">
					<span>产品展示</span>
					<span style="color: #007aff" @click="$router.push('/joinerProductAdd')">添加产品</span>
				</div>
				<GoodsList v-if="productList" thumbField="images" :showPreviewIcon="false" :showPrice="false"
					:left="goodsListLeft()" :right="goodsListRight()" @click="toDetail"></GoodsList>
				<van-empty image-size="100" v-if="productList.length == 0" description="暂无产品数据" />
				<!-- <van-list v-model="loading" :finished="finished" :finished-text="productList.length > 0 ? '没有更多了' : ''"
					@load="getProduct">
					<div class="product-list">
						<div class="item" v-for="(item, index) in productList" :key="index" @click="toDetail(item.id)">
							<img :src="baseUrl + item.images[0]" alt="" />
							<span>{{ item.goods_name }}</span>
						</div>
					</div>
					<van-empty image-size="100" v-if="productList.length == 0" description="暂无产品数据" />
				</van-list> -->
			</div>
		</div>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	import GoodsList from "../../components/GoodsList";
	export default {
		components: {
			Tabber,
			Navbar,
			GoodsList
		},
		props: {},
		data() {
			return {
				baseUrl: base.apiURL,
				loginURL: base.loginURL,
				tabActive: "1",
				userInfo: {
					id: "",
				},
				invate_uid: "",
				info: {},
				productList: [],
				expoList: [],

				finished: false,
				loading: false,

				productPage: "",
				expoPage: "",

				expofinished: false,
				expoloading: false,
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			if (this.userInfo.id == "") {
				loginAlert();
			}
			this.getInfo();
			this.wxLogin();
			this.getProduct()
			// this.getExpo()
		},
		mounted() {},
		watch: {},
		methods: {
			goodsListLeft(){
				var goodslist 	=	[];
				if(!this.productList || this.productList.length < 1){
					return [];
				}
				for(var i in this.productList){
					if( i % 2 == 0 ){
						goodslist.push(this.productList[i]);
					}
				}
				return goodslist;
			},
			goodsListRight(){
				var goodslist 	=	[];
				if(!this.productList || this.productList.length < 1){
					return [];
				}
				for(var i in this.productList){
					if( i % 2 != 0 ){
						goodslist.push(this.productList[i]);
					}
				}
				return goodslist;
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-${this.info.company_name}`,
					desc: `媒体合作，共助发展，一次参展，将享受365天的宣传服务！`,
					link: base.shareURL +
						"/joinerIndex?id=" +
						this.userInfo.id +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			wxLogin() {
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!this.$cookies.isKey("Authorization") && code) {
					login(this.getUser);
				}
			},
			getUser(userInfo) {
				this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			toChat(toId) {
				var _this = this;
				if (_this.$cookies.isKey("userInfo")) {
					var form_uid = _this.userInfo.id; //自己的 uid
					var to_uid = toId; //聊天对象 uid
					var data = {
						params: {
							to_uid: to_uid,
							from_uid: form_uid,
							chat_type: "normal",
						},
					};
					addOp("/im/build_chat", data).then((res) => {
						console.log(res);
						if (res && res.status != 500) {
							//跳转
							_this.$router.push({
								path: "/chat",
								query: {
									chat_id: res.data.chat_id,
									order_no: _this.order_no,
									to_uid: to_uid,
								},
							});
						} else {
							_this.$toast.fail("对话创建失败！");
						}
					});
				} else {
					loginAlert()
				}
			},
			onEdit() {
				this.$router.push("/joinerUserEdit");
			},
			toBack() {
				this.$router.go(-1);
			},
			changeTab(type) {
				this.tabActive = type;
			},
			getInfo() {
				var _this = this;

				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/expo_joiner/show", data).then((res) => {
					if (res.data.state == 1000) {
						_this.info = res.data.data;
						_this.setShare();
					} else {
						_this.$toast.fail(res.data.msg);
					}
				});
			},
			getExpo() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
					page: _this.expoPage + 1,
				};
				indexOp("/expo/index", data).then((res) => {
					if (res && res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.expoPage = res.data.data.current_page;
							_this.expoList = res.data.data.data;
						} else {
							_this.expofinished = true;
						}
						_this.expoloading = false;
					}
					console.log(res);
				});
			},
			getProduct() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
					page: _this.productPage + 1,
				};
				indexOp("/expo_joiner/goods_list", data).then((res) => {
					console.log(res);
					if (res.data.state == 1000) {
						if (res.data.data.data.length > 0) {
							_this.productList = res.data.data.data;
							_this.productPage = res.data.data.current_page;
						} else {
							_this.finished = true;
						}
						_this.loading = false;
					} else {
						_this.$toast.fail(res.data.msg);
					}
				});
			},
			toDetail(item) {
				this.$router.push({
					path: "/joinerProductDetail",
					query: {
						id: item.id,
					},
				});
			},
			toExpoDetail(item) {
				this.$router.push({
					path: "/expoDetail",
					query: {
						id: item.id,
					},
				});
			},
		},
	};
</script>

<style scoped>
</style>