<template>
  <div class="class-list peijian">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">分类管理</span>
      <span class="right" style="font-size: 12px" @click="toAdd">
        添加分类
      </span>
    </div>
    <div class="title">分类管理</div>
    <div class="class-list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="tit">
          <span class="text">{{ item.name }}</span>
          <span class="btn" @click="onDelete(item.id)"
            ><van-icon name="delete-o"
          /></span>
        </div>
        <div class="child">
          <div
            class="tit"
            v-for="(child, index) in item._child"
            :key="'c' + index"
          >
            <span class="text" v-if="index + 1 == item._child.length"
              >└─ {{ child.name }}</span
            >
            <span class="text" v-else>├─ {{ child.name }}</span>
            <span class="btn" @click="onDelete(item.id)"
              ><van-icon name="delete-o"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
export default {
  inject: ["reload"],
  components: {
    Navbar,
    Tabber,
  },
  props: {},
  data() {
    return {
      cid: "", //厂商id
      list: "",
    };
  },
  computed: {},
  created() {
    this.cid = this.$route.query.id;
    this.getClassList();
  },
  mounted() {},
  watch: {},
  methods: {
    //获取分类列表
    getClassList() {
      var _this = this;
      var data = {
        id: _this.cid,
      };
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      indexOp("/goods_type/index",data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.list = res.data;
        }
      });
    },
    //删除分类
    onDelete(id) {
      var _this = this;
      var api = "/goods_type/destroy/" + id;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除该分类？",
        })
        .then(() => {
          addOp(api).then((res) => {
            if (res && res.status == 200) {
              _this.$toast.success("操作成功！");
              setTimeout(() => {
                _this.reload()
              }, 800);
            }else{
                _this.$toast.fail(res.data.error)
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toAdd() {
      var _this = this;
      this.$router.push({
        path: "/peijian_classAdd",
        query:{
            id:_this.cid
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped >
</style>
