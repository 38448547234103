<template>
	<div class="upload-video">
		<Navbar :title="'添加分类'"></Navbar>
		<!-- <div class="title">一级分类</div> -->
		<!-- <div class="input-item">
			<van-field v-model="classValue" autosize readonly clickable type="text" placeholder="请选择所在父级分类"
				@click="showPicker = true" />

			<van-popup v-model="showPicker" round position="bottom">
				<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm"
					value-key="name" />
			</van-popup>
		</div> -->
		<div class="title">分类名称</div>
		<div class="input-item">
			<van-field class="customInput" v-model="formData.name" autosize type="text" placeholder="请输入分类名称" />
		</div>
		<!-- <p style="padding:5px 15px;font-size:12px;">商品上传仅在二级分类下上传，请添加二级分类；</p> -->
		<div class="btn-box" style="margin-top:20px;">
			<button class="btn" @click="onSubmit">提交</button>
		</div>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";

	export default {
		components: {
			Navbar,
			Tabber,
		},
		props: {},
		data() {
			return {
				fileList: [],
				formData: {},
				userInfo: "",
				htmlValue: "",
				showPicker: false,
				columns: [{
					name: "一级分类",
					id: "0",
				}, ],
				cid: "", //厂商id
				pid: "",
				classValue: "",
			};
		},
		computed: {},
		created() {
			this.userInfo = this.$cookies.get("userInfo");
			var _this = this;
			// this.userInfo = this.$cookies.get("userInfo");
			var code = _this.getUrlKey("code");
			var state = _this.getUrlKey("state");
			var token = _this.$cookies.get("Authorization");
			_this.cid = _this.$route.query.id;
			if (!token && !code) {
				loginAlert()
				return;
			} else if (!token && code) {
				login(this.getLoginUser);
				return;
			}
			_this.getGoodsType();
		},
		mounted() {},
		watch: {},
		methods: {
			getGoodsType() {
				var _this = this;
				var data = {
					id: _this.cid,
				};
				indexOp("/goods_type/index", data).then((res) => {
					if (res && res.status == 200) {
						res.data.map((item) => {
							_this.columns.push(item);
						});
					}
					console.log(res);
				});
			},
			onConfirm(value) {
				this.classValue = value.name;
				this.pid = value.id;
				this.showPicker = false;
			},
			onSubmit() {
				var _this = this;
				if (_this.pid == "") {
					_this.$toast("请选择所在一级分类");
					return;
				}
				if (_this.formData.name == undefined) {
					_this.$toast("二级分类名称不能为空！");
					return;
				}
				_this.formData.pid = _this.pid;
				addOp("/goods_type/store", _this.formData).then((res) => {
					if (res && res.status == 201) {
						_this.$toast.success("操作成功！");
						setTimeout(() => {
							_this.$router.go(-1);
						}, 800);
					}
					console.log(res);
				});
				console.log(_this.formData);
			},
			getLoginUser() {
				var _this = this;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
		},
	};
</script>

<style scoped>
</style>