<template>
    <div class="back-home" @click="toHome">
        <van-icon name="wap-home" size="20" color="#2F78DC" />
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        toHome(){
            this.$router.push("/")
        }
    },
    components: {

    },
};
</script>

<style scoped >

</style>
