<template>
  <!-- <div
    :class="
      userInfo.user_vip_info &&
      userInfo.user_vip_info.vip_level > 0 &&
      Date.parse(new Date()) / 1000 <
        Date.parse(new Date(userInfo.user_vip_info.end_time)) / 1000
        ? ' user vipUser'
        : 'user'
    "
  > -->
  <div class="user vipUser">
    <div class="top-box">
      <div class="top-navbar">
        <van-icon name="arrow-left" @click="toBack" />
        <span class="text">我的</span>
        <!-- <span style="font-size: 12px" @click="toEditUset">设置</span> -->
        <!-- <van-icon name="setting"  /> -->
      </div>
      <div class="user-info" v-if="userInfo">
        <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
        <img v-else src="@/assets/image/user.png" alt="" />
        <div class="center" style="padding-bottom: 0">
          <p class="name" v-if="userInfo.nickname">{{ userInfo.nickname }}</p>
          <p class="name" v-else-if="userInfo.truename">
            {{ userInfo.truename }}
          </p>
          <p @click="toEditUset" class="name" v-else>完善个人信息</p>
          <!-- <p class="uid" style="color: #fff; font-size: 12px" v-if="userInfo">
            用户ID：{{ userInfo.id }}
          </p> -->
          <p
            class="uid"
            style="color: #dfac7a; font-size: 10px"
            v-if="userInfo.identity == 2"
          >
            维修师傅
          </p>
          <p
            class="uid"
            style="color: #dfac7a; font-size: 10px"
            v-if="userInfo.identity == 1"
          >
            制冷厂商
          </p>
          <p
            class="uid"
            style="color: #dfac7a; font-size: 10px"
            v-if="userInfo.identity == 0"
          >
            终端用户
          </p>
          <p
            class="uid"
            style="color: #dfac7a; font-size: 10px"
            v-if="userInfo.identity == 3"
          >
            配件模具厂商
          </p>
          <p
            class="uid"
            style="color: #dfac7a; font-size: 10px"
            v-if="userInfo.identity == 9"
          >
            客服
          </p>
          <!-- <div
            @click="show = true"
            class="vip-box"
            v-if="
              userInfo.user_vip_info &&
              userInfo.user_vip_info.vip_level != 0 &&
              Date.parse(new Date()) / 1000 <
                Date.parse(new Date(userInfo.user_vip_info.end_time)) / 1000
            "
          >
            <div class="left" style="padding: 0; margin: 0; border: none">
              <p class="tit" v-if="userInfo.user_vip_info.vip_level == 1">
                普通会员
              </p>
              <p class="tit" v-else-if="userInfo.user_vip_info.vip_level == 2">
                黄金会员
              </p>
              <p class="tit" v-else-if="userInfo.user_vip_info.vip_level == 3">
                钻石会员
              </p>
            </div>
          </div>
          <router-link to="/vip" class="vip-box active" v-else>
            <div class="left">
              <p class="tit">体验会员</p>
            </div>
            <div class="right">
              <p>立即升级</p>
            </div>
          </router-link> -->
          <!-- <p class="shenfen" v-if="userInfo.identity == 2">维修师傅</p>
          <p class="shenfen" v-else-if="userInfo.identity == 1">制冷厂商</p>
          <p class="shenfen" v-else-if="userInfo.identity == 0">终端用户</p>
          <p class="shenfen" v-else-if="userInfo.identity == 3">配件模具厂商</p>
          <p class="shenfen" v-else-if="userInfo.identity == 9">客服</p> -->
          <p
            class="shenfen"
            style="top: 50%; transform: translateY(-50%)"
            @click="toEditUset"
          >
            编辑
          </p>
        </div>
      </div>
      <div class="user-info" v-else>
        <img src="@/assets/image/user.png" alt="" />
        <div class="center">
          <router-link to="/login" class="name">点击登录</router-link>
        </div>
      </div>
    </div>
    <!-- <div
      class="vip-time"
      v-if="
        userInfo.user_vip_info &&
        userInfo.user_vip_info.vip_level > 0 &&
        Date.parse(new Date()) / 1000 <
          Date.parse(new Date(userInfo.user_vip_info.end_time)) / 1000
      "
    >
      <span>会员到期时间：{{ userInfo.user_vip_info.end_time }}</span>
      <span style="color: rgba(2, 0, 1, 0.69)" @click="$router.push('/vip')"
        >会员权益 >>></span
      >
    </div> -->
    <div class="bottom-box">
      <div class="zh-box">
        <div class="left">
          <i class="iconfont iconqianbaoqiandaiyue"></i>
        </div>
        <div class="right">
          <router-link to="/accountBalance" class="item">
            <p class="money" v-if="userInfo">{{ userInfo.user_account }}</p>
            <p class="money" v-else>0.00</p>
            <p class="tit">账户余额</p>
          </router-link>
          <router-link to="/freezeLog" class="item">
            <p class="money" v-if="userInfo">{{ userInfo.freeze_account }}</p>
            <p class="money" v-else>0.00</p>
            <p class="tit">待入账</p>
          </router-link>
          <div class="item">
            <p class="money" v-if="userInfo">{{ userInfo.user_score }}</p>
            <p class="money" v-else>0</p>
            <p class="tit">积分</p>
          </div>
        </div>
      </div>

      <template
        v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 1"
      >
        <div class="vip-card ordinary" @click="onShowVipCard">
          <span class="text"
            >普通会员到期时间：{{ userInfo.user_vip_info.end_time }}</span
          >
          <van-icon name="arrow" />
        </div>
      </template>
      <template
        v-else-if="
          userInfo.user_vip_info && userInfo.user_vip_info.vip_level == 2
        "
      >
        <div class="vip-card glod" @click="onShowVipCard">
          <span class="text"
            >黄金会员到期时间：{{ userInfo.user_vip_info.end_time }}</span
          >
          <van-icon name="arrow" />
        </div>
      </template>
      <template v-else>
        <div class="vip-card default" @click="$router.push('/vip')">
          <span class="text">当前是体验会员，立即升级会员享受专属权利</span>
          <van-icon name="arrow" />
        </div>
      </template>

      <template v-if="showVipCard">
        <template
          v-if="userInfo.user_vip_info && userInfo.user_vip_info.vip_level"
        >
          <!-- 判断会员等级 1：普通会员 2：黄金会员 -->
          <template v-if="userInfo.user_vip_info.vip_level == 1">
            <div
              class="vipinfo-box"
              v-if="userInfo.identity == 0"
              @click="$router.push('/vip')"
            >
              <p class="title text-color">普通会员 - 终端用户</p>
              <p class="subtit">
                1.发布信息找维修师傅<span class="text-color"
                  >（免收服务费）</span
                >
              </p>
              <p class="subtit">
                2.享受普通会员对应的<span class="text-color">专属产品价格</span>
              </p>
              <p class="subtit">
                3.支持<span class="text-color">查看全国各地的维修师傅电话</span>
              </p>
              <p class="time">
                会员到期时间：{{ userInfo.user_vip_info.end_time }}
              </p>
              <img class="vip-bg" src="../../assets/image/vip-bg.png" alt="" />
            </div>
            <div
              class="vipinfo-box"
              v-else-if="userInfo.identity == 2"
              @click="$router.push('/vip')"
            >
              <p class="title text-color">普通会员 - 维修师傅</p>
              <div :class="isShowInfo ? '' : 'info-box'">
                <p class="subtit">
                  1.享受<span class="text-color">优先承接</span>维修订单
                </p>
                <p class="subtit">
                  2.可加入<span class="text-color">全国(含本地)</span
                  >城市服务群成员，享受资源共享，技术交流及沟通指导，打赏提问
                </p>
                <p class="subtit">
                  3.享受普通会员对应的<span class="text-color"
                    >专属产品价格</span
                  >
                </p>
                <p class="subtit">4.享受预购产品福利</p>
                <p class="subtit">5.可申请城市合伙人</p>
                <p class="subtit">6.有权聚集自己的客户资源</p>
              </div>
              <p class="time">
                会员到期时间：{{ userInfo.user_vip_info.end_time }}
              </p>
              <img class="vip-bg" src="../../assets/image/vip-bg.png" alt="" />
              <div class="right-bottom-btn" @click.stop="showMore">
                <van-icon
                  name="question-o"
                  size="13px"
                  style="margin-right: 5px"
                />
                了解更多
              </div>
            </div>
          </template>
          <template v-else>
            <div class="vipinfo-box glod" @click="$router.push('/vip')">
              <p class="title text-color">
                黄金会员 -
                {{
                  userInfo.identity === 0
                    ? "终端用户"
                    : userInfo.identity === 2
                    ? "维修师傅"
                    : "厂商用户"
                }}
              </p>
              <div :class="isShowInfo ? '' : 'info-box'">
                <p class="subtit">
                  1.发布信息找维修师傅<span class="text-color"
                    >（每单服务费10元）</span
                  >
                </p>
                <p class="subtit" style="width: 70%">
                  2.系统支持确认接单的维修师傅，可自动同步至厂商家自己的售后维修服务网点
                </p>
                <p class="subtit">3.售后维修每单全程跟踪服务</p>
                <p class="subtit">4.单单都有维修聊天记录可查</p>
                <p class="subtit">5.配专属客服服务客户</p>
                <p class="subtit">6.支持查看全国各地的维修师傅电话</p>
                <p class="subtit">7.支持宣传自己的产品及企业</p>
                <p class="subtit">8.自动加入全国冷企大全商业库</p>
              </div>
              <p class="time">
                会员到期时间：{{ userInfo.user_vip_info.end_time }}
              </p>
              <img class="vip-bg" src="../../assets/image/vip-bg.png" alt="" />
              <div class="right-bottom-btn" @click.stop="showMore">
                <van-icon
                  name="question-o"
                  size="13px"
                  style="margin-right: 5px"
                />
                了解更多
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <!-- 体验会员 -->
          <div
            class="vipinfo-box default"
            v-if="userInfo.identity === 0"
            @click="$router.push('/vip')"
          >
            <p class="title text-color">体验会员 - 终端用户</p>
            <p class="subtit">
              1.发布信息找师傅维修（仅支持保外维修，收服务费每单20元）
            </p>
            <p class="subtit">2.享受体验会员对应的产品价格</p>
            <p class="subtit">3.支持查看接单维修师傅的电话</p>
            <div class="right-top-btn" @click="$router.push('/vip')">
              <img class="icon" src="../../assets/image/vip-icon.png" alt="" />
              <span>升级普通会员</span>
            </div>
          </div>
          <div
            class="vipinfo-box default"
            v-else-if="userInfo.identity === 1"
            @click="$router.push('/vip')"
          >
            <p class="title text-color">体验会员 - 厂商用户</p>
            <p class="subtit">
              1.发布信息找师傅维修（支持保内/外维修，收服务费每单20元，保内厂商付款，保外用户付款）
            </p>
            <p class="subtit">2.享受体验会员对应的服务及产品推广</p>
            <p class="subtit">3.不支持查看全国各地的维修师傅电话</p>
            <div class="right-top-btn" @click="$router.push('/vip')">
              <img class="icon" src="../../assets/image/vip-icon.png" alt="" />
              <span>升级普通会员</span>
            </div>
          </div>
          <div
            class="vipinfo-box default"
            v-else-if="userInfo.identity === 2"
            @click="$router.push('/vip')"
          >
            <p class="title text-color">体验会员 - 维修师傅</p>
            <p class="subtit">1.维修接单</p>
            <p class="subtit">
              2.可加入本地城市服务群成员，享受资源共享及技术交流沟通
            </p>
            <p class="subtit">3.享受体验会员对应的产品价格</p>
            <p class="subtit">4.享受预购产品福利</p>
            <div class="right-top-btn" @click="$router.push('/vip')">
              <img class="icon" src="../../assets/image/vip-icon.png" alt="" />
              <span>升级普通会员</span>
            </div>
          </div>
        </template>
      </template>
      <template v-if="userInfo.identity == 1 && shopDetail == ''">
        <div
          class="user-notice"
          v-if="
            !shopDetail.name ||
            !shopDetail.person ||
            !shopDetail.phone ||
            !shopDetail.address ||
            !shopDetail.desc
          "
        >
          <span class="text">
            <van-icon
              class="icon"
              name="warning-o"
            />您的公司信息不完整，请尽快完善。
          </span>
          <router-link to="/editShopinfo" class="btn">去完善</router-link>
        </div>
      </template>

      <template v-if="userInfo.identity == 3">
        <div
          class="user-notice"
          v-if="
            !peijianDetail.name ||
            !peijianDetail.person ||
            !peijianDetail.phone ||
            !peijianDetail.address ||
            !peijianDetail.desc
          "
        >
          <span class="text">
            <van-icon
              class="icon"
              name="warning-o"
            />您的公司信息不完整，请尽快完善。
          </span>
          <router-link to="/editPeijianinfo" class="btn">去完善</router-link>
        </div>
      </template>

      <!-- <div class="user-notice" v-if="!goodsShop">
        <span class="text"
          ><van-icon
            class="icon"
            name="warning-o"
          />想要售卖全新/二手商品？</span
        >
        <router-link to="/GoodsShopSettle?me=1" class="btn"
          >点击开通</router-link
        >
      </div>
      <div class="user-notice" v-else>
        <span class="text"
          ><van-icon class="icon" name="warning-o" />您已开通商品售卖通道</span
        >
        <router-link
          :to="{
            path: '/myShop',
            query: {
              uid: userInfo.id,
              tab: 4,
            },
          }"
          class="btn"
          >点击管理</router-link
        >
      </div> -->

      <div class="order-box">
        <div class="title-box">
          <span class="tit">我的订单</span>
          <router-link class="more" to="/userOrderList"
            >查看全部订单</router-link
          >
        </div>
        <div class="order-type-box">
          <router-link
            :to="{
              path: '/userOrderList',
              query: { tab: 1, status: 'order_bidding' },
            }"
            class="item"
          >
            <i class="iconfont iconbaoguoxiangzi"></i>
            <span class="text">待接单</span>
          </router-link>
          <router-link
            :to="{
              path: '/userOrderList',
              query: { tab: 2, status: 'sure_service_user' },
            }"
            class="item"
          >
            <i class="iconfont iconwarehouse"></i>
            <span class="text">待维修</span>
          </router-link>
          <router-link
            :to="{
              path: '/userOrderList',
              query: { tab: 7, status: 'user_sure_service' },
            }"
            class="item"
          >
            <i class="iconfont iconpingjia"></i>
            <span class="text">待评价</span>
          </router-link>
          <router-link
            :to="{
              path: '/userOrderList',
              query: { tab: 9, status: 'pay_money' },
            }"
            class="item"
          >
            <i class="iconfont icondingdanwancheng"></i>
            <span class="text">已完成</span>
          </router-link>
        </div>
      </div>

      <router-link
        :to="{ path: '/shop/myShopIndex', query: { uid: userInfo.id } }"
        class="card-item"
      >
        <van-icon class="icon" name="list-switch" />
        <span class="title">我的商品</span>
      </router-link>

      <div @click="toMyExpo" class="card-item" v-if="userInfo.identity == 0">
        <van-icon class="icon" name="shop-o" />
        <span class="title">展会主办方</span>
      </div>
      <div @click="toMyJoiner" class="card-item" v-if="userInfo.identity == 0">
        <van-icon class="icon" name="shop-o" />
        <span class="title">展会参展商</span>
      </div>

      <router-link to="/chatList" class="card-item">
        <van-icon class="icon" name="chat-o" />
        <span class="title">我的聊天</span>
      </router-link>

      <!-- <router-link to="/tixian" class="card-item">
        <van-icon class="icon" name="after-sale" />
        <span class="title">账户提现</span>
      </router-link>
      <router-link to="/bill" class="card-item">
        <van-icon class="icon" name="bill-o" />
        <span class="title">账单日志</span>
      </router-link> -->

      <router-link
        :to="{ path: '/changshangIndex', query: { uid: userInfo.id } }"
        class="card-item"
        v-if="shopDetail && userInfo.identity == 1"
      >
        <van-icon class="icon" name="shop-o" />
        <span class="title">我的店铺</span>
      </router-link>

      <router-link
        :to="{ path: '/userManage' }"
        class="card-item"
        v-if="shopDetail && userInfo.identity == 1"
      >
        <van-icon class="icon" name="contact" />
        <span class="title">用户管理</span>
      </router-link>

      <router-link
        :to="{ path: '/userIndex', query: { uid: userInfo.id } }"
        class="card-item"
        v-if="userInfo.identity == 0"
      >
        <van-icon class="icon" name="shop-o" />
        <span class="title">我的店铺</span>
      </router-link>

      <router-link
        :to="{ path: '/changShang/BillLog', query: { uid: userInfo.id } }"
        class="card-item"
        v-if="userInfo.identity == 1"
      >
        <van-icon class="icon" name="shop-o" />
        <span class="title">账单流水</span>
      </router-link>

      <router-link to="/noticeLog" class="card-item">
        <van-icon class="icon" name="star-o" />
        <span class="title">流程规则</span>
      </router-link>

      <router-link to="/inviteReg" class="card-item">
        <van-icon class="icon" name="share-o" />
        <span class="title">邀请注册</span>
      </router-link>

      <router-link to="/myInviter" class="card-item">
        <van-icon class="icon" name="friends-o" />
        <span class="title">我的邀请</span>
      </router-link>
    </div>

    <!-- 会员信息 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="vip-info" v-if="userInfo.user_vip_info">
          <div class="title">我的会员信息</div>
          <div class="text" v-if="userInfo.user_vip_info.vip_level == 1">
            会员等级：普通会员
          </div>
          <div class="text" v-if="userInfo.user_vip_info.vip_level == 2">
            会员等级：黄金会员
          </div>
          <div class="text" v-if="userInfo.user_vip_info.vip_level == 3">
            会员等级：钻石会员
          </div>
          <div class="text">
            开始时间：{{ userInfo.user_vip_info.created_at }}
          </div>
          <div class="text">
            到期时间：{{ userInfo.user_vip_info.end_time }}
          </div>
        </div>
      </div>
    </van-overlay>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { getUser, login } from "../../utils/login_toast";
import { wechatConfig } from "../../utils/wxConfig";
import { loginAlert } from "../../utils/loginAlert";
export default {
  components: {
    Tabber,
  },
  props: {},
  data() {
    return {
      userInfo: {
        id: "",
      },
      shopDetail: "",
      show: false,
      peijianDetail: "",

      isShowInfo: false,

      showVipCard: false,
      goodsShop: false,
    };
  },
  computed: {},
  created() {
    var _this = this;
    console.log("用户");
    // this.userInfo = this.$cookies.get("userInfo");
    var invate_uid = this.$route.query.invate_uid;
    if (invate_uid) {
      this.$cookies.set("invate_uid", invate_uid);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    var code = _this.getUrlKey("code");
    var state = _this.getUrlKey("state");
    var token = _this.$cookies.get("Authorization");
    if (!token && !code) {
      _this.$dialog
        .confirm({
          title: "提示",
          message: "您还未登录，请先去登陆！",
          confirmButtonText: "去登陆",
          cancelButtonText: "取消",
        })
        .then(() => {
          _this.$router.push({
            path: "/login",
            query: {
              back: "user",
            },
          });
        })
        .catch(() => {});
      return;
    } else if (!token && code) {
      login(this.getLoginUser);
      return;
    } else {
      _this.getUser();
    }
    _this.setShare();
    _this.checkOpen();
  },
  mounted() {},
  watch: {},
  methods: {
    checkOpen() {
      var _this = this;
      _this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      console.log("checkOpen");
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/goods_shop/shop_info?shop_uid=" + this.userInfo.id).then(
        (res) => {
          _this.$toast.clear();
          if (res && res.status == 200) {
            if (!res.data.error) {
              _this.goodsShop = res.data;
            }
            console.log("CheckOpen:", res.data, "----");
          }
        }
      );
    },
    onShowVipCard() {
      this.showVipCard = !this.showVipCard;
    },
    showMore(e) {
      const _this = this;
      _this.isShowInfo = !_this.isShowInfo;
    },
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-个人中心`,
        desc: `聚制冷精英，集行业资源，方便您我他！`,
        link: base.shareURL + "/user?invate_uid=" + this.userInfo.id,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
    toMyExpo() {
      var _this = this;
      indexOp("/expo_sponsor_user/show").then((res) => {
        if (res.data.state == 1000) {
          _this.$router.push("/sponsorUser");
        } else if (res.data.state == 1005 && res.data.msg == "数据不存在") {
          _this.$router.push("/sponsorUserEdit");
        }
      });
    },
    toMyJoiner() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/expo_joiner/show", data).then((res) => {
        if (res.data.state == 1000) {
          _this.$router.push("/joinerUser");
        } else if (res.data.state == 1005 && res.data.msg == "数据不存在") {
          _this.$router.push("/joinerUserEdit");
        }
      });
    },
    onToast() {
      var _this = this;
      _this.$dialog
        .alert({
          title: "提示",
          message:
            "您好师傅，为了保障冷链厂商家及用户的利益，咱们收到的冻结余额在48小时后，才可以让您随时提现。",
          confirmButtonText: "确定",
        })
        .then(() => {
          // _this.$router.push("/login");
          // on close
        })
        .catch(() => {
          // on cancel
        });
    },

    //获取配件模具详情
    getPeijianDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/fitting_user/show/1", data).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          _this.peijianDetail = res.data;
          // if ((_this.peijianDetail == "")) {
          //   _this.$dialog
          //     .alert({
          //       title: "提示",
          //       message: "您还未完善厂商信息，请先去完善。",
          //       confirmButtonText: "去完善",
          //     })
          //     .then(() => {
          //       _this.$router.push("/editPeijianinfo");
          //       // on close
          //     })
          //     .catch(() => {
          //       // on cancel
          //     });
          // }
        }
      });
    },
    //获取店铺详情
    getShopDetail() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      indexOp("/company_user/show/1", data).then((res) => {
        if (res && res.status == 200) {
          _this.shopDetail = res.data;
          // if ((_this.shopDetail == "")) {
          //   _this.$dialog
          //     .alert({
          //       title: "提示",
          //       message: "您还未完善公司信息，请先去完善。",
          //       confirmButtonText: "去完善",
          //     })
          //     .then(() => {
          //       _this.$router.push("/editShopinfo");
          //       // on close
          //     })
          //     .catch(() => {
          //       // on cancel
          //     });
          // }
        }
      });
    },
    //获取登录返回的用户数据
    getLoginUser(userInfo) {
      var _this = this;
      _this.userInfo = userInfo;
      _this.getUser();
    },
    getUser() {
      var _this = this;
      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      var api = "/user/show/1";
      indexOp(api).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 200) {
          if (res.data.identity == null) {
            _this.$router.push({
              path: "/identity",
              query: {
                back: "user",
              },
            });
            return;
          }
          _this.$cookies.set("identity", res.data.identity);
          _this.$cookies.set("userInfo", res.data);
          _this.userInfo = res.data;
          // if (!res.data.mobile && _this.userInfo.identity == 0) {
          //   _this.$dialog
          //     .alert({
          //       title: "提示",
          //       message: "您还未完善个人信息，请先去完善。",
          //       confirmButtonText: "去完善",
          //     })
          //     .then(() => {
          //       _this.$router.push("/identity");
          //       // on close
          //     })
          //     .catch(() => {
          //       // on cancel
          //     });
          // }else
          if (_this.userInfo.identity == 1) {
            _this.getShopDetail();
          } else if (_this.userInfo.identity == 3) {
            _this.getPeijianDetail();
          }
        } else {
          console.log(res);
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    toEditUset() {
      var _this = this;
      var identity = _this.userInfo.identity;
      var truename = _this.userInfo.truename;
      if (!_this.$cookies.isKey("Authorization")) {
        _this.$router.push({
          path: "/login",
          query: {
            back: "user",
          },
        });
        return;
      }
      _this.$router.push("/userEdit");
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>

<style scoped lang="less">
.vip-info {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  border-radius: 10px;

  .title {
    text-align: center;
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.vipinfo-box {
  padding: 15px;
  background: linear-gradient(169deg, #ebeefc 0%, #f9f0fc 100%);
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;

  .title {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .subtit {
    font-size: 11px;
    color: #616364;
    line-height: 20px;
  }

  .time {
    font-size: 9px;
    color: #7f7f7f;
    margin-top: 26px;
  }

  .text-color {
    color: #5a6fee;
  }

  .vip-bg {
    position: absolute;
    width: 77px;
    height: 86px;
    right: 20px;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0.8;
  }

  .right-top-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 14px;
    right: 12px;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    span {
      font-size: 9px;
      color: #3a4a6c;
    }
  }

  .right-bottom-btn {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 15px;
    right: 12px;
    color: #dfac7a;
    font-size: 10px;
  }
}

.vipinfo-box.glod {
  background: linear-gradient(169deg, #f2dac3 0%, #fff1e4 100%);

  .text-color {
    color: #ae7e50;
  }
}

.vipinfo-box.default {
  background: linear-gradient(169deg, #f3f2fa 0%, #e3eef4 100%);

  .text-color {
    color: #3a4a6c;
  }
}

.info-box {
  height: 80px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.vip-card {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;

  .text {
    font-size: 12px;
  }
}

.vip-card.default {
  background: linear-gradient(169deg, #f3f2fa 0%, #e3eef4 100%);
  color: #67c23a;
}

.vip-card.glod {
  background: linear-gradient(169deg, #f2dac3 0%, #fff1e4 100%);
  color: #ae7e50;
}

.vip-card.ordinary {
  background: linear-gradient(169deg, #f3f2fa 0%, #e3eef4 100%);
  color: #5a6fee;
}
</style>
