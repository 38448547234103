<template>
  <div class="repair-detail bidding-end">
    <Navbar :title="'维修详情'"></Navbar>
    <div class="order-info">
      <div class="title-box">
        <span>订单信息</span>
        <span class="time">发布时间：2小时前</span>
      </div>
      <div class="center">
        <div class="describe">
          <div class="left">
            <p class="item tit">维修产品名称：海尔冰箱 - 001款</p>
            <p class="item">
              维修描述：冰箱制冷异常，起雾出水漏电起雾出水漏电…
            </p>
          </div>
          <div class="right">
            <img src="@/assets/image/avatar.png" alt="" />
            <span>Iron Man</span>
          </div>
        </div>
        <div class="img-box">
          <span>故障产品照片：</span>
          <div class="imgs">
            <img src="@/assets/image/avatar.png" alt="" />
            <img src="@/assets/image/avatar.png" alt="" />
            <img src="@/assets/image/avatar.png" alt="" />
            <img src="@/assets/image/avatar.png" alt="" />
          </div>
        </div>
        <div class="position">
          <i class="iconfont iconweizhi"></i>陕西省铜川市王益区毕升路172号
        </div>
        <div class="position">
          <i class="iconfont iconshouji"></i>15703806315
        </div>
      </div>
      <div class="status-box">
        <span class="status">预付定金</span>
        <span class="icon"><i class="iconfont iconrenminbi"></i></span>
      </div>

      <div class="coll">
        <i class="iconfont iconduihua"></i>
        <span>发起聊天</span>
      </div>
    </div>

    <div class="bottom-box">
      <i class="iconfont iconzhengque"></i>
      <div class="title-box">
        <span>维修保价</span>
      </div>
      <p class="item">
        <span class="tit">上门维修费：</span>
        <span class="price">¥150</span>
      </p>
      <p class="item">
        <span class="tit">预计上门时间：</span>
        <span class="price">¥150</span>
      </p>
      <p class="item">
        <span class="tit">承诺保质期：</span>
        <span class="price">¥150</span>
      </p>
    </div>

    <div class="form-box">
      <div class="title-box">
        <span>订单进程</span>
      </div>
      <van-field
        label="当前状态"
        :value="value"
        placeholder="请选择当前状态"
        @click="showPicker = true"
        readonly
        clickable
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <van-cell-group>
        <van-field
          :autosize="{ maxHeight: 100, minHeight: 25 }"
          v-model="textarea"
          rows="1"
          label="补充说明"
          type="textarea"
          placeholder="请输入补充说明"
        />
      </van-cell-group>
      <div class="upload-img">
        <p class="tit">进程图片</p>
        <div class="imgs">
          <van-uploader multiple :max-count="2" />
        </div>
      </div>

      <van-button class="btn" type="info"
        ><i class="iconfont iconqueren1"></i> 提交</van-button
      >
    </div>

    <div class="order-process">
      <div class="title-box">
        <span>订单进程</span>
      </div>
      <div class="status-box">
        <span class="active">待接单</span>
        <span>等待用户确认</span>
        <span>等待上门检修</span>
        <span>上门检修</span>
        <span>等待配件</span>
        <span>维修中</span>
        <span>维修完成</span>
      </div>
      <div class="item">操作时间：2020-05-26</div>
      <div class="item">补充说明：2020-05-26</div>
      <div class="img-box">
        <span>故障产品照片：</span>
        <div class="imgs">
          <img src="@/assets/image/avatar.png" alt="" />
          <img src="@/assets/image/avatar.png" alt="" />
          <img src="@/assets/image/avatar.png" alt="" />
          <img src="@/assets/image/avatar.png" alt="" />
        </div>
      </div>
    </div>

    <Tabber></Tabber>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
export default {
  components: { Navbar, Tabber },
  props: {},
  data() {
    return {
      textarea: "",
      value: "",
      showPicker: false,
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
  },
};
</script>

<style scoped >
</style>
