<template>
	<div class="order-release">
		<Navbar :title="'添加用户'"></Navbar>
		<h2 class="inpit-title">用户姓名</h2>
		<van-field class="customInput" v-model="formData.name" autosize type="text" placeholder="请输入用户姓名" />
		<h2 class="inpit-title">联系电话</h2>
		<van-field class="customInput" v-model="formData.mobile" autosize type="text" placeholder="请输入联系电话" />
		<h2 class="inpit-title">联系地址</h2>
		<van-cell-group class="input-group">
			<van-field class="customInput" v-model="formData.province" placeholder="省" readonly clickable @click="showCityPicker = true" />
			<van-field class="customInput" v-model="formData.city" placeholder="市" readonly clickable @click="showCityPicker = true" />
			<van-field class="customInput" v-model="formData.area" placeholder="区" readonly clickable @click="showCityPicker = true" />
		</van-cell-group>
		<van-popup v-model="showCityPicker" round position="bottom">
			<van-area title="选择地址" :area-list="areaList" @cancel="showCityPicker = false" @confirm="onCityConfirm" />
		</van-popup>
		<div class="address-box">
			<van-field class="customInput" v-model="formData.address" autosize type="textarea" placeholder="请输入详细地址" />
		</div>
		<h2 class="inpit-title">产品型号</h2>
		<van-field class="customInput" v-model="formData.product_model" autosize type="textarea" placeholder="请输入产品型号" />
		<h2 class="inpit-title">备注</h2>
		<van-field class="customInput" v-model="formData.remark" autosize type="textarea" placeholder="请输入备注" />

		<van-button class="btn" type="info" @click="onSubmit" style="justify-content: center;"> 确认修改</van-button>
		<map-choice v-if="mapShow" @config-map="onConfigMap" @close-map="onCloseMap"></map-choice>
	</div>
</template>

<script>
	import Tabber from "../../components/Tabber";
	import Navbar from "../../components/Navbar";
	import province_list from "../../utils/area";
	import MapChoice from "../map_choice";
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp
	} from "../../api";
	import base from "../../api/base.js";
	import {
		login
	} from "../../utils/login_toast";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	export default {
		components: {
			Tabber,
			Navbar,
			MapChoice,
		},
		props: {},
		data() {
			return {
				message: "",
				radio: "",
				url: base.apiURL,
				showTimePicker: false, //时间选择器
				showCityPicker: false, //地址选择器
				minDate: new Date(2000, 0, 1),
				maxDate: new Date(2050, 10, 1),
				currentDate: new Date(),
				areaList: province_list,

				dateValue: "",
				province: "", //省
				city: "", //市
				area: "", //区
				formData: {
					name: "",
					mobile: "",
					province: "",
					city: "",
					area: "",
					address: "",
					product_model: "",
					remark : ''
				},
				mapShow: false, //地图组件显示

				depositList: [],
				showType: false,
				typeValue: "",
				userInfo: {
					id: "",
				},
				invate_uid: "",
				loginUrl: base.loginURL,
				lid: ""
			};
		},
		computed: {},
		created() {
			this.lid = this.$route.query.id;
			this.getDetail()
		},
		mounted() {},
		watch: {},
		methods: {
			getDetail() {
				var _this = this;
				var api = "/enterprise_user/show/" + _this.lid
				indexOp(api).then(res => {
					if (res && res.status == 200) {
						_this.formData.name = res.data.name;
						_this.formData.mobile = res.data.mobile;
						_this.formData.province = res.data.province_info ? res.data.province_info.area_name : res.data.province;
						_this.formData.city = res.data.city_info ? res.data.city_info.area_name : res.data.city;
						_this.formData.area = res.data.area_info ? res.data.area_info.area_name : res.data.area;
						_this.formData.address = res.data.address;
						_this.formData.remark = res.data.remark;
						_this.formData.product_model = res.data.product_model;
					}
					console.log(res)
				})
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				_this.userInfo = userInfo;
				this.getShifuDetail();
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-配件厂商入驻`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/editPeijianinfo?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			onPickerConfirm(value) {
				var _this = this;
				_this.typeValue = value.text;
				_this.formData.type = value.value;
				_this.showType = false;
				_this.onblur();
			},
			onSubmit() {
				var _this = this;
				if (_this.formData.name == "") {
					_this.$toast.fail("用户姓名不能为空！");
					return;
				}
				if (_this.formData.mobile == "") {
					_this.$toast.fail("联系电话不能为空！");
					return;
				}
				if (_this.formData.address == "") {
					_this.$toast.fail("联系地址不能为空！");
					return;
				}
				if (_this.formData.product_model == "") {
					_this.$toast.fail("产品型号不能为空！");
					return;
				}
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				
				
				_this.formData.id = _this.lid
				addOp("/enterprise_user/update", _this.formData).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.$toast.success("修改成功！");
						setTimeout(() => {
							_this.$router.go(-1);
						}, 500);
					}
				});
			},
			//时间选择确认
			onTimeConfirm(value) {
				this.showTimePicker = false;
				var y = value.getFullYear();
				var m = value.getMonth() + 1;
				var d = value.getDate();
				var h = value.getHours();
				var mm = value.getMinutes();
				var s = value.getSeconds();
				this.formData.buy_time = y + "-" + m + "-" + d + " " + h + ":" + mm;
			},
			//地区选择确认
			onCityConfirm(value, index) {
				this.showCityPicker = false;
				this.formData.province = value[0].name;
				this.formData.city = value[1].name;
				this.formData.area = value[2].name;
				this.formData.address = value[0].name + value[1].name + value[2].name
			},
		},
	};
</script>

<style scoped>
	.customInput {
		background-color: #fff!important;
		border-radius: 5px!important;
	}
</style>