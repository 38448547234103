<template>
  <div>
    <Navbar :title="`流程规则`"></Navbar>
    <div class="notice-box">
      <div class="title-box">《维修接单流程与规则》</div>
      <ul>
        <li>
          新人第一次，<br />一、1、点自己报价
          2、选择登录，允许微信登录3、填写师傅姓名手机号
        </li>
        <li>
          维保师傅<br />二、1、点自己报价，选择.<br />
          2直接报上门检修费或是先在线聊天谈问题及上门检修费。
        </li>
        <li>
          三、1、自己报价（上门检修费）等待下单子的预付款到平台（平台担保支付费用不提成，不收信息费）
          2、在线聊天，谈维修问题，上门检修费（可以在聊天里转账付款上门检查费）（平台担保支付上门检修费）
        </li>
        <li>四、确认接单，上门检修，拍照或是录个视频（检查出来的问题）</li>
        <li>五、上门检修后，自己报维修费用及配件费用</li>
        <li>六、等待预付费用，</li>
        <li>七、维修拍照或是录个视频，维修完成</li>
        <li>
          八、用户确认修好，费用自动到师傅冻结余额，48小时后，师傅可以随时提现。
        </li>
        <li>九、用户留言评价</li>
        <li>
          十、双方也可以用其他方式交易，不经过平台，我们不担保支付维修费用。
        </li>
        <li>
          十一、双方尽量用单子里的系统聊天及交易，后期我们好为您们保存证据，来保障双方的利益。
        </li>
        <li>
          十二、只有完善店铺资料的师傅，才可以展现及共享给更多的冷链厂商家。
        </li>
        <li>
          先预付维修定金，师傅干活更放心，只要事情处理好，维修费用不能少。
        </li>
        <li>通过搭建资源共享平台，让更多优秀的制冷人才聚集在一起。</li>
        <li>
          聚一帮靠谱的人，做一件靠谱的事，让需要找师傅的冷链厂商家及用户更省心，放心，让维保师傅们不再担心维修费用难到位，通过大力发掘，宣传诚信品牌，诚信企业，诚信商户，诚信技师，诚信用户，发挥先进典型的示范作用，引导人们见贤思齐，为提高我国制冷业商务诚信水平，改善商务信用生态环境做贡献。
        </li>
      </ul>
    </div>
    <Tabber></Tabber>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import base from "../api/base";
import { wechatConfig } from "../utils/wxConfig";
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {
      this.setShare()
  },
  mounted() {},
  watch: {},
  methods: {
    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网维修师傅接单流程与规则`,
        desc: `聚制冷精英，集行业资源，方便您我他！`,
        link: base.shareURL + "/noticeLog",
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },
  },
  components: {
    Tabber,
    Navbar,
  },
};
</script>

<style scoped >
</style>
