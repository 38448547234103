<template>
	<div class="changshang-index product-list peijian" style="display:block!important;">
		<div class="nav-box">
			<router-link :to="{ path: '/peijianIndex', query: { uid: uid } }" class="item">网站首页</router-link>
			<!-- <router-link :to="{path:'/changshangIndex', query:{ uid:uid }}" class="item">公司简介</router-link> -->
			<router-link :to="{ path: '/peijian_productList', query: { uid: uid } }" class="item">产品展示</router-link>
			<router-link :to="{ path: '/peijian_newsList', query: { uid: uid } }" class="item">资讯中心</router-link>
			<router-link :to="{ path: '/peijian_videoList', query: { uid: uid } }" class="item">视频中心</router-link>
			<router-link :to="{ path: '/peijian_contactUs', query: { uid: uid } }" class="item">联系我们</router-link>
		</div>

		<div class="swiper-box">
			<van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
				<van-swipe-item v-for="(item, index) in slideList" :key="index">
					<img class="banner-img" :src="url + item.cover" alt="" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="title-box">
			<span class="text">产品列表</span>
			<div class="right">
				<!-- <router-link class="more" :to="{path:'/classAdd',query:{id:cid}}">添加分类</router-link> -->
			</div>
		</div>
		<!-- <div class="search-box" v-if="goodsList.length > 0">
      <div class="input-box">
        <van-icon class="icon" name="search" />
        <van-field v-model="value" class="input" placeholder="产品搜索" />
      </div>
      <div class="type-btn" @click="treeSelectShow = true">
        <span>产品分类</span>
        <van-icon name="arrow-down" />
      </div>
    </div> -->
		<div class="type" @click="treeSelectShow = true" v-if="classValue">
			<span>产品分类：{{ classValue }}</span>
			<van-icon name="close" size="16px" @click.stop="onclear" />
		</div>
		<div class="list">
			<div class="item" v-for="(item, index) in goodsList" :key="index" @click="toDetail(item.id)">
				<img :src="url + item.image.split(',')[0]" alt="" />
				<span class="title">{{ item.goods_name }}</span>
			</div>
			<div class="empty-box" v-if="goodsList.length == 0">暂无产品数据 ~</div>
		</div>
		<!-- <van-popup v-model="treeSelectShow" position="bottom" round>
      <div class="popup-title">选择产品分类</div>
      <van-tree-select
        :items="columns"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-item="onChangeSelect"
      />
    </van-popup> -->

		<van-popup v-model="treeSelectShow" round position="bottom">
			<van-picker ref="classPicker" show-toolbar :columns="columns" @cancel="treeSelectShow = false"
				@confirm="onConfirm" value-key="name" />
		</van-popup>
		<div class="btns" v-if="userInfo && uid == userInfo.id">
			<router-link class="btn" :to="{ path: '/peijian_classList', query: { id: uid } }">
				<van-icon name="setting-o" />
				<span>分类管理</span>
			</router-link>
			<router-link class="btn" :to="{ path: '/peijian_productAdd', query: { id: uid } }">
				<van-icon name="plus" />
				<span>添加产品</span>
			</router-link>
		</div>
		<div class="btns" v-else>
			<a v-if="userInfo && userInfo.user_vip_info && info.phone" :href="`tel:${info.phone}`" class="btn">
				<i class="iconfont icondianhua"></i>
				<span>电话联系</span>
			</a>
			<a v-else :href="`tel:4006912808`" class="btn">
				<i class="iconfont icondianhua"></i>
				<span>电话联系</span>
			</a>
			<div class="btn" @click="toChat">
				<i class="iconfont iconxiaoxi"></i>
				<span>在线联系</span>
			</div>
		</div>
		<Tabber></Tabber>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	import {
		loginAlert
	} from "../../utils/loginAlert";
	export default {
		components: {
			Navbar,
			Tabber,
		},
		props: {},
		data() {
			return {
				url: base.apiURL,
				loginURL: base.loginURL,
				slideList: "", //轮播列表
				userInfo: {
					id: "",
				},
				uid: "",
				info: "",
				treeSelectShow: false,
				items: [{
					// 导航名称
					text: "制冷产品",
					// 该导航下所有的可选项
					children: [{
							// 名称
							text: "冰箱",
							id: 1,
						},
						{
							text: "冰柜",
							id: 2,
						},
					],
				}, ],
				activeId: 1,
				activeIndex: 0,
				value: "",
				cid: "", //厂商id
				queryParam: {},
				columns: [],
				goodsList: [],
				classValue: "",
			};
		},
		computed: {},
		created() {
			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			var code = this.getUrlKey("code");
			var state = this.getUrlKey("state");
			if (!this.$cookies.isKey("Authorization") && code) {
				login(this.getUser);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.uid = this.$route.query.uid;
			this.queryParam.id = this.$route.query.uid;
			this.getShopDetail();
			this.getProductList();
			this.getGoodsType();
			this.getSlide();
		},
		mounted() {},
		watch: {},
		methods: {
			//去产品详情
			toDetail(id) {
				this.$router.push({
					path: "/productDetail",
					query: {
						id: id,
					},
				});
			},
			//获取轮播
			getSlide() {
				var _this = this;
				indexOp("/slide/index?cid=1").then((res) => {
					if (res && res.status == 200) {
						_this.slideList = res.data;
					}
				});
			},
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-` + this.info.name,
					desc: this.info.desc,
					link: base.shareURL +
						"/peijian_productList?uid=" +
						this.info.uid +
						"&invate_uid=" +
						this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			//获取店铺详情
			getShopDetail() {
				var _this = this;
				_this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				var data = {
					uid: _this.uid,
				};
				indexOp("/fitting_user/show/1", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.info = res.data;
						_this.setShare();
					}
					console.log(res);
				});
			},
			//获取产品列表
			getProductList() {
				var _this = this;
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				indexOp("/goods/index", _this.queryParam).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						_this.goodsList = res.data;
					}
					console.log(res);
				});
			},
			//获取产品分类
			getGoodsType() {
				var _this = this;
				var data = {
					id: _this.uid,
				};
				indexOp("/goods_type/index", data).then((res) => {
					if (res && res.status == 200) {
						res.data.map((item) => {
							if (item._child && item._child.length > 0) {
								var arr = [];
								item._child.map((child) => {
									child.text = child.name;
									arr.push(child);
								});
								item.text = item.name;
								item.children = arr;
								_this.columns.push(item);
							}
						});
					}
					console.log(res);
				});
			},
			//去对话
			toChat() {
				var _this = this;
				if (!_this.$cookies.isKey("userInfo")) {
					loginAlert()
					return;
				}
				var to_uid = _this.info.uid; //聊天对象 uid
				var form_uid = _this.$cookies.get("userInfo").id; //自己的 uid
				var data = {
					params: {
						to_uid: to_uid,
						from_uid: form_uid,
						chat_type: "normal",
					},
				};
				addOp("/im/build_chat", data).then((res) => {
					console.log(res);
					//跳转
					_this.$router.push({
						path: "/chat",
						query: {
							chat_id: res.data.chat_id,
							to_uid: to_uid,
						},
					});
				});
			},
			//获取登录返回的用户数据
			getUser(userInfo) {
				var _this = this;
				console.log(userInfo);
				_this.userInfo = userInfo;
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			//分类选择
			onConfirm(value) {
				var val = this.$refs.classPicker.getValues();
				console.log(val);
				this.classValue = value[0] + " / " + value[1];
				this.treeSelectShow = false;
			},
			onclear() {
				this.classValue = "";
			},
		},
	};
</script>

<style scoped>
</style>