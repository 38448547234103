<template>
	<div class="user-manage">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">知识库</span>
			<!-- <span style="font-size: 12px" @click="toAdd">添加用户</span> -->
			<!-- <span style="font-size: 12px" @click="dataAsyncUpdate"><span
					style="position:relative;top:2px;right:3px;"><van-icon name="replay" /></span>同步历史数据</span> -->
			<!-- <van-icon name="setting"  /> -->
		</div>
		<!-- <van-search v-model="filterForm.keyword" show-action placeholder="请输入搜索关键词" :show-action="false"
			@search="onSearch"></van-search> -->
		<van-tabs v-model="categoryActive" v-if="categoryList" @change="tabChange">
			<van-tab title="全部" name="0">
				<template #title> <van-icon name="home-o" style="position:relative;top:2px;" /> <span>全部</span></template>
			</van-tab>
			<van-tab v-for="(item,index) in categoryList" :title="item.name" :name="item.id"></van-tab>
		</van-tabs>
		
		<div class="knowledge">
			<div class="knowledge-list" v-if="list && list.data" v-for="(item,index) in list.data" @click="showDetail(item)">
				<div class="left">
					<div class="title">
						{{item.name}}
					</div>
					<div class="info">
						<van-icon name="eye-o" style="position:relative;top:3px;" /><span>{{item.hits}}</span>
					</div>
				</div>
				<div class="right">
					<svg v-if="!item.thumb" t="1694887059661" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4265" width="200" height="200"><path d="M87 160h850c13.255 0 24 10.745 24 24v656c0 13.255-10.745 24-24 24H87c-13.255 0-24-10.745-24-24V184c0-13.255 10.745-24 24-24z" fill="#C5C5C5" fill-opacity=".45" p-id="4266"></path><path d="M111 864l128.17-128.361c22.092-22.125 57.936-22.151 80.06-0.06 22.124 22.091 57.968 22.065 80.06-0.06l120.576-120.756c39.023-39.081 102.34-39.128 141.42-0.105L911 864H111z" fill="#ABABAB" fill-opacity=".574" p-id="4267"></path><path d="M321 487m-48 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0Z" fill="#ABABAB" fill-opacity=".574" p-id="4268"></path><path d="M71 160h882a8 8 0 0 1 8 8v104H63V168a8 8 0 0 1 8-8z" fill="#ABABAB" fill-opacity=".5" p-id="4269"></path><path d="M287 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000" fill-opacity=".302" p-id="4270"></path><path d="M207 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000" fill-opacity=".302" p-id="4271"></path><path d="M127 216m-16 0a16 16 0 1 0 32 0 16 16 0 1 0-32 0Z" fill="#000000" fill-opacity=".302" p-id="4272"></path></svg>
					<img :src=" url + item.thumb " alt="">
				</div>
			</div>
			<div class="loadMore" v-if="list && list.data" @click="more">
				加载更多<van-icon name="arrow" style="position:relative;top:2px;" />
			</div>
			<van-empty v-if="!list" image="error" description="暂无数据" />
		</div>
		
		<van-popup v-model="detailShow" v-if="detailShow && detailContent" position="bottom" round :closeable="true" >
			<div class="knowledgeContent">
				<div class="title">
					{{detailContent.name}}
				</div>
				<div class="content">
					<p v-html="detailContent.content"></p>
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp
	} from "../../api";
	import base from "../../api/base";
	export default {
		props: {},
		inject: ["reload"],
		components: {},
		data() {
			return {
				tabsActive: 0,
				refresh: false,

				filterForm: {
					keyword: '',
					page: 1,
					limit: 15,
					category_id : '',
				},
				list: null,
				categoryList: null,
				categoryActive : 0,
				url: base.apiURL,
				
				detailShow : false,
				detailContent : null,
				
			};
		},
		computed: {},
		created() {
			var _this 	=	this;
			this.loadCategoryList(function(res){
				_this.getList();
			});
		},
		mounted() {},
		watch: {},
		methods: {
			tabChange(e){
				this.tabsActive 	=	e;
				this.filterForm.page 	=	1;
				this.list 	=	null;
				if(e == 0){
					this.filterForm.category_id 	=	'';
				}else{
					this.filterForm.category_id 	=	e;
				}
				this.getList();
			},
			/**
			 * 加载分类
			 */
			loadCategoryList(callback){
				var _this 	=	this;
				indexOp('/knowledge_category/get').then((res)=>{
					if(res.data){
						_this.categoryList 	=	res.data;
					}
					callback && callback();
				});
			},
			/**
			 * 获取数据
			 */
			getList() {
				var _this = this;
				indexOp("/knowledge/index", this.filterForm).then((res) => {
					_this.refresh = false;
					if (res && res.status == 200) {
						if(res.data.data.length < 1){
							_this.$toast.fail('暂无更多数据');
							if (_this.filterForm.page == 1) {
								_this.list = [];
							}
							return false;
						}
						if (_this.filterForm.page == 1) {
							_this.list = res.data;
						} else {
							var tmpData = _this.list.data;
							for (var i in res.data.data) {
								tmpData.push(res.data.data[i]);
							}
							res.data.data = tmpData;
							_this.list.data = tmpData;
						}
						console.log(_this.list);
					}
				});
			},
			/**
			 * 返回上级
			 */
			toBack() {
				this.$router.go(-1);
			},
			/**
			 * 查看更多
			 */
			more() {
				this.filterForm.page++;
				this.getList();
			},
			/**
			 * 获取详情
			 */
			showDetail(item){
				var _this 	=	this;
				// this.detailContent 	=	item;
				// this.detailShow =	true;
				
				_this.$router.push({
					path : '/knowledge/detail',
					query : {
						id : item.id
					}
				});
			}

		}
	};
</script>

<style lang="less" scoped>

</style>