<template>
  <div class="messageinfoWrap">
    <div class="top-navbar">
      <van-icon name="arrow-left" @click="toBack" />
      <span class="text">信息详情</span>
      <van-icon name="wap-home-o" @click="toBackIndex" />
    </div>

    <div class="messageinfoContent">
      <div class="messageinfoContentTitle">
        <div class="messageinfoContentTitleLeft">
          <img :src="contentObj.uinfo.avatar" alt="" />
        </div>
        <div class="messageinfoContentTitleRight">
          <span>{{ contentObj.uinfo.nickname }}</span>
          <p>发布时间：{{ contentObj.created_at }}</p>
          <van-icon
            v-if="userInfo.id == contentObj.uid && contentObj.show_delete"
            class="delete-icon"
            name="delete-o"
            @click="onDelete"
          />
        </div>
      </div>
      <div class="messageinfoContentXq">
        <p v-html="contentObj.content"></p>
        <div v-if="contentObj.pictures != ''">
          <img
            v-for="(item, index) in contentObj.pictures"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div v-if="contentObj.video">
          <video
            controls="controls"
            width="100%"
            :src="contentObj.video"
          ></video>
        </div>
      </div>
    </div>

    <div class="messageinfoComment">
      <div class="messageinfoCommentTitle">
        <span>评论</span>
      </div>
      <div
        class="messageinfoCommentList"
        v-for="(item, index) in contentList"
        :key="index"
      >
        <div class="messageinfoCommentLeft" @click="toChat(item.uid)">
          <img :src="item.uinfo.avatar" alt="" />
        </div>
        <div class="messageinfoCommentRight">
          <span>{{ item.uinfo.nickname }}</span>
          <p>{{ item.content }}</p>
          <div class="commentBottom">
            <p style="font-size: 12px; color: #666">{{ item.updated_at }}</p>
            <div class="commentBottomDs" @click="showPayPopup1(item.id)">
              <van-icon name="balance-o" color="red" size="20" />
              <span>打赏他</span>
            </div>
          </div>
        </div>
      </div>
      <div class="emptyTips" v-if="contentList.length == 0">
        <p>暂无评论</p>
      </div>
    </div>

    <div class="messageinfoBottom">
      <div class="messageinfoBottomDz" @click="toPointPraise">
        <van-icon name="like-o" size="20" v-if="isDianZan" />
        <van-icon name="like" color="red" size="20" v-else />
        <p>点赞({{ contentObj.zan_count }})</p>
      </div>
      <div class="messageinfoBottomPl" @click="showPopup">
        <van-icon name="comment-o" size="20" />
        <p>评论({{ contentObj.comment_count }})</p>
      </div>
      <div class="messageinfoBottomFx" @click="showPayPopup">
        <van-icon name="balance-o" size="20" />
        <p>打赏({{ contentObj.admire_count }})</p>
      </div>
    </div>

    <van-popup
      class="popupTc"
      v-model="showcomment"
      closeable
      round
      position="bottom"
    >
      <van-field
        v-model="formData.content"
        class="formInput"
        autosize
        type="textarea"
        placeholder="写评论..."
      />
      <van-button class="publishbtn" type="info" @click="onSubmit"
        >发送</van-button
      >
    </van-popup>

    <!-- 打赏帖子 -->
    <van-popup class="popupCenter" v-model="showPricePopup" closeable>
      <h3>我要打赏</h3>
      <van-field
        v-model="price"
        class="formprice"
        autosize
        type="number"
        placeholder="请输入打赏金额"
      />
      <van-button class="pricebtn" type="info" @click="submitPayPrice"
        >确认打赏</van-button
      >
    </van-popup>

    <!-- 打赏评论 -->
    <van-popup class="popupCenter" v-model="showPricePopup1" closeable>
      <h3>我要打赏</h3>
      <van-field
        v-model="price"
        class="formprice"
        autosize
        type="number"
        placeholder="请输入打赏金额"
      />
      <van-button class="pricebtn" type="info" @click="submitPayPrice1"
        >确认打赏</van-button
      >
    </van-popup>
  </div>
</template>
<script>
import Navbar from "../../components/Navbar";
import { indexOp, addOp } from "../../api/index";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";
import base from "../../api/base";
export default {
  data() {
    return {
      messageid: "",
      contentObj: {
        uinfo: {},
      },
      picturesList: [],
      showcomment: false,
      showPricePopup: false,
      showPricePopup1: false,
      formData: {
        content: "",
        pid: "",
        interflow_id: "",
      },
      contentList: [],
      isDianZan: true,
      price: "",
      commentid: "",
      userInfo: {
        id: "",
      },
    };
  },
  components: {
    Navbar,
  },
  created() {
    var code = this.getUrlKey("code");
    var state = this.getUrlKey("state");
    if (!this.$cookies.isKey("Authorization") && code) {
      login(this.getUser);
    }
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
    }
    this.messageid = this.$route.query.id;
    this.getMessageContent();
    this.getCommentList();
  },
  methods: {
    onDelete() {
      var _this = this;
      _this.$dialog
        .confirm({
          title: "提示",
          message: "您确认删除这条帖子吗？",
        })
        .then(() => {
          var data = {
            id: _this.contentObj.id,
          };
          addOp("/interflow/destroy", data).then((res) => {
            console.log(res);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取登录返回的用户数据
    getUser(userInfo) {
      var _this = this;
      console.log(userInfo);
      _this.userInfo = userInfo;
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    toBack() {
      this.$router.go(-1);
    },

    toBackIndex() {
      var _this = this;
      _this.$router.push({
        path: "/",
      });
    },

    showPopup() {
      this.showcomment = true;
    },

    showPayPopup() {
      this.showPricePopup = true;
    },

    showPayPopup1(id) {
      this.commentid = id;
      this.showPricePopup1 = true;
    },

    //分享
    setShare() {
      const shareInfo = {
        title: `冷鲜柜网-技术交流`,
        desc: `有任何问题和需要帮助的地方都可以在这里发布得到解决！`,
        link: base.shareURL + "/messageInfo?id=" + this.messageid,
        img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
      };
      wechatConfig(shareInfo);
    },

    // 获取帖子详情
    getMessageContent() {
      var _this = this;
      var data = {
        id: this.messageid,
      };
      indexOp("/interflow/show", data).then((res) => {
        if (res && res.status == 200) {
          console.log(res);
          _this.contentObj = res.data;
          _this.setShare();
        } else {
          _this.$toast.fail(res.data.error);
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        }
      });
    },

    // 获取评论列表
    getCommentList() {
      var _this = this;
      var data = {
        uid: "",
        pid: "",
        interflow_id: _this.messageid,
        limit: "",
      };
      indexOp("/interflow_comment/get_lists", data).then((res) => {
        // console.log(res)
        _this.contentList = res.data.data;
      });
    },

    // 点赞
    toPointPraise(id) {
      var _this = this;
      _this.isDianZan = true;
      var data = {
        other_id: this.messageid,
        type: "interflow",
      };
      addOp("/interflow_zan/create", data).then((res) => {
        console.log(res);
        if (res && res.status == 201) {
          _this.$toast.success("点赞成功！");
          _this.isDianZan = false;
        } else {
          _this.$toast.fail(res.data.error);
        }
      });
    },

    // 提交评论
    onSubmit() {
      var _this = this;
      _this.formData.interflow_id = _this.messageid;
      if (_this.formData.content == "") {
        _this.$toast.fail("发布内容不能为空");
        return;
      }
      addOp("/interflow_comment/create", _this.formData).then((res) => {
        _this.$toast.clear();
        console.log(res);
        if (res && res.status == 201) {
          _this.$toast.success("发布成功！");
          _this.showcomment = false;
          _this.getCommentList();
        }
      });
    },

    // 打赏帖子
    submitPayPrice() {
      var _this = this;
      var data = {
        money: _this.price,
        type: "interflow",
        interflow_id: parseInt(_this.messageid),
        pay_type: "",
      };
      addOp("/interflow_admire/create", data).then((res) => {
        _this.$toast.clear();
        console.log(res);
        if (res && res.status == 201) {
          _this.$toast.success("打赏成功！");
          _this.showPricePopup = false;
        } else {
          _this.showPricePopup = false;
          _this.$toast.fail(res.data.error);
        }
      });
    },

    // 打赏评论
    submitPayPrice1() {
      var _this = this;
      var data = {
        money: _this.price,
        type: "comment",
        comment_id: _this.commentid,
        pay_type: "",
      };
      addOp("/interflow_admire/create", data).then((res) => {
        _this.$toast.clear();
        console.log(res);
        if (res && res.status == 201) {
          _this.$toast.success("打赏成功！");
          _this.showPricePopup1 = false;
        } else {
          _this.showPricePopup1 = false;
          _this.$toast.fail(res.data.error);
        }
      });
    },

    //去对话
    toChat(uid) {
      var _this = this;
      if (_this.$cookies.isKey("userInfo")) {
        var to_uid = uid; //聊天对象 uid
        var data = {
          params: {
            to_uid: uid,
            chat_type: "normal",
          },
        };
        addOp("/im/build_chat", data).then((res) => {
          console.log(res);
          if (res && res.status != 500) {
            //跳转
            _this.$router.push({
              path: "/chat",
              query: {
                chat_id: res.data.chat_id,
                to_uid: uid,
              },
            });
          } else {
            _this.$toast.fail("对话创建失败！");
          }
        });
      } else {
        loginAlert()
      }
    },
  },
};
</script>
<style lang="less" scoped>
.messageinfoWrap {
  .messageinfoContent {
    width: 96%;
    margin: 10px auto;
    height: 100%;
    background-color: #fff;
    padding: 10px;
    .messageinfoContentTitle {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      .messageinfoContentTitleLeft {
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: block;
          margin-right: 10px;
        }
      }
      .messageinfoContentTitleRight {
        width: 75%;
        position: relative;
        .delete-icon {
          position: absolute;
          right: 0;
          top: 10%;
        }
        span {
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
        }
        p {
          font-size: 12px;
          color: #666;
        }
      }
    }
    .messageinfoContentXq {
      margin-top: 10px;
      p {
        font-size: 12px;
        color: #666;
        line-height: 30px;
      }
      img {
        width: 100%;
      }
    }
  }

  .messageinfoComment {
    width: 96%;
    margin: 10px auto;
    height: 100%;
    background-color: #fff;
    padding: 10px 10px 50px 10px;
    .messageinfoCommentTitle {
      span {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .messageinfoCommentList {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
      .messageinfoCommentLeft {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: block;
          margin-right: 10px;
        }
      }
      .messageinfoCommentRight {
        width: 85%;
        span {
          font-size: 13px;
          color: #666;
        }
        p {
          font-size: 14px;
          line-height: 22px;
        }

        .commentBottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .commentBottomDs {
            justify-content: flex-end;
            align-items: center;
            display: flex;
            span {
              display: block;
              margin-left: 5px;
              color: red;
            }
          }
        }
      }
    }

    .emptyTips {
      p {
        text-align: center;
        color: #666;
        font-weight: bold;
        line-height: 36px;
      }
    }
  }

  .messageinfoBottom {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: #fff;

    .messageinfoBottomDz {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33%;
      // margin-left: 8.5%;
      p {
        font-size: 12px;
        display: block;
        margin-left: 5px;
      }
    }
    .messageinfoBottomPl {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33%;
      // margin-left: 16.5%;
      p {
        font-size: 12px;
        display: block;
        margin-left: 5px;
      }
    }
    .messageinfoBottomFx {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33%;
      // margin-left: 16.5%;
      p {
        font-size: 12px;
        display: block;
        margin-left: 5px;
      }
    }
  }

  .popupTc {
    display: flex;
    justify-content: space-between;
    height: 25%;
    padding: 10px;
    .formInput {
      background-color: #eee;
      width: 280px;
      height: 100px;
      margin-top: 20px;
    }
    .publishbtn {
      margin-top: 35px;
      height: 80px;
    }
  }

  .popupCenter {
    width: 300px;
    height: 200px;
    border-radius: 8px;
    h3 {
      padding-left: 10px;
      text-align: center;
    }
    .formprice {
      background-color: #eee;
      width: 280px;
      margin: 25px auto 0 auto;
    }
    .pricebtn {
      width: 160px;
      margin-top: 20px;
      margin-left: 23%;
    }
  }
}
</style>