<template>
  <div class="account">
    <Navbar :title="`添加账号`"></Navbar>
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="mobile"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import Tabber from "../components/Tabber";
import Navbar from "../components/Navbar";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../api/index";
import base from "../api/base";
export default {
  props: {},
  data() {
    return {
      username: "",
      password: "",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onSubmit(values) {
      var _this = this;
      addOp("/oauth/user_login", values).then((res) => {
        console.log(res);
        if (res && res.status == 200) {
          _this.$cookies.set("Authorization",res.data.token_type + " " + res.data.access_token)
          _this.$cookies.set("login_type", res.data.login_type);
          setTimeout(() => {
            this.addAccount();
          }, 800);
        }else{
          _this.$toast.fail(res.data.error);
        }
      });
      console.log("submit", values);
    },
    addAccount() {
      var _this = this;
      var userInfo = this.$cookies.get("userInfo");
      var data = {
        parent_uid: userInfo.id,
      };
      addOp("/login_log/store", data).then((res) => {
        if (res && res.data) {
          _this.$toast.success("操作成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        }
        console.log(res);
      });
    },
  },
  components: {
    Tabber,
    Navbar,
  },
};
</script>

<style scoped >
</style>
