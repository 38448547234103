<template>
	<div style="overflow-y: scroll;padding-bottom:64px;">
		<shifuUser v-if="identity == 2 || identity == 3"></shifuUser>
		<yhUser v-else></yhUser>
	</div>
</template>

<script>
	import shifuUser from "./Shifu/user";
	import yhUser from "./User/user";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";
	import base from "../api/base";
	export default {
		components: {
			shifuUser,
			yhUser,
		},
		props: {},
		data() {
			return {
				identity: "0",
			};
		},
		computed: {},
		created() {
			if (this.$cookies.isKey("userInfo")) {
				var userInfo = this.$cookies.get("userInfo");
				this.identity 	=	userInfo.identity;
				this.$cookies.set('identity',userInfo.identity);
			}
			this.identity = this.$cookies.get("identity");
		},
		mounted() {},
		watch: {},
		methods: {},
	};
</script>

<style scoped>
</style>
