<template>
	<div class="user-manage">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">用户管理</span>
			<!-- <span style="font-size: 12px" @click="toAdd">添加用户</span> -->
			<span style="font-size: 12px" @click="dataAsyncUpdate"><span
					style="position:relative;top:2px;right:3px;"><van-icon name="replay" /></span>同步历史数据</span>
			<!-- <van-icon name="setting"  /> -->
		</div>
		<van-pull-refresh style="min-height: calc(100vh - 50px);" v-model="refresh" success-text="刷新成功"
			@refresh="onRefresh">
			<van-search v-model="filterForm.searchValue" @clear="filterForm.page=1;list=null;getList()"
				@search="filterForm.page=1;list=null;getList()" placeholder="姓名/手机号码/产品名称" />
			<van-tabs v-model="tabsActive" color="#2F78DC" :before-change="tabsBeforeChange">
				<van-tab title="所有用户"></van-tab>
				<van-tab title="重要用户"></van-tab>
			</van-tabs>
			<div class="list" v-if="list &&  list.data && list.data.length">
				<div class="item" v-for="(item, index) in list.data" :key="index">
					<div class="col">
						<span class="title">用户姓名：</span>
						<span class="cent">{{ item.name ? item.name : '-' }}</span>
					</div>
					<div class="col">
						<span class="title">联系电话：</span>
						<span class="cent">{{ item.mobile }}</span>
					</div>
					<div class="col">
						<span class="title">联系地址：</span>
						<span class="cent">{{  (item.province_info ? item.province_info.area_name : '') + (item.city_info ? item.city_info.area_name : '') + (item.area_info ? item.area_info.area_name : '') + item.address }}</span>
					</div>
					<div class="col">
						<span class="title">产品型号：</span>
						<span class="cent">{{ item.product_model }}</span>
					</div>
					<div class="col">
						<span class="title">维修单数量：</span>
						<span class="cent">{{ item.service_orders_count }}</span>
					</div>
					<van-notice-bar v-if="item.remark" :scrollable="false" left-icon="edit" wrapable
						:text="item.remark" />
					<div class="btns">
						<van-button class="btn" size="small" @click="toggleImportant(item,index)">
							<van-icon name="star-o" v-if="item.important == '0'" />
							<van-icon name="star" v-else color="#ee0a24" />
						</van-button>
						<van-button class="btn" size="small" @click="toEdit(item.id)">修改</van-button>
						<van-button class="btn" type="danger" size="small" @click="onDelete(item.id)">删除</van-button>
						<van-button class="btn" type="info" size="small" @click="openRepairList(item)">维修单</van-button>
					</div>
				</div>
				<a href="javascript:;" class="more-btn" @click="more" v-if="filterForm.page < list.last_page">查看更多</a>
			</div>
			<div v-else>
				<van-empty image="error" description="暂无数据~" />
			</div>
		</van-pull-refresh>

		<!-- 师傅维修单的一个弹出层 -->
		<van-popup v-model="repairListShow" round closeable position="bottom">
			<div class="repair-container">
				<div class="repair-title">
					<van-icon name="balance-list-o" size="25" style="margin-right: 5px;" />
					所有维修单
				</div>
				<van-pull-refresh v-model="repairListLoadingStatus" @refresh="onRepairListRefresh">
					<div class="list" v-if="repairList && repairList.data && repairList.data.length">
						<div class="item" v-for="(item, index) in repairList.data" :key="index"
							@click="toRepairDetail(item)">
							<div class="status" v-if="item.deposit_pay_status == 1 && item.door_pay_status == 0">
								已预付订金
							</div>
							<div class="status" v-else-if="item.door_pay_status == 1 || service_pay_status == 1">
								在线聊天交易
							</div>
							<div class="status" v-else-if="
				  (item.door_pay_status > 0 &&
				    item.service_type == 'user_sure_service') ||
				  item.service_type == 'appraise_order' ||
				  item.service_type == 'pay_money'
				">
								在线聊天交易
							</div>
							<div class="status" v-else style="background: #d6d6d6">
								在线聊天转账
							</div>
							<div class="flex">
								<div class="left-box">
									<img class="avatar" v-if="item.get_user_info" :src="item.get_user_info.avatar"
										alt="" />
									<span class="name" v-if="item.get_user_info">{{
				    item.get_user_info.nickname
				  }}</span>
									<van-tag class="tag" color="#FF4D4F" text-color="#fff"
										v-if="item.service_type == 'order_bidding'">待接单</van-tag>
									<van-tag class="tag" color="#FF4D4F" text-color="#fff"
										v-else-if="item.service_type == 'sure_service_user'">已确认师傅</van-tag>
									<van-tag class="tag" color="#ffdc30" text-color="#fff"
										v-else-if="item.service_type == 'take_order'">已接单</van-tag>
									<van-tag class="tag" color="#FF4D4F" text-color="#fff"
										v-else-if="item.service_type == 'arrive_order'">师傅已到达</van-tag>
									<van-tag class="tag" color="#795548" text-color="#fff"
										v-else-if="item.service_type == 'user_sure_arrive'">师傅已到达</van-tag>
									<van-tag class="tag" color="#3f69de" text-color="#fff"
										v-else-if="item.service_type == 'service_ing'">维修中</van-tag>
									<van-tag class="tag" color="#8e8e8e" text-color="#fff"
										v-else-if="item.service_type == 'service_end'">维修完成</van-tag>
									<van-tag class="tag" color="#d6d6d6" text-color="#fff"
										v-else-if="item.service_type == 'user_sure_service'">已确认完成</van-tag>
									<van-tag class="tag" color="#8bc34a" text-color="#fff"
										v-else-if="item.service_type == 'appraise_order'">已评价</van-tag>
									<!-- <van-tag
				    class="tag"
				    color="#bababa"
				    text-color="#fff"
				    v-else-if="item.service_type == 'pay_money'"
				    >订单结束</van-tag
				  > -->
									<!-- <div class="service-user">
				  <a href="javascript:;">
				    <img class="img" src="@/assets/image/avatar.png" alt="">
				  </a>
				</div> -->
								</div>
								<div class="right-box">
									<p class="title">维修产品名称：{{ item.name }}</p>
									<p class="describe">故障描述：{{ item.desc }}</p>
									<p class="time">发布时间：{{ item.created_at }}</p>
									<p class="time" v-if="item.province_info && item.city_info && item.area_info">
										所在地区：{{
				      item.province_info.area_name
				        ? item.province_info.area_name
				        : "暂无"
				    }}-{{
				      item.city_info.area_name
				        ? item.city_info.area_name
				        : "暂无"
				    }}-{{
				      item.area_info.area_name ? item.area_info.area_name : ""
				    }}
									</p>
									<p class="position">
										<i class="iconfont iconweizhi"></i>
										{{ item.full_address }}
									</p>
								</div>
							</div>
							<div class="service-avatar" v-if="
				  item.service_type == 'order_bidding' &&
				  item.get_order_bidding.length > 0
				">
								<span></span>
								<div v-for="(src, index) in item.get_order_bidding" :key="index"
									@click.stop="toShopDetail(src.service_id)">
									<img :src="src.service_info.user_info.avatar" alt="" />
								</div>
							</div>
						</div>
						<div class="more-btn" style="width:100%;" @click="loadRepairListMore" > {{repairLoadMoreText}} </div>
					</div>
					<div v-else>
						<van-empty image="error" description="暂无数据" />
					</div>
				</van-pull-refresh>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp
	} from "../../api";
	export default {
		props: {},
		inject: ["reload"],
		data() {
			return {
				tabsActive: 0,
				refresh: false,

				// 请求页面数据所用到的form
				filterForm: {
					important: '0,1',
					searchValue: '',
					page: 1,
					limit: 15
				},
				list: null,

				// 请求师傅的维修单所用到的form
				repairForm: {
					page: 1,
					limit: 15,
					enterprise_mobile: ''
				},
				repairList: null,
				repairListShow: false,
				repairListLoadingStatus: false,
				repairLoadMoreText : '加载更多>>',

			};
		},
		computed: {},
		created() {
			this.getList();
		},
		mounted() {},
		watch: {},
		methods: {
			/**
			 * 下拉刷新
			 */
			onRefresh() {
				this.refresh = true;
				this.filterForm.page = 1;
				this.list = null;
				this.getList();
			},
			/**
			 * 检测订单列表的下拉刷新
			 */
			onRepairListRefresh() {
				this.repairListLoadingStatus = true;
				this.repairForm.page = 1;
				this.repairList = null;
				this.repairLoadMoreText 	=	'加载更多>>';
				this.openRepairList();
			},
			/**
			 * tab切换时
			 * @param {Object} e
			 */
			tabsBeforeChange(e) {
				this.filterForm.page = 1;
				this.list = null;
				if (e == 0) {
					this.filterForm.important = '0,1';
				} else {
					this.filterForm.important = '1';
				}
				this.getList();
				this.tabsActive = e;
			},
			/**
			 * 获取数据
			 */
			getList() {
				var _this = this;
				indexOp("/enterprise_user/index", this.filterForm).then((res) => {
					_this.refresh = false;
					if (res && res.status == 200) {
						if(_this.filterForm.page 	==	1){
							_this.list 	=	res.data;
						}else{
							var tmpData 	=	_this.list.data;
							for(var i in res.data.data){
								tmpData.push(res.data.data[i]);
							}
							res.data.data 	=	tmpData;
							_this.list.data = tmpData;
						}
					}
				});
			},
			/**
			 * 删除用户 （废除）
			 * @param {Object} id
			 */
			onDelete(id) {
				var _this = this;
				var api = "/enterprise_user/destroy";
				var data = {
					id: id,
				};
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认进行删除操作？",
						confirmButtonText: "确认",
						cancelButtonText: "取消",
					})
					.then((res) => {
						_this.$toast.loading({
							message: "",
							forbidClick: true,
						});
						indexOp(api, data).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("修改成功！");
								setTimeout(() => {
									_this.reload();
								}, 500);
							}
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			/**
			 * 编辑用户（废除）
			 * @param {Object} id
			 */
			toEdit(id) {
				this.$router.push({
					path: "/userManageEdit",
					query: {
						id: id,
					},
				});
			},
			/**
			 * 返回上级
			 */
			toBack() {
				this.$router.go(-1);
			},
			/**
			 * 数据同步更新
			 */
			dataAsyncUpdate() {
				var _this = this;
				_this.$dialog.confirm({
						title: '操作确认',
						message: '是否同步最新的用户数据？如从未同步过，将耗费一段时间来处理数据！',
					})
					.then(() => {
						addOp("/enterprise_user/dataAsyncUpdate").then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success('数据同步完成');
								_this.getList();
								return;
							}
							_this.$toast.fail('操作失败');
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			/**
			 * 设置星标用户
			 */
			toggleImportant(item, index) {
				var _this = this,
					important = item.important == '1' ? '0' : '1',
					message = '是否将此用户设置为重要用户？';

				if (important == '0') {
					message = '是否设置为此用户为不重要？';
				}

				_this.$dialog.confirm({
						title: '操作确认',
						message: message,
					})
					.then(() => {
						addOp("/enterprise_user/update", {
							id: item.id,
							important: important
						}).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success('操作成功');
								_this.list.data[index].important = important;
								if (_this.tabsActive == '1' && important != '1') {
									_this.list.splice(index, 1);
								}
								return;
							}

							_this.$toast.fail('操作失败');
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			/**
			 * 查看更多
			 */
			more() {
				if (this.filterForm.page == this.list.last_page) {
					this.$toast.fail('已经到底了');
					return false;
				}
				this.filterForm.page++;
				this.getList();
			},
			/**
			 * 打开维修单列表
			 */
			openRepairList(item = null) {
				var _this = this;

				if (item && _this.repairForm.page == 1) {
					_this.repairForm.enterprise_mobile = item.mobile;
				}
				
				if(item && item.mobile != _this.repairForm.enterprise_mobile){
					_this.repairList 	=	null;
					_this.repairForm.page 	=	1;
				}
				
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				// 请求数据
				indexOp('/service_order/index', _this.repairForm).then(function(res) {
					_this.$toast.clear();
					_this.repairListLoadingStatus = false;
					if (res && res.status == 200) {
						if(!res.data.data.length){
							return false;
						}
						if (_this.repairForm.page == 1) {
							_this.repairList = res.data;
						} else {
							var tmpData 	=	_this.repairList.data;
							for(var i in res.data.data){
								tmpData.push(res.data.data[i]);
							}
							// 更换其他信息
							res.data.data 	=	tmpData;
							_this.repairList 	=	res.data;
						}
						if (!_this.repairListShow) {
							_this.repairListShow = true;
						}
						return;
					}

					_this.$toast.error('数据获取失败');

				});

			},
			/**
			 * 去订单详情
			 * @param {Object} order
			 */
			toRepairDetail(order) {
				var _this = this;
				var uid = "";
				var identity = _this.$cookies.get("identity");
				if (_this.$cookies.isKey("userInfo")) {
					uid = _this.$cookies.get("userInfo").id;
				}
				_this.repairListShow = false;
				_this.$dialog.confirm({
						title: '操作确认',
						message: '是否确定查看并跳转至维修单详情？',
					})
					.then(() => {
						if (_this.$cookies.isKey("userInfo") && identity == 2) {
							if (order.uid == uid) {
								_this.$router.push({
									path: "/userRepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							} else if (order.service_id == uid) {
								_this.$router.push({
									path: "/shifuRepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							} else {
								_this.$router.push({
									path: "/RepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							}
						} else {
							if (order.service_id == uid) {
								_this.$router.push({
									path: "/shifuRepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							} else if (order.uid == uid) {
								_this.$router.push({
									path: "/userRepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							} else {
								_this.$router.push({
									path: "/RepairDetail",
									query: {
										order_no: order.order_no,
									},
								});
							}
						}
					})
					.catch(() => {
						// on cancel
						_this.repairListShow = true;
					});

			},
			/**
			 * 去店铺详情
			 * @param {Object} uid
			 */
			toShopDetail(uid) {
				var _this = this;
				_this.repairListShow = false;
				_this.$dialog.confirm({
						title: '操作确认',
						message: '是否确定查看并跳转至店铺详情？',
					})
					.then(() => {
						_this.$router.push({
							path: "/shifuShopDetail",
							query: {
								uid: uid,
							},
						});
					}).catch(() => {
						_this.repairListShow = true;
					});
			},
			/**
			 * 加载更多订单数据
			 */
			loadRepairListMore(){
				if(this.repairForm.page >= this.repairList.last_page ){
					this.repairLoadMoreText 	=	'暂无更多数据!';
					return false;
				}
				this.repairForm.page++;
				this.openRepairList();
			}
		},
		components: {},
	};
</script>

<style lang="less" scoped>
	@ThemColor: #2F78DC;
	@textColor: #2c2c2c;

	.more-btn {
		width: 100%;
		display: block;
		margin: 0 auto;
		color: #666;
		font-size: 16px;
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;
		text-decoration: none;
		background: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.repair-container {
		width: 100vw;
		height: 85vh;

		.repair-title {
			height: 7vh;
			width: 100%;
			background: @ThemColor;
			color: #fff;
			font-weight: bold;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	/**
	 * 此处CSS 后期单独拉出来
	 */
	.list {
		height: calc(85vh - 7vh);
		overflow: hidden;
		overflow-y: scroll;

		.more-btn {
			width: 100px;
			display: block;
			margin: 0 auto;
			color: #666;
			font-size: 16px;
			text-align: center;
			padding-top: 15px;
			text-decoration: none;
		}

		.item {
			padding: 15px 0;
			position: relative;
			border-bottom: 1px solid #f1f1f1;

			.flex {
				display: flex;
			}

			.service-avatar {
				margin-top: 10px;
				display: flex;
				align-items: center;
				font-size: 10px;

				span {
					width: 20%;
					margin-right: 10px;
					text-align: center;
				}

				img {
					width: 24px;
					height: 24px;
					border-radius: 4px;
					margin-right: 5px;
					object-fit: cover;
				}
			}

			.status {
				position: absolute;
				background: #feea3b;
				top: 0;
				right: 0;
				font-size: 10px;
				padding: 4px 10px;
				color: @textColor;
			}

			.left-box {
				width: 20%;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				align-items: center;
				margin-right: 10px;

				.tag {
					font-size: 11px;
					line-height: 1;
					display: flex;
					align-items: center;
					padding: 4px 4px;
				}

				.avatar {
					width: 50px;
					height: 50px;
					object-fit: cover;
					border-radius: 50%;
					margin-bottom: 5px;
				}

				.name {
					width: 100%;
					font-size: 12px;
					margin-bottom: 5px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}

			.right-box {
				width: 75%;

				.title {
					width: 80%;
					font-size: 14px;
					color: #2C2C2D;
					margin-bottom: 8px;
					font-weight: bold;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.describe {
					width: 80%;
					font-size: 14px;
					color: #666;
					margin-bottom: 8px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.time {
					font-size: 14px;
					color: #666;
					margin-bottom: 8px;
				}

				.position {
					font-size: 14px;
					color: #666;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					i {
						font-size: 14px;
						margin-right: 5px;
					}
				}
			}
		}
	}
</style>