<template>
	<div id="video-fullscreen" :class="status ? '' : 'none'">
		<div class="video-fullscreen-swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item ,index) in fullscreenVideoList" @click="onVideoClick"
					@pause="onVideoPause" @play="onVideoPlay" :data-id="item.id">
					<video width="100%" loop @error="onVideoError" @timeupdate="onVideoTimeUpdate" @abort="onVideoError">
						<source :src="url + item.video_url" type="video/mp4">
						</source>
						当前浏览器不支持 video直接播放，点击这里下载视频: <a href="myvideo.webm">下载视频</a>
					</video>
				</div>
			</div>
			<!-- 如果需要分页器 -->
			<div class="swiper-pagination"></div>

			<!-- 如果需要滚动条 -->
			<div class="swiper-scrollbar"></div>
		</div>

		<!-- 点赞评论操作栏 -->
		<div class="video-opera-bar">
			<template v-if="likeStatus">
				<div class="like" @click="unLikeVideo">
					<img src="@/assets/image/video_fullscreen_liked.png">
					<p>{{likeCount}}</p>
				</div>
			</template>
			<template v-else>
				<div class="like" @click="likeVideo">
					<img src="@/assets/image/video_fullscreen_like.png">
					<p>{{likeCount}}</p>
				</div>
			</template>
			<div class="comment" @click="openCommentContainer">
				<img src="@/assets/image/video_fullscreen_comment.png">
				<p>{{commentCount}}</p>
			</div>
			<div class="share" @click="openShareContainer">
				<img src="@/assets/image/video_fullscreen_share.png">
			</div>
		</div>

		<!-- 暂停按钮 -->
		<!-- <div class="pause-icon">
			<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAqFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8j1z1tAAAAN3RSTlMAQNK8DcOWZ0vn34diXCAX9NbNyqyRd1UzBKOMgn5y+O2ybVFFOy4m8ePbnlk4HBK3tKegmyoIhEGUsgAAAxpJREFUeNrs2llOQkEQheG6iKg4gDIqCKKoDE7gcPa/M+0YEoTm0hdIrFPpbwf/U590SqIoiqIoiqIoipRKkuRF+J3s4cdwcnop1AqYOb/OC68x5pwfCKsG/to7FE4VLLpqCqEEy7rHJaFTgE/xVthU4Ld/J1yKWKX6LEzKWK12JjyQ5qjO89Yj3cWpkMA6vUQoYL3CoxBAgBuGLYkguRPRDquwbUkEe38QzRCurHpLIouR4i2JFei2JLKaKt2SyK6l8ucIGxh2FG5JbKTyJNrAj29Lwo9vS8KPb0vCj29LYjtvfVEC2xor2ZLYWrn9KgpgB0YavvDhx7clsSPTpmSiNuTftyR2Z9iRcJpDMm1J3SFA9V7CaA8BCmcSQn8Ijupfsh5BSNiWpAgJ2ZIkIcDHp6SiCcEgfUvyhKw5B2EKSf3C5wpJOQdhC0G3VRIfuhCg2BEPwhBgvyFLKEN8W5I0ZPkchDZkcUvyhiycgzCHAJO+zHCHzG1J9hAM2nlx6ENmW9JAyO+WNBHitqSREHdabCPEnRYbCXFb0kiI25JGQtyWNBLiTotthLgtaSQE6CVGQtw5iJEQdw5iI8R94RsJcVvSSIg7LTYSgvJx3kYIkEuMhAAdKyFoWAlBw0rIqGQkBDUrITkrIQMrId/t3TEOwjAQRNGYJoIoEopEA9wAEKLi/jeDLlVSRvbLzA228np39s9JKeSqFNIjhUzKOzIihQxIi/IwmsbDk2jjz8Ob+FghX11k+ICMg5QBHTIyRYbYyFpBWfQgqzdkGYqspxHDgGLhQEw1iM0JMZ4pVkDEnInYZREDs2IpR0z+yNkFcgiDnCYpx2LI+R5yUImcuCpHx8gZOHKYj6ASFHgFghNBAC8IckeBICFYKgQUhqDbEJiegjdEgJMIAhSBsiqYXARcjKCkEbi3gltHAPhIJEEF3WFiO7ggFSTaBgkbqqk7TCBXbd3hzkPrkBjBloIdmahNJvyUiaO9KQHBLyWymQnRdmLNmaD5rrvMZdwb6A5X1E/fv46fsZ13fEmllJZe8SiKoiiKoiiKou31A+YRxoG6+Cu0AAAAAElFTkSuQmCC" alt="">
		</div> -->

		<!-- 关闭按钮 -->
		<div class="videopage-close" @click="closePage">
			<img src="@/assets/image/video_fullscreen_close.png" alt="">
		</div>

		<!-- 评论区域 -->
		<van-popup v-model="commentShowStatus" round position="bottom" :style="{height:'60vh'}">
			<div class="comment-list-container">
				<div class="comment-title">该视频的评论</div>
				<template v-if="commentList && commentList.length">
					<div class="comment-list" v-for="(item,index) in commentList">
						<div class="comment-cell">
							<div class="user">
								<img :src="item.userInfo.avatar" alt="">
								<span>{{ item.userInfo.nickname }}</span>
							</div>
							<div class="content">
								{{item.content}}
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<van-empty image="network" description="暂无评论" />
				</template>
			</div>
			<div class="comment-editor-container">
				<input type="text" placeholder="点击输入" :value="commentText" @change="commentInputChange">
				<button type="button" @click="commentSubmit">提交</button>
			</div>
		</van-popup>

		<!-- 分享面板 -->
		<van-share-sheet v-model="shareStatus" title="立即分享给好友" :options="shareOptions" @select="shareSelect" />

		<!-- 进度条 -->
		<div class="video-progress">

		</div>

	</div>
</template>

<script>
	import Swiper from 'swiper';
	import base from "../api/base";
	import $ from "jquery";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../api/index";

	export default {
		name: 'VideoFullScreen',
		props: {
			status: {
				type: Boolean
			},
			videoList: {
				type: Array
			},
			close: {
				type: Function
			},
			slideCurrent : {
				type : Number
			}
		},
		data() {
			return {
				// api url
				url: base.apiURL,
				// 当前Current dom实例
				currentVideo: null,
				// 当前 视频ID
				currentVideoId: 0,
				// 视频列表
				fullscreenVideoList: [],
				// 存储swiper
				swiper: null,
				// 评论面板状态
				commentShowStatus: false,
				// 评论列表
				commentList: [],
				// 当前评论页
				commentCurrentPage: 1,
				// 评论统计
				commentCount: 0,
				// 评论文字
				commentText: '',
				commentPid: 0,
				commentTopId: 0,
				// 分享面板状态
				shareStatus: false,
				shareOptions: [{
					name: '复制链接',
					icon: 'link',
					opera : 'copyLink'
				}],
				// 当前视频播放进度
				vprogress: 0,
				// 当前视频总时长
				totalTime: 0,
				// 当前播放了多少时长
				currentTime: 0,
				// Yetid（视频过滤ID）
				yetid: '',
				// 当前视频喜欢状态
				likeStatus: false,
				// 喜欢的次数
				likeCount: 0,
			}
		},
		computed: {

		},
		created() {},
		mounted() {
			// 初始化
			this.initSwiper();
		},
		updated() {
			
		},
		watch: {
			// 监控状态
			status: function(status) {
				this.pageStatus = status;
				// 证明页面关闭了
				if (!status) {
					// 暂停视频
					this.videoPause();
					// 清空播放列表
					this.fullscreenVideoList = [];
					// Yetid
					that.yetid 	=	'';
					this.offCurrentVideo();
				}else{
					this.videoPlay();
				}
			},
			// 视频列表
			videoList: function(list) {
				var clist 	=	list;
				// 赋值到播放列表
				this.fullscreenVideoList = clist;
				// 计算yetid
				var yetid = [];
				for (var i in list) {
					yetid.push(list[i]['id']);
				}
				this.yetid = yetid.join(',');
				if (list.length <= 1) {
					this.getNextVideo();
				}
				// 有变化解除video绑定
				this.offCurrentVideo();
				// 重新绑定
				this.initCurrentVideo();
			},
			slideCurrent : function(id){
				// if(this.currentVideoId != id){
				// 	this.offCurrentVideo();
				// }
			}
		},
		methods: {
			/**
			 * 初始化一下Swiper
			 */
			initSwiper: function() {
				var that = this;
				this.swiper = new Swiper('.video-fullscreen-swiper', {
					direction: 'vertical', // 垂直切换选项
					loop: false, // 循环模式选项
					autoplay: 1000,
					observer: true,
					observeParents: true,
					effect: 'flip',
					// 如果需要分页器
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					// 如果需要滚动条
					scrollbar: {
						el: '.swiper-scrollbar',
					},
					// 一些事件
					on: {
						init: function() {
							// 初始化当前视频
							that.initCurrentVideo();
						},
						// 滑动前
						slideChangeTransitionStart: function(swiper) {
							that.getNextVideo();
						},
						// 滑动过去之后
						slideChangeTransitionEnd: function(swiper) {
							that.offCurrentVideo();
							that.initCurrentVideo();
						},
						// 滑动结束
						touchEnd: function(swiper, event) {},
					}
				})
			},
			/**
			 * 初始化当前视频
			 */
			initCurrentVideo: function(callback) {
				var that = this;
				// 初始化当前的视频
				var s = setInterval(function() {
					var len = $('.swiper-slide-active').length;
					if (len) {
						clearInterval(s);
						// 赋值当前视频实例
						that.currentVideo = $($('.swiper-slide-active video').eq(0))[0];
						// 监控视频加载完毕
						$(that.currentVideo).on('loadedmetadata', that.onVideoLoadedmetadata);
						// 监控视频开始播放
						$(that.currentVideo).on('play', that.onVideoPlay);
						// 监控视频播放时长
						$(that.currentVideo).on('timeupdate', that.onVideoTimeUpdate);
						// 
						$(that.currentVideo).on('abort', that.onVideoError);
						// 监控视频暂停
						$(that.currentVideo).on('pause', that.onVideoPause);
						if (typeof callback == 'function') {
							callback();
						}
						// 直接播放
						that.videoPlay();
						// 获取ID
						that.currentVideoId = $('.swiper-slide-active').attr('data-id');
						// 获取是否喜欢此视频
						that.getIsLike();
						// 获取该视频喜欢数量
						that.getLikeCount();
						// 获取该视频的评论列表
						that.getCommentList();
						// 获取该视频的评论数量
						that.getCommentCount();
					}
				}, 1);

			},
			/**
			 * 解除video绑定
			 */
			offCurrentVideo: function() {
				// 先暂停
				this.videoPause();
				// 解除时间绑定
				$(this.currentVideo).off('timeupdate');
				// 解除播放绑定
				$(this.currentVideo).off('play');
				// 解除暂停绑定
				$(this.currentVideo).off('pause');
				// 清空实例
				this.currentVideo = null;
				// 清空点赞状态
				this.likeStatus = false;
				// 清空视频绑定ID
				this.currentVideoId = 0;
				// 清空评论列表
				this.commentList 	=	[];
				// 清空评论Top / pid
				this.commentTopId 	=	0;
				this.commentPid 	=	0;
				// 清空评论页
				this.commentCurrentPage 	=	1;
				// 清空评论数量
				this.commentCount 	=	0;
			},
			/**
			 * 关闭页面
			 */
			closePage: function() {
				this.fullscreenVideoList = null;
				this.status = false;
				this.close();
			},
			// ----------- 视频区域 ----------------
			/**
			 * 清空一下视频
			 */
			clearVideo: function() {
				this.$emit('clearVideoFullscreenData');
			},
			/**
			 * 获取现在播放的视频状态
			 */
			getThisVideoPlayStatus: function() {
				return !this.currentVideo.paused;
			},
			/**
			 * 视频播放
			 */
			videoPlay: function() {
				this.currentVideo.play();
			},
			/**
			 * 视频暂停
			 */
			videoPause: function() {
				this.currentVideo.pause();
			},
			/**
			 * 视频点击
			 */
			onVideoClick: function(e) {
				var playStatus = this.getThisVideoPlayStatus();
				// 如果播放，则停止
				if (playStatus) {
					this.videoPause();
				} else {
					this.videoPlay();
				}
			},
			/**
			 * 视频播放的回调
			 */
			onVideoPlay: function(e) {
				console.log('videoPlay', e);
			},
			/**
			 * 视频加载的回调
			 */
			onVideoError:function(e){
				console.log('videoError');
			},
			/**
			 * 视频加载完毕的回调
			 */
			onVideoLoadedmetadata: function(e) {
				this.totalTime = e.currentTarget.duration;
			},
			/**
			 * 视频暂停的回调
			 */
			onVideoPause: function(e) {
				console.log('videoPause', e);
			},
			/**
			 *  监控视频播放时长
			 */
			onVideoTimeUpdate: function(e) {
				this.crrentTime = e.currentTarget.currentTime;
				// 计算当前播放进度
				this.vprogress = Math.ceil((this.crrentTime / this.totalTime) * 100);
			},
			/**
			 * 监控视频加载中
			 */
			onVideoWaiting: function(e) {
				console.log('waiting', e);
			},
			// ---------- 评论区域 ------------
			/**
			 * 打开/显示评论框
			 */
			openCommentContainer: function() {
				this.commentShowStatus = true;
			},
			/**
			 * 关闭评论框
			 */
			closeCommentContainer: function() {
				this.commentShowStatus = false;
			},
			// ---------- 分享区域 ------------
			/**
			 * 打开分享面板
			 */
			openShareContainer: function() {
				this.shareStatus = true;
			},
			// 关闭分享面板
			closeShareContainer: function() {
				this.shareStatus = false;
			},
			/**
			 * 分享点击了
			 */
			shareSelect:function(e){
				switch(e.opera){
					case 'copyLink':
						var list 	=	this.fullscreenVideoList,
							id 	=	this.currentVideoId;
						for(var i in list){
							
						}
						this.$toast('视频链接复制成功!');
					break;
				}
			},
			/**
			 * 获取下一个视频
			 */
			getNextVideo: function() {
				var that = this,
					yetid = this.yetid;

				indexOp('/video/next', {
					yetid: yetid
				}).then((res) => {
					if (res && res.status == 200) {
						if (!res.data.error) {
							var len = that.fullscreenVideoList.length;
							var cyet = that.yetid + ',' + res.data.id;
							that.fullscreenVideoList.push(res.data);
							that.yetid = cyet;
						}
					}
				});
			},
			// ----------- Like -------------------
			/**
			 * 获取是否喜欢此视频
			 */
			getIsLike: function() {
				var that = this;
				indexOp('/video_like/is_like', {
					video_id: that.currentVideoId
				}).then((res) => {
					if (res && res.status == 200) {
						if (!res.data.error) {
							that.likeStatus = true;
						}
					}
					// 再次统计喜欢数量
					that.getLikeCount();
				});
			},
			/**
			 * 喜欢视频
			 */
			likeVideo: function() {
				var that = this;
				
				indexOp('/video_like/like', {
					video_id: that.currentVideoId
				}).then((res) => {
					res 	=	res.data;
					if(res.error){
						that.likeStatus 	=	false;
					}else{
						that.likeStatus 	=	true;
					}
					// 再次统计喜欢数量
					that.getLikeCount();
				});
			},
			/**
			 * 取消喜欢视频
			 */
			unLikeVideo: function() {
				var that 	=	this;
				
				indexOp('/video_like/un_like', {
					video_id: that.currentVideoId
				}).then((res) => {
					if (res && res.status == 200) {
						if (!res.data.error) {
							that.likeStatus = false;
						}
					}
				});
			},
			/**
			 * 点赞量统计
			 */
			getLikeCount: function() {
				var that 	=	this;
				indexOp('/video_like/count', {
					video_id: that.currentVideoId
				}).then((res) => {
					if (res && res.status == 200) {
						if (!res.data.error) {
							that.likeCount = res.data;
						}
					}
				});
			},
			// --------------- 评论 ----------------
			/**
			 * 获取评论统计
			 */
			getCommentCount: function() {
				var that 	=	this;
				indexOp('/video_comment/count', {
					video_id: that.currentVideoId
				}).then((res) => {
					if(res.status != 200){
						that.commentCount 	=	0;
						return false;
					}
					res 	=	res.data;
					if(res.error){
						that.commentCount 	=	0;
					}else{
						that.commentCount 	=	res;
					}
				});
			},
			/**
			 * 获取评论列表
			 */
			getCommentList: function() {
				var that = this;
				indexOp('/video_comment/get', {
					video_id: that.currentVideoId,
					page: that.commentCurrentPage
				}).then((res) => {
					res 	=	res.data;
					if(res.error){
						that.$toast(res.error);
						return false;
					}
					if(that.commentCurrentPage == 1){
						that.commentList 	=	res.data;
					}else{
						for(var i in res.data){
							that.commentList.push(res.data[i]);
						}
					}
				});
			},
			/**
			 * 提交评论
			 */
			commentSubmit: function() {
				var that = this;
				if (!this.commentText) {
					this.$toast('请先输入内容');
					return false;
				}

				var data = {
					video_id: this.currentVideoId,
					pid: this.commentPid,
					top_id: this.commentTopId,
					content: this.commentText
				}

				indexOp('/video_comment/create', data).then((res) => {
					res 	=	res.data;
					if(res.error){
						that.$toast.fail(res.error);
						return false;
					}
					that.$toast.success('评论成功!');
					that.getCommentList();
					that.commentText =	'';
				});

			},
			/**
			 * 评论文字发生变动
			 * @param {Object} e
			 */
			commentInputChange: function(e) {
				this.commentText += e.target.value;
			}
		},
		components: {}
	}
</script>

<style>
	.none {
		display: none;
	}

	/**
	 * 视频全屏区域
	 */
	#video-fullscreen {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		/* Zindex大于导航栏 */
		z-index: 99;
		background-color: #000;
	}

	.video-fullscreen-swiper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.pause-icon {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
	}

	.pause-icon img {
		width: 40%;
		opacity: 0.3;
		pointer-events: none;
	}

	.video-opera-bar {
		width: 15%;
		height: 50vh;
		position: fixed;
		right: 0;
		bottom: 0;
		z-index: 101;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		color: #fff;
		padding: 10px;
	}

	.video-opera-bar img {
		width: 100%;
		box-sizing: border-box;
	}

	.video-opera-bar div {
		margin-bottom: 15px;
	}

	.videopage-close {
		width: 30px;
		height: 30px;
		position: fixed;
		right: 10px;
		top: 20px;
		z-index: 200;
	}

	.videopage-close img {
		width: 100%;
		height: 100%;
	}

	/* 评论区域 */
	.comment-list-container {
		height: calc(100% - 30px);
		width: 100%;
	}

	.comment-editor-container {
		height: 30px;
		width: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		position:fixed;
		bottom:8px;
		left:0;
	}

	.comment-editor-container input {
		height: 35px;
		width: 80%;
		/* margin: 0 auto; */
		/* border-radius: 10px; */
		text-align: center;
		box-sizing: border-box;
		border: 1px solid #2F78DC;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	.comment-editor-container button {
		height: 35px;
		width: 50px;
		background-color: #2F78DC;
		color: #fff;
		text-align: center;
		line-height: 35px;
		border: 0;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.comment-title {
		text-align: center;
		/* margin-top: 8px; */
		color: #323233;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		padding: 10px;
		box-sizing: border-box;
		font-size: 15px;
	}

	.comment-cell {
		height: auto;
		padding-left: 16px;
		padding-right: 16px;
		box-sizing: border-box;
		border-bottom: 1px solid #D9D9D9;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.comment-cell .user {
		height: 30px;
		line-height: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.comment-cell .user img {
		height: 30px;
		width: 30px;
		border-radius: 50%;
	}

	.comment-cell .user span {
		margin-left: 10px;
	}

	.comment-cell .content {
		line-height: 30px;
		width: 100%;
		padding: 5px;
		box-sizing: border-box;
	}

	/* 视频进度条 */
	.video-progress {
		position: fixed;
		bottom: 10px;
		left: 0;
		width: 100vw;
		z-index: 100;
	}
	
	/*  */
	..swiper-slide {
		display: flex;
	}
</style>
