<template>
  <div class="upload-video peijian">
    <Navbar :title="'添加产品'" textColor="#DDAE88"></Navbar>
    <div class="title">产品分类</div>

    <div class="input-item" style="display: flex; align-items: center">
      <van-field
        v-model="classValue"
        autosize
        readonly
        clickable
        type="text"
        placeholder="请选择一级分类"
        @click="showPicker = true"
      />
      <span style="padding: 0 10px">-</span>
      <van-field
        v-model="classValueTwo"
        autosize
        readonly
        clickable
        type="text"
        placeholder="请选择二级分类"
        @click="onShowPickTwo"
      />

      <!-- 一级分类 -->
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          ref="classPicker"
          show-toolbar
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
          value-key="name"
        />
      </van-popup>
      <!-- 二级分类 -->
      <van-popup v-model="showPickerTwo" round position="bottom">
        <van-picker
          ref="classPickerTwo"
          show-toolbar
          :columns="columnsTwo"
          @cancel="showPickerTwo = false"
          @confirm="onConfirmTwo"
          value-key="name"
        />
      </van-popup>
    </div>
    <div class="title">产品名称</div>
    <div class="input-item">
      <van-field
        v-model="formData.goods_name"
        autosize
        type="text"
        placeholder="请输入产品名称"
      />
    </div>
    <div class="title">产品价格</div>
    <div class="input-item">
      <van-field
        v-model="formData.price"
        autosize
        type="text"
        placeholder="请输入产品名称"
      />
    </div>
    <div class="title">产品数量</div>
    <div class="input-item">
      <van-field
        v-model="formData.amount"
        autosize
        type="number"
        placeholder="请输入产品名称"
      />
    </div>
    <div class="title">产品图片</div>
    <div class="upload-box">
      <van-uploader
        v-model="fileList"
        :after-read="uploadVideo"
        upload-text="请选择您要上传的产品图片"
        upload-icon="photo"
        max-count="1"
        accept="image/*"
      />
    </div>
    <div class="title">技术介绍</div>
    <div class="editor-box">
      <editor @editor-input="onEditorChange"></editor>
    </div>
    <div class="btn-box">
      <button class="btn" @click="onSubmit">提交</button>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar";
import Tabber from "../../components/Tabber";
import {
  indexOp,
  updateOp,
  deleteOp,
  addOp,
  showOp,
  uploadImg,
  uploadFile,
} from "../../api/index";
import base from "../../api/base";
import { wechatConfig } from "../../utils/wxConfig";
import { getUser, login } from "../../utils/login_toast";
import { loginAlert } from "../../utils/loginAlert";

import Editor from "../../components/editor";
export default {
  components: {
    Navbar,
    Tabber,
    Editor,
  },
  props: {},
  data() {
    return {
      fileList: [],
      formData: {},
      userInfo: "",
      htmlValue: "",
      showPicker: false,
      showPickerTwo: false,
      columns: [],
      columnsTwo: [],
      classValue: "",
      classValueTwo: "",
      id: "", //厂商id
      cid: "", //分类id

      pid: 0,
    };
  },
  computed: {},
  created() {
    this.userInfo = this.$cookies.get("userInfo");
    var _this = this;
    console.log("用户");
    // this.userInfo = this.$cookies.get("userInfo");
    this.id = this.$route.query.id;
    var code = _this.getUrlKey("code");
    var state = _this.getUrlKey("state");
    var token = _this.$cookies.get("Authorization");
    if (!token && !code) {
      loginAlert();
      return;
    } else if (!token && code) {
      login(this.getLoginUser);
      return;
    }
    this.getClassList();
  },
  mounted() {},
  watch: {},
  methods: {
    //显示二级分类选择器
    onShowPickTwo() {
      const _this = this;
      if (_this.pid <= 0) {
        _this.$toast("请先选择一级分类！");
        return;
      }
      var data = {
        pid: _this.pid,
      };
      indexOp("/goods_type/get_list", data).then((res) => {
        if (res && res.status == 200 && res.data.length > 0) {
          _this.columnsTwo = res.data;
          this.showPickerTwo = true;
        }
      });
    },
    //选择二级分类
    onConfirmTwo(value) {
      var val = this.$refs.classPicker.getValues();
      this.classValueTwo = value.name;
      this.cid = value.id;
      this.showPickerTwo = false;
    },
    //选择一级分类
    onConfirm(value) {
      console.log(value);
      var val = this.$refs.classPicker.getValues();
      this.classValue = value.name;
      this.pid = value.id;
      this.showPicker = false;
    },
    //获取一级品分类
    getClassList() {
      var _this = this;
      var data = {
        pid: 0,
      };
      indexOp("/goods_type/get_list", data).then((res) => {
        if (res && res.status == 200 && res.data.length > 0) {
          _this.columns = res.data;
        }
        console.log(_this.columns);
      });
    },
    onEditorChange(value) {
      this.htmlValue = value;
      console.log(value);
    },
    uploadVideo(file) {
      var _this = this;
      console.log(file);
      var form_data = new FormData();
      form_data.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      uploadImg(form_data).then((res) => {
        if (res && res.status == 200) {
          file.status = "success";
          file.message = "";
          console.log(res);
          _this.formData.image = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
      console.log(file);
    },
    onSubmit() {
      var _this = this;
      if (_this.cid == "") {
        _this.$toast.fail("请选择产品所在分类！");
        return;
      }
      if (_this.formData.goods_name == undefined) {
        _this.$toast.fail("产品名称不能为空！");
        return;
      }
      if (_this.formData.price == undefined) {
        _this.$toast.fail("产品价格不能为空！");
        return;
      }
      if (_this.formData.amount == undefined) {
        _this.$toast.fail("产品数量不能为空！");
        return;
      }
      if (_this.htmlValue == "") {
        _this.$toast.fail("产品信息不能为空！");
        return;
      }
      if (_this.formData.image == undefined) {
        _this.$toast.fail("请选择要上传的产品图片！");
        return;
      }
      _this.formData.cid = _this.cid;
      _this.formData.content = _this.htmlValue;

      _this.$toast.loading({
        message: "",
        forbidClick: true,
      });
      addOp("/goods/store", _this.formData).then((res) => {
        _this.$toast.clear();
        if (res && res.status == 201) {
          _this.$toast.success("上传成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 800);
        }
        console.log(res);
      });
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>

<style scoped >
</style>
