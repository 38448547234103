import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import global from "./global/global"

Vue.prototype.global = global

import Vant from 'vant';
import 'vant/lib/index.css';

import './assets/css/style.less'
Vue.use(Vant);
import {
	Swipe,
	SwipeItem
} from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);

import {
	Toast
} from 'vant';
import {
	Dialog
} from 'vant';
import {
	Popover
} from 'vant';

Vue.use(Popover);
Vue.prototype.$Dialog = Dialog
Vue.prototype.$toast = Toast

import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies
import './utils/login'


import http from 'axios'
Vue.prototype.$http = http

import * as socketApi from './utils/webSocket'
Vue.prototype.socketApi = socketApi
import {
	VueJsonp
} from 'vue-jsonp'
// Vue.prototype.$json = VueJsonp
Vue.use(VueJsonp)
import "./utils/wxConfig"
// import wx from 'weixin-js-sdk'
// Vue.prototype.$wx = wx

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

// Vue.use(VueAwesomeSwiper)

import notice from "./components/notice"
Vue.use(notice)

// 引入swiper
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
Vue.use(vueSwiper)

// 引入animate
import animated from 'animate.css'
Vue.use(animated)

// 引入弹入层组件
import {
	Popup
} from 'vant';
Vue.use(Popup);

// 引入列表组件
import {
	List
} from 'vant';
Vue.use(List);

// 引入分享面板
import {
	ShareSheet
} from 'vant';
Vue.use(ShareSheet);

// 引入进度条
import {
	Progress
} from 'vant';
Vue.use(Progress);

// 引入空状态
import {
	Empty
} from 'vant';
Vue.use(Empty);

// 引入复制粘贴
import Clipboard from 'clipboard';
Vue.prototype.$clipboard = Clipboard;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
