
import { Toast,Dialog,Notify } from 'vant';
import { indexOp, uploadImg, updateOp, addOp } from "../api";
var jsApiParameters;
export function getVue(vue){
    console.log(vue)
    return vue;
}
export function payAccount(needMoney,Account){
    const need_money = needMoney;   //需支付金额
    const account = Account;        //账户余额
    const d_money = (need_money * 1000 - account * 1000)/1000
    console.log(need_money,account,d_money)
    Dialog.confirm({
        title: "提示",
        message: "您的余额不足，还差" + d_money + "元，请先充值。",
        confirmButtonText: "微信充值",
    })
    .then(() => {
        var data = {
            money:d_money
        }
        addOp("/bill_order/user_account_recharge", data).then((res) => {
            if (res && res.status == 200 && res.data.state == 1000) {
              jsApiParameters = res.data.data.pay_param;
              callpay();
            } else {
              Toast.fail(res.data.msg);
            }
          });
    })
    .catch(() => {
        // on cancel
    });
};
//支付
function jsApiCall() {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    jsApiParameters,
    function (res) {
      if (res.err_msg === "get_brand_wcpay_request:ok") {
        // alert("余额充值成功！请再次进行支付操作！");
        Dialog.confirm({
          title: "提示",
          message: "余额充值成功！请再次进行支付操作！",
          confirmButtonText: "确认",
        }).then(()=>{

        })
        .catch(() => {
            // on cancel
        });
      } else if (res.err_msg === "get_brand_wcpay_request:fail") {
        Dialog.confirm({
          title: "提示",
          message: "已取消支付！",
          confirmButtonText: "确认",
        }).then(()=>{

        })
        .catch(() => {
            // on cancel
        });
      }
    }
  );
}

function callpay() {
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener(
        "WeixinJSBridgeReady",
        jsApiCall,
        false
      );
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", jsApiCall);
      document.attachEvent("onWeixinJSBridgeReady", jsApiCall);
    }
  } else {
    jsApiCall();
  }
}