<template>
  <div>
    <van-overlay :show.sync="xieyiShow" :lock-scroll="false" z-index="99999" @click="xieyiShow = false">
      <div class="xieyi-content">
        <div class="title">《维修交易规则》</div>
        <ul>
					<li>
						1、厂商及用户如需维修服务可在平台上查询维修师傅信息、维修部件信息及选定维修师傅与服务等，根据页面提示交易流程双方进行沟通-约谈价格-约定维修日期等相关交易内容。
					</li>
					<li>
						2、在本平台发布维修服务信息，请严格遵守法律法规及社会公德。禁止发布违法虚假诈骗广告信息及二维码和外部链接。
					</li>
					<li>
						3、为保障厂商及用户和师傅各方的利益，各方就维修费用及其他维修事宜应在平台内沟通协商，协商好上门检修费用，厂商或用户要及时支付上门费，然后师傅上门检测，判断设备故障（拍照或视频上传聊天室留证)。如需维修，各方协商好维修费用后厂商或用户付款，师傅进行维修。维修现场需拍照留证，师傅维修完毕，经厂商或用户确认订单维修完成，并对师傅的服务态度及维修技术进行评价。（上门及维修费用在平台冻结48个小时，订单确认完成师傅方可提现）
					</li>
					<li>
						4、特别提示:双方在交易过程中，选择本维修定单中的聊天室进行沟通交流，这样平台会保留记录，若发生相关纠纷可作为凭据以维护各方利益。如双方线下私自交易产生任何纠纷均与本平台无关并取消再次进入本平台交易资格。
					</li>
					<li>
						5、为了平台更好的发展和完善，师傅提现时系统自动扣订单10%的服务费。本平台只是厂商或用户和师傅沟通的桥梁，仅负责监管和留证，不干涉费用及后续保修问题，如厂商及用户与维修师傅之间因此产生的经济纠纷、责任均由双方各自承担，平台只提供交易凭证。
					</li>
					<li>
						6、任何通过本平台进行交易的行为都视为用户已同意以上条款。本平台保留基于用户反馈、流程优化等考虑对交易流程及规则进行修改的权利。
					</li>
        </ul>
        <div class="btns" v-if="xieyiShow">
          <div class="btn" @click="onOk">知道了</div>
        </div>
        <div class="btns" v-else>
          <div class="btn" @click="onhide">知道了</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import base from "../api/base";
export default {
  props: {
    xieyiShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: "",
      url: base.apiURL,
      token: "",
      notice_log: "",
      // xieyiShow:false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.$cookies.isKey("userInfo")) {
      this.userInfo = this.$cookies.get("userInfo");
      console.log(this.userInfo);
      this.token = this.$cookies.get("Authorization");
      this.getNotice();
    }
  },
  watch: {},
  methods: {
    onhide() {
      // this.isShow = !this.isShow
      // this.$emit('show', this.isShow)
      this.$emit("update:xieyiShow", false);
    },
    getNotice() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      var api = _this.url + "/notice_log/show?uid=" + _this.userInfo.id;
      this.$http
        .get(api, {
          headers: {
            Authorization: _this.token,
          },
        })
        .then((res) => {
          if (res && res.status == 200 && res.data) {
            _this.notice_log = res.data;
          } else {
            this.$emit("update:xieyiShow", true);
          }
          console.log(res);
        });
      //   indexOp("/notice_log/show",data).then(res=>{
      //       console.log(res)
      //   })
    },
    onOk() {
      var _this = this;
      var data = {
        uid: _this.userInfo.id,
      };
      var api = _this.url + "/notice_log/create";
      this.$http
        .post(api, data, {
          headers: {
            Authorization: _this.token,
          },
        })
        .then((res) => {
          if (res && res.data) {
            _this.xieyiShow = false
            this.$emit("update:xieyiShow", false);
          }
        });
    },
  },
  components: {},
};
</script>

<style scoped >
.xieyi-content .btns {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.xieyi-content .btns .btn {
  text-align: center;
}
.xieyi-content ul{
  height: 250px;
}
</style>
