<template>
	<!-- 注册 -->
	<div class="chongzhi">
		<Navbar :title="'提现'"></Navbar>
		<div class="top">
			<van-tabs v-model="type" background="#fff" type="card" color="#2F78DC">
				<van-tab title="维修费提现" name="service_account"></van-tab>
				<van-tab title="充值提现" name="user_account"></van-tab>
				<van-tab title="货款提现" name="goods_account"></van-tab>
			</van-tabs>
			<p class="title">提现金额</p>
			<div class="input-box">
				<span>¥</span>
				<input type="text" v-model="money" placeholder="" />
			</div>
			<p class="msg">
				可提现金额：{{ userInfo[type] ? userInfo[type] : 0 }} 元
			</p>
		</div>
		<div class="bottom" style="background:#fff;margin-top:10px;">
			<p class="title">提现方式</p>
			<van-radio-group v-model="radio">
				<van-cell-group>
					<van-cell title="提现至微信" clickable style="padding: 15px 0">
						<template #right-icon>
							<van-radio name="1" />
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
		</div>
		<div class="bottom-box">
			<a href="javascript:;" class="xiadan-btn" @click="pay">提现</a>
		</div>
	</div>
</template>

<script>
	import Tabber from "../components/Tabber";
	import Navbar from "../components/Navbar";
	// import websocket from '../utils/webSocket'
	import {
		indexOp,
		uploadImg,
		updateOp,
		addOp,
		uploadFile
	} from "../api";
	import base from "../api/base.js";
	import {
		wechatConfig
	} from "../utils/wxConfig";
	import {
		getUser,
		login
	} from "../utils/login_toast";
	export default {
		inject: ["reload"],
		components: {
			Navbar,
		},
		data() {
			return {
				money: "",
				jsApiParameters: "",
				radio: "1",
				userInfo: {
					id: "",
				},
				shopDetail: "",
				companyDetail: "",
				invate_uid: "",
				type : 'service_account'
			};
		},
		created() {
			var _this = this;

			var invate_uid = this.$route.query.invate_uid;
			if (invate_uid) {
				this.$cookies.set("invate_uid", invate_uid);
			}
			if (this.$cookies.isKey("userInfo")) {
				this.userInfo = this.$cookies.get("userInfo");
			}
			this.WXlogin()
			this.setShare()
		},
		methods: {
			//分享
			setShare() {
				const shareInfo = {
					title: `冷鲜柜网-账户提现`,
					desc: `聚制冷精英，集行业资源，方便您我他！`,
					link: base.shareURL + "/tixian?invate_uid=" + this.userInfo.id,
					img: "http://api.zglxgw.com/uploads/images/20201128/5fc1e41dbfbaf.jpg",
				};
				wechatConfig(shareInfo);
			},
			WXlogin() {
				var _this = this;
				var token = this.$cookies.get("Authorization");
				var code = this.getUrlKey("code");
				var state = this.getUrlKey("state");
				if (!token && code && state) {
					login(_this.getLoginUser);
				} else {
					this.getUser();
				}
			},
			getLoginUser(userInfo) {
				this.getUser();
				console.log(userInfo);
			},
			getUrlKey(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			pay() {
				var _this = this;
				console.log(_this.shopDetail)
				// if(_this.userInfo.identity == 0 && _this.userInfo.truename == ""){
				//   _this.$dialog
				//   .confirm({
				//     title: "提示",
				//     message: "您还未完善个人信息，请先完善信息后再进行操作。",
				//     confirmButtonText: "去完善",
				//     cancelButtonText: "取消",
				//   })
				//   .then(() => {
				//     _this.$router.push("/editUserinfo");
				//     // on close
				//   })
				//   .catch(() => {
				//     // on cancel
				//   });
				//   return;
				// }
				// if(_this.userInfo.identity == 2 && _this.shopDetail == ""){
				//   _this.$dialog
				//   .confirm({
				//     title: "提示",
				//     message: "您还未完善店铺信息，请先完善信息后再进行操作。",
				//     confirmButtonText: "去完善",
				//     cancelButtonText: "取消",
				//   })
				//   .then(() => {
				//     _this.$router.push("/editShifuinfo");
				//     // on close
				//   })
				//   .catch(() => {
				//     // on cancel
				//   });
				//   return;
				// }
				// if(_this.userInfo.identity == 1 && _this.companyDetail == ""){
				//   _this.$dialog
				//   .confirm({
				//     title: "提示",
				//     message: "您还未完善厂商信息，请先完善信息后再进行操作。",
				//     confirmButtonText: "去完善",
				//     cancelButtonText: "取消",
				//   })
				//   .then(() => {
				//     _this.$router.push("/editShopinfo");
				//     // on close
				//   })
				//   .catch(() => {
				//     // on cancel
				//   });
				//   return;
				// }
				if (_this.money < 1) {
					_this.$toast("提现金额不得低于1元！");
					return;
				}
				// if (parseFloat(_this.money)  > parseFloat(_this.userInfo.user_account) ) {
				//   _this.$toast("提现金额不得大于账户余额！");
				//   return;
				// }
				var data = {
					money: _this.money,
					type : this.type
				};
				_this.$toast.loading({
					message: "请稍等...",
					forbidClick: true,
					duration: 0,
				});
				addOp("/user_account/with_draw", data).then((res) => {
					_this.$toast.clear();
					if (res && res.data.state == 200) {
						_this.$toast.success("申请提现成功，等待后台审核");
						_this.reload();
						_this.money = "";
					} else {
						_this.$toast.fail(res.data.msg);
					}
				});
			},
			getUser() {
				var _this = this;
				var api = "/user/show/1";
				indexOp(api).then((res) => {
					if (res && res.status == 200) {
						if (res.data.identity == null) {
							_this.$router.push({
								path: "/identity",
							});
							return;
						}
						_this.$cookies.set("identity", res.data.identity);
						_this.$cookies.set("userInfo", res.data);
						_this.userInfo = res.data;
						_this.getShopDetail();
						_this.getcompanyDetail()
					}
				});
			},

			//获取店铺详情
			getShopDetail() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/service_user/show", data).then((res) => {
					if (res && res.status == 200) {
						_this.shopDetail = res.data;
					}
					console.log(res);
				});
			},
			//获取厂商详情
			getcompanyDetail() {
				var _this = this;
				var data = {
					uid: _this.userInfo.id,
				};
				indexOp("/company_user/show/1", data).then((res) => {
					if (res && res.status == 200) {
						_this.companyDetail = res.data;
					}
					console.log(res);
				});
			},
			//支付
			jsApiCall() {
				var _this = this;
				WeixinJSBridge.invoke(
					"getBrandWCPayRequest",
					_this.jsApiParameters,
					function(res) {
						if (res.err_msg === "get_brand_wcpay_request:ok") {
							alert("充值成功！");
							_this.$router.go(-1);
						} else if (res.err_msg === "get_brand_wcpay_request:fail") {
							alert("支付失败！请重试！");
						}
					}
				);
			},
			callpay() {
				var _this = this;
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener(
							"WeixinJSBridgeReady",
							_this.jsApiCall,
							false
						);
					} else if (document.attachEvent) {
						document.attachEvent("WeixinJSBridgeReady", _this.jsApiCall);
						document.attachEvent("onWeixinJSBridgeReady", _this.jsApiCall);
					}
				} else {
					_this.jsApiCall();
				}
			},
		},
	};
</script>

<style>
</style>