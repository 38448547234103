<template>
	<div class="class-list">
		<div class="top-navbar">
			<van-icon name="arrow-left" @click="toBack" />
			<span class="text">分类管理</span>
			<span class="right" style="font-size: 12px" @click="getClassList">
				<van-icon name="replay" style="position:relative;top:2px;margin-right:2px;" />刷新
			</span>
		</div>
		<!-- <van-notice-bar left-icon="volume-o" text="温馨提示:长按左侧/右侧分类名称获更多操作~" /> -->
		<div class="class-lbox">
			<div class="left">
				<div class="item" style="text-align:center;color:#2F78DC;border-left:0px;margin-bottom:5px;" @click="addStatus=true">
					<van-icon name="plus" style="position:relative;top:2px;right:5px;" />添加分类
				</div>
				<div :class="'item ' + (index == active ? 'activeItem' : '') " v-for="(item,index) in list"
					@click="active=index">
					<van-badge>
						<div>
							{{item.name}}
						</div>
						<template #content>
							<van-icon name="cross" class="badge-icon" @click="onDelete(item.id)" />
						</template>
					</van-badge>
				</div>
			</div>
			<div class="right">
				<div class="children-list">
					<div class="child" style="background:#e2e2e2;border:0px;color:#000;border-radius:0px;text-align:center;"
						@click="addFrom.pid=list[active].id;addStatus=true">
						<van-icon name="plus" style="position:relative;top:2px;right:3px;" />添加分类
					</div>
					<hr style="background-color:#F6F6F6;height:1px;border:0px;">
					<block v-if="list && list.length && list[active] && list[active]._child">
						<div class="child" v-for="(item,index) in list[active]._child">
							<van-badge>
								<div style="width:100%;padding-left:10px;padding-right:10px;">
									{{item.name}}
								</div>
								<template #content>
									<van-icon name="cross" class="badge-icon" @click="onDelete(item.id)" />
								</template>
							</van-badge>
						</div>
					</block>
					<van-empty v-else image="error" description="暂无数据" />

				</div>

			</div>
		</div>

		<van-popup v-model="addStatus" closeable @click-close-icon="addFrom.pid=0;addStatus=false"
			@close="addStatus=false">
			<div class="addPopup">
				<div class="title">分类名称</div>
				<div class="input-item">
					<van-field class="addInput" v-model="addFrom.name" autosize type="text" placeholder="请输入分类名称" />
				</div>
				<button class="btn" @click="addFromSubmit">提交</button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Navbar from "../../components/Navbar";
	import Tabber from "../../components/Tabber";
	import {
		indexOp,
		updateOp,
		deleteOp,
		addOp,
		showOp,
		uploadImg,
		uploadFile,
	} from "../../api/index";
	import base from "../../api/base";
	import {
		wechatConfig
	} from "../../utils/wxConfig";
	import {
		getUser,
		login
	} from "../../utils/login_toast";
	export default {
		inject: ["reload"],
		components: {
			Navbar,
			Tabber,
		},
		props: {},
		data() {
			return {
				active: 0,
				addStatus: false,
				addFrom: {
					name: '',
					pid: 0
				},

				// 气泡
				showPopover: false,
				actions: [{
					text: '选项一'
				}, {
					text: '选项二'
				}, {
					text: '选项三'
				}],

				items: [{
					text: '分组 1'
				}, {
					text: '分组 2'
				}],

				cid: "", //厂商id
				list: [],

			};
		},
		computed: {},
		created() {
			this.cid = this.$route.query.id;
			this.getClassList();
		},
		mounted() {},
		watch: {},
		methods: {
			/**
			 * 添加分类提交
			 */
			addFromSubmit() {
				var _this = this;
				addOp("/goods_type/store", _this.addFrom).then((res) => {
					if (res && res.status == 201) {
						_this.$toast.success("操作成功！");
						_this.addFrom.name = '';
						_this.addFrom.pid = 0;
						_this.addStatus = false;
						_this.getClassList();
					}
				});
			},
			//获取分类列表
			getClassList() {
				var _this = this;
				var data = {
					id: _this.cid,
				};
				_this.$toast.loading({
					message: "",
					forbidClick: true,
				});
				indexOp("/goods_type/index", data).then((res) => {
					_this.$toast.clear();
					if (res && res.status == 200) {
						var tmpList = res.data;
						for (var i in tmpList) {
							tmpList[i].text = tmpList[i].name;
						}
						_this.list = tmpList;
						console.log(_this.list);
					}
				});
			},
			//删除分类
			onDelete(id) {
				var _this = this;
				var api = "/goods_type/destroy/" + id;
				_this.$dialog
					.confirm({
						title: "提示",
						message: "确认删除该分类？",
					})
					.then(() => {
						addOp(api).then((res) => {
							if (res && res.status == 200) {
								_this.$toast.success("操作成功！");
								setTimeout(() => {
									_this.reload()
								}, 800);
							} else {
								_this.$toast.fail(res.data.error)
							}
						});
					})
					.catch(() => {
						// on cancel
					});
			},
			toBack() {
				this.$router.go(-1);
			},
		},
	};
</script>

<style scoped lang="less">
	@ThemColor: #2F78DC;

	.class-lbox {
		background: #fff;
		display: flex;
		flex-direction: row;
		height: calc(100vh - 50px);
		width: 100vw;

		.left {
			background: #f7f8fa;
			width: 30%;
				
			.item {
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 15px;
				padding-left: 5px;
				padding-right: 5px;
				width: 100%;
				font-weight:bold;

				.van-badge__wrapper {
					width: 100%;
				}

				border-left:3px solid #ddd;
			}

			.activeItem {
				background: #fff;
				border-left: 3px solid #2F78DC;
			}
		}

		.right {
			width: 70%;
		}
	}

	.children-list {
		width: 100%;
		height: 100%;
		padding: 10px;
		box-sizing: border-box;
		overflow-y: scroll;

		.child {
			display: inline-block;
			background: #70adff;
			border: 0;
			padding: 8px;
			padding-top: 5px;
			padding-bottom: 5px;
			border-radius: 2px;
			color: #fff;
			font-size: 14px;
			margin-left:10px;
			min-width:80px;
			text-align: center;
			margin-bottom: 10px;;
			
			.van-badge__wrapper {
				width: 100%;
			}
			 
		}
	}

	.addInput {
		border: 1px solid @ThemColor;
		border-radius: 5px;
	}

	.addPopup {
		width: 80vw;
		height: auto;
		display: flex;
		flex-direction: column;
		padding: 10px;
		padding-top: 20px;
		padding-bottom: 20px;
		box-sizing: border-box;

		.title {
			color: @ThemColor;
			font-weight: bold;
			font-size: 16px;
		}

		.btn {
			width: 100%;
			background: @ThemColor;
			color: #fff;
			border: 0;
			margin-top: 15px;
			height: 40px;
			line-height: 40px;
		}

	}

	.btns {
		position: fixed;
		right: 15px;
		bottom: 50px;

		.btn {
			height: 35px;
			width: 100px;
			border-radius: 35px;
			font-size: 12px;
			margin: 10px auto 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: @ThemColor;
			box-shadow: 4px 6px 28px -10px rgba(79, 77, 77, 0.50);
			box-sizing: border-box;
			color: #fff;

			span {
				line-height: 1;
			}

			i {
				margin-right: 5px;
				color: #fff;
				font-size: 14px;
			}
		}
	}
</style>